import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

export const PRIMARY_ACCOUNT_TYPES = {
  ADMIN: 'admin',
  STANDARD: 'standard',
  SKIP: 'skip',
};

const PRIMARY_ACCOUNT_OPTIONS = [
  {
    value: PRIMARY_ACCOUNT_TYPES.ADMIN,
    label: 'Admin',
  },
  {
    value: PRIMARY_ACCOUNT_TYPES.STANDARD,
    label: 'Standard account',
  },
  {
    value: PRIMARY_ACCOUNT_TYPES.SKIP,
    label: 'Skip primary account creation',
  },
];

const AccountTypeRow = ({ setting, update, isDisabled }) => (
  <Setting.Row>
    <div>
      <p className="b-txt">Primary account type</p>
    </div>
    <Setting.Helpers className="b-txt-light">
      Specify if the primary user account created on Macs during Setup Assistant
      is an Administrator account (default) or a Standard account. You may also
      choose to skip primary account creation.
    </Setting.Helpers>
    <div>
      <Select
        isDisabled={isDisabled}
        options={PRIMARY_ACCOUNT_OPTIONS}
        placeholder="Select primary account type"
        compact
        onChange={(v) => update('accountType', v.value)}
        value={PRIMARY_ACCOUNT_OPTIONS.find(
          (option) => option.value === setting.accountType,
        )}
      />
    </div>
  </Setting.Row>
);

export default AccountTypeRow;
