import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import initialState from '../initial-state';
import SoftwareUpdateService from './software-update-service';

function transformSettings(settings) {
  // Check for legacy property and use it if new property isn't set:
  let enforcedSoftwareUpdateDelay;
  if (
    settings.ManagedDeferredInstallDelay &&
    !settings.enforcedSoftwareUpdateDelay
  ) {
    enforcedSoftwareUpdateDelay = settings.ManagedDeferredInstallDelay;
  } else {
    enforcedSoftwareUpdateDelay = settings.enforcedSoftwareUpdateDelay;
  }

  const transformedSettings = {
    updateSettings: {
      'restrict-software-update-require-admin-to-install':
        settings['restrict-software-update-require-admin-to-install'],
      AllowPreReleaseInstallation: Boolean(
        settings.AllowPreReleaseInstallation,
      ),

      forceDelayedSoftwareUpdates: Boolean(
        settings.forceDelayedSoftwareUpdates,
      ),
      enforcedSoftwareUpdateDelay: settings.forceDelayedSoftwareUpdates
        ? enforcedSoftwareUpdateDelay
        : initialState.updateSettings.enforcedSoftwareUpdateDelay,

      forceDelayedMajorSoftwareUpdates: Boolean(
        settings.forceDelayedMajorSoftwareUpdates,
      ),
      enforcedSoftwareUpdateMajorOSDeferredInstallDelay:
        settings.forceDelayedMajorSoftwareUpdates
          ? settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay
          : initialState.updateSettings
              .enforcedSoftwareUpdateMajorOSDeferredInstallDelay,

      forceDelayedMinorSoftwareUpdates: Boolean(
        settings.forceDelayedMinorSoftwareUpdates,
      ),
      enforcedSoftwareUpdateMinorOSDeferredInstallDelay:
        settings.forceDelayedMinorSoftwareUpdates
          ? settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay
          : initialState.updateSettings
              .enforcedSoftwareUpdateMinorOSDeferredInstallDelay,

      forceDelayedAppSoftwareUpdates: Boolean(
        settings.forceDelayedAppSoftwareUpdates,
      ),
      enforcedSoftwareUpdateNonOSDeferredInstallDelay:
        settings.forceDelayedAppSoftwareUpdates
          ? settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay
          : initialState.updateSettings
              .enforcedSoftwareUpdateNonOSDeferredInstallDelay,
    },
    automaticUpdates: {
      AutomaticCheckEnabled: settings.AutomaticCheckEnabled,
      AutomaticDownload: settings.AutomaticDownload,
      AutomaticallyInstallMacOSUpdates:
        settings.AutomaticallyInstallMacOSUpdates,
      AutomaticallyInstallAppUpdates: settings.AutomaticallyInstallAppUpdates,
      ConfigDataInstall: settings.ConfigDataInstall,
      CriticalUpdateInstall: settings.ConfigDataInstall,
    },
  };

  // Update Settings UI logic:
  if (settings.forceDelayedSoftwareUpdates) {
    transformedSettings.updateSettings.deferMacOSUpdates =
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferAll.value;
  } else if (
    settings.forceDelayedMajorSoftwareUpdates ||
    settings.forceDelayedMinorSoftwareUpdates ||
    settings.forceDelayedAppSoftwareUpdates
  ) {
    transformedSettings.updateSettings.deferMacOSUpdates =
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferByType.value;
  } else {
    transformedSettings.updateSettings.deferMacOSUpdates =
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.doNotDefer.value;
  }

  return transformedSettings;
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToOldApi(model) {
  const result = {
    name: model.name,
    is_active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    settings: {
      // Automatic Updates section:
      AutomaticCheckEnabled: model.automaticUpdates.AutomaticCheckEnabled,
      AutomaticDownload: model.automaticUpdates.AutomaticDownload,
      AutomaticallyInstallMacOSUpdates:
        model.automaticUpdates.AutomaticallyInstallMacOSUpdates,
      AutomaticallyInstallAppUpdates:
        model.automaticUpdates.AutomaticallyInstallAppUpdates,
      ConfigDataInstall: model.automaticUpdates.ConfigDataInstall,
      CriticalUpdateInstall: model.automaticUpdates.ConfigDataInstall,

      // Update Settings section:
      'restrict-software-update-require-admin-to-install':
        model.updateSettings[
          'restrict-software-update-require-admin-to-install'
        ],
      AllowPreReleaseInstallation:
        model.updateSettings.AllowPreReleaseInstallation,
    },
  };

  // Use "Defer macOS updates" dropdown to determine which relevant properties to add:
  if (
    model.updateSettings.deferMacOSUpdates ===
    SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferAll.value
  ) {
    result.settings.forceDelayedSoftwareUpdates = true;
    result.settings.enforcedSoftwareUpdateDelay =
      model.updateSettings.enforcedSoftwareUpdateDelay;
  } else if (
    model.updateSettings.deferMacOSUpdates ===
    SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferByType.value
  ) {
    // Only add those options that have their related checkboxes checked:
    if (model.updateSettings.forceDelayedMajorSoftwareUpdates) {
      result.settings.forceDelayedMajorSoftwareUpdates = true;
      result.settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay =
        model.updateSettings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay;
    }

    if (model.updateSettings.forceDelayedMinorSoftwareUpdates) {
      result.settings.forceDelayedMinorSoftwareUpdates = true;
      result.settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay =
        model.updateSettings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay;
    }

    if (model.updateSettings.forceDelayedAppSoftwareUpdates) {
      result.settings.forceDelayedAppSoftwareUpdates = true;
      result.settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay =
        model.updateSettings.enforcedSoftwareUpdateNonOSDeferredInstallDelay;
    }
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['Software Update'];

    result.type = itemConfig.type;
    result.identifier = itemConfig.identifier;
  }

  return result;
}

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);
  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

function transformToApi(model) {
  const transformer = transformToNewApi;

  return transformer(model);
}

export { transformToApi, transformFromApi };
