import React from 'react';

// Bumblebee
import { Button } from '@kandji-inc/bumblebee';

const CancelButton = ({ onClick, isDisabled }) => (
  <Button kind="outline" onClick={onClick} isDisabled={isDisabled}>
    Cancel
  </Button>
);

export default CancelButton;
