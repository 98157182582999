import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const OptionsSection = (props) => {
  const { isDisabled, update, setting } = props;

  const hintsOptions = [
    { value: 0, label: 'Disable password hints' },
    ...new Array(10).fill(0).map((_, idx) => ({
      value: idx + 1,
      label: `Display hint after ${idx + 1} failed attempts`,
    })),
  ];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Options</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.SleepDisabled}
              label="Hide Sleep button"
              onChange={() => update('SleepDisabled', (p) => !p)}
              testId="hide-sleep"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability to sleep the computer at the login window.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.RestartDisabled}
              label="Hide Restart button"
              onChange={() => update('RestartDisabled', (p) => !p)}
              testId="hide-restart"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability to restart the computer at the login window.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.ShutDownDisabled}
              label="Hide Shut Down button"
              onChange={() => update('ShutDownDisabled', (p) => !p)}
              testId="hide-shutdown"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability to shut down the computer at the login window.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.RetriesUntilHint?.checked}
              label="Manage password hints"
              onChange={() =>
                update('RetriesUntilHint', (p) => ({
                  ...p,
                  checked: !p.checked,
                }))
              }
              testId="manage-password-hints"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Specify the number of failed authentication attempts before a
            password hint is displayed to the user, or choose to disable
            password hints. Note that a user account must have a password hint
            for it to be displayed.
          </Setting.Helpers>
          {setting.RetriesUntilHint?.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <Select
                    compact
                    isDisabled={isDisabled}
                    options={hintsOptions}
                    value={hintsOptions.find(
                      ({ value }) => setting.RetriesUntilHint.value === value,
                    )}
                    onChange={({ value }) => {
                      update('RetriesUntilHint', (p) => ({ ...p, value }));
                    }}
                    testId="retries-until-hint"
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.DisableFDEAutoLogin}
              label="Disable automatic login after FileVault disk unlock"
              onChange={() => update('DisableFDEAutoLogin', (p) => !p)}
              testId="disable-fde-login"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            The computer will boot to the login window instead of automatically
            logging into the user account that is authenticated at the FileVault
            disk unlock screen.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting['com.apple.login.mcx.DisableAutoLoginClient']}
              label="Disable automatic login"
              onChange={() =>
                update('com.apple.login.mcx.DisableAutoLoginClient', (p) => !p)
              }
              testId="disable-auto-login"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable Automatic Login if FileVault 2 is not enabled.
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default OptionsSection;
