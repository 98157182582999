import { useInfiniteQuery } from '@tanstack/react-query';
import { integrationsApi } from 'src/app/api/base';

export async function getADEUsers(search: string, page: number): Promise<any> {
  try {
    const res = await integrationsApi('/user-integrations/users').get({
      search,
      sizePerPage: 50,
      page,
    });
    const { data } = res;

    return data.results ?? [];
  } catch (error) {
    return [];
  }
}

export function useGetADEUsers(search: string) {
  return useInfiniteQuery({
    queryKey: ['get-ade-users', search],
    queryFn: ({ pageParam }) => getADEUsers(search, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      /* istanbul ignore next */
      lastPage.length ? pages.length + 1 : undefined,
  });
}
