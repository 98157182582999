import {
  Select,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import CustomScriptService from '../service/custom-script-service';

const options = [
  {
    label: 'Run once per device',
    value: CustomScriptService.executionFrequencyTypes.ONCE,
  },
  {
    label: 'Run every 15 minutes',
    value: CustomScriptService.executionFrequencyTypes.EVERY_15_MIN,
  },
  {
    label: 'Run daily',
    value: CustomScriptService.executionFrequencyTypes.EVERY_DAY,
  },
  {
    label: 'Run on-demand from Self Service',
    value: CustomScriptService.executionFrequencyTypes.NO_ENFORCEMENT,
  },
];
const ExecutionFrequency = (props) => {
  const { setting, update, isDisabled, validationDep } = props;

  const isSubmitted = useIsChanged(validationDep);
  const fieldsToValidate = ['type'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate('type')(
      !setting.type ||
        !Object.values(CustomScriptService.executionFrequencyTypes).includes(
          setting.type,
        ),
    );
  }, [setting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Execution Frequency</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs[0]}>
              Determine how often this Custom Script should be executed. Choose
              to execute the script once per device, at every check-in, once
              daily, or install on-demand through Self Service.{' '}
              <a
                href="https://support.kandji.io/support/solutions/articles/72000558749"
                className="b-alink"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              disabled={isDisabled}
              options={options}
              value={options.find(({ value }) => value === setting.type)}
              onChange={({ value }) => update('type', value)}
              errorText={isSubmitted && invalidations.type && 'Required.'}
              testId="frequency"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default ExecutionFrequency;
