// istanbul ignore file
import { Body, Box, Flex, Icon, Tooltip, styled } from '@kandji-inc/nectar-ui';
import type { ReactNode } from 'react';
import { PrismNavList } from '../../components/PrismNav';
import PrismNavItem from '../../components/PrismNav/PrismNavItem';

export const ViewsNav = ({
  views,
  viewId,
  collapsed,
  onSelectView,
}: {
  views: { id: string; name: string }[];
  viewId: string | null;
  collapsed: boolean;
  onSelectView: (view: { id: string; name: string }) => void;
}) => {
  return (
    <PrismNavList
      flow="column"
      gap="sm"
      css={{ height: 'calc(100vh - 140px)', maxHeight: '100%' }}
    >
      {!collapsed && <SectionHeader>My views</SectionHeader>}
      {views
        .sort((a) => (a.id === '' ? -1 : 1))
        .map((view) => {
          const viewName = view.id ? view.name : 'All devices';
          return (
            <NavItemContainer
              key={view.id}
              showTooltip={collapsed}
              tooltipContent={viewName}
            >
              <PrismNavItem
                onSelect={() => onSelectView(view)}
                selected={viewId === view.id || (!viewId && view.id === '')}
                disabled={false}
                uri={view.id}
              >
                <NavIcon name="devices" />
                {!collapsed && <Box id="views-nav-label">{viewName}</Box>}
              </PrismNavItem>
            </NavItemContainer>
          );
        })}
    </PrismNavList>
  );
};

const SectionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      gap="sm"
      alignItems="center"
      css={{
        padding: '8px',
        alignSelf: 'stretch',
        borderRadius: '4px',
        background: '$neutral00',
      }}
    >
      <Flex gap="xs" alignItems="center" flex={1}>
        <Body weight="medium" as="span">
          {children}
        </Body>
      </Flex>
    </Flex>
  );
};

const NavItemContainer = ({
  children,
  showTooltip,
  tooltipContent,
}: {
  children: React.ReactNode;
  showTooltip: boolean;
  tooltipContent: string;
}) => {
  return showTooltip ? (
    <Tooltip content={tooltipContent} side="right" css={{ zIndex: 2 }}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};

const NavIcon = styled(Icon, {
  height: 20,
  width: 20,
  color: '$icon_primary',
});
