import {
  Button,
  Checkbox,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';

import {
  securityTypeOptions,
  securityWithLoginWindowConfigurationValues,
  securityWithPasswordValues,
} from 'features/library-items/items/wifi/protocols-card/protocols-card.model';
import { Setting } from 'features/library-items/template';
import WifiService from '../service/wifi-service';

const securityOptions = securityTypeOptions.filter(
  ({ value }) => !securityWithLoginWindowConfigurationValues.includes(value),
);
const keys = WifiService.keys.general;
const authKeys = WifiService.keys.authentication;
const fieldsToValidate = [keys.ssid];

const SingleConfiguration = (props) => {
  const {
    config,
    update,
    updateConfig,
    isDisabled,
    validationDep,
    onDelete,
    canDelete,
  } = props;

  const fieldWithId = (k) => `${config.id}_${k}`;
  const fieldsToValidateWithId = fieldsToValidate.map(fieldWithId);
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidateWithId,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidateWithId, update);

  const isSecurityWithPassword = securityWithPasswordValues.includes(
    config.authentication[authKeys.securityType],
  );

  return (
    <Setting.Card testId={`wifiv1_singleconfig_${config.id}`}>
      <Setting.Header className="b-flex-vc b-flex-btw">
        <h3 className="b-h3">Wi-Fi Configuration</h3>
        {canDelete && (
          <Button
            onClick={onDelete}
            isDisabled={isDisabled}
            kind="link"
            theme="error"
            icon="xmark"
          >
            Remove
          </Button>
        )}
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt" ref={refs[fieldWithId(keys.ssid)]}>
              Service Set Identifier (SSID)
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Specify the SSID (network name) of the network you want devices to
              join.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <TextInput
              compact
              disabled={isDisabled}
              value={config.general[keys.ssid]}
              onChange={(e) => {
                const { value } = e.target;
                updateConfig(keys.ssid, value);
              }}
              placeholder="AccuhiveWiFiSecure"
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  trigger: ['onBlur', validationDep],
                }),
              ]}
              onInvalidate={onInvalidate(fieldWithId(keys.ssid))}
              removeValidationOnUnmount
              data-testid="ssid"
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Authentication type</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Choose the authentication type of the wireless network.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              isDisabled={isDisabled}
              options={securityOptions}
              onChange={(v) =>
                updateConfig(authKeys.securityType, v.value, 'authentication')
              }
              value={securityOptions.find(
                (option) =>
                  option.value === config.authentication[authKeys.securityType],
              )}
              testId="security-type"
            />
          </Setting.Controls>
          {isSecurityWithPassword && (
            <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
              <Setting.SecondaryControlsRow>
                <div>
                  <p className="b-txt">
                    Password<span className="b-txt-light2"> (optional)</span>
                  </p>
                </div>
                <div>
                  <p className="b-txt-light">
                    Specify the password (pre-shared key) required to join this
                    network. Leave blank to prompt the user.
                  </p>
                </div>
                <div>
                  <TextInput
                    compact
                    isOptional
                    disabled={isDisabled}
                    value={config.authentication[authKeys.securityPassword]}
                    onChange={(e) =>
                      updateConfig(
                        authKeys.securityPassword,
                        e.target.value,
                        'authentication',
                      )
                    }
                    type="password"
                    placeholder="Password"
                    data-testid="security-password"
                  />
                </div>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={config.general[keys.autoJoin]}
              onChange={() => updateConfig(keys.autoJoin, (p) => !p)}
              disabled={isDisabled}
              label="Auto join network"
              id="auto-join"
              testId="single-config-autojoin"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically join this wireless network.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={config.general[keys.hiddenNetwork]}
              onChange={() => updateConfig(keys.hiddenNetwork, (p) => !p)}
              disabled={isDisabled}
              label="Hidden network"
              id="hidden-network"
              testId="single-config-hidden-network"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Specify whether the network broadcasts its SSID. Hidden networks
              are not standards-compliant and may have compatibility problems.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={config.general[keys.disableMacAddressRandomization]}
              onChange={() =>
                updateConfig(keys.disableMacAddressRandomization, (p) => !p)
              }
              disabled={isDisabled}
              label="Disable MAC address randomization"
              id="disable-mac"
              testId="single-config-disable-mac"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              If enabled, the MAC address will not be randomized when joining
              this network on devices running iOS 14+ and later.
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default SingleConfiguration;
