import featureFlags from 'src/config/feature-flags';

import previewbgFullscreenDarkmode from './assets/previewbg-fullscreen-darkmode-monterey.png';
import previewbgFullscreenLightMode from './assets/previewbg-fullscreen-lightmode-monterey.png';

import previewbgSonomaDarkmode from '../../../kandji-login/assets/bg-macos-sonoma-dark.jpg';
import previewbgSonomaLightMode from '../../../kandji-login/assets/bg-macos-sonoma-light.jpg';

import previewbgWindowDarkmode from './assets/previewbg-window-darkmode-monterey.png';
import previewbgWindowLightmode from './assets/previewbg-window-lightmode-monterey.png';

import installFrameDark from './install-screen/install-frame-dark.png';
import installFrame from './install-screen/install-frame.png';

import helpFrameDark from './help-screen/help-frame-dark.png';
import helpFrame from './help-screen/help-frame.png';

import successFrameDark from './success-screen/assets/success-frame-dark.png';
import successFrame from './success-screen/assets/success-frame.png';

import kandjiLogoDark from './assets/kandji-logo-dark.svg';
import kandjiLogo from './assets/kandji-logo.svg';

import successIconsDark from './success-screen/assets/success-icons-dark.svg';
import successIcons from './success-screen/assets/success-icons.svg';

const setPreviewImage = (o) => {
  const { isDarkMode, displayMode } = o;
  const LDFF_wwdc23LitfoffPreviewImages = featureFlags.getFlag(
    'lit_08312023_wwdc23-liftoff-preview-images',
  );

  const isFullscreen = displayMode === 'full_screen';
  if (isDarkMode && isFullscreen) {
    /* istanbul ignore next */
    return LDFF_wwdc23LitfoffPreviewImages
      ? previewbgSonomaDarkmode
      : previewbgFullscreenDarkmode;
  }
  if (isDarkMode && !isFullscreen) {
    /* istanbul ignore next */
    return LDFF_wwdc23LitfoffPreviewImages
      ? previewbgSonomaDarkmode
      : previewbgWindowDarkmode;
  }
  if (!isDarkMode && isFullscreen) {
    /* istanbul ignore next */
    return LDFF_wwdc23LitfoffPreviewImages
      ? previewbgSonomaLightMode
      : previewbgFullscreenLightMode;
  }
  if (!isDarkMode && !isFullscreen) {
    /* istanbul ignore next */
    return LDFF_wwdc23LitfoffPreviewImages
      ? previewbgSonomaLightMode
      : previewbgWindowLightmode;
  }
  /* istanbul ignore next */
  return LDFF_wwdc23LitfoffPreviewImages
    ? previewbgSonomaLightMode
    : previewbgFullscreenLightMode;
};

const bgPaths = {
  previewbgFullscreenDarkmode,
  previewbgFullscreenLightMode,
  previewbgWindowDarkmode,
  previewbgWindowLightmode,
};

const previewPaths = {
  installFrame,
  installFrameDark,

  helpFrame,
  helpFrameDark,

  successFrame,
  successFrameDark,
};

const logo = {
  kandjiLogo,
  kandjiLogoDark,
};

const success = {
  successIconsDark,
  successIcons,
};

export { bgPaths, previewPaths, logo, success };

export default setPreviewImage;
