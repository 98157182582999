import PropTypes from 'prop-types';
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';

import { api } from 'app/api/base';

import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import useAuth0 from '../../auth0/useAuth0';

const defaultValues = {
  list: undefined,
  auth0UserId: null,
  isLoading: false,
  isWorking: false,
  setList: () => {},
  setIsLoading: () => {},
  setIsWorking: () => {},
  onDelete: () => {},
};

const Context = createContext(defaultValues);

const Provider = ({ children }) => {
  const { auth0UserId } = useContext(AccountContext);
  const { setSnackbar, onModalClose } = useContext(InterfaceContext);

  const dispatch = useDispatch();

  const [list, setList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const fetchList = async (runIsLoading) => {
    if (auth0UserId) {
      try {
        if (runIsLoading) {
          setIsLoading(true);
        }
        const { data } = await api(
          `auth/user/${auth0UserId}/enrollments`,
        ).get();
        setList(data);
        if (runIsLoading) {
          setIsLoading(false);
        }
      } catch (error) {
        if (runIsLoading) {
          setIsLoading(false);
        }
        dispatch(setSnackbar('Something went wrong.'));
      }
    }
  };

  const initializeList = () => fetchList(true);

  const onDelete = async (id) => {
    try {
      setIsWorking(true);
      await api(`auth/enrollments/${id}`).delete();
      onModalClose();
      fetchList(true);
      setIsWorking(false);
      dispatch(setSnackbar('OTP Authenticator deleted.'));
    } catch (error) {
      setIsWorking(false);
      dispatch(setSnackbar('Something went wrong.'));
    }
  };

  useEffect(() => {
    initializeList();
  }, []);

  // If a users auth0 ID session has expired we must force them to re-authenticate
  const { loginWithRedirect } = useAuth0();
  const reAuthenticate = useCallback(() => {
    loginWithRedirect({});
  }, [loginWithRedirect]);

  useEffect(() => {
    if (!auth0UserId) {
      reAuthenticate();
    }
  }, [auth0UserId, reAuthenticate]);

  return (
    <Context.Provider
      value={{
        ...defaultValues,
        list,
        auth0UserId,
        isLoading,
        isWorking,
        setList,
        setIsLoading,
        setIsWorking,
        onDelete,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Context, Provider };
