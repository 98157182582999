import React from 'react';

import { Banner, Button, Flex, modal as Modal } from '@kandji-inc/bumblebee';

import { useAdvanced, useAuthority, useIs, useModal } from '../../hooks';

const ModalDeleteIntegration = () => {
  const { deleteIntegration } = useAdvanced();

  const { clearAuthorityListLibraryItemList } = useAuthority();

  const { isLoading, isWorking, setHasChecked } = useIs();
  const { modal, closeModal } = useModal();

  const isOpen = !!modal['delete-integration'];

  /* istanbul ignore next */
  const reset = () => {
    clearAuthorityListLibraryItemList();
    setHasChecked(false);
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closeModal(reset);
  };

  /* istanbul ignore next */
  const onDelete = async () => {
    await deleteIntegration();
    closeModal(reset);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={
        // onClose is required by Modal but not needed by this component
        /* istanbul ignore next */
        () => {}
      }
      withoutCloseButton
      animationDuration={250}
      parts={{
        header: {
          text: 'Delete AD CS integration',
        },
        content: {
          children: (
            <>
              <p className="b-txt" style={{ width: 496 }}>
                The integration with{' '}
                <strong>Active Directory Certificate Services</strong> will be
                permanently removed. This action cannot be undone.
              </p>

              <Banner
                className="b-mt2"
                theme="warning"
                icon="octagon-exclamation"
              >
                <p>
                  This integration is a requirement to issue AD CS certificates
                  to your Apple fleet.
                </p>
              </Banner>
            </>
          ),
        },
        footer: {
          children: (
            <Flex>
              <Button
                kind="outline"
                onClick={onCancel}
                isDisabled={isLoading || isWorking}
              >
                Cancel
              </Button>

              <Button
                className="l-ml"
                theme="error"
                onClick={onDelete}
                isDisabled={isLoading || isWorking}
                isProgress={isWorking}
              >
                Delete integration
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
};

export default ModalDeleteIntegration;
