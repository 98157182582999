import type { AnyFunc } from '@kandji-inc/nectar-ui';

import { privacyEnum } from './schema/privacy.schema';

import type * as PrivacyTypes from './privacy.types';

const {
  propertyName: {
    enum: {
      Accessibility,
      AddressBook,
      AppleEvents,
      BluetoothAlways,
      Calendar,
      Camera,
      FileProviderPresence,
      ListenEvent,
      MediaLibrary,
      Microphone,
      Reminders,
      Photos,
      PostEvent,
      ScreenCapture,
      SpeechRecognition,
      SystemPolicyAllFiles,
      SystemPolicyAppBundles,
      SystemPolicyDesktopFolder,
      SystemPolicyDocumentsFolder,
      SystemPolicyDownloadsFolder,
      SystemPolicyNetworkVolumes,
      SystemPolicyRemovableVolumes,
      SystemPolicySysAdminFiles,
    },
  },
  allowedLabel,
} = privacyEnum;

export const appAccessPropertyOptions = [
  { value: Accessibility, label: Accessibility },
  { value: AddressBook, label: AddressBook },
  { value: AppleEvents, label: AppleEvents },
  { value: BluetoothAlways, label: BluetoothAlways },
  { value: Calendar, label: Calendar },
  { value: Camera, label: Camera },
  { value: FileProviderPresence, label: FileProviderPresence },
  { value: ListenEvent, label: ListenEvent },
  { value: MediaLibrary, label: MediaLibrary },
  { value: Microphone, label: Microphone },
  { value: Reminders, label: Reminders },
  { value: Photos, label: Photos },
  { value: PostEvent, label: PostEvent },
  { value: ScreenCapture, label: ScreenCapture },
  { value: SpeechRecognition, label: SpeechRecognition },
  { value: SystemPolicyAllFiles, label: SystemPolicyAllFiles },
  { value: SystemPolicyAppBundles, label: SystemPolicyAppBundles },
  { value: SystemPolicyDesktopFolder, label: SystemPolicyDesktopFolder },
  {
    value: SystemPolicyDocumentsFolder,
    label: SystemPolicyDocumentsFolder,
  },
  {
    value: SystemPolicyDownloadsFolder,
    label: SystemPolicyDownloadsFolder,
  },
  {
    value: SystemPolicyNetworkVolumes,
    label: SystemPolicyNetworkVolumes,
  },
  {
    value: SystemPolicyRemovableVolumes,
    label: SystemPolicyRemovableVolumes,
  },
  { value: SystemPolicySysAdminFiles, label: SystemPolicySysAdminFiles },
] as const;

export const allowDenyAccessOptions = [
  { value: true, label: allowedLabel.enum.Allow },
  { value: false, label: allowedLabel.enum.Deny },
] as const;

export const denyAccessOptions = [
  { value: false, label: allowedLabel.enum.Deny },
];

export const denyAndUserApproveAccessOptions = [
  { value: false, label: allowedLabel.enum.Deny },
  { value: null, label: allowedLabel.enum['Allow Standard User to Approve'] },
] as const;

export const getAllowOptions = (
  property: PrivacyTypes.AppAccessPropertySetting['Property'],
) => {
  switch (property) {
    // Deny or allow user approval access options
    case ListenEvent:
    case ScreenCapture:
      return denyAndUserApproveAccessOptions;

    // Only deny access options
    case Camera:
    case Microphone:
      return denyAccessOptions;

    // Allow or deny access options
    default:
      return allowDenyAccessOptions;
  }
};

export const createDisplayableError =
  (showError: boolean) =>
  <ConditionFn extends AnyFunc, Error, Ok>(
    condition: boolean | ConditionFn,
    error?: Error,
    ok?: Ok,
  ) => {
    if (typeof condition === 'function') {
      return showError && condition();
    }
    return showError && condition ? error : ok;
  };

/**
 * Given an identifier, validate it and return the appropriate message given the error.
 */
export const getInvalidIdentifierMessage = (
  Identifier:
    | PrivacyTypes.AppAccessSetting['Identifier']
    | PrivacyTypes.AppAccessPropertySetting['AEReceiverIdentifier'],
  IdentiferType:
    | PrivacyTypes.AppAccessSetting['IdentifierType']
    | PrivacyTypes.AppAccessPropertySetting['AEReceiverIdentifierType'],
) => {
  const { identifierType, validationMessage } = privacyEnum;
  const identifierTypeEnum = identifierType.enum;
  const validationMessageEnum = validationMessage.enum;

  if (!Identifier) {
    return { label: validationMessageEnum['Required.'] };
  }

  // Bundle IDs cannot contain spaces
  if (
    Identifier.includes(' ') &&
    IdentiferType === identifierTypeEnum.bundleID
  ) {
    return { label: validationMessageEnum['Invalid Bundle ID.'] };
  }

  return false;
};
