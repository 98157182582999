import FirewallService from './service/firewall-service';

const initialState = {
  id: '',
  name: '',
  isActive: true,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  general: {
    EnableFirewall: false,
    canBlockAllIncoming: false, // UI-only, not sent to API
    BlockAllIncoming: false,
    canEnableStealthMode: false, // UI-only, not sent to API
    EnableStealthMode: false,
  },
  logging: {
    EnableFirewallLogging: false,
    EnableDetailedFirewallLogging: false,
  },
  managePf: {
    is_managing_pf_firewall: false,
    ...FirewallService.managePfOptions.reduce(
      (acc, val) => ({
        ...acc,
        [val.serverField]: val.defaultValue,
      }),
      {},
    ),
  },
  supportsRules: true,
};

export default initialState;
