import { z } from 'zod';

export const appSchema = z.object({
  Type: z.literal('Application'),
  id: z.string().nonempty(),
  name: z.string().nonempty(),
  icon: z.string(),
  appType: z.enum(['appStore', 'inHouse', 'system']),
  deviceFamilies: z.array(z.enum(['iphone', 'ipad'])).nonempty(),
  BundleID: z.string().nonempty(),
  alwaysInPreview: z.boolean().optional(),
});

export const webClipSchema = z.object({
  Type: z.literal('WebClip'),
  id: z.string().nonempty(),
  name: z.string().nonempty(),
  icon: z.string().url().nonempty(),
  URL: z.string().url().nonempty(),
});

export const nonFolderHomeScreenLayoutItemSchema = z.discriminatedUnion(
  'Type',
  [appSchema, webClipSchema],
);

export const folderSchema = z.object({
  Type: z.literal('Folder'),
  id: z.string().nonempty(),
  DisplayName: z.string(),
  Pages: z.array(z.array(nonFolderHomeScreenLayoutItemSchema)),
});

export const homeScreenLayoutItemSchema = z.discriminatedUnion('Type', [
  appSchema,
  webClipSchema,
  folderSchema,
]);
