import { Chip } from '@kandji-inc/bumblebee';
import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
  useContext,
} from 'react';
/* istanbul ignore file */
import './category.css';

import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { useHistory } from 'react-router';
import PreviewHoverTippy from 'src/features/util/components/preview-hover-tippy';
import SlackIcon from '../../library-item-configurations/assets/slack.png';
import DetailView from './detail-view';
import { getGridElementsPosition } from './helpers';

const defaultDetailsOpen = {
  data: {},
  grid: {},
  gridEl: null,
};
const LibraryItemCategory = (props) => {
  const { category, data, isCategoryOpen, onItemSelect, selectedItem } = props;
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);
  const history = useHistory();
  const ref = useRef();
  const [detailsOpen, setDetailsOpen] = useState({ ...defaultDetailsOpen });

  const updateDetailsGrid = () => {
    if (Object.keys(detailsOpen.data).length) {
      setDetailsOpen((prev) => ({
        ...prev,
        grid: getGridElementsPosition(ref?.current, prev.gridEl, [
          'li-add-library-item__card',
        ]),
      }));
    }
  };

  const onItemClick = (data, target) => {
    onItemSelect(data);
    setDetailsOpen({
      data: data,
      gridEl: target,
      grid: getGridElementsPosition(ref?.current, target, [
        'li-add-library-item__card',
      ]),
    });
    // Without the timeout, because of the opening/closing detail view, it's
    // difficult to get a consistent scroll.
    setTimeout(() => {
      window.scroll({
        top:
          target.getBoundingClientRect().top +
          window.scrollY -
          window.innerHeight / 3,
        behavior: 'smooth',
      });
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDetailsGrid);
    return () => {
      window.removeEventListener('resize', updateDetailsGrid);
    };
  }, [detailsOpen]);

  useEffect(() => {
    // When the category isn't open anymore, reset detailsOpen data.
    if (!isCategoryOpen) {
      setDetailsOpen({ ...defaultDetailsOpen });
    } else if (!detailsOpen.gridEl && selectedItem) {
      const el = document.querySelector(`[id='${selectedItem.name}']`);
      if (el) {
        onItemClick(selectedItem, el);
      }
    }
  }, [isCategoryOpen]);

  return (
    <div className="li-add-library-item__category">
      <h3 className="b-h3 b-mb li-add-library-item__category-title">
        {category}
      </h3>
      <div ref={ref} className="li-add-library-item__category-items">
        {data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d, idx) => {
            const isPreviewChip = d?.data?.is_preview_chip;
            const icon = d.icon || SlackIcon;
            return (
              <Fragment key={`${d.name}_${idx}`}>
                <div
                  id={d.name}
                  onClick={(e) => onItemClick(d, e.currentTarget)}
                  className={`li-add-library-item__card ${
                    d.name === detailsOpen.data?.name
                      ? 'li-add-library-item__selected'
                      : ''
                  }`}
                >
                  <img src={icon} className="li-add-library-item__card-icon" />

                  <div className="li-add-library-item__title">
                    <h3 className="b-flex-vc b-h3">{d.name}</h3>
                    {isPreviewChip && <PreviewHoverTippy />}
                  </div>
                  <p className="li-add-library-item__card-description b-txt-light">
                    {d.description}
                  </p>
                  <div className="b-flex-g" style={{ gridArea: 'tags' }}>
                    {d.devices.map((device) => (
                      <Chip
                        key={`${d.name}_${device}`}
                        text={device}
                        kind="secondary"
                      />
                    ))}
                  </div>
                </div>
                {detailsOpen.data.name === d.name && (
                  <DetailView
                    data={d}
                    row={detailsOpen.grid?.row}
                    onClose={() => {
                      setDetailsOpen({ ...defaultDetailsOpen });
                      onItemSelect(null);
                    }}
                    onConfigure={() => {
                      const addUrlRoute = detailsOpen.data.getAddUrl({
                        id: detailsOpen.data.id,
                        account,
                        environment,
                      });
                      if (addUrlRoute) {
                        history.push(addUrlRoute);
                      }
                    }}
                    category={category}
                  />
                )}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default LibraryItemCategory;
