import { INT_TYPES } from '../../../../constants';

import type { AdcsInputs, AdcsState } from '../../types/adcs.types';
// Types
import type { IntegratorViewConfig } from '../../types/integrator.types';

// Icons
import adcsLogo from '../../../../generic-cards-view/assets/adcs.svg';
import downloadSingleIcon from '../../../adcs/assets/download-single.svg';
import pendingConnectionIcon from '../../../adcs/assets/pending-set-icon.svg';

// Helpers
import {
  downloadStatusOptions,
  downloadTickets,
  handleNextDownload,
  handleNextTextDownload,
  overviewHandleClose,
  overviewHandleRedownload,
  сonnectionTickets,
} from './helper';

// Config
const configOverview: IntegratorViewConfig<AdcsInputs, AdcsState, object> = {
  type: INT_TYPES.adcs,
  name: 'adcs',
  logo: adcsLogo,
  openDialog: true,
  pages: [
    {
      name: 'adcsDownloadAndCreate',
      layout: 'one-column-info',
      logo: downloadSingleIcon,
      header: {
        title: 'Download AD CS connector',
        description:
          'Once the download is complete, you will need to install the connector on your Windows server machine.',
      },
      tickets: downloadTickets,
      // footer
      footerProgressText: 'Kandji AD CS connector is downloading...',
      footerStatusOptions: downloadStatusOptions,
      nextButtonText: handleNextTextDownload,
      cancelButtonText: 'Cancel',
      handleClose: overviewHandleClose,
      handleNext: handleNextDownload,
    },
    {
      name: 'adcsRedownload',
      layout: 'one-column-info',
      logo: downloadSingleIcon,
      header: {
        title: 'Download AD CS connector',
        description:
          'Once the download is complete, you will need to install the connector on your Windows server machine.',
      },
      tickets: downloadTickets,
      // footer
      footerProgressText: 'Kandji AD CS connector is downloading...',
      footerStatusOptions: downloadStatusOptions,
      nextButtonText: handleNextTextDownload,
      cancelButtonText: 'Cancel',
      handleClose: overviewHandleClose,
      handleNext: overviewHandleRedownload,
    },
    {
      name: 'adcsInstructions',
      layout: 'one-column-info',
      logo: pendingConnectionIcon,
      header: {
        title: 'Connection pending...',
        description:
          'Perform the following steps below to finalize the integration with Active Directory Certificate Services',
      },
      tickets: сonnectionTickets,
      // footer
      showCancelButton: false,
      backButtonText: 'Redownload connector',
      nextButtonText: 'Close',
      handleNext: overviewHandleClose,
    },
  ],
};

export { configOverview };
