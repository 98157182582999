import { links } from 'app/common/constants';
import { sum } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useModal } from '../components-for-specific-types/adcs/hooks';
import configurations from '../components-for-specific-types/configurations';
import WelcomeModal from '../components-for-specific-types/generic/welcome-modal';
import {
  ALL_INTEGRATIONS_FILTERS,
  INT_CATEGORIES,
  INT_TYPES,
} from '../constants';
import GenericCardsView from '../generic-cards-view';
import { useFeature } from '../hooks';
import MarketplaceIntegrationCard from './marketplace-integration-card';

import Store from '../components-for-specific-types/adcs/store';
import UserIntCreationErrModal from '../components-for-specific-types/generic/user-integrations/user-int-creation-err-modal';
import WelcomeModalController from '../components-for-specific-types/welcome-modal-controller';

import useListIntegrations from '../hooks/use-list-integrations';
import useListIntegrationsBackend from '../hooks/use-list-integrations-backend';

// list of integrations that should use the updated modal controller
const updatedModalIntegrations = [INT_TYPES.msTeams, INT_TYPES.okta];

function IntegrationsMarketplace() {
  const { mapCategoryType } = useFeature();
  const CATEGORIES = Object.entries(mapCategoryType);
  const [filters, setFilters] = useState({ category: null, search: '' });
  const [welcomeModal, setWelcomeModal] = useState(null);
  const { setModal, setModalSlideIdx } = useModal();
  const location = useLocation();

  const [userIntCreationErrModalProps, setUserIntCreationErrModalProps] =
    useState({
      isOpen: false,
      type: null,
      name: '',
      errorKind: null,
    });

  const {
    location: { state },
    action,
  } = useHistory();

  const {
    data: integrationsList,
    isFetching: isIntegrationsFetching,
    isError: isIntegrationsError,
  } = useListIntegrations();

  const {
    data: integrationsBackendList,
    isFetching: isListIntegrationsBackendFetching,
    isError: isIntegrationsBackendError,
  } = useListIntegrationsBackend();

  const isFetching =
    isIntegrationsFetching || isListIntegrationsBackendFetching;

  const error = isIntegrationsError || isIntegrationsBackendError;

  const configuredIntegrations = [
    ...(integrationsList?.data?.results ?? []),
    ...(integrationsBackendList?.items ?? []),
  ];

  const configuredTypes = Object.values(INT_TYPES).filter((type) =>
    configuredIntegrations?.some((int) => int.type === type),
  );

  function closeWelcomeModal() {
    setWelcomeModal(null);
  }

  function isUserIntegration(type) {
    return mapCategoryType[INT_CATEGORIES.user].includes(type);
  }

  function isItemIncluded(type) {
    /* istanbul ignore next */
    return !configuredTypes.includes(type) || isUserIntegration(type);
  }

  function handleMSTeamsOAuthSuccess() {
    if (location.hash.includes('msteams-success')) {
      setModal(INT_TYPES.msTeams);
      setModalSlideIdx(2);
    }
  }

  useEffect(() => {
    if (state && action === 'REPLACE') {
      setUserIntCreationErrModalProps({ isOpen: true, ...state });
    }
  }, [state]);

  useEffect(() => {
    handleMSTeamsOAuthSuccess();
  }, []);

  const itemsByCategories = (
    filters.category === null
      ? CATEGORIES
      : [[filters.category, mapCategoryType[filters.category]]]
  )
    .map(([title, types]) => ({
      title,
      items: types.filter(isItemIncluded).map((type) => ({ type })),
    }))
    .filter((categoryData) => categoryData.items.length);

  const counts = CATEGORIES.reduce(
    (res, [title, types]) => ({
      ...res,
      [title]: types.filter(isItemIncluded).length,
    }),
    {},
  );
  counts[ALL_INTEGRATIONS_FILTERS] = sum(Object.values(counts));

  return (
    <>
      <Store>
        <GenericCardsView
          filters={filters}
          setFilters={setFilters}
          buttonHref={links.integrations.root}
          counts={counts}
          buttonText="Back to Integrations"
          title="Add new integration"
          buttonIcon="arrow-left"
          itemsByCategories={itemsByCategories}
          CardComponent={MarketplaceIntegrationCard}
          extraPropsForCard={{
            setWelcomeModal,
          }}
          buttonKind="outline"
          isFetching={isFetching}
          error={error}
        />
        <UserIntCreationErrModal
          onClose={() => {
            setUserIntCreationErrModalProps((curr) => ({
              ...curr,
              isOpen: false,
            }));
          }}
          {...userIntCreationErrModalProps}
        />
        {Object.keys(configurations).map((intType) => {
          // TODO: This is messy and should be refactored when we are able to do the integration overview refactor
          // This handles both the old welcome modals and the new welcome modal controller. All new integrations should use the welcome modal controller component
          const hasUpdatedWelcomeModal =
            updatedModalIntegrations.includes(intType);
          if (!hasUpdatedWelcomeModal) {
            return (
              <WelcomeModal
                key={intType}
                isOpen={welcomeModal === intType}
                onClose={closeWelcomeModal}
                type={intType}
                deleteIntegrationOnCancel
              />
            );
          }
          return null;
        })}
        <WelcomeModalController />
      </Store>
    </>
  );
}

export default IntegrationsMarketplace;
