import { debounce, omit } from 'lodash';
import { stringify } from 'query-string';
import type { QueryParamConfigMap } from 'use-query-params';
import { encodeQueryParams, useQueryParams } from 'use-query-params';

const debounceSetState = (func: unknown) => debounce(func, 200);

type UseStateParams = <FiltersState, PaginationState, SortColumnState>(
  filtersParamConfig: QueryParamConfigMap,
  paginationParamConfig: QueryParamConfigMap,
  sortingParamConfig: QueryParamConfigMap,
  defaultFilters: FiltersState,
) => {
  filters: FiltersState;
  setFilters: (filters: FiltersState) => void;
  pagination: PaginationState;
  setPagination: (pagination: PaginationState) => void;
  sort: SortColumnState;
  setSort: (sort: SortColumnState) => void;
  isDefaultFilterState: (omitList?: (keyof FiltersState)[]) => boolean;
};

const useStateParams: UseStateParams = <
  FiltersState,
  PaginationState,
  SortColumnState,
>(
  filtersParamConfig: QueryParamConfigMap,
  paginationParamConfig: QueryParamConfigMap,
  sortingParamConfig: QueryParamConfigMap,
  defaultFilters: FiltersState,
) => {
  const [filters, setFilters] = useQueryParams(filtersParamConfig) as [
    FiltersState,
    (filters: FiltersState) => void,
  ];

  const [pagination, setPagination] = useQueryParams(paginationParamConfig) as [
    PaginationState,
    (pagination: PaginationState) => void,
  ];

  const [sort, setSort] = useQueryParams(sortingParamConfig) as [
    SortColumnState,
    (sort: SortColumnState) => void,
  ];

  const encodeParams = (newFilters: QueryParamConfigMap) =>
    stringify(encodeQueryParams(filtersParamConfig, newFilters));

  const isDefaultFilterState = (omitList: (keyof FiltersState)[] = []) => {
    const filteredDefaultFilters: QueryParamConfigMap = omit(
      defaultFilters,
      omitList,
    );
    const filteredCurrentFilters: QueryParamConfigMap = omit(filters, omitList);
    return (
      encodeParams(filteredDefaultFilters) ===
      encodeParams(filteredCurrentFilters)
    );
  };

  return {
    filters,
    setFilters: debounceSetState(setFilters),
    pagination,
    setPagination: debounceSetState(setPagination),
    sort,
    setSort: debounceSetState(setSort),
    isDefaultFilterState,
  };
};

export default useStateParams;
