import { Button, Flex } from '@kandji-inc/nectar-ui';
import React from 'react';

import { PENDO_IDS } from '../../constants';

type CreateMapFooterProps = {
  onBack?: () => void;
  onNext: () => void;
  onCancel: () => void;
  nextLabel: string;
  isSaveDisabled: boolean;
};

const CreateMapFooter = (props: CreateMapFooterProps) => {
  const { onBack, onNext, onCancel, nextLabel, isSaveDisabled } = props;

  return (
    <Flex gap="md" justifyContent="space-between" wrap="wrap">
      <div>
        {onBack && (
          <Button variant="subtle" onClick={onBack} data-testid="back-btn">
            Back
          </Button>
        )}
      </div>
      <Flex gap="md">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={isSaveDisabled}
          onClick={onNext}
          data-testid="next-btn"
          id={PENDO_IDS.createModalNextBtn}
        >
          {nextLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CreateMapFooter;
