import ThreatEmptyFilterIcon from '../../../assets/threat-no-filtered-results.svg';
import ThreatEmptyIconV3 from '../../../assets/threat-no-threats-v3.png';
import ThreatEmptyIcon from '../../../assets/threat-no-threats.svg';
import ThreatListEmptyTable from '../ThreatListEmptyTable';

export type ThreatListEmptyStatesProps = {
  isFiltering: boolean;
  displayDetectionPeriod?: string;
  isV3?: boolean;
};

const ThreatListEmptyStates = ({
  isFiltering,
  displayDetectionPeriod,
  isV3 = false,
}: ThreatListEmptyStatesProps) => (
  <>
    {isFiltering ? (
      <ThreatListEmptyTable
        icon={ThreatEmptyFilterIcon}
        header="No results found"
        body="Try changing the filter, or search with different keywords."
        compact
      />
    ) : (
      <ThreatListEmptyTable
        icon={isV3 ? ThreatEmptyIconV3 : ThreatEmptyIcon}
        iconWidth={isV3 ? 49 : undefined}
        header="No threats have been detected"
        body={`${displayDetectionPeriod} malware or PUP detections in your fleet.`}
        compact
      />
    )}
  </>
);

export default ThreatListEmptyStates;
