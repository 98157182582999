import React, { useContext, useCallback } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import General from './sections/General';
import TeamIds from './sections/TeamIds';
import { systemExtensionService } from './service/system-extension-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSystemExtensionService from './service/use-system-extension-service';

const SystemExtensionPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel), []);
  const teamIdsUpdate = useCallback(update('teamIds', setModel), []);
  // const sectionUpdate = useCallback(update('/* section-name */', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={systemExtensionService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <General
        settings={model.general}
        isDisabled={pageState.isDisabled}
        update={generalUpdate}
      />
      <TeamIds
        settings={model.teamIds}
        isDisabled={pageState.isDisabled}
        update={teamIdsUpdate}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

// SystemExtensionPage.propTypes = {};

const SystemExtension = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useSystemExtensionService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <SystemExtensionPage {...pageProps} testId="system-extension-v2" />;
};

export default SystemExtension;
