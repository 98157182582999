import NewLibraryItemService from 'features/library-items/data-service/library-item/new-library-item-service';

class VpnService extends NewLibraryItemService {
  static VPNTypes = {
    L2TP_OVER_IPSEC: { label: 'L2TP Over IPSec', value: 'L2TP' },
  };

  static Proxys = {
    NONE: { label: 'None', value: 'None' },
    AUTOMATIC: { label: 'Automatic', value: 'Auto' },
    MANUAL: { label: 'Manual', value: 'Manual' },
  };
}

export const vpnService = new VpnService();

export default VpnService;
