import { Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
export const ChatDisclaimer = () => {
  return (
    <DisclaimerContainer>
      <Flex
        justifyContent="center"
        alignItems="start"
        gap="xs"
        css={{
          height: '36px',
        }}
      >
        <IconContainer>
          <Icon size="xs" name="circle-info" />
        </IconContainer>
      </Flex>
      <Text
        css={{
          flex: '1 0 0',
          color: 'var(--text-secondary, #505E71)',
          fontFeatureSettings: "'liga' off",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px /* 142.857% */',
        }}
      >
        While we strive for accuracy, errors in data may occur. Please verify
        information independently before relying on it.
      </Text>
    </DisclaimerContainer>
  );
};

const DisclaimerContainer = styled(Flex, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  alignSelf: 'stretch',
});

const IconContainer = styled(Flex, {
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
});
