import {
  Checkbox,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';
import './adcs-rows.css';

import AddableContent from 'features/library-items/common/addable-content';
import { Setting } from 'features/library-items/template';
import CertificateService from '../../service/certificate-service';
import CertificateCheckboxRows from '../common/certificate-checkbox-rows';
import CertificateNameRow from '../common/certificate-name-row';
import SanRaw from './san-raw';

const keySizeOptions = [
  {
    value: CertificateService.keySizes.S_1024,
    label: '1024',
  },
  {
    value: CertificateService.keySizes.S_2048,
    label: '2048',
  },
  {
    value: CertificateService.keySizes.S_4096,
    label: '4096',
  },
];

const fieldsToValidate = ['subject', 'template', 'server'];

const AdcsRows = (props) => {
  const { isDisabled, setting, update, validationDep, adcsServers } = props;

  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  const isSubmitted = useIsChanged(validationDep);

  return (
    <>
      <CertificateNameRow {...props} />

      <Setting.Row>
        <Setting.Title>
          <p ref={refs.subject} className="b-txt">
            Certificate subject
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">Specify the certificate subject.</p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            value={setting.subject}
            onChange={(e) => update('subject', e.target.value)}
            disabled={isDisabled}
            placeholder="Enter the certificate subject"
            compact
            validator={(v) => [
              onEmptyBlurValidator(v, { trigger: ['onBlur', validationDep] }),
            ]}
            onInvalidate={onInvalidate('subject')}
            data-testid="adcs-subject"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            checked={setting.isSanSpecified}
            label="Specify Subject Alternative Names (SAN)"
            onChange={() => update('isSanSpecified', (p) => !p)}
            isDisabled={isDisabled}
            testId="adcs-san"
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            Choose to include additional host names by specifying a SAN
            attribute and providing the subject alternative name.
          </p>
        </Setting.Helpers>
        {setting.isSanSpecified && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <div>
                <AddableContent
                  max={25}
                  items={setting.san}
                  onChange={(newItems) => update('san', newItems)}
                  getEmptyItem={CertificateService.createSan}
                  isDisabled={isDisabled}
                  itemsClassName="k-cert-li__san-container"
                  rowClassName="k-cert-li__san-row"
                  renderItem={(index) => {
                    const updateSan = (prop, value) => {
                      const newSan = [...setting.san];
                      newSan[index] = {
                        ...newSan[index],
                        [prop]: value,
                      };

                      update('san', newSan);
                    };

                    return (
                      <SanRaw
                        setting={setting.san[index]}
                        updateValidation={update}
                        validationDep={validationDep}
                        isDisabled={isDisabled}
                        update={updateSan}
                      />
                    );
                  }}
                  keyField="_id"
                />
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>

      <Setting.Row>
        <Setting.Title>
          <p ref={refs.template} className="b-txt">
            Template name
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">Specify the AD CS template to use.</p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            value={setting.template}
            onChange={(e) => update('template', e.target.value)}
            disabled={isDisabled}
            placeholder="Enter the template name"
            compact
            validator={(v) => [
              onEmptyBlurValidator(v, { trigger: ['onBlur', validationDep] }),
            ]}
            onInvalidate={onInvalidate('template')}
            data-testid="adcs-template"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title>
          <p ref={refs.server} className="b-txt">
            AD CS server
          </p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">Select the established AD CS server.</p>
        </Setting.Helpers>
        <Setting.Controls>
          <Select
            isDisabled={isDisabled}
            options={adcsServers}
            placeholder="Select the AD CS server"
            compact
            onChange={(v) => update('server', v.value)}
            value={adcsServers.find(
              (option) => option.value === setting.server,
            )}
            errorText={isSubmitted && !setting.server ? 'Required' : ''}
            testId="adcs-server"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">Key size</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            Specify the key size for the certificate.
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <Select
            isDisabled={isDisabled}
            options={keySizeOptions}
            compact
            onChange={(v) => update('keySize', v.value)}
            value={keySizeOptions.find(
              (option) => option.value === setting.keySize,
            )}
            testId="adcs-keysize"
          />
        </Setting.Controls>
      </Setting.Row>

      <CertificateCheckboxRows {...props} />
    </>
  );
};

export default AdcsRows;
