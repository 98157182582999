import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Setting } from 'src/features/library-items/template';

import { Banner, TextInput, useInputsValidators } from '@kandji-inc/bumblebee';

const AutoLogin = (props) => {
  const { update, isDisabled, settings, validationDep } = props;
  const fieldsToValidate = ['AutologinUsername', 'AutologinPassword'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  const handleToggleManage = () => {
    if (settings.AutologinIsManaged) {
      update('AutologinUsername', '');
      update('AutologinPassword', '');

      // clear the validation:
      onInvalidate('AutologinUsername')(false);
      onInvalidate('AutologinPassword')(false);

      update('AutologinIsManaged', !settings.AutologinIsManaged);
    } else {
      update('AutologinIsManaged', !settings.AutologinIsManaged);
    }
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Auto Login (macOS 14 and later)</h3>
        <Setting.HeaderToggle
          isEnabled={
            /* istanbul ignore next */ settings.AutologinIsManaged ?? false
          }
          onChange={handleToggleManage}
          isDisabled={isDisabled}
          chipText={{
            enabled: 'Managed',
            disabled: 'Unmanaged',
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">Configure auto login settings.</p>
      </Setting.SubHeader>
      {settings.AutologinIsManaged && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">Username</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p className="b-txt-light">
                Specify the user name to use for auto login.
              </p>
              <Banner theme="info">
                <p>
                  This user must already exist on the device for the
                  configuration to install.
                </p>
              </Banner>
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs[0]}>
                <TextInput
                  data-testid="AutologinUsername"
                  compact
                  disabled={isDisabled || !settings.AutologinIsManaged}
                  value={settings.AutologinUsername}
                  onChange={(e) => update('AutologinUsername', e.target.value)}
                  placeholder="AutoLoginUser"
                  validator={(v) => [
                    {
                      message: 'Required',
                      invalid: () => !v,
                      trigger: ['onBlur', validationDep],
                    },
                  ]}
                  onInvalidate={onInvalidate('AutologinUsername')}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">Password</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              Specify the password for the user to use for auto login.
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs[1]}>
                <TextInput
                  data-testid="AutologinPassword"
                  compact
                  disabled={isDisabled || !settings.AutologinIsManaged}
                  value={settings.AutologinPassword}
                  onChange={(e) => update('AutologinPassword', e.target.value)}
                  placeholder="Password"
                  type="password"
                  validator={(v) => [
                    {
                      message: 'Required',
                      invalid: () => !v,
                      trigger: ['onBlur', validationDep],
                    },
                  ]}
                  onInvalidate={onInvalidate('AutologinPassword')}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

AutoLogin.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(AutoLogin);
