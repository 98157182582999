/* istanbul ignore file */

export const deviceVariables = [
  {
    name: 'Blueprint name',
    value: 'blueprint_name',
  },
  {
    name: 'Asset tag',
    value: 'asset_tag',
  },
  {
    name: 'Assigned user full name',
    value: 'assigned_user',
  },
  {
    name: 'Assigned user first name',
    value: 'assigned_user_firstname',
  },
  {
    name: 'Assigned user last name',
    value: 'assigned_user_lastname',
  },
  {
    name: 'Company name',
    value: 'company_name',
  },
  {
    name: 'Marketing name',
    value: 'model_name',
  },
  {
    name: 'Model name',
    value: 'mdm_model_name',
  },
  {
    name: 'Serial number',
    value: 'serial_number',
  },
  {
    name: 'Primary local user name',
    value: 'primary_local_user',
    isMacOnly: true,
  },
];

export const patternDefaults = [
  {
    value: 'MacBook Pro (14-inch, Nov 2023)',
    key: 'model_name',
  },
  {
    value: 'MacBook Pro',
    key: 'mdm_model_name',
  },
  {
    value: 'Heather Smith',
    key: 'assigned_user',
  },
  {
    value: 'Heather',
    key: 'assigned_user_firstname',
  },
  {
    value: 'Smith',
    key: 'assigned_user_lastname',
  },
  {
    value: 'K023',
    key: 'asset_tag',
  },
  {
    value: 'Blueprint',
    key: 'blueprint_name',
  },
  {
    value: 'Accuhive',
    key: 'company_name',
  },
  {
    value: 'S123456789',
    key: 'serial_number',
  },
  {
    value: 'Morgan Freeman',
    key: 'primary_local_user',
  },
];

export const hostnamePatternDefaults = [
  {
    value: 'MacBook-Pro-14-inch-Nov-2023',
    key: 'model_name',
  },
  {
    value: 'MacBook Pro',
    key: 'mdm_model_name',
  },
  {
    value: 'Heather-Smith',
    key: 'assigned_user',
  },
  {
    value: 'Heather',
    key: 'assigned_user_firstname',
  },
  {
    value: 'Smith',
    key: 'assigned_user_lastname',
  },
  {
    value: 'K023',
    key: 'asset_tag',
  },
  {
    value: 'Blueprint',
    key: 'blueprint_name',
  },
  {
    value: 'Accuhive',
    key: 'company_name',
  },
  {
    value: 'S123456789',
    key: 'serial_number',
  },
  {
    value: 'Morgan-Freeman',
    key: 'primary_local_user',
  },
];

export const getPatternDefaults = (kind: 'hostname' | 'device') => {
  if (kind === 'hostname') {
    return hostnamePatternDefaults;
  }

  return patternDefaults;
};
