import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';
import {
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from './column-utils';
import { getGlobalColumns } from './globalColumns';

export const getKernelExtensionsColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }
  const kernelExtensionsColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, kernelExtensionsColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    kernelExtensionsColumnHelper.accessor((row) => row.memory_size, {
      id: 'memory_size',
      meta: {
        displayName: 'Memory Size',
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.name, {
      id: 'name',
      meta: {
        displayName: 'Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.version, {
      id: 'version',
      meta: {
        displayName: 'Version',
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: getCodeSnippetCell,
      meta: {
        displayName: 'Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.identifier, {
      id: 'identifier',
      meta: {
        defaultHidden: true,
        displayName: 'ID',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.references, {
      id: 'references',
      meta: {
        defaultHidden: true,
        displayName: 'References',
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    kernelExtensionsColumnHelper.accessor((row) => row.linked_against, {
      id: 'linked_against',
      meta: {
        defaultHidden: true,
        displayName: 'Linked Against',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
