import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import { Setting } from 'features/library-items/template';
import { qosOptions } from '../../fast-lane-card.model';
import SpecificApps from './specific-apps';

const keys = WifiService.keys.fastlane;

const Qos = ({ setting, isDisabled, update, validationDep }) => {
  const showSpecificApps = setting[keys.qos] === WifiService.qosValues.ALLOW;
  return (
    <>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">QoS Fast Lane marking</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light k-li-helper--compact">
              By default, all apps are allowed to use L2 and L3 QoS marking when
              the Wi-Fi network supports Cisco Fast Lane. You may choose to
              allow specific apps for Fast Lane, or disable Fast Lane for all
              apps.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              isDisabled={isDisabled}
              options={qosOptions}
              compact
              onChange={(v) => update(keys.qos, v.value)}
              value={qosOptions.find(
                (option) => option.value === setting[keys.qos],
              )}
              testId="qos-select"
            />
          </Setting.Controls>
        </Setting.Row>
        {showSpecificApps && (
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                label="Mark built-in audio/video apps for Fast Lane"
                checked={setting[keys.markMediaApps]}
                onChange={() => update(keys.markMediaApps, (p) => !p)}
                disabled={isDisabled}
                testId="apps-mark-media"
              />
            </Setting.Controls>
            <Setting.Helpers>
              <p className="b-txt-light">
                Audio and video traffic of built-in audio/video services such as
                FaceTime and Wi-Fi Calling will be whitelisted for L2 and L3
                marking for traffic sent to the Wi-Fi network.
              </p>
            </Setting.Helpers>
          </Setting.Row>
        )}
      </Setting.Rows>
      {showSpecificApps && (
        <SpecificApps
          setting={setting}
          update={update}
          isDisabled={isDisabled}
          validationDep={validationDep}
        />
      )}
    </>
  );
};

export default Qos;
