import { alertsStatusList, links, tabsNames } from 'app/common/constants';
import { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import uuidv1 from 'uuid/v1';
import NoDataToDisplayHappy from '../../../assets/img/no_data_to_display_happy.png';
import ParameterTab from '../common/ParameterTab';

class TabsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: get(props, 'match.params.tab', 'active'),
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { activeTab } = this.state;
    if (next.match.params.tab !== activeTab) {
      this.setState({ activeTab: next.match.params.tab });
    }
  }

  renderNav = () => {
    const { activeTab } = this.state;
    return (
      <div>
        <nav className="nav nav-secondary">
          <Link
            className={activeTab === 'active' ? 'nav-link active' : 'nav-link'}
            to={links.alerts.active}
            key={uuidv1()}
          >
            {tabsNames.active}
          </Link>

          <Link
            className={
              activeTab === 'activeMuted' ? 'nav-link active' : 'nav-link'
            }
            to={links.alerts.activeMuted}
            key={uuidv1()}
          >
            {tabsNames.activeMuted}
          </Link>

          <Link
            className={activeTab === 'old' ? 'nav-link active' : 'nav-link'}
            to={links.alerts.old}
            key={uuidv1()}
          >
            {tabsNames.old}
          </Link>
        </nav>
      </div>
    );
  };

  renderTab = () => {
    const { location, match, history } = this.props;
    const { activeTab } = this.state;
    const routerProps = () => ({
      location,
      match,
      history,
    });
    switch (activeTab) {
      default:
      case 'active':
        return (
          <ParameterTab
            isAlertTab
            isNavigationTab
            view
            expand
            created
            paramNameColWidth="100%"
            recordActions={[]}
            hideBuildNumber
            filters={[]}
            searchPlaceholder="Search Alerts"
            alertsStatusList={alertsStatusList}
            sortName="started_at"
            startedAtName="Created"
            noEndedAt
            noPeriod
            noDataText="No Alerts"
            noDataImg={NoDataToDisplayHappy}
            {...routerProps()}
          />
        );
      case 'activeMuted':
        return (
          <ParameterTab
            isAlertTab
            isNavigationTab
            view
            expand
            created
            paramNameColWidth="100%"
            recordActions={[]}
            hideBuildNumber
            filters={[]}
            searchPlaceholder="Search Alerts"
            alertsStatusList={alertsStatusList}
            sortName="started_at"
            startedAtName="Created"
            noEndedAt
            noPeriod
            noDataText="No Muted Alerts"
            noDataImg={NoDataToDisplayHappy}
            {...routerProps()}
          />
        );

      case 'old':
        return (
          <ParameterTab
            isAlertTab
            isNavigationTab
            view
            expand
            recordActions={[]}
            paramNameColWidth="100%"
            hideBuildNumber
            filters={['status']}
            searchPlaceholder="Search Alerts"
            alertsStatusList={alertsStatusList}
            sortName="run"
            startedAtName="Created"
            endedAtName="Cleared"
            noPeriod
            noDataText="No Cleared Alerts"
            noDataImg={NoDataToDisplayHappy}
            {...routerProps()}
          />
        );
    }
  };

  render() {
    return (
      <div className="computer-tabs" style={{ justifyContent: 'flex-start' }}>
        {this.renderNav()}
        {this.renderTab()}
      </div>
    );
  }
}

export default TabsContainer;
