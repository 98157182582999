/**
 * @file Static dictionary of official Apple built-in system apps for
 * iOS/iPadOS/universal Apple Silicon apps.
 *
 * Results fetched from endpoint below provided with list of iTunes IDs for
 * Apple system apps with manual modifications:
 * https://uclient-api.itunes.apple.com/WebObjects/MZStorePlatform.woa/wa/lookup
 *
 * @see {@link https://developer.apple.com/documentation/devicemanagement/app_and_book_management/app_and_book_management_legacy/getting_app_and_book_information_legacy}
 * @since iOS/iPadOS 17.2
 */

import settingsAppIcon from './assets/settings.png';

export default {
  'Apple Store': {
    id: '375380948',
    name: 'Apple Store',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.store.Jolly',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/0f/39/9e/0f399e40-218c-f820-c9c3-e5cae95712c5/AppIcon-1x_U007emarketing-0-0-0-7-0-0-P3-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple Books': {
    id: '364709193',
    name: 'Apple Books',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.iBooks',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/63/f3/6d/63f36d4b-63f4-0552-b19b-d21ab375c834/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Calculator: {
    id: '1069511488',
    name: 'Calculator',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.calculator',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/9d/05/f7/9d05f753-6df2-a233-88ca-7ff7a916fd4d/AppIcon-0-0-1x_U007emarketing-0-6-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone'],
  },
  Calendar: {
    id: '1108185179',
    name: 'Calendar',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobilecal',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/7a/4c/bd/7a4cbd2a-71b0-df6a-dc8b-d2112c4ded4c/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Camera: {
    id: '1584216193',
    name: 'Camera',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.camera',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/f1/7e/4d/f17e4d91-c3f4-0e8d-101b-c4d1cf041d21/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  /**
   * Removing second-party app from system apps list
   * @see https://kandji.atlassian.net/browse/LIT-2460
   */
  // Clips: {
  //   id: '1212699939',
  //   name: 'Clips',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.clips',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/ae/15/f2/ae15f26a-9735-6289-8b67-111ad5300faa/AppIcon-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-85-220.png/100x100bb.jpg',
  //   deviceFamilies: ['iphone', 'ipad'],
  // },
  Clock: {
    id: '1584215688',
    name: 'Clock',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobiletimer',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/f2/33/64/f23364f6-7d6c-e473-5b19-a3b88d0e5a38/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Compass: {
    id: '1067456176',
    name: 'Compass',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.compass',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/39/ba/aa/39baaa8a-ff0a-d64f-915d-4fbbc933a290/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Contacts: {
    id: '1069512615',
    name: 'Contacts',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.MobileAddressBook',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/cb/08/41/cb0841ec-5d2a-5a29-d3f7-a48a543be872/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  FaceTime: {
    id: '1110145091',
    name: 'FaceTime',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.facetime',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/e8/14/7b/e8147b40-4730-4b03-a3c9-43af93e9e531/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Files: {
    id: '1232058109',
    name: 'Files',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.DocumentsApp',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/25/f7/fb/25f7fb4e-f365-3fc6-faed-5aca2925a679/AppIcon-1x_U007emarketing-0-7-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Find My': {
    id: '1514844621',
    name: 'Find My',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.findmy',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/45/78/56/457856b5-aa13-99c9-0925-0532e6d5f9f0/AppIcon-1x_U007emarketing-0-7-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Fitness: {
    id: '1208224953',
    name: 'Fitness',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Fitness',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/c2/ae/ca/c2aecacf-fa2c-e349-4451-d0271ea4356e/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Freeform: {
    id: '6443742539',
    name: 'Freeform',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.freeform',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/77/a1/38/77a1382a-94b0-5f47-762e-248f44bc27c0/AppIcon-1x_U007emarketing-0-7-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  /**
   * Removing second-party app from system apps list
   * @see https://kandji.atlassian.net/browse/LIT-2460
   */
  // GarageBand: {
  //   id: '408709785',
  //   name: 'GarageBand',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.mobilegarageband',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/90/c3/80/90c3806a-86d7-9ad7-15b1-12960448485f/AppIcon-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-85-220-0.png/100x100bb.jpg',
  //   deviceFamilies: ['ipad'],
  // },
  'Apple Health': {
    id: '1242545199',
    name: 'Apple Health',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Health',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/2c/f2/65/2cf26599-45d2-1b5c-cea0-6b0bef3c0667/AppIcon-0-0-1x_U007epad-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Home: {
    id: '1110145103',
    name: 'Home',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Home',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/8c/38/42/8c384288-9460-abc9-3952-44d4c8a408e8/AppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  /*
   * Removing since decommissioned in iOS 11, superseded by Files app
   */
  // 'iCloud Drive': {
  //   id: '1070072560',
  //   name: 'iCloud Drive',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.iCloudDriveApp',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple71/v4/fa/45/6c/fa456cf4-cc98-acdb-17b1-82f556436db0/mzl.fluxetgb.png/100x100bb.jpg',
  //   deviceFamilies: ['iphone', 'ipad'],
  // },
  /**
   * Removing second-party app from system apps list
   * @see https://kandji.atlassian.net/browse/LIT-2460
   */
  // iMovie: {
  //   id: '377298193',
  //   name: 'iMovie',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.iMovie',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/cd/65/c1/cd65c16c-69c5-b3e8-63f0-90200dbf366c/AppIcon-1x_U007emarketing-0-7-0-85-220.png/100x100bb.jpg',
  //   deviceFamilies: ['iphone', 'ipad'],
  // },
  'iTunes Store': {
    id: '915061235',
    name: 'iTunes Store',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.MobileStore',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple124/v4/e8/ba/5b/e8ba5bd7-5328-9d9f-0846-f215b53ba834/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-10.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  /**
   * Removing second-party app from system apps list
   * @see https://kandji.atlassian.net/browse/LIT-2460
   */
  // Keynote: {
  //   id: '361285480',
  //   name: 'Keynote',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.Keynote',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/5f/a3/06/5fa30685-6494-5ab8-af51-209aed324d09/AppIcon-0-1x_U007emarketing-0-0-0-7-0-0-0-0-0-85-220-0.png/100x100bb.jpg',
  //   deviceFamilies: ['iphone', 'ipad'],
  // },
  Magnifier: {
    id: '1563316278',
    name: 'Magnifier',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Magnifier',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/4d/5d/c6/4d5dc621-a184-bd3d-2de2-358ed1c0ab0d/AppIcon-1x_U007emarketing-0-10-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Mail: {
    id: '1108187098',
    name: 'Mail',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobilemail',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/bd/1f/32/bd1f324b-6127-5373-7d27-8301d80de088/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple Maps': {
    id: '915056765',
    name: 'Apple Maps',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Maps',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/75/e7/3e/75e73e6c-859a-d090-2896-4659e16b72cf/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Measure: {
    id: '1383426740',
    name: 'Measure',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.measure',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/21/cd/3b/21cd3b7f-0f77-5c50-6380-fd3bcea0407a/AppIcon-0-0-1x_U007emarketing-0-0-0-8-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Messages: {
    id: '1146560473',
    name: 'Messages',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.MobileSMS',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/b7/90/1b/b7901b0d-32aa-dc55-6899-8c2c8d8c21d3/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple Music': {
    id: '1108187390',
    name: 'Apple Music',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Music',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/5a/7a/d8/5a7ad872-726f-791e-8b0e-8f51341a500d/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple News': {
    id: '1066498020',
    name: 'Apple News',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.news',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/2e/ea/8e/2eea8e16-f2fc-d605-f0e0-321beabb3ac7/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Notes: {
    id: '1110145109',
    name: 'Notes',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobilenotes',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/76/3f/44/763f444e-31f9-88d2-da6d-a1752d821a82/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  /**
   * Removing second-party app from system apps list
   * @see https://kandji.atlassian.net/browse/LIT-2460
   */
  // Numbers: {
  //   id: '361304891',
  //   name: 'Numbers',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.Numbers',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/57/6e/a7/576ea76c-1dcf-2b30-8206-5234d325cae4/AppIcon-0-1x_U007emarketing-0-0-0-7-0-0-0-0-0-85-220-0.png/100x100bb.jpg',
  //   deviceFamilies: ['iphone', 'ipad'],
  // },
  /**
   * Removing second-party app from system apps list
   * @see https://kandji.atlassian.net/browse/LIT-2460
   */
  // Pages: {
  //   id: '361309726',
  //   name: 'Pages',
  //   Type: 'Application',
  //   appType: 'system',
  //   BundleID: 'com.apple.Pages',
  //   icon:
  //     'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/92/00/af/9200af99-97d8-707e-b569-ebd2b881bcfc/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-85-220-0.png/100x100bb.jpg',
  //   deviceFamilies: ['iphone', 'ipad'],
  // },
  Phone: {
    id: '1146562108',
    name: 'Phone',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobilephone',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/97/c7/66/97c7668c-e422-43cc-a5aa-3debc713172f/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone'],
    alwaysInPreview: true,
  },
  'Photo Booth': {
    id: '1208226939',
    name: 'Photo Booth',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Photo-Booth',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/30/eb/06/30eb06a5-2410-ea7a-1ae8-95ea76778ca4/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-10.png/100x100bb.jpg',
    deviceFamilies: ['ipad'],
  },
  Photos: {
    id: '1584215428',
    name: 'Photos',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobileslideshow',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/9c/be/25/9cbe25e9-4fc6-2c25-d4b3-b06aefe9f96f/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple Podcasts': {
    id: '525463029',
    name: 'Apple Podcasts',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.podcasts',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/ea/08/23/ea0823f2-0d5c-3fc7-d6ce-7208fd5028f3/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Reminders: {
    id: '1108187841',
    name: 'Reminders',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.reminders',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/f5/45/4a/f5454a02-0d5a-a047-ebfc-d5cec264b007/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Safari: {
    id: '1146562112',
    name: 'Safari',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.mobilesafari',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/f8/76/09/f876095c-4f99-f138-d380-0420e21c3c89/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Shortcuts: {
    id: '1462947752',
    name: 'Shortcuts',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.shortcuts',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/f2/62/0d/f2620d32-bc17-c029-c006-41134692cf16/AppIcon-1x_U007emarketing-0-8-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Stocks: {
    id: '1069512882',
    name: 'Stocks',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.stocks',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/e1/38/69/e1386957-0bcc-7314-6746-23b96a2c05bb/AppIcon-0-0-1x_U007emarketing-0-0-0-8-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Swift Playgrounds': {
    id: '908519492',
    name: 'Swift Playgrounds',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Playgrounds',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/73/78/c5/7378c5fa-93bf-ac91-c40b-62528ce82cf9/PlaygroundsAppIcon-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-85-220-0.png/100x100bb.jpg',
    deviceFamilies: ['ipad'],
  },
  Tips: {
    id: '1069509450',
    name: 'Tips',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.tips',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/b6/87/90/b68790d2-b7e9-e302-8a37-88045547ad5c/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Translate: {
    id: '1514844618',
    name: 'Translate',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Translate',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/03/74/e0/0374e0bb-307f-b91b-cd20-000f041db681/AppIcon-1x_U007emarketing-0-7-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple TV': {
    id: '1174078549',
    name: 'Apple TV',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.tv',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/96/70/ed/9670eddf-db2b-1177-98ed-ae305660d3bc/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Voice Memos': {
    id: '1069512134',
    name: 'Voice Memos',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.VoiceMemos',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/aa/1c/0d/aa1c0d30-ab7d-0b9d-43ea-1f59dd07c511/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  'Apple Wallet': {
    id: '1160481993',
    name: 'Apple Wallet',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Passbook',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/c3/d1/ae/c3d1ae4e-ec13-a927-2e41-0a01a67d16ec/AppIcon-0-0-1x_U007emarketing-0-6-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone'],
  },
  Watch: {
    id: '1069511734',
    name: 'Watch',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Bridge',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/4c/2f/15/4c2f15f5-3913-b073-2215-34f030e458f2/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone'],
  },
  Weather: {
    id: '1069513131',
    name: 'Weather',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.weather',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/cf/b9/28/cfb9281c-6354-e66e-5ee5-b2aeadf81c37/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/100x100bb.jpg',
    deviceFamilies: ['iphone', 'ipad'],
  },
  Journal: {
    id: '6447391597',
    name: 'Journal',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.journal',
    icon: 'https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/a6/2c/2c/a62c2c1f-68cd-123c-c3dc-ea41375f8a30/AppIcon-0-0-1x_U007ephone-0-85-220.png/100x100bb.jpg',
    deviceFamilies: ['iphone'],
  },
  Settings: {
    id: 'com.apple.Preferences',
    name: 'Settings',
    Type: 'Application',
    appType: 'system',
    BundleID: 'com.apple.Preferences',
    icon: settingsAppIcon,
    deviceFamilies: ['iphone', 'ipad'],
    alwaysInPreview: true,
  },
} as const;
