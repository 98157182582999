// import PropTypes from 'prop-types';
import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import featureFlags from 'src/config/feature-flags';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from '../custom-profile/activity-tab';
import './firewall.css';
import initial from './initial-state';
import General from './sections/general';
import Logging from './sections/logging';
import ManagePf from './sections/manage-pf';
import FirewallService, { firewallService } from './service/firewall-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useFirewallService from './service/use-firewall-service';

const FirewallPage = (props) => {
  const { model, setModel, pageState } = props;
  const [/* validationDep */ , triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel));
  const loggingUpdate = useCallback(update('logging', setModel));
  const managePfUpdate = useCallback(update('managePf', setModel), []);
  const managePfSelectAll = () => {
    setModel((p) => ({
      ...p,
      managePf: {
        is_managing_pf_firewall: p.managePf.is_managing_pf_firewall,
        ...FirewallService.managePfOptions.reduce(
          (acc, val) => ({
            ...acc,
            [val.serverField]: true,
          }),
          {},
        ),
      },
    }));
  };

  const managePfDeselectAll = () => {
    setModel((p) => ({
      ...p,
      managePf: {
        is_managing_pf_firewall: p.managePf.is_managing_pf_firewall,
        ...FirewallService.managePfOptions.reduce(
          (acc, val) => ({
            ...acc,
            [val.serverField]: false,
          }),
          {},
        ),
      },
    }));
  };

  const itemConfig = config.Firewall;

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={firewallService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <General
        settings={model.general}
        update={generalUpdate}
        isDisabled={pageState.isDisabled}
      />
      {model.general.EnableFirewall && (
        <Logging
          settings={model.logging}
          update={loggingUpdate}
          isDisabled={pageState.isDisabled}
        />
      )}
      {featureFlags.getFlag('lit_05162022_manage-pf-firewall') && (
        <ManagePf
          settings={model.managePf}
          isDisabled={pageState.isDisabled}
          update={managePfUpdate}
          selectAll={managePfSelectAll}
          deselectAll={managePfDeselectAll}
        />
      )}
    </LibraryItemPage>
  );
};

// FirewallPage.propTypes = {};

const Firewall = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useFirewallService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <FirewallPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="firewall-v2"
    />
  );
};

export default Firewall;
