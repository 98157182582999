/* istanbul ignore file */
import { blueprintLibraryCategories } from 'features/blueprints/common';
import DeviceControl from 'features/library-items/items/DeviceControl';
import Avert from 'src/features/library-items/items/Avert';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  osRequirements,
  publishers,
} from '../../common';
import svgs from '../icons';
import LibraryItemConfig from './item-config.class';

const securityApps = {
  Avert: new LibraryItemConfig({
    name: 'Avert',
    description: `Define threat posture toward Malware and PUP and optionally customize what applications or files are allowed or blocked.`,
    type: apiTypes.THREAT_SECURITY_POLICY,
    publisher: publishers.KANDJI,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_15],
    category: categories.SECURITY,
    blueprintCategory: blueprintLibraryCategories.SECURITY,
    icon: svgs.Avert,
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'avert',
    getUrl: ({ id }) => `/library/avert/${id}`,
    getAddUrl: () => `/library/avert/add`,
    component: Avert,
    isHidden: ({ account }) =>
      !account.currentCompany?.feature_configuration?.edr?.enabled,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
  DeviceControl: new LibraryItemConfig({
    name: 'Accessory & Storage Access',
    description:
      'Manage and configure removable storage, including DMG file types, external volumes, and accessory access controls for macOS devices.',
    type: apiTypes.DEVICE_CONTROL,
    publisher: publishers.KANDJI,
    devices: [deviceTypes.MAC],
    osRequirements: [osRequirements.MAC_10_15],
    category: categories.SECURITY,
    blueprintCategory: blueprintLibraryCategories.SECURITY,
    icon: svgs['Media Access'],
    canAdd: canAddLibraryTypes.PREDEFINED_BY_FRONTEND,
    identifier: '',
    routeType: 'device-control',
    getUrl: ({ id }) => `/library/device-control/${id}`,
    getAddUrl: () => `/library/device-control/add`,
    component: DeviceControl,
    isHidden: ({ account }) =>
      !account.currentCompany?.feature_configuration?.edr?.enabled,
    isOldPath: () => false,
    singleBlueprintAllowed: true,
  }),
};

export default securityApps;
