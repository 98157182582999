// `curve` appears to be unused, but it is used by `line`
import { curveStep, line } from 'd3';
import type { ElkExtendedEdge } from 'elkjs/lib/elk.bundled';
import ELK from 'elkjs/lib/elk.bundled';
import type { Edge, Node, ReactFlowState } from 'reactflow';
import { useNodesInitialized, useReactFlow, useStore } from 'reactflow';
/* istanbul ignore file - the majority of this code was provided by React Flow Pro with some modifications */
import { useShallow } from 'zustand/react/shallow';

import { EDGE_TYPES, NODE_TYPES } from '../constants';
import useBlueprintFlow from '../store';

const nodeCountSelector = (state: ReactFlowState) => state.nodeInternals.size;

function useLayout() {
  const [isEditingAssignments, validationErrors, setIsLoadingMap] =
    useBlueprintFlow(
      useShallow((state) => [
        state.isEditingAssignments,
        state.validationErrors,
        state.setIsLoadingMap,
      ]),
    );

  const nodeCount = useStore(nodeCountSelector);
  const nodesInitialized = useNodesInitialized();

  const { getNodes, getEdges, setNodes, setEdges, fitView } = useReactFlow();

  const elk = new ELK();

  const GAP_BETWEEN_ASSIGNMENT_NODES = 10;

  // Takes an Elk edge and returns an SVG path string
  const toSvgPath = ({
    elkEdge,
    edgeType,
  }: {
    elkEdge: ElkExtendedEdge;
    edgeType: string;
  }) => {
    const { sections } = elkEdge;
    return sections?.map((section) => {
      const { startPoint, bendPoints, endPoint } = section;

      // Root edges should always be a straight, horizontal line. To accomplish this, use the x, y coordinates of
      // the startpoint and the x coordinate of the endpoint, and the y coordinate of the startpoint.
      if (edgeType === EDGE_TYPES.root) {
        const data = [startPoint, { x: endPoint.x, y: startPoint.y }];
        return line()
          .curve(curveStep)
          .x((d) => d.x)
          .y((d) => d.y)(data);
      }

      // All other edges should use the provided start, bend, and end points
      const data = [startPoint, ...(bendPoints || []), endPoint];
      return line()
        .curve(curveStep)
        .x((d) => d.x)
        .y((d) => d.y)(data);
    });
  };

  const getPortOffset = ({
    node,
    index,
    peers,
  }: {
    node: Node;
    index: number;
    peers: Node[];
  }) => {
    // The 'else' Assignment node needs the port to be slighly vertically
    // offset to account for the 'add else if' button (only present when editing the AM)
    if (index === peers.length - 1 && isEditingAssignments) {
      return 18;
    }

    // The port should be slightly offset to account for the validation error message
    if (validationErrors?.assignmentNodesWithoutRules.includes(node.id)) {
      return -10;
    }

    return 0;
  };

  const getLayoutedNodes = async (nodes: Node[], edges: Edge[]) => {
    const graph = {
      id: 'root',
      layoutOptions: {
        'elk.algorithm': 'layered',
        'elk.direction': 'RIGHT',

        // Left-aligns nodes in the same layer
        'elk.layered.layering.strategy': 'LONGEST_PATH_SOURCE',
        'elk.layered.nodePlacement.strategy': 'SIMPLE',
        'elk.edgeRouting': 'ORTHOGONAL',

        // Spacing between nodes horizontally
        'elk.layered.spacing.nodeNodeBetweenLayers': '100',

        // Minimum spacing between nodes and edges in a layer (essentially increases height in this graph)
        'elk.spacing.edgeNode': '75',

        // Minumum spacing between parent nodes
        'elk.spacing.nodeNode': '100',

        // Enable the inclusion of children when compiling the layout
        'elk.hierarchyHandling': 'INCLUDE_CHILDREN',
      },
      children: nodes.map((n: Node) => {
        const targetPorts = [{ id: `${n.id}-t`, properties: { side: 'WEST' } }];
        const sourcePorts = [{ id: `${n.id}-s`, properties: { side: 'EAST' } }];

        // Assign ports to the children nodes, if there are any:
        // @ts-expect-error -- `children` is not a valid property of a React Flow `Node`, but it is for Elk
        const nodeChildren = n?.children?.map((child: Node, index: number) => ({
          ...child,
          ports: [
            {
              id: `${child.id}-s`,
              properties: {
                side: 'EAST',
                'elk.port.anchor':
                  // @ts-expect-error -- `children` is not a valid property of a React Flow `Node`, but it is for Elk
                  `0, ${getPortOffset({
                    node: child,
                    index,
                    peers: n.children,
                  })}`,
              },
            },
          ],
        }));

        return {
          id: n.id,
          width: n.width,
          height: n.height,
          ports: [...targetPorts, ...sourcePorts],
          children: nodeChildren,
          properties: {
            portConstraints: 'FIXED_ORDER',
            'layered.crossingMinimization.forceNodeModelOrder': 'true',
            'elk.portAlignment.default': 'CENTER',

            // Padding between the parent node (Conditional) and its children (Assignments)
            'elk.padding': '[top=20.0,left=18.0,bottom=0.0,right=25.0]',

            // Gap between Assignment nodes within a Conditional node
            'elk.spacing.nodeNode': `${GAP_BETWEEN_ASSIGNMENT_NODES}`,
          },
        };
      }),
      edges: edges.map((e: Edge) => ({
        id: e.id,
        sources: [`${e.source}-s`],
        targets: [`${e.target}-t`],
      })),
    };

    const layoutedGraph = await elk.layout(graph);

    const layoutedNodes = nodes.map((node) => {
      const elkNode = layoutedGraph.children?.find((n) => n.id === node.id);

      const layoutedChildren = elkNode?.children?.map((child, idx) => ({
        ...child,
        position: {
          x: elkNode?.children?.[idx]?.x ?? 0,
          y: elkNode?.children?.[idx]?.y ?? 0,
        },
      }));

      return {
        ...node,
        position: {
          x: elkNode?.x || 0,
          y: elkNode?.y || 0,
        },
        children: layoutedChildren,
      };
    });

    const layoutedEdges = edges.map((edge) => {
      const elkEdge = layoutedGraph.edges?.find((e) => e.id === edge.id);

      return {
        ...edge,
        path: toSvgPath({ elkEdge, edgeType: edge.type }),
        startPoint: elkEdge.sections[0].startPoint,
        endPoint: elkEdge.sections[0].endPoint,
      };
    });

    return { layoutedNodes, layoutedEdges };
  };

  const flattenChildNodes = (nodes) => {
    const flatNodes = [];

    nodes.forEach((node) => {
      flatNodes.push(node);

      if (node.children) {
        node.children?.forEach((child) => flatNodes.push(child));
      }
    });

    return flatNodes;
  };

  const transformToElkNodes = (nodes) => {
    const nodesById: { [key: string]: any } = nodes.reduce((acc, node) => {
      acc[node.id] = node;
      return acc;
    }, {});

    nodes.forEach((node) => {
      if (node.parentNode) {
        // Add this node to the children of the parent:
        nodesById[node.parentNode].children = [
          ...(nodesById[node.parentNode].children || []),
          node,
        ];
      }
    });

    // Calculate the dimensions of the Conditional nodes based on the size of the children:
    // This leaves enough room for the delete button at the bottom of the container
    const PARENT_CONTAINER_HEIGHT_PADDING = isEditingAssignments ? 65 : 40;
    const PARENT_CONTAINER_WIDTH_PADDING = 35;
    Object.values(nodesById).forEach((node) => {
      if (node.children) {
        const PARENT_CONTAINER_GAP_BETWEEN_CHILDREN =
          GAP_BETWEEN_ASSIGNMENT_NODES * (node.children.length - 1);

        nodesById[node.id].height =
          node.children.reduce((acc, child) => acc + child.height, 0) +
          PARENT_CONTAINER_HEIGHT_PADDING +
          PARENT_CONTAINER_GAP_BETWEEN_CHILDREN;

        nodesById[node.id].width =
          node.children[0].width + PARENT_CONTAINER_WIDTH_PADDING;
      }
    });

    return Object.values(nodesById).filter((n) => !n.parentNode);
  };

  const runLayout = async (isFitView = false) => {
    if (nodeCount > 0 && nodesInitialized) {
      const currentNodes = getNodes();
      const elkNodes = transformToElkNodes(currentNodes);

      const { layoutedNodes, layoutedEdges } = await getLayoutedNodes(
        elkNodes,
        getEdges(),
      );

      const flatNodes = flattenChildNodes(layoutedNodes);
      const flatNodesById: { [key: string]: any } = flatNodes.reduce(
        (acc, node) => {
          acc[node.id] = node;
          return acc;
        },
        {},
      );

      setEdges((prevEdges) =>
        prevEdges.map((prevEdge) => {
          const edge = prevEdge;

          const layoutedEdgeData = layoutedEdges?.find((e) => e.id === edge.id);

          return {
            ...edge,
            data: {
              ...edge.data,
              path: layoutedEdgeData?.path,
              startPoint: layoutedEdgeData?.startPoint,
              endPoint: layoutedEdgeData?.endPoint,
            },
          };
        }),
      );

      setNodes((prevNodes) =>
        prevNodes.map((prevNode) => {
          const node = prevNode;
          const foundElkNode = flatNodesById[node.id];

          const x = foundElkNode?.position?.x || 0;
          const y = foundElkNode?.position?.y || 0;

          const calculatedDimensions = {
            width: foundElkNode?.width || node.width,
            height: foundElkNode?.height || node.height,
          };

          return {
            ...node,
            position: {
              x: x + calculatedDimensions.width / 2,
              y: y + calculatedDimensions.height / 2,
            },
            data: {
              ...node.data,
              parentNode: node.parentNode,

              // Assignment nodes should not render until their parent has their position
              ...(node.type === NODE_TYPES.assignment && {
                parentNodePosition: flatNodesById[node.parentNode]?.position,
              }),

              // Conditional nodes need to know their calcuated width and height
              // to render large enough to house their children
              ...(node.type === NODE_TYPES.conditional && {
                width: calculatedDimensions.width,
                height: calculatedDimensions.height,
              }),
            },
          };
        }),
      );

      if (isFitView) {
        fitView({
          maxZoom: 1,
          duration: 250,
        });
      }

      setIsLoadingMap(false);
    }
  };

  return { runLayout };
}

export default useLayout;
