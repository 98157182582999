import { useEffect, useState } from 'react';
import { libraryItemService } from './library-item-service';

const noop = (v) => v;

/**
 * @typedef { import("src/features/library-items/data-service/library-item/new-library-item-service").default } NewLibraryItemServiceType
 * @typedef { import("src/features/library-items/data-service/library-item/library-item-service").default } LibraryItemServiceType
 */

/**
 * @param {string} id
 * @param {Function} transformer
 * @param {unknown[]} watch
 * @param {LibraryItemServiceType | NewLibraryItemServiceType} service
 * @returns
 */
const useLibraryService = (
  id,
  transformer = noop,
  watch = [],
  service = libraryItemService,
) => {
  const [err, setErr] = useState();
  const [resp, setResp] = useState();

  useEffect(() => {
    if (id) {
      service
        .get(id)
        .then(async (r) => {
          const transformedData = await transformer(r.data);
          const apiResp = {
            ...r,
            data: transformedData,
          };

          setResp(apiResp);
        })
        .catch((error) => {
          console.log(error);
          setErr(error);
        });
    }
  }, [id, ...watch]);

  return [err, resp];
};

export { useLibraryService };
