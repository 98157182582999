import {
  Breadcrumb,
  Button,
  Checkbox,
  Chip,
  Flex,
  Icon,
  Tabs,
  TextInput,
} from '@kandji-inc/bumblebee';
import { DATE_FORMAT, useDateFormat } from 'app/components/common/helpers';
import HubSpotHandler from 'components/common/hubspot-handler';
import { InterfaceContext } from 'contexts/interface';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import Loader from '../../../theme/components/atoms/Loader';
import { useGetTokenDetails } from '../api-token-service/api-token-hooks';
import { newTokenService } from '../api-token-service/api-token-service';
import transformer, {
  transformToApi,
} from '../api-token-service/token-transformer/token-transformer';
import { DeleteModal } from '../modals';
import EmptyBeehive from './empty-beehive.svg';
import LockWithKey from './lock-with-key.svg';
import PermissionGroup from './permission-group';
import './permissions.css';
import TokenPermissionsActivity from './token-permissions-activity/token-permissions-activity';

const DATE_FORMAT_MAPPING = {
  [DATE_FORMAT.options[0]]: {
    order: [0, 1, 2],
    punctuation: ['.', ',', ''],
    momentFormat: 'On MMMM Do, YYYY',
  },
  [DATE_FORMAT.options[1]]: {
    order: [1, 0, 2],
    punctuation: ['', '.', ''],
    momentFormat: 'On Do MMMM YYYY',
  },
  [DATE_FORMAT.options[2]]: {
    order: [2, 0, 1],
    punctuation: [',', '.', ''],
    momentFormat: 'On YYYY, MMMM Do',
  },
};

const apiTokenService = newTokenService();
const TokenPermissions = ({ dateFormat }) => {
  const MAX_TITLE_LEN = 32;
  const MAX_DESC_LEN = 250;
  const match = useRouteMatch();
  const history = useHistory();
  const withState = history.location.state;
  const firstTimeConfiguring = withState && withState.inEditMode;
  const { sidebarDocked } = useContext(InterfaceContext);
  const [err, tokenResp] = useGetTokenDetails(match.params.id, transformer);
  const [isEditing, setIsEditing] = useState(firstTimeConfiguring);
  const [data, setData] = useState({
    original: {},
    altered: {},
  });
  const [showEnabledPermissionsOnly, setShowEnabledPermissionsOnly] = useState(
    !firstTimeConfiguring,
  );
  const [filterKey, setFilterKey] = useState('');
  const [collapsedSubgroups, setCollapsedSubgroups] = useState({});
  const [isRevoking, setIsRevoking] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const dateFormattingProps = DATE_FORMAT_MAPPING[dateFormat];

  useEffect(() => {
    if (tokenResp && tokenResp.data) {
      setData({
        original: tokenResp.data,
        altered: cloneDeep(tokenResp.data),
      });
    }
  }, [tokenResp]);

  const onSaveToken = (d) => {
    setIsSaving(true);
    apiTokenService
      .patch(d.id, transformToApi(d))
      .then((r) => {
        const transformed = transformer(r.data);
        setData({
          original: transformed,
          altered: cloneDeep(transformed),
        });
        setIsEditing(false);
      })
      .catch((e) => {
        console.error(`Unable to patch token with id ${d.id}: ${e}`);
      })
      .finally(() => {
        // When saving, showEnabledPermissions should be enabled again.
        setIsSaving(false);
        setShowEnabledPermissionsOnly(true);
        if (firstTimeConfiguring) {
          // Clear the history state after done configuring for first time.
          history.replace();
        }
      });
  };

  /**
   * When enabling edit mode, ensure showing all permissions, not only enabled.
   */
  const onEdit = () => {
    setIsEditing(true);
    setShowEnabledPermissionsOnly(false);
  };

  /**
   *
   * @param {String} group - The group to collapse (ex. Devices).
   * @param {String} subgroup - The subgroup of the group (ex. Device
   * Information)
   * @param {Boolean} collapseAll - When group and subgroup are null,
   * collapseAll indicates whether to collapse all subgroups or expand.
   */
  const onCollapse = (group, subgroup, collapseAll) => {
    setCollapsedSubgroups((prev) => {
      if (!group && !subgroup) {
        return Object.keys(data.original.permissions).reduce(
          (accGroup, currGroup) => ({
            ...accGroup,
            ...Object.keys(data.original.permissions[currGroup]).reduce(
              (accSubgroup, currSubgroup) => ({
                ...accSubgroup,
                [`${currGroup}_${currSubgroup}`]: collapseAll,
              }),
              {},
            ),
          }),
          {},
        );
      }

      const id = `${group}_${subgroup}`;
      return {
        ...prev,
        [id]: !prev[id],
      };
    });
  };

  /**
   *
   * @param {string} group - The name of the group ex. Devices
   * @param {string} subgroup - The name of the subgroup ex. Device Information
   * @param {string} id - The id of the specific api. Set to null to impact all
   *  apis.
   * @param {boolean} allAllowed - If id is set to null, all permissions will be
   * set to this value.
   */
  const onToggle = (group, subgroup, id, allAllowed) => {
    setData((prev) => {
      const alteredSubgroup = prev.altered.permissions[group][subgroup];
      alteredSubgroup.forEach((perm, idx) => {
        if (!id) {
          alteredSubgroup[idx].allowed = allAllowed;
        } else if (perm.id === id) {
          alteredSubgroup[idx].allowed = !alteredSubgroup[idx].allowed;
        }
      });

      return { ...prev };
    });
  };

  /**
   * Filters the entire data object's permissions field.
   * @param {Object} dataToFilter - The data to filter. This is going to either be
   * data.original or data.altered.
   * @param {String} key - The keyword to filter by.
   * @param {Boolean} onlyEnabledPerms - Show only enabled permissions.
   */
  const filterData = (dataToFilter, key = '', onlyEnabledPerms = false) => {
    const { permissions } = dataToFilter;

    if (!permissions) {
      return dataToFilter;
    }

    const filteredData = Object.keys(permissions).reduce(
      (accGroup, currGroup) => {
        // The subgroups within a group (ex. Devices).
        const subgroups = permissions[currGroup];

        // The group data that contains the list of subgroups and their api
        // permissions.
        const groupData = Object.keys(subgroups).reduce(
          (accSubGroup, currSubGroup) => {
            // The subgroup (ex. Device Information) and its set of permissions
            // (filtered).
            const subgroup = subgroups[currSubGroup];
            const filtered = subgroup.filter((perm) => {
              const doesInclude = (txt) =>
                txt.toLowerCase().includes(key.toLowerCase());
              // Determine whether or not fields in the api for this subgroup contain the key.
              const isFoundInApi =
                doesInclude(perm.name) ||
                doesInclude(perm.http_method) ||
                doesInclude(perm.path) ||
                doesInclude(perm.description);

              return onlyEnabledPerms
                ? perm.allowed && isFoundInApi
                : isFoundInApi;
            });

            // Add the isCollapsed property to the filtered subgroup.
            filtered.isCollapsed =
              collapsedSubgroups[`${currGroup}_${currSubGroup}`];
            return {
              ...accSubGroup,
              ...(filtered.length ? { [currSubGroup]: filtered } : {}),
            };
          },
          {},
        );

        return {
          ...accGroup,
          ...(Object.keys(groupData).length ? { [currGroup]: groupData } : {}),
        };
      },
      {},
    );

    return {
      ...dataToFilter,
      permissions: filteredData,
    };
  };

  /**
   * Returns the given date in the format of `Mon. Day, Year`
   * @param {String} aDate - A stringified date ex. 2021-01-29T09:11:54.296304Z
   */
  const transformDate = (aDate) => {
    const { order, punctuation } = dateFormattingProps;
    const date = new Date(aDate).toDateString().split(' ').slice(1);
    const parts = [0, 1, 2].map((i) => `${date[order[i]]}${punctuation[i]}`);
    return parts.join(' ');
  };

  const formatLastAccessed = (lastAccessed, lastIp) => {
    const { momentFormat } = dateFormattingProps;
    const sep = lastAccessed && lastIp ? '/' : '';
    return `${
      lastAccessed ? moment(lastAccessed).format(momentFormat) : ''
    }${sep}${lastIp || ''}`;
  };

  // Are any permissions configured under this token? Use data.original to avoid
  // filtered data setting this to an incorrect value.
  const hasAnyConfigured =
    data.original.permissions &&
    Object.keys(data.original.permissions).some(
      (group) =>
        Object.values(data.original.permissions[group])
          .flat()
          .filter((perm) => perm.allowed).length,
    );

  // The active data being used based on whether permissions are being edited.
  const activeFilterData = filterData(
    isEditing ? data.altered : data.original,
    filterKey,
    showEnabledPermissionsOnly,
  );

  // An array of all the subgroups collapsed status's.
  const groupsCollapsedStatus = activeFilterData.permissions
    ? Object.keys(activeFilterData.permissions)
        .map((g) =>
          Object.keys(activeFilterData.permissions[g]).map(
            (sg) => !!activeFilterData.permissions[g][sg].isCollapsed,
          ),
        )
        .flat()
    : [];

  const totalLengths = useMemo(
    () =>
      Object.entries(data.original.permissions || {}).reduce(
        (groupRes, [groupKey, groupData]) => ({
          ...groupRes,
          [groupKey]: Object.entries(groupData).reduce(
            (subgroupRes, [subgroupKey, subgroupData]) => ({
              ...subgroupRes,
              [subgroupKey]: subgroupData.length,
            }),
            {},
          ),
        }),
        {},
      ),
    [data.original.permissions],
  );

  if (err) {
    return <Redirect to="/my-company/access" />;
  }

  if (!tokenResp || !Object.keys(activeFilterData).length) {
    return <Loader type="line" />;
  }

  return (
    <>
      <HubSpotHandler />
      <div
        style={{ marginBottom: '102px' }}
        className="token-permissions-container"
      >
        <section style={{ padding: '35px 0 23px 0' }}>
          <Breadcrumb
            crumbs={[
              { title: 'ACCESS', url: '/my-company/access' },
              { title: activeFilterData.label },
            ]}
          />
        </section>
        <div className="l-card">
          <div className="l-card-title l-card-content token-permission-header">
            <img src={LockWithKey} alt="" />
            {isEditing ? (
              <input
                type="text"
                className={`l-title-input b-txt ${
                  !activeFilterData.label.length ? 'l-title-input__error' : ''
                }`}
                onChange={(e) => {
                  const v = e.target.value;
                  if (v.length <= MAX_TITLE_LEN) {
                    setData((prev) => ({
                      ...prev,
                      altered: { ...prev.altered, label: v },
                    }));
                  }
                }}
                value={activeFilterData.label}
                name={activeFilterData.label}
                placeholder="Add name of token"
              />
            ) : (
              <h1 className="b-h1">{activeFilterData.label}</h1>
            )}
            <Chip
              text="Active"
              kind="active"
              className="b-txt-ctrl8"
              style={{ justifySelf: 'flex-end' }}
            />
          </div>

          <div className="l-card-content token-permission-sub-header">
            <div className="b-pos-rel token-permission-sub-header-col1">
              <label
                className={`${isEditing ? 'b-txt-light' : 'b-txt-bold'}`}
                htmlFor="token-description"
              >
                {isEditing ? 'Description (Optional)' : 'API Token'}
              </label>
              {isEditing ? (
                <>
                  <TextInput
                    textArea
                    name="token-description"
                    value={activeFilterData.description || ''}
                    onChange={(e) => {
                      const v = e.target.value;
                      if (v.length <= MAX_DESC_LEN) {
                        setData((prev) => ({
                          ...prev,
                          altered: { ...prev.altered, description: v },
                        }));
                      }
                    }}
                  />
                  {/* <Flex
                    className="b-txt-ctrl5"
                    style={{ width: '100%' }}
                    justify="flex-end"
                  >{`${
                    (activeFilterData.description &&
                      activeFilterData.description.length) ||
                    0
                  } / ${MAX_DESC_LEN}`}</Flex> */}
                </>
              ) : (
                <p className="b-txt l-description">
                  {activeFilterData.description}
                </p>
              )}
            </div>
            <section className="token-permissions-description-grid">
              <div className="permissions-description b-txt-light">
                Token ID:
              </div>
              <div className="b-txt">{activeFilterData.id}</div>

              <div className="permissions-description b-txt-light">
                Creator:
              </div>
              <div className="b-txt">{activeFilterData.created_by}</div>

              <div className="permissions-description b-txt-light">
                Creation date:
              </div>
              <div className="b-txt">
                {transformDate(activeFilterData.created_at)}
              </div>

              <div className="permissions-description b-txt-light">
                Last Accessed/IP:
              </div>
              <div className="b-txt">
                {formatLastAccessed(
                  activeFilterData.last_used_at,
                  activeFilterData.last_used_ip,
                )}
              </div>
            </section>
          </div>
        </div>

        <Tabs
          className="token-permissions_tabs"
          tabs={[
            { label: 'Permissions' },
            { label: 'Activity', route: 'activity' },
          ]}
        >
          <div tabid="Permissions">
            {(isEditing || hasAnyConfigured) && (
              <>
                <div className="l-card">
                  <div
                    className={`token-permission-expand-buttons-wrapper${
                      firstTimeConfiguring || !isEditing
                        ? ' token-permissions-wrapper-is-editing'
                        : ''
                    }`}
                  >
                    {!firstTimeConfiguring && isEditing && (
                      <Flex align="center">
                        <Checkbox
                          className="b-txt"
                          name="Show enabled permissions"
                          checked={showEnabledPermissionsOnly}
                          onChange={() =>
                            setShowEnabledPermissionsOnly((prev) => !prev)
                          }
                        />
                        <label
                          htmlFor="Show enabled permissions"
                          className="b-flex-vc b-txt"
                          style={{ margin: 0 }}
                        >
                          Only show enabled permissions
                        </label>
                      </Flex>
                    )}
                    <div className="b-flex-vc token-permission-expand-buttons">
                      <Button
                        disabled={groupsCollapsedStatus.every((is) => !is)}
                        kind="link"
                        size="small"
                        icon="angle-down"
                        onClick={() => onCollapse(null, null)}
                      >
                        Expand all
                      </Button>
                      <Button
                        disabled={groupsCollapsedStatus.every((is) => is)}
                        kind="link"
                        size="small"
                        icon="angle-up"
                        onClick={() => onCollapse(null, null, true)}
                      >
                        Collapse all
                      </Button>
                    </div>
                    <div className="l-search">
                      <input
                        type="text"
                        name="api-permission-search"
                        placeholder="Search"
                        value={filterKey}
                        onChange={(e) => setFilterKey(e.target.value)}
                        className="b-txt l-search__input"
                      />
                      {filterKey.length ? (
                        <a
                          href=""
                          className="decorate-off"
                          onClick={(e) => {
                            e.preventDefault();
                            setFilterKey('');
                          }}
                        >
                          <Icon
                            name="circle-xmark"
                            className="l-search__icon"
                          />
                        </a>
                      ) : (
                        <Icon
                          name="magnifying-glass"
                          className="l-search__icon"
                        />
                      )}
                    </div>
                  </div>
                </div>

                {Object.keys(activeFilterData.permissions).length ? (
                  <PermissionGroup
                    isReadOnly={!isEditing}
                    data={activeFilterData.permissions}
                    onCollapse={onCollapse}
                    onToggle={onToggle}
                    totalLengths={totalLengths}
                  />
                ) : (
                  <Flex
                    direction="col"
                    justify="center"
                    align="center"
                    style={{ height: '55vh' }}
                    className="permissions-page-hive-container"
                  >
                    <img src={EmptyBeehive} alt="" />
                    <h3 className="b-h3">No results found</h3>
                    <p className="b-txt" style={{ marginBottom: 0 }}>
                      We couldn’t find a match. Try changing the filter, or
                      search with
                    </p>
                    <p className="b-txt" style={{ marginBottom: 0 }}>
                      keywords and request methods.
                    </p>
                  </Flex>
                )}
              </>
            )}

            {!isEditing && !hasAnyConfigured && (
              <Flex
                direction="col"
                justify="center"
                align="center"
                style={{ height: '55vh' }}
                className="permissions-page-hive-container"
              >
                <img src={EmptyBeehive} alt="" />
                <h3 className="b-h3">API Permissions are not set up</h3>
                <p className="b-txt" style={{ marginBottom: 0 }}>
                  Permissions for this API token have not been configured yet.
                </p>
                <p className="b-txt" style={{ marginBottom: 0 }}>
                  You can set them up now by clicking configure below.
                </p>
                <Button kind="link" className="b-txt-ctrl1" onClick={onEdit}>
                  Configure Permissions
                </Button>
              </Flex>
            )}
          </div>

          <TokenPermissionsActivity dateFormat={dateFormat} tabid="Activity" />
        </Tabs>
      </div>

      <Flex className="permissions-page-footer">
        <div
          style={{
            width: sidebarDocked ? '256px' : '77px',
            flexShrink: 0,
            marginRight: 0,
          }}
        />
        <Flex
          justify="space-between"
          className="permissions-page-footer__inner"
        >
          <Button
            kind="link"
            theme="error"
            onClick={() => setIsRevoking(true)}
            disabled={!isEditing}
          >
            Revoke
          </Button>
          <Flex>
            <Button
              kind="outline"
              onClick={() => {
                if (isEditing) {
                  setIsEditing(false);
                  setData((prev) => ({
                    ...prev,
                    altered: cloneDeep(prev.original),
                  }));
                  setShowEnabledPermissionsOnly(true);
                  if (firstTimeConfiguring) {
                    // Clear the history state after done configuring for first time.
                    history.replace();
                  }
                } else {
                  history.push('/my-company/access');
                }
              }}
              disabled={isSaving}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                if (isEditing) {
                  onSaveToken(data.altered);
                } else {
                  onEdit();
                }
              }}
              isProgress={isSaving}
              iconPlacement="right"
              icon={isSaving ? 'arrows-rotate' : ''}
            >
              {isSaving ? 'Saving' : isEditing ? 'Save' : 'Edit'}
            </Button>
          </Flex>
        </Flex>
      </Flex>

      {isRevoking && (
        <DeleteModal
          shouldShow
          onDelete={() =>
            apiTokenService
              .remove(activeFilterData.id)
              .then(() => history.push('/my-company/access'))
              .catch(() => {
                console.error(
                  `Unable to revoke token with id ${data.original.id}`,
                );
                setIsRevoking(false);
              })
          }
          onCancel={() => setIsRevoking(false)}
        />
      )}
    </>
  );
};

TokenPermissions.propTypes = {
  dateFormat: PropTypes.oneOf(DATE_FORMAT.options),
};

TokenPermissions.defaultProps = {
  dateFormat: DATE_FORMAT.default,
};

const TokenPermissionsWithDate = () => {
  const dateFormat = useDateFormat();

  return <TokenPermissions dateFormat={dateFormat} />;
};

export { TokenPermissions, TokenPermissionsWithDate };
