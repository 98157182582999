import { Flex, Text, styled } from '@kandji-inc/nectar-ui';
import { usePrismAIContext } from '../../contexts/PrismAIContext';

export const ChatTemplates = ({
  templates,
  onApplyTemplate,
}: {
  templates: { id: number; title: string; description: string }[];
  onApplyTemplate: (message: string) => void;
}) => {
  const { chatPanelSize } = usePrismAIContext();

  // istanbul ignore next
  const getFlexWrap = () => {
    if (chatPanelSize > 70) {
      return 'no-wrap';
    }
    return 'wrap';
  };

  return (
    <TemplatesContainer
      wrap={getFlexWrap()}
      css={{
        maxWidth:
          /* istanbul ignore next */ chatPanelSize < 70 ? '636px' : '100%',
      }}
    >
      {templates.map((t) => (
        <TemplateBox key={t.id} onClick={() => onApplyTemplate(t.description)}>
          <Text
            css={{
              alignSelf: 'stretch',
              color: '$neutral90',
              fontSize: '$2',
              fontStyle: 'normal',
              fontWeight: '$medium',
              lineHeight: '20px /* 142.857% */',
            }}
          >
            {t.title}
          </Text>
          <Text
            css={{
              alignSelf: 'stretch',
              color: '$neutral70',
              fontSize: '$1',
              fontStyle: 'normal',
              fontWeight: '$regular',
              lineHeight: '16px /* 133.333% */',
            }}
          >
            {t.description}
          </Text>
        </TemplateBox>
      ))}
    </TemplatesContainer>
  );
};

const TemplatesContainer = styled(Flex, {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  alignContent: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  borderRadius: '8px',
  margin: '0 auto',
});

const TemplateBox = styled(Flex, {
  display: 'flex',
  minWidth: '220px',
  maxWidth: '400px',
  padding: '16px 24px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  flex: '1 0 0',
  borderRadius: '8px',
  background: 'var(--Neutral-neutral10, #ECF1F7)',
  cursor: 'pointer',
});
