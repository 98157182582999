import { Hint, dropdownSizeTokens, styled } from '@kandji-inc/nectar-ui';
import type {
  AllowedSelectValue,
  SelectOptionMemberList,
} from '@kandji-inc/nectar-ui';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { LineLoader } from 'app/components/interface/LineLoader';
import { useSidebarToast } from 'src/hooks/useSidebarToast';

import useGetIntegrationUserGroupList from '../../../../ms-device-compliance/hooks/use-get-integration-user-group-list';
import ViewCheckbox from '../../../layouts/partials/view-checkbox';
import ViewHeaderH2 from '../../../layouts/partials/view-header-h2';
import ViewMultiSelect from '../../../layouts/partials/view-multi-select';
import ViewRows from '../../../layouts/partials/view-rows';

import type { IntegrationGroupsResult } from '../../../../ms-device-compliance/types/schema';
import type { CheckboxOption } from '../../../layouts/partials/view-checkbox';

import { StyledContainer as container } from '../../../../../components/styled-components/main';
import { INT_TYPES } from '../../../../../constants';
import useListIntegrations from '../../../../hooks/use-list-integrations';
import useGetIntegrationDetails from '../../../../ms-device-compliance/hooks/use-get-integration-details';

const StyledContainer = styled(container, {
  $$selectMdSize: dropdownSizeTokens.$$md,

  paddingBottom: '$$selectMdSize',
  gap: '$5',
});

const StyledPlatformInfo = styled(container, {
  gap: '$3',
  width: '100%',
});

const StyledTypeaheadContainer = styled('div', {
  paddingLeft: '$5',
});

interface SetupProps {
  data: {
    platformInfo: {
      title: string;
      description: string;
      validationMessage: string;
      list: Array<CheckboxOption>;
    };
  };
}

const Setup = ({ data }: SetupProps) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const { displayToast } = useSidebarToast();

  const { data: integrationId } = useListIntegrations({
    integrationType: INT_TYPES.msDeviceCompliance,
    select: (res) => res?.data?.results?.[0]?.uuid || '',
  });

  useEffect(() => {
    if (!integrationId) {
      displayToast({
        title:
          'Please set up the Microsoft Device Compliance integration first',
        variant: 'error',
      });
    }
  }, [integrationId]);

  const { data: msDomainName } = useGetIntegrationDetails(integrationId, {
    queryOptions: {
      select: (res) => res?.data?.ms_domain_name || '',
    },
  });

  const { platformInfo } = data;

  const formInputs = getValues();
  const validateInputs = !!(formInputs.macOsDevices || formInputs.iosDevices);
  const showMessage =
    !!(errors.macOsDevices || errors.iosDevices) && !validateInputs;

  const [groupOptions, setUserGroupOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const accountInfo = [
    {
      name: 'Microsoft domain',
      value: msDomainName || '',
    },
  ];

  useGetUserGroupList({ setUserGroupOptions, setIsLoading });

  return isLoading ? (
    <LineLoader />
  ) : (
    <StyledContainer>
      <ViewRows list={accountInfo} />

      <StyledPlatformInfo>
        <ViewHeaderH2
          title={platformInfo.title}
          description={platformInfo.description}
        />

        {platformInfo.list.map((option: CheckboxOption) => (
          <React.Fragment key={option.name}>
            <ViewCheckbox
              options={[
                {
                  ...option,
                  validations: { validate: () => validateInputs },
                },
              ]}
            />
            <SelectUserGroup field={option.name} options={groupOptions} />
          </React.Fragment>
        ))}

        {showMessage && (
          <Hint variant="error" label={platformInfo.validationMessage} />
        )}
      </StyledPlatformInfo>
    </StyledContainer>
  );
};

interface Props {
  field: string;
  defaultOptions: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
}

const SelectUserGroup = ({ field, defaultOptions }: Props) => {
  const { watch, getValues } = useFormContext();
  const [searchOptions, setUserGroupOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const [searchTerm, setSearchTerm] = useState('');

  watch([field]);
  const formInputs = getValues();

  useGetUserGroupList({ setUserGroupOptions, searchTerm });

  return (
    <StyledTypeaheadContainer>
      {formInputs[field] && (
        <ViewMultiSelect
          label={
            field === 'macOsDevices' ? 'macOS user group ' : 'iOS user group'
          }
          defaultValue={defaultOptions}
          options={searchOptions}
          handleChange={setSearchTerm}
          debounceTime={500}
          name={`${field}_groups`} // macOsDevices_groups or iosDevices_groups
          validations={{ required: 'Please select a user group' }}
        />
      )}
    </StyledTypeaheadContainer>
  );
};

export default Setup;

function useGetUserGroupList({
  setUserGroupOptions,
  setIsLoading,
  searchTerm,
}: {
  setUserGroupOptions: (
    arg: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>,
  ) => void;
  setIsLoading?: (a: boolean) => void;
  searchTerm?: string;
}) {
  const { data: userGroupList, isLoading } =
    useGetIntegrationUserGroupList(searchTerm);

  useEffect(() => {
    setIsLoading ? setIsLoading(isLoading) : null;
    if (userGroupList) {
      /* istanbul ignore next */
      const results = userGroupList?.data?.results || [];

      setUserGroupOptions(
        results.map((r: IntegrationGroupsResult) => ({
          label: `${r.name} (${r.integration_name})`,
          value: `${r.id}|${r.external_id}`,
        })),
      );
    }
  }, [userGroupList, isLoading]);
}
