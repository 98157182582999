import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AppLockService extends NewLibraryItemService {
  static appOptionsMap = {
    assistiveTouch: {
      label: 'Assistive touch',
      serverField: 'EnableAssistiveTouch',
      defaultValue: false,
      needsInvert: false,
    },
    autoLock: {
      label: 'Auto lock',
      serverField: 'DisableAutoLock',
      defaultValue: true,
      needsInvert: true,
    },
    deviceRotation: {
      label: 'Device rotation',
      serverField: 'DisableDeviceRotation',
      defaultValue: true,
      needsInvert: true,
    },
    invertColors: {
      label: 'Invert colors',
      serverField: 'EnableInvertColors',
      defaultValue: false,
      needsInvert: false,
    },
    monoAudio: {
      label: 'Mono audio',
      serverField: 'EnableMonoAudio',
      defaultValue: false,
      needsInvert: false,
    },
    ringerSwitch: {
      label: 'Ringer switch',
      serverField: 'DisableRingerSwitch',
      defaultValue: true,
      needsInvert: true,
    },
    sleepWakeButton: {
      label: 'Sleep/wake button',
      serverField: 'DisableSleepWakeButton',
      defaultValue: true,
      needsInvert: true,
    },
    speakSelection: {
      label: 'Speak selection',
      serverField: 'EnableSpeakSelection',
      defaultValue: false,
      needsInvert: false,
    },
    touch: {
      label: 'Touch',
      serverField: 'DisableTouch',
      defaultValue: true,
      needsInvert: true,
    },
    voiceControl: {
      label: 'Voice control',
      serverField: 'EnableVoiceControl',
      defaultValue: false,
      needsInvert: false,
    },
    voiceOver: {
      label: 'Voice over',
      serverField: 'EnableVoiceOver',
      defaultValue: false,
      needsInvert: false,
    },
    volumeButtons: {
      label: 'Volume buttons',
      serverField: 'DisableVolumeButtons',
      defaultValue: true,
      needsInvert: true,
    },
    zoom: {
      label: 'Zoom',
      serverField: 'EnableZoom',
      defaultValue: false,
      needsInvert: false,
    },
  };

  static userOptionsMap = {
    assistiveTouch: {
      label: 'Assistive touch',
      serverField: 'AssistiveTouch',
    },
    invertColors: {
      label: 'Invert colors',
      serverField: 'InvertColors',
    },
    voiceControl: {
      label: 'Voice control',
      serverField: 'VoiceControl',
    },
    voiceOver: {
      label: 'Voice over',
      serverField: 'VoiceOver',
    },
    zoom: {
      label: 'Zoom',
      serverField: 'Zoom',
    },
  };
}

export const appLockService = new AppLockService();

export default AppLockService;
