import { Banner, updateSetting as update } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useCallback } from 'react';

import { useSsInstall } from 'features/library-items/template';

import useUniqValue from '../../../../components/common/hooks/use-uniq-value/use-uniq-value';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithoutDefaultsPreset from '../../common/self-service-card/drawer/presets/without-defaults-preset';
import ActivityTab from './activity-tab';
import defaultIcon from './cs-library.png';
import ExecutionFrequency from './execution-frequency';
import initialState from './initial-state';
import ScriptDetailsCard from './script-details-card';
import CustomScriptService, {
  customScriptService,
} from './service/custom-script-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useCustomScriptService from './service/use-custom-script-service';

const CustomScriptPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const updateInstallation = useCallback(
    update('executionFrequency', setModel),
    [],
  );
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateDetails = useCallback(update('scriptDetails', setModel), []);

  const isSsForced = useSsInstall({
    installType: model.executionFrequency.type,
    ssType: CustomScriptService.executionFrequencyTypes.NO_ENFORCEMENT,
    updateSs,
  });

  return (
    <LibraryItemPage
      {...props}
      askFlush={() =>
        model.executionFrequency.type ===
        CustomScriptService.executionFrequencyTypes.ONCE
      }
      crumb={model.name || 'Custom Script'}
      summaryInfoProps={{
        name: 'Custom Script',
        description:
          'Run any type of script supported by macOS. Choose to run once per device or continuously, and add an optional remediation script that can be run when needed. If you do not specify a shell or interpreter, scripts will run in shell (/bin/sh).',
        publisher: 'Kandji, Inc.',
        devices: ['Mac'],
        requirements: ['macOS 10.11+'],
      }}
      extraSummary={
        <Banner theme="warning" kind="block" className="b-mt1">
          <p>
            <strong style={{ fontWeight: 500 }}> WARNING: </strong> This is a
            very powerful feature. All scripts run as root. Test your scripts
            extensively before deploying to production machines.
          </p>
        </Banner>
      }
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={customScriptService}
      isIconEditable
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={defaultIcon}
      supportsRules
      supportsDuplication
    >
      <ExecutionFrequency
        setting={model.executionFrequency}
        update={updateInstallation}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        isSubmitted={pageState.isSubmitted}
      />
      <SelfServiceCard
        setting={model.selfService}
        update={updateSs}
        isDisabled={pageState.isDisabled}
        defaults={{
          icon: model.icon || defaultIcon,
          name: model.name,
        }}
        DrawerContent={WithoutDefaultsPreset}
        canBeDisabled={!isSsForced}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
      />
      <ScriptDetailsCard
        setting={model.scriptDetails}
        update={updateDetails}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const CustomScript = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useCustomScriptService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <CustomScriptPage {...pageProps} />;
};

export default CustomScript;
