import {
  TextInput,
  onEmptyBlurValidator,
  Toaster as toaster,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import { Banner, styled } from '@kandji-inc/nectar-ui';
import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import FooterButtons from 'src/features/integrations/components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledHeaderImage,
} from 'src/features/integrations/components/styled-components';
import FooterStatus from '../../../../../components/footer-status/footer-status';
import { useGetOktaDeviceTrust } from '../../../hooks';
import useUpdateOktaDeviceTrust from '../../../hooks/use-update-okta-device-trust';
import footerIcon from '../../../modals/assets/okta-small.svg';
import type { FooterStatusState, ODTIntegration } from '../../../types';
import {
  handleOktaValidation,
  validationLimit,
} from '../../../utils/validation';
import settingsCog from '../../assets/settings-cog.svg';

type Props = {
  onClose: () => void;
  integrationData: ODTIntegration;
};

const StyledTexInputContainer = styled('div', {
  display: 'grid',
  gap: '$5',
  width: '360px',
  textAlign: 'left',
  marginTop: '$5',
});

const HeaderContainer = styled('div', {
  display: 'grid',
  gap: '$3',
});

type IntegrationSettingValues = {
  domain: string;
  clientId: string;
  clientSecret?: string;
};

const EditIntegrationSettings = (props: Props) => {
  const { onClose, integrationData } = props;
  const [integrationSettingValues, setIntegrationSettingValues] =
    useState<IntegrationSettingValues>({
      domain: '',
      clientId: '',
      clientSecret: '',
    });
  const [footerStatusState, setFooterStatusState] =
    useState<FooterStatusState>();

  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });

  const { getOktaDeviceTrustDetails } = useGetOktaDeviceTrust();

  const { updateOktaDeviceTrust, loading } = useUpdateOktaDeviceTrust();

  const {
    domain,
    client_id: clientId,
    mac_configured: clientSecret,
  } = integrationData;

  //  istanbul ignore next
  const handleNext = async () => {
    setFooterStatusState('pending');
    try {
      await updateOktaDeviceTrust(
        {
          domain: integrationSettingValues.domain,
          client_id: integrationSettingValues.clientId,
          client_secret: integrationSettingValues.clientSecret,
        },
        integrationData.id,
      );
      setFooterStatusState('success');
      await getOktaDeviceTrustDetails();
      toaster('Integration settings updated successfully.');

      onClose();
    } catch (e) {
      setFooterStatusState('failed');
      setShowErrorBanner(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const {
      target: { value },
    } = event;
    setIntegrationSettingValues((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const isSubmitDisabled =
    invalidations.some(Boolean) ||
    domain.length > 255 ||
    !integrationSettingValues.clientId ||
    !integrationSettingValues.clientSecret;

  useEffect(() => {
    if (Object.keys(integrationData).length) {
      setIntegrationSettingValues({
        domain,
        clientId: clientId ?? '',
        clientSecret: '',
      });
    }
  }, [domain, clientId, clientSecret, integrationData]);

  const errorBanner = showErrorBanner ? (
    <Banner
      icon="circle-xmark"
      theme="warning"
      className="b-mt"
      text="There was an error with one of the fields below. Please confirm the information entered in each of the followings fields and try again."
    />
  ) : null;

  return (
    <Container>
      <ModalContent>
        <StyledHeaderImage src={settingsCog} alt="header-icons" />
        <HeaderContainer>
          <h1>Edit integration settings</h1>
          <p className="b-txt-light">
            Edit the Okta domain, Client ID or Client Secret that was used to
            create the integration.
          </p>
          {errorBanner}
        </HeaderContainer>

        <StyledTexInputContainer>
          <div>
            <TextInput
              label="Okta domain"
              value={integrationSettingValues.domain.trim()}
              onChange={(e) => handleChange(e, 'domain')}
              onInvalidate={onInvalidate(0)}
              validator={handleOktaValidation}
              maxLength={255}
              hideMaxLength
            />
          </div>
          <div>
            <TextInput
              label="Client ID"
              value={integrationSettingValues.clientId.trim()}
              onChange={(e) => handleChange(e, 'clientId')}
              validator={(v) => {
                const v1 = onEmptyBlurValidator(v, { message: 'Required.' });
                const v2 = {
                  message: `must be ${validationLimit.clientId} characters or fewer`,
                  invalid: () => v.length > validationLimit.clientId,
                };
                return [v1, v2];
              }}
            />
          </div>
          <div>
            <TextInput
              label="Client Secret"
              value={integrationSettingValues.clientSecret?.trim()}
              onChange={(e) => handleChange(e, 'clientSecret')}
              type="password"
              validator={(v) => {
                const v1 = onEmptyBlurValidator(v, { message: 'Required.' });
                const v2 = {
                  message: `must be ${validationLimit.clientSecret} characters or fewer`,
                  invalid: () => v.length > validationLimit.clientSecret,
                };
                return [v1, v2];
              }}
            />
          </div>
        </StyledTexInputContainer>
      </ModalContent>

      <FooterButtons
        nextButtonText="Save and test connection"
        handleNext={handleNext}
        onClose={onClose}
        nextButtonDisabled={isSubmitDisabled}
        isWorking={loading}
        customComponent={
          footerStatusState ? (
            <FooterStatus
              icon={footerIcon}
              status={footerStatusState}
              options={{
                success: {
                  title: 'Okta is successfully connected.',
                  statusText: 'Completed',
                },
                pending: {
                  title: 'Confirming the connection with Okta...',
                  statusText: 'In progress',
                },
                failed: {
                  title: 'Connection to Okta failed',
                  statusText: 'Failed',
                },
              }}
            />
          ) : undefined
        }
      />
    </Container>
  );
};

export default EditIntegrationSettings;
