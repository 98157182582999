import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import { links } from 'app/common/constants';
import { useFeature } from 'features/integrations/hooks';
import { omit } from 'lodash';
import { bool } from 'prop-types';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import emptyState from '../common-assets/empty-state.svg';
import DeleteUserIntegrationModal from '../components-for-specific-types/generic/user-integrations/delete-user-integration-modal';
import RenameUserIntegrationModal from '../components-for-specific-types/generic/user-integrations/rename-user-integration-modal';
import UserIntCreationSuccessModal from '../components-for-specific-types/generic/user-integrations/user-int-creation-success-modal';
import ViewUserIntegrationModal from '../components-for-specific-types/generic/user-integrations/view-user-integration-modal';
import RotateTokenModal from '../components-for-specific-types/scim/rotate-token-modal';
import { INT_CATEGORIES, INT_TYPES } from '../constants';
import { newIntegrationsService } from '../data-service/new-integrations-service';
import GenericCardsView from '../generic-cards-view';
import {
  applyFiltersAndOrdering,
  getCounts,
  getItems,
  shouldSuggestMarketplace,
} from './calc';
import {
  validateCommunicationIntegration,
  validateUserIntegration,
} from './new-int-data-validation';
import OverviewIntegrationCard from './overview-integration-card';
import './overview.styles.scss';

import Store from '../components-for-specific-types/adcs/store';
import WelcomeModal from '../components-for-specific-types/generic/welcome-modal';
import useListIntegrations from '../hooks/use-list-integrations';
import useListIntegrationsBackend from '../hooks/use-list-integrations-backend';

function IntegrationsOverview({ testing }) {
  const { mapCategoryType } = useFeature();

  const [filters, setFilters] = useState({ category: null, search: '' });
  const [
    userIntCreationSuccessModalProps,
    setUserIntCreationSuccessModalProps,
  ] = useState({
    isOpen: false,
    type: null,
    name: '',
  });

  const [editedIntegration, setEditedIntegration] = useState(null);
  const [deletedIntegration, setDeletedIntegration] = useState(null);
  const [viewedIntegration, setViewedIntegration] = useState(null);
  const [rotatedScimIntegration, setRotatedScimIntegration] = useState(null);

  const history = useHistory();
  const {
    location: { state, search, hash },
    action,
  } = history;

  const {
    data: integrationsList,
    isFetching: isIntegrationsFetching,
    error: integrationsError,
    refetch: refetchIntegrations,
  } = useListIntegrations();

  const {
    data: integrationsBackendList,
    isFetching: isListIntegrationsBackendFetching,
    error: isIntegrationsBackendError,
  } = useListIntegrationsBackend();

  const isFetching =
    isIntegrationsFetching || isListIntegrationsBackendFetching;

  const error = integrationsError || isIntegrationsBackendError;

  const fullDataset = !isFetching
    ? [
        ...(integrationsList?.data?.results ?? []),
        ...(integrationsBackendList?.items ?? []),
      ].map((item) => {
        const category = Object.entries(mapCategoryType).find(([, types]) =>
          types.includes(item?.type),
        )?.[0];
        return category ? { ...item, category } : null;
      })
    : [];

  const filteredDataset = applyFiltersAndOrdering(fullDataset, filters);

  useEffect(() => {
    if (search) {
      const newIntegrationData = parse(search);
      const { type } = newIntegrationData || {};
      const category = Object.entries(mapCategoryType).find(([, types]) =>
        types.includes(type),
      )?.[0];

      let redirectPath = '';
      let redirectState;
      switch (category) {
        case INT_CATEGORIES.communication:
          if (validateCommunicationIntegration(newIntegrationData)) {
            redirectPath = `/${type}`;
            redirectState = omit(newIntegrationData, 'type');
          }

          break;
        case INT_CATEGORIES.user:
          if (validateUserIntegration(newIntegrationData)) {
            redirectState = newIntegrationData;
            if (hash) {
              redirectPath = '/marketplace';
              redirectState.errorKind = hash.replace('#', '');
            }
          }

          break;
        // TODO: [Apple Integrations] will be added back in the future
        // case INT_CATEGORIES.apple:
      }

      history.replace(
        `${links.integrations.root}${redirectPath}`,
        redirectState,
      );
    }
  }, [search, hash]);

  useEffect(() => {
    if (state) {
      if (action === 'REPLACE') {
        setUserIntCreationSuccessModalProps({ isOpen: true, ...state });
      } else if (action === 'PUSH' && state.type === INT_TYPES.scim) {
        toaster(`${state.name} has been added to your integrations library.`);
      }
    }
  }, [state]);

  const counts = getCounts(fullDataset);

  const itemsByCategories = getItems(
    filteredDataset,
    fullDataset,
    mapCategoryType,
  );

  const suggestMarketplace = !isFetching && shouldSuggestMarketplace(counts);

  function stopDeletingIntegration() {
    setDeletedIntegration(null);
  }

  function deleteIntegration(beforeApiCall, onSuccess, afterApiCall) {
    beforeApiCall?.();
    newIntegrationsService
      .deleteIntegration(deletedIntegration.id)
      .then(() => {
        refetchIntegrations();
        stopDeletingIntegration();
        onSuccess?.();
        toaster(`${deletedIntegration.name} has been deleted.`);
      })
      .catch((e) => {
        console.log(e);
        toaster('Something went wrong');
      })
      .finally(() => afterApiCall?.());
  }

  function requestDeletingIntegration(integrationData) {
    setDeletedIntegration({
      name: integrationData.name,
      id: integrationData.id,
    });
  }

  function stopViewingIntegration() {
    setViewedIntegration(null);
  }

  function startViewingIntegration(integrationData) {
    setViewedIntegration(integrationData);
  }

  function stopEditingIntegration() {
    setEditedIntegration(null);
  }

  function saveEditedIntegration(beforeApiCall, afterApiCall) {
    if (editedIntegration.initial === editedIntegration.edited) {
      stopEditingIntegration();
      return;
    }

    beforeApiCall?.();
    newIntegrationsService
      .updateIntegration(editedIntegration.id, {
        name: editedIntegration.edited,
      })
      .then(({ data }) => {
        refetchIntegrations();
        stopEditingIntegration();
        toaster(`${data.name} has been updated.`);
      })
      .catch((e) => {
        console.log(e);
        toaster('Something went wrong');
      })
      .finally(() => afterApiCall?.());
  }

  function startEditingIntegration(integrationData) {
    setEditedIntegration({
      initial: integrationData.name,
      edited: integrationData.name,
      id: integrationData.id,
    });
  }

  function finishRotatingScimIntegration() {
    setRotatedScimIntegration(null);
  }

  function requestRotatingScimIntegration(integrationData) {
    setRotatedScimIntegration({
      name: integrationData.name,
      id: integrationData.id,
    });
  }

  return (
    <Store>
      <GenericCardsView
        buttonText="Discover integrations"
        itemsByCategories={itemsByCategories}
        counts={counts}
        isFetching={isFetching}
        buttonIcon="magnifying-glass"
        buttonHref={links.integrations.marketplace}
        title="Integrations"
        setFilters={setFilters}
        filters={filters}
        CardComponent={OverviewIntegrationCard}
        error={error}
        extraPropsForCard={{
          requestDeletingIntegration,
          startViewingIntegration,
          startEditingIntegration,
          requestRotatingScimIntegration,
        }}
        testing={testing}
      />

      <UserIntCreationSuccessModal
        onClose={() =>
          setUserIntCreationSuccessModalProps((curr) => ({
            ...curr,
            isOpen: false,
          }))
        }
        {...userIntCreationSuccessModalProps}
      />

      <DeleteUserIntegrationModal
        stopDeletingIntegration={stopDeletingIntegration}
        deleteIntegration={deleteIntegration}
        name={deletedIntegration?.name}
      />

      <ViewUserIntegrationModal
        stopViewingIntegration={stopViewingIntegration}
        viewedIntegration={viewedIntegration}
      />

      <RenameUserIntegrationModal
        stopEditingIntegration={stopEditingIntegration}
        saveEditedIntegration={saveEditedIntegration}
        onChange={(newNameValue) =>
          setEditedIntegration((curr) => ({ ...curr, edited: newNameValue }))
        }
        nameValue={editedIntegration?.edited}
      />

      <RotateTokenModal
        finishRotating={finishRotatingScimIntegration}
        rotatedIntegration={rotatedScimIntegration}
      />

      {!!suggestMarketplace && (
        <>
          <h3 className="b-h3 b-mb b-mt3">Marketplace</h3>
          <div className="int-overview_suggest-marketplace">
            <img alt="empty-state" src={emptyState} />
            <h3 className="b-h3 b-mt">
              You have not set up any integrations from the marketplace
            </h3>
            <p className="b-txt b-mt1">
              Browse our marketplace of available integrations.
            </p>
            <Button
              className="b-mt1"
              kind="link"
              href={links.integrations.marketplace}
              linkComponent={Link}
            >
              Discover integrations
            </Button>
          </div>
        </>
      )}

      <WelcomeModal
        isOpen={false}
        onClose={() => {}}
        type={INT_TYPES.adcs}
        externalTrigger
      />
    </Store>
  );
}

IntegrationsOverview.propTypes = {
  testing: bool,
};

IntegrationsOverview.defaultProps = {
  testing: false,
};

export default IntegrationsOverview;
