import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../service/ethernet-service';

const keys = EthernetService.keys.authentication.acceptedEapTypes;

const RequireTwoFactor = ({ isDisabled, update, setting }) => (
  <Setting.SecondaryControlsRow>
    <Setting.Controls>
      <Checkbox
        isDisabled={isDisabled}
        checked={setting[keys.twoFactorAuth]}
        onChange={() =>
          update(keys.twoFactorAuth, !setting[keys.twoFactorAuth])
        }
        label="Require Two-Factor Authentication"
        testId="require-two-factor"
      />
    </Setting.Controls>
    <Setting.Helpers>
      <p className="b-txt-light">
        Select to allow two-factor authentication for EAP-TTLS, PEAP, or
        EAP-FAST.
      </p>
    </Setting.Helpers>
  </Setting.SecondaryControlsRow>
);

export default React.memo(RequireTwoFactor);
