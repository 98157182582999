/* istanbul ignore file */

import React, { useState, useEffect } from 'react';

import {
  Banner,
  Button,
  Flex,
  Select,
  TextInput,
  useInvalidations,
} from '@kandji-inc/bumblebee';

import { STATUS_CONNECTED } from 'features/integrations/components-for-specific-types/adcs/status';
import { Controls, Title } from 'features/library-items/template';
import { useAuthority, useConnector, useIs, usePanel } from '../../hooks';

import { findOption, includeByProperty } from '../../util';

import { validatorEditServerName } from '../../validation';

import Panel from '..';
import Header from '../header';

const PanelEditServer = () => {
  const {
    authority,
    authorityItem,
    authorityList,
    setAuthority,
    clearAuthority,
    clearAuthorityItem,
    fetchAuthorityItem,
    fetchAuthorityList,
    updateAuthorityItem,
  } = useAuthority();

  const { connectorList, fetchConnectorList } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { panel, openPanel, closePanel } = usePanel();

  const isOpen = !!panel['edit-server'];

  const initial = {
    id: '',
    name: '',
    connector: '',
  };

  const [form, setForm] = useState(initial);

  const { id, name, connector } = form;

  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });

  const hasInvalidations = invalidations.some(Boolean);

  const existingServerNames = authorityList.map(({ name: n }) => n);

  const filteredConnectorList = includeByProperty(
    connectorList,
    'status',
    STATUS_CONNECTED,
  );

  /* istanbul ignore next */
  const hasConnectorInstalled = filteredConnectorList?.length > 0;

  const optionsConnectors = [
    {
      value: null,
      label: 'Not assigned',
    },
    ...filteredConnectorList?.map(
      ({ id: value, name: connectorName, bound_domain }) => ({
        label:
          connectorName || bound_domain || 'Connector details are pending...',
        value,
      }),
    ),
  ];

  const valueConnector = findOption(connector, optionsConnectors);

  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  /* istanbul ignore next */
  const reset = () => {
    clearAuthority();
    clearAuthorityItem();
    setForm(initial);
  };

  /* istanbul ignore next */
  const openPanelDeleteServer = () => {
    setAuthority(id);
    closePanel(() => openPanel('delete-server'));
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closePanel(reset);
  };

  /* istanbul ignore next */
  const onSubmit = async () => {
    await updateAuthorityItem(form);
    fetchAuthorityList();
    fetchConnectorList();
    closePanel(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && authority) {
      fetchAuthorityItem();
      fetchConnectorList();
    }
  }, [isOpen, authority]);

  /* istanbul ignore next */
  useEffect(() => {
    if (authorityItem?.id) {
      setForm({
        ...form,
        id: authorityItem?.id,
        name: authorityItem?.name,
        connector: authorityItem?.connector,
      });
    }
    return () => setForm(initial);
  }, [authorityItem]);

  /* istanbul ignore next */
  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  return (
    <Panel name="edit-server">
      <Header title="Edit AD CS server" />

      <div className="b-side-panel-layout__body">
        <div className="k-section-primary">
          <p className="b-txt">
            Edit the server name and connector assignment below.
          </p>

          {!hasConnectorInstalled && (
            <Banner className="b-mt" theme="info" kind="block">
              <p>Servers can be assigned to the connector once installed.</p>
            </Banner>
          )}
        </div>

        <div className="k-section-primary k-section-border-top">
          <div className="k-section-secondary">
            <Title>
              <p className="b-txt b-mb1">Server name and connector assigment</p>
            </Title>

            <Controls>
              <TextInput
                className="b-mb"
                placeholder="Enter the server name"
                value={name}
                /* istanbul ignore next */
                validator={validatorEditServerName(
                  existingServerNames,
                  authorityItem?.name,
                )}
                onInvalidate={onInvalidate(0)}
                onChange={
                  /* istanbul ignore next */
                  (v) => updateForm('name', v.target.value)
                }
                disabled={isLoading || isWorking}
              />

              <Select
                className={
                  /* istanbul ignore next */
                  hasConnectorInstalled ? undefined : 'k-adcs-select-disabled'
                }
                placeholder="Select connector from list"
                options={optionsConnectors}
                value={valueConnector}
                onChange={
                  /* istanbul ignore next */
                  hasConnectorInstalled
                    ? (v) => updateForm('connector', v.value)
                    : () => {}
                }
                disabled={isLoading || isWorking || !hasConnectorInstalled}
              />
            </Controls>
          </div>
        </div>
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="space-between">
          <Button
            kind="link"
            theme="error"
            onClick={openPanelDeleteServer}
            isDisabled={isLoading || isWorking}
          >
            Delete
          </Button>

          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onCancel}
              isDisabled={isLoading || isWorking}
            >
              Cancel
            </Button>

            <Button
              icon={isWorking ? 'arrows-rotate' : undefined}
              onClick={onSubmit}
              isDisabled={isLoading || isWorking || hasInvalidations}
              isProgress={isWorking}
            >
              Save
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  );
};

export default PanelEditServer;
