import { TextInput } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const CertificateNameRow = (props) => {
  const { isDisabled, setting, update } = props;

  return (
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">
          Certificate name&nbsp;
          <span className="b-txt-light"> (optional) </span>
        </p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">The display name of the certificate.</p>
      </Setting.Helpers>
      <Setting.Controls>
        <TextInput
          value={setting.name}
          onChange={(e) => update('name', e.target.value)}
          disabled={isDisabled}
          placeholder="Honey, Inc. Certificate"
          compact
          data-testid="cert-name"
        />
      </Setting.Controls>
    </Setting.Row>
  );
};

export default CertificateNameRow;
