import { Button, Flex } from '@kandji-inc/nectar-ui';
import DetectionDateFilter from '../common/components/CommonFilters/DetectionDateFilter';
import SearchFilter from '../common/components/CommonFilters/SearchFilter';
import ClassificationFilter from '../common/components/ThreatListFilters/ClassificationFilter';
import MultiselectFilter from '../common/components/ThreatListFilters/MultiselectFilter';
import constants from '../common/constants';
import type {
  FiltersState,
  OnApply,
  OnClear,
  OnClearAll,
} from '../threat.types';

type ThreatListFiltersProps = {
  filters: FiltersState;
  onApply: OnApply;
  onClear: OnClear;
  onClearAll: OnClearAll;
  isFiltering: boolean;
  malwareCount: number | undefined;
  pupCount: number | undefined;
};

const enumOptions =
  constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.filter(
    (option) => option.value !== 'last_24_hours' && option.value !== 'all_time',
  );

const ThreatListFilters = (props: ThreatListFiltersProps) => {
  const {
    filters,
    onApply,
    onClear,
    onClearAll,
    isFiltering,
    malwareCount,
    pupCount,
  } = props;

  return (
    <Flex flow="row" wrap="wrap" gap="sm">
      <SearchFilter
        name="query"
        value={filters.query}
        onChange={onApply}
        onClear={onClear}
      />
      <DetectionDateFilter
        filter={filters.detectionDate}
        onChange={onApply}
        onClear={onClear}
        options={enumOptions}
      />
      <MultiselectFilter
        name="status"
        label="Status"
        values={filters.status}
        onChange={onApply}
        onClear={onClear}
        options={constants.THREAT_STATUS_LIST.map((value) => ({
          key: value,
          value,
          label: constants.THREAT_STATUS_DISPLAY_NAME_MAP[value],
        }))}
      />
      <ClassificationFilter
        value={filters.classification}
        onChange={onApply}
        onClear={onClear}
        malwareCount={malwareCount}
        pupCount={pupCount}
      />
      {isFiltering && (
        <Button variant="subtle" compact onClick={onClearAll}>
          Clear all
        </Button>
      )}
    </Flex>
  );
};

export default ThreatListFilters;
