import { Checkbox, Chip, Select } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../service/ethernet-service';
import { interfaceTypeOptions } from '../protocols-card.model';

const keys = EthernetService.keys.authentication;

const AuthenticationTypeRow = ({ setting, update, isDisabled }) => {
  return (
    <Setting.Row>
      <Setting.Helpers>
        <p className="b-txt-light">
          User logs in to authenticate the Mac to the network.
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <Checkbox
          checked={setting[keys.useLoginWindow]}
          onChange={() => update(keys.useLoginWindow, (p) => !p)}
          disabled={isDisabled}
          label="Use as a Login Window configuration"
        />
      </Setting.Controls>
    </Setting.Row>
  );
};

export default AuthenticationTypeRow;
