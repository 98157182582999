/* istanbul ignore file */

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import history from 'src/app/router/history';
import { msTeamsService } from '../services/teams';

type MSTeamsIntegrationsParams = {
  isAutoFetchEnabled?: boolean;
};

const deleteIntegration = (integrationId: number) =>
  msTeamsService.deleteIntegration(integrationId);

const useGetMSTeamsIntegrations = (params: MSTeamsIntegrationsParams) =>
  useQuery({
    queryKey: ['msTeamsIntegrations'],
    queryFn: () => msTeamsService.getIntegrations(),
    enabled: params?.isAutoFetchEnabled ?? true,
  });

const useDeleteMSTeamsIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteIntegration,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['msTeamsIntegrations'],
      });
      toaster('Microsoft Teams integration deleted.');
      history.push('/integrations');
    },
  });
};

const useDeleteMSTeamIntegrations = () =>
  useMutation({
    mutationFn: deleteIntegration,
  });

export {
  useGetMSTeamsIntegrations,
  useDeleteMSTeamIntegrations,
  useDeleteMSTeamsIntegration,
};
