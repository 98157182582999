import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useEffect } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import configs from 'features/library-items/library/library-item-configurations/items/profile';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import ActivityTab from './activity-tab';
import initialState from './initial-state';
import Configurations from './sections/configurations';
import { transformFromApi, transformToApi } from './service/transformers';
import useVpnService from './service/use-vpn-service';
import { vpnService } from './service/vpn.service';

const VpnPage = (props) => {
  const { model, setModel, pageState } = props;
  const itemConfig = configs.VPN;
  const [validationDep, triggerValidation] = useUniqValue();
  const updateConfigurations = useCallback(
    update('configurations', setModel),
    [],
  );

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={vpnService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <Configurations
        setting={model.configurations}
        update={updateConfigurations}
        validationDep={validationDep}
        {...pageState}
      />
    </LibraryItemPage>
  );
};

const Vpn = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useVpnService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <VpnPage {...pageProps} testId="vpn-page" />;
};

export default Vpn;
