import {
  Select,
  useInputsValidators,
  useIsChanged,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useMemo, useState } from 'react';

import { rootApi } from 'app/api/base';
import { Setting } from 'features/library-items/template';
import { useLocation } from 'react-router-dom';
import CertificateService from '../service/certificate-service';
import AdcsRows from './adcs-rows';
import Pkcs1Rows from './pkcs1-rows';
import Pkcs12Rows from './pkcs12-rows';

const certTypeOptions = [
  {
    value: CertificateService.certificateTypes.AD_CS,
    label: 'AD CS certificate',
  },
  {
    value: CertificateService.certificateTypes.PKCS_1,
    label: 'PKCS #1-formatted certificate',
  },
  {
    value: CertificateService.certificateTypes.PKCS_12,
    label: 'PKCS #12-formatted certificate',
  },
];

const rowsComponentMap = {
  [CertificateService.certificateTypes.AD_CS]: AdcsRows,
  [CertificateService.certificateTypes.PKCS_1]: Pkcs1Rows,
  [CertificateService.certificateTypes.PKCS_12]: Pkcs12Rows,
};

const fieldsToValidate = ['certType'];
const CertificateCard = (props) => {
  const { isDisabled, setting, update, validationDep } = props;
  const { state } = useLocation();
  const RowsComponent = rowsComponentMap[setting.type];
  const [adcsServers, setAdcsServers] = useState([]);

  useEffect(() => {
    rootApi('/integrations/v1/adcs/authority/')
      .get()
      .then(({ data: { results } }) =>
        setAdcsServers(
          results.map(({ name, id }) => ({
            value: id,
            label: name,
          })),
        ),
      )
      .catch((_) => {
        // Not much to do here, we can't log to console. In this there was an
        // error retrieving adcs servers.
      });
  }, []);

  const { onInvalidate, refs } = useInputsValidators(fieldsToValidate, update);
  const isSubmitted = useIsChanged(validationDep);
  useEffect(() => {
    onInvalidate('certType')(!setting.type);
  }, [setting.type]);

  useEffect(() => {
    if (state?.certificate?.server && adcsServers?.length) {
      const server = adcsServers.find(
        ({ value }) => value === state.certificate.server,
      );
      if (server?.value) {
        update('server', server.value);
      }
      delete state.certificate.server;
    }
  }, [state, adcsServers]);

  const filteredCertTypeOptions = useMemo(() => {
    if (adcsServers.length) {
      return certTypeOptions;
    }

    return certTypeOptions.filter(
      ({ value }) => value !== CertificateService.certificateTypes.AD_CS,
    );
  }, [adcsServers]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Certificate</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Specify the certificate type and configure details for the
          certificate.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558739"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more...
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p ref={refs.certType} className="b-txt">
              Certificate type
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Select the type of certificate being used. This dictates the
              payload type that is populated in the configuration profile.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              isDisabled={isDisabled}
              options={filteredCertTypeOptions}
              placeholder="Select certificate type"
              compact
              onChange={(v) => update('type', v.value)}
              value={certTypeOptions.find(
                (option) => option.value === setting.type,
              )}
              errorText={(isSubmitted && !setting.type && 'Required') || ''}
              testId="cert-card-type"
            />
          </Setting.Controls>
        </Setting.Row>

        {RowsComponent && (
          <RowsComponent {...props} adcsServers={adcsServers} />
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default CertificateCard;
