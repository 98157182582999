import { Button } from '@kandji-inc/bumblebee';
import {
  array,
  bool,
  element,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useDragHelper from '../hooks/use-drag-helper/use-drag-helper';
import {
  bytesToSize,
  getFileEnding,
  loadGivenFile,
  validateFileEnding,
  withClass,
} from './helpers';
import Progress from './progress/progress';
import './upload.css';

const states = {
  errorSelecting: 'errorSelecting',
  errorUploading: 'errorUploading',
  errorValidating: 'errorValidating',
  idle: 'idle',
  fileSelected: 'fileSelected',
  uploading: 'uploading',
  validating: 'validating',
  uploaded: 'uploaded',
};

const defaultCurrentFile = {
  fileUploadId: '',
  file: null,
  fileSHA: '',
  progress: 0,
  state: states.idle,
};

const defaultHasError = {
  validationError: '',
  uploadError: '',
  fileSelectError: '',
};

const Upload = ({
  fileUrl,
  className,
  style,
  isDisabled,
  allowedTypes,
  allowedTypesAlert,
  onFileSelect,
  onUpload,
  onValidate,
  onUploaded,
  onDelete,
  onCancel,
  onError,
  fileIcons,
  icon,
  withError,
  forceWithFile,
  maxFileSize,
  uploadInstructions,
}) => {
  const inputRef = useRef();
  const [currentFile, setCurrentFile] = useState(defaultCurrentFile);
  const [hasError, setHasError] = useState(defaultHasError);
  const [draggedFile, setDraggedFile] = useState();
  const [ref, isHovering] = useDragHelper(isDisabled, (e) => {
    const file = e.dataTransfer.files[0];
    if (!file) {
      return;
    }
    setDraggedFile(file);
  });

  const openFileSelector = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const reset = () => {
    setCurrentFile({ ...defaultCurrentFile });
    setHasError({ ...defaultHasError });
    if (inputRef && inputRef.current) {
      inputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (draggedFile) {
      const file = draggedFile;
      const allowed = validateFileEnding(file, allowedTypes);
      const isBelowMaxSize =
        maxFileSize && maxFileSize > 0 ? file.size < maxFileSize : true;
      if (allowed && isBelowMaxSize) {
        setCurrentFile((prev) => ({
          ...prev,
          file,
          state: states.fileSelected,
        }));
        if (onFileSelect) {
          onFileSelect(file);
        }
        setHasError({ ...defaultHasError });
        if (onError) {
          onError();
        }
      } else {
        const err = !isBelowMaxSize
          ? `File size must be less than ${bytesToSize(maxFileSize)}`
          : `This doesn't appear to be a valid file type. Allowed extensions: ${allowedTypes.join(
              ', ',
            )} `;
        setHasError({
          ...defaultHasError,
          fileSelectError: err,
        });
        if (onError) {
          onError(err);
        }
      }
      setDraggedFile();
    }
  }, [draggedFile]);

  useEffect(() => {
    if (forceWithFile) {
      try {
        loadGivenFile(forceWithFile, allowedTypes)
          .then((r) => {
            if (r.file) {
              setCurrentFile({
                ...defaultCurrentFile,
                file: r.file,
                fileSHA: r.sha256 || '',
                state: states.uploaded,
              });
            }
          })
          .catch(() => setCurrentFile({ ...defaultCurrentFile }));
      } catch (e) {
        console.log('loadGivenFile error', e);
        setCurrentFile({ ...defaultCurrentFile });
      }
    }
  }, [forceWithFile]);

  useEffect(() => {
    if (currentFile.file && currentFile.state === states.uploaded) {
      const isCurrentFileAllowed = validateFileEnding(
        currentFile.file,
        allowedTypes,
      );
      setHasError((prev) => {
        if (isCurrentFileAllowed) {
          return { ...prev, fileSelectError: '' };
        }
        return {
          ...defaultHasError,
          fileSelectError: `This doesn't appear to be a valid file type. Allowed extensions: ${allowedTypes.join(
            ', ',
          )} `,
        };
      });
    }
  }, [allowedTypes, currentFile]);

  useEffect(() => {
    if (onError) {
      const activeError = Object.values(hasError).filter((d) => d);
      onError(activeError.length ? activeError[0] : '');
    }
  }, [hasError]);

  useEffect(() => {
    if (currentFile.state === states.fileSelected && onUpload) {
      setCurrentFile((prev) => ({ ...prev, state: states.uploading }));
      onUpload(currentFile.file, (progress) =>
        setCurrentFile((prev) => ({ ...prev, progress })),
      )
        .then(() =>
          setCurrentFile((prev) => ({
            ...prev,
            state: onValidate ? states.validating : states.uploaded,
          })),
        )
        .then(() => {
          if (!onValidate && onUploaded) {
            onUploaded(currentFile.file);
          }
        })
        .catch(() => {
          setCurrentFile((prev) => ({ ...prev, state: states.errorUploading }));
          setHasError({
            ...defaultHasError,
            uploadError:
              'Something went wrong while uploading the file, please try again.',
          });
        });
    } else if (currentFile.state === states.validating && onValidate) {
      onValidate(currentFile.file)
        .then((r) => {
          setCurrentFile((prev) => ({
            ...prev,
            state: states.uploaded,
            fileSHA: r || '',
          }));
          if (onUploaded) {
            onUploaded(currentFile.file, r || '');
          }
        })
        .catch(() => {
          setCurrentFile((prev) => ({
            ...prev,
            state: states.errorValidating,
          }));
          setHasError({
            ...defaultHasError,
            validationError:
              'Something went wrong while validating the file, please try again.',
          });
        });
    }
  }, [currentFile]);

  const shouldShowFileSelectView =
    currentFile.state === states.idle ||
    currentFile.state === states.fileSelected ||
    currentFile.state === states.errorSelecting;
  const isIdle = currentFile.state === states.idle;
  const isUploading = currentFile.state === states.uploading;
  const isValidating = currentFile.state === states.validating;
  const isUploaded = currentFile.state === states.uploaded;
  const anyErrors =
    withError || Object.values(hasError).filter((e) => e).length;
  const activeError =
    withError || (anyErrors ? Object.values(hasError).filter((e) => e)[0] : '');
  return (
    <div className={`b-flex-col ${className || className}`}>
      <div
        ref={ref}
        className={`b-up-container b-up-lg hook-upload-container ${withClass(
          isUploading,
          'b-up-lg-uploader',
        )} ${withClass(
          isUploaded,
          'b-up-lg-uploaded b-up-container__loaded',
        )} ${withClass(isHovering, 'b-up-container__focus')} ${withClass(
          isDisabled,
          'b-up-container__disabled',
        )} ${withClass(
          !isIdle && !isDisabled,
          'b-up-container__selected',
        )} ${withClass(anyErrors, 'b-up-container__error')}`}
        style={style}
        onClick={() => isIdle && openFileSelector()}
      >
        <input
          className="b-icon-up-xs__input"
          type="file"
          accept={allowedTypes.join(',')}
          disabled={isDisabled || currentFile.file}
          ref={inputRef}
          onChange={(e) => {
            const file = e.target.files[0];
            if (!file) {
              return;
            }
            const isBelowMaxSize =
              maxFileSize && maxFileSize > 0 ? file.size < maxFileSize : true;
            if (isBelowMaxSize) {
              setCurrentFile((prev) => ({
                ...prev,
                file,
                state: states.fileSelected,
              }));
              if (onFileSelect) {
                onFileSelect(file);
              }
              if (onError) {
                onError('');
              }
              setHasError({ ...defaultHasError });
            } else if (onError) {
              onError(
                `File size must be less than ${bytesToSize(maxFileSize)}`,
              );
            }
          }}
        />
        {shouldShowFileSelectView && (
          <div className="b-flex-col b-flex-btw b-flex-vc b-flex-hc b-flex1 hook-upload-instructions">
            <i
              className={`bi bi-file-upload b-up-icon ${withClass(
                isDisabled,
                'b-up-icon-disabled',
              )}`}
            />
            <div className="b-flex-col b-flex-vc">
              <span className={`b-txt${withClass(isDisabled, '-light')}`}>
                {uploadInstructions}
              </span>
              <span
                className={`b-txt-bold hook-upload-allowed-types ${withClass(
                  isDisabled,
                  'b-txt-light',
                )}`}
              >
                {allowedTypesAlert ||
                  `${allowedTypes.join(
                    allowedTypes.length <= 2 ? ' or ' : ', ',
                  )} file`}
              </span>
            </div>
          </div>
        )}

        {!shouldShowFileSelectView && (
          <div className="b-flex b-flex1 b-flex-g">
            <div className="b-flex-col b-flex1">
              <div className="b-flex b-flex1 b-flex-vc b-flex-g b-ml b-up-type-icon">
                {icon ||
                  (fileIcons && fileIcons[getFileEnding(currentFile.file)] ? (
                    fileIcons[getFileEnding(currentFile.file)]
                  ) : (
                    <i className="bi bi-file-upload b-up-icon" />
                  ))}
                <div className="b-flex-col">
                  <span
                    className={`b-txt-bold ${withClass(
                      isDisabled,
                      'b-txt-light',
                    )}`}
                  >
                    {currentFile.file.name}
                  </span>
                  <span className="b-txt-light">
                    {bytesToSize(currentFile.file.size)}
                  </span>
                  {currentFile.fileSHA && (
                    <span className="b-txt-light">
                      SHA-256: {currentFile.fileSHA}
                    </span>
                  )}
                  {fileUrl && (
                    <a href={fileUrl} target="_blank" rel="noreferrer">
                      {' '}
                      Download{' '}
                    </a>
                  )}
                </div>
              </div>
              {isUploading && <Progress progress={currentFile.progress} />}
              {isValidating && (
                <span className="b-txt b-up-validate-text">
                  Validating file...
                </span>
              )}
            </div>
            <div
              className={`b-flex ${
                isUploading ? 'b-flex-align-end' : 'b-flex-vc'
              }`}
            >
              <Button
                kind="link"
                icon={isUploading ? 'circle-xmark' : 'trash-can'}
                isDisabled={
                  isDisabled || currentFile.state === states.validating
                }
                theme="error"
                size="small"
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }

                  if (isUploading && onCancel) {
                    onCancel(currentFile.file);
                  } else if (!isUploading && onDelete) {
                    onDelete(currentFile.file);
                    reset();
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      {anyErrors ? (
        <span className="b-txt b-up-red b-mt-tiny">
          <i className="bi bi-exclamation-circle b-mr-tiny" />
          {activeError}
        </span>
      ) : null}
    </div>
  );
};

Upload.propTypes = {
  className: string,
  style: object,
  allowedTypes: array,
  allowedTypesAlert: string,
  isDisabled: bool,
  onFileSelect: func,
  onUpload: func,
  onValidate: func,
  onUploaded: func,
  onDelete: func,
  onCancel: func,
  onError: func,
  withError: string,
  fileIcons: object,
  icon: element,
  forceWithFile: oneOfType([object, func]),
  maxFileSize: number,
  uploadInstructions: oneOfType([string, node]),
  fileUrl: string,
};

Upload.defaultProps = {
  className: '',
  style: {},
  allowedTypes: ['.pkg', '.dmg'],
  allowedTypesAlert: null,
  isDisabled: false,
  onFileSelect: () => {},
  onUpload: null,
  onValidate: null,
  onUploaded: null,
  onDelete: null,
  onCancel: null,
  onError: null,
  withError: '',
  fileIcons: null,
  icon: null,
  forceWithFile: null,
  fileUrl: null,
  maxFileSize: -1,
  uploadInstructions: <span>Drag file here or click to upload</span>,
};

export default Upload;
