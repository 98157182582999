import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class CustomScriptService extends NewLibraryItemService {
  static installEnforcementTypes = {
    INSTALL_ONCE: 'install_once',
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    EVERY_DAY: 'every_day',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static executionFrequencyTypes = {
    ONCE: 'once',
    EVERY_15_MIN: 'every_15_min',
    EVERY_DAY: 'every_day',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static enforcementAndFrequencyMap = {
    [CustomScriptService.executionFrequencyTypes.ONCE]:
      CustomScriptService.installEnforcementTypes.INSTALL_ONCE,
    [CustomScriptService.executionFrequencyTypes.EVERY_15_MIN]:
      CustomScriptService.installEnforcementTypes.CONTINUOUSLY_ENFORCE,
    [CustomScriptService.executionFrequencyTypes.EVERY_DAY]:
      CustomScriptService.installEnforcementTypes.EVERY_DAY,
    [CustomScriptService.executionFrequencyTypes.NO_ENFORCEMENT]:
      CustomScriptService.installEnforcementTypes.NO_ENFORCEMENT,
  };
}

export const customScriptService = new CustomScriptService();
export default CustomScriptService;
