// import PropTypes from 'prop-types';
import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from '../custom-profile/activity-tab';
import initial from './initial-state';
import Message from './sections/Message';
import { conferenceRoomDisplayService } from './service/conference-room-display-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useConferenceRoomDisplayService from './service/use-conference-room-display-service';

const ConferenceRoomDisplayPage = (props) => {
  const { model, setModel, pageState } = props;
  const [/* validationDep */ , triggerValidation] = useUniqValue();

  const settingsUpdate = useCallback(update('message', setModel));

  const itemConfig = config['Conference Room Display'];

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={conferenceRoomDisplayService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <Message
        message={model.message}
        isDisabled={pageState.isDisabled}
        update={settingsUpdate}
      />
    </LibraryItemPage>
  );
};

// ConferenceRoomDisplayPage.propTypes = {};

const ConferenceRoomDisplay = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useConferenceRoomDisplayService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <ConferenceRoomDisplayPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="conference-room-display-v2"
    />
  );
};

export default ConferenceRoomDisplay;
