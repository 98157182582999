import { Checkbox, Radio, Select } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Setting } from 'features/library-items/template';

import AutomaticAppService from '../../service/automatic-app-service';

const Notifications = ({ update, isDisabled, settings }) => {
  const settingsUpdate = (property, value) => {
    update('settings', (settingsObj) => ({
      ...settingsObj,
      [property]:
        typeof value === 'function' ? value(settingsObj[property]) : value,
    }));
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Notifications</h3>
        <Setting.HeaderToggle
          isEnabled={settings.is_available && settings.is_managed}
          onChange={() => update('is_managed', (p) => !p)}
          isDisabled={isDisabled || !settings.is_available}
          chipText={{
            enabled: 'Managed',
            disabled: !settings.is_available ? 'Not available' : 'Unmanaged',
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {!settings.is_available
            ? 'This application does not support notifications.'
            : 'Specify whether or not end users should have control over their notifications for this app, or if they should be managed in a particular way by Kandji.'}
        </p>
      </Setting.SubHeader>
      {settings.is_available && settings.is_managed && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Helpers>
              <p className="b-txt-light">
                Select &quot;Disallow notifications&quot; to prevent the user
                from turning notifications on for this application. Select
                &quot;Allow notifications&quot; to force notifications on for
                this application.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Radio
                label="Disallow notifications"
                onChange={() => settingsUpdate('NotificationsEnabled', false)}
                checked={!settings.settings.NotificationsEnabled}
                isDisabled={isDisabled}
                id="notifications_disable_radio"
              />
              <br />
              <Radio
                label="Allow notifications"
                onChange={() => settingsUpdate('NotificationsEnabled', true)}
                checked={settings.settings.NotificationsEnabled}
                isDisabled={isDisabled}
                id="notifications_enable_radio"
              />
            </Setting.Controls>
            {settings.settings.NotificationsEnabled && (
              <Setting.SecondaryControls>
                <Setting.SecondaryControlsRow>
                  <Setting.Title>
                    <p className="b-txt">Alert style</p>
                  </Setting.Title>
                  <Setting.Helpers>
                    <p className="b-txt-light">
                      Banners appear in the upper-right corner and go away
                      automatically. Alerts stay on screen until dismissed.
                    </p>
                  </Setting.Helpers>
                  <Setting.Controls>
                    <Select
                      compact
                      options={Object.values(
                        AutomaticAppService.notificationAlertTypeOptions,
                      )}
                      onChange={(selected) =>
                        settingsUpdate('AlertType', selected.value)
                      }
                      value={Object.values(
                        AutomaticAppService.notificationAlertTypeOptions,
                      ).find(
                        (opt) => opt.value === settings.settings.AlertType,
                      )}
                      disabled={isDisabled}
                      inputId="alert_type_dropdown"
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Checkbox
                      label="Enable critical alerts"
                      id="enable_critical_alerts"
                      testId="enable_critical_alerts_checkbox"
                      checked={settings.settings.CriticalAlertEnabled}
                      onChange={() =>
                        settingsUpdate(
                          'CriticalAlertEnabled',
                          (checked) => !checked,
                        )
                      }
                      disabled={isDisabled}
                    />
                  </Setting.Controls>
                  <Setting.Helpers>
                    <p className="b-txt-light">
                      If enabled, critical alerts can ignore Do Not Disturb and
                      ringer settings for this app.
                    </p>
                  </Setting.Helpers>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Checkbox
                      label="Show notifications on lock screen"
                      id="show_notifications_on_lock_screen"
                      testId="show_notifications_on_lock_screen_checkbox"
                      checked={settings.settings.ShowInLockScreen}
                      onChange={() =>
                        settingsUpdate(
                          'ShowInLockScreen',
                          (checked) => !checked,
                        )
                      }
                      disabled={isDisabled}
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Checkbox
                      label="Show in Notification Center"
                      id="show_in_notification_center"
                      testId="show_in_notification_center_checkbox"
                      checked={settings.settings.ShowInNotificationCenter}
                      onChange={() =>
                        settingsUpdate(
                          'ShowInNotificationCenter',
                          (checked) => !checked,
                        )
                      }
                      disabled={isDisabled}
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Checkbox
                      label="Badge app icon"
                      id="badge_app_icon"
                      testId="badge_app_icon_checkbox"
                      checked={settings.settings.BadgesEnabled}
                      onChange={() =>
                        settingsUpdate('BadgesEnabled', (checked) => !checked)
                      }
                      disabled={isDisabled}
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Checkbox
                      label="Play sound for notifications"
                      id="play_sound_for_notifications"
                      testId="play_sound_for_notifications_checkbox"
                      checked={settings.settings.SoundsEnabled}
                      onChange={() =>
                        settingsUpdate('SoundsEnabled', (checked) => !checked)
                      }
                      disabled={isDisabled}
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Select
                      label="Show previews"
                      compact
                      options={Object.values(
                        AutomaticAppService.notificationPreviewTypeOptions,
                      )}
                      onChange={(selected) =>
                        settingsUpdate('PreviewType', selected.value)
                      }
                      value={Object.values(
                        AutomaticAppService.notificationPreviewTypeOptions,
                      ).find(
                        (opt) => opt.value === settings.settings.PreviewType,
                      )}
                      disabled={isDisabled}
                      inputId="preview_type_dropdown"
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Select
                      label="Notification grouping"
                      compact
                      options={Object.values(
                        AutomaticAppService.notificationGroupingTypeOptions,
                      )}
                      onChange={(selected) =>
                        settingsUpdate('GroupingType', selected.value)
                      }
                      value={Object.values(
                        AutomaticAppService.notificationGroupingTypeOptions,
                      ).find(
                        (opt) => opt.value === settings.settings.GroupingType,
                      )}
                      disabled={isDisabled}
                      inputId="grouping_type_dropdown"
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

Notifications.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    is_managed: PropTypes.bool,
    is_available: PropTypes.bool,
    settings: PropTypes.shape({
      NotificationsEnabled: PropTypes.bool,
      AlertType: PropTypes.oneOf([0, 1, 2]),
      BadgesEnabled: PropTypes.bool,
      CriticalAlertEnabled: PropTypes.bool,
      GroupingType: PropTypes.oneOf([0, 1, 2]),
      PreviewType: PropTypes.oneOf([0, 1, 2]),
      ShowInLockScreen: PropTypes.bool,
      ShowInNotificationCenter: PropTypes.bool,
      SoundsEnabled: PropTypes.bool,
    }),
  }).isRequired,
};

export default memo(Notifications);
