import { enterpriseApi } from 'app/api/base';
import { InterfaceContext } from 'contexts/interface';
/* istanbul ignore file */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { copyToClipboard } from 'theme/function';

const getExternalTokens = () =>
  enterpriseApi(`token/`)
    .get()
    .then((res) => res.data.results);

const createExternalToken = (data) =>
  enterpriseApi(`token/`)
    .post(data)
    .then((res) => res.data);

const setLabelForExternalToken = (id, label) =>
  enterpriseApi(`token/${id}/`).patch({ label });

const deleteExternalToken = (id) => enterpriseApi(`token/${id}/`).delete();

const defaultValues = {
  data: [],
  selectedItemId: null,
  setSelectedItemId: undefined,
  selectedItemLabel: null,
  setSelectedItemLabel: undefined,
  givenToken: null,
  givenID: null,
  setGivenToken: undefined,
  setGivenID: undefined,
  onTokenDelete: undefined,
  onCancelDelete: undefined,
  onTokenRename: undefined,
  onCancelRename: undefined,
  onTokenCreate: undefined,
  onTokenCopy: undefined,
  onCancelCreate: undefined,
  uniqueTokenValidation: undefined,
  tokenHoneyValidation: null,
  pathToTokenPage: undefined,
  isWorking: false,
};

const Context = createContext(defaultValues);

const Provider = ({ children }) => {
  const { onModalOpen, onModalClose, setSnackbar } =
    useContext(InterfaceContext);

  const [data, setData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemLabel, setSelectedItemLabel] = useState(null);
  const [givenToken, setGivenToken] = useState(null);
  const [givenID, setGivenID] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  const initializeData = () =>
    getExternalTokens().then((tokensData) => setData(tokensData));

  const pathToTokenPage = '/my-company/access/api-token/';

  // validation
  const required =
    (msg = 'Required') =>
    (v) =>
      !v && msg;
  const maxLength =
    (len, msg = 'Too long') =>
    (v) =>
      v.length > len && msg;

  const maxLen = 32;

  const uniqueTokenValidation =
    (msg = 'Should be unique.') =>
    (v) => {
      const labels = data.map((datum) => datum.label);
      return labels.includes(v) && msg;
    };

  const tokenHoneyValidation = {
    label: [
      required(),
      maxLength(
        maxLen,
        `Please enter a name with ${maxLen} characters or less`,
      ),
      uniqueTokenValidation(),
    ],
  };

  // delete modal
  const onTokenDelete = async () => {
    try {
      setIsWorking(true);
      await deleteExternalToken(selectedItemId);
      await initializeData();
      setIsWorking(false);
      onModalClose();
    } catch (err) {
      setIsWorking(false);
    }
  };

  const onCancelDelete = () => {
    onModalClose();
    setSelectedItemId(null);
  };

  // rename modal
  const onTokenRename = async (form) => {
    setIsWorking(true);
    try {
      await setLabelForExternalToken(selectedItemId, form.label);
      await initializeData();
      setIsWorking(false);
      onModalClose();
    } catch (err) {
      setIsWorking(false);
    }
    setSelectedItemId(null);
  };

  const onCancelRename = () => {
    onModalClose();
    setSelectedItemId(null);
  };

  // create modal
  const onTokenCreate = async (form) => {
    setIsWorking(true);
    try {
      const tokenResp = await createExternalToken(form);
      setGivenToken(tokenResp.token);
      setGivenID(tokenResp.id);
      onModalOpen('add-second');
      setIsWorking(false);
      await initializeData();
    } catch (err) {
      setIsWorking(false);
    }
  };

  const onTokenCopy = () => {
    copyToClipboard(givenToken);
    setSnackbar('Copied!');
  };

  const onCancelCreate = () => {
    onModalClose();
    setGivenToken(null);
  };

  useEffect(() => {
    initializeData();
  }, []);

  return (
    <Context.Provider
      value={{
        ...defaultValues,
        data,
        selectedItemId,
        setSelectedItemId,
        selectedItemLabel,
        setSelectedItemLabel,
        givenToken,
        givenID,
        setGivenToken,
        setGivenID,
        onTokenDelete,
        onCancelDelete,
        onTokenRename,
        onCancelRename,
        onTokenCreate,
        onTokenCopy,
        onCancelCreate,
        uniqueTokenValidation,
        tokenHoneyValidation,
        pathToTokenPage,
        isWorking,
        setIsWorking,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
