import AutomaticAppService from './service/automatic-app-service';

const initialState = {
  // _data: {}, // original data from api response
  id: '',
  name: '',
  instanceName: '',
  description: '', // item.data.description
  subtitle: '', // item.data.subtitle
  icon: '', // item.data.icon_url
  isActive: true, // item.active
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  requiresRosetta: true, // item.data.requires_rosetta
  warning: '', // item.data.warning
  publisher: '', // item.data.publisher
  version: '', // item.data.version_display || item.data.version
  runsOn: {
    ipad: true, // item.runs_on_ipad
    iphone: true, // item.runs_on_iphone
    ipod: true, // item.runs_on_ipod
    mac: true, // item.runs_on_mac
    tv: true, // item.runs_on_tv
    watch: true, // item.runs_on_watch
  },
  osRequirements: [], // item.data.minimum_os_version // only one field???

  installation: {
    type: AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE, // item.data.install_enforcement
    updates_on_pre_installed_versions_only: false,
  },

  selfService: {
    isEnabled: false, // false => item.data.self_service_data === undefined
    category: '', // item.data.self_service_data.category
    isRecommended: false, // item.data.self_service_data.isRecommended
    displayName: '', // item.data.self_service_data.displayName
    shortDescription: '', // item.data.self_service_data.description
    longDescription: '', // item.data.self_service_data.bodyText
    isRequireRead: false, // item.data.self_service_data.mustViewAgreement
    iconUrl: null, // item.data.self_service_data.iconUrl
    iconFile: null,
    size: 0, // item.data.file_size
    version: '', // item.data.version
    displayInfoBtn: false, // ?? item.data.self_service_data.displayInfoBtn ??
    infoBtnText: '', // ?? item.data.self_service_data.infoBtnText ??
    infoBtnUrl: '', // ?? item.data.self_service_data.infoBtnUrl ??
  },

  updates: {
    type: AutomaticAppService.updateTypes.ANY, // item.data.version_enforcement

    delay: 14, // item.data.enforcement_delay in days
    time: '12:00', // item.data.enforcement_time
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // item.data.enforcement_timezone
    afterDate: '', // item.data.enforce_after  "06/23/2021"
    minimumVersion: '', // item.data.minimum_app_version
    minimumAutoAppVersion: {}, // item.data.minimum_auto_app_version
    versions: {}, // item.data._version_dates.
    // example
    // versions: {
    //   '20.006.20034': 1581296400,
    //   '20.006.20042': 1584403200,
    //   '20.013.20064': 1604361600,
    // },
    versionsAutoApps: [], // item.data._version_dates_auto_apps
  },

  options: {
    addToDock: false, // item.data.add_to_dock
  },

  notifications: {
    is_managed: true,
    is_available: true,
    settings: {
      NotificationsEnabled: true,
      AlertType: AutomaticAppService.notificationAlertTypeOptions.BANNERS.value,
      BadgesEnabled: true,
      CriticalAlertEnabled: false,
      GroupingType:
        AutomaticAppService.notificationGroupingTypeOptions.AUTOMATIC.value,
      PreviewType:
        AutomaticAppService.notificationPreviewTypeOptions.WHEN_LOCKED.value,
      ShowInLockScreen: true,
      ShowInNotificationCenter: true,
      SoundsEnabled: true,
    },
  },

  supportsRules: true,
};

export default initialState;
