import { Flex, Table, styled } from '@kandji-inc/nectar-ui';
import { memo } from 'react';
import featureFlags from 'src/config/feature-flags';
import type {
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
} from '../../../threat.types';
import ThreatListLoader from '../ThreatListLoader';
import ThreatListEmptyStates from './ThreatListEmptyStates';
import ThreatListTableHead from './ThreatListTableHead';
import ThreatListTablePaginationBar from './ThreatListTablePaginationBar';

const Container = styled(Flex, {
  height: '100%',
  position: 'relative',
  minHeight: 440,
  flexFlow: 'column',
  justifyContent: 'space-between',
  pb: 90,
});

const TableStyled = styled(Table, {
  '& th:last-child, td:last-child': {
    padding: 0,
    paddingRight: '$1',
    textAlign: 'right',
    $$cellActiveBg: 'none',
  },
  '& tr > td:first-child': {
    padding: 0,
    $$cellActiveBg: 'none',
  },
  // overrides anchor resets from `_reboot.scss`
  ':where(th, td) a': {
    color: '$neutral90',
  },
});

const TableStickyContainer = styled(Flex, {
  width: '100%',
  backgroundColor: 'rgba(255,255,255,0.85)',
  flow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  height: 'calc(-214px + 100vh)',
  maxHeight: '100%',
  position: 'sticky',
  top: 80,
});

const TablePlaceholder = styled(Flex, {
  height: 'calc(100% - 36px)',
  position: 'absolute',
  width: '100%',
  top: 36,
});

const PaginationContainer = styled(Flex, {
  marginInline: '-$7',
  paddingInline: '$7',
  pb: 90,
  backgroundColor: '$neutral0',
  boxShadow:
    '$shadows$inset_border_bottom_1 $colors$neutral0, $shadows$inset_border_top_1 $colors$neutral30',
  position: 'fixed',
  bottom: 0,
  alignItems: 'center',
  zIndex: 1,
});

type ThreatListTableProps = Readonly<{
  dataCount: number | undefined;
  isLoading: boolean;
  isError: boolean;
  error: string | undefined;
  sort: SortColumnState;
  onColumnSort: (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => void;
  pagination: PaginationState;
  onPaginationChange: (page: number, pageSize: PageSize) => void;
  displayDetectionPeriod: string;
  isFiltering: boolean;
  showToast: ShowToast;
  columns: TableHeaderColumn[];
  isScrollable?: boolean;
  isSelectable?: boolean;
  children: React.ReactNode;
  onAllPageSelected?: (isSelected: boolean) => void;
  isAllPageSelected?: boolean;
  isPaginationPageSizeShown?: boolean;
  bulkActions?: React.ReactNode;
  isLoadingForSelecting?: boolean;
  isOnDeviceRecord?: boolean | undefined;
}>;

const ThreatListTable = (props: ThreatListTableProps) => {
  const {
    dataCount,
    isLoading,
    isError,
    error,
    sort,
    onColumnSort,
    pagination,
    onPaginationChange,
    displayDetectionPeriod,
    isFiltering,
    columns,
    isScrollable = false,
    isSelectable = false,
    children,
    onAllPageSelected,
    isAllPageSelected = false,
    isPaginationPageSizeShown = true,
    bulkActions,
    isLoadingForSelecting = false,
    isOnDeviceRecord,
  } = props;

  if (isError) {
    throw new Error(`Data fetching/validating error: ${error}`);
  }

  const isVulnOn = featureFlags.getFlag('vm_06252024_vuln-poc');

  const isEmpty: boolean = dataCount === 0;
  const isEmptyOrLoading: boolean = isEmpty || isLoading;

  const handlePaginationChange = (newPage: number, newPageSize: PageSize) => {
    onPaginationChange(newPage, newPageSize);
  };

  return (
    <>
      <Container
        css={{
          overflow: isScrollable ? 'scroll' : 'unset',
        }}
      >
        <TableStyled aria-label="Threats" data-testid="threats-table">
          <ThreatListTableHead
            sort={sort}
            onColumnSort={onColumnSort}
            isSortingDisabled={isEmptyOrLoading}
            columns={columns}
            isSticky={!isScrollable}
            isSelectable={isSelectable}
            isSelectionDisabled={
              isSelectable && (isEmptyOrLoading || isLoadingForSelecting)
            }
            onAllSelected={onAllPageSelected}
            isAllSelected={isAllPageSelected}
          />
          {children}
        </TableStyled>
        {isEmptyOrLoading && (
          <TablePlaceholder>
            <TableStickyContainer>
              {isEmpty && !isLoading && (
                <ThreatListEmptyStates
                  isFiltering={isFiltering}
                  displayDetectionPeriod={displayDetectionPeriod}
                  isV3={isSelectable}
                />
              )}
              {isLoading && <ThreatListLoader label="Loading Threats" />}
            </TableStickyContainer>
          </TablePlaceholder>
        )}
        <PaginationContainer
          pt3
          /* Adjust width to account for when table is beneath tabs */
          css={{
            width:
              isVulnOn || isOnDeviceRecord
                ? 'calc(100% - 210px)'
                : 'calc(100% - 256px)',

            '.full-width-sidebar-closed &': {
              width:
                isVulnOn || isOnDeviceRecord
                  ? 'calc(100% - 30px)'
                  : 'calc(100% - 77px)',
            },
          }}
        >
          <ThreatListTablePaginationBar
            dataCount={dataCount}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            bulkActions={bulkActions}
            isPageSizeShown={isPaginationPageSizeShown}
          />
        </PaginationContainer>
      </Container>
    </>
  );
};

export default memo(ThreatListTable);
