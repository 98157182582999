import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import {
  Box,
  Code,
  Flex,
  Icon,
  Tbody,
  Td,
  Text,
  Tr,
  styled,
} from '@kandji-inc/nectar-ui';
import { AccountContext } from 'contexts/account';
import { Fragment, useContext } from 'react';
import { constants } from '../../common';
import CopyButton from '../../common/components/CopyButton';
import ThreatListDevicesCountChip from '../../common/components/ThreatListDevicesCountChip';
import ThreatListHighlightedText from '../../common/components/ThreatListHighlightedText';
import TableActionsDropdown from '../../common/components/ThreatListTable/TableActionsDropdown';
import ThreatListTableCheckbox from '../../common/components/ThreatListTable/ThreatListTableCheckbox';
import getFormatCount from '../../common/utils/getFormatCount';
import isoToDateString from '../../common/utils/isoToDateString';
import type { ThreatClassification, ThreatGroup } from '../../threat.types';
import ThreatListStatusSummaryBadges from '../ThreatListStatusSummaryBadges';

const TextTruncate = styled(Text, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const HashCell = styled(Flex, {
  alignItems: 'center',
  '& > div': {
    visibility: 'hidden',
  },
  '&:hover': {
    '& > div': {
      visibility: 'visible',
    },
  },
});

const getClassificationLabel = (
  classification: ThreatClassification,
  shortPUP: boolean = false,
) => {
  if (shortPUP && classification === 'PUP') {
    return classification;
  }
  return constants.THREAT_CLASSIFICATION_LABELS[classification];
};

const getClassifactionIcon = (classification: ThreatClassification) =>
  constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

const getRowActionOptions = (item: ThreatGroup) => {
  const actionOptions: DropdownMenuOption[] = [
    {
      label: 'Search VirusTotal',
      icon: 'virus-total',
      onClick: /* istanbul ignore next -- Linking */ () =>
        window.open(`${constants.VIRUS_TOTAL_URL}${item.threat_id}`),
    },
  ];

  return actionOptions;
};

type ThreatListTableBodyProps = Readonly<{
  threats: ThreatGroup[];
  query: string | undefined;
  isLoading: boolean;
  isRowSelected: (key: string) => boolean;
  onSelectRow: (key: string, isSelected: boolean) => void;
  isSelectionDisabled: boolean;
  onOpenSidePanel: (threat: ThreatGroup) => void;
}>;

const ThreatListTableBody = (props: ThreatListTableBodyProps) => {
  const {
    threats,
    query,
    isLoading,
    isRowSelected,
    onSelectRow,
    isSelectionDisabled,
    onOpenSidePanel,
  } = props;
  const { userSettings } = useContext(AccountContext);
  const timezone = userSettings?.timezone;

  return (
    <Tbody>
      {threats.map((item) => {
        const id = `${item.threat_id}`;
        const isSelected = isRowSelected(id) && !isLoading;
        const actionOptions = getRowActionOptions(item);
        const devicesCount = item.device_count;
        const notQuarantinedCount = item.not_quarantined_count;
        const quarantinedCount = item.quarantined_count;
        const resolvedCount = item.resolved_count;
        const releasedCount = item.released_count;

        return (
          <Fragment key={id}>
            <Tr
              hoverAnchorUnderline={false}
              selected={isSelected}
              onClick={() => onOpenSidePanel(item)}
              css={{ cursor: 'pointer' }}
            >
              <Td title={isSelected ? 'Selected' : 'Unselected'}>
                <ThreatListTableCheckbox
                  selected={isSelected}
                  onToggle={(isSelected: boolean) =>
                    onSelectRow(id, isSelected)
                  }
                  disabled={isSelectionDisabled}
                  testId="select-row-button"
                />
              </Td>
              <Td title={item.threat_id}>
                <HashCell gap="xs">
                  <TextTruncate>
                    <ThreatListHighlightedText search={query}>
                      {item.threat_id}
                    </ThreatListHighlightedText>
                  </TextTruncate>
                  <CopyButton value={item.threat_id} />
                </HashCell>
              </Td>
              <Td title={item.latest_process_name}>
                <ThreatListHighlightedText search={query}>
                  {item.latest_process_name}
                </ThreatListHighlightedText>
              </Td>
              <Td title={getClassificationLabel(item.classification)}>
                <Flex
                  gap="xs"
                  pr1
                  css={{ '& > svg': { height: 20, width: 20, flexShrink: 0 } }}
                >
                  <Icon
                    name={getClassifactionIcon(item.classification)}
                    size="sm"
                  />
                  <Box>
                    <ThreatListHighlightedText search={query}>
                      {getClassificationLabel(item.classification, true)}
                    </ThreatListHighlightedText>
                  </Box>
                </Flex>
              </Td>
              <Td title={`${item.latest_action_date}Z`}>
                {isoToDateString(item.latest_action_date, timezone, false, {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}
              </Td>
              <Td title="Devices impacted">
                <ThreatListDevicesCountChip
                  label={getFormatCount(devicesCount)}
                />
              </Td>
              <Td title="">
                <ThreatListStatusSummaryBadges
                  notQuarantined={notQuarantinedCount}
                  quarantined={quarantinedCount}
                  resolved={resolvedCount}
                  released={releasedCount}
                />
              </Td>
              <Td title="Actions">
                <TableActionsDropdown
                  options={actionOptions}
                  testId="action-ellipsis"
                />
              </Td>
            </Tr>
          </Fragment>
        );
      })}
    </Tbody>
  );
};

export default ThreatListTableBody;
