import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Banner, Checkbox, Chip, Select, Slider } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

import SoftwareUpdateService from '../../service/software-update-service';

const UpdateSettings = ({ update, isDisabled, settings }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">Update settings</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">Defer macOS updates</p>
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            Delay the availability of new macOS updates up to 90 days. Beta seed
            builds are exempt from deferral on any device enrolled in an Apple
            beta software program.{' '}
            <a
              href="https://support.apple.com/guide/deployment/depc4c80847a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more...
            </a>
          </p>

          <Banner theme="info" kind="block">
            <p>
              This deferral period does not apply to updates that are deployed
              by MDM.
            </p>
          </Banner>
        </Setting.Helpers>
        <Setting.Controls>
          <Select
            compact
            inputId="defer_macos_updates_select"
            options={Object.values(
              SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS,
            )}
            onChange={(selected) => update('deferMacOSUpdates', selected.value)}
            value={Object.values(
              SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS,
            ).find((opt) => opt.value === settings.deferMacOSUpdates)}
            disabled={isDisabled}
          />
        </Setting.Controls>
        {settings.deferMacOSUpdates ===
          SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferAll.value && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Delay the availability of all macOS updates and upgrades. The
                  user will see available updates{' '}
                  {settings.enforcedSoftwareUpdateDelay} days after the release
                  of the update.
                </p>

                <Chip kind="tertiary-light" text="macOS 10.13+" />
              </Setting.Helpers>
              <Setting.Controls>
                <div data-testid="enforce_software_update_container">
                  <div className="b-txt-light b-mb1">
                    New updates available after{' '}
                    <Chip
                      style={{ display: 'inline' }}
                      text={`${settings.enforcedSoftwareUpdateDelay} days`}
                    />
                  </div>
                  <Slider
                    className="b-mb2"
                    value={settings.enforcedSoftwareUpdateDelay}
                    onChange={(value) =>
                      update('enforcedSoftwareUpdateDelay', value)
                    }
                    disabled={isDisabled}
                    min={1}
                    max={90}
                    getMarkerLabel={(val) => `${val} days`}
                    markedValues={[1, 30, 60, 90]}
                  />
                </div>
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
        {settings.deferMacOSUpdates ===
          SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferByType
            .value && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Delay the availability of major macOS updates (upgrades). The
                  user will see the major OS upgrade only after the specified
                  delay after the release of the upgrade.
                </p>

                <Chip kind="tertiary" text="macOS 11.3+" />
              </Setting.Helpers>
              <Setting.Controls>
                <Checkbox
                  label="Defer major macOS updates (upgrades)"
                  id="defer_major_macos_updates"
                  testId="defer_major_macos_updates_checkbox"
                  checked={settings.forceDelayedMajorSoftwareUpdates}
                  onChange={() =>
                    update(
                      'forceDelayedMajorSoftwareUpdates',
                      (checked) => !checked,
                    )
                  }
                  disabled={isDisabled}
                />
                <div className="b-txt-light b-mb1 b-mt1">
                  New updates available after{' '}
                  <Chip
                    style={{ display: 'inline' }}
                    text={`${settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay} days`}
                  />
                </div>
                <Slider
                  className="b-mb2"
                  value={
                    settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay
                  }
                  onChange={(value) =>
                    update(
                      'enforcedSoftwareUpdateMajorOSDeferredInstallDelay',
                      value,
                    )
                  }
                  disabled={isDisabled}
                  min={1}
                  max={90}
                  getMarkerLabel={(val) => `${val} days`}
                  markedValues={[1, 30, 60, 90]}
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Delay the availability of minor macOS updates. The user will
                  see the minor OS update only after the specified delay after
                  the release of the update.
                </p>

                <Chip kind="tertiary" text="macOS 10.13+" />

                <Banner theme="info" kind="block">
                  <p>
                    On macOS versions earlier than 11.3 this option will control
                    both major and minor OS deferral.
                  </p>
                </Banner>
              </Setting.Helpers>
              <Setting.Controls>
                <Checkbox
                  label="Defer minor macOS updates"
                  id="defer_minor_macos_updates"
                  testId="defer_minor_macos_updates_checkbox"
                  checked={settings.forceDelayedMinorSoftwareUpdates}
                  onChange={() =>
                    update(
                      'forceDelayedMinorSoftwareUpdates',
                      (checked) => !checked,
                    )
                  }
                  disabled={isDisabled}
                />
                <div className="b-txt-light b-mb1 b-mt1">
                  New updates available after{' '}
                  <Chip
                    style={{ display: 'inline' }}
                    text={`${settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay} days`}
                  />
                </div>
                <Slider
                  className="b-mb2"
                  value={
                    settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay
                  }
                  onChange={(value) =>
                    update(
                      'enforcedSoftwareUpdateMinorOSDeferredInstallDelay',
                      value,
                    )
                  }
                  disabled={isDisabled}
                  min={1}
                  max={90}
                  getMarkerLabel={(val) => `${val} days`}
                  markedValues={[1, 30, 60, 90]}
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Delay the availability of non-OS updates, such as supplemental
                  updates for Safari, the user will see the update only after
                  the specified delay after the release of the update.
                </p>

                <Chip kind="tertiary" text="macOS 11.3+" />
              </Setting.Helpers>
              <Setting.Controls>
                <Checkbox
                  label="Defer non-OS updates"
                  id="defer_non_os_updates"
                  testId="defer_non_os_updates_checkbox"
                  checked={settings.forceDelayedAppSoftwareUpdates}
                  onChange={() =>
                    update(
                      'forceDelayedAppSoftwareUpdates',
                      (checked) => !checked,
                    )
                  }
                  disabled={isDisabled}
                />
                <div className="b-txt-light b-mb1 b-mt1">
                  New updates available after{' '}
                  <Chip
                    style={{ display: 'inline' }}
                    text={`${settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay} days`}
                  />
                </div>
                <Slider
                  className="b-mb2"
                  value={
                    settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay
                  }
                  onChange={(value) =>
                    update(
                      'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
                      value,
                    )
                  }
                  disabled={isDisabled}
                  min={1}
                  max={90}
                  getMarkerLabel={(val) => `${val} days`}
                  markedValues={[1, 30, 60, 90]}
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
      <Setting.Row>
        <Setting.Helpers>
          <p className="b-txt-light">
            Before software updates can be installed, macOS will prompt Standard
            user accounts to enter administrator credentials.
          </p>

          <Banner theme="info" kind="block">
            <p>
              If this option is enabled, turning off all “Automatic updates”
              settings is recommended. Otherwise, Standard user accounts will be
              prompted to enter administrator credentials every time macOS
              attempts to install updates.
            </p>
          </Banner>
        </Setting.Helpers>
        <Setting.Controls>
          <Checkbox
            label="Restrict software updates to admin users"
            id="restrict_software_updates_to_admin_users"
            testId="restrict_software_updates_to_admin_users_checkbox"
            checked={
              settings['restrict-software-update-require-admin-to-install']
            }
            onChange={() =>
              update(
                'restrict-software-update-require-admin-to-install',
                (checked) => !checked,
              )
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Helpers>
          <p className="b-txt-light">
            Prevent devices from being enrolled in an Apple beta software
            program. Devices that are already enrolled in a beta program will be
            unenrolled.{' '}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000560359"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more...
            </a>
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <Checkbox
            label="Disallow macOS beta release installation"
            id="disallow_macos_beta_release_installation"
            testId="disallow_macos_beta_release_installation_checkbox"
            checked={!settings.AllowPreReleaseInstallation}
            onChange={() =>
              update('AllowPreReleaseInstallation', (checked) => !checked)
            }
            disabled={isDisabled}
          />
        </Setting.Controls>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

UpdateSettings.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(UpdateSettings);
