import { INT_TYPES } from '../../../../constants';
import welcomTickets, {
  oktaSignInTickets,
  oktaSignInErrorTickets,
  oktaVerifyAppTickets,
  oktaSetupCompleteTickets,
} from '../../../okta/modals/tickets';
import type { IntegratorViewConfig } from '../../types/integrator.types';
import type { OktaInputs, OktaState } from '../../types/okta.types';

// Helpers
import {
  oktaAddIOSHandleNext,
  oktaAddMacOSHandleNext,
  oktaAddMacOSHandleNextText,
  oktaCredentialsHandleNext,
  oktaCredentialsHandleRetry,
  // FooterStatus Options
  oktaCredentialsStatusOptions,
  oktaDeviceSetupStatusOptions,
  oktaDeviceSetupValidation,
  oktaHandleIntegrationDetails,
  oktaHandleLibraryItemView,
  oktaHandleSetupLater,
  oktaHandleSync,
  oktaHideCancelButton,
  oktaSetupCompleteDescription,
  oktaSetupCompleteStatusOptions,
  oktaSignInHandleNext,
  oktaVerifyLibraryStatusOptions,
} from './helper';

import iosIcon from '../../../okta/assets/ios-icon.svg';
import macOsIcon from '../../../okta/assets/mac-os-icon.svg';
import odtComplete from '../../../okta/assets/odt-complete.svg';
// Icons
import oktaLogo from '../../../okta/assets/okta.svg';
import errorIcon from '../../../okta/modals/assets/error.svg';
import iosOdt from '../../../okta/modals/assets/ios-odt.svg';
import macOsOdt from '../../../okta/modals/assets/macos-odt.svg';
import oktaBtnSm from '../../../okta/modals/assets/okta-button-sm.svg';

// Config
const config: IntegratorViewConfig<OktaInputs, OktaState, object> = {
  type: INT_TYPES.okta,
  name: 'Okta',
  logo: oktaLogo,
  openDialog: true,
  pages: [
    {
      name: 'oktaWelcome',
      layout: 'one-column-info',
      header: {
        title: 'Welcome to Okta Device Trust',
        description:
          'The Okta Device Trust integration enables organizations to protect their corporate resources and ensures that devices are compliant before allowing access to applications through Okta.',
      },
      tickets: welcomTickets,
      nextButtonText: 'Get Started',
      cancelButtonText: 'Cancel',
    },
    {
      name: 'oktaDomain',
      layout: 'one-column-input',
      header: {
        title: 'Specify your Okta Domain',
        description:
          'Enter the Okta domain that will be used to connect the integration.',
      },
      inputs: [
        {
          name: 'oktaDomain',
          type: 'text',
          placeholder: 'domain.okta.com',
          validations: {
            required: 'Okta domain is required',
            maxLength: 255,
          },
        },
      ],
      cancelButtonText: 'Cancel',
    },
    {
      name: 'oktaSignIn',
      layout: 'one-column-info',
      header: {
        title: 'Sign in with Okta',
        description:
          'Sign in to your Okta account to establish the connection between your Kandji and Okta administrative accounts.',
      },
      tickets: oktaSignInTickets,
      nextButtonText: 'Sign in with Okta',
      nextButtonIcon: oktaBtnSm,
      handleNext: oktaSignInHandleNext,
    },
    {
      name: 'oktaCompleteTasks',
      layout: 'one-column-info',
      header: {
        title: 'Complete the following tasks in Okta',
        description:
          'You will need to perform the following actions in your Okta portal before continuing this integration setup. Learn more about each Okta workflow below.',
      },
      tickets: oktaSignInTickets,
    },
    {
      name: 'oktaCredentials',
      layout: 'one-column-input',
      header: {
        title: 'API Service Integration credentials',
        description:
          'Enter the Client ID and Client Secret that was provided when you created the API Service Integration for Kandji in Okta.',
      },
      inputs: [
        {
          name: 'clientId',
          type: 'text',
          label: 'Client ID',
          placeholder: 'Enter the Okta client ID',
          validations: {
            required: 'Required',
            maxLength: {
              value: 25,
              message: 'Must be 25 characters or fewer',
            },
          },
        },
        {
          name: 'clientSecret',
          type: 'text',
          label: 'Client Secret',
          placeholder: 'Enter the Okta client secret',
          validations: {
            required: 'Required',
            maxLength: {
              value: 64,
              message: 'Must be 64 characters or fewer',
            },
          },
        },
      ],
      footerStatusOptions: oktaCredentialsStatusOptions,
      nextButtonText: 'Connect to Okta',
      handleNext: oktaCredentialsHandleNext,
    },
    {
      name: 'oktaCredentialsError',
      layout: 'one-column-info',
      logo: errorIcon,
      header: {
        title: 'Cannot complete integration setup',
        description:
          'It looks like your Okta account does not have the correct settings to finalize the Okta Device Trust integration with Kandji. Please reference the following support articles below.',
      },
      condition: ({ state }) => !!state.verifyResult,
      tickets: oktaSignInErrorTickets,
      nextButtonText: 'Retry',
      handleNext: oktaCredentialsHandleRetry,
    },
    {
      name: 'deviceSetup',
      layout: 'two-column-checkbox',
      header: {
        title: 'Configure device platforms',
        description:
          'Choose to configure Okta Device Trust for both macOS and iOS devices, or select a single device platform. This selection should reflect which device management providers you added in the Okta console in previous steps',
        link: {
          url: 'https://support.kandji.io/support/solutions/articles/72000580346#Configure-device-platforms-in-Okta',
          text: 'Learn More',
        },
      },
      validate: oktaDeviceSetupValidation,
      options: [
        {
          name: 'macOsDevices',
          label: 'macOS devices',
          icon: macOsIcon,
          altText: 'mac os icon',
        },
        {
          name: 'iosDevices',
          label: 'iOS devices',
          icon: iosIcon,
          altText: 'ios icon',
        },
      ],
    },
    {
      name: 'oktaAddMacOS',
      layout: 'two-column-input',
      logo: macOsOdt,
      header: {
        title: 'Add macOS as a device platform',
        description:
          'Provide the following information for macOS devices. Each field was generated in Okta when adding macOS as a device management provider.',
      },
      condition: ({ formInputs }) => !!formInputs.macOsDevices,
      inputs: [
        {
          name: 'scepUrl',
          type: 'text',
          label: 'SCEP URL',
          hint: 'The Simple Certificate Enrollment Protocol (SCEP) URL that was generated in Okta when adding macOS a device management provider.',
          placeholder: 'Enter the base URL for the SCEP server',
          validations: {
            required: 'Required',
            maxLength: {
              value: 250,
              message: 'Must be 250 characters or fewer',
            },
          },
        },
        {
          name: 'username',
          type: 'text',
          label: 'Username',
          placeholder: 'Enter the Okta username',
          validations: {
            required: 'Required',
            maxLength: {
              value: 25,
              message: 'Must be 25 characters or fewer',
            },
          },
        },
        {
          name: 'challengeUrl',
          type: 'text',
          label: 'Challenge URL',
          hint: 'The Challenge URL that was generated in Okta when adding macOS a device management provider.',
          placeholder: 'Enter the URL to retrieve the SCEP challenge',
          validations: {
            required: 'Required',
            maxLength: {
              value: 250,
              message: 'Must be 250 characters or fewer',
            },
          },
        },
        {
          name: 'password',
          type: 'password',
          label: 'Password',
          placeholder: 'Enter the password',
          validations: {
            required: 'Required',
            maxLength: {
              value: 50,
              message: 'Must be 50 characters or fewer',
            },
          },
        },
      ],
      footerStatusOptions: oktaDeviceSetupStatusOptions,
      nextButtonText: oktaAddMacOSHandleNextText,
      handleNext: oktaAddMacOSHandleNext,
    },
    {
      name: 'oktaAddIOS',
      layout: 'one-column-input',
      logo: iosOdt,
      header: {
        title: 'Add iOS as a device platform',
        description:
          'Provide the following information for iOS devices. The secret key was generated in Okta when adding iOS as a device management provider.',
      },
      condition: ({ formInputs }) => !!formInputs.iosDevices,
      inputs: [
        {
          name: 'secretKey',
          type: 'password',
          label: 'Secret key',
          hint: 'The secret key that was generated in Okta when adding iOS as a device management provider.',
          placeholder: 'Enter the secret key',
          validations: {
            required: 'Required',
            maxLength: {
              value: 50,
              message: 'Must be 50 characters or fewer',
            },
          },
        },
      ],
      footerStatusOptions: oktaDeviceSetupStatusOptions,
      nextButtonText: 'Finish setup',
      handleNext: oktaAddIOSHandleNext,
    },
    {
      name: 'oktaVerifyLibrary',
      layout: 'one-column-info',
      header: {
        title: ' The Okta Verify app is not in your Library',
        description:
          'You can use Kandji&apos;s integration with Apps and Books in Apple Business Manager to install Okta Verify on your devices. ',
      },
      condition: ({ state }) => !state.verifyLibrary,
      tickets: oktaVerifyAppTickets,
      links: [
        { url: 'https://business.apple.com/', title: 'Apple Business Manager' },
        { url: 'https://school.apple.com/', title: 'Apple School Manager' },
      ],
      footerStatusOptions: oktaVerifyLibraryStatusOptions,
      cancelButtonText: 'Set this up later',
      handleClose: oktaHandleSetupLater,
      nextButtonText: 'Sync now',
      handleNext: oktaHandleSync,
    },
    {
      name: 'oktaSetupComplete',
      layout: 'one-column-info',
      logo: odtComplete,
      header: {
        title: 'Okta Device Trust setup complete',
        description: oktaSetupCompleteDescription,
      },
      tickets: oktaSetupCompleteTickets,
      footerStatusOptions: oktaSetupCompleteStatusOptions,
      showCancelButton: oktaHideCancelButton,
      handleClose: oktaHandleLibraryItemView,
      nextButtonText: 'View integration settings',
      handleNext: oktaHandleIntegrationDetails,
    },
  ],
};

export default config;
