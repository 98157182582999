import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { useHistory, withRouter } from 'react-router';
import styled from 'styled-components';
import image404 from '../assets/img/404.svg';
import logo from '../assets/img/new_icons/kandji_logo_horizontal_light.svg';

const Header = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 75px;
  background: #1a1d25;
  padding: 21px 0 18px 30px;
`;

const Wrapper = styled('div')`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const H1 = styled('h1')`
  font-family: var(--font-family-primary);
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #1a1d25;
  margin-top: 70px;
  margin-bottom: 12px;
`;

const Text = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1a1d25;
`;

const ButtonsWrapper = styled('div')`
  width: 430px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-top: 32px;
`;

const Margin = styled('div')`
  margin-left: 10px;
`;

const Fragment = styled('div')`
  z-index: 20000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #f0f2f7;
`;

export const Whoops404 = () => {
  const history = useHistory();
  const goHome = () => {
    history.push('/');
  };
  return (
    <Fragment>
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <Wrapper className="mx-auto">
        <img
          src={image404}
          alt="404"
          style={{ margin: '0 auto', width: 'fit-content' }}
        />
        <H1>404 Error. This page doesn’t exist</H1>
        <Text>Go back to the Kandji homepage or visit our help center.</Text>
        <ButtonsWrapper>
          <Button
            kind="link"
            onClick={() => {
              window.location.href = 'https://support.kandji.io/';
              return null;
            }}
          >
            Visit Help Center
          </Button>
          <Margin />
          <Button onClick={goHome}>Back to the homepage</Button>
        </ButtonsWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default withRouter(Whoops404);
