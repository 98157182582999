import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSnackbar as callSetSnackbar } from '../../../_actions/ui';
import { deleteUser as callDeleteUser } from '../../../_actions/users';
import AwesomeCheckbox from '../AwesomeCheckbox';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

export class UserDelete extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'UserDelete';
    this.state.isAccepted = false;
    this.idsToDelete = props.info.idsToDelete;
    this.delete = props.info.delete;
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const { setSnackbar } = this.props;
    const { isAccepted } = this.state;
    if (!isAccepted) {
      return null;
    }
    this.setState({ isLoading: true }, () => {
      this.delete(this.idsToDelete)
        .then(() => {
          setSnackbar(
            `Selected ${
              this.idsToDelete.length === 1 ? 'user was' : 'users were'
            } deleted`,
          );
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          setSnackbar('Something went wrong');
        })
        .finally(() => this.setState({ isLoading: false }));
    });
    return null;
  }

  renderHeader = () => <p>Delete User</p>;

  renderBody() {
    const { isAccepted } = this.state;
    const text = this.idsToDelete.length > 1 ? 'these users' : 'this user';
    return (
      <>
        <p style={{ color: '#f05b7e' }}>You are about to delete {text}</p>
        <p>This cannot be undone. Please confirm you want to delete {text}.</p>
        <AwesomeCheckbox
          label={`I understand ${text}, its records and all related info will be deleted. This action cannot be undone.`}
          onCheck={() => this.setState({ isAccepted: !isAccepted })}
          checked={isAccepted}
          keyId="deleteCheckbox"
          margin={0}
          checkboxPosition="start"
        />
      </>
    );
  }

  renderFooter() {
    const { isLoading, isAccepted } = this.state;
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={isLoading || !isAccepted}
          onClick={this.onDelete}
          theme="error"
        >
          {!isLoading ? 'Delete' : 'Deleting'}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
      deleteUser: callDeleteUser,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserDelete);
