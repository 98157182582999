import React, { useContext, useCallback, useMemo, memo } from 'react';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router-dom';

import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import { Button } from 'theme/components/molecules/Action';
import Dropdown from '../../theme/components/atoms/Dropdown';
import Icon from '../../theme/components/atoms/Icon';
import Table, { TablePagination } from '../../theme/components/atoms/Table';

import { Context } from './context';

export default memo(() => {
  const { formatTimeWithTZ } = useContext(AccountContext);
  const { onModalOpen } = useContext(InterfaceContext);
  const {
    data,
    setSelectedItemId,
    setSelectedItemLabel,
    pathToTokenPage,
    isWorking,
  } = useContext(Context);
  const history = useHistory();

  const rowClasses = useCallback((row) => {
    if (row.is_enabled) {
      return 'ea-table-active-row';
    }
    return 'ea-table-revoked-row';
  }, []);

  const onAdd = useCallback(() => onModalOpen('add'), []);

  const formatterStatus = useCallback((cell, row) => {
    const field = row.is_enabled;
    const toggle = field ? 'on' : 'off';
    const label = field ? 'active' : 'inactive';

    return (
      <div className={`status status-${toggle} chip`}>
        <span>{label}</span>
      </div>
    );
  }, []);
  const formatterLastUsedAt = useCallback(
    (cell, row) =>
      row.last_used_at !== null ? formatTimeWithTZ(row.last_used_at) : '-',
    [formatTimeWithTZ],
  );
  const formatterCreatedAt = useCallback(
    (cell, row) =>
      row.created_at !== null ? formatTimeWithTZ(row.created_at) : '-',
    [formatTimeWithTZ],
  );
  const formatterAction = useCallback(
    (cell, row) => {
      const id = row.id;
      const name = row.label || 'no-name';

      const onView = () => {
        setSelectedItemId(id);
        setSelectedItemLabel(name);
        history.push(pathToTokenPage + id);
      };
      const onRevoke = () => {
        setSelectedItemId(id);
        onModalOpen('delete');
      };

      const label = <Icon icon="ellipsis" />;
      const render = (toggle, setToggle) => (
        <div className="dropdown-menu-body dropdown-menu-body-default">
          <ul className="dropdown-menu-list">
            <li className="dropdown-menu-list-item">
              <Button
                kind="hollow"
                tier="two"
                label="View"
                icon="eye"
                onClick={onView}
              />
            </li>
            <li className="dropdown-menu-list-item">
              <Button
                kind="hollow"
                tier="three"
                label="Revoke"
                icon="trash"
                onClick={onRevoke}
              />
            </li>
          </ul>
        </div>
      );

      return (
        <Dropdown
          type="action"
          alignment="right"
          name={name}
          label={label}
          render={render}
        />
      );
    },
    [setSelectedItemId, setSelectedItemLabel, onModalOpen],
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'id',
        text: 'ID',
        hidden: true,
        isKey: true,
      },
      {
        dataField: 'label',
        text: 'Name',
        sort: true,
        searchable: true,
        events: {
          onClick: (x, y, z, row) => {
            setSelectedItemId(row.id);
            setSelectedItemLabel(row.name);
            history.push(pathToTokenPage + row.id);
          },
        },
        formatter: (cell, row) => (
          <a className="b-txt-bold decorate-off" style={{ cursor: 'pointer' }}>
            {row.label}
          </a>
        ),
      },
      {
        dataField: 'is_enabled',
        text: 'Status',
        formatter: formatterStatus,
      },
      {
        dataField: 'created_by',
        text: 'Created By',
        sort: true,
        searchable: true,
      },
      {
        dataField: 'last_used_at',
        text: 'Last Accessed',
        sort: true,
        formatter: formatterLastUsedAt,
      },
      {
        dataField: 'created_at',
        text: 'Creation Date',
        sort: true,
        formatter: formatterCreatedAt,
      },
      {
        dataField: '',
        text: '',
        isDummyField: true,
        headerStyle: {
          width: '60px',
          textAlign: 'right',
        },
        formatter: formatterAction,
        formatExtraData: { isWorking },
      },
    ],
    [formatterStatus, formatterLastUsedAt, formatterCreatedAt, formatterAction],
  );

  const pagination = useMemo(
    () =>
      paginationFactory({
        custom: true,
        totalSize: data.length,
      }),
    [data.length],
  );

  return (
    <PaginationProvider pagination={pagination}>
      {({ paginationProps, paginationTableProps }) => (
        <>
          <Table
            keyField="id"
            columns={columns}
            data={data}
            filter={null}
            rowClasses={rowClasses}
            {...paginationTableProps}
          />
          <div className="table-footer">
            <Button
              kind="hollow"
              tier="two"
              label="Add Token"
              icon="plus-circle-hollow"
              onClick={onAdd}
            />

            {}
            <TablePagination {...paginationProps} />
          </div>
        </>
      )}
    </PaginationProvider>
  );
});
