import {
  Checkbox,
  LazyScriptEditor,
  Select,
  mapInvalidIndexToField,
  useInvalidations,
  useRefs,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';

import { Setting } from 'features/library-items/template';

import { Credential, Kerberos, Redirect } from './extensions';
import { extensionTypeOptions, extensionTypes } from './extensions/constants';

const extensionTypeToCpt = {
  [extensionTypes.credential]: Credential,
  [extensionTypes.redirect]: Redirect,
  [extensionTypes.kerberos]: Kerberos,
};

const Details = (props) => {
  const { setting, update, validationDep, isDisabled, isSubmitted } = props;
  const [isTouched, setIsTouched] = useState(false);

  const fieldsToValidate = ['extensionDetails'];
  const refs = useRefs(fieldsToValidate.length);
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: fieldsToValidate.length,
  });

  const hasValidConfigValue = /^<dict>.*<\/dict>$/s.test(
    setting.extension_details,
  );

  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    fieldsToValidate,
    refs,
  );
  const isExtensionDetails = setting.isExtensionDetails;

  const validate = () => {
    if (isExtensionDetails && !hasValidConfigValue) {
      onInvalidate(0)('Configuration must be wrapped with <dict>');
    } else {
      onInvalidate(0)(false);
    }
  };

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  useEffect(() => {
    validate();
  }, [setting.value, isExtensionDetails, hasValidConfigValue, onInvalidate]);

  useEffect(() => {
    if (isDisabled || isSubmitted) {
      setIsTouched(false);
    }
  }, [isDisabled, isSubmitted]);

  useEffect(() => {
    setIsTouched(false);
    onInvalidate(0)(false);
  }, [setting.type]);

  const RestDetailsComponent = extensionTypeToCpt[setting.type];

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Extension details</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title className="b-txt">Extension type</Setting.Title>
          <Setting.Helpers className="b-txt-light">
            <p>The type of sign-on authorization being used.</p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              options={extensionTypeOptions}
              onChange={({ value }) => update('type', value)}
              value={extensionTypeOptions.find(
                ({ value }) => value === setting.type,
              )}
              disabled={isDisabled}
              compact
            />
          </Setting.Controls>
        </Setting.Row>
        <RestDetailsComponent
          setting={setting}
          update={update}
          validationDep={validationDep}
          isDisabled={isDisabled}
        />
        {setting.type && setting.type !== extensionTypes.kerberos && (
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                label="Set additional extension configuration"
                checked={isExtensionDetails}
                isDisabled={isDisabled}
                onChange={() => update('isExtensionDetails', (p) => !p)}
              />
            </Setting.Controls>
            <Setting.Helpers className="b-txt-light">
              <p>
                Optionally specify any additional configuration data required by
                the extension.
              </p>
            </Setting.Helpers>

            {isExtensionDetails && (
              <Setting.SecondaryControls className="k-autoapp-config__controls">
                <div className="k-vpp2-config">
                  <p ref={refs[0]} className="b-txt b-mb1">
                    Configuration dictionary
                  </p>
                  <div
                    onBlur={() => {
                      setIsTouched(true);
                      validate();
                    }}
                  >
                    <LazyScriptEditor
                      value={setting.extension_details}
                      onChange={(v) => update('extension_details', v)}
                      language="python"
                      placeholder="<dict><key>ExtensionConfigKey</key><true/></dict>"
                      error={
                        ((isSubmitted || isTouched) && invalidations[0]) || ''
                      }
                      options={{ readOnly: isDisabled }}
                      className={isDisabled && 'k-mobile-config-info__preview'}
                    />
                  </div>
                </div>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Details;
