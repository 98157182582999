import {
  Flex,
  Heading,
  Icon,
  Link,
  Text,
  Tooltip,
} from '@kandji-inc/nectar-ui';

import useVulnerability from '../../store';

const Header = () => {
  const selectedVulnerability = useVulnerability(
    (state) => state.selectedVulnerability,
  );
  const { cve_id, cve_link, app_name, versions } =
    selectedVulnerability.cveInfo;

  return (
    <Flex
      justifyContent="start"
      gap="sm"
      alignItems="center"
      css={{ flexGrow: 1 }}
    >
      <Flex
        p2
        css={{
          backgroundColor: '$red10',
          borderRadius: '$round',
          height: '40px',
          width: '40px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon name="malware" color="var(--colors-red60)" />
      </Flex>
      <Flex flow="column">
        <Flex gap="sm">
          <Heading size="3" css={{ fontWeight: '$medium' }}>
            {cve_id}
          </Heading>
          <Link
            href={cve_link}
            target="_blank"
            rel="noopener noreferrer"
            css={{ paddingTop: '$1' }}
          >
            <Icon
              name="arrow-up-right-from-square"
              size="sm"
              color="var(--colors-neutral70)"
            />
          </Link>
        </Flex>
        <Flex gap="xs">
          {app_name && <Text css={{ color: '$neutral70' }}>{app_name}</Text>}
          {app_name && versions && <Text css={{ color: '$neutral70' }}>|</Text>}
          {versions && (
            <Flex gap="xs">
              <Text
                css={{ color: '$neutral70' }}
              >{`${versions[0]}${versions.length > 1 ? ',' : ''}`}</Text>
              {versions.length > 1 && (
                <Tooltip
                  content={versions.slice(1).join(', ')}
                  side="bottom"
                  theme="dark"
                  css={{ zIndex: 100 }}
                >
                  <Text css={{ color: '$neutral70' }}>
                    {' '}
                    +{versions.length - 1}
                  </Text>
                </Tooltip>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Header };
