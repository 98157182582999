/* istanbul ignore file */

import { Icon } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';
import { useOldField } from 'src/old-honey-form';
import styled from 'styled-components';
import { FieldTextarea } from 'theme/components/molecules/Form';
import { slugify } from 'theme/function';
import isValidCert from './certificateRegex';

const DashedArea = styled('div')`
  background-color: #fff;
  border: 2px dashed #1a1d2526;
  border-radius: 5px;
  min-height: 130px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    border-color: #1a1d2566;
  }
  &:focus {
    border-color: #0057da;
  }
  &.highlight {
    border-color: #0057da;
  }
`;

const UploadAreaIcon = styled('i')`
  margin-bottom: 19px;
  font-size: 35px;
  color: #1a1d2566;
`;

const UploadCancelIcon = styled(Icon)`
  margin-top: -5px;
  font-size: 18px;
  color: #ee3434;
  float: right;
  cursor: pointer;
`;

const UploadAreaText = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #1a1d25ff;
`;

const FileUpload = ({ name, label, detail, disabled, required, ...props }) => {
  const id = slugify(name);
  const fileInput = useRef();
  const { setValue, getValue } = useOldField(name);
  const { setError, getError } = useOldField(name);
  const error = getError();

  const isValidFileExtension = (file) =>
    file.name.endsWith('.pem') ||
    file.name.endsWith('.cer') ||
    file.name.endsWith('.cert');
  const validateFile = (file) => {
    if (file) {
      if (!isValidFileExtension(file)) {
        setValue();
        setError("This doesn't appear to be a valid certificate.");
      } else {
        setError('');
        (async () => {
          const fileContentsRaw = await file.text();
          const fileContents = fileContentsRaw.trim();
          setValue(fileContents);
          if (!isValidCert(fileContents)) {
            setError("This doesn't appear to be a valid certificate.");
          }
          // console.log(fileContents);
        })();
      }
    }
  };

  useLayoutEffect(() => {
    const dropArea = document.getElementById('drop-area');
    const handleDrop = (e) => validateFile(e.dataTransfer.files[0]);
    const highlight = () => dropArea.classList.add('highlight');
    const unhighlight = () => dropArea.classList.remove('highlight');
    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    if (dropArea) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });
      dropArea.addEventListener('drop', handleDrop, false);
    }
  }, []);

  const handleFile = (e) => validateFile(e.target.files[0]);
  const handleClick = () => fileInput.current && fileInput.current.click();
  const clearFile = () => {
    setError();
    setValue();
  };
  return (
    <>
      <input
        hidden
        ref={fileInput}
        type="file"
        accept=".cer,.cert,.pem"
        className="theme-form-text-upload"
        disabled={disabled}
        onChange={handleFile}
      />
      {getValue() ? (
        <>
          <UploadCancelIcon icon="trash-can" onClick={clearFile} />
          <FieldTextarea
            name={name}
            label="Signing Certificate Upload"
            detail="Upload the signing certificate (PEM or CER)"
            required
            disabled
          />
        </>
      ) : (
        <div
          className={`theme-form-text theme-form-block-${
            error ? 'error' : 'valid'
          }`}
        >
          {label && (
            <>
              <label htmlFor={id} className="theme-form-text-label">
                {label}
                {required && <i>*</i>}
                {!required && <span>(optional)</span>}
              </label>
            </>
          )}
          {detail && typeof detail === 'string' ? (
            <p className="theme-form-text-detail">{detail}</p>
          ) : (
            detail
          )}
          <DashedArea onClick={handleClick} id="drop-area">
            <UploadAreaIcon className="fal fa-file-alt" />
            <UploadAreaText>Drop here or click to upload</UploadAreaText>
          </DashedArea>
          {error && <p className="theme-form-text-error">{error}</p>}
        </div>
      )}
    </>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FileUpload.defaultProps = {
  detail: undefined,
  disabled: false,
  required: false,
};

export default FileUpload;
