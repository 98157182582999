import { Icon, Th, Thead, Tr, css, styled } from '@kandji-inc/nectar-ui';
import { constants } from '../../../common';
import type {
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
} from '../../../threat.types';
import ThreatListTooltip from '../ThreatListTooltip';
import ThreatListTableCheckbox from './ThreatListTableCheckbox';

const TheadStyled = styled(Thead, {
  position: 'sticky',
  zIndex: 0,
});

const getNextSortOrder = (
  sortOrder: SortColumnDirection | undefined,
  sortBy: SortColumnName | undefined,
  targetColumn: SortColumnName,
) => {
  const orders: SortColumnDirection[] = constants.THREAT_SORT_ORDER;
  // Sets the next in line sort order
  const nextDirection: SortColumnDirection | undefined =
    targetColumn === sortBy && sortOrder
      ? orders[(orders.indexOf(sortOrder) + 1) % orders.length]
      : 'asc';

  return nextDirection;
};

type ThreatListTableHeadProps = Readonly<{
  sort: SortColumnState;
  onColumnSort: (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => void;
  isSortingDisabled: boolean;
  columns: TableHeaderColumn[];
  isSticky: boolean;
  isSelectable: boolean;
  onAllSelected: ((isSelected: boolean) => void) | undefined;
  isAllSelected: boolean;
  isSelectionDisabled: boolean;
}>;

const infoIconStyle = css({
  pl: '$1',
  '& > svg': { height: 16, width: 16, verticalAlign: 'text-top' },
});

const ThreatListTableHead = (props: ThreatListTableHeadProps) => {
  const {
    sort: { sortBy, sortOrder },
    onColumnSort,
    isSortingDisabled,
    columns,
    isSticky,
    isSelectable,
    onAllSelected,
    isAllSelected,
    isSelectionDisabled,
  } = props;

  const handleHeaderClick = (newColumn: SortColumnName) => () => {
    const nextDirection = getNextSortOrder(sortOrder, sortBy, newColumn);
    // If sortOrder is 'none' we reset sortBy and sortOrder to undefined
    const sortByColumn = nextDirection === 'none' ? undefined : newColumn;
    const direction = nextDirection === 'none' ? undefined : nextDirection;
    onColumnSort(sortByColumn, direction);
  };

  const applySort = (newColumn: SortColumnName) => ({
    state: sortBy === newColumn ? sortOrder : 'none',
    onSort: !isSortingDisabled ? handleHeaderClick(newColumn) : null,
  });

  return (
    <TheadStyled css={{ top: isSticky ? 44 : 0 }}>
      <Tr>
        <Th
          title={isSelectable ? 'Select' : 'Expand'}
          css={{
            $$cellWidth: '$sizes-6',
            padding: 0,
            '& > div': { columnGap: 0 },
            '& > div > div': { justifyContent: 'center' },
          }}
        >
          {isSelectable ? (
            <ThreatListTableCheckbox
              selected={isAllSelected}
              onToggle={(isSelected) => {
                if (typeof onAllSelected === 'function') {
                  onAllSelected(isSelected);
                }
              }}
              disabled={isSelectionDisabled}
              testId="select-row-button"
            />
          ) : (
            ' '
          )}
        </Th>
        <>
          {columns.map((column) => (
            <Th
              key={column.name}
              title={column.title}
              sort={column.isSortable === false ? null : applySort(column.name)}
              css={{ $$cellWidth: `$sizes-${column.size}` }}
            >
              {column.title}
              {column.tooltip && (
                <ThreatListTooltip label={column.tooltip}>
                  <span className={infoIconStyle().className}>
                    <Icon name="circle-info" />
                  </span>
                </ThreatListTooltip>
              )}
            </Th>
          ))}
        </>
        <Th title="Actions" css={{ $$cellWidth: '$sizes-7' }}>
          &nbsp;
        </Th>
      </Tr>
    </TheadStyled>
  );
};

export default ThreatListTableHead;
