// istanbul ignore file
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type {
  FiltersState,
  GetByThreatsResponse,
  PaginationState,
  SortColumnState,
} from '../../../../threat.types';
import threatsGroupQuery from './threatsGroupQuery';

type UseGetByThreats = (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
) => {
  data: GetByThreatsResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
};

/**
 * Retrieves threat details based on the provided filters, sort, pagination, and computer information.
 *
 * @param filters - The filters to apply to the threat details.
 * @param sort - The sorting options for the threat details.
 * @param pagination - The pagination options for the threat details.
 * @returns An object containing the threat details data and a boolean indicating if the data is currently being loaded.
 */
const useGetByThreats: UseGetByThreats = (filters, sort, pagination) => {
  const { data, refetch, isPlaceholderData, isLoading, isError, error } =
    useQuery({
      queryKey: ['by-threats', window?.location?.search],
      queryFn: () => threatsGroupQuery(filters, sort, pagination),
      placeholderData: keepPreviousData,
      retry: 2,
      retryDelay: 1000,
    });

  return {
    data: data?.data,
    isLoading: isLoading || isPlaceholderData,
    isError,
    error,
    refetch: refetch as unknown as () => Promise<void>,
  };
};

export default useGetByThreats;
