import type { CheckedState } from '@kandji-inc/nectar-ui';
import type {
  BaseLibraryItemModel,
  BlueprintConflicts,
  LibraryItemPageProps,
  UpdateFunction,
} from '../../library-items.types';

// DeviceControl specific
export type DeviceControlModelSetting = {
  isManaged: boolean;
  userAccess: UserAccessValue;
  appliesTo: AppliesToUsersValue;
  deviceAlerts: CheckedState | boolean;
};

// Setting Section Types
type VolumeMountsSetting = DeviceControlModelSetting & {
  requireEncryption: CheckedState | boolean;
  requireAdminPassword: CheckedState | boolean;
};

type DiskImagesSetting = DeviceControlModelSetting & {
  requireAdminPassword: CheckedState | boolean;
};

export interface DeviceControlModelSettings {
  volumeMounts: VolumeMountsSetting;
  diskImages: DiskImagesSetting;
  networkMounts: DeviceControlModelSetting;
}

export interface DeviceControlModel
  extends BaseLibraryItemModel,
    DeviceControlModelSettings {
  skip_blueprint_conflict?: boolean;
  excludedBlueprints: string[];
  isAllBlueprints: boolean;
}

// Props
type DeviceControlSectionProps<T extends Record<string, unknown>> = Readonly<{
  settings: T;
  isDisabled: boolean;
  update: UpdateFunction<T>;
}>;

export type DeviceControlProps = LibraryItemPageProps<
  Readonly<DeviceControlModel>
> & {
  blueprintConflicts: BlueprintConflicts;
};

export type VolumeMountsProps = DeviceControlSectionProps<VolumeMountsSetting>;

export type DiskImagesProps = DeviceControlSectionProps<DiskImagesSetting>;

export type NetworkMountsProps =
  DeviceControlSectionProps<DeviceControlModelSetting>;

// Enums and types for options
export const UserAccessValue = {
  READ_WRITE: 'read-write',
  READ_ONLY: 'read-only',
  BLOCK: 'block',
} as const;

export type UserAccessValue =
  (typeof UserAccessValue)[keyof typeof UserAccessValue];

export const UserAccessLabel = {
  READ_WRITE: 'Read & Write',
  READ_ONLY: 'Read only',
  BLOCK: 'No access',
} as const;

export const AppliesToUsersValue = {
  ALL: 'all',
  STANDARD: 'standard',
} as const;

export type AppliesToUsersValue =
  (typeof AppliesToUsersValue)[keyof typeof AppliesToUsersValue];

export type UserAccessSelectOptions = Readonly<
  Array<{
    label: string;
    value: UserAccessValue;
  }>
>;

export type AppliesToUsersRadioOptions = Readonly<
  Array<{
    label: string;
    value: AppliesToUsersValue;
  }>
>;
