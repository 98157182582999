import {
  Button,
  Chip,
  setClass,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { links } from 'app/common/constants';
import {
  useAuthority,
  useConnector,
  useModal,
} from 'features/integrations/components-for-specific-types/adcs/hooks';
import {
  STATUS_CONNECTED,
  STATUS_PENDING,
} from 'features/integrations/components-for-specific-types/adcs/status';
import { newIntegrationsService } from 'features/integrations/data-service/new-integrations-service';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { func, object } from 'prop-types';
/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OptionsMenu from '../../../util/components/options-menu';
import configurations from '../../components-for-specific-types/configurations';
import { INT_CATEGORIES, INT_TYPES } from '../../constants';
import cardConfigurations from '../../generic-cards-view/cards-config';
import './overview-integration-card.styles.scss';

import featureFlags from '../../../../config/feature-flags';
import { useGetMSTeamsIntegrations } from '../../components-for-specific-types/ms-teams/hooks';
import { useGetOktaDeviceTrust } from '../../components-for-specific-types/okta/hooks';
import Bell from './assets/bell.svg';
import CheckCircle from './assets/check-circle.svg';
import Clock from './assets/clock.svg';
import DesktopIcon from './assets/desktop.svg';
import MobileIcon from './assets/mobile.svg';
import ServerIcon from './assets/server-badge-icon.svg';
import TabletIcon from './assets/tablet.svg';

const DESCRIPTIVE_WORDS = ['yesterday', 'today'];
function formatRelativeDate(dateAsString) {
  const asDate = DateTime.fromISO(dateAsString);
  const fromToday = asDate.toRelativeCalendar({ locale: 'en' });
  const time = asDate.toFormat('h:mm a');
  const relative = asDate.toRelative();
  const date =
    relative === fromToday || !DESCRIPTIVE_WORDS.includes(fromToday)
      ? asDate.toFormat('MMM d yyyy')
      : capitalize(fromToday);
  return `Last import: ${date} at ${time} (${relative})`;
}

function OverviewIntegrationCard({
  integrationData,
  requestDeletingIntegration,
  startViewingIntegration,
  startEditingIntegration,
  requestRotatingScimIntegration,
}) {
  const {
    type,
    name,
    amount,
    category,
    synced_at,
    is_syncing,
    servicenow_configs: servicenowConfigs,
    id,
  } = integrationData;

  const { title, img } = cardConfigurations[type];
  const { integrationAlias, makeIntegrationLink } = configurations[type] || {};
  const history = useHistory();
  const [isSyncing, setIsSyncing] = useState(is_syncing);

  const isReAuthenticateFlagActive = featureFlags.getFlag(
    'int-re-authenticate',
  );
  const isAzureReauthenticationFlagActive = featureFlags.getFlag(
    'int-azure-re-authenticate',
  );

  const isAssetManagement = category === INT_CATEGORIES.assetManagement;
  const isCommunicationInt = category === INT_CATEGORIES.communication;
  const isSecurityInt = category === INT_CATEGORIES.security;
  const isDirectoryInt = category === INT_CATEGORIES.user;
  const isScim = type === INT_TYPES.scim;
  const isADCS = type === INT_TYPES.adcs;
  const isODT = type === INT_TYPES.okta; // okta device trust
  const isServicenow = type === INT_TYPES.servicenow;
  const isMsTeams = type === INT_TYPES.msTeams;
  const isGSuite = type === INT_TYPES.gsuite;
  const isAzure = type === INT_TYPES.office365;

  const { openModal, isOpen } = useModal();
  const {
    connectorList,
    fetchConnectorList,
    setConnector,
    clearConnectorList,
  } = useConnector();
  const { authorityList, fetchAuthorityList, clearAuthorityList } =
    useAuthority();

  const { data: teamsIntegrations } = useGetMSTeamsIntegrations();
  const { data: odtData } = useGetOktaDeviceTrust();

  const isInteractive = [
    isCommunicationInt,
    isSecurityInt,
    isAssetManagement,
  ].some(Boolean);

  const odtIntegrationData = odtData?.results?.[0];

  // isConnected is true if the status of ANY connector is connected
  const isConnected =
    connectorList.length > 0 &&
    !!connectorList.find((connector) => connector.status === STATUS_CONNECTED);

  const hasSinglePendingConnector =
    connectorList.length === 1 && connectorList[0].status === STATUS_PENDING;

  useEffect(() => {
    if (isADCS && isOpen) {
      fetchConnectorList();
      fetchAuthorityList();
    }
    return () => {
      clearConnectorList();
      clearAuthorityList();
    };
  }, []);

  function getTitle() {
    // This determines if some integrations have names provided by the user or if the title is the integration name.
    return [isCommunicationInt, isSecurityInt, isAssetManagement].some(Boolean)
      ? title
      : name;
  }

  function getNumEventNotificationsText() {
    const integrations = teamsIntegrations?.integrations;
    if (!integrations) {
      return '';
    }

    const numEventNotifications = integrations.reduce(
      (prev, curr) => prev.concat(curr.notifications),
      [],
    ).length;
    if (numEventNotifications < 1) {
      return '';
    }
    const pluralized =
      numEventNotifications > 1 ? 'notifications' : 'notification';
    return `${numEventNotifications} event ${pluralized}`;
  }

  function getSubTitle() {
    if (isCommunicationInt || !synced_at) {
      return null;
    }

    return (
      <p className="b-txt-light2 b-mt-micro">{formatRelativeDate(synced_at)}</p>
    );
  }

  function getMainText() {
    if (isServicenow) {
      const servicenowTenant = servicenowConfigs?.[0]?.servicenow_tenant;
      return `Domain: ${servicenowTenant}.service-now.com`;
    }
    if (isCommunicationInt) {
      const integrationWord = capitalize(integrationAlias);
      return amount === 1
        ? `${integrationWord}: ${makeIntegrationLink(name)}`
        : `${integrationWord}s: ${amount}`;
    }

    if (isADCS) {
      return isConnected
        ? `Connectors: ${connectorList.length}`
        : 'Pending installation...';
    }

    if (isODT) {
      return odtIntegrationData?.domain
        ?.replace('https://', '')
        .replace('/', '');
    }

    return title;
  }

  function getIcon() {
    if (!isADCS) {
      return null;
    }
    const src = isConnected ? CheckCircle : Clock;
    const alt = isConnected ? 'success icon' : 'pending icon';
    return <img className="int-overview-card_icon" src={src} alt={alt} />;
  }
  function odtBadges() {
    if (!odtIntegrationData) {
      return null;
    }
    const { ios_configured, mac_configured } = odtIntegrationData;

    return (
      <div className="odt-badges">
        {mac_configured && (
          <Chip
            iconLeft={DesktopIcon}
            asImage
            text="Mac"
            className="b-mt-tiny"
          />
        )}

        {ios_configured && (
          <>
            <Chip
              iconLeft={MobileIcon}
              asImage
              text="iPhone"
              className="b-mt-tiny"
            />
            <Chip
              iconLeft={TabletIcon}
              asImage
              text="iPad"
              className="b-mt-tiny"
            />
          </>
        )}
      </div>
    );
  }

  function getBadge() {
    if (isODT) {
      return odtBadges();
    }
    if (isADCS && isConnected) {
      const serverCount = authorityList.length;
      const serverText = serverCount === 1 ? 'server' : 'servers';
      return (
        <Chip
          iconLeft={ServerIcon}
          asImage
          text={`${serverCount} ${serverText}`}
          className="b-mt-tiny"
        />
      );
    }
    return null;
  }

  function onSyncUsersClick() {
    setIsSyncing(true);
    newIntegrationsService
      .syncUsers(id)
      .then(() => {
        toaster(`Successfully syncing users from ${name}.`);
      })
      .catch((e) => {
        let errMessage;
        let newIsSyncing = false;
        switch (e.response.status) {
          case 400:
            errMessage = `User sync is already running for ${name}`;
            newIsSyncing = true;
            break;
          case 404:
            errMessage = `Could not sync users from ${name}`;
            break;
          default:
            errMessage = 'Something went wrong';
        }
        toaster(errMessage);
        setIsSyncing(newIsSyncing);
      });
  }

  function renderMenuOptions(close) {
    if (isADCS) {
      const connector = connectorList[0]?.id;

      return (
        <>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setConnector(connector);
              openModal(INT_TYPES.adcs, 2, false);
              close();
            }}
            theme="action"
            kind="link"
            icon="eye"
          >
            View install instructions
          </Button>
          {hasSinglePendingConnector && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setConnector(connector);
                openModal(INT_TYPES.adcs, 1, false);
                close();
              }}
              theme="action"
              kind="link"
              icon="arrow-down-to-line"
            >
              Redownload connector
            </Button>
          )}
        </>
      );
    }

    const handleReAuthentication = async (data) => {
      const { id: intId, type: intType } = data;

      try {
        const response = await newIntegrationsService.integrationsRotateToken(
          intId,
          intType,
        );
        window.location = response?.data?.redirect_url;
      } catch (e) {
        toaster('Something went wrong');
      }
    };

    let thirdButtonText;
    if (isScim) {
      thirdButtonText = 'Rotate token';
    } else {
      thirdButtonText = isSyncing ? 'Syncing users...' : 'Sync users';
    }

    const isGoogleReauthenticationActive =
      isGSuite && isReAuthenticateFlagActive;

    const isAzureReauthenticationActive =
      isAzure && isAzureReauthenticationFlagActive;

    const hasReauthenticateButton = [
      isGoogleReauthenticationActive,
      isAzureReauthenticationActive,
    ].some(Boolean);

    return (
      <>
        {hasReauthenticateButton && (
          <Button
            onClick={() => {
              handleReAuthentication(integrationData);
              close();
            }}
            theme="action"
            kind="link"
            icon="circle-check"
          >
            Re-authenticate
          </Button>
        )}

        <Button
          onClick={() => {
            startViewingIntegration(integrationData);
            close();
          }}
          theme="action"
          kind="link"
          icon="eye"
        >
          View details
        </Button>
        <Button
          onClick={() => {
            startEditingIntegration(integrationData);
            close();
          }}
          icon="pencil"
          theme="action"
          kind="link"
        >
          Rename
        </Button>
        <Button
          icon="arrows-rotate"
          kind="link"
          theme="action"
          disabled={isSyncing}
          onClick={() => {
            if (isScim) {
              requestRotatingScimIntegration(integrationData);
            } else {
              onSyncUsersClick();
            }
            close();
          }}
        >
          {thirdButtonText}
        </Button>
        <Button
          icon="trash-can"
          kind="link"
          theme="error"
          onClick={() => {
            requestDeletingIntegration(integrationData);
            close();
          }}
        >
          Delete integration
        </Button>
      </>
    );
  }

  // For some integrations, we should show the "menu container" component which means this could be only the icon, or it may be the ellipsis button, which is the case for ADCS - security integration. We are hiding the ellipsis button for ADCS but showing the status icon.
  const showButtonforADCS = isADCS && hasSinglePendingConnector;
  const hasStatusMenuContainer = [isDirectoryInt, isSecurityInt].some(Boolean);
  const hasEllipsisButton = [isAzure, isScim, isGSuite, showButtonforADCS].some(
    Boolean,
  );

  return (
    <div
      className={setClass(
        'int-card_wrapper',
        'int-card_wrapper--overview',
        isInteractive && 'int-card_wrapper--interactive',
      )}
      onClick={(e) => {
        if (isInteractive && !e.defaultPrevented) {
          const withIntegrationId = [isServicenow].some(Boolean);
          const route = `${links.integrations.root}/${type}`;
          history.push(withIntegrationId ? `${route}/${id}` : route);
        }
      }}
    >
      <img className="int-card_icon" alt="int-card_wrapper__icon" src={img} />
      <div className="int-card_detail b-ml1">
        <div className="int-overview-card_header">
          <h3 className="b-h3">{getTitle()}</h3>
          {hasStatusMenuContainer && (
            <OptionsMenu render={renderMenuOptions}>
              {hasEllipsisButton && (
                <Button
                  onClick={(e) => e.preventDefault()}
                  icon="ellipsis"
                  kind="link"
                  className="int-overview-card_options-btn"
                />
              )}
              {getIcon()}
            </OptionsMenu>
          )}
        </div>
        {getSubTitle()}
        <p className="b-mt1 b-txt-light">{getMainText()}</p>
        {getBadge()}
        {isMsTeams && getNumEventNotificationsText() && (
          <div className="event-notifications-wrapper">
            <img
              className="int-overview-card_event-notifications"
              src={Bell}
              alt="event-notifications-bell"
            />
            <p className="b-mt1 b-txt-light event-notifications">
              {getNumEventNotificationsText()}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

OverviewIntegrationCard.propTypes = {
  integrationData: object.isRequired,
  requestDeletingIntegration: func.isRequired,
  startViewingIntegration: func.isRequired,
  startEditingIntegration: func.isRequired,
  requestRotatingScimIntegration: func.isRequired,
};

export default OverviewIntegrationCard;
