import cn from 'classnames';
import React from 'react';

import { Setting } from 'features/library-items/template';
import SimpleRow from './simple-row';

const ToggleRow = (props) => {
  const { setting, update, toggleField, checkboxFiled } = props;

  return (
    <SimpleRow {...props}>
      {setting[checkboxFiled] && (
        <Setting.SecondaryControls>
          <Setting.SecondaryControlsRow>
            <div>
              <button
                data-testid={`${checkboxFiled}_disable_button`}
                onClick={() => update(toggleField, false)}
                className={cn('k-ssh-gc-group-btn', {
                  '--active': !setting[toggleField],
                })}
                type="button"
              >
                Off
              </button>
              <button
                data-testid={`${checkboxFiled}_enable_button`}
                onClick={() => update(toggleField, true)}
                className={cn('k-ssh-gc-group-btn', {
                  '--active': setting[toggleField],
                })}
                type="button"
              >
                On
              </button>
            </div>
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </SimpleRow>
  );
};

export default ToggleRow;
