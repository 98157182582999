// Auth
export const URL_GET_ACCOUNT = 'users/whoami';
export const URL_AUTH0_CREDENTIALS = 'auth/client';

// Parameters
export const URL_PARAMETERS_META = 'public/meta/legacy';
export const URL_PARAMETERS_SEARCH = 'parameters/history/search';

// Computer
export const URL_MDM = 'mdm/';
export const URL_COMPUTERS = 'computers/';
export const URL_COMPUTERS_FOR_FILTERS = 'computers-for-filters/';
export const URL_COMPUTER_CHANGE_BLUEPRINT = 'computers/change-blueprint';
export const URL_DEVICES = 'devices/';
export const URL_DEVICE_FAMILY_COUNTS = 'computers/device-family-counts';

// DEP Computer
export const URL_DEP_COMPUTERS = 'dep/devices/';
export const URL_DEP_COMPUTER_STATUS_COUNTS = 'dep/devices/status-counts/';
export const URL_DEP_COMPUTERS_COUNTS = 'dep/devices/counts/';
export const URL_DEP_COMPUTER_CHANGE_BLUEPRINT =
  'dep/devices/change-blueprint/';
export const URL_DEP_COMPUTER_CHANGE_ASSET_TAG =
  'dep/devices/change-asset-tag/';
export const URL_DEP_COMPUTER_CHANGE_USER = 'dep/devices/assign-user/';
export const URL_DEP_TOKENS = 'integrations/dep/stoken/list';
export const URL_DEP_ACCOUNT_INFO = 'integrations/dep/account/info/';

// Blueprints
export const URL_BLUEPRINTS = 'blueprints/';
export const URL_BLUEPRINTS_V2 = 'blueprints-v2/';

export const URL_BLUEPRINTS_CHANGE_CODE = (blueprintId: string) =>
  `${URL_BLUEPRINTS}${blueprintId}/change-enrollment-code`;

export const URL_BLUEPRINTS_TOGGLE_CODE = (blueprintId: string) =>
  `${URL_BLUEPRINTS}${blueprintId}/turn-on-enrollment-code`;

// Auth config
export const URL_BLUEPRINT_AUTH_CONFIG = 'enrollment-auth-configurations/';
export const URL_BLUEPRINT_AUTH_CONFIG_UPDATE = (id: string) =>
  `enrollment-auth-configurations/${id}`;

// Utils
export const URL_NOTES = 'notes/';

// User management
export const URL_USERS = 'users/';
export const URL_COMPANY_USERS = 'company-users/';

// Groups
export const URL_COMPANY_INFO = 'company-info';
export const URL_COUNTS = 'counts';

// Reports
export const URL_REPORTS_COMPUTERS = '/reports/computers';
export const URL_REPORTS_DEP_COMPUTERS = '/dep/devices/export/';
export const URL_REPORTS_DEP_COMPUTERS_V2 = '/dep/devices/exportV2/';

// Timeline
export const URL_TIMELINE = 'timeline/';

// Enrollment Portal
export const URL_ENROLLMENT_PORTAL = 'enrollment-portal/';
export const URL_ENROLLMENT_PORTAL_TOGGLE = 'enrollment-portal/toggle';
export const URL_ENROLLMENT_PORTAL_STATUS = 'enrollment-portal/check-status';

// Template and Categories
export const URL_TEMPLATE_CATEGORIES = '/blueprint-template-categories/';
export const URL_APPLICATION_INVENTORY = '/application-inventory/';
export const URL_APPLICATION_INVENTORY_INFO = '/application-inventory/info';

// Feature Request
export const URL_FEATURE_REQUEST = '/i/feature-request';
export const URL_FEATURE_REQUEST_UPDATED = '/feature-request';

// Self Service
export const URL_COMPANY_SELF_SERVICE_CONFIG = 'company/self-service/config';

// Single Sign-On
export const URL_AUTH_CONNECTOR = 'auth/connector';
export const URL_AUTH_CONNECTOR_ENROLLMENTS = (id) =>
  `auth/connector/${id}/enrollments`;

// Background refreshes that should not be considered user activity
export const BACKGROUND_REFRESH_URLS = new Set([URL_COUNTS]);
