import {
  updateSetting as update,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import React, { useContext, useCallback, useEffect } from 'react';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import { RequiredModal } from './RequiredModal';
import type { DeviceControlProps } from './devicecontrol.types';
import initial from './initial-state';
import DiskImages from './sections/DiskImages';
import NetworkMounts from './sections/NetworkMounts';
import VolumeMounts from './sections/VolumeMounts';
import { deviceControlService } from './service/device-control-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useDeviceControlService from './service/use-device-control-service';

const DeviceControlPage = (props: DeviceControlProps) => {
  const { model, setModel, pageState, setPageState } = props;
  const [, triggerValidation] = useUniqValue();

  const volumeMountsUpdate = useCallback(update('volumeMounts', setModel), []);
  const diskImagesUpdate = useCallback(update('diskImages', setModel), []);
  const networkMountsUpdate = useCallback(
    update('networkMounts', setModel),
    [],
  );

  const { onInvalidate: onVolumeMountsUpdateInvalidate } = useInputsValidators(
    ['isManaged'],
    volumeMountsUpdate,
  );

  const isInvalid =
    !model.volumeMounts.isManaged &&
    !model.networkMounts.isManaged &&
    !model.diskImages.isManaged;

  useEffect(() => {
    onVolumeMountsUpdateInvalidate(0)(isInvalid);
  }, [isInvalid]);

  const isModalOpen = pageState.isSubmitted && isInvalid;

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: itemConfig.name,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={deviceControlService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsDuplication
    >
      <VolumeMounts
        settings={model.volumeMounts}
        isDisabled={pageState.isDisabled}
        update={volumeMountsUpdate}
      />
      <DiskImages
        settings={model.diskImages}
        isDisabled={pageState.isDisabled}
        update={diskImagesUpdate}
      />
      <NetworkMounts
        settings={model.networkMounts}
        isDisabled={pageState.isDisabled}
        update={networkMountsUpdate}
      />
      <RequiredModal
        isOpen={isModalOpen}
        onClose={() => setPageState((p) => ({ ...p, isSubmitted: false }))}
      />
    </LibraryItemPage>
  );
};

const DeviceControl = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useDeviceControlService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <DeviceControlPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="device-control-v2"
    />
  );
};

export default DeviceControl;
