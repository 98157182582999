import { useEffect } from 'react';
/* istanbul ignore file */
import '../styles/sidepanel-chat-bubble.styles.scss';

/**
 * This hook is a utility hook for moving the chat bubble out of the way. This
 * is primarily for the when the sidebar is open. It moves the chat bubble above
 * the 'Done' button.
 */
const useAdjustSidebarChatBubble = (enabled = true) => {
  useEffect(() => {
    if (!enabled) return;

    let chatBubble;
    new MutationObserver((_, observer) => {
      chatBubble = document?.querySelector('#fc_frame');
      if (chatBubble) {
        chatBubble?.classList?.add('fc_frame-side-panel-chat-bubble');
        observer.disconnect();
      }
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });

    return () => {
      chatBubble?.classList?.remove('fc_frame-side-panel-chat-bubble');
    };
  }, [enabled]);
};

export default useAdjustSidebarChatBubble;
