import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
} from './column-utils';
import { getGlobalColumns } from './globalColumns';

export const getCertificatesColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const certificatesColumnHelper = createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, certificatesColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    certificatesColumnHelper.accessor((row) => row.common_name, {
      id: 'common_name',
      meta: {
        displayName: 'Common Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    certificatesColumnHelper.accessor((row) => row.identity_certificate, {
      id: 'identity_certificate',
      cell: YesNoCell,
      meta: {
        displayName: 'Identity Certificate',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    // TODO: re-enable once we get certificates data
    // certificatesColumnHelper.accessor((row) => row.cert_data, {
    //   id: 'cert_data',
    //   meta: {
    //     displayName: 'Identity Certificate',
    //     filterType: 'string',
    //     filterIcon: 'text-size',
    //     filterDisabled,
    //   },
    // }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
