import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Banner,
  Box,
  Button,
  Code,
  Dialog,
  Flex,
  Icon,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useState } from 'react';
import { links } from 'src/app/common/constants';
import { isPinEnabled } from 'src/app/components/common/helpers';
import { getPin } from 'src/app/components/computer/api';

import history from 'src/app/router/history';

const ComputerDelete = (props) => {
  const {
    decreaseBlueprintComputersCount,
    deleteComputers,
    getBlueprints,
    info,
    name,
    onHide,
    setComputers,
  } = props;
  const {
    activationLockSupported,
    blueprintId,
    computerId,
    computers,
    deviceFamily,
    erased,
    locked,
    model,
    osVersion,
  } = info;

  const several = computers.length > 1;
  const pluralize = () => several && 's';
  const pluralizeDevice = () => (several ? 'devices' : 'the device');
  const pluralizeRecord = () => (several ? 'records' : 'record');

  const [isLoading, setIsLoading] = useState(true);
  const [isPinEnabledOnDevice, setIsPinEnabledOnDevice] = useState();
  const [pin, setPin] = useState();
  const [confirmationText, setConfirmationText] = useState();

  useEffect(() => retrievePin(), []);

  const retrievePin = /* istanbul ignore next */ () => {
    if (computerId && (erased || locked)) {
      getPin(computerId)
        .then((res) => {
          setPin(res.pin);
          setIsPinEnabledOnDevice(
            isPinEnabled(
              deviceFamily,
              osVersion,
              model,
              activationLockSupported,
            ),
          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toaster('Failed to get the PIN code');
        });
    }

    setIsLoading(false);
  };

  const handleOnCopy = (value) => {
    navigator.clipboard.writeText(value);
    toaster('Copied to clipboard.');
  };

  const onDelete = /* istanbul ignore next */ () => {
    const ids = computers;

    if (!ids.length) {
      return null;
    }

    setIsLoading(true);
    const newComputerList = computers.filter(
      (computer) => !ids.includes(computer.id),
    );

    deleteComputers(ids).then(
      () => {
        getBlueprints()
          .then(() => {
            if (blueprintId) {
              decreaseBlueprintComputersCount(ids.length);
            }

            if (computerId) {
              onHide();
              setIsLoading(false);
              history.push(links.devices);
              toaster(`The device record ${name} has been deleted.`);
            } else {
              onHide();
              setIsLoading(false);
              toaster('Selected devices were deleted.');
            }
          })
          .then(() => {
            setComputers(newComputerList);
            if (info.fetchComputers) {
              info.fetchComputers();
            }
          });
      },
      (err) => {
        onHide();
        setIsLoading(false);
        toaster(err.message);
      },
    );

    return null;
  };

  const title = several
    ? 'Delete device records'
    : erased
      ? 'This device has been erased'
      : 'Delete device record';

  const content = (
    <Flex flow="column" gap="lg">
      <Banner
        theme="warning"
        text={
          <Text>
            Deleting the device {pluralizeRecord()}{' '}
            {name && (
              <Text
                css={{
                  fontWeight: '$medium',
                  display: 'inline-block',
                }}
              >
                {name}
              </Text>
            )}{' '}
            cannot be undone.
          </Text>
        }
      />

      {isPinEnabledOnDevice && (erased || locked) && (
        <Banner
          theme="warning"
          text={
            <Text>
              This device has been {erased ? 'erased' : 'locked'}, and cannot be
              used again unless it is unlocked with its PIN code.
            </Text>
          }
        />
      )}

      <Text>
        All history will be lost, and {pluralizeDevice()} will need to be
        re-enrolled into Kandji for future use.
      </Text>

      {!erased && !locked && (
        <Flex flow="column" gap="md">
          <Text>
            All device secrets that may prevent anyone from using{' '}
            {several ? 'these devices' : 'this device'} will be lost, including:
          </Text>
          <ul>
            <li>
              Device lock PIN code
              {pluralize()}
            </li>
            <li>Recovery password{pluralize()}</li>
            <li>
              FileVault Recovery Key
              {pluralize()}
            </li>
            <li>
              Activation Lock Bypass Code
              {pluralize()}
            </li>
          </ul>
        </Flex>
      )}

      <Flex
        flow="column"
        gap="lg"
        css={{
          paddingTop: '$4',
          borderTop: '1px solid $neutral20',
        }}
      >
        {isPinEnabledOnDevice && pin && (
          <Flex flow="column" gap="md">
            <Text>Copy and store the PIN code</Text>
            <Flex gap="sm">
              <Code>{pin}</Code>
              <Box
                css={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleOnCopy(pin)}
                data-testid="copy-pin"
              >
                <Icon name="copy" color="var(--colors-neutral50)" size="sm" />
              </Box>
            </Flex>
          </Flex>
        )}

        <Text>
          Type{' '}
          <Text
            css={{
              fontWeight: '$medium',
              display: 'inline-block',
            }}
          >
            DELETE
          </Text>{' '}
          to permanently delete the device {pluralizeRecord()}
        </Text>

        <TextField
          placeholder="DELETE"
          onChange={(e) => setConfirmationText(e.target.value)}
        />

        <Text variant="subtle" size="1">
          I understand that the selected device {pluralizeRecord()}, all related
          device record information, and all device secrets will be destroyed.
          This cannot be undone.
        </Text>

        {isPinEnabledOnDevice && (erased || locked) && (
          <Text variant="subtle" size="1">
            I have securely stored the PIN code needed to unlock this device, or
            I no longer have access to the device. I understand that this PIN
            will no longer be available in Kandji.
          </Text>
        )}
      </Flex>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="danger"
        loading={isLoading}
        disabled={confirmationText !== 'DELETE'}
        onClick={onDelete}
      >
        {isLoading
          ? 'Deleting...'
          : `Delete Device ${several ? 'Records' : 'Record'}`}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title={title}
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerDelete;
