import { Link } from '@kandji-inc/nectar-ui';
import {
  PRISM_CONTENT_HEIGHT,
  PRISM_TABLE_HEIGHT,
} from 'src/features/visibility/prism/utils/constants';

export function getTableCss({ contentOffset, tableOffset }) {
  return {
    container: {
      minWidth: 350,
      height:
        contentOffset != null
          ? `calc(100vh - ${contentOffset}px)`
          : PRISM_CONTENT_HEIGHT,
    },
    toolbar: {
      background: '$neutral0',
      height: 'fit-content',
      pb: '$3',
      width: '100%',
    },
    tableContainer: {
      flexBasis: 0,
      maxHeight:
        tableOffset != null
          ? `calc(100vh - ${tableOffset}px)`
          : PRISM_TABLE_HEIGHT,
      bb: '$neutral30',
    },
    table: {
      // use bottom border from scroll container instead
      ':where(tbody) tr:last-of-type': {
        borderBottom: 'none',
      },

      // overrides anchor resets from `_reboot.scss`
      [`:where(th, td) a:not(${Link})`]: {
        color: '$neutral90',
      },
    },
    paginationContainer: {
      width: '100%',
      p: '$2 0',
      mt: '5px',
    },
  };
}

export function toggleResizingClass(isResizing: boolean) {
  const bodyElement = document.body;
  if (bodyElement) {
    if (isResizing) {
      bodyElement.classList.add('resizing-column');
    } else {
      bodyElement.classList.remove('resizing-column');
    }
  }
}
