import AppLockService from './service/app-lock-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  appIdentifier: {
    value: undefined,
    validationsMap: {},
  },

  appOptions: Object.keys(AppLockService.appOptionsMap).reduce((acc, val) => {
    acc[val] = AppLockService.appOptionsMap[val].defaultValue;
    return acc;
  }, {}),

  userOptions: Object.keys(AppLockService.userOptionsMap).reduce((acc, val) => {
    acc[val] = false;
    return acc;
  }, {}),

  supportsRules: true,
};
