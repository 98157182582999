import {
  Button,
  ButtonGroup,
  Chip,
  Toggle,
  setClass,
} from '@kandji-inc/bumblebee';
import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import React, { useState, useContext } from 'react';
import useCompanyLogo from '../hooks/use-company-logo.hook';
import KandjiLogo from '../kandji-logo';
import setPreviewImage, {
  previewPaths,
  success as successIconsPath,
} from '../set-preview-image';
import ToggleButton from '../toggle-button';
import LinkPreviews from './link-previews';
import './success-screen.css';
import SuccessSidePanel from './success-side-panel';

const SuccessScreen = (props) => {
  const {
    isDisabled,
    isDarkMode,
    setIsDarkMode,
    setGlobalValidations,
    itemId,
  } = useContext(KandjiSetupCtx);
  const { model, setModel, initialStateCopy, beforeEditModel } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const isEnabled = model.successScreen.isActive;

  const setIsEnabled = () => {
    setModel((p) => ({
      ...p,
      successScreen: {
        ...p.successScreen,
        isActive: !isEnabled,
      },
    }));
  };

  const onValidations = (v) => {
    setGlobalValidations((p) => ({ ...p, [v.name]: v.isInvalid }));
  };

  const companyLogo = useCompanyLogo({ model, isDarkMode });

  return (
    <div className="b-library__setting-card" style={{ marginBottom: '97px' }}>
      <div className="b-library__setting-card--title b-flex-btw b-flex-vc">
        <h3 className="b-h3">Complete screen</h3>
        <div className="b-flex">
          <Chip
            className={setClass([
              'b-txt-ctrl8',
              isDisabled ? 'b-chip-active-md--disabled' : '',
            ])}
            kind={isEnabled ? 'active' : 'inactive'}
            text={isEnabled ? 'Enabled' : 'Disabled'}
          />{' '}
          <Toggle
            disabled={isDisabled}
            style={{ marginLeft: 'var(--b-gap-tiny)' }}
            checked={isEnabled}
            onToggle={setIsEnabled}
          />
        </div>
      </div>
      <p className="b-txt b-library__setting-card--subtitle">
        Customize the header and subheader on the Complete screen. Add links and
        resources to help your users get started on their new devices.
      </p>
      <div
        className={setClass([
          'b-library__setting-rows',
          !isEnabled ? 'k-library__setting-rows--collapsed' : '',
        ])}
      >
        <div className="b-library__setting-row--single-col">
          <div className="b-flex-vc b-flex-btw">
            <div className="b-flex-g b-flex-vc">
              <SuccessSidePanel
                itemId={itemId}
                disabled={isDisabled}
                model={model}
                beforeEditModel={beforeEditModel}
                setModel={setModel}
                initialStateCopy={initialStateCopy}
                isDarkMode={isDarkMode}
                onValidations={onValidations}
              />
              <Button
                onClick={() => setIsExpanded((p) => !p)}
                icon={isExpanded ? 'angle-up' : 'angle-down'}
                kind="link"
                size="small"
              >
                {isExpanded ? 'Collapse preview' : 'Expand preview'}
              </Button>
            </div>
            <div className={setClass('k-ks-toggle', !isExpanded && '--hidden')}>
              <ButtonGroup
                initialSelected={isDarkMode ? 1 : 0}
                activeWatch={isDarkMode ? 1 : 0}
                onToggle={(index) => setIsDarkMode(index !== 0)}
              >
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(false)}
                >
                  Light Mode
                </ToggleButton>
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(true)}
                >
                  Dark Mode
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div
            className={setClass([
              'k-library-preview',
              !isExpanded ? 'k-library-preview--collapsed' : '',
            ])}
          >
            <div className="k-library-preview__bg">
              <img
                draggable="false"
                src={setPreviewImage({
                  isDarkMode,
                  displayMode: model.general.displayMode,
                })}
                alt="Preivew"
              />
              <div className="k-library-preview__item k-library-preview__item--success">
                <img
                  draggable="false"
                  className="k-library-preview__frame k-library-preview__frame--success"
                  src={
                    isDarkMode
                      ? previewPaths.successFrameDark
                      : previewPaths.successFrame
                  }
                  alt="Success Screen"
                />
                <img
                  draggable="false"
                  className={setClass(
                    'k-library-preview__logo',
                    companyLogo.isSquare && '--round-corner-preview',
                  )}
                  src={companyLogo.src}
                  alt="Company Logo"
                />
                <KandjiLogo isDarkMode={isDarkMode} />
                <div
                  className={`k-library-preview__content preview-content--success ${
                    isDarkMode ? 'k-library-preview__content--dark' : ''
                  }`}
                >
                  <img
                    draggable="false"
                    className="k-ks-success-icons"
                    src={
                      isDarkMode
                        ? successIconsPath.successIconsDark
                        : successIconsPath.successIcons
                    }
                    alt="Success"
                  />
                  <h2 className="k-library-preview__title --success">
                    {model.successScreen.header}
                  </h2>
                  <p className="k-library-preview__copy">
                    {model.successScreen.subHeader}
                  </p>
                  <LinkPreviews
                    isEditing={false}
                    isDarkMode={isDarkMode}
                    model={model}
                    setModel={setModel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
