import {
  Button,
  Icon,
  sidePanel as SidePanel,
  TextInput,
  setClass,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import './sspanel.css';

import ScreenSaverService from '../../service/screen-saver-service';

const SSPanel = (props) => {
  const {
    setting,
    update,
    isDisabled,
    validationDep,
    beforeEditModel,
    field,
    testId,
    triggerValidation,
  } = props;
  const [isSelectingSaver, setIsSelectingSaver] = useState(false);

  const { refs, onInvalidate } = useInputsValidators(
    [`${testId}_other`],
    update,
  );
  useRemoveValidationOnUnmount([`${testId}_other`], update);

  const selectedModule = ScreenSaverService.SCREEN_SAVER_OPTIONS.find(
    ({ value }) => value === setting[field],
  );
  const isOtherOption = !setting[field] || !selectedModule;

  return (
    <>
      <div
        className={`l-ssaver-panel__button ${isDisabled ? '--disabled' : ''}`}
        onClick={() => {
          if (!isDisabled) {
            setIsSelectingSaver((prev) => !prev);
          }
        }}
        data-testid={testId}
      >
        <div className="l-ssaver-panel__button-preview">
          <img
            src={selectedModule?.src || ScreenSaverService.OTHER_SS}
            alt=""
            className="l-ssaver-panel__button-preview-img"
          />
          <p className="b-txt-bold">{selectedModule?.label || 'Other'}</p>
        </div>
        <Icon
          name="pencil"
          className={`l-ssaver-panel__button-icon ${
            isDisabled ? '--disabled' : ''
          }`}
        />
      </div>

      <SidePanel className={`${testId}_sidepanel`} isVisible={isSelectingSaver}>
        <div className="l-ssaver-panel" data-testid={`${testId}_panel`}>
          <div className="l-ssaver-panel__title">
            <h2 className="b-h2">Manage screen saver module</h2>
          </div>
          <div ref={refs[0]} className="l-ssaver-panel__body">
            <p className="b-txt b-mb2">
              Select a built-in macOS screensaver, or specify a custom path by
              selecting Other.
            </p>
            <div className="l-ssaver-panel__body-grid">
              {ScreenSaverService.SCREEN_SAVER_OPTIONS.map(
                ({ label, value, src, isOther }) => {
                  const isSelected =
                    value === setting[field] || (isOtherOption && isOther);
                  return (
                    <div
                      key={value}
                      className={setClass(
                        'l-ssaver-panel__option',
                        isSelected && '--selected',
                      )}
                      onClick={() => {
                        if (!isOther) {
                          onInvalidate(0)(false);
                        }
                        update(field, value);
                      }}
                      data-testid={`${value}_panel_option`}
                    >
                      <img
                        src={src}
                        alt=""
                        className="l-ssaver-panel__option-img"
                      />
                      <p className="b-txt">{label}</p>
                    </div>
                  );
                },
              )}
            </div>
            {isOtherOption && (
              <TextInput
                fieldsGrid
                label="Module path"
                placeholder="/System/Library/Screen Savers/Flurry.saver"
                value={setting[field]}
                onChange={(e) => {
                  const { value } = e.target;
                  update(field, value);
                }}
                validator={(v) => [
                  {
                    message: 'Required.',
                    invalid: () => !v.length,
                    trigger: ['onBlur', 'onInput', validationDep],
                  },
                  {
                    message: 'File extension must be .saver or .appex.',
                    invalid: () =>
                      !v.endsWith('.saver') && !v.endsWith('.appex'),
                    trigger: ['onBlur', validationDep],
                  },
                ]}
                onInvalidate={onInvalidate(0)}
                data-testid="ss-panel-other-input"
              />
            )}
          </div>
          <div className="l-ssaver-panel__footer b-flex-g">
            <Button
              onClick={() => {
                setIsSelectingSaver(false);
                update(field, beforeEditModel[field]);
                onInvalidate(0)(false);
              }}
              className="b-mr"
              kind="outline"
              isDisabled={isDisabled}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                triggerValidation();
                if (!setting.invalidationsMap[`${testId}_other`]?.isInvalid) {
                  setIsSelectingSaver(false);
                }
              }}
              isDisabled={isDisabled}
            >
              Save
            </Button>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default SSPanel;
