/* istanbul ignore file */
import uuidv4 from 'uuid/v4';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SingleSignOnExtensionService extends NewLibraryItemService {
  static AuthorizationMode = {
    Standard: 'Standard',
    Admin: 'Admin',
    Groups: 'Groups',
  };

  static AuthorizationMethod = {
    Password: 'Password',
    UserSecureEnclaveKey: 'UserSecureEnclaveKey',
    SmartCard: 'SmartCard',
  };

  static getEmptyUserGroup() {
    return {
      id: uuidv4(),
      group: '',
      authRight: '',
    };
  }
}

export const singleSignOnExtensionService = new SingleSignOnExtensionService();

export default SingleSignOnExtensionService;
