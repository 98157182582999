import React, { useState, useEffect } from 'react';

import { Button, Icon } from '@kandji-inc/bumblebee';

import Loader from 'theme/components/atoms/Loader';

import { Card, Header, SubHeader } from 'features/library-items/template';

import featureFlags from 'src/config/feature-flags';
import iconEmptyStateOverview from '../../assets/empty-state-overview.svg';

import { useAuthority, useConnector, useIs, useModal } from '../../hooks';

import { configOverview } from '../../../integrator/configs/adcs/config-overview';
import { IntegratorView } from '../../../integrator/integrator-view';
import Connector from './connector';

const Content = () => {
  const { fetchAuthorityList, clearAuthorityList } = useAuthority();
  const adcsIntegratorFlow = featureFlags.getFlag('paint-adcs-integrator-flow');

  const {
    connectorList,
    connectorLimit,
    fetchConnectorList,
    clearConnectorList,
    fetchConnectorLimit,
  } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { openModal } = useModal();

  const [checked, setChecked] = useState(false);
  const [addConnectorDialog, setAddConnectorDialog] = useState(false);

  const hasReachedConnectorLimit = connectorList.length >= connectorLimit;

  /* istanbul ignore next */
  const getConfigByAction = (action) => {
    const config = { ...configOverview };
    switch (action) {
      case 'addConnector':
        config.pages = config.pages.filter((page) =>
          ['adcsDownloadAndCreate', 'adcsInstructions'].includes(page.name),
        );
        break;
      default:
    }

    return config;
  };

  /* istanbul ignore next */
  const openModalAddConnector = () => {
    if (adcsIntegratorFlow) {
      // need to find out more sophisticated approach to reset the state
      setAddConnectorDialog(false);
      setTimeout(() => {
        setAddConnectorDialog(true);
      }, 0);
    } else {
      openModal('adcs', 1);
    }
  };

  /* istanbul ignore next */
  const check = async () => {
    setChecked(false);
    await Promise.all([
      fetchConnectorLimit(),
      fetchAuthorityList(),
      fetchConnectorList(),
    ]);
    setChecked(true);
  };

  /* istanbul ignore next */
  const reset = () => {
    clearAuthorityList();
    clearConnectorList();
    setChecked(false);
  };

  useEffect(() => {
    check();

    return () => {
      reset();
    };
  }, []);

  /* istanbul ignore next */
  const loopConnector = connectorList.length > 0 && (
    <div className="k-adcs-connector-list">
      {connectorList.map(
        /* istanbul ignore next */
        (connector) => (
          <Connector key={connector.id} connector={connector} />
        ),
      )}
    </div>
  );

  const MaxConnectorLimit = () => {
    if (!hasReachedConnectorLimit) {
      return null;
    }
    return (
      <p className="k-adcs-limit b-txt b-txt--error">
        <Icon name="octagon-exclamation" className="b-mr-tiny" />
        <span>Max number of connectors allowed = {connectorLimit}</span>
      </p>
    );
  };

  const isEmpty = connectorList.length === 0;

  return (
    <>
      <Card className="k-adcs-connector-bar b-mb">
        <Header className="k-adcs-connector-header">
          <div className="k-adcs-connector-heading">
            <h3 className="b-h3">Connectors</h3>
            <MaxConnectorLimit />
            {addConnectorDialog && (
              /* istanbul ignore next */
              <IntegratorView config={getConfigByAction('addConnector')} />
            )}
            <Button
              kind="link"
              size="small"
              icon="circle-plus"
              onClick={openModalAddConnector}
              isDisabled={isLoading || isWorking || hasReachedConnectorLimit}
            >
              Add connector
            </Button>
          </div>
        </Header>

        {checked && isEmpty && (
          <div className="k-adcs-table-empty">
            <SubHeader>
              <p className="b-txt">
                In order to issue certificates to your Apple fleet, it is
                required to have an active AD CS Connector with assigned AD CS
                servers.{' '}
                <a
                  className="b-alink"
                  href="https://support.kandji.io/support/solutions/articles/72000566079"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More...
                </a>
              </p>
            </SubHeader>

            <div className="k-adcs-table-body">
              <img src={iconEmptyStateOverview} alt="empty" />

              <h3 className="b-h3 b-mt">A connector has not been configured</h3>

              <p className="b-txt b-mt1 b-mb1">
                Download the AD CS connector and install it on a Windows Server
                <br />
                machine. You will be prompted to authenticate with your Kandji
                <br />
                administrative credentials to finalize the setup.
              </p>
            </div>
          </div>
        )}
      </Card>

      {
        /* istanbul ignore next */
        checked ? loopConnector : <Loader type="line" />
      }
    </>
  );
};

export default Content;
