import history from 'app/router/history';
import image404 from 'assets/img/404.svg';
import React from 'react';
import { logError } from 'src/app/common/logger';
import './ErrorBoundary.css';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    history.listen(() => this.setState({ hasError: false }));
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="main-error-boundary">
          <img
            src={image404}
            alt="404"
            style={{ margin: '0 auto 80px', width: 'fit-content' }}
          />
          <h1>Something went wrong</h1>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
