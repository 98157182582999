import { Checkbox, TextInput, Toggle } from '@kandji-inc/bumblebee';
import React from 'react';

import { useRemovableValue } from '../hooks';

const ContentWithoutDefaults = (props) => {
  const { update, setting, needReset } = props;
  const { shortDescription, longDescription, isRequireRead } = setting;

  const [isShortDescriptionHidden, setIsShortDescriptionHidden] =
    useRemovableValue(
      shortDescription,
      (v) => update('shortDescription', v),
      needReset,
    );
  const [isLongDescriptionHidden, setIsLongDescriptionHidden] =
    useRemovableValue(
      longDescription,
      (v) => update('longDescription', v),
      needReset,
    );

  return (
    <div className="k-ss2-drawer-section --content">
      <h3 className="b-h3">Content</h3>
      <div className="k-ss2-drawer-setting">
        <div className="k-ss2-drawer-setting-with-toggle">
          <div>
            <p className="b-txt">Display short description</p>
            <p className="b-txt-light">
              A short description is displayed under the Library Item name. The
              provided description is pulled directly from the App Store.
            </p>
          </div>
          <div>
            <Toggle
              checked={!isShortDescriptionHidden}
              onToggle={() =>
                setIsShortDescriptionHidden(!isShortDescriptionHidden)
              }
            />
          </div>
        </div>
        {!isShortDescriptionHidden && (
          <div className="k-ss2-drawer-setting-extra1">
            <div className="b-mt2">
              <TextInput
                textArea
                maxLength={170}
                fieldsGrid="k-ss2-input-grid"
                value={shortDescription || ''}
                onChange={(e) => update('shortDescription', e.target.value)}
                placeholder="Add a custom short description of the Library Item."
              />
            </div>
          </div>
        )}
      </div>

      <div className="k-ss2-drawer-setting">
        <div className="k-ss2-drawer-setting-with-toggle">
          <div>
            <p className="b-txt">Display long description</p>
            <p className="b-txt-light">
              A long description is displayed in the item detail page. The
              provided description is pulled directly from the App Store.
            </p>
          </div>
          <div>
            <Toggle
              checked={!isLongDescriptionHidden}
              onToggle={() =>
                setIsLongDescriptionHidden(!isLongDescriptionHidden)
              }
            />
          </div>
        </div>
        {!isLongDescriptionHidden && (
          <>
            <div className="k-ss2-drawer-setting-extra1">
              <div className="b-mt2">
                <TextInput
                  style={{ minHeight: '212px' }}
                  textArea
                  maxLength={4000}
                  fieldsGrid="k-ss2-input-grid"
                  value={longDescription || ''}
                  onChange={(e) => update('longDescription', e.target.value)}
                  placeholder="Add a custom long description of the Library Item."
                />
              </div>
            </div>
            <div className="k-ss2-drawer-setting-extra">
              <p className="b-txt b-mb1">Additional option</p>
              <Checkbox
                checked={isRequireRead}
                onChange={() => update('isRequireRead', !isRequireRead)}
                label="Require users to read the description before installing"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContentWithoutDefaults;
