import { Icon, styled, theme } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_CHANGE_USER } from 'src/app/api/urls';
import { useGetADEUsers } from '../hooks/useGetADEUsers';
import { ADEListViewStore } from '../store/ADEListViewStore';
import { ADECellSelect } from './ADECellSelect/ADECellSelect';
import { type User, UserSelections, isUser } from './ADEListViewTableColumns';

interface ADECellUserSelectProps {
  deviceId: string;
  user: User | null;
}

export const NotAssignedTrigger = {
  color: theme.colors.neutral60,
  fontStyle: 'italic',
  fontWeight: 'normal',
};

const UserIconWrapper = styled('div', {
  display: 'flex',
});

const UserImage = styled('img', {
  width: 20,
  height: 20,
  borderRadius: '50%',
});

const NoUserImage = styled(Icon, {
  width: 20,
  height: 20,
});

const NotAssignedIcon = styled(NoUserImage, {
  color: theme.colors.neutral60,
  display: 'flex',
});

export async function handleUpdateUser(
  deviceId: string,
  newUser: User | UserSelections,
  oldUser: User | UserSelections,
) {
  const updateUser = ADEListViewStore.getState().updateUser;

  const newUserId = isUser(newUser) ? newUser.id : null;

  if (oldUser == null && newUserId == UserSelections.NOT_ASSIGNED) {
    return;
  }

  if (oldUser === newUserId) return;

  if (isUser(oldUser) && oldUser?.id === newUserId) return;

  try {
    updateUser([deviceId], isUser(newUser) ? newUser : null);
    await api(URL_DEP_COMPUTER_CHANGE_USER).post({
      devices: [deviceId],
      user_id: newUserId,
    });
  } catch {
    updateUser([deviceId], isUser(oldUser) ? oldUser : null);
  }
}

const UserIconSizes = {
  sm: 14,
  default: 20,
};

export function UserIcon({
  user,
  size = 'default',
}: {
  user: User | UserSelections;
  size?: 'sm' | 'default';
}) {
  if (!isUser(user) || user == null) {
    return (
      <NotAssignedIcon
        name="user-large"
        style={{ height: UserIconSizes[size], width: UserIconSizes[size] }}
      />
    );
  }

  return (
    <UserIconWrapper>
      {user.photo == null && (
        <NoUserImage
          name="user-large"
          style={{ height: UserIconSizes[size], width: UserIconSizes[size] }}
        />
      )}
      {user.photo != null && (
        <UserImage
          src={user.photo}
          alt={user.name}
          style={{
            height: UserIconSizes['default'],
            width: UserIconSizes['default'],
          }}
        />
      )}
    </UserIconWrapper>
  );
}

export const ADECellUserSelect = React.memo(function ADECellUserSelect({
  deviceId,
  user,
}: ADECellUserSelectProps) {
  return (
    <ADECellSelect
      value={user?.id ?? UserSelections.NOT_ASSIGNED}
      defaultValue={UserSelections.NOT_ASSIGNED}
      placeholder="Search Users"
      triggerValue={user?.name ?? UserSelections.NOT_ASSIGNED}
      triggerTitle={user?.name ?? UserSelections.NOT_ASSIGNED}
      triggerStyle={user == null ? NotAssignedTrigger : {}}
      ariaLabel={'User Select'}
      onValueChange={
        /* istanbul ignore next */
        (newUser) => handleUpdateUser(deviceId, newUser, user)
      }
      useGetData={useGetADEUsers}
      triggerIcon={<UserIcon user={user} size="sm" />}
      buildItemIcon={(u) => <UserIcon user={u} />}
      valueIsNullable
    />
  );
});
