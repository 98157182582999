import { Banner, Select } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import React from 'react';
import {
  RSR_ENFORCEMENT_DELAY_OPTIONS,
  RSR_ENFORCEMENT_OPTIONS,
  TIME_OPTIONS,
} from './updates-card-constants';
import { RSREnforcementKeys } from './updates-card.types';
import type { SelectOption } from './updates-card.types';

interface RSREnforcementCardProps {
  name: string;
  rsrEnforcement: SelectOption;
  rsrEnforcementDelay: SelectOption;
  rsrEnforcementTime: SelectOption;
  isDisabled: boolean;
  update: (key: string, value: unknown) => void;
}

export function RSREnforcementUpdateSection({
  name,
  rsrEnforcement,
  rsrEnforcementDelay,
  rsrEnforcementTime,
  isDisabled,
  update,
}: RSREnforcementCardProps) {
  return (
    <>
      <Setting.Row>
        <Setting.Title className="b-txt">
          Rapid Security Response (RSR) Enforcement
        </Setting.Title>

        <Setting.Helpers className="b-txt-light">
          <p>
            Specify if Kandji should automatically enforce Rapid Security
            Response updates when made available from Apple.
          </p>
          <Banner theme="info">
            <p>
              Rapid Security Response updates only apply to devices running the
              latest OS version.
            </p>
          </Banner>
        </Setting.Helpers>

        <Setting.Controls>
          <Select
            inputId="rsr-enforcement-select"
            value={rsrEnforcement}
            options={RSR_ENFORCEMENT_OPTIONS}
            disabled={isDisabled}
            compact
            onChange={(newValue) => update('rsrEnforcement', newValue)}
          />
        </Setting.Controls>
      </Setting.Row>

      {rsrEnforcement.value === RSREnforcementKeys.AUTOMATICALY && (
        <Setting.Row>
          <Setting.Title className="b-txt">
            Enforcement timeframe for Rapid Security Response updates
          </Setting.Title>

          <Setting.Helpers className="b-txt-light">
            <p>
              Specify when Rapid Security Response updates should be enforced.
              The update will be applied silently unless a restart is required.
              If a restart is required, users will be able to voluntarily update
              before the enforcement deadline.
            </p>
            <Banner theme="info">
              <p>
                {name} enforces updates in the device&apos;s local timezone.
              </p>
            </Banner>
          </Setting.Helpers>

          <Setting.Controls>
            <div className="enforcement-delay-wrapper">
              <Select
                inputId="enforcement-timeframe-select"
                options={RSR_ENFORCEMENT_DELAY_OPTIONS}
                disabled={isDisabled}
                onChange={(newValue) => update('rsrEnforcementDelay', newValue)}
                value={rsrEnforcementDelay}
              />
              <div className="b-txt">After an update is released</div>
            </div>
            <Select
              className="b-mt2"
              inputId="enforcement-time"
              value={rsrEnforcementTime}
              options={TIME_OPTIONS}
              disabled={isDisabled}
              compact
              onChange={(newValue) => update('rsrEnforcementTime', newValue)}
            />
          </Setting.Controls>
        </Setting.Row>
      )}
    </>
  );
}
