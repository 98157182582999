import {
  Banner,
  Button,
  LogoUploader,
  Select,
  sidePanel as SidePanel,
  TextInput,
  Toggle,
  setClass,
  urlValidator,
  useInvalidations,
  useRefs,
  useShadowOnScroll,
} from '@kandji-inc/bumblebee';
import RTFFileSelect from 'components/common/upload/rtf-file-select';
import RTFFileViewer from 'components/common/upload/rtf-file-viewer';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import featureFlags from 'src/config/feature-flags';
import DefaultDesktopBg from '../../assets/macos-monterey.png';
import DefaultDesktopBgSonoma from '../../assets/macos-sonoma.jpg';
import {
  mapInvalidIndexToFieldWithRefs,
  requiredFileValidator,
  requiredTextValidator,
} from '../../input-validations';
import KandjiLoginService from '../../service/kandji-login-service';
import DefaultKandji from '../common/assets/kandji-bee-logo-transparent.png';

const lockOptions = [
  { label: "Don't display a lock message", value: 'none' },
  { label: 'Display a custom lock message', value: 'custom' },
  { label: 'Inherit system settings', value: 'inherit' },
];

const policyOptions = [
  { label: "Don't display a policy banner", value: 'none' },
  { label: 'Display a plain-text policy banner', value: 'plain' },
  { label: 'Display an RTF policy banner', value: 'rtf' },
  { label: 'Inherit system settings', value: 'inherit' },
];

const Drawer = (props) => {
  const { isVisible, setIsVisible, setting, authSetting, update, onClose } =
    props;
  const {
    isDesktopBg,
    isLogo,
    lockMessageOption,
    policyBannerOption,
    isCustomUsername,
    isIncludePasswordUrl,
  } = setting;
  const { isShowHeaderShadow, setBody } = useShadowOnScroll();
  const refs = useRefs(6);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 6 });
  const invalidationsMap = mapInvalidIndexToFieldWithRefs(
    invalidations,
    [
      'brandingLogo',
      'brandingDesktopImage',
      'bannerLock',
      'bannerPolicy',
      'customUsername',
      'passwordResetUrl',
    ],
    refs,
  );
  const [toggleValidation, setToggleValidation] = useState();

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  const onCancel = () => {
    setIsVisible(false);
    onClose();
  };

  const LDFFpassportSonoma = featureFlags.getFlag('LIT-WWDC23-passport-sonoma');
  const LDFFpassportAllowOpenWifiNetworks = featureFlags.getFlag(
    'dc-06142024_passport-allow-open-wifi-networks',
  );
  return (
    <SidePanel isVisible={isVisible}>
      <div className="b-side-panel-layout">
        <div
          className={setClass(
            'b-side-panel-layout__header',
            isShowHeaderShadow && '--with-shadow',
          )}
        >
          <h2 className="b-h2">Customize login window</h2>
        </div>
        <div
          className="b-side-panel-layout__body --k-ss2-style-body hubspot-buffer-bottom"
          ref={setBody}
        >
          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">Branding</h3>
            {authSetting.idp &&
              authSetting.mode ===
                KandjiLoginService.authenticationMode.WEB_LOGIN && (
                <Banner kind="block" theme="info" className="b-mb2">
                  <span>
                    Logo will only be displayed at the Local Login window.
                  </span>
                </Banner>
              )}
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Display logo</p>
                  <p className="b-txt-light">
                    Include your organization’s logo on the login window.
                  </p>
                </div>
                <div>
                  <Toggle
                    checked={isLogo}
                    onToggle={() => update('isLogo', !isLogo)}
                  />
                </div>
              </div>
              {setting.isLogo && (
                <div ref={refs[0]} className="b-mt2">
                  <p className="b-txt">Logo</p>
                  <p className="b-txt-light">
                    A 128x128 pixel PNG file with a transparent background is
                    recommended.
                  </p>
                  <LogoUploader
                    className="b-mt1 k-klogin-logo-preview"
                    smallPreview
                    icon={setting.logoUrl || setting.logo || DefaultKandji}
                    onRemove={() => {
                      update('logoUrl', '');
                      update('logoS3Key', '');
                      update('logo', null);
                    }}
                    onImage={(img) => {
                      update('logoUrl', '');
                      update('logoS3Key', '');
                      update('logo', img);
                    }}
                    canRemove={setting.logoUrl || setting.logo}
                    validators={[
                      (v) => null,
                      (file) => {
                        if (!file) {
                          return null;
                        }

                        const allowedFileTypes = ['jpg', 'jpeg', 'png'];

                        if (
                          !allowedFileTypes.some((ftype) =>
                            file?.type?.includes(ftype),
                          )
                        ) {
                          return 'Image needs to be in PNG or JPEG format';
                        }

                        return null;
                      },
                    ]}
                    runValidatorsOn={[toggleValidation]}
                    renderTip={({ icon }) => (
                      <>
                        {icon}
                        <p className="b-txt">
                          Drop image here or{' '}
                          <a
                            href=""
                            onClick={(e) => e.preventDefault()}
                            className="b-alink"
                          >
                            upload
                          </a>
                        </p>
                        <p className="b-txt k-ssw-style-drawer-bg-tip">
                          JPEG, PNG
                        </p>
                      </>
                    )}
                  />
                </div>
              )}
            </div>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Customize Desktop picture</p>
                  <p className="b-txt-light">
                    Upload a custom image to display on the login window.
                  </p>
                </div>
                <div>
                  <Toggle
                    checked={isDesktopBg}
                    onToggle={() => {
                      update('isDesktopBg', !isDesktopBg);
                      if (
                        !isDesktopBg &&
                        !setting.desktopBgUrl &&
                        !setting.desktopBg
                      ) {
                        onInvalidate(1)(true);
                      } else {
                        onInvalidate(1)(false);
                      }
                    }}
                  />
                </div>
              </div>
              {setting.isDesktopBg && (
                <div ref={refs[1]} className="b-mt2">
                  <p className="b-txt">Desktop picture</p>
                  <p className="b-txt-light">
                    A 3840x2160 pixel image file is recommended.
                  </p>
                  <LogoUploader
                    className="b-mt1 li-passport__login-bg-uploader"
                    // accept=".heic,image/*"
                    largePreview
                    icon={
                      setting.desktopBgUrl ||
                      setting.desktopBg ||
                      (LDFFpassportSonoma && DefaultDesktopBgSonoma) ||
                      DefaultDesktopBg
                    }
                    onRemove={() => {
                      update('desktopBgUrl', '');
                      update('desktopBgS3Key', '');
                      update('desktopBg', null);
                      onInvalidate(1)(true);
                    }}
                    onImage={(img) => {
                      update('desktopBgUrl', '');
                      update('desktopBgS3Key', '');
                      update('desktopBg', img);
                      onInvalidate(1)(false);
                    }}
                    canRemove={setting.desktopBgUrl || setting.desktopBg}
                    validators={[
                      (file) =>
                        requiredFileValidator(
                          'Upload a custom image or turn the toggle off to use the default image. ',
                        )(setting.desktopBgUrl || file),
                      (file, image) => {
                        if (image.height < 500 || image.width < 1024) {
                          return 'Image should be at least 1024 x 500 pixels';
                        } else if (image.height > 6016 || image.width > 6016) {
                          return 'Image can be a maximum of 6016 x 6016 pixels';
                        }
                      },
                      (file) => {
                        if (!file) {
                          return null;
                        }

                        const allowedFileTypes = ['jpg', 'jpeg', 'png'];

                        if (
                          !allowedFileTypes.some((ftype) =>
                            file?.type?.includes(ftype),
                          )
                        ) {
                          return 'Image needs to be in PNG or JPEG format';
                        }

                        return null;
                      },
                    ]}
                    runValidatorsOn={[toggleValidation]}
                    renderTip={({ icon }) => (
                      <>
                        {icon}
                        <p className="b-txt">
                          Drop image here or{' '}
                          <a
                            href=""
                            onClick={(e) => e.preventDefault()}
                            className="b-alink"
                          >
                            upload
                          </a>
                        </p>
                        <p className="b-txt k-ssw-style-drawer-bg-tip">
                          {/* JPEG, PNG, HEIC */}
                          JPEG, PNG
                        </p>
                      </>
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">Menu bar</h3>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Network manager</p>
                  <p className="b-txt-light">
                    Include the Wi-Fi network manager in the menu bar.
                  </p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isNetworkManager}
                    onToggle={() =>
                      update('isNetworkManager', !setting.isNetworkManager)
                    }
                  />
                </div>
              </div>
            </div>
            {LDFFpassportAllowOpenWifiNetworks && setting.isNetworkManager && (
              <div className="k-ss2-style-drawer-setting">
                <div className="k-ss2-style-drawer-setting-with-toggle">
                  <div>
                    <p className="b-txt">
                      Allow connecting to unsecured Wi-Fi networks
                    </p>
                    <p className="b-txt-light b-mb1">
                      Allows users to use the Wi-Fi network manager to connect
                      to unsecured Wi-Fi networks.
                    </p>
                    <p className="b-txt-light">
                      <strong>Note: </strong> Captive Portal networks are not
                      supported.
                    </p>
                  </div>
                  <div>
                    <Toggle
                      checked={setting.allowOpenWifiNetworks}
                      onToggle={() =>
                        update(
                          'allowOpenWifiNetworks',
                          !setting.allowOpenWifiNetworks,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">Banners</h3>
            <div ref={refs[2]} className="k-ss2-style-drawer-setting">
              <p className="b-txt b-mb-micro">Lock message</p>
              <p className="b-txt-light b-mb1">
                Choose to display or hide a lock message.
              </p>
              <Select
                options={lockOptions}
                value={lockOptions.find(
                  (opt) => opt.value === lockMessageOption,
                )}
                onChange={({ value }) => {
                  update('lockMessageOption', value);
                  if (value === 'custom' && !setting.customLockMessage) {
                    onInvalidate(2)(true);
                  } else {
                    onInvalidate(2)(false);
                  }
                }}
              />
              {lockMessageOption === 'custom' && (
                <TextInput
                  className="b-mt1 b-mb-tiny k-klogin-lock-banner-input"
                  style={{ height: '160px' }}
                  textArea
                  value={setting.customLockMessage}
                  onChange={(e) => update('customLockMessage', e.target.value)}
                  validator={requiredTextValidator(
                    [toggleValidation],
                    'Selected option is a required field.',
                  )}
                  onInvalidate={onInvalidate(2)}
                  maxLength={220}
                  placeholder="Add a custom lock message."
                />
              )}
            </div>
            <div ref={refs[3]} className="k-ss2-style-drawer-setting">
              <p className="b-txt b-mb-micro">Policy banner</p>
              <p className="b-txt-light b-mb1">
                Choose to display or hide a policy banner.
              </p>
              <Select
                options={policyOptions}
                value={policyOptions.find(
                  (opt) => opt.value === policyBannerOption,
                )}
                onChange={({ value }) => {
                  update('policyBannerOption', value);
                  if (
                    (value === 'plain' && !setting.policyBannerText) ||
                    (value === 'rtf' &&
                      !setting.policyBannerFile &&
                      !setting.policyBannerS3Key &&
                      !setting.policyBannerUrl)
                  ) {
                    onInvalidate(3)(true);
                  } else {
                    onInvalidate(3)(false);
                  }
                }}
              />
              {policyBannerOption === 'plain' && (
                <TextInput
                  className="b-mt1 b-mb-tiny"
                  style={{ height: '160px' }}
                  textArea
                  value={setting.policyBannerText}
                  onChange={(e) => update('policyBannerText', e.target.value)}
                  validator={requiredTextValidator([
                    toggleValidation,
                    'Selected option is a required field.',
                  ])}
                  onInvalidate={onInvalidate(3)}
                  maxLength={900}
                  placeholder="Add a plain-text policy banner."
                />
              )}
              {policyBannerOption === 'rtf' && (
                <div className="b-mt2">
                  {setting.policyBannerFile || setting.policyBannerUrl ? (
                    <RTFFileViewer
                      file={
                        setting.policyBannerFile ||
                        (setting.policyBannerUrl
                          ? {
                              ...setting.policyBannerFileMeta,
                              url: setting.policyBannerUrl,
                            }
                          : null)
                      }
                      onTrash={() => {
                        update('policyBannerFile', null);
                        update('policyBannerUrl', '');
                        update('policyBannerS3Key', '');
                        update('policyBannerFileMeta', {});
                        onInvalidate(3)(true);
                      }}
                      size="sm"
                    />
                  ) : (
                    <RTFFileSelect
                      allowedFileTypes={['rtf']}
                      onFileSelect={(files) => {
                        update('policyBannerFile', files[0]);
                        update('policyBannerUrl', '');
                        update('policyBannerFileMeta', {
                          name: files[0].name,
                          size: files[0].size,
                          uploadedAt: files[0].lastModified,
                        });
                        onInvalidate(3)(false);
                      }}
                      validators={[
                        (file) =>
                          requiredFileValidator()(
                            file || setting.policyBannerS3Key,
                          ),
                      ]}
                      toggleValidationOn={[toggleValidation]}
                      size="sm"
                    >
                      {({ icon }) => (
                        <div className="b-flex-col b-flex-vc">
                          <img
                            src={icon}
                            className="b-mb-tiny"
                            alt="upload"
                            style={{
                              width: 'var(--b-gap2)',
                              height: 'var(--b-gap2)',
                            }}
                          />
                          <p className="b-txt">
                            Drop RTF file or{' '}
                            <a
                              href=""
                              onClick={(e) => e.preventDefault()}
                              className="b-alink"
                            >
                              upload
                            </a>
                          </p>
                        </div>
                      )}
                    </RTFFileSelect>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3 b-mb1">Power controls</h3>
            <p className="b-txt-light b-mb2">
              Specify which power controls will be accessible from the login
              window.
            </p>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Shut Down button</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isShutdownButton}
                    onToggle={() =>
                      update('isShutdownButton', !setting.isShutdownButton)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Restart button</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isRestartButton}
                    onToggle={() =>
                      update('isRestartButton', !setting.isRestartButton)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Sleep button</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isSleepButton}
                    onToggle={() =>
                      update('isSleepButton', !setting.isSleepButton)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">Username and Password</h3>
            {authSetting.idp &&
              authSetting.mode ===
                KandjiLoginService.authenticationMode.WEB_LOGIN && (
                <Banner kind="block" theme="info" className="b-mb2">
                  <span>
                    Username and password reset will only be displayed at the
                    Local Login window.
                  </span>
                </Banner>
              )}
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Customize username label</p>
                  <p className="b-txt-light">
                    Add a custom label that will display in the username field.
                  </p>
                </div>
                <div ref={refs[4]}>
                  <Toggle
                    checked={isCustomUsername}
                    onToggle={() => {
                      if (isCustomUsername) {
                        update('customUsername', '');
                      }
                      update('isCustomUsername', !isCustomUsername);
                      if (!isCustomUsername && !setting.customUsername) {
                        onInvalidate(4)(true);
                      } else {
                        onInvalidate(4)(false);
                      }
                    }}
                  />
                </div>
              </div>
              {isCustomUsername && (
                <TextInput
                  className="b-mt2 b-mb-tiny"
                  value={setting.customUsername}
                  placeholder="Username"
                  onChange={(e) => update('customUsername', e.target.value)}
                  maxLength={83}
                  validator={requiredTextValidator(
                    [toggleValidation],
                    'Required field if toggle is on.',
                  )}
                  onInvalidate={onInvalidate(4)}
                />
              )}
            </div>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">Include password reset URL</p>
                  <p className="b-txt-light">
                    Allow users to reset their passwords from the login
                    window.&nbsp;
                    <a
                      href="https://support.kandji.io/support/solutions/articles/72000558707"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="b-alink"
                    >
                      Learn more...
                    </a>
                  </p>
                </div>
                <div ref={refs[5]}>
                  <Toggle
                    checked={isIncludePasswordUrl}
                    onToggle={() => {
                      update('isIncludePasswordUrl', !isIncludePasswordUrl);
                      if (!isIncludePasswordUrl && !setting.passwordResetUrl) {
                        onInvalidate(5)(true);
                      } else {
                        onInvalidate(5)(false);
                      }
                    }}
                  />
                </div>
              </div>
              {isIncludePasswordUrl && (
                <TextInput
                  className="b-mt2 b-mb-tiny"
                  value={setting.passwordResetUrl}
                  placeholder="https://"
                  onChange={(e) => update('passwordResetUrl', e.target.value)}
                  maxLength={2048}
                  validator={(v) => [
                    {
                      message: 'Required',
                      invalid: () => !v,
                      trigger: ['onBlur', toggleValidation],
                    },
                    urlValidator(v, {
                      options: { protocols: ['https'] },
                      trigger: ['onBlur', toggleValidation],
                    }),
                  ]}
                  onInvalidate={onInvalidate(5)}
                  textArea
                  hideMaxLength
                />
              )}
            </div>
          </div>
        </div>

        <div className="b-side-panel-layout__footer">
          <div className="b-flex-justify-end">
            <div className="b-grid-ctas">
              <Button kind="outline" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  const invalids = Object.values(
                    setting.invalidationsMap,
                  ).filter((d) => d.isInvalid);
                  if (!invalids.length) {
                    setIsVisible(false);
                  } else {
                    setToggleValidation((prev) => !prev);
                    const withRef = invalids.find((d) => d.ref);
                    withRef?.ref.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest',
                    });
                  }
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default Drawer;
