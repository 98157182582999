/* istanbul ignore file */

import React, { useState, useEffect } from 'react';

import uuid from 'uuid/v4';

import {
  Banner,
  Button,
  Flex,
  Icon,
  Select,
  TextInput,
  useInvalidations,
} from '@kandji-inc/bumblebee';

import { Controls, Title } from 'features/library-items/template';

import { STATUS_CONNECTED } from 'features/integrations/components-for-specific-types/adcs/status';
import { useAuthority, useConnector, useIs, usePanel } from '../../hooks';

import { findOption, includeByProperty } from '../../util';

import { validatorAddServerName } from '../../validation';

import Panel from '..';
import Header from '../header';

const PanelAddServer = () => {
  const {
    authorityList,
    authorityLimit,
    addAuthorityItemBulk,
    fetchAuthorityList,
  } = useAuthority();

  const { connectorList, fetchConnectorList } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { panel, closePanel } = usePanel();

  const isOpen = !!panel['add-server'];

  const server = {
    key: uuid(),
    name: '',
    connector: null,
  };

  const initial = {
    add: [server],
  };

  const [form, setForm] = useState(initial);

  const { add } = form;

  const { invalidations, setInvalidations, onInvalidate } = useInvalidations({
    inputs: add.length,
  });

  const hasInvalidations = invalidations.some(Boolean);

  const hasReachedAuthorityLimit = authorityList.length >= authorityLimit;

  const existingServerNames = authorityList.map(({ name: n }) => n);

  const [serverNames, setServerNames] = useState([]);

  const filteredConnectorList = includeByProperty(
    connectorList,
    'status',
    STATUS_CONNECTED,
  );

  /* istanbul ignore next */
  const hasConnectorInstalled = filteredConnectorList?.length > 0;

  const optionsConnectors = [
    {
      value: null,
      label: 'Not assigned',
    },
    ...filteredConnectorList?.map(
      ({ id: value, name: connectorName, bound_domain }) => ({
        label:
          connectorName || bound_domain || 'Connector details are pending...',
        value,
      }),
    ),
  ];

  const valueConnector = (index) => {
    const list = [...add];

    return findOption(list[index].connector, optionsConnectors);
  };

  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  /* istanbul ignore next */
  const handleAddServer = () => {
    updateForm('add', [...add, server]);

    setServerNames(add.map(({ name: n }) => n));
  };

  /* istanbul ignore next */
  const handleEditServerName = (index, { value }) => {
    const list = [...add];

    list[index].name = value;

    updateForm('add', list);
  };

  /* istanbul ignore next */
  const handleEditServerConnector = (index, value) => {
    const list = [...add];

    list[index].connector = value;

    updateForm('add', list);
  };

  /* istanbul ignore next */
  const handleDeleteServer = (index) => {
    // if we are deleting this server, it no longer can cause
    // an invalidation and we need to reset it
    const updatedInvalidations = [...invalidations];

    updatedInvalidations[index] = false;

    setInvalidations(updatedInvalidations);

    return updateForm('add', [...add.slice(0, index), ...add.slice(index + 1)]);
  };

  /* istanbul ignore next */
  const reset = () => {
    setForm(initial);
    setServerNames([]);
  };

  const onCancel = () => {
    closePanel(reset);
  };

  const onSubmit = async () => {
    await addAuthorityItemBulk(form);
    fetchAuthorityList();
    fetchConnectorList();
    closePanel(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen) {
      fetchConnectorList();
    }
  }, [isOpen]);

  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  const loopAddServer = add?.length > 0 && (
    <div className="k-adcs-add-server-list k-section-border-bottom">
      {add?.map(({ key, name }, index) => (
        <div
          key={key}
          className={`k-adcs-add-server-item k-adcs-add-server-item-${index}`}
        >
          <div className="k-section-secondary">
            <Title>
              <p className="b-txt b-mb1">
                Server name and connector assignment
              </p>
            </Title>

            <Controls>
              <TextInput
                className="b-mb"
                placeholder="Enter the server name"
                value={name}
                validator={validatorAddServerName(
                  serverNames,
                  existingServerNames,
                )}
                onInvalidate={onInvalidate(index)}
                onChange={
                  /* istanbul ignore next */
                  ({ target }) => handleEditServerName(index, target)
                }
                disabled={isLoading || isWorking}
              />

              <Select
                className={
                  /* istanbul ignore next */
                  hasConnectorInstalled ? undefined : 'k-adcs-select-disabled'
                }
                placeholder="Select connector from list"
                options={optionsConnectors}
                value={valueConnector(index)}
                onChange={
                  /* istanbul ignore next */
                  hasConnectorInstalled
                    ? ({ value }) => handleEditServerConnector(index, value)
                    : () => {}
                }
                disabled={isLoading || isWorking || !hasConnectorInstalled}
              />
            </Controls>

            <Flex className="b-mt">
              <div className="b-grid-ctas">
                <Button
                  kind="link"
                  theme="error"
                  onClick={
                    /* istanbul ignore next */
                    () => handleDeleteServer(index)
                  }
                  isDisabled={add?.length === 1 || isLoading || isWorking}
                >
                  Delete
                </Button>
              </div>
            </Flex>
          </div>
        </div>
      ))}
    </div>
  );

  const MaxAuthorityLimit = () => {
    if (!hasReachedAuthorityLimit) {
      return null;
    }
    return (
      <p className="k-adcs-limit b-txt b-txt--error no-gap">
        <Icon name="octagon-exclamation" className="b-mr-tiny" />
        Max number of servers allowed = {authorityLimit}
      </p>
    );
  };

  return (
    <Panel name="add-server">
      <Header title="Add AD CS servers" />

      <div className="b-side-panel-layout__body">
        <div className="k-section-primary">
          <p className="b-txt">
            Enter the server name and specify the connector assignment for
            certificate management.{' '}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000569107-active-directory-certificate-services-ad-cs-integration-setup-and-configure"
              className="b-alink"
              target="_blank"
              rel="noreferrer"
            >
              Learn more...
            </a>
          </p>

          {!hasConnectorInstalled && (
            <Banner className="b-mt" theme="info" kind="block">
              <p>Servers can be assigned to the connector once installed.</p>
            </Banner>
          )}
        </div>

        <div className="k-section-primary k-section-border-top b-mb">
          {loopAddServer}
        </div>

        <div className="k-section-primary">
          <Flex>
            <div className="b-grid-ctas">
              <Button
                kind="link"
                icon="circle-plus"
                onClick={handleAddServer}
                isDisabled={isLoading || isWorking || hasReachedAuthorityLimit}
              >
                Add server
              </Button>
              <MaxAuthorityLimit />
            </div>
          </Flex>
        </div>
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="flex-end">
          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onCancel}
              isDisabled={isLoading || isWorking}
            >
              Cancel
            </Button>

            <Button
              icon={isWorking ? 'arrows-rotate' : undefined}
              onClick={onSubmit}
              isDisabled={isLoading || isWorking || hasInvalidations}
              isProgress={isWorking}
            >
              Add
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  );
};

export default PanelAddServer;
