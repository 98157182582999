import { TextInput, setClass, useOnChange } from '@kandji-inc/bumblebee';
import { bool, func, object, string } from 'prop-types';
import React, { useState } from 'react';

const Instance = (props: any) => {
  const {
    style,
    className,
    canHaveInstanceName,
    instanceName,
    placeholder,
    isDisabled,
    onChange,
  } = props;

  const [localModel, setLocalModel] = useState({
    isValid: true,
    validationResult: [],
  });

  const [instanceNameInFocus, setInstanceNameInFocus] = useState(false);

  useOnChange(onChange, { ...localModel, instanceName });
  const onFieldChange = (field: any, value: any) => {
    onChange({
      ...localModel,
      instanceName,
      [field]: value,
    });
  };

  const instanceNameMaxLength = 50;

  const isShowingMaxLimitMessage =
    instanceName?.length === instanceNameMaxLength && instanceNameInFocus;

  const instanceDescription =
    'Enter a descriptive label to highlight how this Library Item is different from other instances. This label will not be displayed to end users.';

  return (
    canHaveInstanceName &&
    !isDisabled && (
      <>
        <hr style={{ borderTop: '1px solid var(--color-neutral-20)' }} />
        <div
          style={style}
          className={setClass(
            'b-library-summary__info',
            // increase the padding below the instance name field when we have
            isShowingMaxLimitMessage ? 'mb-5' : '',
            className,
          )}
        >
          <div>
            <p className="b-txt mb-2">Label</p>
            <TextInput
              compact
              placeholder={placeholder}
              value={instanceName}
              onChange={(e: any) => {
                e.persist();
                onFieldChange('instanceName', e.target.value);
              }}
              maxLength={50}
              hideMaxLength
              onFocus={() => setInstanceNameInFocus(true)}
              onBlur={() => setInstanceNameInFocus(false)}
            />
          </div>
          <div className="b-txt-light">{instanceDescription}</div>
        </div>
      </>
    )
  );
};

Instance.propTypes = {
  style: object,
  className: string,
  canHaveInstanceName: bool,
  instanceName: string,
  placeholder: string,
  isDisabled: bool,
  onChange: func.isRequired,
};

Instance.defaultProps = {
  style: {},
  className: null,
  canHaveInstanceName: false,
  instanceName: null,
  placeholder: 'ex. For Marketing, Notifications Off',
  isDisabled: true,
};

export default Instance;
