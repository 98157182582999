export enum ManagedOSNameShort {
  MAC_OS = 'macOS',
  IPAD_OS = 'iPadOS',
  IOS = 'iOS',
  TV_OS = 'tvOS',
}

export enum ManagedOSNameFull {
  MAC_OS_14_SONOMA = 'macOS 14 Sonoma',
}

export enum ManagedOSName {
  MAC_OS_14 = 'macOS 14',
  MAC_OS_13 = 'macOS 13',
  IOS_17 = 'iOS 17',
  IOS_16 = 'iOS 16',
  IOS_15 = 'iOS 15',
  IPAD_OS_17 = 'iPadOS 17',
  IPAD_OS_16 = 'iPadOS 16',
  IPAD_OS_15 = 'iPadOS 15',
  TV_OS_17 = 'tvOS 17',
  TV_OS_16 = 'tvOS 16',
  TV_OS_15 = 'tvOS 15',
  DEFAULT = '',
}

export function isManagedOSAppName(
  name: string | ManagedOSNameShort,
): name is ManagedOSNameShort {
  return Object.values(ManagedOSNameShort).includes(name as ManagedOSNameShort);
}

export enum RSREnforcementKeys {
  DO_NOT_ENFORCE,
  AUTOMATICALY,
}

export enum FieldsToValidate {
  ENFORCEMENT_DEADLINE = 'enforcementDeadline',
  ENFORCEMENT_TIMEZONE = 'enforcementTimezone',
  VERSION_ENFORCEMENT = 'versionEnforcement',
}

export interface VersionDate {
  [version: string]: number;
}

export interface VersionDateAutoApps {
  version: string;
  build: string;
  version_display?: string;
  date?: number;
}

export interface VersionAutoAppsOption {
  value: VersionDateAutoApps;
  label: string;
}

export interface RawVersionDateRsr {
  os_version: string;
  release_date: string;
  extra_version: string;
}

export interface VersionDateRsr {
  osVersion: string;
  releaseDate: number;
  extraVersion: string;
}

export interface RawUpdates {
  _version_dates?: Record<string, unknown>;
  _version_dates_auto_apps?: VersionDateAutoApps[];
  _rsr_version_dates?: RawVersionDateRsr[];
  enforcement_delay?: number;
  version_enforcement?: string;
  minimum_app_version?: string;
  minimum_auto_app_version?: VersionDateAutoApps;
  enforce_after?: string | number | Date;
  enforcement_timezone?: string;
  enforcement_time?: string;
  rsr_enforcement?: number;
  rsr_enforcement_delay?: string;
  rsr_enforcement_time?: string;
}

export interface SelectOption {
  value: string | number | boolean;
  label: string;
}

export interface UpdatesSetting {
  versionEnforcement: SelectOption;
  minimumVersion: SelectOption;
  minimumAutoAppVersion: VersionAutoAppsOption;
  versionOptions?: SelectOption[];
  versionAutoAppOptions: VersionAutoAppsOption[];
  versionsAutoApps: VersionDateAutoApps[];
  enforcementDelay: SelectOption;
  enforcementDeadline?: Date;
  enforcementTime: SelectOption;
  enforcementTimezone?: SelectOption;
  rsrEnforcement?: SelectOption;
  rsrEnforcementDelay?: SelectOption;
  rsrEnforcementTime?: SelectOption;
  rsrVersions?: VersionDateRsr[];
  versions: VersionDate;
  invalidationsMap?: {
    enforcementTimezone?: {
      isInvalid: boolean;
    };
  };
}

export interface UpdatesCardProps {
  type?: string;
  name?: string;
  setting: UpdatesSetting;
  isDisabled?: boolean;
  update?: (key: string, value: unknown) => void;
  selectedTimezone: SelectOption;
  version?: string;
  appName?: ManagedOSNameShort | string;
  installationType?: string;
  installationUpdateOnly?: boolean;
  validationDep?: number;
  isDevelopmentInstance?: boolean;
  fall2023NewManagedOSLibraryItems?: boolean;
  fall2023RSREnforcement?: boolean;
}
