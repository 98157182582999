/* istanbul ignore file */

/**
 * Maps known Restrictions categories to their human readable variant.
 */
export const getRestrictionCategories = () => {
  const categories = {
    apps: 'Apps',
    blackandwhitelist: 'Manage App Restrictions',
    autonomoussingleappmode: 'Autonomous Single App Mode',
    passcodeandauthentication: 'Passcode & Authentication',
    lockscreen: 'Lock Screen',
    accountsandpasswords: 'Accounts & Passwords',
    datasegregation: 'Data Segregation',
    icloud: 'iCloud',
    network: 'Network',
    cellular: 'Cellular',
    airdrop: 'AirDrop',
    airplay: 'AirPlay',
    airprint: 'AirPrint',
    applewatch: 'Apple Watch',
    diagnostics: 'Diagnostics',
    files: 'Files',
    findmy: 'Find My',
    gamecenter: 'Game Center',
    keyboard: 'Keyboard',
    media: 'Media',
    osupdates: 'OS Updates',
    safari: 'Safari',
    siri: 'Siri',
    osandgeneral: 'OS & General',
    sharing: 'Sharing',
    appleIntelligence: 'Apple Intelligence',
  };

  return categories;
};
