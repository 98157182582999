import React from 'react';
import apple from './assets/apple.png';
import wifi from './assets/wifi.png';

export default function Header(props) {
  const { setting } = props;
  return (
    <>
      <img
        style={{ display: 'none' }}
        draggable="false"
        src={apple}
        alt="apple"
        className="k-klog-apple"
      />
      <div className="k-klog-pheader">
        <div className="k-klog-pheader-inner">
          <img
            hidden={!setting.isNetworkManager}
            draggable="false"
            src={wifi}
            alt="network"
          />
          <p>
            8<span className="k-klog-gg">:</span>39 AM
          </p>
        </div>
      </div>
    </>
  );
}
