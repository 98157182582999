import featureFlags from 'src/config/feature-flags';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import FirewallService from './firewall-service';

function transformSettings(settings) {
  const transformedSettings = {
    general: {
      EnableFirewall: settings?.EnableFirewall || false,
      canBlockAllIncoming: Boolean(settings?.BlockAllIncoming !== undefined),
      BlockAllIncoming: settings?.BlockAllIncoming || false,
      canEnableStealthMode: Boolean(settings?.EnableStealthMode !== undefined),
      EnableStealthMode: settings?.EnableStealthMode || false,
    },
    logging: {
      EnableFirewallLogging: settings?.EnableFirewallLogging || false,
      EnableDetailedFirewallLogging:
        settings?.EnableDetailedFirewallLogging || false,
    },
  };

  if (featureFlags.getFlag('lit_05162022_manage-pf-firewall')) {
    transformedSettings.managePf = {
      is_managing_pf_firewall: settings.is_managing_pf_firewall,
      ...FirewallService.managePfOptions.reduce(
        (acc, val) => ({
          ...acc,
          [val.serverField]:
            settings[val.serverField] !== undefined
              ? !settings[val.serverField]
              : val.defaultValue, // invert the value because we're using the inverse in the UI
        }),
        {},
      ),
    };
  }

  return transformedSettings;
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    data: {
      EnableFirewall: model.general.EnableFirewall,
    },
  };

  if (model.general.EnableFirewall) {
    if (model.general.canBlockAllIncoming) {
      toSend.data.BlockAllIncoming = model.general.BlockAllIncoming;
    }
    if (model.general.canEnableStealthMode) {
      toSend.data.EnableStealthMode = model.general.EnableStealthMode;
    }

    // Only add the Logging options if they are set to true:
    if (model.logging.EnableFirewallLogging) {
      toSend.data.EnableFirewallLogging = true;
    }
    if (model.logging.EnableDetailedFirewallLogging) {
      toSend.data.EnableDetailedFirewallLogging = true;
    }
  }

  if (featureFlags.getFlag('lit_05162022_manage-pf-firewall')) {
    toSend.data = {
      ...toSend.data,
      is_managing_pf_firewall: model.managePf.is_managing_pf_firewall,
      ...FirewallService.managePfOptions.reduce(
        (acc, val) => ({
          ...acc,
          [val.serverField]: model.managePf.is_managing_pf_firewall
            ? !model.managePf[val.serverField] // invert the value because we're using the inverse in the UI
            : null,
        }),
        {},
      ),
    };
  }

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.Firewall;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
