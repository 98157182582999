import {
  Banner,
  updateSetting,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import React, { useEffect } from 'react';
import BackgroundItemModal from '../background-item-modal';
import Empty from './background-items-empty';
import BackgroundItemsList from './background-items-list';

function BackgroundItems(props) {
  const { model, setModel, pageState } = props;
  const { isDisabled, isSubmitted } = pageState;
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    ['items'],
    updateSetting('general', setModel),
  );

  useEffect(() => {
    onInvalidate('items')(!model.items?.length);
  }, [model]);

  return (
    <>
      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">Background Items</h3>
        </Setting.Header>
        <Setting.SubHeader>
          <p ref={refs.items} className="b-txt">
            Specify login items to prevent them from being able to be disabled
            by users in System Settings in macOS Ventura and later. They will
            also be identified as being managed by your organization. Items can
            be specified by bundle identifier, bundle identifier prefix, label,
            label prefix, or team identifier.{' '}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000578621"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              Learn more
            </a>
            {invalidations.items && isSubmitted && (
              <Banner className="b-mt1" theme="error">
                <span data-testid="bg-error">
                  You must specify a background item.
                </span>
              </Banner>
            )}
          </p>
        </Setting.SubHeader>
        {model.items.length > 0 ? (
          <BackgroundItemsList
            isDisabled={isDisabled}
            items={model.items}
            setModel={setModel}
          />
        ) : (
          <Empty setModel={setModel} isDisabled={isDisabled} />
        )}
      </Setting.Card>
      <BackgroundItemModal model={model} setModel={setModel} />
    </>
  );
}

export default BackgroundItems;
