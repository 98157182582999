import { Toaster as toaster } from '@kandji-inc/bumblebee';
import type { AxiosPromise } from 'axios';
import type { DependencyList } from 'react';
import { useEffect, useReducer } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

type ServiceStateType = {
  isLoading: boolean;
  response: any; // Not sure if we can consistently predict the structure, unless we create types for each endpoint
  error: any; // @TODO populate with a standard error format once we have established one from the BE
  offsets: {
    // `offset` search param number values parsed from full `previous` and
    // `next` endpoints in API response, otherwise `null` if endpoint does not
    // support pagination
    previous: number | null;
    next: number | null;
  } | null;
};

const INITIAL_STATE = {
  isLoading: false,
  response: null,
  error: null,
  offsets: {
    previous: null,
    next: null,
  },
};

const ACTIONS = {
  START_REQUEST: 'startRequest',
  FINISH_REQUEST: 'finishRequest',
  CAUGHT_ERROR: 'caughtError',
};

function getIsVulnTableResponse(url: string) {
  const { pathname } = new URL(url);

  const match = matchPath(pathname, {
    path: [
      '*/vuln/v1/vulnerabilities',
      '*/vuln/v1/vulnerabilities/:vulnId/devices',
      '*/vuln/v1/vulnerabilities/:vulnId/software',
      '*/vuln/v1/vulnerabilities/:vulnId/timeline',
      '*/vuln/v1/vulnerabilities/devices/:deviceId',
    ],
    exact: true,
    strict: false,
  });

  return !!match;
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.START_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTIONS.FINISH_REQUEST: {
      const {
        payload: { data, config },
      } = action;

      const isVulnTableResponse = getIsVulnTableResponse(config.url);

      if (isVulnTableResponse) {
        let intermediatePrevOffset;

        if (!data || typeof data.count !== 'number') {
          intermediatePrevOffset = 0;
        } else {
          intermediatePrevOffset = data?.previous
            ? Number(
                new URLSearchParams(new URL(data.previous).search).get(
                  'offset',
                ),
              )
            : null;
        }

        const previous =
          intermediatePrevOffset !== null && intermediatePrevOffset > data.count
            ? 0
            : intermediatePrevOffset;

        const next = data?.next
          ? Number(new URLSearchParams(new URL(data.next).search).get('offset'))
          : null;

        return {
          ...state,
          isLoading: false,
          error: null,
          response: data,
          offsets: { previous, next },
        };
      }

      return {
        ...state,
        isLoading: false,
        error: null,
        response: data,
        offsets: null,
      };
    }

    case ACTIONS.CAUGHT_ERROR: {
      toaster(action.payload.message);

      return {
        ...state,
        isLoading: false,
        response: null,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Performs a passed API call and returns loading, error and response state
 *
 * @example
 *  import useVulnerabilityService from 'src/features/vulnerability/old/hooks/use-vulnerability-service';
 *  ...
 *  const {
 *    isLoading,
 *    response,
 *    error,
 *    offsets // null if response does not support pagination
 *  } = useVulnerabilityService(() => vulnerabilityService.getCve(id));
 */
export default function useVulnerabilityService(
  apiCall: (
    currParams?: Record<string, string> | null,
  ) => AxiosPromise<Record<string, unknown>>,
  dependencies: DependencyList = [],
): ServiceStateType {
  const [serviceState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const history = useHistory<{ from?: 'deviceVulnerabilityTooltip' }>();

  useEffect(() => {
    let isEffectUnmounted = false;

    async function performApiCall() {
      dispatch({ type: ACTIONS.START_REQUEST });

      try {
        const response = await apiCall().catch((e) => Promise.reject(e));

        if (!isEffectUnmounted) {
          dispatch({
            type: ACTIONS.FINISH_REQUEST,
            payload: response,
          });
        }
      } catch (e) {
        if (!isEffectUnmounted) {
          dispatch({
            type: ACTIONS.CAUGHT_ERROR,
            payload: {
              message: 'A server error occurred, please try again.',
            },
          });
        }
      }
    }

    performApiCall();

    return () => {
      isEffectUnmounted = true;
    };
  }, [...dependencies]);

  useEffect(() => {
    let isEffectUnmounted = false;
    const unlisten = history.listen((location, action) => {
      if (!isEffectUnmounted) {
        const isPushFromVulnTooltip =
          location.state?.from === 'deviceVulnerabilityTooltip' &&
          action === 'PUSH';
        if (isPushFromVulnTooltip) {
          dispatch({ type: ACTIONS.START_REQUEST });
          const currParams = location.search
            ? Object.fromEntries(new URLSearchParams(location.search).entries())
            : null;

          apiCall(currParams)
            .then((response) => {
              if (!isEffectUnmounted) {
                dispatch({
                  type: ACTIONS.FINISH_REQUEST,
                  payload: response,
                });
              }
            })
            .catch(() => {
              dispatch({
                type: ACTIONS.CAUGHT_ERROR,
                payload: {
                  message: 'A server error occurred, please try again.',
                },
              });
            });
        }
      }
    });

    return () => {
      isEffectUnmounted = true;
      unlisten();
    };
  }, []);

  return serviceState;
}
