import { Button } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { setModal } from 'src/app/_actions/ui';
import { links } from 'src/app/common/constants';
import styled from 'styled-components';
import { cancelLostMode } from '../../api';
import LocationPoint from './assets/LocationPoint';

const ErroredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  .errored-lost-mode__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    background: #fcf2f2;
    border-radius: 50%;
    svg {
      path {
        fill: #c30000;
      }
    }
  }

  .errored-lost-mode__header {
    font-weight: bold;
    font-size: 16px;
    padding: 14px 0 14px 0;
  }

  .errored-lost-mode__description {
    max-width: 440px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-family:
      Atlas Grotesk Web,
      sans-serif;
    font-weight: 400;

    a {
      color: #0057da;
    }
  }
  .errored-lost-mode__try-again-btn {
    margin-bottom: 16px;
  }
`;

function ErroredLostModeTab(props) {
  const { computer, setSnackbar } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeoutId, setTimeoutId] = useState('');
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => () => window.clearTimeout(timeoutId), [timeoutId]);

  const handleCancel = (e) => {
    e.preventDefault();
    cancelLostMode(computer.id)
      .then(() => {
        setSnackbar('Disable Lost Mode command sent');
        setIsButtonDisabled(true);
        const id = window.setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
        setTimeoutId(id);
      })
      .catch((error) => {
        setSnackbar(error.message);
      });
  };

  const handleTryAgain = (e) => {
    e.preventDefault();
    dispatch(
      setModal('ENABLE_LOST_MODE', {
        computerId: computer?.id,
        isNewModal: true,
      }),
    );
  };

  return (
    <ErroredContainer>
      <div className="errored-lost-mode__icon">
        <LocationPoint data-testid="location-point" />
      </div>
      <div className="errored-lost-mode__header">
        Lost Mode could not be activated
      </div>
      <p className="errored-lost-mode__description">
        There was an issue activating Lost Mode on {computer.name}. Please check
        the{' '}
        <Link to={`${links.devices}/${match.params.id}/activity`}>
          Activity{' '}
        </Link>
        tab for more details.
      </p>
      <Button
        className="errored-lost-mode__try-again-btn"
        onClick={handleTryAgain}
        icon="arrow-rotate-right"
      >
        Try Again
      </Button>

      <Button onClick={handleCancel} disabled={isButtonDisabled} kind="link">
        Cancel
      </Button>
    </ErroredContainer>
  );
}

export default ErroredLostModeTab;
