import { camelCase } from 'lodash';
import featureFlags from '../../../config/feature-flags';

const isThreatAlertFFEnabled = featureFlags.getFlag(
  'paint-threat-alert-notification',
);

type EventTrigger = {
  name: string;
  toggleName: string;
  isDisabled?: boolean;
};

type NotificationKeys = keyof typeof NOTIFICATION_MAP;

const EVENT_ACTION_NAME_MAPPING = {
  remediations: 'Remediations',
  device_missing: 'Device goes offline',
  device_found: 'Device no longer offline',
  new_mac_enrolled: 'New device enrolled',
  mac_checked_out: 'MDM profile removed',
  new_mac_first_run_summary: 'New Mac first run summary',
  application_blocked: 'An application is blocked',
  threat_alert_detected: 'Threat detected on device',
};

const NOTIFICATION_MAP = {
  remediations: 'remediations',
  deviceMissing: 'device_missing',
  deviceFound: 'device_found',
  newMacEnrolled: 'new_mac_enrolled',
  macCheckedOut: 'mac_checked_out',
  newMacFirstRunSummary: 'new_mac_first_run_summary',
  applicationBlocked: 'application_blocked',
  threatAlertDetected: 'threat_alert_detected',
};

export const eventTriggers: EventTrigger[] = [
  {
    name: EVENT_ACTION_NAME_MAPPING.remediations,
    toggleName: 'remediations',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.device_missing,
    toggleName: 'deviceMissing',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.device_found,
    toggleName: 'deviceFound',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.mac_checked_out,
    toggleName: 'macCheckedOut',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.new_mac_enrolled,
    toggleName: 'newMacEnrolled',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.new_mac_first_run_summary,
    toggleName: 'newMacFirstRunSummary',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.application_blocked,
    toggleName: 'applicationBlocked',
  },
  {
    name: EVENT_ACTION_NAME_MAPPING.threat_alert_detected,
    toggleName: 'threatAlertDetected',
    isDisabled: !isThreatAlertFFEnabled,
  },
];

/**
 * Transform notification object to snake_case array.
 * @param {object} notifications Notifications Object.
 * @returns {String[]}  Returns an array of snake_case strings
 */
function mapNotificationsToSnakeCase(
  notifications: Record<NotificationKeys, boolean>,
) {
  // filter out the keys that are true
  const activeKeys = Object.keys(notifications).filter(
    (key) => notifications[key as keyof typeof notifications],
  );

  // map those keys to the format that the backend is expecting
  const mappedKeys = activeKeys.map(
    (key) => NOTIFICATION_MAP[key as NotificationKeys],
  );

  return mappedKeys;
}

/**
 * Transform notification array to camelCase array.
 * @param {object[]} notifications Notifications Array.
 * @returns {String[]}  Returns an array of snake_case strings
 */
function mapNotificationsToCamelCase(
  notifications: Array<keyof typeof EVENT_ACTION_NAME_MAPPING>,
) {
  if (!notifications) {
    return [];
  }
  return notifications?.map((notification) => camelCase(notification));
}

// This is a helper that transforms the eventTriggers array into an object of just "toggleName" boolean statuses to be used for setting initial state where needed.
export const initialNotificationToggleState = eventTriggers.reduce(
  (accum, { toggleName, isDisabled }) => {
    if (isDisabled) {
      return accum;
    }
    return {
      ...accum,
      // we want to set initial values to false
      [toggleName]: false,
    };
  },
  {},
);

export {
  mapNotificationsToSnakeCase,
  mapNotificationsToCamelCase,
  EVENT_ACTION_NAME_MAPPING,
};
