/* istanbul ignore file */

import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import {
  InstallationCard,
  useSsInstall,
} from 'features/library-items/template';

import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithoutDefaultsPreset from '../../common/self-service-card/drawer/presets/without-defaults-preset';
import defaultIcon from './cp-library.png';
import GeneralCard from './general-card';
import initialState from './initial-state';
import PrinterFileCard from './printer-file-card';
import CustomPrinterService, {
  customPrinterService,
} from './service/custom-printer-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useCustomPrinterService from './service/use-custom-printer-service';

const installationOptions = [
  {
    label: 'Install printer once per device',
    value: CustomPrinterService.installationTypes.INSTALL_ONCE,
  },
  {
    label: 'Install printer and continuously enforce',
    value: CustomPrinterService.installationTypes.CONTINUOUSLY_ENFORCE,
  },
  {
    label: 'Install on-demand from Self Service',
    value: CustomPrinterService.installationTypes.NO_ENFORCEMENT,
  },
];

const CustomPrinterPage = (props) => {
  const { model, setModel, pageState, handlers } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const updateInstallation = useCallback(update('installation', setModel), []);
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateGeneral = useCallback(update('general', setModel), []);
  const updateFile = useCallback(update('printerFile', setModel), []);

  const isSsForced = useSsInstall({
    installType: model.installation.type,
    ssType: CustomPrinterService.installationTypes.NO_ENFORCEMENT,
    updateSs,
  });

  return (
    <LibraryItemPage
      {...props}
      crumb={model.name || 'Custom Printer'}
      defaultIcon={defaultIcon}
      summaryInfoProps={{
        name: 'Custom Printer',
        description:
          'Configure custom printers and printer drivers to be installed using the Kandji Agent. Custom printers can be continously enforced, installed once, or offered through Self Service.',
        publisher: 'Apple, Inc.',
        devices: ['Mac'],
        requirements: ['macOS 10.11+'],
      }}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      askFlush={() =>
        model.installation.type ===
        CustomPrinterService.installationTypes.INSTALL_ONCE
      }
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={customPrinterService}
      isIconEditable
      supportsRules
      supportsDuplication
    >
      <InstallationCard
        setting={model.installation}
        update={updateInstallation}
        isDisabled={pageState.isDisabled}
        options={installationOptions}
      />
      <SelfServiceCard
        setting={model.selfService}
        update={updateSs}
        isDisabled={pageState.isDisabled}
        defaults={{
          icon: model.iconSrc || defaultIcon,
          name: model.name,
        }}
        DrawerContent={WithoutDefaultsPreset}
        canBeDisabled={!isSsForced}
        isSubmitted={pageState.isSubmitted}
      />
      <GeneralCard
        setting={model.general}
        update={updateGeneral}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
        onValidate={handlers.onValidate}
      />
      <PrinterFileCard
        setting={model.printerFile}
        update={updateFile}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
        onValidate={handlers.onValidate}
      />
    </LibraryItemPage>
  );
};

const CustomPrinter = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useCustomPrinterService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <CustomPrinterPage {...pageProps} />;
};

export default CustomPrinter;
