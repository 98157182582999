import type { SortingState } from '@tanstack/react-table';
import * as React from 'react';
import { ExportButton } from 'src/features/visibility/shared/components/export';
import { getSortString } from '../../../contexts/utils/sortUtils';
import { getDisplayName } from '../../../implemented-category-uris';
import type {
  ExportPrismCategoryRequestSchema,
  PrismCategoryUri,
} from '../../../types/prism.types';

interface PrismExportProps {
  blueprintIds: string[] | undefined;
  deviceFamilies: string[] | undefined;
  category: PrismCategoryUri | undefined;
  filters: Record<string, unknown> | undefined;
  sorting: SortingState;
  columnVisibility: {
    [key: string]: boolean;
  };
}

export const PrismExport = ({
  blueprintIds,
  deviceFamilies,
  category,
  filters,
  sorting,
  columnVisibility,
}: PrismExportProps) => {
  const [exportType, setExportType] = React.useState('current_view');
  const filterEntry = filters;
  // istanbul ignore next
  const filter = React.useMemo(() => filterEntry || {}, [filterEntry]);

  return (
    <ExportButton
      title={`Export ${getDisplayName(category ?? 'device_information')} to CSV`}
      exportTypeOptions={[
        { type: 'current_view', label: 'Current View' },
        { type: 'all_attributes', label: 'All Attributes' },
      ]}
      helpText={getHelpText(exportType)}
      onExport={() =>
        handleExportPayload({
          exportType,
          sorting,
          columnVisibility,
          blueprintIds,
          deviceFamilies,
          category,
          filter,
        })
      }
      exportType={exportType}
      setExportType={setExportType}
      exportDataType="prism"
    />
  );
};

export const handleExportPayload = ({
  exportType,
  sorting,
  columnVisibility,
  blueprintIds,
  deviceFamilies,
  category,
  filter,
}: {
  exportType: string;
  sorting: SortingState;
  columnVisibility: {
    [key: string]: boolean;
  };
  blueprintIds: string[] | undefined;
  deviceFamilies: string[] | undefined;
  category: PrismCategoryUri | undefined;
  filter: Record<string, unknown> | undefined;
}) => {
  const sort_by = getSortString(sorting);
  const payload: ExportPrismCategoryRequestSchema = {
    blueprint_ids: blueprintIds ?? [],
    device_families: deviceFamilies ?? [],
    category: category ?? 'device_information',
    filter,
    sort_by,
  };

  if (exportType !== 'all_attributes') {
    payload.columns = Object.entries(columnVisibility)
      .filter(([_, value]) => value === true)
      .map(([key, _]) => key);
  }

  return payload;
};

export const getHelpText = (exportType: string) => {
  if (exportType === 'current_view') {
    return (
      <>
        CSV will <strong>only</strong> include data that is currently visible in
        the table, and contain any applied filters.
      </>
    );
  }
  return (
    <>
      CSV will include <strong>all</strong> data for this category, including
      data that is not currently visible in the table.
    </>
  );
};
