import { Banner, Checkbox, Chip, Slider } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { Setting } from 'features/library-items/template';

const RecoveryKeys = ({ isDisabled, recoveryKeys, update }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">Recovery Keys</h3>
    </Setting.Header>

    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label="Show user the FileVault recovery key when it is generated"
            id="show_recovery_key"
            checked={recoveryKeys.ShowRecoveryKey}
            onChange={() => update('ShowRecoveryKey', (checked) => !checked)}
            disabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            By default, the FileVault recovery key will be shown to the end user
            when the recovery key is created or regenerated. A common security
            practice is to not show the recovery key to the end user and allow
            team members to view the escrowed recovery key in Kandji.
          </p>
        </Setting.Helpers>
      </Setting.Row>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label="Escrow recovery keys to Kandji"
            checked={recoveryKeys.EscrowRecoveryKey}
            onChange={() => update('EscrowRecoveryKey', (checked) => !checked)}
            disabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            This option will send the recovery key to Kandji where it can be
            viewed by team members.
          </p>
          <Banner theme="info" kind="block">
            <p>
              If FileVault is currently enabled, this option will cause the
              Kandji agent to prompt the user for authentication before
              regenerating the recovery key.
            </p>
          </Banner>
        </Setting.Helpers>
      </Setting.Row>
      {recoveryKeys.EscrowRecoveryKey && (
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Automatically rotate keys"
              checked={recoveryKeys.autoRotateKeys}
              onChange={() => update('autoRotateKeys', (checked) => !checked)}
              disabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically rotate the recovery key on a regular schedule.
            </p>
          </Setting.Helpers>
          {recoveryKeys.autoRotateKeys && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <p className="b-txt-light">
                    Rotate keys after they are escrowed to Kandji in{' '}
                    <Chip
                      style={{ display: 'inline' }}
                      text={`${recoveryKeys.AutomaticallyRotateKeysAfter} days`}
                    />
                  </p>
                  <Slider
                    className="mb-4"
                    value={recoveryKeys.AutomaticallyRotateKeysAfter}
                    onChange={(value) =>
                      update('AutomaticallyRotateKeysAfter', value)
                    }
                    disabled={isDisabled}
                    min={1}
                    max={365}
                    getMarkerLabel={(val) => `${val} days`}
                    markedValues={[1, 182, 365]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      )}
    </Setting.Rows>
  </Setting.Card>
);

RecoveryKeys.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  recoveryKeys: PropTypes.shape({
    ShowRecoveryKey: PropTypes.bool.isRequired,
    EscrowRecoveryKey: PropTypes.bool.isRequired,
    autoRotateKeys: PropTypes.bool.isRequired,
    AutomaticallyRotateKeysAfter: PropTypes.number.isRequired,
  }).isRequired,
};

export default RecoveryKeys;
