import { z } from 'zod';

const integrationRedirectUrlSchema = z.object({
  redirect_url: z.string().url(),
});

const integrationGroupsResult = z.object({
  external_id: z.string(),
  id: z.string().uuid(),
  integration_id: z.number(),
  integration_name: z.string(),
  integration_type: z.string(),
  name: z.string(),
});

const integrationUserGroupListSchema = z.object({
  results: z.array(integrationGroupsResult),
});

const integrationDetailsSchema = z.object({
  tenant_id: z.string().uuid(),
  integration_id: z.string().uuid(),
  ms_domain_name: z.string().nullable(),
  ms_tenant_name: z.string().nullable(),
  ms_tenant_id: z.string().uuid().nullable(),
  ios_policy_id: z.string().uuid().nullable(),
  ios_entra_group_ids: z.array(z.string().uuid()).nullable(),
  macos_policy_id: z.string().uuid().nullable(),
  macos_entra_group_ids: z.array(z.string().uuid()).nullable(),
});

const compliancePoliciesSchema = z.object({
  mac_os_devices_enabled: z.boolean(),
  ios_devices_enabled: z.boolean(),
  mac_os_group_ids: z.array(z.string()),
  ios_group_ids: z.array(z.string()),
});

export {
  integrationRedirectUrlSchema,
  integrationUserGroupListSchema,
  integrationDetailsSchema,
  compliancePoliciesSchema,
};

export type IntegrationGroupsResult = z.infer<typeof integrationGroupsResult>;
export type IntegrationDetails = z.infer<typeof integrationDetailsSchema>;
export type CompliancePolicies = z.infer<typeof compliancePoliciesSchema>;
