import { formatDate } from './_helpers';

export default (activity) => [
  {
    label: 'Edited by',
    values: [activity.actor_name],
  },
  {
    label: 'Edited at',
    values: [formatDate(activity)],
  },
  {
    label: 'Old name',
    values: [activity.data.old_label],
  },
  {
    label: 'New name',
    values: [activity.data.label],
  },
];
