import { useReducer } from 'react';
import { oktaDeviceTrustService } from '../services/okta-device-trust-service';
import type {
  DevicePlatformRequestBody,
  DevicePlatformResponse,
} from '../types';

type ODTState = {
  data: DevicePlatformResponse | null;
  error: string | null;
  isLoading: boolean;
};

type ODTAction =
  | {
      type: typeof ODT_ACTIONS.START_REQUEST;
    }
  | {
      type: typeof ODT_ACTIONS.FINISH_REQUEST;
      payload: DevicePlatformResponse;
    }
  | {
      type: typeof ODT_ACTIONS.CAUGHT_ERROR;
      payload: string;
    };

const ODT_ACTIONS = {
  START_REQUEST: 'startRequest',
  FINISH_REQUEST: 'finishRequest',
  CAUGHT_ERROR: 'caughtError',
} as const;

const INTITIAL_ODT_STATE = {
  data: null,
  error: null,
  isLoading: false,
} as const;

function oktaDeviceTrustReducer(state: ODTState, action: ODTAction) {
  switch (action.type) {
    case ODT_ACTIONS.START_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ODT_ACTIONS.FINISH_REQUEST:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    // istanbul ignore next
    case ODT_ACTIONS.CAUGHT_ERROR:
      return {
        ...state,
        data: null,
        error: action.payload || 'Something went wrong.',
        isLoading: false,
      };
    // istanbul ignore next
    default:
      return state;
  }
}

const useOktaDeviceTrustPlatform = () => {
  const [odtState, dispatch] = useReducer(
    oktaDeviceTrustReducer,
    INTITIAL_ODT_STATE,
  );
  // istanbul ignore next
  const createDevicePlatform = (body: DevicePlatformRequestBody) => {
    dispatch({
      type: ODT_ACTIONS.START_REQUEST,
    });
    return (
      oktaDeviceTrustService
        .createDevicePlatform(body)
        .then((response) => {
          if (response?.data) {
            dispatch({
              type: ODT_ACTIONS.FINISH_REQUEST,
              payload: response.data,
            });
          }
          return Promise.resolve(response?.data);
        })

        // TODO: test when the banner is setup

        .catch((err: Error) => {
          if (err?.message) {
            dispatch({
              type: ODT_ACTIONS.CAUGHT_ERROR,
              payload: err.message,
            });
          }

          return Promise.reject(err.message);
        })
    );
  };

  return {
    ...odtState,
    createDevicePlatform,
  };
};

export default useOktaDeviceTrustPlatform;
