import { Banner, Chip, Radio } from '@kandji-inc/bumblebee';
import { Box } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

import { devices } from 'features/library-items/data-service/library-item/devices';
import { Setting } from 'features/library-items/template';

import SetComputerName from '../dnd/SetComputerName';
import VariablePreview from '../dnd/VariablePreview';
import DeviceNameService from '../service/device-name-service';

const Hostname = (props: any) => {
  const {
    update,
    isDisabled,
    setting,
    deviceNameSetting,
    devices: runsOnDevices,
  } = props;

  const isMacExcluded = !runsOnDevices?.find(
    ({ value }) => value === devices.MAC,
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Hostname</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          The hostname is how a device is identified on the network and is only
          customizable for Mac computers. iOS, iPadOS, and tvOS automatically
          generate hostnames. Devices with Private Address enabled will not
          display a hostname on the network.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000560507-setting-macos-and-ios-device-names"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more
          </a>
        </p>
        {isMacExcluded && (
          <Banner
            kind="block"
            theme={isDisabled ? 'info' : 'warning'}
            icon={isDisabled && 'circle-info'}
            className="b-mt-micro"
            style={{ padding: '8px' }}
          >
            <span>
              Hostname for macOS will not be managed or customized because Mac
              was excluded in the device family assignment for this Library
              item.
            </span>
          </Banner>
        )}
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt-bold">
              Select how the hostname should be managed for macOS
            </p>
          </Setting.Title>
          <Setting.Controls>
            <Box>
              <Box>
                <Radio
                  label="Use device name pattern for hostname"
                  checked={setting.useDeviceNamePattern}
                  onChange={() => {
                    update('useDeviceNamePattern', (p) => !p);
                    update(
                      'variables',
                      DeviceNameService.convertDeviceNameToHostName(
                        deviceNameSetting.variables,
                      ),
                    );
                  }}
                  disabled={isMacExcluded || isDisabled}
                />
              </Box>
              <Radio
                label="Customize hostname"
                checked={!setting.useDeviceNamePattern}
                onChange={() => update('useDeviceNamePattern', (p) => !p)}
                disabled={isMacExcluded || isDisabled}
              />
            </Box>
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Choose to have the hostname match the device name pattern
              specified above, or customize the hostname for Mac computers.
            </p>
            <div className="b-flex-vc b-flex-g1">
              <Chip kind="tertiary-light" text="Mac 10.13+" />
            </div>
          </Setting.Helpers>
          {setting.useDeviceNamePattern && !isMacExcluded && (
            <Box css={{ gridColumn: '1 / -1', marginTop: '16px' }}>
              <VariablePreview
                kind="hostname"
                selected={setting.variables}
                onDevices={[{ device: devices.MAC }]}
                title="Hostname"
                previewLabel="Hostname"
              />
            </Box>
          )}
        </Setting.Row>
        {!setting.useDeviceNamePattern && (
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt-bold">Set a hostname for macOS</p>
              <p className="b-txt">
                Drag variables into the hostname field to create the desired
                pattern. Additionally, you can add custom text between
                variables.
              </p>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Hostnames can only contain letters, numbers and hyphens. On
                macOS spaces are automatically replaced with hyphens. Apple
                allows for 63 character hostnames, but some networks limit total
                characters to 31.
              </p>
            </Setting.Helpers>
            <Box css={{ gridColumn: '1 / -1' }}>
              <SetComputerName
                kind="hostname"
                title="Hostname"
                previewLabel="Hostname"
                onDevices={[{ device: devices.MAC, disabled: false }]}
                selectedVariables={setting.variables}
                setSelectedVariables={(vars) => update('variables', vars)}
                isDisabled={isDisabled || isMacExcluded}
              />
            </Box>
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Hostname;
