import React from 'react';
import ApplicationBlacklisting from './parameter-components/application-blacklist';
import CheckLibraryFolder from './parameter-components/check-library-folder';
import CreateUserAccounts from './parameter-components/create-user-accounts';
import CustomPolicyBanner from './parameter-components/custom-policy-banner';
import DemoteUsers from './parameter-components/demote-users';
import EnforceCustomMessage from './parameter-components/enforce-custom-message';
import Exclusions from './parameter-components/exclusions';
import HotCorner from './parameter-components/hot-corner';
import ManageSSHConfig from './parameter-components/manage-ssh-config';
import MultiToggle from './parameter-components/multi-toggle';
import RetentionSecurityAuditing from './parameter-components/retention-security-auditing';
import SecurityAuditingFlags from './parameter-components/security-auditing-flags';
import SetComputerName from './parameter-components/set-computer-name';
import SingleInput from './parameter-components/single-input';
import SingleNumberInput from './parameter-components/single-number-input';
import SingleSelect from './parameter-components/single-select';
import SingleToggle from './parameter-components/single-toggle';
import TimeMachineStatus from './parameter-components/time-machine-status';
import UsersAndGroupsRestriction from './parameter-components/user-and-groups-restrict';

const parametersWithAlerts = {
  '1636e014-eee6-4858-b508-24594a4b521d': true,
  'e5decad7-7cbf-49b4-99b0-d7e94a21fa47': true,
  '135b1c65-a665-431b-b919-39e984d6d29d': true,
  'e9c1d35c-55f1-4ab9-9081-2865091b1fb2': true,
  '93384921-61aa-46f6-a7d9-d45908babccc': true,
  'c248f070-6bce-42b1-9688-27d9bf4c1513': true,
};

const parameterComponents = {
  'bb40515e-4b28-48fc-9596-59c8db14a7a3': CreateUserAccounts,
  'ccdeac19-fd75-4ce5-a8e6-b3f6b38ffae1': (props) => (
    <EnforceCustomMessage
      {...props}
      validationsKey="ccdeac19-fd75-4ce5-a8e6-b3f6b38ffae1"
    />
  ),
  '6b9fc543-0e05-4abc-8ddf-0bc22936b98c': (props) => (
    <EnforceCustomMessage
      {...props}
      validationsKey="6b9fc543-0e05-4abc-8ddf-0bc22936b98c"
    />
  ),
  '3d81acd3-8d3d-4941-a88e-6b8b9147cc6a': HotCorner,
  '51a763db-716c-4bfa-b721-2f303d203b3b': (props) => (
    <SingleSelect
      options={[
        { label: 'Enable location services', value: '1' },
        { label: 'Disable location services', value: '0' },
      ]}
      defaultValue={{ label: 'Enable location services', value: '1' }}
      {...props}
    />
  ),
  '07cac17b-33b0-4af3-8302-3e5ded562b45': (props) => (
    <SingleSelect
      options={[
        { label: 'Enabled', value: '1' },
        { label: 'Disabled', value: '0' },
      ]}
      defaultValue={{ label: 'Enabled', value: '1' }}
      {...props}
    />
  ),
  '08323d29-7296-4fc1-8a74-295b3ac2b081': (props) => (
    <SingleSelect
      options={[
        { label: 'Show', value: '1' },
        { label: 'Hide', value: '0' },
      ]}
      defaultValue={{ label: 'Show', value: '1' }}
      {...props}
    />
  ),
  '6e82a850-901a-47ec-9b4e-03393af029c9': (props) => (
    <SingleNumberInput
      max={99999}
      min={1}
      defaultValue={365}
      label="Days"
      validationKey="6e82a850-901a-47ec-9b4e-03393af029c9"
      {...props}
    />
  ),
  'd011e3a4-eebe-4af2-adf8-aa41c11efacf': (props) => (
    <SingleInput
      defaultValue="time.apple.com"
      label="Enter time server"
      {...props}
    />
  ),
  '93335d6f-3007-4648-a3e0-7e081bdccbfd': (props) => (
    <SingleSelect
      options={[
        { label: 'Contacts only', value: '0' },
        { label: 'Everyone', value: '1' },
        { label: 'No one', value: '2' },
      ]}
      defaultValue={{ label: 'Contacts only', value: '0' }}
      {...props}
    />
  ),
  'ec0a818c-42ef-4755-a423-72ed2580838b': (props) => (
    <MultiToggle
      label="Require administrator authorization to"
      toggles={[
        [
          {
            key: 'computer_to_computer',
            label: 'Create computer-to-computer networks',
            defaultValue: false,
          },
        ],
        [
          {
            key: 'network_change',
            label: 'Change networks',
            defaultValue: false,
          },
        ],
        [
          {
            key: 'power_toggle',
            label: 'Turn wi-fi on or off',
            defaultValue: false,
          },
        ],
      ]}
      {...props}
    />
  ),
  '4aa511a0-be7d-4819-9c1d-0c781d3bde97': (props) => (
    <SingleSelect
      options={[
        { label: 'Disable wi-fi', value: '0' },
        { label: 'Enable wi-fi', value: '1' },
      ]}
      defaultValue={{ label: 'Disable wi-fi', value: '0' }}
      {...props}
    />
  ),
  '5d78eebc-8552-4f9a-8236-44b9e7a4bff2': (props) => (
    <SingleSelect
      options={[
        { label: 'Disable only if no device paired', value: '0' },
        { label: 'Disabled', value: '1' },
        { label: 'Enabled', value: '2' },
      ]}
      defaultValue={{ label: 'Disable only if no device paired', value: '0' }}
      {...props}
    />
  ),
  '7dc0b0ea-26a5-4427-91f5-582c140b3480': (props) => (
    <SingleSelect
      options={[
        { label: 'Disable waking with bluetooth', value: '0' },
        { label: 'Enable waking with bluetooth', value: '1' },
      ]}
      defaultValue={{ label: 'Disable waking with bluetooth', value: '0' }}
      {...props}
    />
  ),
  '3c25d52b-f6fd-4619-9d60-5db1ef78223a': (props) => (
    <SingleSelect
      options={[
        { label: 'Do not open setup assistant', value: '0' },
        { label: 'Open setup assistant', value: '1' },
      ]}
      defaultValue={{ label: 'Do not open setup assistant', value: '0' }}
      {...props}
    />
  ),
  'c4f7759f-af3f-487e-9546-ab5360846b05': (props) => (
    <SingleSelect
      options={[
        { label: 'Do not open setup assistant', value: '0' },
        { label: 'Open setup assistant', value: '1' },
      ]}
      defaultValue={{ label: 'Do not open setup assistant', value: '0' }}
      {...props}
    />
  ),
  'a1674edc-f788-424d-addc-dea91f3053f3': (props) => (
    <SingleNumberInput
      max={99999}
      min={1}
      defaultValue={30}
      label="Days until forced restart"
      validationKey="a1674edc-f788-424d-addc-dea91f3053f3"
      {...props}
    />
  ),
  '8c6b6864-3faa-47f1-b599-e13bb04682ed': (props) => (
    <SingleSelect
      options={[
        { label: 'Prompt for each website', value: '0' },
        { label: 'Deny without prompting', value: '1' },
      ]}
      defaultValue={{ label: 'Prompt for each website', value: '0' }}
      {...props}
    />
  ),
  '4911ed60-3c2d-40ba-a5ca-33306f1d1245': (props) => (
    <SingleNumberInput
      max={99999}
      min={1}
      defaultValue={15}
      label="Minutes until standby"
      validationKey="4911ed60-3c2d-40ba-a5ca-33306f1d1245"
      {...props}
    />
  ),
  'e9c1d35c-55f1-4ab9-9081-2865091b1fb2': TimeMachineStatus,
  '93384921-61aa-46f6-a7d9-d45908babccc': (props) => (
    <SingleToggle
      defaultValue={false}
      label="Alert if Time Machine backups are not enabled"
      {...props}
    />
  ),
  '65f7aef7-797d-438e-8537-086772f9b22d': DemoteUsers,
  '776f1c86-54ea-4876-a226-a0a07040fec0': CustomPolicyBanner,
  '2107e444-9421-4ec8-a7d0-67ac23ed555c': CheckLibraryFolder,
  '648b04c9-8d63-40f3-81b0-167a515864f7': (props) => (
    <Exclusions
      buttonLabel="Add directory exclusion"
      inputLabel="Full paths of excluded directories"
      inputPlaceholder="/Library/Excluded Directory/"
      validators={(v) => [
        {
          message: 'Required',
          invalid: () => !v,
          trigger: ['onBlur', 'onInput'],
        },
        {
          message: 'Path must end with a /',
          invalid: () => v[v.length - 1] !== '/',
          trigger: ['onBlur', 'onInput'],
        },
      ]}
      {...props}
    />
  ),
  'cbb3b9c5-2db4-476a-9faa-0c61b7354ec1': RetentionSecurityAuditing,
  'c4215248-df85-4af8-ada1-5afc305a7447': (props) => (
    <SingleNumberInput
      min={2}
      max={2048}
      defaultValue={80}
      label="Please, select a value (MB)"
      validationKey="c4215248-df85-4af8-ada1-5afc305a7447"
      {...props}
    />
  ),
  '8447697d-c47b-4508-968f-d503a2b6fb9f': (props) => (
    <MultiToggle
      label="Check on services to block with PF firewall"
      toggles={[
        [
          {
            key: 'afp_packets_blocked',
            label: 'AFP',
          },
          {
            key: 'bonjour_packets_blocked',
            label: 'Bonjour',
          },
        ],
        [
          {
            key: 'finger_packets_blocked',
            label: 'Finger',
          },
          {
            key: 'ftp_packets_blocked',
            label: 'FTP',
          },
        ],
        [
          {
            key: 'http_packets_blocked',
            label: 'HTTP',
          },
          {
            key: 'icmp_packets_blocked',
            label: 'ICMP',
          },
        ],
        [
          {
            key: 'imap_packets_blocked',
            label: 'IMAP',
          },
          {
            key: 'imaps_packets_blocked',
            label: 'IMAPS',
          },
        ],
        [
          {
            key: 'itunes_packets_blocked',
            label: 'iTunes sharing',
          },
          {
            key: 'mdnsresponder_packets_blocked',
            label: 'mDNSResponder',
          },
        ],
        [
          {
            key: 'nfs_packets_blocked',
            label: 'NFS',
          },
          {
            key: 'optical_packets_blocked',
            label: 'Optical drive sharing',
          },
        ],
        [
          {
            key: 'pop_packets_blocked',
            label: 'POP',
          },
          {
            key: 'pops_packets_blocked',
            label: 'POPS',
          },
        ],
        [
          {
            key: 'printer_packets_blocked',
            label: 'Printer sharing',
          },
          {
            key: 'screensharing_packets_blocked',
            label: 'Screen sharing',
          },
        ],
        [
          {
            key: 'smb_packets_blocked',
            label: 'SMB',
          },
          {
            key: 'smtp_packets_blocked',
            label: 'SMTP',
          },
        ],
        [
          {
            key: 'ssh_packets_blocked',
            label: 'SSH',
          },
          {
            key: 'telnet_packets_blocked',
            label: 'Telnet',
          },
        ],
        [
          {
            key: 'tftp_packets_blocked',
            label: 'TFTP',
          },
          {
            key: 'uucp_packets_blocked',
            label: 'UUCP',
          },
        ],
      ]}
      {...props}
    />
  ),
  '91e86fb9-1c25-45b0-b1ed-064f8a478409': SecurityAuditingFlags,
  '428d99a1-5ab0-4198-b248-7603d1c2e258': (props) => (
    <UsersAndGroupsRestriction
      disableOptionLabel="Disable screen sharing"
      {...props}
    />
  ),
  'fb63a562-062d-4679-9e16-3dd2453e726d': (props) => (
    <UsersAndGroupsRestriction
      disableOptionLabel="Disable remote Apple events"
      {...props}
    />
  ),
  '3a192a9a-3452-4357-8674-685b767eb116': ManageSSHConfig,
  '1bf63aac-9033-49b4-93f1-0ac857afb8a8': ApplicationBlacklisting,
  '1d11235c-707f-4f26-b6ca-a27f20f3ee83': SetComputerName,
};

const BP_PARAMS_VALIDATION_FIELDS = {
  SingleInput: 'SingleInput',

  /* SingleNumberInput */
  '6e82a850-901a-47ec-9b4e-03393af029c9':
    '6e82a850-901a-47ec-9b4e-03393af029c9',
  'a1674edc-f788-424d-addc-dea91f3053f3':
    'a1674edc-f788-424d-addc-dea91f3053f3',
  '4911ed60-3c2d-40ba-a5ca-33306f1d1245':
    '4911ed60-3c2d-40ba-a5ca-33306f1d1245',
  'c4215248-df85-4af8-ada1-5afc305a7447':
    'c4215248-df85-4af8-ada1-5afc305a7447',

  CreateUserAccounts: 'CreateUserAccounts',
  CreateUserAccounts_shortname: 'CreateUserAccounts_shortname',
  CreateUserAccounts_password: 'CreateUserAccounts_password',
  CreateUserAccounts_repeatPassword: 'CreateUserAccounts_repeatPassword',

  /* EnforceCustomMessage */
  'ccdeac19-fd75-4ce5-a8e6-b3f6b38ffae1':
    'ccdeac19-fd75-4ce5-a8e6-b3f6b38ffae1',
  '6b9fc543-0e05-4abc-8ddf-0bc22936b98c':
    '6b9fc543-0e05-4abc-8ddf-0bc22936b98c',

  TimeMachineStatus: 'TimeMachineStatus',

  DemoteUsers: 'DemoteUsers',
  DemoteUsers_exclusion: 'DemoteUsers_exclusion',

  CustomPolicyBanner_bannerText: 'CustomPolicyBanner_bannerText',
  CustomPolicyBanner_bannerFile: 'CustomPolicyBanner_bannerFile',

  CheckLibraryFolder: 'CheckLibraryFolder',

  RetentionSecurityAuditing_days: 'RetentionSecurityAuditing_days',
  RetentionSecurityAuditing_size: 'RetentionSecurityAuditing_size',

  SecurityAuditingFlags: 'SecurityAuditingFlags',

  ManageSSHConfig_loginGracePeriod: 'ManageSSHConfig_loginGracePeriod',
  ManageSSHConfig_maxAuthTries: 'ManageSSHConfig_maxAuthTries',
  ManageSSHConfig_clientTimeout: 'ManageSSHConfig_clientTimeout',

  ApplicationBlacklisting: 'ApplicationBlacklisting',
  ApplicationBlacklisting_byProcess: 'ApplicationBlacklisting_byProcess',
  ApplicationBlacklisting_byPath: 'ApplicationBlacklisting_byPath',
  ApplicationBlacklisting_byDeveloperId:
    'ApplicationBlacklisting_byDeveloperId',
  ApplicationBlacklisting_byBundleId: 'ApplicationBlacklisting_byBundleId',
  ApplicationBlacklisting_customizationMessage:
    'ApplicationBlacklisting_customizationMessage',
  ApplicationBlacklisting_customizationTitle:
    'ApplicationBlacklisting_customizationTitle',
  ApplicationBlacklisting_customizationUrl:
    'ApplicationBlacklisting_customizationUrl',

  SetComputerName: 'SetComputerName',

  UsersAndGroupsRestriction_users: 'UsersAndGroupsRestriction_users',
  UsersAndGroupsRestriction_groups: 'UsersAndGroupsRestriction_groups',
  UsersAndGroupsRestriction_userInput: 'UsersAndGroupsRestriction_userInput',
  UsersAndGroupsRestriction_groupInput: 'UsersAndGroupsRestriction_groupInput',

  Exclusions: 'Exclusions',
  Exclusions_excludedDirectory: 'Exclusions_excludedDirectory',
};

export {
  parametersWithAlerts,
  parameterComponents,
  BP_PARAMS_VALIDATION_FIELDS,
};
