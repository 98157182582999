import {
  Button,
  sidePanel as SidePanel,
  setClass,
  useShadowOnScroll,
} from '@kandji-inc/bumblebee';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';

const Drawer = ({
  isVisible,
  setIsVisible,
  setting,
  update,
  defaults,
  DrawerContent,
  size,
  title,
  externalFields = [],
  onInvalidate,
  validationDep,
  ...restProps
}) => {
  const [originalSetting, setOriginalSetting] = useState();
  const [needReset, setNeedReset] = useState(0);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const { isShowHeaderShadow, setBody } = useShadowOnScroll();

  const handleCancel = () => {
    Object.keys(originalSetting).forEach((key) => {
      update(key, originalSetting[key]);
    });
    setNeedReset((p) => p + 1);

    setIsVisible(false);
  };

  return (
    <SidePanel
      isVisible={isVisible}
      onOpen={() => setOriginalSetting(cloneDeep(setting))}
      size={size}
    >
      <div
        className="b-side-panel-layout"
        id="drawer"
        data-testid="wifi-drawer"
      >
        <div
          className={setClass(
            'b-side-panel-layout__header',
            isShowHeaderShadow && '--with-shadow',
          )}
        >
          <h2 className="b-h2">{title}</h2>
        </div>
        <div
          className="b-side-panel-layout__body --k-ss2 hubspot-buffer-bottom"
          ref={setBody}
          style={{ paddingTop: 0 }}
        >
          <DrawerContent
            update={update}
            defaults={defaults}
            setting={setting}
            needReset={needReset}
            triggerValidate={triggerValidation}
            onInvalidate={onInvalidate}
            isVisible={isVisible}
            validationDep={validationDep}
            {...restProps}
          />
        </div>
        <div className="b-side-panel-layout__footer">
          <div className="b-flex-justify-end">
            <div className="b-grid-ctas">
              <Button kind="outline" onClick={handleCancel}>
                Close
              </Button>
              <Button
                onClick={() => {
                  // this fields are not in drawer. don't need validate the here
                  const invalids = Object.entries(
                    setting.invalidationsMap || {},
                  )
                    .filter(([, value]) => value?.isInvalid)
                    .filter(([key]) => !externalFields.includes(key))
                    .map(([, value]) => value);

                  if (!invalids.length) {
                    setIsVisible(false);
                  } else {
                    setTriggerValidation((prev) => !prev);
                    const withRef = invalids.find((d) => d.ref);
                    withRef?.ref.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    });
                  }
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default Drawer;
