import { Box, Tooltip } from '@kandji-inc/nectar-ui';
import TableHeaderIconButton from '../../../prism/components/PrismTable/components/TableHeaderIconButton';

export const ColumnEditorButton = ({
  onToggleColumnEditor,
}: {
  onToggleColumnEditor: () => void;
}) => {
  return (
    <Box onClick={onToggleColumnEditor}>
      <Tooltip content="Edit columns" side="top">
        <TableHeaderIconButton
          icon="table-columns"
          onClick={onToggleColumnEditor}
          aria-label="Edit columns"
          role="button"
        />
      </Tooltip>
    </Box>
  );
};
