import { Button, Flex } from '@kandji-inc/nectar-ui';
import type { ReactNode } from 'react';
import SearchFilter from '../common/components/CommonFilters/SearchFilter';
import ThreatListExportButton from '../common/components/ThreatListExportButton';
import ViewSelectorFilter from '../common/components/ThreatListFilters/ViewSelectorFilter';
import type {
  OnApply,
  OnClear,
  OnClearAll,
  ViewSelectorState,
} from '../threat.types';

type ThreatListFiltersProps = {
  query?: string | undefined;
  view: ViewSelectorState | undefined;
  onApply: OnApply;
  onClear: OnClear;
  onClearAll: OnClearAll;
  isFiltering: boolean;
  isExportHidden: boolean;
  onExport: () => void;
  children: ReactNode;
  isExportDisabled?: boolean;
};

const ThreatListFilters = (props: ThreatListFiltersProps) => {
  const {
    query,
    view,
    onApply,
    onClear,
    onClearAll,
    isFiltering,
    isExportHidden,
    onExport,
    children,
    isExportDisabled = false,
  } = props;

  return (
    <Flex flow="column" gap="sm" py2>
      <Flex flow="row" gap="sm" justifyContent="space-between">
        <SearchFilter
          name="query"
          value={query}
          onChange={onApply}
          onClear={onClear}
        />
        {/* Will be reanabled when "view by devices" is reintroduced */}
        {/* <ViewSelectorFilter value={view} /> */}
      </Flex>
      <Flex flow="row" gap="sm" justifyContent="space-between">
        <Flex flow="row" gap="sm">
          {children}
          {isFiltering && (
            <Button variant="subtle" compact onClick={onClearAll}>
              Clear all
            </Button>
          )}
        </Flex>
        {!isExportHidden && (
          <ThreatListExportButton
            onClick={onExport}
            disabled={isExportDisabled}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ThreatListFilters;
