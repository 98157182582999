import axios from 'axios';
import _cond from 'lodash/cond';
import _stubTrue from 'lodash/stubTrue';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './enrollmentAuthentication.css';

import { Button } from '@kandji-inc/bumblebee';
import { LineLoader } from 'app/components/interface/LineLoader';
import HubSpotHandler from 'src/components/common/hubspot-handler';
import { auth0EnrollmentRedirectUri } from 'src/config';
import { injectPendo } from 'src/config/pendo';
import KandjiLogo from '../../assets/img/new_icons/kandji_logo_stacked_dark.svg';
import { useAuth0 } from '../../auth0';

const AuthTrackingEvents = {
  LOGIN_WITH_REDIRECT: '[ADE Auth] Login With Redirect',
  RETRIEVE_EMAIL_FAIL: '[ADE Auth] Failed to retrieve email',
  RETRIEVE_EMAIL_SUCCESS: '[ADE Auth] Retrieved user email',
  SYNC_USER_DEVICE_FAIL: '[ADE Auth] Failed to synce user device',
  SYNC_USER_DEVICE_SUCCESS: '[ADE Auth] Sync user device successful',
  LOGIN_SUCCESS: '[ADE Auth] User login successful',
  AUTH0_CLIENT_ERROR: '[ADE Auth] Auth0 client error',
};

function logErrorMessage(err) {
  if (err && err.message) {
    console.log(err.message);
  }
}

function getEnvFromUrl(url) {
  const urlArray = url.split('.');
  return urlArray.find((item) => ['stage', 'dev'].includes(item)) || 'prod';
}

const syncUserDevice = ({ url, email }) =>
  axios.post(
    url,
    {
      email,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

const isSuccessful = ({ isAuthenticated, isLoading }) =>
  isAuthenticated && !isLoading;
const isFail = ({ isAuthenticated, isLoading, redirect }) =>
  !isAuthenticated && !isLoading && !redirect;
const isError = ({ error }) => error;
const isCredentialsError = ({ auth0ClientError }) => auth0ClientError;
const missingCallback = ({ isAuthenticated, callbackUrl }) =>
  isAuthenticated && !callbackUrl;

const successMessages = () => ({
  message: 'Authentication was successful',
  info: 'Redirect in progress...',
});
const failMessages = () => ({
  message: 'Failed to authenticate',
  info: 'There was a problem authenticating your user account. If the problem persists, you will need to reach out to your IT department.',
});
const errorMessages = () => ({
  message: 'Unable to verify your account',
  info: 'There was a problem authenticating your user account. If the problem persists, you will need to reach out to your IT department.',
});

const getMessaging = _cond([
  [isSuccessful, successMessages],
  [isError, errorMessages],
  [isCredentialsError, errorMessages],
  [missingCallback, errorMessages],
  [isFail, failMessages],
  [_stubTrue, () => ({})],
]);

const AgentEnrollmentAuthentication = () => {
  const {
    clientId,
    clientDomain,
    callbackUrl,
    configureUrl,
    enablePendo,
    metadata,
    error: auth0ClientError,
  } = useSelector((state) => state.auth0);
  const gotAuth0Creds = !!clientId;
  const {
    error,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  const [redirect, setRedirect] = useState(false);
  const redirectUri = `${auth0EnrollmentRedirectUri}?metadata=${metadata}`;
  const tempDeviceId = useMemo(
    () => callbackUrl?.substring(callbackUrl?.lastIndexOf('/') + 1),
    [callbackUrl],
  );
  const tenant = useMemo(() => clientDomain?.split('.')[0], [clientDomain]);

  const authTrackingAnalytics = useMemo(
    () => ({
      clientId,
    }),
    [clientId],
  );

  const trackAuthEvent = useCallback(
    async (eventType, data) => {
      if (!enablePendo) {
        return;
      }

      pendo?.track(eventType, data);
      await pendo?.flushNow();
    },
    [enablePendo],
  );

  if (auth0ClientError) {
    trackAuthEvent(AuthTrackingEvents.AUTH0_CLIENT_ERROR, {
      error: auth0ClientError,
      ...authTrackingAnalytics,
    });
  }

  // send user to auth0, user will be redirected here
  useEffect(() => {
    if (clientId && !isLoading && !isAuthenticated && !error) {
      setRedirect(true);

      trackAuthEvent(
        AuthTrackingEvents.LOGIN_WITH_REDIRECT,
        authTrackingAnalytics,
      );

      loginWithRedirect({
        redirectUri,
      });
    }
  }, [
    clientId,
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    redirectUri,
    error,
    authTrackingAnalytics,
    trackAuthEvent,
  ]);

  // on successful auth, redirect to callback with user email
  useEffect(() => {
    async function tryGetEmail() {
      try {
        const { email = '' } = await getIdTokenClaims();

        trackAuthEvent(AuthTrackingEvents.RETRIEVE_EMAIL_SUCCESS, {
          email,
          ...authTrackingAnalytics,
        });

        return email;
      } catch (err) {
        trackAuthEvent(AuthTrackingEvents.RETRIEVE_EMAIL_FAIL, {
          error: err?.message,
          ...authTrackingAnalytics,
        });

        logErrorMessage(err);
        return '';
      }
    }

    async function trySyncUserDevice(email) {
      try {
        await syncUserDevice({ url: configureUrl, email });
        trackAuthEvent(
          AuthTrackingEvents.SYNC_USER_DEVICE_SUCCESS,
          authTrackingAnalytics,
        );
      } catch (err) {
        trackAuthEvent(AuthTrackingEvents.SYNC_USER_DEVICE_FAIL, {
          error: err?.message,
          ...authTrackingAnalytics,
        });

        logErrorMessage(err);
      }
    }

    async function redirectWithEmail() {
      const email = await tryGetEmail();
      await trySyncUserDevice(email);

      trackAuthEvent(AuthTrackingEvents.LOGIN_SUCCESS, {
        email,
        ...authTrackingAnalytics,
      });

      const url = new URL(callbackUrl);
      url.searchParams.append('email', email);

      window.location.replace(url.toString());
    }

    if (isAuthenticated && callbackUrl) {
      redirectWithEmail();
    }
  }, [
    isAuthenticated,
    callbackUrl,
    configureUrl,
    getIdTokenClaims,
    authTrackingAnalytics,
    trackAuthEvent,
  ]);

  // ui messaging
  const { message = '', info = '' } = getMessaging({
    isAuthenticated,
    isLoading,
    error,
    callbackUrl,
    auth0ClientError,
    redirect,
  });

  const className = 'enrollment-authentication';

  useEffect(() => {
    document.body.classList.add(className);
    if (enablePendo && tenant != null) {
      injectPendo(false);
      const env = getEnvFromUrl(clientDomain);
      pendo.initialize({
        visitor: { id: tempDeviceId, type: 'device' },
        account: { id: `${env}_${tenant}`, name: tenant },
      });
    }
    return () => document.body.classList.remove(className);
  }, [clientDomain, enablePendo, tempDeviceId, tenant]);

  return (
    <div className="enrollment-container">
      <HubSpotHandler modifiers={['hide']} />
      <img src={KandjiLogo} width="175" alt="Kandji App" />
      <div className="enrollment-content">
        <h2 className="enrollment-msg">{message}</h2>
        <div className="enrollment-info">{info}</div>
        {/* Initial mount - redirecting to auth0 */}
        {redirect && <LineLoader />}
        {/* Unsuccessful authentication - allow user to retry */}
        {!isLoading && !isAuthenticated && !redirect && (
          <div className="enrollment-btn">
            <Button
              onClick={() =>
                loginWithRedirect({
                  redirectUri,
                })
              }
              disabled={!gotAuth0Creds}
            >
              {error ? 'Try Again' : 'Continue Enrollment'}
            </Button>
          </div>
        )}
        <div className="enrollment-footer">
          If you have any questions, please contact your IT department.
        </div>
      </div>
    </div>
  );
};

export default AgentEnrollmentAuthentication;
