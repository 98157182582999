/* istanbul ignore file */
import { v4 } from 'uuid';
import { getSelectedDevicesFromRunsOn } from '../../data-service/library-item/devices';
import { getRestrictionKeysWithV1Defaults } from './config/keys';
import RestrictionsService from './service/restrictions-service';

export default () => ({
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  supportsRules: true,
  devices: getSelectedDevicesFromRunsOn({
    runs_on_mac: true,
    runs_on_iphone: true,
    runs_on_ipad: true,
    runs_on_tv: true,
  }),

  // FE only
  filters: {
    restrictionType: 'all',
    isShowEnabledOnly: false,
    deviceType: 'all',
    searchTerm: '',
  },

  // TODO: Use this if we ever get to V2 key defaults
  // restrictions: getRestrictionKeys(),

  restrictions: getRestrictionKeysWithV1Defaults(),

  // FE helper fields as to not touch main restrictions object
  _helpers: {
    invalidationsMap: {},
    isManageAppRestrictions: false,
    appRestrictionsOption: RestrictionsService.appRestrictionsOptions[0].value,
    appRestrictionsValues: [
      {
        _id: v4(),
        id: '',
        name: '',
      },
    ],
  },
});
