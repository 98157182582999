import { useDraggable, useDroppable } from '@dnd-kit/core';
import { Box, Button, Flex } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useMemo } from 'react';
import CustomChip from './CustomChip';
import { deviceVariables } from './common';

const DraggableChip = (props: any) => {
  const { value, zone, itemId, label, canDrag, disabled } = props;
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${zone}_${value}`,
    data: { value, zone, itemId },
    disabled,
  });

  const baseStyle = {
    userSelect: 'none',
  };

  return (
    <CustomChip
      canDrag={canDrag}
      disabled={disabled}
      label={label}
      ref={setNodeRef}
      css={baseStyle}
      {...listeners}
      {...attributes}
    />
  );
};

const VariableBank = (props: any) => {
  const { id, selected, onAddCustom, isDisabled, kind } = props;
  const { setNodeRef } = useDroppable({
    id,
  });

  const isHostname = kind === 'hostname';
  const nonMacOnlyVariables = useMemo(
    () => deviceVariables.filter((v) => isHostname || !v.isMacOnly),
    [isHostname],
  );
  const macOnlyVariables = useMemo(
    () => deviceVariables.filter((v) => !isHostname && v.isMacOnly),
    [isHostname],
  );

  return (
    <Box>
      <p className="b-mb1">Device variables</p>
      <Flex ref={setNodeRef} flow="column" gap="sm">
        {nonMacOnlyVariables.map((deviceVar) => {
          const isSelected = !!selected.find(
            (v) => v.value === deviceVar.value,
          );
          return (
            <DraggableChip
              key={deviceVar.value}
              label={deviceVar.name}
              value={deviceVar.value}
              itemId={deviceVar.value}
              zone={id}
              disabled={isSelected || isDisabled}
              canDrag={isSelected}
            />
          );
        })}
        <Box>
          <Button
            compact
            variant="subtle"
            onClick={onAddCustom}
            icon={{ name: 'circle-plus', position: 'left' }}
            css={{ color: '$blue50' }}
            disabled={isDisabled}
          >
            Add custom text
          </Button>
        </Box>
        {macOnlyVariables.length > 0 && (
          <>
            <p className="b-mb-micro">macOS Only variables</p>
            {macOnlyVariables.map((deviceVar) => {
              const isSelected = !!selected.find(
                (v) => v.value === deviceVar.value,
              );
              return (
                <DraggableChip
                  key={deviceVar.value}
                  label={deviceVar.name}
                  value={deviceVar.value}
                  itemId={deviceVar.value}
                  zone={id}
                  disabled={isSelected || isDisabled}
                  canDrag={isSelected}
                />
              );
            })}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default VariableBank;
