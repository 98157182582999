import { Button, DialogPrimitives as Dialog } from '@kandji-inc/nectar-ui';

export type ExportConfirmNavModalProps = {
  onClose: () => void;
  onLeave: () => void;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const ExportConfirmNavModal = ({
  onClose,
  onLeave,
  isOpen,
  onOpenChange,
}: ExportConfirmNavModalProps) => (
  <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
    <Dialog.Content
      hideClose
      onOpenAutoFocus={(e: Event) => e.preventDefault()}
      css={{
        width: '480px',
        height: 'auto',
      }}
    >
      <Dialog.Header>
        <Dialog.Title>Export is currently in progress</Dialog.Title>
      </Dialog.Header>
      <Dialog.Description as="div">
        <p>
          The file download will be canceled if you navigate away from this
          page.{' '}
        </p>
        <p>Are you sure you want to leave?</p>
      </Dialog.Description>
      <Dialog.Footer>
        <Button variant="primary" compact onClick={onClose}>
          Stay on this page
        </Button>
        <Button compact onClick={onLeave}>
          Cancel export and leave
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Root>
);

export default ExportConfirmNavModal;
