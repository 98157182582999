import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { Button, modal as Modal } from '@kandji-inc/bumblebee';

type Props = {
  isSaving: boolean;
  isOpen: boolean;
  onClose: () => Dispatch<SetStateAction<boolean>>;
  onConfirm: () => Dispatch<SetStateAction<boolean>>;
};

function WarningModal(props: Props) {
  const { isSaving, isOpen, onClose, onConfirm } = props;

  const parts = {
    header: {
      text: 'Are you sure you want to make changes to Library Item assignment?',
    },
    content: {
      children: (
        <p>
          This Library Item may be installed, uninstalled, or no longer enforced
          on devices based on the changes made.
        </p>
      ),
    },
    footer: {
      children: (
        <>
          <Button kind="outline" onClick={onClose} isDisabled={isSaving}>
            Back
          </Button>

          <Button className="b-ml" iconPlacement="right" onClick={onConfirm}>
            Yes, make changes
          </Button>
        </>
      ),
    },
  };

  return (
    <Modal
      classes={{ root: 'k-warning-modal' }}
      isOpen={isOpen}
      onClose={onClose}
      parts={parts}
      disableCloseOnOutsideClick
      withoutCloseButton
    />
  );
}

export default WarningModal;
