import { ProfileTypes, colors, links } from 'app/common/constants';
import { getCustomAppInstallStatusesForComputer } from 'app/components/computer/api';
import { withPermissions } from 'contexts/account';
import { inclusionLvl } from 'contexts/environment';
import profileApps from 'features/library-items/library/library-item-configurations/items/profile';
import { startCase } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators } from 'redux';
import {
  apiTypes,
  categories,
} from 'src/features/library-items/library/common';
import HoverTippy from 'src/features/util/components/hover-tippy';
import { getDevelopmentEnvironmentName } from 'src/util';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import NoDataToDisplay from '../../../../assets/img/no_data_to_display.png';
import NoParametersEnabled from '../../../../assets/img/toggle_unhappy.png';
import RulesIcon from '../../../../features/library-items/library/library-list/assets/icon_rules.svg';
import {
  getBlueprintParams as callGetBlueprintParams,
  startGetBlueprint as callStartGetBlueprint,
  updateBlueprint as callUpdateBlueprint,
} from '../../../_actions/blueprint';
import { updateComputer as callUpdateComputer } from '../../../_actions/computer';
import { setEditor as callSetEditor } from '../../../_actions/editor';
import {
  setTabsScroll as callSetTabsScroll,
  setTabsStatus as callSetTabsStatus,
  queryParamHistory,
} from '../../../_actions/parameters';
import { setResultsFilters as callSetResultsFilters } from '../../../_actions/results';
import {
  setModal as callSetModal,
  setSnackbar as callSetSnackbar,
} from '../../../_actions/ui';
import history from '../../../router/history';
import { BootstrapTable, TableHeaderColumn } from '../../common/BootstrapTable';
import TableNoDataHelper from '../../common/TableNoDataHelper';
import {
  formatTime,
  getHelperTextForStatus,
  getStatusCircleIconClass,
  getStatusColorClass,
} from '../../common/helpers';
import { linkEvent } from '../../good/util';
import AwesomeDropdown from '../../interface/AwesomeDropdown';
import { HoveredSpan } from '../../interface/Base';
import { LineLoader } from '../../interface/LineLoader';
import { getLibraryItemStatusesForComputer } from '../../library/api';
import { getCustomScriptRunStatusesForComputer } from '../../library/custom-scripts/api';
import {
  Status,
  StatusLabel,
} from '../../library/libraryItemStatusTab/LibraryItemStatusTab.styles';
import {
  libraryItemLog,
  profileLog,
  standardLog,
  vppAppLog,
} from '../../library/log';
/* istanbul ignore file */
import './status-tab-styles.scss';

const typeToLink = {
  [apiTypes.IPA_APP]: links.ipaApps,
  [apiTypes.VPP_APP]: links.vppApps,
  [apiTypes.AUTO_APP]: links.automaticApps,
  [apiTypes.MACOS_RELEASE]: links.operatingSystems,
  [apiTypes.IOS_RELEASE]: links.iOSReleases,
  [apiTypes.IPADOS_RELEASE]: links.iPadOSReleases,
  [apiTypes.TVOS_RELEASE]: links.tvOSReleases,
  [apiTypes.CUSTOM_PRINTER]: links.customPrinters,
  [apiTypes.CUSTOM_SCRIPT]: links.customScripts,
  [apiTypes.CUSTOM_APP]: links.customApps,
  [apiTypes.THREAT_SECURITY_POLICY]: links.avert,
  [apiTypes.DEVICE_CONTROL]: links.deviceControl,
};

const getLink = (row) =>
  // generate link to library item page based on type field
  `${typeToLink[row.type]}/${row.library_item_id}`;
const moment = require('moment');

const TempPadding = styled('div')`
  height: 15px;
`;

const TempPaddingBetweenTables = styled('div')`
  height: 50px;
`;

const CategoryName = styled.h2`
  font-size: var(--font-headings-m-size);
  line-height: var(--font-headings-m-line-height);
  font-weight: var(--font-headings-m-weight);
  letter-spacing: var(--font-headings-m-letter-spacing);
  font-family: var(--font-family-primary);
  font-style: normal;
  color: var(--color-neutral-100);
  padding: 0;
  margin: 0;
`;

class StatusTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      params: [],
      libraryItemStatuses: [],
    };
    this.next = null;
    this.fetchParams = this.fetchParams.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchCustomAppInstallStatuses =
      this.fetchCustomAppInstallStatuses.bind(this);
    this.fetchCustomScriptRunStatuses =
      this.fetchCustomScriptRunStatuses.bind(this);
    this.fetchLibraryItemStatuses = this.fetchLibraryItemStatuses.bind(this);
    this.annotateParameterResults = this.annotateParameterResults.bind(this);
    this.formatParameterActions = this.formatParameterActions.bind(this);
    this.renderNoParametersView = this.renderNoParametersView.bind(this);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { computer } = next;

    if (computer.blueprint_id !== this.props.computer.blueprint_id) {
      this.setState({ isLoading: true });
      this.props.getBlueprintParams(computer.blueprint_id).then(() =>
        this.setState({ isLoading: false }, () => {
          this.fetchParams(true);
          this.fetchLibraryItemStatuses();
        }),
      );
    }
  }

  componentDidMount() {
    const { getBlueprintParams, computer, blueprints = [] } = this.props;
    if (blueprints.find((bp) => bp.id === computer.blueprint_id)) {
      // if computer has a valid blueprint
      getBlueprintParams(computer.blueprint_id).then(() =>
        this.setState({ isLoading: false }, () => {
          if (!computer.deferred_install) {
            this.fetchData();
          } else {
            this.renderEmptyParamsList.bind(this)();
          }
        }),
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  fetchData = () => {
    const { computer } = this.props;
    switch (computer.device_family) {
      case 'iPhone':
      case 'iPod':
      case 'iPad':
      case 'AppleTV':
        this.fetchLibraryItemStatuses();
        break;
      case 'Mac':
        this.fetchParams(true);
        this.fetchLibraryItemStatuses();
        break;
      default:
        break;
    }
  };

  getRequestQuery() {
    const options = {};
    const { computer } = this.props;
    options.parameterid = null;
    options.computerid = computer.id;
    options.blueprintid = computer.blueprint_id;
    options.displayType = 'LAST_STATE';
    options.ordering = '-ended_at';
    options.for_device = true;
    return options;
  }

  annotateParameterResults = (results) => {
    const { parameterMeta, blueprintNames } = this.props;
    const totalResults = results || [];

    return totalResults.map((item) => {
      const { run, parameter_id, parameter_name } = item;
      const momentObj = moment(run);
      const paramObject = parameterMeta[parameter_id];
      const nameVerbose =
        parameter_name || get(paramObject, 'name') || 'No name';

      return {
        ...item,
        runVerbose: run ? formatTime(run) : 'Not Yet Run',
        runTimestamp: momentObj.unix(),
        nameVerbose,
        blueprintName: blueprintNames[item.blueprint_id],
        enrollmentDate: item.first_for_enrollment
          ? formatTime(item.first_for_enrollment.enrolled_at)
          : null,
      };
    });
  };

  fetchCustomAppInstallStatuses() {
    const { isLoading } = this.state;
    const { computer, setSnackbar } = this.props;

    if (isLoading) {
      return null;
    }
    this.setState({ isLoading: true });

    return getCustomAppInstallStatusesForComputer(computer.id)
      .then((customAppInstallStatuses) =>
        this.setState({
          customAppInstallStatuses,
          isLoading: false,
        }),
      )
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        setSnackbar('An error occurred while loading records');
      });
  }

  fetchCustomScriptRunStatuses() {
    const { isLoading } = this.state;
    const { computer, setSnackbar } = this.props;

    if (isLoading) {
      return null;
    }
    this.setState({ isLoading: true });

    return getCustomScriptRunStatusesForComputer(computer.id)
      .then((customScriptRunStatuses) =>
        this.setState({
          customScriptRunStatuses,
          isLoading: false,
        }),
      )
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        setSnackbar('An error occurred while loading records');
      });
  }

  fetchLibraryItemStatuses() {
    const { isLoading } = this.state;
    const { computer, setSnackbar } = this.props;

    if (isLoading) {
      return null;
    }
    this.setState({ isLoading: true });

    return getLibraryItemStatusesForComputer(computer.id)
      .then((libraryItemStatuses) =>
        this.setState({
          libraryItemStatuses,
          isLoading: false,
        }),
      )
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        setSnackbar('An error occurred while loading records');
      });
  }

  fetchParams(clearPrev) {
    const { blueprintParams, setResultsFilters, setTabsStatus, setSnackbar } =
      this.props;
    const { isLoading, params } = this.state;

    if (isLoading) {
      return null;
    }
    if (clearPrev) {
      this.setState({ params: [], isLoading: true });
    }
    if (!Object.keys(blueprintParams).length > 0) {
      this.setState({ params: [], isLoading: false });
      return null;
    }

    this.setState({ isLoading: true });
    const requestQuery = this.getRequestQuery();
    setResultsFilters(requestQuery);
    queryParamHistory(requestQuery)
      .then(({ results, count }) => {
        Object.keys(blueprintParams).forEach((param) => {
          if (!results.find((res) => res.parameter_id === param)) {
            results.push({
              id: uuidv4(),
              run: null,
              parameter_id: param,
              status: 'NO_HISTORY',
              notYetRun: true,
            });
          }
        });

        const annotated = this.annotateParameterResults(results);
        this.setState(
          {
            params: clearPrev ? [...annotated] : [...params, ...annotated],
            isLoading: false,
            totalItems: count,
          },
          () => {
            setTabsStatus(true);
          },
        );
        this.props.onChange && this.prop.onChange(count);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        setSnackbar('An error occurred while loading records');
      });
    return null;
  }

  formatParameterActions(cell, row) {
    if (row.type === 'app') {
      return null;
    }

    const { recordActions, onHistoryClick } = this.props;
    const options = [];
    if (recordActions.indexOf('history') >= 0) {
      const historyHandler = () => {
        localStorage.setItem('activeComputerParameter', row.parameter_id);
        return onHistoryClick(row.parameter_id);
      };
      options.push({
        action: historyHandler,
        iconClass: 'arrow-rotate-left',
        label: 'View Parameter History',
      });
    }
    return <AwesomeDropdown horizontal="right" options={options} />;
  }

  renderEmptyParamsList() {
    const { blueprintParams, setTabsStatus } = this.props;
    const results = [];
    Object.keys(blueprintParams).map((param) => {
      results.push({
        id: uuidv4(),
        run: null,
        parameter_id: param,
        status: 'NO_HISTORY',
        notYetRun: true,
      });
    });
    const annotated = this.annotateParameterResults(results);
    this.setState(
      {
        params: annotated,
        totalItems: Object.keys(blueprintParams).length,
      },
      () => setTabsStatus(true),
    );
  }

  formatStatusRow = (cell, row) => {
    const id = row.app_id || row.script_id || row.library_item_id || row.id;
    return (
      <div>
        <i
          className={`${getStatusCircleIconClass(
            row.status,
          )} table-row-status-icon ${getStatusColorClass(row.status)}`}
          id={`status-${id}`}
        />
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`status-${id}`}
        >
          {row.status && getHelperTextForStatus(row.status)}
        </UncontrolledTooltip>
      </div>
    );
  };

  formatNameRow = (cell, row) => (
    <span title={row.nameVerbose}>{row.nameVerbose}</span>
  );

  // custom script and custom app APIs don't return type field, cannot use generic method.
  formatAppLink = (cell, row) => {
    const { history: historyObj } = this.props;
    return (
      <div className="b-flex">
        <div
          role="presentation"
          className="cursor-pointer underline-cell b-mr-tiny"
          onClick={linkEvent(
            historyObj,
            `${links.customApps}/${row.library_item_id}`,
          )}
          title={row.name}
        >
          {row.name}
        </div>
        {row.rules_present && (
          <HoverTippy icon={RulesIcon} text="Assignment Rules" />
        )}
      </div>
    );
  };

  formatScriptLink = (cell, row) => {
    const { history: historyObj } = this.props;
    return (
      <div className="b-flex">
        <div
          role="presentation"
          className="cursor-pointer underline-cell b-mr-tiny"
          onClick={linkEvent(
            historyObj,
            `${links.customScripts}/${row.library_item_id}`,
          )}
          title={row.name}
        >
          {row.name}
        </div>
        {row.rules_present && (
          <HoverTippy icon={RulesIcon} text="Assignment Rules" />
        )}
      </div>
    );
  };

  formatLibraryLink = (cell, row) => {
    const { history: historyObj } = this.props;
    return (
      <div className="b-flex">
        <div
          role="presentation"
          className="cursor-pointer underline-cell b-mr-tiny"
          onClick={linkEvent(historyObj, getLink(row))}
          title={row.name}
        >
          {row.name}
        </div>
        {row.rules_present && (
          <HoverTippy icon={RulesIcon} text="Assignment Rules" />
        )}
      </div>
    );
  };

  formatLibrarySettingsLink = (cell, row) => {
    const { history: historyObj } = this.props;
    return (
      <div className="b-flex">
        <div
          role="presentation"
          className="cursor-pointer underline-cell b-mr-tiny"
          onClick={linkEvent(historyObj, `${getLink(row)}`)}
          title={row.name}
        >
          {row.name}
        </div>
        {row.rules_present && (
          <HoverTippy icon={RulesIcon} text="Assignment Rules" />
        )}
      </div>
    );
  };

  formatProfileLink = (_, row) => {
    const { history: historyObj } = this.props;

    let profileConfig;

    // If provided an `identifier`, search for a matching profile config based on that `identifier`.
    // Note that there are inconsistencies in the way profile statuses indicate that they do not
    // have an `identifier` (ie. "undefined" or "") so there are cases where this `if` statement
    // will be entered but not actually yield a profile config.
    if (row.identifier?.length) {
      profileConfig = Object.values(profileApps).find(
        ({ identifier }) => row?.identifier === identifier,
      );
    }

    // If a profile config was not found using the `identifier`, attempt to find one using `type`.
    if (!profileConfig) {
      profileConfig = Object.values(profileApps).find(
        ({ type }) => row?.type === type,
      );
    }

    if (!profileConfig) {
      console.error(
        "Warning profile config wasn't found for: ",
        row?.identifier,
      );
      return null;
    }

    const profileLink = profileConfig.getUrl({
      id: row.library_item_id,
      environment: { inclusionLvl },
    });

    return (
      <div className="b-flex">
        <div
          role="presentation"
          title={row.name}
          className="cursor-pointer underline-cell b-mr-tiny"
          onClick={linkEvent(historyObj, profileLink)}
        >
          {row.name}
        </div>
        {row.rules_present && (
          <HoverTippy icon={RulesIcon} text="Assignment Rules" />
        )}
      </div>
    );
  };

  formatRunRow = (cell, row) => (
    <HoveredSpan
      text={row.runVerbose}
      hoveredText={
        row.run
          ? formatTime(row.run, false, false, false, true)
          : row.runVerbose
      }
    />
  );

  formatCustomAppDateRow = (cell, row) =>
    ['pending', 'excluded', 'available'].includes(row.status?.toLowerCase()) ? (
      // these three share the same icon color
      <Status iColor="#D3D3D3">
        <StatusLabel>
          <i className="fas fa-circle" /> {startCase(row.status.toLowerCase())}
        </StatusLabel>
      </Status>
    ) : (
      row.most_recent_action && (
        <HoveredSpan
          text={formatTime(row.most_recent_action)}
          hoveredText={
            row.most_recent_action
              ? formatTime(row.most_recent_action, false, false, false, true)
              : row.most_recent_action
          }
        />
      )
    );

  formatDateRow = (cell, row) => {
    const isMissingDate = row.date === undefined || row.date === null;
    const isPending = row.status?.toLowerCase() === 'pending';
    const isAvailable = row.status?.toLowerCase() === 'available';
    const isExcluded = row.status?.toLowerCase() === 'excluded';

    if (isPending || isAvailable || isExcluded) {
      // these three share the same icon color
      return (
        <Status iColor="#D3D3D3">
          <StatusLabel>
            <i className="fas fa-circle" />{' '}
            {startCase(row.status.toLowerCase())}
          </StatusLabel>
        </Status>
      );
    }
    if (!isMissingDate) {
      return (
        <HoveredSpan
          text={
            row.last_audit_run
              ? formatTime(row.last_audit_run)
              : formatTime(row.date)
          }
          hoveredText={
            row.date
              ? formatTime(row.date, false, false, false, true)
              : row.date
          }
        />
      );
    }

    return null;
  };

  expandDetails = (row) => {
    if (!row.details || !row.details.length) {
      return null;
    }
    return (
      <div className="parameter-details">
        {row.details && (
          <p key="date_last_run">
            {row.status !== 'REMEDIATED'
              ? `Last Update: ${formatTime(row.run, null, null, null, true)}`
              : `Remediation on ${formatTime(row.run, null, null, null, true)}`}
          </p>
        )}
        {(row.details || []).map((el, idx) => (
          <p key={idx}>{el}</p>
        ))}
      </div>
    );
  };

  expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
    let content = '';

    if (isExpandableRow) {
      content = isExpanded ? (
        <i className="fas fa-caret-down c-grey-500" />
      ) : (
        <i className="fas fa-caret-right c-grey-999" />
      );
    }
    return content;
  };

  renderBlocker = () => (
    <div className="tab-parameters-blocker">
      <div className="wrapper">
        <h1 className="message">
          Results will become available after your subscription is updated.
        </h1>
        <p>
          Please contact your Account Executive or Customer Success Manager to
          update your subscription.
        </p>
      </div>
    </div>
  );

  renderNoParametersView() {
    const { blueprintParams, computer, updateBlueprint, permissions } =
      this.props;
    const { isLoading, profileStatuses } = this.state;
    const TableName =
      computer.device_family === 'Mac' ? 'Parameters' : 'Profiles';
    const noData =
      computer.device_family === 'Mac'
        ? isEmpty(blueprintParams)
        : isEmpty(profileStatuses);
    if (isLoading) {
      return <LineLoader />;
    }
    if (noData) {
      return (
        <TableNoDataHelper
          borderless
          key="##table-empty##"
          message={`No ${TableName} Enabled`}
          submessage={`${computer.blueprint} has 0 ${TableName} enabled\nGet busy and start flipping some switches`}
          image={NoParametersEnabled}
          btnText={permissions.canManageBlueprints && `Enable ${TableName}`}
          onBtnClick={() => {
            updateBlueprint(
              TableName === 'Parameters'
                ? { editorMode: true }
                : { appEditorMode: true },
            );
            history.push(`/blueprints/${computer.blueprint_id}/parameters`);
          }}
          imageWidth={300}
          imageMargin="0 0 0 -35px"
          onFullWidth
        />
      );
    }
    return (
      <TableNoDataHelper
        borderless
        key="##table-empty##"
        message="No data to display"
        image={NoDataToDisplay}
      />
    );
  }

  rowHasDetails = (row) => !isEmpty(get(row, 'details'));

  rowHasLog = (row) => !isEmpty(get(row, 'log') || get(row, 'last_audit_log'));

  isExpandableProfileRow = (row, profilesWithoutProfileType) =>
    profilesWithoutProfileType[row.type]
      ? this.rowHasLog(row)
      : ['success', 'pass', 'failed', 'installing', 'incompatible'].includes(
          row.status?.toLowerCase(),
        ) ||
        (row.status?.toLowerCase() === 'pending' && row.last_audit_log);

  logProfileWithControl = (row, profilesWithoutProfileType) => {
    if (profilesWithoutProfileType[row.type]) {
      return libraryItemLog(row);
    }
    let controlLogTitle = null;
    if (row.identifier === ProfileTypes.FILEVAULT) {
      controlLogTitle = 'FileVault Status';
    } else if (row.identifier === ProfileTypes.FIREWALL) {
      controlLogTitle = 'Firewall Status';
    }
    return profileLog(row, row.date, controlLogTitle);
  };

  render() {
    const {
      paramNameColWidth,
      parameterMeta,
      tariffBlocked,
      activeTab,
      computer,
    } = this.props;

    const { params, libraryItemStatuses } = this.state;

    const parameterOptions = {
      defaultSortName: 'run', // default sort column name
      defaultSortOrder: 'desc', // default sort order
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
      noDataView: this.renderNoParametersView(),
    };

    const customAppInstallStatusOptions = {
      defaultSortName: 'most_recent_action', // default sort column name
      defaultSortOrder: 'desc', // default sort order
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
    };

    const sortByDateDefaultOptions = {
      defaultSortName: 'date', // default sort column name
      defaultSortOrder: 'desc', // default sort order
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
    };

    const sortByNameDefaultOptions = {
      defaultSortName: 'name',
      defaultSortOrder: 'asc',
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
    };

    const iOSProfilesOptions = {
      defaultSortName: 'date', // default sort column name
      defaultSortOrder: 'desc', // default sort order
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
      noDataView: this.renderNoParametersView(),
    };

    const securityOptions = {
      defaultSortName: 'date', // default sort column name
      defaultSortOrder: 'desc', // default sort order
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
    };

    if (!parameterMeta || !Object.keys(parameterMeta).length) {
      return <LineLoader />;
    }
    const nameColWidth = paramNameColWidth || '25%';

    const tdStyle = {
      height: 50,
    };

    if (tariffBlocked) {
      return this.renderBlocker();
    }

    // The items placed under the profile category that are not really
    // profiles (type !== PROFILE).
    const profilesWithoutProfileType = Object.values(profileApps).reduce(
      (a, c) => {
        if (c.type === apiTypes.PROFILE) {
          return a;
        }
        return {
          ...a,
          [c.type]: c,
        };
      },
      {},
    );

    const ipaAppStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.IPA_APP,
    );
    const vppAppStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.VPP_APP,
    );
    const automaticAppStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.AUTO_APP,
    );
    const operatingSystemStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.MACOS_RELEASE,
    );
    const iOSReleaseStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.IOS_RELEASE,
    );
    const iPadOSReleaseStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.IPADOS_RELEASE,
    );
    const tvOSReleaseStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.TVOS_RELEASE,
    );
    const mdmMacosReleaseStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.MDM_MACOS_RELEASE,
    );
    const customPrinterStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.CUSTOM_PRINTER,
    );
    const customAppStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.CUSTOM_APP,
    );
    const customScriptStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.CUSTOM_SCRIPT,
    );
    const securityStatuses = libraryItemStatuses.filter((i) =>
      [apiTypes.THREAT_SECURITY_POLICY, apiTypes.DEVICE_CONTROL].includes(
        i.type,
      ),
    );

    // Profiles with type 'profile'
    const profileStatuses = libraryItemStatuses.filter(
      (i) => i.type === apiTypes.PROFILE,
    );
    // Profiles that have a different type, ex. 'recovery-password' and 'ssh'
    const profilesWithoutProfileTypeStatuses = libraryItemStatuses.filter(
      (i) => profilesWithoutProfileType[i.type],
    );
    // Combine the two to create a list of all profile statuses
    const allProfileStatuses = [
      ...profileStatuses,
      ...profilesWithoutProfileTypeStatuses,
    ];

    const statusColumn = () => (
      <TableHeaderColumn
        dataField="status"
        dataAlign="left"
        columnClassName="td-icon td-status-icon"
        className="td-icon"
        dataFormat={this.formatStatusRow}
        tdStyle={tdStyle}
        width="15px"
      />
    );

    const nameColumn = ({
      title,
      dataFormat,
      sort = true,
      dataField = 'name',
    }) => (
      <TableHeaderColumn
        dataSort={sort}
        dataField={dataField}
        dataFormat={dataFormat}
        tdStyle={{ width: nameColWidth, color: 'black' }}
        thStyle={{ width: nameColWidth }}
      >
        {title}
      </TableHeaderColumn>
    );

    const dateColumn = ({
      title,
      dataField = 'date',
      dataFormat = this.formatDateRow,
    }) => (
      <TableHeaderColumn
        dataSort
        dataField={dataField}
        dataFormat={dataFormat}
        width="200px"
        tdStyle={{ color: colors['grey-450'], fontSize: '.9rem' }}
      >
        {title}
      </TableHeaderColumn>
    );

    const actionsColumn = ({ dataFormat = () => null }) => (
      <TableHeaderColumn
        dataField="id"
        tdStyle={{
          overflow: 'visible',
          width: '80px',
          textAlign: 'right',
        }}
        thStyle={{ width: '80px' }}
        dataFormat={dataFormat}
        expandable={false}
      />
    );

    return (
      <>
        {/* ==================== In-House Apps Status ==================== */}
        {!isEmpty(ipaAppStatuses) && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>In-House Apps</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={ipaAppStatuses}
                version="4"
                // search
                options={sortByNameDefaultOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasLog}
                expandComponent={libraryItemLog}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'App Name',
                  dataFormat: this.formatLibraryLink,
                })}

                {dateColumn({ title: 'Checked In' })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== VPP App Status ==================== */}
        {!isEmpty(vppAppStatuses) && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>App Store Apps</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={vppAppStatuses}
                version="4"
                // search
                options={sortByDateDefaultOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasLog}
                expandComponent={vppAppLog}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'App Name',
                  dataFormat: this.formatLibraryLink,
                  sort: false,
                })}

                {dateColumn({ title: 'Checked In' })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== Automatic App Status ==================== */}
        {!isEmpty(automaticAppStatuses) && computer.device_family === 'Mac' && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>Auto Apps</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={automaticAppStatuses}
                version="4"
                // search
                options={sortByNameDefaultOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasLog}
                expandComponent={libraryItemLog}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'App Name',
                  dataFormat: this.formatLibraryLink,
                })}

                {dateColumn({ title: 'Checked In' })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== Operating System Status ==================== */}
        {!isEmpty(operatingSystemStatuses) &&
          computer.device_family === 'Mac' && (
            <>
              <TempPaddingBetweenTables />
              <CategoryName>Operating Systems</CategoryName>
              <TempPadding />
              <div className="tab-parameters">
                <BootstrapTable
                  data={operatingSystemStatuses}
                  version="4"
                  // search
                  options={sortByDateDefaultOptions}
                  tableContainerClass="mini"
                  containerClass="bst-borderless old-table"
                  expandableRow={this.rowHasLog}
                  expandComponent={libraryItemLog}
                  trClassName="table-row-clickable"
                  expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }}
                >
                  {statusColumn()}

                  {nameColumn({
                    title: 'App Name',
                    dataFormat: this.formatLibraryLink,
                    sort: false,
                  })}

                  {dateColumn({ title: 'Checked In' })}

                  {actionsColumn({})}

                  <TableHeaderColumn dataField="id" isKey hidden />
                </BootstrapTable>
              </div>
            </>
          )}

        {!isEmpty(iOSReleaseStatuses) &&
          ['iPhone', 'iPod'].includes(computer.device_family) && (
            <>
              <TempPaddingBetweenTables />
              <CategoryName>Operating Systems</CategoryName>
              <TempPadding />
              <div className="tab-parameters">
                <BootstrapTable
                  data={iOSReleaseStatuses}
                  version="4"
                  // search
                  options={sortByDateDefaultOptions}
                  tableContainerClass="mini"
                  containerClass="bst-borderless old-table"
                  expandableRow={this.rowHasLog}
                  expandComponent={libraryItemLog}
                  trClassName="table-row-clickable"
                  expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }}
                >
                  {statusColumn()}

                  {nameColumn({
                    title: 'App Name',
                    dataFormat: this.formatLibrarySettingsLink,
                    sort: false,
                  })}

                  {dateColumn({ title: 'Checked In' })}

                  {actionsColumn({})}

                  <TableHeaderColumn dataField="id" isKey hidden />
                </BootstrapTable>
              </div>
            </>
          )}

        {!isEmpty(iPadOSReleaseStatuses) &&
          computer.device_family === 'iPad' && (
            <>
              <TempPaddingBetweenTables />
              <CategoryName>Operating Systems</CategoryName>
              <TempPadding />
              <div className="tab-parameters">
                <BootstrapTable
                  data={iPadOSReleaseStatuses}
                  version="4"
                  // search
                  options={sortByDateDefaultOptions}
                  tableContainerClass="mini"
                  containerClass="bst-borderless old-table"
                  expandableRow={this.rowHasLog}
                  expandComponent={libraryItemLog}
                  trClassName="table-row-clickable"
                  expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }}
                >
                  {statusColumn()}

                  {nameColumn({
                    title: 'App Name',
                    dataFormat: this.formatLibrarySettingsLink,
                    sort: false,
                  })}

                  {dateColumn({ title: 'Checked In' })}

                  {actionsColumn({})}

                  <TableHeaderColumn dataField="id" isKey hidden />
                </BootstrapTable>
              </div>
            </>
          )}

        {!isEmpty(tvOSReleaseStatuses) &&
          computer.device_family === 'AppleTV' && (
            <>
              <TempPaddingBetweenTables />
              <CategoryName>Operating Systems</CategoryName>
              <TempPadding />
              <div className="tab-parameters">
                <BootstrapTable
                  data={tvOSReleaseStatuses}
                  version="4"
                  // search
                  options={sortByDateDefaultOptions}
                  tableContainerClass="mini"
                  containerClass="bst-borderless old-table"
                  expandableRow={this.rowHasLog}
                  expandComponent={libraryItemLog}
                  trClassName="table-row-clickable"
                  expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }}
                >
                  {statusColumn()}

                  {nameColumn({
                    title: 'App Name',
                    dataFormat: this.formatLibrarySettingsLink,
                    sort: false,
                  })}

                  {dateColumn({ title: 'Checked In' })}

                  {actionsColumn({})}

                  <TableHeaderColumn dataField="id" isKey hidden />
                </BootstrapTable>
              </div>
            </>
          )}

        {!isEmpty(mdmMacosReleaseStatuses) &&
          computer.device_family === 'Mac' &&
          getDevelopmentEnvironmentName() && (
            <>
              <TempPaddingBetweenTables />
              <CategoryName>MDM Managed macOS Releases</CategoryName>
              <TempPadding />
              <div className="tab-parameters">
                <BootstrapTable
                  data={mdmMacosReleaseStatuses}
                  version="4"
                  // search
                  options={sortByDateDefaultOptions}
                  tableContainerClass="mini"
                  containerClass="bst-borderless old-table"
                  expandableRow={this.rowHasLog}
                  expandComponent={libraryItemLog}
                  trClassName="table-row-clickable"
                  expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }}
                >
                  {statusColumn()}

                  {nameColumn({
                    title: 'App Name',
                    dataFormat: this.formatLibrarySettingsLink,
                    sort: false,
                  })}

                  {dateColumn({ title: 'Checked In' })}

                  {actionsColumn({})}

                  <TableHeaderColumn dataField="id" isKey hidden />
                </BootstrapTable>
              </div>
            </>
          )}

        {/* ==================== Apps Statuses ==================== */}
        {!isEmpty(customAppStatuses) && computer.device_family === 'Mac' && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>Custom Apps</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={customAppStatuses}
                version="4"
                // search
                options={customAppInstallStatusOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasLog}
                expandComponent={libraryItemLog}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'Application Name',
                  dataFormat: this.formatAppLink,
                  sort: false,
                })}

                {dateColumn({
                  title: 'Checked In',
                  dataField: 'most_recent_action',
                  dataFormat: this.formatCustomAppDateRow,
                })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== Scripts Status ==================== */}
        {!isEmpty(customScriptStatuses) && computer.device_family === 'Mac' && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>Custom Scripts</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={customScriptStatuses}
                version="4"
                // search
                options={sortByDateDefaultOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasLog}
                expandComponent={standardLog}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'Script Name',
                  dataFormat: this.formatScriptLink,
                  sort: false,
                })}

                {dateColumn({ title: 'Ran' })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== Custom Printer Status ==================== */}
        {!isEmpty(customPrinterStatuses) &&
          computer.device_family === 'Mac' && (
            <>
              <TempPaddingBetweenTables />
              <CategoryName>Custom Printers</CategoryName>
              <TempPadding />
              <div className="tab-parameters">
                <BootstrapTable
                  data={customPrinterStatuses}
                  version="4"
                  // search
                  options={sortByDateDefaultOptions}
                  tableContainerClass="mini"
                  containerClass="bst-borderless old-table"
                  expandableRow={this.rowHasLog}
                  expandComponent={standardLog}
                  trClassName="table-row-clickable"
                  expandColumnOptions={{
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }}
                >
                  {statusColumn()}

                  {nameColumn({
                    title: 'Script Name',
                    dataFormat: this.formatLibraryLink,
                    sort: false,
                  })}

                  {dateColumn({ title: 'Ran' })}

                  {actionsColumn({})}

                  <TableHeaderColumn dataField="id" isKey hidden />
                </BootstrapTable>
              </div>
            </>
          )}

        {/* ==================== Profiles Statuses ==================== */}
        {((computer.device_family === 'Mac' && !isEmpty(allProfileStatuses)) ||
          computer.device_family !== 'Mac') && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>Profiles</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={allProfileStatuses}
                version="4"
                // search
                options={iOSProfilesOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={(row) =>
                  this.isExpandableProfileRow(row, profilesWithoutProfileType)
                }
                expandComponent={(row) =>
                  this.logProfileWithControl(row, profilesWithoutProfileType)
                }
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'Profile Name',
                  dataFormat: this.formatProfileLink,
                  sort: false,
                })}

                {dateColumn({ title: 'Checked In' })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== Security Statuses ==================== */}
        {computer.device_family === 'Mac' && !isEmpty(securityStatuses) && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>{categories.SECURITY}</CategoryName>
            <TempPadding />
            <div className="tab-parameters">
              <BootstrapTable
                data={securityStatuses}
                version="4"
                // search
                options={securityOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasLog}
                expandComponent={standardLog}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'Name',
                  dataFormat: this.formatLibraryLink,
                  sort: false,
                })}

                {dateColumn({ title: 'Checked In' })}

                {actionsColumn({})}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}

        {/* ==================== Parameters Statuses ==================== */}
        {computer.device_family === 'Mac' && (
          <>
            <TempPaddingBetweenTables />
            <CategoryName>Parameters</CategoryName>
            <TempPadding />
            <div className="tab-parameters tab-parameters-section">
              <BootstrapTable
                data={params}
                version="4"
                // search
                options={parameterOptions}
                tableContainerClass="mini"
                containerClass="bst-borderless old-table"
                expandableRow={this.rowHasDetails}
                expandComponent={this.expandDetails}
                trClassName="table-row-clickable"
                expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: this.expandColumnComponent,
                  columnWidth: 60,
                }}
              >
                {statusColumn()}

                {nameColumn({
                  title: 'Parameter Name',
                  dataFormat: this.formatNameRow,
                  dataField: 'nameVerbose',
                  sort: activeTab !== 'paramHistory',
                })}

                {dateColumn({
                  title: 'Checked In',
                  dataField: 'run',
                  dataFormat: this.formatRunRow,
                })}

                {actionsColumn({
                  dataFormat: this.formatParameterActions,
                })}

                <TableHeaderColumn dataField="id" isKey hidden />
              </BootstrapTable>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tariffBlocked: state.account.tariffBlocked,

  parameterMeta: state.data.parameterMeta,
  blueprints: state.data.blueprints,
  blueprintNames: state.data.blueprintNames,
  blueprintParams: state.data.blueprintParams,
  computers: state.data.computers,
  computer: state.computerRecord,
  activeTab: state.computerRecord.activeTab,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal: callSetModal,
      setSnackbar: callSetSnackbar,
      setEditor: callSetEditor,
      startGetBlueprint: callStartGetBlueprint,
      updateComputer: callUpdateComputer,
      setTabsStatus: callSetTabsStatus,
      setTabsScroll: callSetTabsScroll,
      setResultsFilters: callSetResultsFilters,
      getBlueprintParams: callGetBlueprintParams,
      updateBlueprint: callUpdateBlueprint,
    },
    dispatch,
  );

StatusTab.defaultProps = {
  recordActions: ['history', 'note'],
  filters: ['status', 'parameter'],
  tariffBlocked: false,
};

StatusTab.propTypes = {
  recordActions: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(PropTypes.string),

  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  parameterMeta: PropTypes.object.isRequired,
  blueprints: PropTypes.array.isRequired,
  blueprintNames: PropTypes.object,
  blueprintParams: PropTypes.object,
  computers: PropTypes.array,
  computer: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  tariffBlocked: PropTypes.bool,

  paramNameColWidth: PropTypes.string,

  onChange: PropTypes.func,
  onHistoryClick: PropTypes.func,
  getBlueprintParams: PropTypes.func,
  setResultsFilters: PropTypes.func,
  setTabsStatus: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withPermissions(StatusTab)),
);
