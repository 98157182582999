import {
  Button,
  Toaster_UNSTABLE as Toaster,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { getDisplayName } from 'src/features/visibility/prism/implemented-category-uris';
import type {
  ExportPrismCategoryRequestSchema,
  ExportPrismViewRequestSchema,
} from 'src/features/visibility/prism/types/prism.types';
import type { PulseExportRequestSchema } from 'src/features/visibility/pulse/types/pulse.types';
import { InterfaceContext } from '../interface';
import { useCreateExportMutation, useExportQuery } from './hooks';

export type ExportDataType = 'prism' | 'pulse' | 'views';

export const DataExportContext = React.createContext<{
  isExporting: boolean;
  onCreateExport: (
    data: ExportPrismCategoryRequestSchema | PulseExportRequestSchema,
  ) => void;
  setExportDataType: (type: ExportDataType) => void;
}>({
  isExporting: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: ok
  onCreateExport: /* istanbul ignore next */ () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: ok
  setExportDataType: /* istanbul ignore next */ () => {},
});

export const DataExportProvider = ({
  children,
}: { children: React.ReactNode }) => {
  const [exportId, setExportId] = React.useState<string | null>(null);
  const [exportDataType, setExportDataType] =
    React.useState<ExportDataType>('prism');

  const { sidebarOpened } = React.useContext(InterfaceContext);

  // istanbul ignore next
  const sidebarOffset = sidebarOpened ? 264 : 100;
  const { toast } = useToast();

  const {
    mutateAsync: createExport,
    isPending: createExportPending,
    variables,
    isError,
  } = useCreateExportMutation(exportDataType);

  const { data: exportData, fetchStatus: exportFetchStatus } = useExportQuery(
    exportDataType,
    exportId,
  );

  const isExporting =
    createExportPending ||
    exportFetchStatus === 'fetching' ||
    exportId !== null;

  const handleCreateExport = React.useCallback(
    async (
      data: ExportPrismCategoryRequestSchema | PulseExportRequestSchema,
    ) => {
      const createdExport = await createExport(data);
      setExportId(createdExport.id);
    },
    [createExport],
  );

  React.useEffect(() => {
    // istanbul ignore next
    const handleBeforeUnload = (event) => {
      if (exportData?.status === 'pending') {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [exportData?.status]);

  React.useEffect(() => {
    // istanbul ignore next
    if (
      exportData?.status === 'success' &&
      exportData?.signed_url &&
      exportData?.path
    ) {
      toast({
        open: true,
        duration: 8000,
        title: getToastTitle(exportDataType, variables),
        variant: 'success',
        content: 'Export complete!',
        style: {
          left: sidebarOffset,
          position: 'absolute',
          bottom: '12px',
        },
      });
      downloadFile(exportData.signed_url, exportData.path);
      setExportId(null);
    }

    // istanbul ignore next
    if (exportData?.status === 'pending') {
      toast({
        title: getToastTitle(exportDataType, variables),
        open: true,
        duration: Infinity,
        variant: 'default',
        content: 'Preparing file for download...',
        style: {
          left: sidebarOffset,
          position: 'absolute',
          bottom: '12px',
        },
      });
    }
  }, [exportData, exportDataType, variables, sidebarOffset, toast]);

  React.useEffect(() => {
    // istanbul ignore next
    if (exportData?.status === 'failed' || isError) {
      const title = getToastTitle(exportDataType, variables);
      toast({
        open: true,
        duration: 8000,
        title,
        variant: 'error',
        content: 'Export failed!',
        action: (
          <Button
            variant="primary"
            onClick={() => {
              const payload = getExportPayload(exportDataType, variables);
              handleCreateExport(payload);
              setExportId(null);
            }}
          >
            Retry
          </Button>
        ),
        style: {
          left: sidebarOffset,
          position: 'absolute',
          bottom: '12px',
        },
      });
    }
  }, [
    exportData,
    handleCreateExport,
    variables,
    exportDataType,
    isError,
    sidebarOffset,
    toast,
  ]);

  return (
    <DataExportContext.Provider
      value={{
        isExporting,
        onCreateExport: handleCreateExport,
        setExportDataType,
      }}
    >
      {children}
      <Toaster />
    </DataExportContext.Provider>
  );
};

export const useDataExportContext = () => React.useContext(DataExportContext);

export const getToastTitle = (
  exportDataType: ExportDataType,
  variables:
    | ExportPrismCategoryRequestSchema
    | PulseExportRequestSchema
    | ExportPrismViewRequestSchema,
) => {
  switch (exportDataType) {
    case 'prism':
      return getDisplayName(
        (variables as ExportPrismCategoryRequestSchema).category ??
          'device_information',
      );
    case 'views':
      return (variables as ExportPrismViewRequestSchema).view_name ?? 'Devices';
    default:
      return (variables as PulseExportRequestSchema).report_name;
  }
};

export const getExportPayload = (
  exportDataType: ExportDataType,
  variables:
    | ExportPrismCategoryRequestSchema
    | PulseExportRequestSchema
    | ExportPrismViewRequestSchema,
):
  | ExportPrismCategoryRequestSchema
  | PulseExportRequestSchema
  | ExportPrismViewRequestSchema => {
  switch (exportDataType) {
    case 'prism': {
      const prismVariables = variables as ExportPrismCategoryRequestSchema;
      return {
        blueprint_ids: prismVariables.blueprint_ids ?? [],
        device_families: prismVariables.device_families ?? [],
        category: prismVariables.category ?? 'device_information',
        filter: prismVariables.filter,
        sort_by: prismVariables.sort_by,
      };
    }
    case 'pulse': {
      const pulseVariables = variables as PulseExportRequestSchema;
      return {
        pulse_job_id: pulseVariables.pulse_job_id,
        with_results_only: pulseVariables.with_results_only,
      };
    }
    case 'views': {
      const viewVariables = variables as ExportPrismViewRequestSchema;
      return {
        view_name: viewVariables.view_name,
        blueprint_ids: viewVariables.blueprint_ids ?? [],
        device_families: viewVariables.device_families ?? [],
        filter: viewVariables.filter,
        columns: viewVariables.columns,
      };
    }
    default:
      throw new Error(`Unsupported export data type: ${exportDataType}`);
  }
};

// istanbul ignore next
function downloadFile(signedUrl: string, path: string) {
  const link = document.createElement('a');
  link.setAttribute('href', signedUrl);
  link.setAttribute('download', path);
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
