import { Banner, Checkbox } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Setting } from 'features/library-items/template';

const AutomaticUpdates = ({ update, isDisabled, settings }) => {
  const onCheckForUpdatesChange = () => {
    update('AutomaticCheckEnabled', (checked) => {
      const newValue = !checked;

      if (!newValue) {
        // set other options to false:
        update('AutomaticDownload', false);
        update('AutomaticallyInstallMacOSUpdates', false);
        update('AutomaticallyInstallAppUpdates', false);
        update('ConfigDataInstall', false);
      }

      return newValue;
    });
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Automatic updates</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Manage settings found in the Software Update preference pane.
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Check for updates"
              id="check_for_updates"
              testId="check_for_updates_checkbox"
              checked={settings.AutomaticCheckEnabled}
              onChange={onCheckForUpdatesChange}
              disabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically check for available updates.
            </p>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Download new updates when available"
              id="download_new_updates"
              testId="download_new_updates_checkbox"
              checked={settings.AutomaticDownload}
              onChange={() => {
                update('AutomaticDownload', (checked) => !checked);
              }}
              disabled={isDisabled || !settings.AutomaticCheckEnabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically download available macOS updates in the background.
            </p>

            {!settings.AutomaticCheckEnabled && (
              <Banner theme="info" kind="block">
                <p>
                  Turn on &quot;Check for updates&quot; to modify this option.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Install macOS updates"
              id="install_macos_updates"
              testId="install_macos_updates_checkbox"
              checked={settings.AutomaticallyInstallMacOSUpdates}
              onChange={() =>
                update(
                  'AutomaticallyInstallMacOSUpdates',
                  (checked) => !checked,
                )
              }
              disabled={isDisabled || !settings.AutomaticDownload}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically install macOS updates that have been downloaded.
            </p>
            <p className="b-txt-light">
              macOS notifies users that pending software updates will require a
              restart. Users have the option to temporarily delay these updates.
            </p>

            {!settings.AutomaticDownload && (
              <Banner theme="info" kind="block">
                <p>
                  Turn on &quot;Download new updates when available&quot; to
                  change this setting.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Install app updates from the App Store"
              id="install_app_updates"
              testId="install_app_updates_checkbox"
              checked={settings.AutomaticallyInstallAppUpdates}
              onChange={() =>
                update('AutomaticallyInstallAppUpdates', (checked) => !checked)
              }
              disabled={isDisabled || !settings.AutomaticDownload}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically download and install app updates from the App Store.
            </p>

            {!settings.AutomaticDownload && (
              <Banner theme="info" kind="block">
                <p>
                  Turn on &quot;Download new updates when available&quot; to
                  change this setting.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Install system data files and security updates"
              id="install_system_updates"
              testId="install_system_updates_checkbox"
              checked={settings.ConfigDataInstall}
              onChange={() =>
                update('ConfigDataInstall', (checked) => !checked)
              }
              disabled={isDisabled}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Automatically download and install Security Responses, system data
              files and security updates, including XProtect, MRT, and
              Gatekeeper.{' '}
              <a
                href="https://support.apple.com/en-us/HT201222"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more...
              </a>
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

AutomaticUpdates.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    AutomaticCheckEnabled: PropTypes.bool,
    AutomaticDownload: PropTypes.bool,
    AutomaticallyInstallMacOSUpdates: PropTypes.bool,
    AutomaticallyInstallAppUpdates: PropTypes.bool,
    ConfigDataInstall: PropTypes.bool,
  }).isRequired,
};

export default memo(AutomaticUpdates);
