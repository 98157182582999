import { colors } from 'app/common/constants';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { useOnClickOutside } from '../common/hooks';

const WrapperDefault = styled.div.attrs(({ leftIcon }) => ({
  justifyContent: leftIcon ? 'space-between' : 'center',
}))`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-family-primary);
  transition: box-shadow 0.3s;
  cursor: pointer;
  height: 66px;
  justify-content: ${(props) => props.justifyContent};
`;

const WrapperSmall = styled(WrapperDefault)`
  height: 30px;
`;

const SubWrapper = styled.div.attrs(({ opened }) => ({
  zIndex: opened ? 10 : 9,
  boxShadow: opened ? '0px 10px 30px -5px rgba(0, 0, 0, 0.2)' : null,
}))`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.zIndex};
  box-shadow: ${(props) => props.boxShadow};
`;

const HeaderDefault = styled.div.attrs(({ opened }) => ({
  boxShadow: opened
    ? `0 0 0 1px ${colors['grey-250']}`
    : `0 0 0 1px ${colors['grey-250']}`,
  backgroundColor: colors[opened ? 'white' : 'grey-80'],
  borderBottomRightRadius: opened ? 0 : 5,
  borderBottomLeftRadius: opened ? 0 : 5,
}))`
  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px 15px;
  transition: box-shadow 0.15s;
  box-shadow: ${(props) => props.boxShadow};
  background-color: ${(props) => props.backgroundColor};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius}px;
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius}px;
`;

const HeaderSmall = styled(HeaderDefault)`
  height: 30px;
  padding-top: 0;
  padding-bottom: 0;
`;

const Body = styled.div.attrs(({ opened }) => ({
  boxShadow: opened ? `0 0 0 1px ${colors['grey-250']}` : null,
}))`
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.white};
  transition: box-shadow 0.15s;
  box-shadow: ${(props) => props.boxShadow};
`;

const Option = styled.div`
  padding: 10px 15px 0;
  &:last-child {
    padding-bottom: 20px;
  }
  &:hover {
    color: ${colors.yellow500};
  }
  transition: color 0.1s;
`;

const Arrow = styled.i.attrs(({ opened }) => ({
  color: opened ? colors.black : colors.grey500,
}))`
  margin-left: auto;
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-size: 16px;
  padding-left: 10px;
  transition: color 0.2s;
  color: ${(props) => props.color};
`;

const Label = styled.label`
  height: 16px;
  font-family: var(--font-family-primary);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2em;
  margin-bottom: 8px;
  color: ${colors.grey400};
`;

const LabelAndOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const bpParamSelectVariant = {
  default: 'default',
  small: 'small',
};

const wrapperMapping = {
  default: WrapperDefault,
  small: WrapperSmall,
};

const headerMapping = {
  default: HeaderDefault,
  small: HeaderSmall,
};

const BpParamSelect = ({
  value,
  options,
  label,
  filterSelected,
  onChange,
  noSelectText,
  disabled,
  variant,
  onClick,
  className,
}) => {
  const getChosenOption = options.find(
    (el) => String(el.value) === String(value),
  ) || { value: null, label: noSelectText };
  const [opened, setOpened] = useState(false);
  const [option, setOption] = useState(getChosenOption);

  const ref = React.useRef(null);
  useOnClickOutside(ref, () =>
    !disabled && opened ? setOpened(!opened) : null,
  );

  useEffect(() => {
    setOption(getChosenOption);
  }, [value]);

  const change = (opt) => {
    // setOption(opt); // Don't change inner selected value! Inner selected value must be set by props.value!
    setOpened(false);
    if (onChange) {
      onChange(opt);
    }
  };

  const Header = headerMapping[variant];
  const Wrapper = wrapperMapping[variant];

  return (
    <Wrapper className={className} opened={opened} ref={ref} onClick={onClick}>
      {/* SubWrapper needed for shadow */}
      <SubWrapper opened={opened}>
        <Header opened={opened} onClick={() => !disabled && setOpened(!opened)}>
          <LabelAndOptionWrapper>
            {label && <Label opened={opened}>{label}</Label>}
            {get(option, 'label')}
          </LabelAndOptionWrapper>
          <Arrow opened={opened} className="fas fa-angle-down" />
        </Header>

        {opened && options.length && (
          <Body opened={opened}>
            <Scrollbars autoHide autoHeight autoHeightMax={250}>
              {options
                .filter((item) =>
                  filterSelected ? item.value !== option.value : true,
                )
                .map((item) => (
                  <Option
                    key={camelCase(item.value)}
                    onClick={() => change(item)}
                    className={classNames({
                      active: item.value === option.value,
                    })}
                  >
                    {item.label}
                  </Option>
                ))}
            </Scrollbars>
          </Body>
        )}
      </SubWrapper>
    </Wrapper>
  );
};

BpParamSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  noSelectText: PropTypes.string,
  disabled: PropTypes.bool,
  filterSelected: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'small']),
};

BpParamSelect.defaultProps = {
  value: '',
  onChange: null,
  noSelectText: 'Select...',
  disabled: false,
  filterSelected: false,
  label: '',
  className: null,
  variant: 'default',
};

export default BpParamSelect;
