import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

import AddLibraryItem from '../../AddLibraryItem';
import emptySvg from '../assets/empty-list.svg';

// export type EmptyLibraryListProps = {};

const EmptyLibraryList = (/* props: EmptyLibraryListProps */) => (
  // const {} = props;

  <Flex
    flow="column"
    justifyContent="center"
    alignItems="center"
    gap="lg"
    css={{ maxWidth: '480px', alignSelf: 'center', textAlign: 'center' }}
  >
    <img src={emptySvg} alt="Empty Library Item List" />
    <Heading size="3">Get started with Library</Heading>
    <Text>
      Choose an item you would like to add to your Library. Once an item has
      been added and configured, it can be assigned to Blueprints.
    </Text>
    <AddLibraryItem />
  </Flex>
);
export default EmptyLibraryList;
