import {
  arrayOf,
  func,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types';
import { INT_TYPES } from '../../../constants';

const ACTION_NAME_MAPPING = {
  remediations: 'Remediations',
  computer_goes_missing: 'Device goes offline',
  computer_goes_active: 'Device no longer offline',
  new_mac_enrolled: 'New device enrolled',
  mac_checked_out: 'MDM profile removed',
  new_mac_first_run_summary: 'New Mac first run summary',
  application_blocked: 'An application is blocked',
  threat_alert_detected: 'Threat detected on device',
};
const ACTION_KEYS = Object.keys(ACTION_NAME_MAPPING);

const ACTIONS_PROP_TYPE = arrayOf(oneOf([...Object.keys(ACTION_NAME_MAPPING)]));
const TYPE_PROP_TYPE = oneOf([...Object.values(INT_TYPES)]);

const NOTIFICATION_SHAPE = shape({
  actions: ACTIONS_PROP_TYPE.isRequired,
  channels: arrayOf(
    shape({
      channel_id: string.isRequired,
      channel_name: string.isRequired,
    }),
  ).isRequired,
  created_at: string.isRequired,
  created_by: shape({
    first_name: string.isRequired,
    last_name: string.isRequired,
  }).isRequired,
  id: number.isRequired,
  name: string,
});

const INTEGRATION_SHAPE = shape({
  display_name: string,
  name: string.isRequired,
  icon: string,
  id: number.isRequired,
});

const INITIAL_NOTIFICATION_DATA = {
  channels: [],
  name: '',
  actions: [],
  integrationId: null,
  id: null,
};

const NOTIFICATION_DATA_SHAPE = shape({
  channels: arrayOf(
    shape({
      value: string.isRequired,
      label: string.isRequired,
    }),
  ).isRequired,
  name: string.isRequired,
  actions: ACTIONS_PROP_TYPE.isRequired,
  integrationId: number,
  id: number,
});

const CONFIG_SHAPE = shape({
  icons: shape({
    large: string.isRequired, // 48x48
    medium: string.isRequired, // 36x36
    small: string.isRequired, // 32x32
  }).isRequired,
  title: string.isRequired,
  description: string.isRequired,
  integrationAlias: string.isRequired,
  transformNotificationToApi: func.isRequired,
  transformNotificationToEditMode: func.isRequired,
  transformNotificationToTest: func.isRequired,
  initialNotificationData: object.isRequired,
  makeIntegrationLink: func.isRequired,
  getTableColumns: func.isRequired,
});

const SORT_ORDER = {
  asc: 1,
  desc: -1,
};

export {
  ACTION_NAME_MAPPING,
  ACTION_KEYS,
  NOTIFICATION_SHAPE,
  INTEGRATION_SHAPE,
  INITIAL_NOTIFICATION_DATA,
  NOTIFICATION_DATA_SHAPE,
  TYPE_PROP_TYPE,
  CONFIG_SHAPE,
  SORT_ORDER,
  ACTIONS_PROP_TYPE,
};
