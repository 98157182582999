import {
  Button,
  sidePanel as SidePanel,
  TextInput,
  setClass,
} from '@kandji-inc/bumblebee';
import React, { useState, useEffect } from 'react';
import useScreenFormValidation from '../use-screen-form-validation.hook';
import useShadowOnScroll from './use-shadow-on-scroll';

const limitTextNewLines = (text, maxNewLines, maxConsecutiveNewLines) => {
  const indices = Array.from(text)
    .map((char, idx) => (char === '\n' ? idx : -1))
    .filter((i) => i >= 0);
  const maxConsecutiveNewLineChars = maxConsecutiveNewLines * 2 - 1;
  const maxNewLineChars = maxNewLines * 2;
  // If multiline text allowed, must be less than max allowed
  // and non consecutive.
  const isConsecutive = indices.reduce(
    (a, c, idx, arr) => {
      if (a.hasFailed) {
        return a;
      }
      const prev = arr[idx - 1];
      if (!idx) {
        a.count += 1;
      } else {
        a.count = c === prev + 1 ? a.count + 1 : 1;
        if (a.count >= maxConsecutiveNewLineChars) {
          a.hasFailed = true;
        }
      }
      return a;
    },
    { count: 0, hasFailed: false },
  );

  if (indices.length <= maxNewLineChars && !isConsecutive.hasFailed) {
    return { hasFailed: false };
  }

  return { hasFailed: true };
};

const CustomizePanel = (props) => {
  const {
    form,
    model,
    setModel,
    initialStateCopy,
    disabled,
    beforeEditModel,
    multiLineTextAllowed,
    maxConsecutiveNewLines = 2,
    maxNewLines = 2,
    onValidations = () => {},
    autoSubheader,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { isShowHeaderShadow, setBody } = useShadowOnScroll();

  const update = (k, v) => {
    setModel((p) => ({
      ...p,
      [form.key]: {
        ...p[form.key],
        [k]: v,
      },
    }));
  };

  /* check if model has changed since initial model. */
  useEffect(() => {
    const prev = initialStateCopy[form.key];
    const next = model[form.key];
    const isSame = JSON.stringify(prev) === JSON.stringify(next);
    setIsUpdated(!isSame);
  }, [form]);

  const restoreAll = () => {
    setModel((p) => ({ ...p, [form.key]: initialStateCopy[form.key] }));
  };

  const { isFormInvalid, validator, onInvalidate } = useScreenFormValidation({
    inputs: 2,
  });

  const onCancel = () => {
    setIsOpen(false);
    setModel((p) => ({ ...p, [form.key]: beforeEditModel[form.key] }));
  };

  useEffect(() => {
    onValidations({
      name: form.key,
      isInvalid: isFormInvalid,
    });
  }, [isFormInvalid]);

  return (
    <div>
      <Button disabled={disabled} onClick={() => setIsOpen(true)}>
        Customize
      </Button>
      <SidePanel isVisible={isOpen} size="default">
        <div className="b-side-panel-layout">
          <div
            className={setClass(
              'b-side-panel-layout__header',
              isShowHeaderShadow && '--with-shadow',
            )}
          >
            <h2 className="b-h2">{form.title}</h2>
          </div>
          <div className="b-side-panel-layout__body" ref={setBody}>
            <div className="b-form-grid">
              <TextInput
                value={model[form.key].header}
                label={form.header.label}
                validator={validator(form.header.maxLength)}
                onInvalidate={onInvalidate(0)}
                maxLength={form.header.maxLength}
                fieldsGrid
                onChange={(e) => {
                  e.persist();
                  update('header', e.target.value);
                }}
              />
              <TextInput
                value={model[form.key].subHeader}
                style={{ minHeight: autoSubheader ? '85px' : '160px' }}
                textArea
                validator={validator(form.subHeader.maxLength)}
                onInvalidate={onInvalidate(1)}
                label={form.subHeader.label}
                maxLength={form.subHeader.maxLength}
                fieldsGrid
                onChange={(e) => {
                  e.persist();
                  const r = limitTextNewLines(
                    e.target.value,
                    ...(multiLineTextAllowed
                      ? [maxNewLines, maxConsecutiveNewLines]
                      : [0, 0]),
                  );
                  if (!r.hasFailed) {
                    update('subHeader', e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="b-side-panel-layout__footer">
            <div className="b-flex-btw">
              <Button onClick={restoreAll} disabled={!isUpdated} kind="link">
                Restore defaults
              </Button>
              <div className="b-grid-ctas">
                <Button kind="outline" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={isFormInvalid}
                  onClick={() => setIsOpen(false)}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </div>
  );
};

export default CustomizePanel;
