import { Flex } from '@kandji-inc/nectar-ui';
import useVulnerability from 'src/features/vulnerability/store';
import { DeviceCard } from './device-card';

const DeviceList = () => {
  const selectedVulnerability = useVulnerability(
    (state) => state.selectedVulnerability,
  );
  const { devices } = selectedVulnerability;

  return (
    <Flex flow="column" gap="md" css={{ padding: '$3 0' }}>
      {devices.map((device) => (
        <DeviceCard device={device} />
      ))}
    </Flex>
  );
};

export { DeviceList };
