import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import Configuration from './configuration';
import { generateRowConfig } from './rows.config';

const Configurations = (props) => {
  const { setting, update, isDisabled, isSaving, validationDep } = props;

  const addConfiguration = () => {
    if (!isDisabled) {
      update('vpnConfigurations', (p) => [...p, generateRowConfig()]);
    }
  };

  const deleteConfiguration = (configuration) => {
    if (!isDisabled) {
      update('vpnConfigurations', (p) =>
        p.filter(({ id }) => id !== configuration.id),
      );
    }
  };

  // Wraps the update call to update only a specific vpn configuration within
  // the array.
  const updateSpecificConfiguration = (configurationToUpdate) => (key, fn) => {
    update('vpnConfigurations', (p) => {
      const currentConfigIdx = p.findIndex(
        ({ id }) => configurationToUpdate.id === id,
      );
      const currentConfig = p[currentConfigIdx];
      currentConfig[key] = fn(currentConfig[key]);
      return [...p];
    });
  };

  const canDeleteAConfiguration = setting.vpnConfigurations.length > 1;
  return (
    <div className="b-flex-vg">
      {setting.vpnConfigurations.map((configuration) => (
        <Configuration
          key={configuration.id}
          configuration={configuration}
          isDisabled={isDisabled}
          isSaving={isSaving}
          validationDep={validationDep}
          onDelete={
            canDeleteAConfiguration
              ? () => deleteConfiguration(configuration)
              : null
          }
          setting={setting}
          update={update}
          updateConfiguration={updateSpecificConfiguration(configuration)}
        />
      ))}
      <div className="b-flex-justify-end">
        <Button onClick={addConfiguration} icon="plus" isDisabled={isDisabled}>
          Add VPN configuration
        </Button>
      </div>
    </div>
  );
};

export default Configurations;
