import { parse, stringify } from 'query-string';
import { StrictMode } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import featureFlags from 'src/config/feature-flags';

import { Box } from '@kandji-inc/nectar-ui';
import ThreatListErrorBoundary from './common/components/ThreatListErrorBoundary';
import ThreatListSuspense from './common/components/ThreatListSuspense';
import ThreatListV2 from './threat-events/ThreatList';
import ThreatListV3 from './threat-groups/ThreatList';

const ThreatListFFSwitch = (props: any) => {
  const { isOnDeviceRecord } = props;
  const isFeatureFlagV3On = featureFlags.getFlag('edr_041724_threats-v3');
  const isVulnFeatureFlagOn = featureFlags.getFlag('vm_06252024_vuln-poc');

  return (
    <StrictMode>
      <ThreatListErrorBoundary css={{ position: 'absolute', inset: 0 }}>
        <ThreatListSuspense>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify,
              removeDefaultsFromUrl: true,
            }}
          >
            <Box
              css={
                /* Apply styles specific to when ThreatList is within a tab */
                /* istanbul ignore next - purely styling */
                isVulnFeatureFlagOn && !isOnDeviceRecord
                  ? { padding: '$1 $5 $0' }
                  : {}
              }
            >
              {isFeatureFlagV3On && !props.forceV2 ? (
                <ThreatListV3 {...props} />
              ) : (
                <ThreatListV2 {...props} />
              )}
            </Box>
          </QueryParamProvider>
        </ThreatListSuspense>
      </ThreatListErrorBoundary>
    </StrictMode>
  );
};

export default ThreatListFFSwitch;
