import { initialize } from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';

import * as constants from '../constants';

const clientSideID = constants.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;

class FeatureFlags {
  constructor() {
    this.client = initialize(
      clientSideID,
      {
        anonymous: true, // initially use anonmyous user, but this will be changed after logging in with client.identify()
        kind: 'user',
      },
      {
        streaming: true, // client will always attempt to maintain a streaming connection for live flag updates
      },
    );
  }

  initializeClient() {
    return this.client.waitForInitialization();
  }

  /**
   * @param {String} flagName
   * @returns {any} The current value for the given flag
   */
  getFlag(flagName, defaultValue = false) {
    return this.client.variation(flagName, defaultValue);
  }

  /**
   * Sets the current user for our feature flagging solution
   * @param {Object} user User details used to tailor the feature flag values
   * @param {String} user.key: state.company.subdomain,
   * @param {String} user.company_size: state.company.company_size,
   * @param {String} user.industry: state.company.industry,
   * @param {String} user.instance_type: state.company.instance_type,
   * @param {String} user.billing_type: state.company.billing_type,
   * @param {String} user.join_date: state.company.join_date,
   */
  setUser(user) {
    this.client.identify({ ...user, kind: 'user' });
  }

  unsetUser() {
    // trigger all pending analytics calls immediately:
    this.client
      .flush()
      .then(() => {
        this.client.identify({ anonymous: true, kind: 'user' });
      })
      .catch(() => {
        this.client.identify({ anonymous: true, kind: 'user' });
      });
  }
}

// Create a **single instance** of FeatureFlags to be used throughout the application
// Note: Launch Darkly strongly suggests using a singleton instance of their SDK client so the flags
// stay in sync throughout the app.
const featureFlags = new FeatureFlags();

export const useFlags = (watchList = []) => {
  const [flags, setFlags] = useState(featureFlags.client.allFlags() || {});
  const [clientReady, setClientReady] = useState(false);

  featureFlags.client.waitUntilReady().then(() => {
    if (!clientReady) {
      setClientReady(true);
    }
  });

  useEffect(() => {
    setFlags(featureFlags.client.allFlags());
  }, [clientReady]);

  useEffect(() => {
    /**
     * @param {Object} changedFlags Mapping of only the flag that have changed. *Does not* include all flags
     */
    function handleChangedFlags(changedFlags) {
      const newFlags = { ...flags };
      const flagKeys = Object.keys(changedFlags);

      // Don't update the flags unless the one we're watching gets updated:
      if (!flagKeys.some((key) => watchList.includes(key))) {
        return;
      }

      flagKeys.forEach((featureFlagKey) => {
        newFlags[featureFlagKey] = changedFlags[featureFlagKey].current;
      });

      setFlags(newFlags);
    }

    featureFlags.client.on('change', handleChangedFlags);

    return function onUnmountUseFlags() {
      featureFlags.client.off('change', handleChangedFlags);
    };
  }, [watchList]);

  return flags;
};

export default featureFlags;
