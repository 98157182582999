import '../styles.scss';

import React, { useState } from 'react';

import { bool, func, number } from 'prop-types';

import { Flex } from '@kandji-inc/bumblebee';
import history from 'app/router/history';

import toaster from '@kandji-inc/bumblebee/lib/atoms/toaster';
import { useAdvanced, useConnector, useIs, useModal } from '../../../hooks';

import FooterButtonsLeft from './FooterButtonsLeft/FooterButtonsLeft';
import FooterButtonsRight from './FooterButtonsRight/FooterButtonsRight';

import handleDownload from './utils/handleDownload';

const FooterButtons = ({
  onClose,
  currentStepIdx,
  setCurrentStepIdx,
  nextButtonDisabled,
  showDownloadError,
  showDownloadProgress,
  noRedirectOnClose,
  deleteIntegrationOnCancel,
}) => {
  const { deleteIntegration, createIntegrationAndConnector } = useAdvanced();

  const { getInstallerDownloadLink } = useConnector();

  const { setIsWorking } = useIs();

  const { modalCreateConnector } = useModal();

  const [downloadProgress, setDownloadProgress] = useState(0);

  const nextPage = () => setCurrentStepIdx((curr) => curr + 1);

  // const previousPage = () => () => setCurrentStepIdx((curr) => (curr === 0 ? curr : curr - 1));

  /* istanbul ignore next */
  const handleFirstStep = () => nextPage();

  /* istanbul ignore next */
  const handleDownloadStep = async () => {
    setIsWorking(true);

    const download = await handleDownload(
      getInstallerDownloadLink,
      setDownloadProgress,
    );

    if (download) {
      await createIntegrationAndConnector();
    } else {
      toaster('You do not have permission to download the connector');
    }

    setIsWorking(false);

    return download;
  };

  /* istanbul ignore next */
  const handleNext = () => {
    setDownloadProgress(0); // reset the downloadProgress so when we return its at 0
    nextPage();
  };

  /* istanbul ignore next */
  const handleClose = () => {
    if (!noRedirectOnClose) {
      history.push('/integrations');
    }
    onClose();
  };

  /* istanbul ignore next */
  const handleCancel = async () => {
    // modalCreateConnector is being used as a proxy for an integration exists
    if (!modalCreateConnector && deleteIntegrationOnCancel) {
      await deleteIntegration();
    }
    onClose();
  };

  const onClickByStep = [handleFirstStep, handleDownloadStep, handleClose];

  return (
    <Flex
      className="adcs-welcome-modal_footer__wrapper"
      align="center"
      justify="space-between"
    >
      <FooterButtonsLeft
        showDownloadError={showDownloadError}
        showDownloadProgress={showDownloadProgress}
        downloadProgress={downloadProgress}
        currentStepIdx={currentStepIdx}
        setCurrentStepIdx={setCurrentStepIdx}
      />
      <FooterButtonsRight
        onClose={handleCancel}
        downloadProgress={downloadProgress}
        nextButtonDisabled={nextButtonDisabled}
        onClickByStep={onClickByStep}
        currentStepIdx={currentStepIdx}
        handleNext={handleNext}
      />
    </Flex>
  );
};

FooterButtons.propTypes = {
  onClose: func.isRequired,
  currentStepIdx: number.isRequired,
  setCurrentStepIdx: func.isRequired,
  nextButtonDisabled: bool.isRequired,
};

export default FooterButtons;
