import { Chip, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import {
  ACTIVATION_LOCK_OPTIONS,
  ACTIVATION_LOCK_OPTION_ITEM,
} from '../activation-lock';

const ActivationLockRow = ({ setting, update, isDisabled }) => (
  <Setting.Row>
    <div>
      <p className="b-txt">Activation Lock</p>
    </div>
    <Setting.Helpers>
      <p className="b-txt-light">
        Specify if users should be allowed to enable Activation Lock using Find
        My and a personal Apple Account.&nbsp;
        <a
          href="https://support.kandji.io/support/solutions/articles/72000558685"
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          Learn More...
        </a>
      </p>
      <div className="b-flex-wrap-gtiny">
        <Chip
          kind="tertiary-light"
          text="macOS 10.15+"
          className="k-ade-chip--m0"
        />
      </div>
    </Setting.Helpers>
    <div>
      <Select
        isDisabled={isDisabled}
        options={ACTIVATION_LOCK_OPTIONS}
        compact
        onChange={(v) =>
          update(
            'userActivationLockAllowed',
            v.value === ACTIVATION_LOCK_OPTION_ITEM.ALLOW.value,
          )
        }
        value={
          setting.userActivationLockAllowed
            ? ACTIVATION_LOCK_OPTION_ITEM.ALLOW
            : ACTIVATION_LOCK_OPTION_ITEM.DISALLOW
        }
      />
    </div>
  </Setting.Row>
);

export default ActivationLockRow;
