import { Badge, Flex, Icon, Text, Tooltip } from '@kandji-inc/nectar-ui';
import iosDevicesLogo from '../../../../components-for-specific-types/ms-device-compliance/assets/ios-devices.svg';
import macosDevicesLogo from '../../../../components-for-specific-types/ms-device-compliance/assets/mac-os-devices.svg';
import useGetIntegrationUserGroupList from '../../../../components-for-specific-types/ms-device-compliance/hooks/use-get-integration-user-group-list';

import type { IntegrationDetails } from '../../types/schema';

import type { DEVICE_PLATFORMS } from '../details-integration/details-integration';
type GroupsOptions = { value: string; label: string }[];

const ViewUserGroups = ({
  devicePlatform,
  integrationDetails,
}: {
  devicePlatform: DEVICE_PLATFORMS;
  integrationDetails: IntegrationDetails;
}) => {
  const getConfiguredUserGroups = (groupIds: string[] | []) => {
    // * get UserGroup list by ids from main-be service *
    const { data: groupList } = useGetIntegrationUserGroupList('', groupIds);

    const groupOptions = groupList?.data?.results.map((group) => ({
      label: group.name,
      value: group.external_id,
    }));

    const uniqueGroupOptions = groupOptions?.reduce((a, current) => {
      const isDuplicate = a.find((option) => option.value === current.value);
      if (!isDuplicate) a.push(current);
      return a;
    }, [] as GroupsOptions);

    return uniqueGroupOptions || [];
  };

  const getUserGroupTooltipContent = (groups: GroupsOptions) => {
    // won't work in IE
    const copyToClipboard = (text: string) =>
      navigator.clipboard.writeText(text);

    return (
      <Tooltip
        content={
          <Flex flow="column" p1>
            {groups.map((group) => {
              return (
                <Flex key={group.value} gap="xs">
                  {group.label}
                  <Icon
                    name="copy"
                    size="sm"
                    onClick={() =>
                      copyToClipboard(`${group.label} (${group.value})`)
                    }
                  />
                </Flex>
              );
            })}
          </Flex>
        }
        theme="light"
        interactive
        side="bottom"
      >
        <Badge>+{groups.length}</Badge>
      </Tooltip>
    );
  };

  const displayUserGroupsByPlatform = (platform: DEVICE_PLATFORMS) => {
    const maxGroups = 2;
    /* istanbul ignore next */
    const platformGroupIds =
      integrationDetails[`${platform}_entra_group_ids`] || [];
    const groups = getConfiguredUserGroups(platformGroupIds);

    const list = groups.slice(0, maxGroups).map((group, i) => (
      <Tooltip
        content={`${group.label} (${group.value})`}
        side="top"
        closeOnOutsideClick={true}
        trigger="hover"
        interactive
        key={group.value}
      >
        <Text>
          {group.label} {i < groups.length - 1 ? ',' : ''}
        </Text>
      </Tooltip>
    ));

    if (groups.length > maxGroups) {
      list.push(getUserGroupTooltipContent(groups.slice(2)));
    }

    return <Flex gap="sm">{list}</Flex>;
  };

  return (
    <Flex gap="lg" p5>
      <img
        src={devicePlatform === 'ios' ? iosDevicesLogo : macosDevicesLogo}
        width="64"
        height="64"
        alt={`${devicePlatform} icon`}
      />

      <Flex flow="column" gap="xs">
        <Text variant="description">User groups</Text>
        {displayUserGroupsByPlatform(devicePlatform)}
      </Flex>
    </Flex>
  );
};

export default ViewUserGroups;
