import type { ChangeEvent, FocusEventHandler } from 'react';
import React, { memo, useState } from 'react';

import { Toaster } from '@kandji-inc/bumblebee';
import {
  Button,
  Dialog,
  Flex,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';

import type { Blueprint } from 'src/features/blueprint-flow/blueprint-flow.types';

const EditAssignmentMap = (props: {
  blueprint: Blueprint;
  onEdit: (
    name: Blueprint['name'],
    description: Blueprint['description'],
  ) => void;
  onClose: () => void;
}) => {
  const { blueprint, onEdit, onClose } = props;

  const { name: initialName, description: initialDescription } = blueprint;
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [hasFocusedName, setHasFocusedName] = useState(false);

  const MAX_DESC = 256;

  const isSaveDisabled = !name || description.length > MAX_DESC;

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const handleDescriptionChange = (e: any) => setDescription(e.target.value);

  const focusName: FocusEventHandler<HTMLInputElement> = (e) => {
    /* istanbul ignore next */
    if (!hasFocusedName) {
      e.target.select();
      setHasFocusedName(true);
    }
  };

  const onCancel = () => {
    onClose();
    setName(initialName);
    setDescription(initialDescription);
    setHasFocusedName(false);
  };

  const onSave = () => {
    if (description.length > MAX_DESC) {
      return;
    }

    const sanitizedName = name.trim();
    Promise.resolve(onEdit(sanitizedName, description))
      .then(onClose)
      .catch(() => Toaster('Failed to save changes.'));
  };

  const content = (
    <Flex flow="column" gap="lg">
      <TextField
        label="Blueprint name"
        autoFocus
        onFocus={focusName}
        value={name}
        onChange={handleNameChange}
        data-testid="bp-name"
      />

      <TextArea
        label="Description"
        isOptional
        minHeight="var(--sizes-10)"
        maxHeight="var(--sizes-10)"
        // @ts-expect-error -- `style` is not a formally recognized prop of `TextArea`
        style={{ overflow: 'scroll' }}
        placeholder="Enter a description"
        value={description}
        onChange={handleDescriptionChange}
        hint={{ charCounter: { count: description.length, max: MAX_DESC } }}
        data-testid="desc"
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button compact variant="subtle" onClick={onCancel}>
        Cancel
      </Button>

      <Button
        compact
        variant="primary"
        disabled={isSaveDisabled}
        onClick={onSave}
      >
        Confirm
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title="Edit details"
      content={content}
      footer={footer}
      isOpen
      onOpenChange={onCancel}
      css={{ width: '560px', zIndex: 2000 }}
    />
  );
};

export default memo(EditAssignmentMap);
