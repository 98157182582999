import { Button, modal as Modal } from '@kandji-inc/bumblebee';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function RequiredModal({ isOpen, onClose }: Props) {
  const parts = {
    header: { text: 'Selection needed to save this Library Item' },
    content: {
      children: (
        <>
          <p>
            To save this Library Item, you must select at least one of the
            following to manage:
          </p>
          <ul>
            <li>External volumes</li>
            <li>Disk images</li>
            <li>Server volumes</li>
          </ul>
        </>
      ),
    },
    footer: {
      children: (
        <Button onClick={onClose} className="b-ml">
          Okay
        </Button>
      ),
    },
  };

  return <Modal isOpen={isOpen} onClose={onClose} parts={parts} />;
}
