import './blueprint-conflicts-modal.scss';

import BlueprintConflictsModal from './blueprint-conflicts-modal';
import useBlueprintConflicts from './use-blueprint-conflicts';

/**
 * Modules for checking blueprint conflicts in library items.
 * `BlueprintConflictsModal` provides the UI for user to resolve conflicting
 * blueprints and `useBlueprintConflicts` hook encapsulates actions for calling
 * API for conflict checking and selecting blueprints in
 * `BlueprintConflictsModal`.  The `useBlueprintConflicts` hook should be
 * called in a parent component of `BlueprintConflictsModal` and pass its
 * values to `BlueprintConflictsModal` to set it visible and provide actions
 * for conflict resolution.
 */

export { BlueprintConflictsModal, useBlueprintConflicts };
