/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type {
  SelectedVulnerability,
  Vulnerability,
  VulnerableDevice,
} from '../vulnerability.types';

const useGetVulnerability = (vulnerabilityId: string) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerability', vulnerabilityId],
    queryFn: () =>
      vulnerabilityApi(`vulnerabilities/${vulnerabilityId}`).get({}),
  });

  const data: {
    cve_info: Vulnerability;
    devices: VulnerableDevice[];
    total: number;
    page: number;
    size: number;
  } = apiRes?.data;

  return { data, isLoading };
};

export { useGetVulnerability };
