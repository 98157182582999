import { Button, Icon, kmodal as Kmodal } from '@kandji-inc/bumblebee';
import React from 'react';

const DeleteModal = (props) => {
  const { setIsShow, remove, isDeleting = false } = props;
  return (
    <Kmodal>
      <a
        href=""
        className="decorate-off kb-modal-close"
        onClick={(e) => {
          e.preventDefault();
          setIsShow(false);
        }}
      >
        <Icon name="xmark" />
      </a>
      <div className="kb-modal-top">
        <div className="kb-modal-header">
          <h2 className="b-h2 b-mb">Delete custom logo</h2>
        </div>
        <div className="kb-modal-body">
          <div className="b-txt b-mb3">
            Are you sure you want to delete your organization’s logo? The Kandji
            logo will be used during setup by default if you do not upload a
            custom logo for your organization.
          </div>
        </div>
      </div>
      <div className="kb-modal-footer b-flex-g">
        <Button
          disabled={isDeleting}
          kind="outline"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          theme="error"
          onClick={remove}
          iconPlacement="right"
          isProgress={isDeleting}
          icon={isDeleting ? 'arrows-rotate' : ''}
        >
          Delete
        </Button>
      </div>
    </Kmodal>
  );
};

export default DeleteModal;
