import { devicesData } from 'features/library-items/data-service/library-item/devices';
import WifiService from 'features/library-items/items/wifi/service/wifi-service';

export const qosOptions = [
  {
    label: 'Disable Fast Lane for all apps',
    value: WifiService.qosValues.DISABLE,
  },
  { label: 'Allow specific apps', value: WifiService.qosValues.ALLOW },
];

export const appsTypes = {
  VPP: 'vpp-app',
  CUSTOM: 'custom',
};

const tagsKeysMap = Object.values(devicesData).map((v) => ({
  key: v.commonKey,
  label: v.label,
}));

export const getTags = (item) => {
  const tags = [];
  tagsKeysMap.forEach((tagKeys) => {
    if (item[tagKeys.key]) {
      tags.push(tagKeys.label);
    }
  });
  return tags.filter(Boolean);
};

export const addAppModes = {
  SEARCH: 'search',
  CUSTOM: 'custom',
};
