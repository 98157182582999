import React, { useState, useEffect } from 'react';

import { Banner, Button, Checkbox, Flex } from '@kandji-inc/bumblebee';

import { STATUS_PENDING } from 'features/integrations/components-for-specific-types/adcs/status';
import { Controls } from 'features/library-items/template';
import iconConnector from '../../assets/adcs-connector-small.svg';
import iconIntegration from '../../assets/adcs.svg';
import iconServer from '../../assets/server-icon-small.svg';

import {
  useAdvanced,
  useAuthority,
  useConnector,
  useIs,
  useModal,
  usePanel,
} from '../../hooks';

import Panel from '..';
import Header from '../header';
import Item from '../item';

const PanelDeleteIntegration = () => {
  const { deleteIntegration } = useAdvanced();

  const {
    authorityList,
    authorityListLibraryItemList,
    clearAuthorityListLibraryItemList,
    fetchAuthorityList,
    fetchAuthorityListLibraryItemList,
  } = useAuthority();

  const { connectorList, fetchConnectorList } = useConnector();

  const { isLoading, isWorking, hasChecked, setHasChecked } = useIs();
  const { openModal } = useModal();
  const { panel, openPanel, closePanel } = usePanel();

  const isOpen = !!panel['delete-integration'];

  const [agreed, setAgreed] = useState(false);

  /* istanbul ignore next */
  const handleAgreed = () => setAgreed(!agreed);

  /* istanbul ignore next */
  const reset = () => {
    clearAuthorityListLibraryItemList();
    setAgreed(false);
    setHasChecked(false);
  };

  /* istanbul ignore next */
  const openModalDeleteIntegration = () => {
    closePanel(() => openModal('delete-integration'));
  };

  /* istanbul ignore next */
  const openPanelHandleConflictLibraryItem = () => {
    closePanel(() => openPanel('handle-conflict-library-item'));
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closePanel(reset);
  };

  /* istanbul ignore next */
  const onDelete = async () => {
    await deleteIntegration();
    closePanel(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen) {
      fetchAuthorityList();
      fetchAuthorityListLibraryItemList();
      fetchConnectorList();
    }
  }, [isOpen]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && authorityListLibraryItemList?.length > 0) {
      openPanelHandleConflictLibraryItem();
    } else if (
      isOpen &&
      authorityListLibraryItemList?.length === 0 &&
      authorityList?.length === 0 &&
      (connectorList?.length === 0 ||
        (connectorList?.length === 1 &&
          connectorList[0]?.status === STATUS_PENDING))
    ) {
      openModalDeleteIntegration();
    }
  }, [isOpen, authorityList, authorityListLibraryItemList, connectorList]);

  /* istanbul ignore next */
  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  /* istanbul ignore next */
  const loopConnector = connectorList?.length > 0 && (
    <div className="k-section-primary k-section-border-top">
      <h4 className="b-h4 b-mb1">The following connectors will be deleted:</h4>
      {connectorList?.map(
        /* istanbul ignore next */
        ({ id, name, bound_domain }) => (
          <Item key={id} icon={iconConnector}>
            {name || bound_domain || 'Connector details are pending...'}
          </Item>
        ),
      )}
    </div>
  );

  /* istanbul ignore next */
  const loopServer = authorityList?.length > 0 && (
    <div className="k-section-primary k-section-border-top">
      <h4 className="b-h4 b-mb1">The following servers will be deleted:</h4>
      {authorityList?.map(
        /* istanbul ignore next */
        ({ id, name }) => (
          <Item key={id} icon={iconServer}>
            {name}
          </Item>
        ),
      )}
    </div>
  );

  /* istanbul ignore next */
  return hasChecked &&
    authorityListLibraryItemList?.length === 0 &&
    (authorityList?.length > 0 || connectorList?.length > 0) ? (
    <Panel name="delete-integration">
      <Header title="Delete AD CS integration" icon={iconIntegration} />

      <div className="b-side-panel-layout__body hubspot-buffer-bottom">
        <div className="k-section-primary">
          <h4 className="b-h4 b-mb1">
            Are you sure you want to delete this integration?
          </h4>

          <p className="b-txt">
            The integration with Active Directory Certificate Services will be
            permanently removed. This action cannot be undone.
          </p>

          <Banner
            className="b-mt"
            theme="warning"
            kind="block"
            icon="octagon-exclamation"
          >
            <p>
              Deleting this integration will permanently remove all connectors
              and servers that currently exist for the integration.
            </p>
          </Banner>
        </div>

        {loopConnector}

        {loopServer}

        <div className="k-section-primary k-section-border-top">
          <Controls>
            <Checkbox
              className="k-adcs-agreed"
              label="I have read the above information and understand this action cannot be undone."
              checked={agreed}
              onChange={handleAgreed}
              disabled={isLoading || isWorking}
            />
          </Controls>
        </div>
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="flex-end">
          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onCancel}
              isDisabled={isLoading || isWorking}
            >
              Cancel
            </Button>

            <Button
              theme="error"
              icon={isWorking ? 'arrows-rotate' : undefined}
              onClick={
                /* istanbul ignore next */
                agreed ? onDelete : () => {}
              }
              isDisabled={isLoading || isWorking || !agreed}
              isProgress={isWorking}
            >
              Delete
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  ) : null;
};

export default PanelDeleteIntegration;
