/* istanbul ignore file */
import {
  Checkbox,
  Uploader,
  fileToBase64,
  updateProgressPercent,
  useInputsValidators,
  useIsChanged,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useMemo, useState } from 'react';

import { Setting } from 'features/library-items/template';

import certPng from '../../protocols-card/identity-certificate-row/configure-pkcs/cert.png';
import EthernetService from '../../service/ethernet-service';

const keys = EthernetService.keys.trust;
const fieldsToValidate = [keys.certificates];

const getTrustedCertType = (fileName = '') => {
  const pkcs1Types = ['.cer', '.crt', '.der'];
  const pkcs12Types = ['.p12', '.pfx'];

  const isPkcs1Type = pkcs1Types.some((type) => fileName.endsWith(type));
  if (isPkcs1Type) {
    return 'pkcs1';
  }
  const isPkcs12Type = pkcs12Types.some((type) => fileName.endsWith(type));
  if (isPkcs12Type) {
    return 'pkcs12';
  }

  return Promise.reject(
    new Error('Failed to determine trusted certificate type from file.'),
  );
};

const TrustedCertificates = ({
  setting,
  isDisabled,
  update,
  validationDep,
}) => {
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );

  const [uploadCancelFn, setUploadCancelFn] = useState(null);
  const specifyCertificates = setting[keys.specifyCertificates];
  const isSubmitted = useIsChanged(validationDep, specifyCertificates);

  useEffect(() => {
    if (specifyCertificates) {
      onInvalidate(keys.certificates)(
        !setting[keys.certificates]?.length && 'Required',
      );
    } else {
      onInvalidate(keys.certificates)(false);
    }
  }, [setting[keys.certificates], specifyCertificates]);

  const forceWithFile = useMemo(() => {
    const files = setting[keys.certificates];
    return files?.length ? files.map((f) => ({ file: f })) : null;
  }, [setting[keys.certificates]]);

  return (
    <Setting.Row testId="trusted-certs">
      <Setting.Controls>
        <Checkbox
          checked={setting[keys.specifyCertificates]}
          onChange={() => update(keys.specifyCertificates, (p) => !p)}
          disabled={isDisabled}
          label="Specify trusted certificates"
        />
      </Setting.Controls>
      {specifyCertificates && (
        <Setting.SecondaryControls>
          <Setting.SecondaryControlsRow className="--no-grid">
            <div ref={refs[keys.certificates]} />
            <Uploader
              isMulti
              withError={
                (isSubmitted && invalidations[keys.certificates]) || ''
              }
              onUpload={async (file, updateProgress) => {
                const base64 = await fileToBase64(file, {
                  onReaderLoadStart: ({ reader }) =>
                    setUploadCancelFn(() => () => reader.abort()),
                  onReaderLoad: ({ resolveParsedBase64 }) => {
                    updateProgressPercent(updateProgress, {
                      onDone: resolveParsedBase64,
                    });
                  },
                });

                return Promise.resolve({ base64 });
              }}
              onUploaded={(file, { data }) => {
                const { base64 } = data;
                const type = getTrustedCertType(file.name);
                update(keys.certificates, (p) => [
                  ...p,
                  {
                    name: file.name,
                    size: file.size,
                    data: base64,
                    type,
                  },
                ]);
              }}
              onError={(error) => {
                if (error) {
                  onInvalidate(keys.certificates)(true);
                }
              }}
              onCancel={uploadCancelFn}
              onDelete={(_file, { error, fileIdx }) => {
                update(keys.certificates, (p) => {
                  const removeFileIdx = error?.invalidFile?.idx ?? fileIdx;
                  return p.filter((_f, idx) => idx !== removeFileIdx);
                });
                setUploadCancelFn(null);
              }}
              allowedTypes={['.cer', '.crt' /* '.der', '.p12', '.pfx' */]}
              isDisabled={isDisabled}
              uploadInstructions={
                <span>
                  Drag file here or{' '}
                  <span className="b-alink">click to upload</span>
                </span>
              }
              maxFileSize={5e9}
              forceWithFile={forceWithFile}
              fileIcons={{
                '.p12': (
                  <img
                    src={certPng}
                    alt=".p12 Certificate"
                    style={{ objectFit: 'contain' }}
                  />
                ),
                '.crt': (
                  <img
                    src={certPng}
                    alt=".crt Certificate"
                    style={{ objectFit: 'contain' }}
                  />
                ),
                '.cer': (
                  <img
                    src={certPng}
                    alt=".cer Certificate"
                    style={{ objectFit: 'contain' }}
                  />
                ),
                '.der': (
                  <img
                    src={certPng}
                    alt=".der Certificate"
                    style={{ objectFit: 'contain' }}
                  />
                ),
                '.pfx': (
                  <img
                    src={certPng}
                    alt=".pfx Certificate"
                    style={{ objectFit: 'contain' }}
                  />
                ),
              }}
            />
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default TrustedCertificates;
