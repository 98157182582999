import type { DetectionDateFilterFields } from '../../../threat.types';
import isoToDateString from './isoToDateString';

const OPERATORS = {
  GTE: 'gte',
  LTE: 'lte',
  EQ: 'eq',
  IB: 'ib',
};

const FALLBACK_DETECTION_PERIOD_MESSAGE = 'During the selected period';
const PRESENT_PERFECT_SIMPLE_TENSE = 'there have not been';
const SIMPLE_PAST_TENSE = 'there were no';

const getMessage = (period: string, tense: string): string =>
  `${period}, ${tense}`;

type OperatorMap = {
  [key: string]: (
    date: string | undefined,
    from: string | undefined,
    to: string | undefined,
  ) => string;
};

const getDisplayPeriod = (
  detectionDate: DetectionDateFilterFields,
  timezone?: string,
): string => {
  const { value, operator, selectedDate, dateRangeFrom, dateRangeTo } =
    detectionDate;
  const operatorMap: OperatorMap = {
    [OPERATORS.GTE]: (date) => `Since ${date}`,
    [OPERATORS.LTE]: (date) => `Until ${date}`,
    [OPERATORS.EQ]: (date) => `On ${date}`,
    [OPERATORS.IB]: (_, from, to) => `Between ${from} and ${to}`,
  };

  if (value === 'custom_date_range') {
    const when = selectedDate && isoToDateString(selectedDate, timezone);
    const from = dateRangeFrom && isoToDateString(dateRangeFrom, timezone);
    const to = dateRangeTo && isoToDateString(dateRangeTo, timezone);

    if (!operator || !(operator in operatorMap)) {
      throw new Error('Operator is required for custom date range');
    }

    const selectedOperator = operatorMap[operator];
    // istanbul ignore next -- invalid operator is not possible
    if (!selectedOperator) {
      throw new Error('Invalid operator');
    }
    return selectedOperator(when, from, to);
  }

  if (value?.startsWith('last_')) {
    const days = value.split('_')[1];
    const period = value.split('_')[2];
    return `In the last ${days} ${period}`;
  }
  return FALLBACK_DETECTION_PERIOD_MESSAGE;
};

const getDisplayTense = (detectionDate: DetectionDateFilterFields): string => {
  if ([OPERATORS.EQ, OPERATORS.IB].includes(detectionDate.operator || '')) {
    return SIMPLE_PAST_TENSE;
  }
  return PRESENT_PERFECT_SIMPLE_TENSE;
};

const getDisplayDetectionPeriod = (
  detectionDate: DetectionDateFilterFields | undefined,
  timezone?: string,
): string => {
  try {
    if (!detectionDate) {
      throw new Error('Detection date filter fields are required');
    }

    const period = getDisplayPeriod(detectionDate, timezone);
    const tense = getDisplayTense(detectionDate);

    return getMessage(period, tense);
  } catch (error) {
    return getMessage(
      FALLBACK_DETECTION_PERIOD_MESSAGE,
      PRESENT_PERFECT_SIMPLE_TENSE,
    );
  }
};

export default getDisplayDetectionPeriod;
