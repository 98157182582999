import {
  Box,
  Button,
  Flex,
  Heading,
  Loader,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useState, useEffect, useRef } from 'react';

import useWhiteBg from 'features/util/hooks/use-white-bg';
import AddLibraryItem from './AddLibraryItem';
import LibraryList from './LibraryList';
import EmptyLibraryList from './LibraryList/EmptyLibraryList';
import { useGetLibraryItems } from './api/useGetLibraryItems';
import LibraryOld from './library';

const Library = () => {
  const [useOldLibrary, setUseOldLibrary] = useState<boolean>(false);
  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

  useWhiteBg();

  const {
    isFetching,
    isPlaceholderData,
    data: libraryItemsData,
    fetchNextPage,
    fetchPreviousPage,
    isFetched,
    isFetchingNextPage,
    isFetchingPreviousPage,
    hasNextPage,
    hasPreviousPage,
    ...rest
  } = useGetLibraryItems();

  useEffect(() => {
    if (useOldLibrary) {
      document.body.classList.remove('white-bg');
    }
  }, [useOldLibrary]);

  useEffect(() => {
    const loadMoreButtonEl = loadMoreButtonRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 },
    );

    if (loadMoreButtonEl) {
      observer.observe(loadMoreButtonEl);
    }

    return () => {
      if (loadMoreButtonEl) {
        observer.unobserve(loadMoreButtonEl);
      }
    };
  }, [isFetched, fetchNextPage]);

  if (useOldLibrary) {
    return <LibraryOld />;
  }

  return (
    <div>
      <Box my2>
        <Button
          compact
          type="button"
          icon={{
            name: 'backward',
            position: 'left',
          }}
          onClick={() => setUseOldLibrary(!useOldLibrary)}
        >
          old library
        </Button>
      </Box>
      <Flex justifyContent="space-between" py5>
        <Heading size="2">Library</Heading>
        {libraryItemsData.pages.length > 0 && <AddLibraryItem />}
      </Flex>
      <div>
        {isFetching && isPlaceholderData && (
          <Flex
            flex="1"
            flow="column"
            alignItems="center"
            justifyContent="center"
          >
            <Loader size="xl" label="Loading Library Items" />
          </Flex>
        )}

        <Flex flow="column" gap="md">
          {!isFetching && libraryItemsData.pages.length < 1 && (
            <EmptyLibraryList />
          )}
          {libraryItemsData.pages.length > 0 && (
            <>
              <TooltipProvider>
                <LibraryList items={libraryItemsData} />
              </TooltipProvider>
              {hasNextPage && (
                <Button
                  ref={loadMoreButtonRef}
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                  css={{
                    alignSelf: 'center',
                  }}
                >
                  {isFetchingNextPage ? 'Loading...' : 'Load more'}
                </Button>
              )}
            </>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default Library;
