import { Auth0Client } from '@auth0/auth0-spa-js';

import Auth0Context from './context';
import Auth0Provider from './provider';
import useAuth0 from './useAuth0';
import useAuthenticationRequired from './useAuthenticationRequired';
import withAuthenticationRequired from './withAuthenticationRequired';

const internals = {
  auth0Client: null,
};

const toAuth0ClientOptions = (opts) => {
  const {
    clientId,
    organizationId,
    connectionName,
    redirectUri,
    maxAge,
    ...validOpts
  } = opts;

  return {
    ...validOpts,
    client_id: clientId,
    redirect_uri: redirectUri,
    useRefreshTokens: true,
    prompt: 'login',
    ...(connectionName && { connection: connectionName }),
    ...(organizationId && { organization: organizationId }),
  };
};

export const createAuth0Client = (clientOpts) => {
  if (!internals.auth0Client) {
    internals.auth0Client = new Auth0Client(toAuth0ClientOptions(clientOpts));
  }
  return internals.auth0Client;
};

export const getAuth0Client = () => internals.auth0Client;

export { OAuthError } from './errors';
export {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
  useAuthenticationRequired,
  Auth0Context,
};
