/* istanbul ignore file */
import { logout } from 'app/_actions/account';
import { store } from 'app/_store/rootStore';
import {
  getAgentLogsApi,
  getApiGatewayBaseUrl,
  getBackend,
  getDataPlatformApi,
  getIntegrationsBackendApi,
  getIntegrationsDeviceTrustApi,
  getSubscriptionsApi,
  getVisibilityApi,
  getVulnerabilityApi,
} from 'app/api/domains';
import { BACKGROUND_REFRESH_URLS } from 'app/api/urls';
import axiosBase from 'axios';
import { getAuthHeader } from './utils';

const BACKGROUND_QUERY_INACTIVITY_TIME = 55 * 1000; // 55 seconds

// activity tracking
let _lastActivity = Date.now();
export const getMillisecondsInactive = () => Date.now() - _lastActivity;
export const resetActivity = () => {
  _lastActivity = Date.now();
};

export const publicRequest =
  (client) =>
  async ({ headers: optHeaders, ...opts }) =>
    client({
      ...opts,
      headers: {
        ...optHeaders,
      },
    });

export const authenticatedRequest =
  (client) =>
  async ({ headers: optHeaders, ...opts }) => {
    const authHeaders = await getAuthHeader();
    return client({
      ...opts,
      headers: {
        ...authHeaders,
        ...optHeaders,
      },
    });
  };

const trackedClient = (client, args) => {
  const timeInactive = getMillisecondsInactive();

  if (BACKGROUND_REFRESH_URLS.has(args.url)) {
    // return null for refresh data when inactive
    if (document.hidden || timeInactive >= BACKGROUND_QUERY_INACTIVITY_TIME) {
      return Promise.resolve(null);
    }
  } else {
    resetActivity();
  }

  return client(args);
};

const apiFactory = (client) => (url) => ({
  get: (params, options = {}) =>
    trackedClient(client, { method: 'GET', url, params, ...options }),
  post: (data, options = {}) =>
    trackedClient(client, { method: 'POST', url, data, ...options }),
  patch: (data, options = {}) =>
    trackedClient(client, { method: 'PATCH', url, data, ...options }),
  put: (data, options = {}) =>
    trackedClient(client, { method: 'PUT', url, data, ...options }),
  delete: (data, options = {}) =>
    trackedClient(client, { method: 'DELETE', url, data, ...options }),
});

const backendApi = (baseUrl, isAuthenticated = true, logoutOn401 = false) => {
  const client = axiosBase.create({
    baseURL: baseUrl,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (logoutOn401) {
    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          store.dispatch(logout());
        }
        return Promise.reject(error);
      },
    );
  }
  return isAuthenticated
    ? apiFactory(authenticatedRequest(client))
    : apiFactory(publicRequest(client));
};

const backendDomain = getBackend();
const subscriptionsApiDomain = getSubscriptionsApi();
const apiGatewayDomain = getApiGatewayBaseUrl();
const visibilityDomain = getVisibilityApi();
const integrationsDomain = getIntegrationsBackendApi();
const integrationsDeviceTrustDomain = getIntegrationsDeviceTrustApi();
const dataPlatformdomain = getDataPlatformApi();
const vulnerabilityDomain = getVulnerabilityApi();
const agentLogsDomain = getAgentLogsApi();

//
// main backend (backendDomain)
//

export const rootApi = backendApi(backendDomain); // no trailing slash
export const publicApi = backendApi(`${backendDomain}app/v1/`, false);
export const api = backendApi(`${backendDomain}app/v1/`, true, true);
export const authApi = backendApi(`${backendDomain}api/v1/`);
export const libraryAxios = backendApi(
  `${backendDomain}library/v1/`,
  true,
  true,
);
export const integrationsApi = backendApi(`${backendDomain}integrations/v1/`);
export const enterpriseApi = backendApi(`${backendDomain}enterprise-api/v1/`);
export const enterpriseExternalApi = backendApi(`${backendDomain}api/v1/`);
export const rulesApi = backendApi(`${backendDomain}rules/v1/`);
export const threatApi = backendApi(`${backendDomain}threat/v1`); // no trailing slash
export const threatApiV2 = backendApi(`${backendDomain}threat/v2`); // no trailing slash
export const newLibraryAxios = backendApi(
  `${backendDomain}api/v1/library_items/`,
);
export const newBlueprintAxios = backendApi(
  `${backendDomain}api/v1/blueprints/`,
);
export const newIntegrationsAxios = backendApi(`${backendDomain}integrations/`);

//
// other domains
//

export const threatPublicApi = backendApi(`${apiGatewayDomain}threat/api/v1`);
export const threatPublicApiV2 = backendApi(`${apiGatewayDomain}threat/api/v2`);
export const visibilityApi = backendApi(visibilityDomain);
export const subscriptionsApi = backendApi(subscriptionsApiDomain);
export const integrationsBackendAxios = backendApi(integrationsDomain);
export const integrationsDeviceTrustAxios = backendApi(
  integrationsDeviceTrustDomain,
);
export const dataPlatformApi = backendApi(dataPlatformdomain);
export const agentLogsApi = backendApi(agentLogsDomain);
export const vulnerabilityApi = backendApi(vulnerabilityDomain);
