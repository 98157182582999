import { TableWithFilters } from '@kandji-inc/bumblebee';
import { DateTime } from 'luxon';
import {
  arrayOf,
  bool,
  elementType,
  func,
  number,
  object,
  shape,
  string,
} from 'prop-types';
import React, { memo } from 'react';
import './activity-table.scss';
import {
  ActivityCollapsableSearch,
  ActivityDateRangeSelect,
  ActivityTypeSelect,
} from './filter-parts';
import { SERVER_DATE_FORMAT, serverPaginatedFetcher } from './util';

function ActivityTable({
  fetcher,
  serverSidePagination,
  columns,
  ExpandedComponent,
  typesObject,
  transformers,
  title,
}) {
  const tableFetcher = serverSidePagination
    ? serverPaginatedFetcher(fetcher)
    : fetcher;

  return (
    <div className="activity-table__wrapper">
      <TableWithFilters
        title={title}
        fetcher={tableFetcher}
        columns={columns}
        ExpandedComponent={ExpandedComponent}
        cache={{ invalidateTimeout: 60000 }}
        pagination={{ fixSizePerPage: false }}
        filters={[
          {
            name: 'search',
            throttle: 1000,
            // TODO:
            //  Component: CollapsableSearchInput (from bumblebee), componentProps: { className: 'activity-table__filters_gap' }
            //  componentProps not handled properly right now
            Component: ActivityCollapsableSearch,
            slot: TableWithFilters.Filters.filterSlots.RIGHT,
          },
          {
            name: 'types',
            Component: ActivityTypeSelect(typesObject),
            slot: TableWithFilters.Filters.filterSlots.LEFT,
            transform: transformers.types,
            initialValue: [],
          },
          {
            name: 'dateRange',
            Component: ActivityDateRangeSelect,
            initialValue: { from: undefined, to: undefined },
            slot: TableWithFilters.Filters.filterSlots.LEFT,
            transform: (val) => transformers.dateRange(val, SERVER_DATE_FORMAT),
          },
        ]}
      />
    </div>
  );
}

ActivityTable.propTypes = {
  fetcher: func.isRequired,
  columns: arrayOf(
    shape({
      isSortable: bool,
      className: string,
      fieldName: string.isRequired,
      header: string,
      ratio: number,
      HeaderCell: elementType,
      Cell: elementType,
    }),
  ).isRequired,
  typesObject: object.isRequired,

  ExpandedComponent: func,
  serverSidePagination: bool,
  transformers: shape({
    types: func,
    dateRange: func,
  }),
  title: string,
};

ActivityTable.defaultProps = {
  ExpandedComponent: null,
  serverSidePagination: true,
  transformers: {
    types: (types) => ({ type__in: types.join(',') }),
    dateRange: ({ from, to }, serverFormat) =>
      from && to
        ? {
            created_at_before: DateTime.fromJSDate(to).toFormat(serverFormat),
            created_at_after: DateTime.fromJSDate(from).toFormat(serverFormat),
          }
        : {},
  },
  title: null,
};

const memoTable = memo(ActivityTable);
memoTable.SERVER_DATE_FORMAT = SERVER_DATE_FORMAT;

export default memoTable;
