import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import React from 'react';

type Props = {
  size?: 'sm' | 'md' | 'lg';
  isOpen?: boolean;
  title: string;
  content?: JSX.Element;
  nextText?: string;
  nextClick: () => void;
  backText?: string;
  backClick: () => void;
  isProcessing: boolean;
};

const dialogSize = {
  sm: { width: '480px', height: '360px' },
  md: { width: '640px', height: '480px' },
  lg: { width: '800px', height: '600px' },
};

const ActionDialog = ({
  size = 'sm',
  isOpen = true,
  title,
  content,
  nextText,
  nextClick,
  backText,
  backClick,
  isProcessing,
}: Props) => (
  <Dialog
    isOpen={isOpen}
    onOpenChange={backClick}
    title={title}
    content={
      <Flex flow="column" gap="sm">
        {content}
      </Flex>
    }
    footer={
      <Flex justifyContent="end" gap="sm">
        <Button variant="subtle" onClick={backClick} data-testid="btn:back">
          {backText || 'Back'}
        </Button>
        <Button
          variant="danger"
          onClick={nextClick}
          data-testid="btn:next"
          disabled={isProcessing}
        >
          {nextText || 'Exit'}
        </Button>
      </Flex>
    }
    css={dialogSize[size]}
  />
);

export default ActionDialog;
