import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Flex,
  Grid,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import type React from 'react';
import { useEffect } from 'react';
import { v4 } from 'uuid';

import { Setting } from 'features/library-items/template';
import type { UpdateFn } from '../config/common';
import type { CheckboxRowProps } from './CheckboxRow';
import CheckboxRow from './CheckboxRow';

const ControlsRow = styled(Setting.Controls, {
  // Do this since we don't need helpers from the controls setup.
  gridColumn: '1 / -1 !important',
});

export type Bundle = { _id: string; id: string; name: string };
export type BundleAppRowProps = {
  value: Array<Bundle>;
  onChange: (fn: (prev: Array<Bundle>) => void) => void;
} & CheckboxRowProps;

type SingleBundleAppProps = {
  row: any;
  isDisabled: boolean;
  isSubmitted: boolean;
  onUpdateField: (
    row: Bundle,
    value: string,
  ) => React.ChangeEventHandler<HTMLInputElement>;
  canTrash: boolean;
  onTrashRow: (row: Bundle) => void;
  update: UpdateFn;
  validationDep: number;
};
const SingleBundleApp = (props: SingleBundleAppProps) => {
  const {
    row,
    isDisabled,
    isSubmitted,
    onUpdateField,
    canTrash,
    onTrashRow,
    update,
    validationDep,
  } = props;
  const idField = `${row._id}_id`;
  const fieldsToValidate = [idField];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate(idField)(!row.id);
  }, [validationDep]);

  return (
    <Setting.SecondaryControlsRow key={row._id}>
      <ControlsRow>
        <Grid css={{ gridTemplateColumns: '1fr 1fr auto' }} gap="3">
          <TextField
            ref={refs[0]}
            disabled={isDisabled}
            placeholder="Bundle ID"
            value={row.id}
            onChange={(e) => {
              onUpdateField(row, 'id')(e);
              onInvalidate(idField)(!e.target.value);
            }}
            state={isSubmitted && invalidations[idField] ? 'error' : 'default'}
            data-testid="bundle-id"
          />
          <TextField
            disabled={isDisabled}
            placeholder="App Name (optional)"
            value={row.name}
            onChange={onUpdateField(row, 'name')}
          />
          <Flex alignItems="center">
            <Button
              compact
              disabled={!canTrash}
              variant="subtle"
              icon={{ name: 'trash' }}
              onClick={() => onTrashRow(row)}
            />
          </Flex>
        </Grid>
      </ControlsRow>
    </Setting.SecondaryControlsRow>
  );
};

const BundleApp = (props: BundleAppRowProps) => {
  const {
    value,
    isDisabled,
    isSubmitted,
    onChange,
    updateHelpers,
    validationDep,
  } = props;

  const onAddMore = () =>
    onChange((prev) => [
      ...prev,
      {
        _id: v4(),
        id: '',
        name: '',
      },
    ]);

  const onTrashRow = (row) =>
    onChange((prev) => {
      const updateIdx = prev.findIndex((bundle) => bundle._id === row._id);
      return [...prev.slice(0, updateIdx), ...prev.slice(updateIdx + 1)];
    });

  const onUpdateField = (row, field) => (e) =>
    onChange((prev) => {
      const p = prev;
      const updateIdx = prev.findIndex((bundle) => bundle._id === row._id);
      p[updateIdx][field] = e.target.value;
      return [...p];
    });

  useEffect(() => {
    if (!value.length) {
      onAddMore();
    }
  }, []);

  return (
    <Box>
      <Setting.SecondaryControls>
        {value.map((row) => (
          <SingleBundleApp
            key={row._id}
            row={row}
            onUpdateField={onUpdateField}
            isDisabled={isDisabled}
            isSubmitted={isSubmitted}
            canTrash={value.length > 1}
            onTrashRow={onTrashRow}
            update={updateHelpers}
            validationDep={validationDep}
          />
        ))}
      </Setting.SecondaryControls>
      <Flex pt3 justifyContent="end" id="add">
        <Button
          compact
          disabled={isDisabled}
          variant="primary"
          icon={{ position: 'left', name: 'circle-plus' }}
          onClick={onAddMore}
        >
          Add More
        </Button>
      </Flex>
    </Box>
  );
};

const BundleAppRow = (props: BundleAppRowProps) => (
  <CheckboxRow {...props}>
    {(isChecked) => isChecked && <BundleApp {...props} />}
  </CheckboxRow>
);

export default BundleAppRow;
