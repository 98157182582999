import {
  Banner,
  Button,
  Checkbox,
  Radio,
  Select,
  TextInput,
} from '@kandji-inc/bumblebee';
import { customAlphabet } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';

import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import { Setting } from 'features/library-items/template';

import featureFlags from 'src/config/feature-flags';
import { kandjiIcon } from '../default-icons';
import LogoUploader from '../logo-uploader/logo-uploader';
import isSquare from '../screens/hooks/utils/isSquare';
import DeletePrimaryLogoModal from './delete-primary-logo-modal';

const alphabet = '123456789abcdefghijkmnpqrstuvwxyz';
const nanoid = customAlphabet(alphabet, 8);

const DISPLAY_MODE = {
  WINDOWED: 'windowed',
  FULL_SCREEN: 'full_screen',
};

const ENROLLMENT_TRIGGERS = {
  ALL_ENROLLMENTS: 'all_enrollments',
  AUTOMATED_ONLY: 'automated_device_enrollment_only',
  MANUAL_ONLY: 'manual_enrollment_only',
};

const { WINDOWED, FULL_SCREEN } = DISPLAY_MODE;

const { ALL_ENROLLMENTS, AUTOMATED_ONLY, MANUAL_ONLY } = ENROLLMENT_TRIGGERS;

const ENROLLMENT_TRIGGER_OPTIONS = [
  {
    value: ALL_ENROLLMENTS,
    label: 'All enrollments',
  },
  {
    value: AUTOMATED_ONLY,
    label: 'Automated Device Enrollment only',
  },
  {
    value: MANUAL_ONLY,
    label: 'Manual enrollment only',
  },
];

const General = (props) => {
  const { isDisabled, isEditingPage } = useContext(KandjiSetupCtx);
  const { model, setModel } = props;

  const enrollTrigOptions = ENROLLMENT_TRIGGER_OPTIONS.find(
    ({ value }) => value === model.general.enrollmentTrigger,
  );

  const [canRemovePrimary, setCanRemovePrimary] = useState(false);
  const [isDeletingPrimaryLogo, setIsDeletingPrimaryLogo] = useState(false);
  const [isAddingSecondaryLogo, setIsAddingSecondaryLogo] = useState(false);
  const [isPrimarySquare, setIsPrimarySquare] = useState(
    isSquare(model.general.logoWidth, model.general.logoHeight),
  );
  const [isSecondSquare, setIsSecondSquare] = useState(
    isSquare(model.general.logoDarkWidth, model.general.logoDarkHeight),
  );

  const setDisplayMode = (displayMode) =>
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        displayMode,
      },
    }));

  const onSelectEnrollTrigger = ({ value: enrollmentTrigger }) =>
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        enrollmentTrigger,
      },
    }));

  const onChangeOfEnforcingingManagedOs = (enforceManagedOS) =>
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        enforceManagedOS,
      },
    }));

  const onImage = (img) => {
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        logo: img,
        logoUrl: '',
        logoS3Key: '',
        isCustomLogo: true,
        logoWidth: img.width,
        logoHeight: img.height,
      },
    }));
  };

  const onImageSecondary = (img) => {
    setIsAddingSecondaryLogo(true);
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        logoDark: img,
        logoDarkS3Key: '',
        logoDarkUrl: '',
        logoDarkWidth: img.width,
        logoDarkHeight: img.height,
      },
    }));
  };

  useEffect(() => {
    /*
    kind of tricky to get the logic right, can revert primary to default
    icon essentially when what you see on the page is not kandji default
    icon.
    */
    if (isEditingPage) {
      if (model.general.isCustomLogo) {
        setCanRemovePrimary(true);
      } else {
        setCanRemovePrimary(false);
      }
    } else {
      setCanRemovePrimary(false);
    }
  }, [isEditingPage, model]);

  /* on revert, actually reverting the dark logo to empty as well per UX */
  const revertPrimaryAndSecondary = () => {
    setIsAddingSecondaryLogo(false);
    setIsDeletingPrimaryLogo(false);
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        logo: kandjiIcon,
        logoUrl: '',
        logoS3Key: '',
        logoDark: {},
        logoDarkUrl: '',
        logoDarkS3Key: '',
        isCustomLogo: false,
        logoWidth: '',
        logoHeight: '',
        logoDarkWidth: '',
        logoDarkHeight: '',
      },
    }));
  };

  const addingDarkLogo = () => {
    setIsAddingSecondaryLogo(true);
    setModel((p) => {
      const c = { ...p };
      if (c.general.logo.dataUrl) {
        c.general.logoDark = c.general.logo;
      } else if (c.general.logoUrl) {
        c.general.logoDarkUrl = c.general.logoUrl;
      }
      c.general.logoDarkWidth = c.general.logoWidth;
      c.general.logoDarkHeight = c.general.logoHeight;
      return c;
    });
  };

  /* ... */
  useEffect(() => {
    setIsPrimarySquare(
      isSquare(model.general.logoWidth, model.general.logoHeight),
    );
  }, [model.general.logoWidth, model.general.logoHeight]);

  useEffect(() => {
    setIsSecondSquare(
      isSquare(model.general.logoDarkWidth, model.general.logoDarkHeight),
    );
  }, [model.general.logoDarkWidth, model.general.logoDarkHeight]);

  /* per ux this is the behavior, tricky to get right ...
   * might be simpler to just have an option for both primary and secondary
   * logos and just keep both optional ...
   */
  const showAddDarkLogo =
    !isAddingSecondaryLogo &&
    model.general.isCustomLogo &&
    !model.general.logoDarkUrl;

  const showDarkLogoUploader =
    isAddingSecondaryLogo ||
    model.general.logoDarkUrl ||
    model.general.logoDark?.dataUrl;

  const onExitPasswordChange = (password) => {
    setModel((p) => ({
      ...p,
      general: {
        ...p.general,
        exitPassword: password,
      },
    }));
  };

  const generatePassword = () => {
    const password = nanoid();

    onExitPasswordChange(password);
  };

  /* Auto-generates new password on item create */
  useEffect(() => {
    if (!model.general.exitPassword) {
      generatePassword();
    }
  }, [model]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">General</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Specify which enrollment types will trigger Liftoff and brand the
          experience with your organization’s logo.
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Enrollment trigger</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Select the enrollment type that will trigger Liftoff.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              options={ENROLLMENT_TRIGGER_OPTIONS}
              compact
              disabled={isDisabled}
              onChange={onSelectEnrollTrigger}
              value={enrollTrigOptions}
            />
          </Setting.Controls>
        </Setting.Row>
        {featureFlags.getFlag('ag_05182023_Liftoff-deferManagedOs') && (
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">Managed OS</p>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                If enabled, Liftoff will install macOS updates as soon as the
                device is enrolled into Kandji.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Checkbox
                label="Enforce Managed OS"
                id="enforce_managed_os"
                testId="liftoff_enforce_managed_os_checkbox"
                checked={model.general.enforceManagedOS}
                onChange={() => {
                  onChangeOfEnforcingingManagedOs(
                    !model.general.enforceManagedOS,
                  );
                }}
                disabled={isDisabled}
              />
            </Setting.Controls>
          </Setting.Row>
        )}
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">
              Logo {showDarkLogoUploader && '(Light Mode)'}
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Upload your organization’s logo and optionally add a Dark Mode
              variant. A square 128x128 pixel PNG file with transparent
              background is recommended.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <div>
              <LogoUploader
                icon={model.general.logoUrl || model.general.logo}
                previewClass={`k-library-settings-logo-upload 
                    ${isPrimarySquare && '--round-corner-preview'}`}
                disabled={isDisabled}
                onImage={onImage}
                canRemove={canRemovePrimary}
                onRemove={() => {
                  setIsDeletingPrimaryLogo(true);
                }}
              />
              {showAddDarkLogo && (
                <div className="b-mt1">
                  <Button
                    disabled={isDisabled}
                    onClick={addingDarkLogo}
                    kind="link"
                    size="small"
                    icon="circle-plus"
                  >
                    Add Dark Mode logo
                  </Button>
                </div>
              )}
              {showDarkLogoUploader && (
                <div style={{ margin: '28px 0 2px' }}>
                  <p className="b-txt b-mb1">Logo (Dark Mode)</p>
                  <LogoUploader
                    icon={model.general.logoDarkUrl || model.general.logoDark}
                    disabled={isDisabled}
                    isDarkMode
                    onImage={onImageSecondary}
                    previewClass={isSecondSquare && '--round-corner-preview'}
                    canRemove={isEditingPage}
                    onRemove={() => {
                      setIsAddingSecondaryLogo(false);
                      setModel((p) => ({
                        ...p,
                        general: {
                          ...p.general,
                          logoDark: {},
                          logoDarkS3Key: '',
                          logoDarkUrl: '',
                          logoDarkWidth: '',
                          logoDarkHeight: '',
                        },
                      }));
                    }}
                  />
                </div>
              )}
              {isDeletingPrimaryLogo && (
                <DeletePrimaryLogoModal
                  setIsShow={setIsDeletingPrimaryLogo}
                  remove={() => {
                    revertPrimaryAndSecondary();
                  }}
                />
              )}
            </div>
          </Setting.Controls>
        </Setting.Row>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Display mode</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Specify whether Liftoff displays in a window or takes over the
              full screen. Full-screen mode prevents use of the device until
              setup is complete.
            </p>
            {model.general.displayMode === FULL_SCREEN ? (
              <Banner theme="info" kind="block">
                <span>
                  Liftoff will display in full-screen mode. Users will not be
                  able to exit and use the device until setup is complete.
                </span>
              </Banner>
            ) : null}
          </Setting.Helpers>
          <Setting.Controls>
            <div className="b-flex-vc b-mb1">
              <Radio
                label="Window"
                value={WINDOWED}
                disabled={isDisabled}
                checked={model.general.displayMode === WINDOWED}
                onChange={(e) => setDisplayMode(e.target.value)}
              />
            </div>
            <div className="b-flex-vc">
              <Radio
                label="Full screen"
                disabled={isDisabled}
                value={FULL_SCREEN}
                checked={model.general.displayMode === FULL_SCREEN}
                onChange={(e) => setDisplayMode(e.target.value)}
              />
            </div>
          </Setting.Controls>
        </Setting.Row>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Exit password</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              If needed, an exit password can let users quit Liftoff while it is
              still running.
            </p>
            <Banner theme="info">
              <span>Users can exit Liftoff by pressing Cmd+Shift+K+J+D</span>
            </Banner>
          </Setting.Helpers>
          <Setting.Controls>
            <div>
              <TextInput
                style={{ letterSpacing: '1.5px' }}
                value={model.general.exitPassword}
                type="password"
                compact
                readOnly
              />
              <Button
                className="b-mt"
                kind="link"
                size="small"
                isDisabled={isDisabled}
                onClick={generatePassword}
              >
                Generate new password
              </Button>
            </div>
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default General;
