import { Button, Icon } from '@kandji-inc/bumblebee';
import React, { Fragment, useContext } from 'react';

import { Form } from 'theme/components/molecules/Form';
import Link from '../../theme/components/atoms/Link';
import Loader from '../../theme/components/atoms/Loader';
import Offcanvas from '../../theme/components/atoms/Offcanvas';
import Sticky from '../../theme/components/atoms/Sticky';
import Basic from '../../theme/components/molecules/Basic';

import { Context, Provider } from './context';
import TableConnections from './table';
import Wizard from './wizard';

const Module = () => {
  const { list, isLoading, onWizardOpen } = useContext(Context);

  const isFeature = true;

  const onAdd = () => onWizardOpen(null, null);

  return (
    <Form>
      <Basic section="single-sign-on">
        <div className="paper">
          <Sticky
            label="paper-header"
            origin="paper"
            edge="border"
            sticky={false}
          >
            <div className="node">
              <h3 className="paper-title">
                <span>Authentication</span>
                {false && <Icon name="circle-info" size="sm" />}
              </h3>
            </div>
          </Sticky>
          {list && !isLoading ? (
            <>
              {isFeature && list.length > 0 ? (
                <>
                  <div className="paper-aside">
                    <div className="node">
                      <div className="paper-block">
                        <div className="paper-copy">
                          <p>
                            Add Single Sign-On (SSO) for all users. SSO can
                            provide a simpler log in process for your end users.{' '}
                            <Link href="https://support.kandji.io/support/solutions/articles/72000559828" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="paper-body">
                    <TableConnections />
                  </div>
                  <div className="paper-footer">
                    <div className="node">
                      <Button kind="link" icon="circle-plus" onClick={onAdd}>
                        Add
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="paper-body">
                  <div className="node">
                    <div className="paper-block">
                      <div className="paper-copy paper-copy-center">
                        <Icon name="kandji-bee" />
                        {isFeature ? (
                          <>
                            <p>
                              Add Single Sign-On (SSO) for all users. SSO can
                              provide a simpler log in process for your end
                              users.
                            </p>
                            <Button
                              kind="outline"
                              icon="circle-plus"
                              onClick={onAdd}
                            >
                              Add Single Sign-On Provider
                            </Button>
                          </>
                        ) : (
                          <>
                            <p>Upgrade your plan to use SSO features.</p>
                            <Button
                              kind="hollow"
                              tier="two"
                              label="Contact Success"
                              icon="envelope"
                              onClick={() => {}}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="paper-body">
              <div className="node">
                <div className="paper-block">
                  <Loader type="simple" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Basic>
      <Offcanvas name="single-sign-on">
        <Wizard />
      </Offcanvas>
    </Form>
  );
};

export default () => (
  <Provider>
    <Module />
  </Provider>
);
