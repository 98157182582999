import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import CheckboxRow from './CheckboxRow';

const DeviceFamilySettings = ({
  update,
  isDisabled,
  settings,
  checkboxFields,
  deviceFamily,
}) => {
  useEffect(() => {
    checkboxFields.forEach((field) => {
      if (!field.dependence) {
        return;
      }

      const isDepUnchecked =
        field.dependence && !settings[field.dependence].checked;
      const isFieldChecked = settings[field.apiProperty].checked;

      if (isDepUnchecked && isFieldChecked) {
        update(field.apiProperty, { ...field.defaults });
      }
    });
  }, [settings]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{deviceFamily}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">Passcode settings for {deviceFamily} devices:</p>
      </Setting.SubHeader>
      <Setting.Rows>
        {checkboxFields.map((fieldDetails) => (
          <CheckboxRow
            key={`${deviceFamily}_${fieldDetails.apiProperty}`}
            deviceFamily={deviceFamily}
            isDisabled={
              // Disable if the dependent field is not checked:
              fieldDetails.dependence
                ? !settings[fieldDetails.dependence].checked || isDisabled
                : isDisabled
            }
            onUpdate={update}
            isChecked={settings[fieldDetails.apiProperty].checked}
            apiProperty={fieldDetails.apiProperty}
            label={fieldDetails.label}
            helpText={fieldDetails.helpText}
            subFieldType={fieldDetails?.subField?.type}
            subFieldLabel={fieldDetails?.subField?.label}
            subFieldOptions={fieldDetails?.subField?.options}
            subFieldValue={settings[fieldDetails.apiProperty].value}
            subFieldMin={fieldDetails?.subField?.min}
            subFieldMax={fieldDetails?.subField?.max}
            subFieldIsValid={fieldDetails?.subField?.isValid}
          />
        ))}
      </Setting.Rows>
    </Setting.Card>
  );
};

DeviceFamilySettings.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  checkboxFields: PropTypes.array.isRequired,
  deviceFamily: PropTypes.string.isRequired,
};

export default memo(DeviceFamilySettings);
