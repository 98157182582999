import { Callout, Paragraph } from '@kandji-inc/nectar-ui';
import React from 'react';
import ActionDialog from '../../../../components/action-dialog';

type Props = {
  isDeleteDialogOpen: boolean;
  handleToggle: () => void;
  handleDelete: () => void;
  isDeleting: boolean;
};

const DeleteIntegration = (props: Props) => {
  const { isDeleteDialogOpen, handleToggle, handleDelete, isDeleting } = props;

  const dialogContent = () => (
    <>
      {/* <Callout
        theme="warning"
        title="Device information will immediately stop data synchronization to ServiceNow."
        text="Please be aware that deleting the integration in Kandji will neither uninstall the Kandji application or remove any of the data that has already synced to your ServiceNow instance."
        showCloseButton={false}
      /> */}
      <Paragraph>
        Deleting this integration removes the ability for Intune to access
        compliance details on your Apple fleet and grant end-users to resources.
        This action cannot be undone.
      </Paragraph>
      <Paragraph>Are you sure you want to delete this integration?</Paragraph>
    </>
  );

  return (
    <ActionDialog
      size="md"
      title="Delete integration"
      content={dialogContent()}
      isOpen={isDeleteDialogOpen}
      nextText="Delete integration"
      nextClick={handleDelete}
      backText="Cancel"
      backClick={handleToggle}
      isProcessing={isDeleting}
    />
  );
};

export default DeleteIntegration;
