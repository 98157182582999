import deepcopy from 'deepcopy';
import { getAllBlueprintOptions } from 'features/library-items/data-service/blueprint/use-blueprint-service';
import { omit } from 'lodash';
import dataReplace from './data-replace';

// this used in ../automatic-app/transformers.js and other transformers
const getOSRequirement = (apiData) => {
  if (apiData.runs_on_mac) {
    return [`macOS ${apiData.data.minimum_os_version || `10.11`}+`];
  }

  return [`iOS ${apiData.data.minimum_os_version}+`];
};

/**
 * Necessary transformations on the data from the api.
 * @param {Object} data - The library item data received from the api.
 */
const transformFromApi = async (apiData) => {
  // These are the keys this specific library item type has that contains runs
  // on info.
  const runsOnKeys = Object.keys(apiData.data).filter((k) =>
    k.includes('runs_on_'),
  );
  const transformed = {
    ...apiData,
    data: {
      // In case the backend, or somewhere else has changed the actual library
      // item data, let's update the necessary fields in data.
      ...apiData.data,
      blueprints: await getAllBlueprintOptions(apiData.blueprints),
      isActive: apiData.active,
      name: apiData.name,
      type: apiData.type,
      id: apiData.id,
      template: apiData.template,
    },
  };

  // This way, we don't add unnecessary runs_on fields to the transformed data.
  runsOnKeys.forEach((runsOn) => {
    transformed.data[runsOn] = apiData[runsOn];
  });

  /* set nullish values to '' for ui */
  dataReplace(transformed.data, '');

  return transformed;
};

/**
 * Necessary transformations on the data to send to the api.
 * @param {Object} data - The library item data model to send to the api.
 */
const transformToApi = (data) => {
  const d = deepcopy(data);
  const blueprints = d.blueprints.map((blueprint) => blueprint.value);
  delete d.blueprints;
  delete d.id;

  const toSend = {
    active: d.isActive,
    blueprints,
    data: omit(d, ['blueprints', 'id', 'template']),
    name: d.name,
    type: d.type,
    runs_on_mac: d.runs_on_mac || false,
    runs_on_ipad: d.runs_on_ipad || false,
    runs_on_iphone: d.runs_on_iphone || false,
    runs_on_ipod: d.runs_on_ipod || false,
    runs_on_tv: d.runs_on_tv || false,
    runs_on_watch: d.runs_on_watch || false,
    reassign_lib_item_to_bp: true,
    skip_blueprint_conflict: d.skip_blueprint_conflict || false,
  };

  dataReplace(toSend.data);
  return toSend;
};

const setBlueprintsToFormData = (formData, blueprints) => {
  if (!blueprints.length) {
    formData.set('blueprints', '');
    return;
  }
  for (let i = 0; i < blueprints.length; i++) {
    const bp = blueprints[i];
    formData.append('blueprints', bp.value);
  }
};

export {
  transformFromApi,
  transformToApi,
  getOSRequirement,
  setBlueprintsToFormData,
};
