import {
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, string } from 'prop-types';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import SemiBold from 'features/util/components/semi-bold';
import useDeleteNotification from '../../../../slack/hooks/use-delete-notification';

function DeleteNotificationModal({ isOpen, onClose, id, name }) {
  const queryClient = useQueryClient();

  const deleteNotificationMutation = useDeleteNotification();

  function onDeleteClick() {
    deleteNotificationMutation
      .mutateAsync(id)
      .then(() => {
        toaster(`${name} has been deleted.`);
        queryClient.invalidateQueries({ queryKey: ['slackNotifications'] });
        onClose();
      })
      .catch((e) => {
        console.log(e);
        toaster('Something went wrong');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withoutCloseButton
      parts={{
        header: { text: 'Delete event notification' },
        content: {
          children: (
            <>
              Are you sure you would like to delete <SemiBold>{name}</SemiBold>?
              This action cannot be undone.
            </>
          ),
        },
        footer: {
          children: (
            <>
              <Button kind="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className="b-ml"
                theme="error"
                onClick={onDeleteClick}
                disabled={deleteNotificationMutation.isLoading}
                isProgress={deleteNotificationMutation.isLoading}
              >
                Delete
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

DeleteNotificationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  name: string,
  id: number,
};

DeleteNotificationModal.defaultProps = {
  name: null,
  id: null,
};

export default DeleteNotificationModal;
