const extensionTypes = {
  credential: 'Credential',
  redirect: 'Redirect',
  kerberos: 'Kerberos',
};

const extensionTypeOptions = [
  { label: extensionTypes.credential, value: extensionTypes.credential },
  { label: extensionTypes.redirect, value: extensionTypes.redirect },
  { label: extensionTypes.kerberos, value: extensionTypes.kerberos },
];

export { extensionTypes, extensionTypeOptions };
