import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
  margin-left: 20px;
  padding: 6px 15px;
  height: 24px;

  background: var(--color-green-20);
  border-radius: 3px;

  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: var(--color-green-70);
`;

const Warning = styled(Indicator)`
  background: var(--color-red-20);
  color: var(--color-red-50);
`;

const Danger = styled(Indicator)`
  background: var(--color-red-20);
  color: var(--color-red-50);
`;

export const TokenExpirationIndicator = (props) => {
  const { daysLeft } = props;
  const isExpired = daysLeft < 0;
  const text = isExpired ? 'Expired' : 'Renew Soon';

  if (daysLeft >= 30) {
    return <Indicator>Complete</Indicator>;
  }
  return daysLeft < 5 ? <Danger>{text}</Danger> : <Warning>{text}</Warning>;
};

TokenExpirationIndicator.propTypes = {
  daysLeft: PropTypes.number.isRequired,
};

export default TokenExpirationIndicator;
