import {
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';

const keys = WifiService.keys.proxy.manual;

const fieldsToValidate = [keys.port, keys.proxyServer];

const Manual = ({ update, setting, isDisabled, validationDep }) => {
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  // since validation works not so well clean this way
  useEffect(
    () => () => {
      update('invalidationsMap', (p = {}) => {
        const prev = { ...p };
        fieldsToValidate.forEach((key) => {
          delete prev[key];
        });
        return prev;
      });
    },
    [],
  );

  return (
    <Setting.SecondaryControls
      testId="proxy-type-manual"
      className="k-ade-secondary-ctrl-grid-gap-align"
    >
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">Proxy server and port</p>
        </div>
        <div ref={refs[keys.port]}>
          <p className="b-txt-light" ref={refs[keys.proxyServer]}>
            Hostname or IP address, and port number of the proxy server.
          </p>
        </div>
        <div>
          <div className="k-wifiv2-protocols-proxy__proxy-inputs">
            <TextInput
              disabled={isDisabled}
              value={setting[keys.proxyServer]}
              onChange={(e) => update(keys.proxyServer, e.target.value)}
              compact
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  trigger: ['onBlur', validationDep],
                }),
              ]}
              onInvalidate={onInvalidate(keys.proxyServer)}
              removeValidationOnUnmount
              placeholder="proxy.accuhive.io"
              data-testid="manual-proxy-server"
            />
            <span className="k-wifiv2-protocols-proxy__proxy-inputs-span">
              :
            </span>
            <TextInput
              disabled={isDisabled}
              value={setting[keys.port]}
              onChange={(e) => update(keys.port, e.target.value)}
              compact
              type="number"
              min={0}
              validator={(v) => [
                onEmptyBlurValidator(v, {
                  trigger: ['onBlur', validationDep],
                }),
              ]}
              onInvalidate={onInvalidate(keys.port)}
              removeValidationOnUnmount
              placeholder="8080"
              data-testid="manual-port"
            />
          </div>
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">
            Proxy username <span className="b-txt-light2"> (optional)</span>
          </p>
        </div>
        <div>
          <p className="b-txt-light">
            The user name used to authenticate to the proxy server.
          </p>
        </div>
        <div>
          <TextInput
            disabled={isDisabled}
            value={setting[keys.proxyUsername]}
            onChange={(e) => update(keys.proxyUsername, e.target.value)}
            compact
            isOptional
            placeholder="$EMAIL"
            data-testid="manual-username"
          />
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">
            Proxy password <span className="b-txt-light2"> (optional)</span>
          </p>
        </div>
        <div>
          <p className="b-txt-light">
            The password used to authenticate to the proxy server.
          </p>
        </div>
        <div>
          <TextInput
            disabled={isDisabled}
            value={setting[keys.proxyPassword]}
            onChange={(e) => update(keys.proxyPassword, e.target.value)}
            compact
            type="password"
            isOptional
            data-testid="manual-password"
          />
        </div>
      </Setting.SecondaryControlsRow>
    </Setting.SecondaryControls>
  );
};

export default Manual;
