/* istanbul ignore file */
import { getMillisecondsInactive } from 'app/api/base';
import { getLogoutInactivityTime } from 'app/components/sidebar/LogoutMonitor';
import { useLogoutWarningBanner } from 'app/components/sidebar/LogoutWarningBanner';

const LOGOUT_INACTIVITY_WARNING_DURATION = 5 * 60 * 1000; // 5 minutes

/* istanbul ignore next */
export const getLogoutWarningDuration = () => {
  let logoutWarningAfter = LOGOUT_INACTIVITY_WARNING_DURATION;

  // TODO: remove after testing
  if (localStorage.getItem('logoutInactivityWarning')) {
    logoutWarningAfter = parseInt(
      localStorage.getItem('logoutInactivityWarning'),
      10,
    );
  }

  return logoutWarningAfter;
};

export const useLogoutWarningMonitor = () => {
  let warningTimeout;
  const logoutAfter = getLogoutInactivityTime();
  const logoutWarningDuration = getLogoutWarningDuration();
  const logoutWarningBanner = useLogoutWarningBanner();

  const checkForLogoutWarning = () => {
    const timeTillLogout = logoutAfter - getMillisecondsInactive();
    const timeTillLogoutWarning = timeTillLogout - logoutWarningDuration;

    // time to show a warning
    if (timeTillLogoutWarning <= 0) {
      logoutWarningBanner.show();

      // wait max duration before checking again
      warningTimeout = setTimeout(
        checkForLogoutWarning,
        logoutAfter - logoutWarningDuration,
      );
    }

    // not time for a warning
    else {
      logoutWarningBanner.hide();

      // check again when it should be time to show warning
      warningTimeout = setTimeout(checkForLogoutWarning, timeTillLogoutWarning);
    }
  };

  return {
    start: checkForLogoutWarning,
    stop: () => clearTimeout(warningTimeout),
  };
};
