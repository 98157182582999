import { Checkbox, Select } from '@kandji-inc/bumblebee';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
/* istanbul ignore file */
import React, { memo, useEffect } from 'react';

import { Setting } from '../setting';

export const ssLink = (
  <a
    href="https://support.kandji.io/support/solutions/folders/72000558148"
    rel="noopener noreferrer"
    target="_blank"
    className="b-alink"
  >
    Learn More
  </a>
);

const defaultHelper = (
  <p className="b-txt-light">
    Determine how this item should be installed. Choose to install and
    continuously enforce, or install on-demand from Self Service. {ssLink}
  </p>
);
const defaultTitle = 'Installation';

const InstallationCard = (props: any) => {
  const {
    setting,
    update,
    isDisabled,
    options,
    helper = defaultHelper,
    title = defaultTitle,
    rowsSlot,
    secondaryControlsSlot,
  } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{title}</h3>
      </Setting.Header>

      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers>{helper}</Setting.Helpers>
          <Setting.Controls>
            <div>
              <Select
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ isDisabled: any; options: any; placeholder... Remove this comment to see the full error message
                isDisabled={isDisabled}
                options={options}
                placeholder="Installation"
                compact
                onChange={(v: any) => update('type', v.value)}
                value={options.find(
                  (option: any) => option.value === setting.type,
                )}
              />
            </div>
          </Setting.Controls>
          {secondaryControlsSlot}
        </Setting.Row>
        {rowsSlot}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(InstallationCard);

InstallationCard.propTypes = {
  setting: object.isRequired,
  update: func.isRequired,
  isDisabled: bool.isRequired,
  options: arrayOf(
    shape({
      value: string,
      label: string,
    }),
  ).isRequired,
  helper: node,
  title: string,
  rowsSlot: node,
  secondaryControlsSlot: node,
};

InstallationCard.defaultProps = {
  helper: defaultHelper,
  title: defaultTitle,
  rowsSlot: null,
  secondaryControlsSlot: null,
};

export const useSsInstall = ({ installType, ssType, updateSs }: any) => {
  useEffect(() => {
    if (installType === ssType) {
      updateSs('isEnabled', true);
    }
  }, [installType, ssType]);

  return installType === ssType;
};
