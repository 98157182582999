/* istanbul ignore file */
import React from 'react';
import featureFlags from 'src/config/feature-flags';
import AzureLoginSonoma from '../../assets/entra_sonoma.png';
import IdpLogin from '../../assets/idp.png';
import IdpLoginSonoma from '../../assets/idp_sonoma.png';
import OktaLogin from '../../assets/okta.png';
import OktaLoginSonoma from '../../assets/okta_sonoma.png';
import OneLoginLogin from '../../assets/onelogin.png';
import OneLoginLoginSonoma from '../../assets/onelogin_sonoma.png';
import KandjiLoginService from '../../service/kandji-login-service';

const providerOptions = [
  {
    label: 'Microsoft Entra ID',
    value: KandjiLoginService.identityProviders.MICROSOFT_AZURE,
  },
  {
    label: 'Okta',
    value: KandjiLoginService.identityProviders.OKTA,
  },
  {
    label: 'OneLogin',
    value: KandjiLoginService.identityProviders.ONE_LOGIN,
  },
  {
    label: 'Other',
    value: KandjiLoginService.identityProviders.OTHER,
  },
];

const tempFeatureFlagGoogleIdpProviderOptions = [
  {
    label: 'Google Workspace',
    value:
      KandjiLoginService.tempFeatureFlagGoogleIdpIdentityProviders
        .GOOGLE_WORKSPACE,
  },
  {
    label: 'Microsoft Entra ID',
    value: KandjiLoginService.identityProviders.MICROSOFT_AZURE,
  },
  {
    label: 'Okta',
    value: KandjiLoginService.identityProviders.OKTA,
  },
  {
    label: 'OneLogin',
    value: KandjiLoginService.identityProviders.ONE_LOGIN,
  },
  {
    label: 'Other',
    value: KandjiLoginService.identityProviders.OTHER,
  },
];

const providerImages = () => {
  const LDFFpassportSonoma = featureFlags.getFlag('LIT-WWDC23-passport-sonoma');
  if (LDFFpassportSonoma) {
    return {
      [KandjiLoginService.identityProviders.MICROSOFT_AZURE]: AzureLoginSonoma,
      [KandjiLoginService.identityProviders.OKTA]: OktaLoginSonoma,
      [KandjiLoginService.identityProviders.ONE_LOGIN]: OneLoginLoginSonoma,
      [KandjiLoginService.identityProviders.OTHER]: IdpLoginSonoma,
    };
  }

  return {
    [KandjiLoginService.identityProviders.MICROSOFT_AZURE]: AzureLoginSonoma,
    [KandjiLoginService.identityProviders.OKTA]: OktaLogin,
    [KandjiLoginService.identityProviders.ONE_LOGIN]: OneLoginLogin,
    [KandjiLoginService.identityProviders.OTHER]: IdpLogin,
  };
};

const providerCopy = {
  [KandjiLoginService.identityProviders.OKTA]: {
    idpLearnMoreHelper:
      'Additional steps required to configure Passport with Okta.',
    idpLearnMoreUrl:
      'https://support.kandji.io/support/solutions/articles/72000560471',
    urlPlaceholder: 'Okta domain URL',
    urlHelper: (
      <>
        Enter the URL of your Okta domain in the following format: <br />
        {'https://{yourOktaDomain}/.well-known/openid-configuration'}
      </>
    ),
    clientHelper:
      'Create an Okta app with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    modeHelper:
      'Select the authentication mode displayed at user login. If you have MFA policies configured with Okta, choose Web Login to enforce them.',
    webLoginBanner: (
      <span>
        {`To ensure users can log in locally, confirm the`}{' '}
        <span className="b-txt-bold li-passport-color__inherit">
          Local user access
        </span>{' '}
        settings below.
      </span>
    ),
    webClientIdHelper:
      'Create a second Okta application with POST authentication to use with web login. Copy and paste the client ID for this app here.',
    redirectUriPlaceholder: 'Okta redirect URI',
    redirectUriHelper: 'Copy and paste the redirect URI from your Okta app.',
    clientSecretHelper:
      'If one exists, copy and paste the client secret from your Okta app.',
  },
  [KandjiLoginService.identityProviders.MICROSOFT_AZURE]: {
    idpLearnMoreHelper:
      'Additional steps required to configure Passport with Microsoft Entra ID.',
    idpLearnMoreUrl:
      'https://support.kandji.io/support/solutions/articles/72000560497',
    urlPlaceholder: 'Microsoft Entra ID domain URL',
    urlHelper: (
      <>
        Enter the URL of your Microsoft Entra ID domain in the following format:{' '}
        <br />
        {
          'https://login.microsoftonline.com/{tenant}/v2.0/.well-known/openid-configuration'
        }
      </>
    ),
    clientHelper:
      'Create a Microsoft Entra ID app with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    modeHelper:
      'Select the authentication mode displayed at user login. If you have MFA policies configured with Microsoft Entra ID, select Web Login to enforce them.',
    webLoginBanner: (
      <span>
        {`To ensure users can log in locally, confirm the`}{' '}
        <span className="b-txt-bold li-passport-color__inherit">
          Local user access
        </span>{' '}
        settings below.
      </span>
    ),
    webClientIdHelper:
      'Create a second Microsoft Entra ID application with POST authentication to use with web login. Copy and paste the client ID for this app here.',
    macLoginBanner: (
      <span>
        {`Azure's free-tier MFA policies are not supported by Mac Login and may
        prevent users from being able to log in.`}
      </span>
    ),
    redirectUriPlaceholder: 'Microsoft Entra ID redirect URI',
    redirectUriHelper:
      'Copy and paste the redirect URI from your Microsoft Entra ID app.',
    clientSecretHelper:
      'If one exists, copy and paste the client secret from your Microsoft Entra ID app.',
  },
  [KandjiLoginService.tempFeatureFlagGoogleIdpIdentityProviders
    .GOOGLE_WORKSPACE]: {
    idpLearnMoreHelper:
      'Additional steps required to configure Passport with Google Workspace.',
    idpLearnMoreUrl:
      'https://support.kandji.io/en/support/solutions/articles/72000595458',
  },
  [KandjiLoginService.identityProviders.ONE_LOGIN]: {
    idpLearnMoreHelper:
      'Additional steps required to configure Passport with OneLogin.',
    idpLearnMoreUrl:
      'https://support.kandji.io/support/solutions/articles/72000560516',
    urlPlaceholder: 'Issuer URL',
    urlHelper: (
      <>
        Enter the URL of your OneLogin domain in the following format: <br />
        {
          'https://{subdomain}.onelogin.com/oidc/2/.well-known/openid-configuration'
        }
      </>
    ),
    clientHelper:
      'Create a OneLogin app with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    modeHelper:
      'Select the authentication mode displayed at user login. If you have MFA policies configured with OneLogin, select Web Login to enforce them.',
    webLoginBanner: (
      <span>
        {`To ensure users can log in locally, confirm the`}{' '}
        <span className="b-txt-bold li-passport-color__inherit">
          Local user access
        </span>{' '}
        settings below.
      </span>
    ),
    webClientIdHelper:
      'Create a second OneLogin application with POST authentication to enable web login. Copy and paste the client ID for this app here.',
    webClientBanner: (
      <span>
        To enable Web Login, you must configure a second OneLogin application
        that uses the POST authentication method. Each of the following fields
        will use information from this POST application.
      </span>
    ),
    macLoginBanner: (
      <span>
        {`OneLogin's MFA policies are not supported by Mac Login and may prevent
        users from being able to log in.`}
      </span>
    ),
    redirectUriPlaceholder: 'OneLogin redirect URI',
    redirectUriHelper:
      'Copy and paste the redirect URI from your OneLogin POST app.',
    clientSecretHelper:
      'Copy and paste the client secret from your OneLogin app.',
    clientSecretWebHelper:
      'Copy and paste the client secret from your OneLogin POST app.',
  },
  [KandjiLoginService.identityProviders.OTHER]: {
    urlPlaceholder: 'OIDC domain',
    urlHelper: (
      <>
        Enter the URL of your OIDC domain in the following format: <br />
        {'https://{OIDCDomainURL}/.well-known/openid-configuration'}
      </>
    ),
    clientHelper:
      'Create an app in your OIDC with ROPG/PKCE authentication to sync user passwords. Copy and paste the client ID for this app here.',
    modeHelper:
      'Select the authentication mode displayed at user login. If you have MFA policies configured with your IdP, select Web Login to enforce them.',
    webLoginBanner: (
      <span>
        {`To ensure users can log in locally, confirm the`}{' '}
        <span className="b-txt-bold li-passport-color__inherit">
          Local user access
        </span>{' '}
        settings below.
      </span>
    ),
    webClientIdHelper:
      'Create a second OIDC application with POST authentication to use with web login. Copy and paste the client ID for this app here.',
    webClientBanner: (
      <span>
        To enable Web Login, you must configure a second OIDC application that
        uses the authorization code grant. Each of the following fields will use
        information from this OIDC application.
      </span>
    ),
    redirectUriPlaceholder: 'OIDC redirect URI',
    redirectUriHelper:
      'Copy and paste the redirect URI from your second OIDC app.',
    clientSecretHelper:
      'If one exists, copy and paste the client secret from your second OIDC app.',
  },
};

export {
  providerImages,
  providerOptions,
  providerCopy,
  tempFeatureFlagGoogleIdpProviderOptions,
};
