import {
  Banner,
  LazyScriptEditor,
  useInputsValidators,
  useIsChanged,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

import { Setting } from 'features/library-items/template';

import CustomAppsService from '../service/custom-apps-service';

const fieldsToValidate = ['auditScript'];

const InstallationCard = (props) => {
  const { setting, update, isDisabled, validationDep } = props;
  const [isTouched, setIsTouched] = useState(false);
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );

  useEffect(() => {
    if (
      setting.type ===
        CustomAppsService.installationTypes.CONTINUOUSLY_ENFORCE &&
      !setting.auditScript
    ) {
      onInvalidate('auditScript')('Required');
    } else {
      onInvalidate('auditScript')(false);
    }
  }, [setting.type, setting.auditScript]);

  const isSubmitted = useIsChanged(
    validationDep,
    setting.type === CustomAppsService.installationTypes.CONTINUOUSLY_ENFORCE,
  );

  if (
    setting.type !== CustomAppsService.installationTypes.CONTINUOUSLY_ENFORCE
  ) {
    return null;
  }

  return (
    <Setting.Row>
      <div style={{ gridColumn: '1/3' }}>
        <div>
          <p className="b-txt">Audit Script</p>
        </div>
        <Banner theme="info" kind="block" className="b-mb1 b-mt1">
          <span>
            {`Use an audit script to check for files, folders, apps, settings and
            more. A failed audit script will trigger package
            installation. Failure is detected when a script's exit code is
            anything other than 0.`}
          </span>
        </Banner>

        <div onBlur={() => setIsTouched(true)} ref={refs.auditScript}>
          <LazyScriptEditor
            value={setting.auditScript}
            onChange={(value) => update('auditScript', value)}
            language="shell"
            error={((isSubmitted || isTouched) && invalidations[0]) || ''}
            options={{ readOnly: isDisabled }}
            className={isDisabled && 'k-mobile-config-info__preview'}
          />
        </div>
      </div>
    </Setting.Row>
  );
};

export default InstallationCard;
