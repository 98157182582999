import {
  Checkbox,
  ChipSelect,
  TextInput,
  setClass,
  useChipSelect,
} from '@kandji-inc/bumblebee';
import { deviceTypes } from 'features/library-items/library/common';
import React, { useState, useEffect } from 'react';
import './filter.css';

const deviceOptions = [
  { value: 'All', all: true },
  { value: deviceTypes.MAC },
  { value: deviceTypes.IPHONE },
  { value: deviceTypes.IPAD },
  { value: deviceTypes.TV },
];

const types = {
  LIBRARY: 'library',
  PARAMETERS: 'parameters',
};

const Filter = (props) => {
  const { searchFor, onFilter, className, filter, frameworks, compatibility } =
    props;
  const [searchTerm, setSearchTerm] = useState('');
  const [enabledOnly, setEnabledOnly] = useState(true);

  const frameworkOptions = [
    { value: 'All', all: true },
    ...(frameworks?.map(({ name, id }) => ({ value: id, label: name })) || []),
  ];

  const macosCompatibility = [
    { value: 'All', all: true },
    ...(compatibility?.map(({ name, id }) => ({ value: id, label: name })) ||
      []),
  ];

  const { selectOneOrAll, selected: selectedDevices } = useChipSelect({
    options: deviceOptions,
    defaultSelected: [deviceOptions[0].value],
  });
  const {
    selectMultiOrAll: selectedOneOrAllFramework,
    selected: selectedFramework,
  } = useChipSelect({
    options: frameworkOptions,
    defaultSelected: frameworkOptions
      .filter(({ all }) => !all)
      .map(({ value }) => value),
  });
  const {
    selectMultiOrAll: selectedOneOrAllCompatibility,
    selected: selectedCompatibility,
  } = useChipSelect({
    options: macosCompatibility,
    defaultSelected: macosCompatibility
      .filter(({ all }) => !all)
      .map(({ value }) => value),
  });

  useEffect(() => {
    if (filter) {
      const { enabledOnly: enabled = true } = filter;
      setEnabledOnly(enabled);
    }
  }, [filter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const allDevices = deviceOptions.slice(1).map((d) => d.value);
      const allFrameworks = frameworkOptions.slice(1).map((d) => d.value);
      const allCompatibility = macosCompatibility.slice(1).map((d) => d.value);

      onFilter({
        searchTerm,
        devices: selectedDevices[0] === 'All' ? allDevices : selectedDevices,
        frameworks:
          selectedFramework[0] === 'All' ? allFrameworks : selectedFramework,
        compatibility:
          selectedCompatibility[0] === 'All'
            ? allCompatibility
            : selectedCompatibility,
        isAllFrameworks: selectedFramework.length === frameworks?.length,
        isAllDevices:
          selectedDevices[0] === 'All' ||
          _.isEqual(selectedDevices, allDevices),
        isAllCompatibility:
          selectedCompatibility.length === compatibility?.length,
        enabledOnly,
      });
    }, 250);

    return () => clearTimeout(timeout);
  }, [
    selectedDevices,
    searchTerm,
    enabledOnly,
    selectedFramework,
    selectedCompatibility,
  ]);

  return (
    <div
      className={setClass(`bl-blueprint-page__filter`, 'b-flex-vc', className)}
    >
      <Checkbox
        label="Show enabled only"
        checked={enabledOnly}
        onChange={() => setEnabledOnly((prev) => !prev)}
      />
      {searchFor === types.LIBRARY && (
        <ChipSelect
          selected={selectedDevices}
          setSelected={selectOneOrAll}
          options={deviceOptions}
          selectionType="selectOneOrAll"
        />
      )}
      {searchFor.toLowerCase() === types.PARAMETERS && (
        <div className="b-flex-vc b-flex-g2">
          <ChipSelect
            selected={selectedFramework}
            setSelected={selectedOneOrAllFramework}
            options={frameworkOptions}
            selectionType="selectMultiOrAll"
          />
          <ChipSelect
            selected={selectedCompatibility}
            setSelected={selectedOneOrAllCompatibility}
            options={macosCompatibility}
            selectionType="selectMultiOrAll"
          />
        </div>
      )}
      <TextInput
        placeholder={`Search ${
          searchFor === types.LIBRARY ? 'Library Items' : 'Parameters'
        }`}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        icon={searchTerm ? 'circle-xmark' : 'magnifying-glass'}
        onIconClick={() => setSearchTerm((prev) => (prev ? '' : prev))}
      />
    </div>
  );
};

export default Filter;
