import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Checkbox } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

const General = ({ update, isDisabled, settings }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">General</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            disabled={isDisabled}
            label="Allow users to approve system extensions"
            id="allow_users_to_approve_system_ext"
            testId="allow_users_to_approve_system_ext_checkbox"
            checked={settings.AllowUserOverrides}
            onChange={() =>
              update('AllowUserOverrides', (isChecked) => !isChecked)
            }
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            Uncheck to restrict users from approving additional system
            extensions that are not explicitly allowed by configuration
            profiles.
          </p>
        </Setting.Helpers>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

General.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(General);
