import React, { useContext, useCallback } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import General from './sections/general';
import TeamIds from './sections/team-ids';
import { kernelExtensionService } from './service/kernel-extension-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useKernelExtensionService from './service/use-kernel-extension-service';

const KernelExtensionPage = (props) => {
  const { model, setModel, pageState } = props;

  const [validationDep, triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel), []);
  const teamIdsUpdate = useCallback(update('teamIds', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={kernelExtensionService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <General
        settings={model.general}
        isDisabled={pageState.isDisabled}
        update={generalUpdate}
      />
      <TeamIds
        settings={model.teamIds}
        isDisabled={pageState.isDisabled}
        update={teamIdsUpdate}
        itemName={itemConfig.name}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

// KernelExtensionPage.propTypes = {};

const KernelExtension = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useKernelExtensionService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <KernelExtensionPage {...pageProps} testId="kernel-extension-v2" />;
};

export default KernelExtension;
