import { Box, Code, Flex, Icon, styled } from '@kandji-inc/nectar-ui';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import type { CSSProperties } from 'react';
import type React from 'react';
import { Link } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import {
  ParameterType,
  links,
  selectOptionsMapping,
} from 'src/app/common/constants';
import {
  NULL_VALUE_N_DASH,
  getDeviceIconByFamily,
} from 'src/features/visibility/prism/utils/column-helpers/column-utils';

import { formatDate, highlightedText, pluralizeString } from '../../../helpers';
import { FlowTippy, TippyContainer } from '../../../theme';

const Bold = styled('Bold', {
  fontWeight: '$medium',
});

export const overFlowCss: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

/* istanbul ignore next */
const GetDescriptionCell = ({ activity, filter }) => {
  const actionTypeMap = {
    computer_out_of_blueprint: {
      description: 'Moved out',
      icon: 'right-left',
    },
    computer_into_blueprint: {
      description: 'Moved in',
      icon: 'right-left',
    },
    library_item_assignment_changed: {
      description: 'Library Item assignment changed',
      icon: 'grid-2',
    },
    computer_deletion: {
      description: 'Deleted',
      icon: 'trash',
    },
    computer_enrollment: {
      description: 'Device Enrolled',
      icon: 'laptop-mobile',
    },
    parameters_changed: {
      description: 'Blueprint Modified',
      icon: 'kandji-blueprint',
    },
    blueprint_created: {
      description: 'Blueprint Created',
      icon: 'kandji-blueprint',
    },
    blueprint_name_changed: {
      description: 'Blueprint Modified',
      icon: 'kandji-blueprint',
    },
    blueprint_desc_changed: {
      description: 'Blueprint Description',
      icon: 'kandji-blueprint',
    },
    blueprint_duplicated: {
      description: 'Blueprint Duplicated',
      icon: 'copy',
    },
    blueprint_created_from_source: {
      description: 'Blueprint Created',
      icon: 'copy',
    },
    blueprint_created_from_template: {
      description: 'Blueprint Created',
      icon: 'copy',
    },
    assignment_map_changed: {
      description: 'Assignment Map changed',
      icon: 'sitemap',
    },
  };
  const { action_type, details, blueprint, user, created_at } = activity;

  let description;

  if (
    action_type === 'computer_out_of_blueprint' ||
    action_type === 'computer_into_blueprint' ||
    action_type === 'computer_deletion'
  ) {
    description = (
      <span>
        <Bold>{actionTypeMap[action_type]?.description}:</Bold>{' '}
        {details?.computers_count}{' '}
        {pluralizeString(details?.computers_count, 'Device', 'Devices')}
      </span>
    );
  } else if (
    action_type === 'parameters_changed' ||
    action_type === 'blueprint_created'
  ) {
    description = (
      <span>
        <Bold>{actionTypeMap[action_type]?.description}:</Bold> {blueprint.name}
      </span>
    );
  } else if (action_type === 'blueprint_name_changed') {
    description = (
      <span>
        <Bold>{actionTypeMap[action_type]?.description}:</Bold>{' '}
        {details?.new_name}
      </span>
    );
  } else if (action_type === 'blueprint_desc_changed') {
    description = (
      <span>
        <Bold>{actionTypeMap[action_type]?.description}:</Bold> Modified
      </span>
    );
  } else if (action_type === 'library_item_assignment_changed') {
    description = (
      <span>
        <div>
          <Bold>Edited by:</Bold> {user}
        </div>
        <div>
          <Bold>Edited:</Bold> {formatDate(created_at, true)}
        </div>
      </span>
    );
  } else if (action_type === 'blueprint_created_from_source') {
    description = (
      <span>
        <Bold>{actionTypeMap[action_type]?.description}:</Bold>{' '}
        {details.new_blueprint_name}
      </span>
    );
  } else if (action_type === 'blueprint_duplicated') {
    description = (
      <span>
        <Bold>Blueprint:</Bold> Duplicated
      </span>
    );
  } else if (action_type === 'blueprint_created_from_template') {
    description = (
      <span>
        <Bold>{actionTypeMap[action_type]?.description}:</Bold>{' '}
        {details.new_blueprint_name}
      </span>
    );
  } else {
    description = actionTypeMap[action_type]?.description;
  }

  return (
    <Flex alignItems="center" gap="md">
      <Icon
        name={actionTypeMap[action_type]?.icon}
        color="var(--color-neutral-80)"
        size="sm"
      />
      <div>{description}</div>
    </Flex>
  );
};

const ComputerList = styled('ul', {
  margin: '$2 0 $4 0',
});

const ExpandedContentContainer = styled(Box, {
  whiteSpace: 'normal',
});

const ItemId = styled(Code, {
  fontSize: '$1',
});

/* istanbul ignore next -- not new functionality, just copied from old BP activity table */
export const ExpandedContentCell = ({ activity, parametersMeta }) => {
  const { action_type, details } = activity;

  if (
    action_type === 'computer_out_of_blueprint' ||
    action_type === 'computer_into_blueprint'
  ) {
    return (
      <ExpandedContentContainer>
        {details?.computers_count}{' '}
        {pluralizeString(details?.computers_count, 'device', 'devices')} moved
        from <Bold>{details.old_blueprint_name || '(unassigned)'}</Bold> to{' '}
        <Bold>{details.new_blueprint_name}</Bold>
        <ComputerList>
          {details.computer_names.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </ComputerList>
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'computer_deletion') {
    return (
      <ExpandedContentContainer>
        {details?.computers_count}{' '}
        {pluralizeString(details?.computers_count, 'device', 'devices')} deleted
        from {details.blueprint_name}
        <ComputerList>
          {details.computer_names.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </ComputerList>
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'blueprint_name_changed') {
    return (
      <ExpandedContentContainer>
        Name changed from <Bold>{details.old_name}</Bold> to{' '}
        <Bold>{details.new_name}</Bold>
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'blueprint_desc_changed') {
    return (
      <ExpandedContentContainer>
        Description changed to <Bold>{details.description}</Bold>
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'blueprint_created') {
    return (
      <ExpandedContentContainer>
        Blueprint <Bold>{details.name}</Bold> created
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'blueprint_duplicated') {
    return (
      <ExpandedContentContainer>
        Blueprint <Bold>{details.current_blueprint_name}</Bold> duplicated to{' '}
        <Bold>{details.new_blueprint_name}</Bold>
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'blueprint_created_from_source') {
    return (
      <ExpandedContentContainer>
        <div>
          Duplicated from{' '}
          <Bold>
            {details.source_blueprint_name} (ID: {details.source_blueprint_id})
          </Bold>
        </div>
        {details?.library_items?.added_from_source_blueprint.length > 0 && (
          <div>
            <p>Library Items assigned:</p>
            <ComputerList>
              {details?.library_items?.added_from_source_blueprint.map(
                (item) => (
                  <li key={item}>{item}</li>
                ),
              )}
            </ComputerList>
          </div>
        )}
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'blueprint_created_from_template') {
    return (
      <ExpandedContentContainer>
        <div>Created from template: {details.current_blueprint_name}</div>
        {details?.library_items?.added_from_template.length > 0 && (
          <div>
            <p>Library Items created and assigned:</p>
            <ComputerList>
              {details?.library_items?.added_from_template.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ComputerList>
          </div>
        )}
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'computer_enrollment') {
    const enrollmentTypeMap = {
      STANDARD: 'STANDARD',
      SILENT: 'SILENT',
      AUTO: 'AUTO',
      MDM: 'MDM Manual Enrollment',
      DEP: 'MDM Auto-Enroll (DEP)',
      ADDE: 'Account Driven Device Enrollment',
      ADUE: 'ACcount Driven User Enrollment',
      NOT_FOUND: 'Not Found',
    };

    return (
      <ExpandedContentContainer>
        {details.enrollment_type && (
          <p>Enrollment type: {enrollmentTypeMap[details.enrollment_type]}</p>
        )}
        {details?.computer?.enrolled_at && (
          <p>
            Enrollment date: {formatDate(details.computer.enrolled_at, true)}
          </p>
        )}
        {details?.computer?.name && <p>Device name: {details.computer.name}</p>}
        <p>Blueprint: {activity?.blueprint?.name}</p>
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'parameters_changed') {
    return (
      <ExpandedContentContainer>
        {Object.entries(details).map((el) => {
          const paramName = parametersMeta[el[0]]
            ? parametersMeta[el[0]].name
            : el[1].name;

          if (el[1].type_of_event === 'value_changed') {
            const paramChangedValues = prepareChangedValues(el, parametersMeta);
            return (
              <div key={uuidv4()}>
                <p className="history-details-p" key={uuidv4()}>
                  <i className="far fa-toggle-on history-details-icon" />
                  <span>{`Parameter Enabled - ${paramName}`}</span>
                </p>
                {paramChangedValues &&
                  paramChangedValues.map((paramVal) => (
                    <p key={uuidv4()} className="history-param-value">
                      {paramVal}
                    </p>
                  ))}
              </div>
            );
          }

          if (el[1].type_of_event === 'turned_on') {
            const paramTurnedOnValues = prepareTurnedOnValues(
              el,
              parametersMeta,
            );
            return (
              <div key={uuidv4()}>
                <p className="history-details-p" key={uuidv4()}>
                  <i className="far fa-toggle-on history-details-icon" />
                  <span>{`Parameter Enabled - ${paramName}`}</span>
                </p>
                {paramTurnedOnValues &&
                  paramTurnedOnValues.length > 0 &&
                  paramTurnedOnValues.map((paramVal) => (
                    <p key={uuidv4()} className="history-param-value">
                      {paramVal}
                    </p>
                  ))}
              </div>
            );
          }

          if (el[1].type_of_event === 'turned_off') {
            return (
              <p className="history-details-p" key={uuidv4()}>
                <i className="far fa-toggle-off history-details-icon" />
                <span>{`Parameter Disabled - ${paramName}`}</span>
              </p>
            );
          }

          return '';
        })}
      </ExpandedContentContainer>
    );
  }

  if (action_type === 'assignment_map_changed') {
    return (
      <ExpandedContentContainer>
        <p>
          Assignment Map changed by <Bold>{activity.user}</Bold>
        </p>
        {details.library_items_added.length > 0 && (
          <>
            <p>
              <Bold>
                {details.library_items_added.length} Library{' '}
                {pluralizeString(
                  details.library_items_added.length,
                  'Item',
                  'Items',
                )}
              </Bold>{' '}
              added
            </p>
            <ComputerList>
              {details.library_items_added.map((item) => (
                <li key={item.id}>
                  {item.name} (<ItemId>{item.id}</ItemId>)
                </li>
              ))}
            </ComputerList>
          </>
        )}
        {details.library_items_removed.length > 0 && (
          <>
            <p>
              <Bold>
                {details.library_items_removed.length} Library{' '}
                {pluralizeString(
                  details.library_items_removed.length,
                  'Item',
                  'Items',
                )}
              </Bold>{' '}
              deleted
            </p>
            <ComputerList>
              {details.library_items_removed.map((item) => (
                <li key={item.id}>
                  {item.name} (<ItemId>{item.id}</ItemId>)
                </li>
              ))}
            </ComputerList>
          </>
        )}
        {details.library_items_changed_rules.length > 0 && (
          <>
            <p>
              <Bold>
                {details.library_items_changed_rules.length} Library{' '}
                {pluralizeString(
                  details.library_items_changed_rules.length,
                  'Item',
                  'Items',
                )}
              </Bold>{' '}
              changed rules
            </p>
            <ComputerList>
              {details.library_items_changed_rules.map((item) => (
                <li key={item.id}>
                  {item.name} (<ItemId>{item.id}</ItemId>)
                </li>
              ))}
            </ComputerList>
          </>
        )}
        {details.assignment_nodes_added > 0 && (
          <p>
            <Bold>
              {details.assignment_nodes_added} assignment{' '}
              {pluralizeString(details.assignment_nodes_added, 'node', 'nodes')}
            </Bold>{' '}
            added
          </p>
        )}
        {details.assignment_nodes_removed > 0 && (
          <p>
            <Bold>
              {details.assignment_nodes_removed} assignment{' '}
              {pluralizeString(
                details.assignment_nodes_removed,
                'node',
                'nodes',
              )}
            </Bold>{' '}
            deleted
          </p>
        )}
        {details.assignment_nodes_changed_rules > 0 && (
          <p>
            <Bold>
              {details.assignment_nodes_changed_rules} assignment node
            </Bold>{' '}
            {pluralizeString(
              details.assignment_nodes_changed_rules,
              'rule',
              'rules',
            )}{' '}
            changed rules
          </p>
        )}
        {details.conditional_blocks_added > 0 && (
          <p>
            <Bold>
              {details.conditional_blocks_added} conditional{' '}
              {pluralizeString(
                details.conditional_blocks_added,
                'block',
                'blocks',
              )}
            </Bold>{' '}
            added
          </p>
        )}
        {details.conditional_blocks_removed > 0 && (
          <p>
            <Bold>
              {details.conditional_blocks_removed} conditional{' '}
              {pluralizeString(
                details.conditional_blocks_removed,
                'block',
                'blocks',
              )}
            </Bold>{' '}
            deleted
          </p>
        )}
      </ExpandedContentContainer>
    );
  }

  return '';
};

const GetDeviceCell = ({ activity, filter }) => {
  const { computer } = activity;
  const icon = getDeviceIconByFamily(computer?.device_family);

  return (
    <Flex alignItems="center" gap="sm">
      {computer ? (
        <>
          {computer?.device_family && (
            <img
              data-testid="device_icon_image"
              height="20"
              width="20"
              src={icon}
              alt={computer.device_family}
            />
          )}
          <Link
            to={`${links.devices}/${computer?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...overFlowCss }}
          >
            {highlightedText(computer?.name, filter.term)}
          </Link>
        </>
      ) : (
        NULL_VALUE_N_DASH
      )}
    </Flex>
  );
};

const GetExpandCell = ({ isExpanded }) => (
  <Box>
    <Icon
      name={isExpanded ? 'fa-angle-up-small' : 'fa-angle-down-small'}
      size="sm"
      color="var(--color-neutral-80)"
    />
  </Box>
);

const GetUserCell = ({ activity, filter }) => {
  const { user } = activity;

  return (
    <Flex alignItems="center" gap="sm">
      {highlightedText(user || NULL_VALUE_N_DASH, filter.term)}
    </Flex>
  );
};

const getDateTimeCell =
  (key) =>
  ({ activity }) => (
    <Box>
      <FlowTippy
        content={
          <TippyContainer>{formatDate(activity[key], true)}</TippyContainer>
        }
        popperOptions={{ strategy: 'fixed' }}
      >
        <Box as="span">{formatDate(activity[key])}</Box>
      </FlowTippy>
    </Box>
  );

export type TableColumn = {
  name: string;
  dataKey: string;
  headerCell?: React.FunctionComponent<any>;
  cell?: React.FunctionComponent<any>;
  expandedContent?: React.FunctionComponent<any>;
  isSortable?: boolean;
  meta?: {
    header?: {
      css?: any;
    };
    row?: {
      css?: any;
    };
  };
  style?: any;
};

export const columns: Array<TableColumn> = [
  {
    name: '',
    dataKey: '',
    cell: GetExpandCell,
    isSortable: false,
    meta: {
      header: {
        css: {
          width: 15,
        },
      },
    },
  },
  {
    name: 'Description',
    dataKey: 'name',
    cell: GetDescriptionCell,
    isSortable: false,
    meta: {
      header: {
        css: {
          width: 400,
        },
      },
    },
  },
  {
    name: 'Device',
    dataKey: 'computer',
    cell: GetDeviceCell,
    isSortable: true,
  },
  {
    name: 'Kandji user',
    dataKey: 'user',
    cell: GetUserCell,
    isSortable: true,
  },
  {
    name: 'Date',
    dataKey: 'created_at',
    cell: getDateTimeCell('created_at'),
    isSortable: true,
    meta: {
      header: {
        css: {
          width: 60,
        },
      },
    },
  },
];

/* istanbul ignore next -- not new functionality, just copied from old BP activity table */
const prepareChangedValues = (el, parameterMeta) => {
  if (parameterMeta[el[0]]) {
    const paramInputType = parameterMeta[el[0]].input
      ? parameterMeta[el[0]].input.type
      : null;
    const diff = { ...el[1].diff };
    const result = [];
    switch (paramInputType) {
      case 'disable_with_restrictions':
        result.push(
          `Value: ${selectOptionsMapping[el[0]][!isEmpty(diff.details)]}`,
        );
        if (!isEmpty(get(diff.details, 'users', []))) {
          result.push(`Users: ${diff.details.users.join(', ')}`);
        }
        if (!isEmpty(get(diff.details, 'groups', []))) {
          result.push(`Groups: ${diff.details.groups.join(', ')}`);
        }
        return result;
      case 'application_blacklisting':
        Object.entries(diff.details).forEach((entry) => {
          result.push(selectOptionsMapping[el[0]][entry[0]]);
          result.push('-----');
          if (entry[0] === 'message_customization') {
            Object.entries(entry[1]).forEach((mEl) => {
              result.push(
                `-- ${selectOptionsMapping[el[0]][mEl[0]]}: ${mEl[1]}`,
              );
            });
            result.push('-----');
          } else {
            entry[1].forEach((l) => {
              Object.entries(l).forEach((ll) => {
                const fieldsName = [
                  'by_developer_id',
                  'by_bundle_id',
                  'by_path',
                  'by_process',
                ];
                if (fieldsName.includes(entry[0])) {
                  if (ll[0] !== 'type') {
                    result.push(
                      `-- ${selectOptionsMapping[el[0]][ll[0]]}: ${ll[1]}`,
                    );
                  } else {
                    result.push(
                      `- Match type: ${selectOptionsMapping[el[0]][ll[1]]}`,
                    );
                  }
                }
              });
              result.push('-----');
            });
          }
        });
        return result;
      case 'text_or_file':
        if (diff.details && diff.details.type === 'text') {
          result.push(`Value: ${diff.details.value}`);
        } else if (diff.details && diff.details.type === 'file') {
          result.push(
            `Value: ${diff.details.filename}.${diff.details.extension}`,
          );
        }
        return result;
      case 'hot_corner':
        if (!isEmpty(diff) && !diff.details) {
          return result;
        }
        if (typeof diff.details === 'string') {
          result.push(`Corner: ${diff.details}`);
          return result;
        }
        Object.keys(diff.details).map((key) =>
          result.push(
            `${key.capitalize()}: ${
              selectOptionsMapping[el[0]][diff.details[key]]
            }`,
          ),
        );
        return result;
      case 'secure_wifi_settings':
        Object.keys(diff.details).forEach((key) =>
          result.push(
            `${selectOptionsMapping[el[0]][key]}: ${diff.details[key]}`,
          ),
        );
        return result;
      case 'security_auditing_flags':
        if (isEmpty(diff.details)) {
          return null;
        }
        diff.details.forEach((item, index) => {
          result.push(`-- Flag: ${selectOptionsMapping[el[0]][item.flag]}`);
          result.push(`-- Prefix: ${selectOptionsMapping[el[0]][item.prefix]}`);
          if (index !== diff.details.length - 1) {
            result.push('-----');
          }
        });
        return result;
      case ParameterType.set_computer_name:
        if (diff.details.pattern) {
          result.push(
            `-- Pattern: ${diff.details.pattern
              .map((d) =>
                d.startsWith('custom_text')
                  ? selectOptionsMapping[el[0]].custom_text
                  : selectOptionsMapping[el[0]][d],
              )
              .join(' - ')}`,
          );
        }
        if (!isEmpty(diff.details.custom_text)) {
          result.push(
            `-- Custom Text: ${Object.values(diff.details.custom_text).join(
              ', ',
            )}`,
          );
        }
        return result;
      case 'path': {
        const prevValues = el[1].from.details ? el[1].from.details : [];
        const newValues = el[1].to.details;
        const removed = prevValues
          ? prevValues.filter((prevVal) => !newValues.includes(prevVal))
          : [];
        const added = prevValues
          ? newValues.filter((prevVal) => !prevValues.includes(prevVal))
          : newValues;
        if (added.length > 0) {
          result.push(`Added: ${added}`);
        }
        if (removed.length > 0) {
          result.push(`Removed: ${removed}`);
        }
        delete diff.details;
        Object.keys(diff).forEach((key) => {
          result.push(`${key.toString()}: ${diff[key].toString()}`);
        });
        return result;
      }
      case 'multi_value':
        Object.keys(diff.details).map((key) =>
          result.push(`${key.capitalize()}: ${diff.details[key]}`),
        );
        return result;
      case 'multi_array': {
        const getNames = () => {
          const names = [];
          const items = parameterMeta[el[0]].input.fields.map((field) => [
            field.key,
            field.weight,
          ]);
          items.sort((first, second) => first[1] - second[1]);
          items.map((item) => {
            if (item[0] !== 'password' && item[0] !== 'password2') {
              names.push(item[0]);
            }
            return item;
          });
          return names;
        };
        diff.details.map((detail, index) => {
          result.push(`Account #${index + 1}`);
          const namesOfFields = getNames();
          namesOfFields.map((key) => {
            const fieldData = parameterMeta[el[0]].input.fields.find(
              (field) => field.key === key,
            );
            return result.push(`${fieldData.text.capitalize()} ${detail[key]}`);
          });
          if (diff.details.length > 1 && index !== diff.details.length - 1) {
            result.push(
              <div
                style={{
                  borderTop: '1px solid #e3eaf2',
                  width: 150,
                }}
              />,
            );
          }

          return detail;
        });
        return result;
      }
      default: {
        let value;
        if (!(diff.details === undefined || diff.details === null)) {
          const isOwnProp = Object.prototype.hasOwnProperty.call(
            selectOptionsMapping,
            el[0],
          );
          value = isOwnProp
            ? selectOptionsMapping[el[0]][diff.details]
            : diff.details.toString();
          result.push(`Value: ${value}`);
          delete diff.details;
        }
        Object.keys(diff).forEach((key) => {
          result.push(`${key.toString()}: ${diff[key].toString()}`);
        });
        return result;
      }
    }
  }

  return '';
};

/* istanbul ignore next -- not new functionality, just copied from old BP activity table */
const prepareTurnedOnValues = (el, parameterMeta) => {
  if (parameterMeta[el[0]]) {
    let value;
    const result = [];
    const { details } = el[1];
    let paramInputType = null;
    if (!has(parameterMeta[el[0]], 'input')) {
      paramInputType = 'deleted';
    } else if (parameterMeta[el[0]].input) {
      paramInputType = parameterMeta[el[0]].input.type;
    }
    if (paramInputType === 'multi_array') {
      const getNames = () => {
        const names = [];
        const items = parameterMeta[el[0]].input.fields.map((field) => [
          field.key,
          field.weight,
        ]);
        items.sort((first, second) => first[1] - second[1]);
        items.map((item) => {
          if (item[0] !== 'password' && item[0] !== 'password2') {
            names.push(item[0]);
          }
          return item;
        });
        return names;
      };
      details.map((detail, index) => {
        result.push(`Account #${index + 1}`);
        const namesOfFields = getNames();
        namesOfFields.map((key) =>
          result.push(`${key.capitalize()}: ${detail[key]}`),
        );
        if (details.length > 1 && index !== details.length - 1) {
          result.push(
            <div style={{ borderTop: '1px solid #e3eaf2', width: 150 }} />,
          );
        }

        return detail;
      });
      return result;
    }

    if (paramInputType === 'disk_media_accesses') {
      const getNames = () => {
        const names = [];
        const items = Object.keys(parameterMeta[el[0]].input.options).map(
          (field) => [field, parameterMeta[el[0]].input.options[field].weight],
        );
        items.sort((first, second) => first[1] - second[1]);
        items.map((item) => names.push(item[0]));
        return names;
      };
      const namesOfFields = getNames();
      namesOfFields.map((key) =>
        result.push(
          `${parameterMeta[el[0]].input.options[key].title}: ${
            details[key].allow
          }`,
        ),
      );
      return result;
    }
    if (paramInputType === 'disable_with_restrictions') {
      result.push(`Value: ${selectOptionsMapping[el[0]][!isEmpty(details)]}`);
      if (!isEmpty(get(details, 'users', []))) {
        result.push(`Users: ${details.users.join(', ')}`);
      }
      if (!isEmpty(get(details, 'groups', []))) {
        result.push(`Groups: ${details.groups.join(', ')}`);
      }
      return result;
    }
    if (paramInputType === 'application_blacklisting') {
      Object.entries(details).forEach((entry) => {
        result.push(selectOptionsMapping[el[0]][entry[0]]);
        result.push('-----');
        if (entry[0] === 'message_customization') {
          Object.entries(entry[1]).forEach((mEl) => {
            result.push(`-- ${selectOptionsMapping[el[0]][mEl[0]]}: ${mEl[1]}`);
          });
          result.push('-----');
        } else {
          entry[1].forEach((l) => {
            Object.entries(l).forEach((ll) => {
              const fieldsName = [
                'by_developer_id',
                'by_bundle_id',
                'by_path',
                'by_process',
              ];
              if (fieldsName.includes(entry[0])) {
                if (ll[0] !== 'type') {
                  result.push(
                    `-- ${selectOptionsMapping[el[0]][ll[0]]}: ${ll[1]}`,
                  );
                } else {
                  result.push(
                    `- Match type: ${selectOptionsMapping[el[0]][ll[1]]}`,
                  );
                }
              }
            });
            result.push('-----');
          });
        }
      });
      return result;
    }
    if (paramInputType === 'hot_corner') {
      if (typeof details === 'string') {
        result.push(`Corner: ${details}`);
        return result;
      }
      Object.keys(details).forEach((key) =>
        result.push(
          `${key.capitalize()}: ${selectOptionsMapping[el[0]][details[key]]}`,
        ),
      );
      return result;
    }
    if (paramInputType === 'secure_wifi_settings') {
      Object.keys(details).forEach((key) =>
        result.push(`${selectOptionsMapping[el[0]][key]}: ${details[key]}`),
      );
      return result;
    }
    if (paramInputType === 'security_auditing_flags') {
      if (isEmpty(details)) {
        return null;
      }
      details.forEach((item, index) => {
        result.push(`-- Flag: ${selectOptionsMapping[el[0]][item.flag]}`);
        result.push(`-- Prefix: ${selectOptionsMapping[el[0]][item.prefix]}`);
        if (index !== details.length - 1) {
          result.push('-----');
        }
      });
      return result;
    }
    if (paramInputType === ParameterType.set_computer_name) {
      if (isEmpty(details)) {
        return null;
      }
      if (details.pattern) {
        result.push(
          `-- Pattern: ${details.pattern
            .map((d) =>
              d.startsWith('custom_text')
                ? selectOptionsMapping[el[0]].custom_text
                : selectOptionsMapping[el[0]][d],
            )
            .join(' - ')}`,
        );
      }
      if (details.custom_text) {
        result.push(
          `-- Custom Text: ${Object.values(details.custom_text).join(', ')}`,
        );
      }
      return result;
    }
    if (Array.isArray(details) && details.length > 0) {
      details.map((detailVal) => result.push(`Value: ${detailVal}`));
    } else if (typeof details === 'object') {
      if (isEmpty(details)) {
        return null;
      }
      if (details && details.type === 'file') {
        result.push(`Value: ${details.filename}.${details.extension}`);
      } else {
        Object.keys(details).map((key) =>
          result.push(`${key.capitalize()}: ${details[key]}`),
        );
      }
    } else if (details && typeof details !== 'object') {
      const isOwnProp = Object.prototype.hasOwnProperty.call(
        selectOptionsMapping,
        el[0],
      );
      value = isOwnProp
        ? selectOptionsMapping[el[0]][el[1].details]
        : el[1].details.toString();
      result.push(`Value: ${value}`);
    }
    return result;
  }

  return '';
};
