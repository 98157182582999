import { NumberParam, withDefault } from 'use-query-params';

function createPaginationParam<Page, PageSize>(
  defaultPage: Page | undefined,
  defaultPageSize: PageSize | undefined,
) {
  const PageParam = withDefault(NumberParam, defaultPage);
  const PageSizeParam = withDefault(NumberParam, defaultPageSize);

  return { PageParam, PageSizeParam };
}

export default createPaginationParam;
