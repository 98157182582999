import { Checkbox } from '@kandji-inc/bumblebee';
import React, { memo } from 'react';

import { Setting } from 'features/library-items/template';

const OptionsCard = ({ setting, update, isDisabled }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">Options</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        {' '}
        <Setting.Controls>
          <Checkbox
            checked={setting.addToDock}
            label="Add to Dock during install"
            onChange={() => update('addToDock', (p) => !p)}
            isDisabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            Kandji will add the app icon to the dock during initial app install,
            or during re-install if the app is found to be missing. Kandji will
            not automatically re-add the icon to the dock if the app is updated.
          </p>
        </Setting.Helpers>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

export default memo(OptionsCard);
