import { Tr } from '@kandji-inc/nectar-ui';
import type { Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import * as React from 'react';

import TableCell from './TableCell';

export type FleetTableBodyProps = {
  rows: Row<unknown>[];
};

export const PrismTableCell = ({ cell }) => {
  const cellCtx = cell.getContext();
  const { table } = cellCtx;
  const cellScope = table.options.meta?.fixedColumns?.[cell.column.id]
    ? 'row'
    : undefined;
  const cellValue = String(cell.getValue());
  const cellTitle = ['undefined', 'null', 'NaN'].includes(cellValue)
    ? ''
    : cellValue;

  return (
    <TableCell.RowCell
      key={cell.id}
      columnId={cell.column.id}
      scope={cellScope}
      size={cell.column.getSize()}
      isResizingColumn={table.getState().columnSizingInfo.isResizingColumn}
      data-pinned={cellScope === 'row'}
      title={cellTitle}
    >
      {flexRender(cell.column.columnDef.cell, cellCtx)}
    </TableCell.RowCell>
  );
};

const FleetTableBody = (props: FleetTableBodyProps) => {
  const { rows } = props;

  return (
    <>
      {rows.map((row) => (
        <Tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <PrismTableCell cell={cell} />
          ))}
        </Tr>
      ))}
    </>
  );
};

export default FleetTableBody;
