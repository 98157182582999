import './style.css';

import { Tabs } from '@kandji-inc/bumblebee';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import useScrollToTop from 'src/hooks/useScrollToTop';

import { Library } from 'features/library-items/template';

import featureFlags from '../../config/feature-flags';

import InviteUser from '../../features/invite-user';

import Access from './tab-access';
import General from './tab-general';
import Integrations from './tab-integrations';
import Licensing from './tab-licensing';
import SelfService from './tab-self-service';

export default () => {
  const showLicensing = featureFlags.getFlag('kit_092022_stripe_billing');
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const isAccess = pathname === `${path}/access`;

  const tabs = [
    {
      label: 'General',
    },
    {
      label: 'Apple integrations',
      route: 'integrations',
    },
    {
      label: 'Self Service',
      route: 'self-service',
    },
    {
      label: 'Access',
      route: 'access',
    },
    ...(showLicensing
      ? [
          {
            label: 'Licensing',
            route: 'licensing',
          },
        ]
      : []),
  ];
  useScrollToTop();

  return (
    <Library style={{ minHeight: '1600px' }} className="k-settings">
      <h1 className="b-h1 b-mb2 b-flex-btw">
        Settings
        {isAccess && <InviteUser />}
      </h1>
      <Tabs className="k-settings-tabs" tabs={tabs}>
        <General tabid="General" />
        <Integrations tabid="Apple integrations" />
        <SelfService tabid="Self Service" />
        {showLicensing && <Licensing tabid="Licensing" />}
        <Access tabid="Access" />
      </Tabs>
    </Library>
  );
};
