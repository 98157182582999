/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import type { Edge, Node } from 'reactflow';

import { api } from 'app/api/base';
import { URL_COMPUTERS } from 'app/api/urls';

import type { MapSelectedDevicePath } from '../blueprint-flow.types';
import { transformEdgesToApi, transformNodestoApi } from './blueprints';

const useDevicePath = (
  computerId: string,
  graph: { nodes: Array<Node>; edges: Array<Edge> },
  keys: Array<any> = [],
  isEnabled = false,
) => {
  const {
    data: apiRes,
    isLoading,
    isError,
    ...rest
  } = useQuery<MapSelectedDevicePath>({
    queryKey: ['am-device-path', ...(keys || [])],
    queryFn: () =>
      api(`${URL_COMPUTERS}${computerId}/assignment_map_evaluation`)
        .post({
          graph: {
            nodes: transformNodestoApi(graph.nodes),
            edges: transformEdgesToApi(graph.edges),
          },
        })
        .then(({ data }) => ({
          nodes: data.node_ids,
          edges: data.edge_ids,
          libraryItems: data.library_item_ids,
        })),
    enabled: isEnabled,
  });

  return {
    data: apiRes,
    queryKey: ['am-device-path', ...(keys || [])],
    queryKeyBase: ['am-device-path'],
    isLoading,
    isError,
    ...rest,
  };
};

export default useDevicePath;
