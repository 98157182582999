import { Code, Flex, Text, styled } from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import CopyButton from 'src/features/threat/common/components/CopyButton';
import { formatDate } from 'src/features/vulnerability/helpers';
import type { AppSummary } from 'src/features/vulnerability/vulnerability.types';

const DetailText = styled(Text, {
  fontSize: '$1',
  color: '$neutral70',
});

const DevicePath = (props: { app: AppSummary }) => {
  const { path, detection_date, version } = props.app;
  const [isHoveringPath, setIsHoveringPath] = useState(false);

  return (
    <Flex
      alignItems="center"
      pt3
      pb3
      css={{ gap: '40px', borderTop: '1px solid $neutral20' }}
      onMouseLeave={() => setIsHoveringPath(false)}
    >
      <Flex
        alignItems="center"
        gap="xs"
        onMouseEnter={() => setIsHoveringPath(true)}
        onMouseLeave={() => setIsHoveringPath(false)}
        css={{ width: '240px' }}
      >
        <DetailText>Path:</DetailText>
        <Code
          css={{
            fontSize: '$1',
            lineHeight: '$1',
            maxWidth: '177px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {path}
        </Code>
        {isHoveringPath && (
          <CopyButton
            value={path}
            testId="path-copy-button"
            css={{ zIndex: 2 }}
          />
        )}
      </Flex>

      <Flex alignItems="center" gap="xs">
        <DetailText>Detection date:</DetailText>
        <Text size="1">{formatDate(detection_date)}</Text>
      </Flex>

      <Flex alignItems="center" gap="xs">
        <DetailText>Version:</DetailText>
        <Text size="1">{version}</Text>
      </Flex>
    </Flex>
  );
};

export { DevicePath };
