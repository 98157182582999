import React, { useState } from 'react';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import { setDeviceName } from '../../computer/api';

const ComputerSetMDMDeviceName = (props) => {
  const { info, onHide } = props;
  const { computerId, deviceFamily } = info;

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();

  const placeholderMap = {
    Mac: "Logan's Mac",
    iPad: "Logan's iPad",
    iPhone: "Logan's iPhone",
    iPod: "Logan's iPod touch",
    AppleTV: '5th Floor Conference Room Apple TV',
  };

  const handleOnDeviceNameChange = (e) => setName(e.target.value);

  const onSetDeviceName = () => {
    setIsLoading(true);
    setDeviceName(computerId, name)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster('Command initiated.');
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster('Something went wrong.');
      });
  };

  const content = (
    <Flex flow="column" gap="md" css={{ minWidth: '$15' }}>
      <Text>Enter the updated device name you would like to set.</Text>
      <TextField
        aria-label="device-name"
        placeholder={placeholderMap[deviceFamily]}
        onChange={handleOnDeviceNameChange}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!name}
        onClick={onSetDeviceName}
      >
        Set Device Name
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Set Device Name"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerSetMDMDeviceName;
