import _cond from 'lodash/cond';
import _stubTrue from 'lodash/stubTrue';
import PropTypes from 'prop-types';
import React from 'react';

import { billingTypes } from 'src/app/common/constants';

import { getDaysBetween } from '../../common/helpers';
import UniversalAlert from './UniversalAlert';

export const isTrial = ({ billingType, daysLeft }) =>
  billingType === billingTypes.TRIAL && daysLeft;

export const trialExpired = ({ billingType, daysLeft }) =>
  billingType === billingTypes.TRIAL && !daysLeft;

export const isTrialMessage = ({ daysLeft, hubspotQuoteLink }) => ({
  message: `You have ${daysLeft} days left in your trial. ${
    hubspotQuoteLink ? 'Ready to buy?' : ''
  }`,
  cta: hubspotQuoteLink ? 'View Quote' : '',
  action: () => window.open(hubspotQuoteLink),
});

export const trialExpiredMessage = ({ hubspotQuoteLink }) => ({
  message: `Your trial has expired.`,
  cta: hubspotQuoteLink ? 'View Quote' : '',
  action: () => window.open(hubspotQuoteLink),
});

const getMessaging = _cond([
  [isTrial, isTrialMessage],
  [trialExpired, trialExpiredMessage],
  [_stubTrue, () => ({})],
]);

const TrialPeriodBanner = ({ trialBannerData, billingType }) => {
  const trialEndDate = Date.parse(trialBannerData.trial_end_date);
  const now = Date.now();
  const dateIsNotExpired = trialEndDate && now < trialEndDate + 1;
  const daysLeft = dateIsNotExpired
    ? getDaysBetween(Date.parse(trialBannerData.trial_end_date), Date.now()) + 1
    : null;

  const {
    message = '',
    cta = '',
    action = () => null,
  } = getMessaging({
    billingType,
    daysLeft,
    hubspotQuoteLink: trialBannerData.hubspot_quote_link,
  });

  const button = cta
    ? {
        text: cta,
        onClick: action,
      }
    : null;

  return (
    <UniversalAlert type="warning" text={message} button={button} closable />
  );
};

TrialPeriodBanner.propTypes = {
  trialBannerData: PropTypes.shape({
    is_active: PropTypes.bool,
    hubspot_quote_link: PropTypes.string,
    trial_end_date: PropTypes.string,
  }),
  billingType: PropTypes.string.isRequired,
};

TrialPeriodBanner.defaultProps = {
  trialBannerData: {
    is_active: false,
    hubspot_quote_link: null,
    trial_end_date: null,
  },
};

export default TrialPeriodBanner;
