import { Chip, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../../service/wifi-service';
import { keySizeOptions, keyUsageOptions } from '../../protocols-card.model';

const keys = WifiService.keys.authentication.scep;

const tags = [
  'macOS 10.11+',
  'iPadOS 13+',
  'tvOS 9+',
  'iOS 4.0+',
  'CIS',
  'NIST',
  'STIG',
  'Supervised',
];

const renderTag = (tag) => (
  <Chip
    kind="tertiary-light"
    text={tag}
    className="k-ade-chip--m0 k-wifiv2-protocols-general__tag"
    key={tag}
  />
);

const onMenuOpen = () => {};

const Key = ({ update, setting }) => (
  <>
    <Setting.Header testId="scep-key" className="--no-padding --no-shadow">
      <h3 className="b-h3">Key</h3>
    </Setting.Header>
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">Key size</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">Key size, in bits.</p>
        <div className="b-flex-wrap-gtiny">{tags.map(renderTag)}</div>
      </Setting.Helpers>
      <Setting.Controls>
        <div>
          <Select
            options={keySizeOptions}
            compact
            onChange={(v) => update(keys.keySize, v.value)}
            value={keySizeOptions.find(
              (option) => option.value === setting[keys.keySize],
            )}
            onMenuOpen={onMenuOpen}
            testId="key-size-options"
          />
        </div>
      </Setting.Controls>
    </Setting.Row>
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">Key usage</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">Indicate the use of the key.</p>
        <div className="b-flex-wrap-gtiny">{tags.map(renderTag)}</div>
      </Setting.Helpers>
      <Setting.Controls>
        <div>
          <Select
            options={keyUsageOptions}
            compact
            onChange={(v) => update(keys.keyUsage, v.value)}
            value={keyUsageOptions.find(
              (option) => option.value === setting[keys.keyUsage],
            )}
            testId="key-usage-options"
          />
        </div>
      </Setting.Controls>
    </Setting.Row>
  </>
);

export default Key;
