import React from 'react';
import { ProfileTypes } from 'src/app/common/constants';
import styled from 'styled-components';
import { formatTime } from '../common/helpers';

export const Log = styled('div')`
  margin-top: 10px;
  padding-right: 30px;
  margin-left: -28px;
  p,
  li {
    color: #666666;
    white-space: normal;
    font-size: 14px;
    font-weight: 400;
    margin: 14px 0;
  }

  .log-file-download-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
  }
`;

export const LogTitle = styled('h5')`
  font-size: 14px;
`;

export const LogText = styled('div')``;

export const LogSectionWithIndent = styled('p')`
  padding-left: 10px;
`;

const LogDownload = ({ logFileUrl }) => {
  const isDownloadable = typeof logFileUrl === 'string' && Boolean(logFileUrl);

  return isDownloadable ? (
    <div className="b-ml2 b-txt-bold">
      <a
        href={logFileUrl}
        rel="noopener noreferrer"
        className="b-alink b-ml-micro"
      >
        Download log file
      </a>
    </div>
  ) : null;
};

export const formatLogText = (logText) =>
  // index as key is OK -- log text does not change
  logText
    .split('\n')
    .map((l, i) => (
      <LogSectionWithIndent key={i}>{`• ${l}`}</LogSectionWithIndent>
    ));

export const LogRecord = ({ header, date, text, logFileUrl }) => {
  if (!header) {
    return null;
  }

  return (
    <Log>
      <div className={logFileUrl ? 'log-file-download-row' : ''}>
        <LogTitle>
          {header}
          {date && ' - '}
          {date && formatTime(date, null, null, null, true)}
        </LogTitle>
        <LogDownload logFileUrl={logFileUrl} />
      </div>
      {text && <LogText>{formatLogText(text)}</LogText>}
    </Log>
  );
};

export const libraryItemLog = (libraryItem) => {
  const installInProgress = ['CACHED', 'DOWNLOADING', 'INSTALLING'].includes(
    libraryItem.status,
  );
  return (
    <>
      <LogRecord
        header="Last Audit"
        date={libraryItem.last_audit_run}
        text={libraryItem.last_audit_log}
      />
      {libraryItem.log && (
        <LogRecord
          header={installInProgress ? 'Last Install Progress' : 'Last Install'}
          date={libraryItem.date}
          text={libraryItem.log}
        />
      )}
    </>
  );
};

export const vppAppLog = (vppApp) => {
  const isInstall = ['installing', 'INSTALLING'].includes(vppApp.status);

  return (
    <>
      <LogRecord
        header="Last Audit"
        date={vppApp.last_audit_run}
        text={vppApp.last_audit_log}
      />
      {!isInstall && vppApp.log && (
        <LogRecord
          header="Last Install"
          date={vppApp.date}
          text={vppApp.log}
          logFileUrl={vppApp.log_file_url}
        />
      )}
    </>
  );
};

export const standardLog = (item) => (
  <LogRecord header="Last Run" date={item.date} text={item.log} />
);

/* istanbul ignore next -- not a component to test behavior of */
export const profileLog = (
  row,
  alertCreated = null,
  controlLogHeader = null,
) => {
  let controlLogTitle = null;
  if (row.identifier === ProfileTypes.FILEVAULT) {
    controlLogTitle = 'FileVault Status';
  } else if (row.identifier === ProfileTypes.FIREWALL) {
    controlLogTitle = 'Firewall Status';
  }

  return (
    <>
      {alertCreated && <LogRecord header="Alert Created" date={alertCreated} />}
      {row.control_log && (
        <LogRecord
          header={controlLogHeader || controlLogTitle || 'Status'}
          date={row.control_reported_at}
          text={row.control_log}
        />
      )}
      {row.last_audit_log && (
        <LogRecord
          header="Last Profile Audit"
          date={row.last_audit_run}
          text={row.last_audit_log}
        />
      )}
      {row.log && (
        <LogRecord
          header="Last Profile Install"
          date={row.date}
          text={row.log}
        />
      )}
    </>
  );
};
