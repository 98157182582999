import { styled } from '@kandji-inc/nectar-ui';
import type { SortingState } from '@tanstack/react-table';
import * as React from 'react';
import { InterfaceContext } from 'src/contexts/interface';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import { usePagination } from 'src/features/visibility/prism/hooks/use-pagination';
import { useBodyNoMargin } from 'src/hooks/useBodyNoMargin';
import { useHubspotHandler } from 'src/hooks/useHubspotHandler';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { ADEListViewHeader } from './ADEListViewHeader';
import { ADEListViewTable } from './ADEListViewTable/ADEListViewTable';
import { ADENotConfigured } from './ADENotConfigured';
import { useGetADEDeviceCount } from './hooks/useGetADEDeviceCount';
import { useGetADEDevices } from './hooks/useGetADEDevices';
import { useGetADELastSync } from './hooks/useGetADELastSync';
import { useGetADETokens } from './hooks/useGetADETokens';
import { usePrettyLoading } from './hooks/usePrettyLoading';
import {
  type ADEListViewStoreType,
  useADEListViewStore,
} from './store/ADEListViewStore';
import { fromSortingState } from './utils/fromSortingState';
import { getEnrollmentStatusFilter } from './utils/getEnrollmentStatusFilters';
import { getTokenFilters } from './utils/getTokenFilters';
import { getValidSearchColumns } from './utils/getValidSearchColumns';

const ADEListViewContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 108px)',
});

const DEFAULT_PARAMS = {
  SIZE_PER_PAGE: 50,
  page: 1,
};

const DEFAULT_SORTING = {
  id: 'device_assigned_date',
  desc: true,
};

const WARNING_BANNER_HEIGHT = 57;

interface ADEListViewProps {
  isADEConfigured: boolean;
  isWarningBannerOpen: boolean;
}

export function ADEListView({
  isADEConfigured,
  isWarningBannerOpen,
}: ADEListViewProps) {
  const { sidebarDocked } = React.useContext(InterfaceContext);
  const [isAllDevices, setIsAllDevices] = React.useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([DEFAULT_SORTING]);
  const [debouncedSearch, setSearch] = useDebouncedState<string>('', 750);
  const [sizePerPage, setSizePerPage] = React.useState(
    DEFAULT_PARAMS.SIZE_PER_PAGE,
  );

  const {
    paginationState: { pageIndex },
    setPagination,
    resetPagination,
  } = usePagination();

  const {
    devices,
    visibleColumns,
    tokenFilters,
    enrollmentStatusFilter,
    setSelectedDevices,
    setSelectAllDevices,
    setSearchText,
  } = useADEListViewStore((state: ADEListViewStoreType) => ({
    devices: state.devices,
    visibleColumns: state.visibleColumns,
    setDevices: state.setDevices,
    tokenFilters: state.tokenFilters,
    enrollmentStatusFilter: state.enrollmentStatusFilter,
    setSearchText: state.setSearchText,
    setSelectedDevices: state.setSelectedDevices,
    setSelectAllDevices: state.setSelectAllDevices,
  }));

  const {
    refetch: refetchDevices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices,
  } = useGetADEDevices({
    ordering: fromSortingState(sorting),
    sizePerPage,
    page: pageIndex + 1,
    search_columns: getValidSearchColumns(visibleColumns),
    filters: [
      ...getEnrollmentStatusFilter(isAllDevices, enrollmentStatusFilter),
      ...getTokenFilters(tokenFilters),
    ],
    search: debouncedSearch,
  });

  const isLoading = usePrettyLoading(isLoadingDevices || isFetchingDevices);

  const { data: countData } = useGetADEDeviceCount({});

  const deviceCounts = countData ?? {
    all: 0,
    awaitingEnrollment: 0,
  };

  const { data: tokenData } = useGetADETokens();
  const tokens = tokenData ?? [];

  const { data: lastSyncData, refetch: syncDevices } = useGetADELastSync();
  const lastSync =
    lastSyncData != null ? new Date(lastSyncData).getTime() : null;

  React.useEffect(() => {
    resetPagination();
    setSelectedDevices([]);
    setSelectAllDevices(false);
  }, [
    debouncedSearch,
    sorting,
    tokenFilters,
    enrollmentStatusFilter,
    sizePerPage,
    isAllDevices,
    resetPagination,
  ]);

  /* istanbul ignore next */
  React.useEffect(() => {
    if (devices.length > 0 && !isLoading) {
      setSearchText(debouncedSearch);
    }
  }, [devices, setSearchText, debouncedSearch, isLoading]);

  useWhiteBackground();
  useBodyNoMargin();
  useHubspotHandler();

  if (!isADEConfigured) {
    return <ADENotConfigured />;
  }

  const noFilters =
    debouncedSearch.length === 0 &&
    enrollmentStatusFilter == null &&
    tokenFilters.length === 0;

  const hasNoAwaitingDevices =
    deviceCounts.awaitingEnrollment === 0 && noFilters;
  const hasNoADEDevices = deviceCounts.all === 0 && noFilters;

  const offsetTop = isWarningBannerOpen ? WARNING_BANNER_HEIGHT : 0;

  return (
    <ADEListViewContainer
      css={{
        height: `calc(100vh - 108px - ${offsetTop}px)`,
      }}
    >
      <ADEListViewHeader
        isAllDevices={isAllDevices}
        setIsAllDevices={setIsAllDevices}
        deviceCounts={deviceCounts}
      />
      <ADEListViewTable
        isAllDevices={isAllDevices}
        devices={devices}
        tokens={tokens}
        sync={syncDevices}
        refetchDevices={refetchDevices}
        lastSync={lastSync}
        isLoading={isLoading}
        page={pageIndex}
        setPage={setPagination}
        sizePerPage={sizePerPage}
        setSizePerPage={setSizePerPage}
        sorting={sorting}
        setSorting={setSorting}
        setSearch={setSearch}
        deviceCounts={deviceCounts}
        hasNoADEDevices={hasNoADEDevices}
        hasNoAwaitingDevices={hasNoAwaitingDevices}
        setIsAllDevices={setIsAllDevices}
      />
    </ADEListViewContainer>
  );
}
