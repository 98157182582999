import React from 'react';

import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';

type ConfirmNavigationProps = {
  isOpen: boolean;
  toggle: (boolean) => void;
  onConfirm: () => void;
};

function ConfirmNavigation(props: ConfirmNavigationProps) {
  const { isOpen, toggle, onConfirm } = props;

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={() => toggle(false)}>
        Stay on page
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          onConfirm();
          toggle(false);
        }}
      >
        Leave page
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title="Are you sure you want to leave this page?"
      content="Any changes will be lost."
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => toggle(false)}
      css={{ width: '500px', zIndex: 2000 }}
    />
  );
}

export default ConfirmNavigation;
