/* Adding tests later since this is only a placeholder */
/* istanbul ignore file */
import { Flex, SelectV2 as Select, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { ManualEnrollmentPortal } from './ManualEnrollmentPortal';

const ManualEnrollmentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 220px)',
  width: '100%',

  padding: '$4 $5 0 $5',
  overflow: 'auto',
  gap: '$6',
});

interface ManualEnrollmentProps {
  enrollmentPortalActive: boolean;
  toggleEnrollmentPortalStatus: ({
    is_enrollment_portal_active,
  }: {
    is_enrollment_portal_active: boolean;
  }) => Promise<any>;
}

export function ManualEnrollment({
  enrollmentPortalActive,
  toggleEnrollmentPortalStatus,
}: ManualEnrollmentProps) {
  useWhiteBackground();

  return (
    <ManualEnrollmentContainer>
      <Flex>
        <Select
          value="enroll"
          onValueChange={() => {}}
          options={[{ value: 'enroll', label: 'Enroll a single device' }]}
          triggerVariant="default"
        />
      </Flex>
      <ManualEnrollmentPortal
        enrollmentPortalActive={enrollmentPortalActive}
        toggleEnrollmentPortalStatus={toggleEnrollmentPortalStatus}
      />
    </ManualEnrollmentContainer>
  );
}
