import { AccountContext, usePermissions } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { getConfig } from 'features/library-items/library/library-item-configurations/items';
/* istanbul ignore file */
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useFlags } from 'src/config/feature-flags';
import Library from '../library/LibraryNewLayout';
import AddLibraryItem from '../library/add-library-item/add-library-item';
import LibraryOld from '../library/library';
import allLibraryItemsConfiguration, {
  operatingSystemApps,
  profileApps,
} from '../library/library-item-configurations';
import LibraryContext from './library.context';

const LibraryItems = () => {
  const { path } = useRouteMatch();
  const environment = useContext(EnvironmentContext);
  const account = useContext(AccountContext);
  const permissions = usePermissions();

  const typify = (configs) => configs.map((c) => c.routeType).join('|');

  const {
    'lit_10022023_library-findability-thru-layout':
      LDFFlibraryFindabilityThruLayout,
  } = useFlags(['lit_10022023_library-findability-thru-layout']);

  // Not all profile items & operating systems are using the correct routing,
  // for those specific items their paths must be different in order to work
  // correctly.
  const OldPathProfiles = Object.values(profileApps).filter((pc) =>
    pc.isOldPath({ account, environment }),
  );
  const OldPathOperatingSystems = Object.values(operatingSystemApps).filter(
    (oc) => oc.isOldPath({ account, environment }),
  );

  return (
    <Switch>
      <Route
        path={[
          permissions.canManageBlueprints && `${path}/:type/add`,
          `${path}/:type(${typify(OldPathProfiles)})/:profileId/:tab?`,
          `${path}/:type(${typify(OldPathOperatingSystems)})/:itemId/:tab`,
          `${path}/:type/:templateId/add`,
          `${path}/:type/:item`,
        ].filter(Boolean)}
        render={(routeProps) => {
          const libraryItemConfig = Object.values(
            allLibraryItemsConfiguration,
          ).find((lib) => lib.routeType === routeProps.match.params?.type);

          if (
            libraryItemConfig?.component &&
            !libraryItemConfig.isHidden({ account, environment })
          ) {
            const componentKey = Object.values(routeProps.match.params).join(
              '|',
            );
            return (
              <LibraryContext.Provider
                value={{ getConfig, itemConfig: libraryItemConfig }}
              >
                <libraryItemConfig.component
                  {...routeProps}
                  key={componentKey}
                  account={account}
                  environment={environment}
                  itemConfig={libraryItemConfig}
                />
              </LibraryContext.Provider>
            );
          }
          return <Redirect to="/library" />;
        }}
      />
      {permissions.canManageBlueprints && (
        <Route exact path={`${path}/add`} component={AddLibraryItem} />
      )}
      <Route
        exact
        path={`${path}`}
        component={LDFFlibraryFindabilityThruLayout ? Library : LibraryOld}
      />
      <Redirect to="/library" />
    </Switch>
  );
};

export default LibraryItems;
