import { withCache } from '@kandji-inc/bumblebee';
import { omit } from 'lodash';
/* add heic later,
 * will need to add `worker-src 'self' blob:;` to handle csp.
 */
// import heic2any from 'heic2any';
import { getAllBlueprintOptions } from 'src/features/library-items/data-service/blueprint/use-blueprint-service';
import uuidv4 from 'uuid/v4';
import {
  transformFromApi as libTransformFromApi,
  transformToApi as libTransformToApi,
} from '../../../data-service/library-item/transformers';
import KandjiLoginService from './kandji-login-service';

const transformHeicUrlToPngUrl = withCache(
  async (url) => {
    if (!url) {
      return url;
    }
    if (!/\.heic$/.test(new URL(url).pathname)) {
      return url;
    }

    return '';

    // return fetch(url)
    //   .then((res) => res.blob())
    //   .then((blob) => heic2any({ blob }))
    //   .then((conversionResult) => URL.createObjectURL(conversionResult))
    //   .catch((err) => {
    //     console.log('transformHeicUrlToPngUrl err', err);
    //     return url;
    //   });
  },
  { invalidateTimeout: null },
).call;

const omitNullishGroupValues = (setting, groupKey, filterCb) => {
  const cb = filterCb || (({ value }) => Boolean(value));
  if (Array.isArray(groupKey)) {
    const groups = groupKey.map((key) => [key, setting[key].filter(cb)]);
    return {
      ...setting,
      ...Object.fromEntries(groups),
    };
  }
  const group = setting[groupKey].filter(cb);
  return {
    ...setting,
    [groupKey]: group,
  };
};

const filterNullishProvisionGroups = ({ group, permission, value }) =>
  (Boolean(group) && Boolean(permission)) || Boolean(value);

const transformToApi = (v, initialTransformer = libTransformToApi) => {
  const OMIT_KEYS = [
    'invalidationsMap',
    'isActive',
    'isNameValid',
    'nameValidationResult',
    'selectedBlueprints',
    'logo',
    'desktopBg',
    'policyBannerFile',
  ];

  const transformer = (d) => {
    const {
      access: acc,
      auth,
      helpScreen,
      loginScreen,
      provision: prov,
      skip_blueprint_conflict,
    } = d.data;

    const authCopy = { ...auth };

    if (
      auth.idp !==
      KandjiLoginService.tempFeatureFlagGoogleIdpIdentityProviders
        .GOOGLE_WORKSPACE
    ) {
      authCopy.googleIdpCertFile = null;
    }

    const access = omitNullishGroupValues(acc, 'localUsers');
    const provision = omitNullishGroupValues(
      prov,
      ['permissionsBasedOnGroup', 'localUsersExcluded'],
      filterNullishProvisionGroups,
    );
    const blueprints = d.data.selectedBlueprints.map(
      (blueprint) => blueprint.value,
    );

    if (!loginScreen.isDesktopBg) {
      loginScreen.desktopBgS3Key = '';
      loginScreen.desktopBgUrl = '';
    }
    if (!loginScreen.isLogo) {
      loginScreen.logoS3Key = '';
      loginScreen.logoUrl = '';
    }
    if (loginScreen.policyBannerOption !== 'rtf') {
      loginScreen.policyBannerS3Key = '';
      loginScreen.policyBannerUrl = '';
    }
    if (!loginScreen.isNetworkManager) {
      delete loginScreen.allowOpenWifiNetworks;
    }

    return {
      ...d,
      skip_blueprint_conflict,
      blueprints,
      is_all_blueprints: d.data.isAllBlueprints,
      data: {
        access: omit(access, OMIT_KEYS),
        auth: omit(authCopy, OMIT_KEYS),
        helpScreen: omit(helpScreen, OMIT_KEYS),
        loginScreen: omit(loginScreen, OMIT_KEYS),
        provision: omit(provision, OMIT_KEYS),
      },
      rules: d.data.rules || null,
    };
  };

  if (initialTransformer && typeof initialTransformer === 'function') {
    const data = initialTransformer(v);
    return transformer(data);
  }

  return transformer(v);
};
const transformFromApi = async (
  v,
  initialTransformer = libTransformFromApi,
) => {
  const transformer = async (d) => {
    d.data.loginScreen.desktopBgUrl = await transformHeicUrlToPngUrl(
      d.data.loginScreen.desktopBgUrl,
    );

    const matchToIdp = [
      {
        match: /\.microsoftonline\.|\.microsoft\./,
        idp: KandjiLoginService.identityProviders.MICROSOFT_AZURE,
      },
      {
        match: /\.okta\.|\.oktapreview\./,
        idp: KandjiLoginService.identityProviders.OKTA,
      },
      {
        match: /\.onelogin\./,
        idp: KandjiLoginService.identityProviders.ONE_LOGIN,
      },
    ];

    const mfaIncludes = {};
    if (!d.data.auth?.idp) {
      // idp is not specified. Assume data structure is from the pre-mfa
      // passport. Determine idp from oidcBaseUrl.
      const matchedIdp = matchToIdp.find(({ match }) =>
        d.data.auth.oidcBaseUrl.match(match),
      );
      mfaIncludes.idp =
        matchedIdp?.idp || KandjiLoginService.identityProviders.OTHER;
      mfaIncludes.mode = KandjiLoginService.authenticationMode.MAC_LOGIN;
    }

    const excludedBlueprints = await getAllBlueprintOptions(
      d.excluded_blueprints,
    );
    return {
      ...d,
      data: {
        id: d.data.id,
        name: d.data.name,
        isActive: d.data.isActive,
        selectedBlueprints: d.data.blueprints,
        excludedBlueprints,
        isAllBlueprints: d.is_all_blueprints,
        template: d.data.template,
        auth: {
          ...d.data.auth,
          ...mfaIncludes,
          invalidationsMap: {},
        },
        provision: {
          ...d.data.provision,
          invalidationsMap: {},
          // If API returns empty permission-based group list, set to intial group
          // with single empty item
          ...{
            ...(d.data.provision.permissionsBasedOnGroup.length <= 0 && {
              permissionsBasedOnGroup: [
                {
                  group: '',
                  permission: '',
                  uuid: uuidv4(),
                },
              ],
            }),
          },
          // If API returns empty local excluded users list, set to intial group
          // with single empty item
          ...{
            ...(d.data.provision.localUsersExcluded.length <= 0 && {
              localUsersExcluded: [
                {
                  value: '',
                  uuid: uuidv4(),
                },
              ],
            }),
          },
        },
        access: {
          ...d.data.access,
          invalidationsMap: {},
          // If API returns empty local users list, set to intial group
          // with single empty item
          ...{
            ...(d.data.access.localUsers.length <= 0 && {
              localUsers: [
                {
                  value: '',
                  uuid: uuidv4(),
                },
              ],
            }),
          },
          ...{
            ...(!d.data.access.secureStoreUserPassword && {
              secureStoreUserPassword: 'secure_store_user_password_allow',
            }),
          },
        },
        loginScreen: {
          ...d.data.loginScreen,
          invalidationsMap: {},
        },
        helpScreen: {
          ...d.data.helpScreen,
          invalidationsMap: {},
        },
        rules: d.rules,
      },
    };
  };

  let toTransform = v;
  if (initialTransformer && typeof initialTransformer === 'function') {
    toTransform = await initialTransformer(v);
  }

  const result = await transformer(toTransform);

  return result;
};

export { transformFromApi, transformToApi };
