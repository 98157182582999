import {
  Checkbox,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';
import './logged-in-users.css';

import { Setting } from 'features/library-items/template';

const LoggedInUsersSection = (props) => {
  const { isDisabled, update, setting, validationDep } = props;

  const validationKeys = ['com.apple.autologout.AutoLogOutDelay'];
  const { refs, onInvalidate } = useInputsValidators(validationKeys, update);
  useRemoveValidationOnUnmount(validationKeys, update);

  const autoDelay = setting['com.apple.autologout.AutoLogOutDelay'];
  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Logged in users</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.MultipleSessionEnabled}
              label="Disable the fast user switching menu"
              onChange={() => update('MultipleSessionEnabled', (p) => !p)}
              testId="multiple-sessions"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability for multiple users to be logged in at once. The
            fast user switching menu will be disabled, and users will need to
            log out before logging in to another user.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.RestartDisabledWhileLoggedIn}
              label="Hide Restart option in the Apple menu"
              onChange={() => update('RestartDisabledWhileLoggedIn', (p) => !p)}
              testId="restart-disable-loggedin"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability for logged in users to restart the computer from
            the Apple menu.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.ShutDownDisabledWhileLoggedIn}
              label="Hide Shut Down option in the Apple menu"
              onChange={() =>
                update('ShutDownDisabledWhileLoggedIn', (p) => !p)
              }
              testId="shutdown-disable-loggedin"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability for logged in users to shut down the computer
            from the Apple menu.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.DisableScreenLockImmediate}
              label="Hide Lock Screen option in the Apple menu"
              onChange={() => update('DisableScreenLockImmediate', (p) => !p)}
              testId="lockscreen-disable-loggedin"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability for logged users to immediately lock the screen
            from the Apple menu. Available in macOS 10.13 and later.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.LogOutDisabledWhileLoggedIn}
              label="Hide Log Out option in the Apple menu"
              onChange={() => update('LogOutDisabledWhileLoggedIn', (p) => !p)}
              testId="logout-disable-loggedin"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Disable the ability for logged in users to log out from the Apple
            menu. Available in macOS 10.13 and later.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={autoDelay?.checked}
              label="Automatically log out inactive users"
              onChange={() => {
                if (autoDelay.checked) {
                  onInvalidate('com.apple.autologout.AutoLogOutDelay')(false);
                }
                update('com.apple.autologout.AutoLogOutDelay', (p) => ({
                  ...p,
                  checked: !p.checked,
                }));
              }}
              testId="logout-inactive-users"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Specify the amount of time a user can remain inactive before macOS
            attempts to log them out. Note that unsaved changes, certain apps,
            or other settings may prevent automatic logout.
          </Setting.Helpers>

          {autoDelay?.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls className="b-flex-vc">
                  <div ref={refs[0]} className="b-flex-vc b-flex-g1">
                    <TextInput
                      disabled={isDisabled}
                      subLabel={
                        <p className="b-txt" style={{ width: 'max-content' }}>
                          Minutes of activity before logout
                        </p>
                      }
                      className="lw-liu-auto-delay-input"
                      wrapperClassName="lw-liu-auto-delay-input"
                      id="auto-delay-input"
                      value={autoDelay.value / 60}
                      placeholder="60"
                      onChange={(e) => {
                        const val = e.target.value;
                        const num = parseInt(val, 10);
                        if (!val.length) {
                          update(
                            'com.apple.autologout.AutoLogOutDelay',
                            (p) => ({
                              ...p,
                              value: '',
                            }),
                          );
                        } else if (!Number.isNaN(num)) {
                          update(
                            'com.apple.autologout.AutoLogOutDelay',
                            (p) => ({
                              ...p,
                              value: num * 60,
                            }),
                          );
                        }
                      }}
                      validator={(v) => [
                        {
                          message: 'Value must be between 5 and 960.',
                          invalid: () => {
                            const num = parseInt(v, 10);
                            return Number.isNaN(num) || num < 5 || num > 960;
                          },
                          trigger: ['onBlur', validationDep],
                        },
                      ]}
                      onInvalidate={onInvalidate(
                        'com.apple.autologout.AutoLogOutDelay',
                      )}
                    />
                  </div>
                  {/* <p className="b-txt">Minutes of activity before logout</p> */}
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default LoggedInUsersSection;
