import { Badge, Box, Checkbox, Flex } from '@kandji-inc/nectar-ui';
import type React from 'react';
import type { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { UserRoles, links } from 'src/app/common/constants';
import deviceImagesMap from 'src/components/common/image-device/map';
import useAccount from 'src/contexts/account';
import {
  NULL_VALUE_N_DASH,
  getDeviceIconByFamily,
} from 'src/features/visibility/prism/utils/column-helpers/column-utils';

import { formatDate, highlightedText } from '../../helpers';
import { FlowTippy, TippyContainer } from '../../theme';

export const overFlowCss: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const GetDeviceCell = ({ device, filter }) => {
  const { device_family, name, model, id } = device;
  const icon = deviceImagesMap[model] ?? getDeviceIconByFamily(device_family);

  return (
    <Flex alignItems="center" gap="sm">
      <img
        data-testid="device_icon_image"
        height="20"
        width="20"
        src={icon}
        alt={model ?? device_family}
      />
      <Link
        to={`${links.devices}/${id}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...overFlowCss }}
      >
        {highlightedText(name || NULL_VALUE_N_DASH, filter.term)}
      </Link>
    </Flex>
  );
};

const GetCheckCell = (props) => {
  const { device, devices, setSelected, selected, isHeader } = props;
  const { userRole } = useAccount();
  const isAuditor = userRole === UserRoles.auditor;
  const selectedId = device?.id;
  const isChecked = device && selected[selectedId];

  // Header specific
  const allDevicesSelectedId = devices?.map(({ id }) => id);
  const allDevicesIsChecked = allDevicesSelectedId?.every((id) => selected[id]);
  const someDevicesIsChecked = allDevicesSelectedId?.some((id) => selected[id]);
  const headerChecked =
    allDevicesIsChecked || (someDevicesIsChecked ? 'indeterminate' : false);

  const devicesById = devices?.reduce(
    (a, c) => ({
      ...a,
      [c.id]: c,
    }),
    {},
  );

  const checked = isHeader ? headerChecked : Boolean(isChecked);
  return (
    <Box>
      <Checkbox
        data-testid={`checkbox-${isHeader ? 'header' : device?.id}`}
        checked={checked}
        onCheckedChange={() =>
          setSelected((prev) => {
            if (isHeader) {
              const p = prev;
              const devicesSelection = allDevicesSelectedId.reduce((a, c) => {
                if (headerChecked === 'indeterminate' || p[c]) {
                  delete p[c];
                  return a;
                }

                return {
                  ...a,
                  [c]: devicesById[c],
                };
              }, {});

              return {
                ...p,
                ...devicesSelection,
              };
            }

            const { [selectedId]: _, ...rest } = prev;

            if (prev[selectedId]) {
              return rest;
            }
            return {
              ...prev,
              [selectedId]: device,
            };
          })
        }
        disabled={isAuditor}
      />
    </Box>
  );
};

const getDateTimeCell =
  (key) =>
  ({ device }) => (
    <Box>
      <FlowTippy
        content={
          <TippyContainer>{formatDate(device[key], true)}</TippyContainer>
        }
        popperOptions={{ strategy: 'fixed' }}
      >
        <Box as="span">{formatDate(device[key])}</Box>
      </FlowTippy>
    </Box>
  );

const getStatusCell = ({ device }) => {
  const statusHumanReadable = {
    EMPTY: { label: 'No History', color: 'neutral' },
    PASS: { label: 'All Clear', color: 'green' },
    REMEDIATED: { label: 'Remediated', color: 'green' },
    INCOMPATIBLE: { label: 'All Clear', color: 'green' },
    WARNING: { label: 'Alert', color: 'yellow' },
    ERROR: { label: 'Alert', color: 'yellow' },
  };
  const status = statusHumanReadable[device.status];
  const isDeviceOffline = device.is_missing;
  const isDeviceLost =
    device.lost_mode_status === 'yes' || device.lost_mode_status === 'pending';

  if (isDeviceLost) {
    return <Badge color="yellow">Lost</Badge>;
  }

  if (isDeviceOffline) {
    return <Badge color="yellow">Offline</Badge>;
  }

  if (status) {
    return <Badge color={status.color}>{status.label}</Badge>;
  }

  return null;
};

export type TableColumn = {
  name: string;
  dataKey: string;
  sortKey?: string;
  headerCell?: React.FunctionComponent<any>;
  cell?: React.FunctionComponent<any>;
  isSortable?: boolean;
  meta?: {
    header?: {
      css?: any;
    };
    row?: {
      css?: any;
    };
  };
};

export const columns: Array<TableColumn> = [
  {
    name: 'check',
    dataKey: '',
    headerCell: (props) => <GetCheckCell {...props} isHeader />,
    cell: GetCheckCell,
    isSortable: false,
    meta: {
      header: {
        css: {
          padding: '$2 $3 $2 $5',
          width: 52,
        },
      },
      row: {
        css: {
          padding: '$3',
          paddingLeft: '$5',
        },
      },
    },
  },
  {
    name: 'Device',
    dataKey: 'name',
    sortKey: 'name',
    cell: GetDeviceCell,
    isSortable: true,
  },
  {
    name: 'Device user',
    dataKey: 'user.name',
    sortKey: 'user__name',
    isSortable: true,
  },
  {
    name: 'Model name',
    dataKey: 'model',
    sortKey: 'model',
    isSortable: true,
  },
  {
    name: 'Serial number',
    dataKey: 'serial_number',
    sortKey: 'serial_number',
    isSortable: true,
  },
  {
    name: 'Asset tag',
    dataKey: 'asset_tag',
    sortKey: 'asset_tag',
    isSortable: true,
  },
  {
    name: 'Status',
    dataKey: 'status',
    cell: getStatusCell,
    isSortable: false,
  },
  {
    name: 'Checked in',
    dataKey: 'last_seen_dt',
    sortKey: 'last_seen_dt',
    cell: getDateTimeCell('last_seen_dt'),
    isSortable: true,
  },
];
