import difference from 'lodash/difference';
import { formatDate } from './_helpers';

export default (activity) => {
  const oldPermissions = activity.data.old_permissions.map(
    (item) => `${item[0]} /${item[1]}`,
  );
  const newPermissions = activity.data.permissions.map(
    (item) => `${item[0]} /${item[1]}`,
  );
  const enabled = difference(newPermissions, oldPermissions);
  const removed = difference(oldPermissions, newPermissions);

  return [
    {
      label: 'Edited by',
      values: [activity.actor_name || activity.ip || '-'],
    },
    {
      label: 'Edited at',
      values: [formatDate(activity)],
    },
    {
      label: 'Permissions enabled',
      values: enabled,
    },
    {
      label: 'Permissions disabled',
      values: removed,
    },
  ];
};
