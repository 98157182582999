import type {
  ColumnFiltersState,
  ColumnOrderState,
  ColumnSizingInfoState,
  Header,
  SortDirection,
  VisibilityState,
} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import * as React from 'react';

import type { PrismCategoryFilterProperties } from 'src/features/visibility/prism/types/filter.types';
import type { PrismCategorySchema } from 'src/features/visibility/prism/types/prism.types';
import { getNextSortState } from '../../../contexts/utils/sortUtils';
import {
  getNewFilterByType,
  parseFilterableFromSchema,
} from '../utils/prismFiltersUtils';
import TableCell from './TableCell';

type FleetTableHeaderProps = {
  header: Header<unknown, unknown>;
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
  isResizingColumn: ColumnSizingInfoState['isResizingColumn'];
  columnFilters: ColumnFiltersState;
  selectedPrismCategory: PrismCategorySchema | undefined;
  onSort: any;
  sortState: SortDirection | 'none';
};

const FleetTableHeader = (props: FleetTableHeaderProps) => {
  const {
    header,
    isResizingColumn,
    columnOrder,
    columnVisibility,
    columnFilters,
    selectedPrismCategory,
    onSort,
    sortState,
  } = props;

  const headerCtx = header.getContext();
  const { table } = headerCtx;
  const visibleColumns = columnOrder.filter((colId) => columnVisibility[colId]);

  const [leftNeighborHeader, rightNeighborHeader] = [
    visibleColumns[header.index - 1],
    visibleColumns[header.index + 1],
  ];
  const isColumnFixed = table.options.meta?.fixedColumns?.[header.id];
  const isColumnFrozen = table.options.meta?.frozenColumns?.[header.id];
  const isLeftNeighborFixed = leftNeighborHeader
    ? table.options.meta?.fixedColumns?.[leftNeighborHeader]
    : false;
  const isRightNeighborFixed = rightNeighborHeader
    ? table.options.meta?.fixedColumns?.[rightNeighborHeader]
    : false;
  const isLeftNeighborFrozen = leftNeighborHeader
    ? table.options.meta?.frozenColumns?.[leftNeighborHeader]
    : false;
  const isRightNeighborFrozen = rightNeighborHeader
    ? table.options.meta?.frozenColumns?.[rightNeighborHeader]
    : false;

  const [isMoveLeftDisabled, isMoveRightDisabled] = [
    isColumnFixed ||
      isColumnFrozen ||
      !leftNeighborHeader ||
      isLeftNeighborFixed ||
      isLeftNeighborFrozen,
    isColumnFixed ||
      isColumnFrozen ||
      !rightNeighborHeader ||
      isRightNeighborFrozen ||
      isRightNeighborFixed,
  ];

  const moveColumn = (dir: 'left' | 'right') => () => {
    const preventMove =
      dir === 'left' ? isMoveLeftDisabled : isMoveRightDisabled;
    if (preventMove) {
      return;
    }

    const headerIdx = columnOrder.findIndex((colId) => colId === header?.id);
    const nextIndex =
      dir === 'left'
        ? columnOrder.findIndex((colId) => colId === leftNeighborHeader)
        : columnOrder.findIndex((colId) => colId === rightNeighborHeader);

    table.setColumnOrder(swapNeighborCol(headerIdx, nextIndex));
  };

  const onMoveLeft = moveColumn('left');
  const onMoveRight = moveColumn('right');

  const onHideColumn = React.useCallback(() => {
    // delays toggling off column visibility until the next microtask to avoid
    // `Can't perform a React state update on an unmounted component` warning
    // caused by unmounting the column header before the dropdown closes
    queueMicrotask(() => header.column.toggleVisibility(false));
  }, [header.column]);

  const onCreateFilter = React.useCallback(() => {
    const columnMeta = header.column.columnDef.meta;

    const filterMenuOption = {
      icon: columnMeta?.filterIcon || 'filter',
      label: columnMeta?.displayName || header.id,
      meta: columnMeta || {
        displayName: header.id,
      },
      value: header.id,
    };

    const newFilterParams: {
      key: string;
      filterMenuOption: PrismCategoryFilterProperties['menuOption'];
      schema?:
        | PrismCategoryFilterProperties['filterableColumnSchema']
        | undefined;
    } = {
      key: header.id as string,
      filterMenuOption,
    };

    if (columnMeta?.filterType === 'enum') {
      if (!selectedPrismCategory) {
        return undefined;
      }

      // only if it's enum, we call parseFilterableFromSchema
      const columnSchemas = parseFilterableFromSchema(selectedPrismCategory);
      const columnSchema = columnSchemas.find((col) => col.value === header.id);
      newFilterParams.schema = columnSchema || undefined;
    }

    const newFilter = getNewFilterByType(
      newFilterParams.key,
      newFilterParams.filterMenuOption,
      newFilterParams.schema,
    );

    if (newFilter) {
      table.setColumnFilters<
        Array<PrismCategoryFilterProperties['unionOf']['details']>
      >((prev) => [...prev, newFilter]);
    }

    return undefined;
  }, [header.column.columnDef.meta, header.id, selectedPrismCategory, table]);

  const menuOptions = React.useMemo(() => {
    const baseOptions = [
      {
        label: 'Move column 1 to the left',
        icon: 'fa-arrow-left-to-line-control',
        disabled: isMoveLeftDisabled,
        onClick: onMoveLeft,
      },
      {
        label: 'Move column 1 to the right',
        icon: 'fa-arrow-right-to-line-control',
        disabled: isMoveRightDisabled,
        onClick: onMoveRight,
      },
      {
        label: 'Hide column',
        icon: 'eye-slash',
        disabled: header.column.columnDef.enableHiding === false,
        onClick: onHideColumn,
      },
    ];

    const filterOptionDisabled = columnFilters?.some(
      // @ts-expect-error -- todo: fix
      (filter) => filter.key === header.id,
    );

    const filterOption = {
      label: `Filter table by ${header.column.columnDef.meta?.displayName}`,
      icon: 'filter',
      onClick: !filterOptionDisabled ? onCreateFilter : undefined,
      disabled: filterOptionDisabled,
    };

    const hideFilterOption = !header.column.columnDef.meta?.filterDisabled;

    return hideFilterOption ? baseOptions : [...baseOptions, filterOption];
  }, [
    columnFilters,
    header.column.columnDef.meta?.displayName,
    header.column.columnDef.meta?.filterDisabled,
    header.column.columnDef.enableHiding,
    header.id,
    isMoveLeftDisabled,
    isMoveRightDisabled,
    onCreateFilter,
    onHideColumn,
    onMoveLeft,
    onMoveRight,
  ]);

  return (
    <TableCell.HeaderCell
      key={header.id}
      columnId={header.id}
      size={header.getSize()}
      resizable={header.column.getCanResize()}
      isResizingColumn={isResizingColumn}
      handleResize={header.getResizeHandler()}
      showMenu={!header.column.columnDef.meta?.hideThMenu}
      showMenuOnHover
      css={{
        // prevents sort on click release if current column being resized
        pointerEvents: isResizingColumn ? 'none' : 'auto',
      }}
      // @ts-expect-error -- fix
      menuOptions={menuOptions}
      sort={{
        state: sortState,
        onSort,
      }}
      data-pinned={!!isColumnFixed}
      title={header.column.columnDef.meta?.displayName || header.id}
      // @ts-expect-error -- ok for pendo tracking
      className={`pendo-prism-column-header-${header.id}`}
      data-pendo-prism-column-name={header.id}
      data-pendo-prism-column-curr-sort={sortState}
      data-pendo-prism-column-next-sort={getNextSortState(sortState)}
    >
      {flexRender(header.column.columnDef.header, headerCtx)}
    </TableCell.HeaderCell>
  );
};

export default FleetTableHeader;

export function swapNeighborCol(headerIdx, neighborIdx) {
  return (order) => {
    if (typeof neighborIdx !== 'number' || typeof headerIdx !== 'number') {
      return order;
    }
    const orderCopy = [...order];
    [orderCopy[headerIdx], orderCopy[neighborIdx]] = [
      orderCopy[neighborIdx],
      orderCopy[headerIdx],
    ];
    return orderCopy;
  };
}
