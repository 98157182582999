import { api } from 'app/api/base';

const LATEST_PUBLIC_OS_VERSION_OPTION = {
  value: 'latest_public',
  label: 'Latest public release',
};

const initialMinimumOsVersions = {
  mac: [{ value: '14.0', label: 'macOS 14.0' }],
  iphone: [LATEST_PUBLIC_OS_VERSION_OPTION],
  ipad: [LATEST_PUBLIC_OS_VERSION_OPTION],
};

const transformToOptions = (data, osType) =>
  // OS versions are already in descending order (by BE)
  (data[osType] || []).map((i) => ({ value: i, label: `${osType} ${i}` }));

const getAdeMinimumOsVersions = async () =>
  api('apple-software/min-ade-versions')
    .get()
    .then(({ data }) => ({
      mac: transformToOptions(data, 'macOS'),
      iphone: [LATEST_PUBLIC_OS_VERSION_OPTION].concat(
        transformToOptions(data, 'iOS'),
      ),
      ipad: [LATEST_PUBLIC_OS_VERSION_OPTION].concat(
        transformToOptions(data, 'iPadOS'),
      ),
    }));

export {
  LATEST_PUBLIC_OS_VERSION_OPTION,
  initialMinimumOsVersions,
  getAdeMinimumOsVersions,
};
