import React from 'react';
import NewModal from 'src/app/components/interface/modals/shared/NewModal';
import { closeModalDefaults } from '../../initial-state';
import ModalBody from './background-item-modal-body';
import ModalFooter from './background-item-modal-footer';

function BackgroundItemModal(props) {
  const { model, setModel } = props;

  return (
    <NewModal
      isOpen={model.isModalOpen}
      onHide={() =>
        setModel((prev) => ({
          ...prev,
          ...closeModalDefaults,
        }))
      }
      header={`${model.isExisting ? 'Edit' : 'Add'} Background Item`}
      body={<ModalBody model={model} setModel={setModel} />}
      footer={<ModalFooter model={model} setModel={setModel} />}
    />
  );
}

export default BackgroundItemModal;
