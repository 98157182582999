import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import AirplaySecurityService from '../service/airplay-security-service';

const accessOptions = [
  {
    value: AirplaySecurityService.accessTypes.ANY,
    label: 'Allow connections from all devices',
  },
  {
    value: AirplaySecurityService.accessTypes.WIFI_ONLY,
    label: 'Require devices to be on same network as Apple TV ',
  },
];

const AccessCard = (props) => {
  const { setting, isDisabled, update } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Access</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Access policy</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light k-li-helper--compact">
            Allow all devices to initiate AirPlay connections to Apple TV, or
            require that devices be on the same network as Apple TV.
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={accessOptions}
              compact
              onChange={({ value }) => update('type', value)}
              value={accessOptions.find(({ value }) => value === setting.type)}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default AccessCard;
