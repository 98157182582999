import type {
  FiltersState,
  PaginationState,
  SortColumnState,
} from 'src/features/threat/threat.types';
import { ThreatService } from '../../../../data-service';
import ThreatsGroupSchema from '../../../schemas/ThreatsGroupSchema';
import fromResponseFormat from './fromResponseFormat';
import toRequestFormat from './toRequestFormat';

const threatService = new ThreatService();

const threatsGroupQuery = async (
  filters: FiltersState,
  sort: SortColumnState,
  pagination: PaginationState,
) => {
  const result = await threatService.getByThreats(
    toRequestFormat(filters, sort, pagination),
  );

  if (!ThreatsGroupSchema.safeParse(result?.data).success) {
    throw new Error('Invalid schema');
  }

  return { ...result, data: fromResponseFormat(result?.data) };
};

export default threatsGroupQuery;
