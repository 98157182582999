import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import React, { useContext } from 'react';
import Bar from '../../theme/components/atoms/Bar';

export const TARIFF_NOTIFICATION_ID = 'account-limit-notification';

// TODO: refactor and rename

const DisabledTariffNotificator = () => {
  const { showEnvironment } = useContext(EnvironmentContext);
  const { tariffBlocked } = useContext(AccountContext);
  // Disable legacy Account-Over-Limit banner
  return null;
  // TODO: Cleanup and remove banner code
  return tariffBlocked ? (
    <Bar
      id={TARIFF_NOTIFICATION_ID}
      type="disabled-tariff-notificator"
      offset={showEnvironment ? 'environment' : undefined}
    >
      <span>You have reached your account limit.</span>
    </Bar>
  ) : null;
};

export default DisabledTariffNotificator;
