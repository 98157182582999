import React, { useState, useEffect } from 'react';

import uuid from 'uuid/v4';

import {
  Button,
  Flex,
  MultiSelectSearch,
  Select,
  TextInput,
  useInvalidations,
} from '@kandji-inc/bumblebee';

import { Controls, Title } from 'features/library-items/template';

import {
  useAdvanced,
  useAuthority,
  useConnector,
  useIs,
  usePanel,
} from '../../hooks';

import { validatorAddServerName } from '../../validation';

import Panel from '..';
import Header from '../header';

const PanelAssignServer = () => {
  const { updateConnectorItem } = useAdvanced();

  const { authorityLimit, authorityList, fetchAuthorityList } = useAuthority();

  const {
    connector,
    connectorItem,
    clearConnector,
    clearConnectorItem,
    fetchConnectorItem,
    fetchConnectorList,
  } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { panel, closePanel } = usePanel();

  const isOpen = !!panel['assign-server'];

  const initial = {
    id: '',
    manage: [],
    add: [],
  };

  const [form, setForm] = useState(initial);

  const { manage, add } = form;

  const hasReachedAuthorityLimit = authorityList.length >= authorityLimit;

  /* istanbul ignore next */
  const { invalidations, setInvalidations, onInvalidate } = useInvalidations({
    inputs: add.length,
  });

  /* istanbul ignore next */
  const hasInvalidations = invalidations.some(Boolean);

  /* istanbul ignore next */
  const existingServerNames = authorityList.map(({ name: n }) => n);

  /* istanbul ignore next */
  const [serverNames, setServerNames] = useState([]);

  /* istanbul ignore next */
  const optionsAuthorities = authorityList?.map(
    ({ id: value, name: label }) => ({
      value,
      label,
    }),
  );

  /* istanbul ignore next */
  const valuesAuthorities = authorityList
    .filter(({ id }) => manage.includes(id))
    .map(({ id: value, name: label }) => ({
      value,
      label,
    }));

  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  /* istanbul ignore next */
  const handleAddServer = () => {
    updateForm('add', [
      ...add,
      {
        key: uuid(),
        name: '',
      },
    ]);

    setServerNames(add.map(({ name: n }) => n));
  };

  /* istanbul ignore next */
  const handleEditServerName = (index, { value }) => {
    const list = [...add];

    list[index].name = value;

    updateForm('add', list);
  };

  /* istanbul ignore next */
  const handleDeleteServer = (index) => {
    // if we are deleting this server, it no longer can cause
    // an invalidation and we need to reset it
    const updatedInvalidations = [...invalidations];

    updatedInvalidations[index] = false;

    setInvalidations(updatedInvalidations);

    return updateForm('add', [...add.slice(0, index), ...add.slice(index + 1)]);
  };

  /* istanbul ignore next */
  const reset = () => {
    clearConnector();
    clearConnectorItem();
    setForm(initial);
    setServerNames([]);
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closePanel(reset);
  };

  /* istanbul ignore next */
  const onSubmit = async () => {
    await updateConnectorItem(form);
    fetchAuthorityList();
    fetchConnectorList();
    closePanel(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (add.length === 0) {
      setInvalidations([]);
    }
  }, [add]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && connector) {
      fetchAuthorityList();
      fetchConnectorItem();
    }
  }, [isOpen, connector]);

  /* istanbul ignore next */
  useEffect(() => {
    if (connectorItem?.id) {
      setForm({
        ...form,
        id: connectorItem?.id,
        manage: connectorItem?.adcs_authorities,
      });
    }
    return () => setForm(initial);
  }, [connectorItem]);

  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  /* istanbul ignore next */
  const loopAddServer = () => {
    const valueConnector = {
      value: connectorItem?.id,
      label:
        connectorItem?.name ||
        connectorItem?.bound_domain ||
        'Connector details are pending...',
    };

    const optionsConnectors = [valueConnector];

    return (
      add?.length > 0 && (
        <div className="k-adcs-server-list k-section-border-bottom b-mt">
          {add?.map(({ key, name }, index) => (
            <div
              key={key}
              className={`k-adcs-server-item k-adcs-server-item-${index}`}
            >
              <div className="k-section-secondary">
                <Title>
                  <p className="b-txt b-mb1">
                    Server name and connector assignment
                  </p>
                </Title>

                <Controls>
                  <TextInput
                    className="b-mb"
                    placeholder="Enter the server name"
                    value={name}
                    /* istanbul ignore next */
                    validator={validatorAddServerName(
                      serverNames,
                      existingServerNames,
                    )}
                    onInvalidate={onInvalidate(index)}
                    onChange={
                      /* istanbul ignore next */
                      ({ target }) => handleEditServerName(index, target)
                    }
                    disabled={isLoading || isWorking}
                  />

                  <Select
                    className="k-adcs-select-disabled"
                    placeholder="Select connector from list"
                    options={optionsConnectors}
                    value={valueConnector}
                    onChange={
                      /* istanbul ignore next */
                      () => {}
                    }
                    disabled
                  />
                </Controls>

                <Flex className="b-mt">
                  <div className="b-grid-ctas">
                    <Button
                      kind="link"
                      theme="error"
                      onClick={
                        /* istanbul ignore next */
                        () => handleDeleteServer(index)
                      }
                      isDisabled={isLoading || isWorking}
                    >
                      Delete
                    </Button>
                  </div>
                </Flex>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <Panel name="assign-server">
      <Header title="Assign AD CS servers" />

      <div className="b-side-panel-layout__body">
        <div className="k-section-primary">
          <h4 className="b-h4 b-mb1">Assign existing servers</h4>

          <p className="b-txt">
            Assign servers to the connector from the existing list of unassigned
            AD CS servers. Servers can only be assigned to a single connector.
          </p>

          <div className="k-section-secondary b-mt">
            <Title>
              <p className="b-txt b-mb1">AD CS servers</p>
            </Title>

            <Controls>
              <MultiSelectSearch
                placeholder="Select servers from list"
                searchPlaceholder="Search servers"
                options={optionsAuthorities}
                values={valuesAuthorities}
                onChange={
                  /* istanbul ignore next */
                  (v) =>
                    updateForm(
                      'manage',
                      v?.map(({ value }) => value),
                    )
                }
                disabled={isLoading || isWorking}
              />
            </Controls>
          </div>
        </div>

        <div className="k-section-primary k-section-border-top b-mb">
          <h4 className="b-h4 b-mb1">Add and assign new servers</h4>
          <p className="b-txt">
            Enter the server name and connector assignment below.
          </p>

          {loopAddServer()}
        </div>

        <div className="k-section-primary">
          <Flex>
            <div className="b-grid-ctas">
              <Button
                kind="link"
                icon="circle-plus"
                onClick={handleAddServer}
                isDisabled={isLoading || isWorking || hasReachedAuthorityLimit}
              >
                Add server
              </Button>
            </div>
          </Flex>
        </div>
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="flex-end">
          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onCancel}
              isDisabled={isLoading || isWorking}
            >
              Cancel
            </Button>

            <Button
              icon={isWorking ? 'arrows-rotate' : undefined}
              onClick={onSubmit}
              isDisabled={isLoading || isWorking || hasInvalidations}
              isProgress={isWorking}
            >
              Add
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  );
};

export default PanelAssignServer;
