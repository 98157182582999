import type { ReactNode } from 'react';
import React, { memo, useContext } from 'react';

import { Flex } from '@kandji-inc/nectar-ui';

import { TARIFF_NOTIFICATION_ID } from 'components/region/DisabledTariffNotificator';
import { InterfaceContext } from 'contexts/interface';

function Wrapper(props: { children: ReactNode }) {
  const { children } = props;

  const { bannerTopOffset } = useContext(InterfaceContext);

  const accountLimitBannerHeight =
    document.getElementById(TARIFF_NOTIFICATION_ID)?.clientHeight || 0;

  const css = {
    margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
    height: `calc(100vh - ${bannerTopOffset + accountLimitBannerHeight}px)`,
    position: 'relative',
  };

  return (
    <Flex id="blueprint-flow" css={css}>
      {children}
    </Flex>
  );
}

export default memo(Wrapper);
