import { Button, Chip } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';
import PreviewHoverTippy from 'src/features/util/components/preview-hover-tippy';

function getCategoryFromPlural(plural) {
  return plural.substring(0, plural.length - 1);
  // Remove the 's' ending; will probably need a mapping obj if
  // some irregular plurals appear
}

const DetailView = (props) => {
  const { row, data, onClose, onConfigure, category } = props;
  const isPreviewChip = data?.data?.is_preview_chip;
  const requiresSupervision =
    data.data?.requires_supervision ||
    data.type === 'wallpaper' ||
    data.name === 'Home Screen Layout';

  return (
    <div
      className="li-add-library-item__detail"
      style={{ gridRowStart: row + 2 }}
    >
      <div className="li-add-library-item__detail-header">
        <div className="b-flex">
          <img
            src={data.icon}
            className="li-add-library-item__detail-icon b-mr"
          />
          <h3 className="b-h3 b-flex-vc li-add-library-item__detail-name">
            {data.name}
          </h3>
        </div>

        {isPreviewChip && <PreviewHoverTippy />}
      </div>
      <div className="li-add-library-item__detail-content">
        <div className="li-add-library-item__detail-left-column-content">
          <div className="li-add-library-item__detail-description b-txt-light">
            {data.description}
          </div>
          <div className="b-flex-vc b-flex-g li-add-library-item__detail-button">
            <Button onClick={onConfigure} className="--first">
              Add & Configure
            </Button>
          </div>
        </div>
        <div className="li-add-library-item__detail-info">
          <span className="b-txt-light">Publisher: </span>
          <span className="b-txt">{data.publisher}</span>
          <span className="b-txt-light">Device families: </span>
          <span className="b-flex-g">
            {data.devices.map((device) => (
              <Chip key={device} text={device} kind="secondary" />
            ))}
          </span>
          {data.osRequirements && data.osRequirements.length ? (
            <>
              <span className="b-txt-light">OS requirement: </span>
              <span className="b-flex-vgtiny">
                {data.osRequirements.map((os) => (
                  <span key={os} className="b-txt">
                    {os}
                    {requiresSupervision && ' (Requires Supervision)'}
                  </span>
                ))}
              </span>
            </>
          ) : null}
        </div>
      </div>
      <Button
        className="li-add-library-item__detail-close"
        kind="link"
        icon="xmark"
        onClick={onClose}
      />
    </div>
  );
};

export default DetailView;
