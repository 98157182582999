import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import colorConstants from '../../colors';
import blueprintIconClasses from '../../icons';
import './icon-chooser.css';

const defaultColors = [
  'red-500',
  'orange-500',
  'orange-400',
  'orange-300',
  'yellow-500',
  'green-300',
  'green-500',
  'aqua-500',
  'aqua-800',
  'purple-500',
  'purple-400',
];
const IconSelector = (props) => {
  const { blueprint, icon, color, onBack } = props;
  const [newColor, setNewColor] = useState(color);
  const [newIcon, setNewIcon] = useState(icon);

  return (
    <Modal
      isOpen
      onClose={() => {}}
      withoutCloseButton
      animationDuration={0}
      disableCloseOnOutsideClick
      classes={{
        root: 'bl-blueprints-edit__modal',
      }}
      parts={{
        header: { text: '' },
        content: {
          children: (
            <div className="bl-blueprints-ic__selector">
              <div className="b-flex-vg b-flex-vc">
                {defaultColors.map((col) => (
                  <div
                    key={col}
                    className={`bl-blueprints-ic__selector-circle${
                      col === newColor ? '--active' : ''
                    }`}
                    style={{ background: colorConstants[col] }}
                    onClick={() => setNewColor(col)}
                  />
                ))}
              </div>
              <div className="b-flex-vg b-flex-vc b-flex-hc">
                <div
                  className="bl-blueprints-ic__display--big"
                  style={{
                    background: `linear-gradient(90deg, ${colorConstants[newColor]} 50%, ${colorConstants[newColor]}dd 50%)`,
                  }}
                >
                  <i className={`ss-icon ${newIcon}`} />
                </div>
                <Button
                  kind="link"
                  icon="arrow-rotate-right"
                  onClick={() => {
                    setNewColor(blueprint.color);
                    setNewIcon(blueprint.icon);
                  }}
                >
                  Revert to original
                </Button>
              </div>
              <div className="b-flex bl-blueprints-ic__icon-choices">
                {blueprintIconClasses.map((iconName) => (
                  <div
                    key={iconName}
                    className={`bl-blueprints-ic__icon-choice ${
                      newIcon === iconName
                        ? 'bl-blueprints-ic__display--small'
                        : ''
                    }`}
                    onClick={() => setNewIcon(iconName)}
                    style={
                      newIcon === iconName
                        ? {
                            background: `linear-gradient(90deg, ${colorConstants[newColor]} 50%, ${colorConstants[newColor]}dd 50%)`,
                          }
                        : {}
                    }
                  >
                    <i className={`ss-icon ${iconName}`} />
                  </div>
                ))}
              </div>
            </div>
          ),
        },
        footer: {
          children: (
            <Button onClick={() => onBack(newIcon, newColor)}>Done</Button>
          ),
        },
      }}
    />
  );
};

export default IconSelector;
