import { useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_AUTH_CONNECTOR } from 'src/app/api/urls';
import type { AuthConnection } from '../ManualEnrollment.types';

export async function getAuthConnectors(): Promise<AuthConnection[]> {
  try {
    const res = await api(URL_AUTH_CONNECTOR).get({});
    const { data } = res;

    return data.connections ?? [];
  } catch (error) {
    return [];
  }
}

export function useGetAuthConnectors() {
  return useQuery({
    queryKey: ['get-auth-connectors'],
    queryFn: getAuthConnectors,
  });
}
