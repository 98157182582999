import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useEffect } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { useLocation } from 'react-router-dom';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import configs from '../../library/library-item-configurations/items/profile';
import ActivityTab from '../ssh-profile/activity-tab';
import CertificateCard from './certificate-card';
import initial from './initial-state';
import { certificateService } from './service/certificate-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useCertificateService from './service/use-certificate-service';

const CertificatePage = (props) => {
  const { model, setModel, pageState } = props;
  const { state } = useLocation();
  const [validationDep, triggerValidation] = useUniqValue();
  const itemConfig = configs.Certificate;
  const itemName = `${itemConfig.name} Profile`;

  const updateCertificate = useCallback(update('certificate', setModel), []);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  useEffect(() => {
    if (state?.certificate?.type) {
      updateCertificate('type', state.certificate.type);
      delete state.certificate.type;
    }
  }, [state]);

  return (
    <LibraryItemPage
      {...props}
      crumb={model.name || itemName}
      summaryInfoProps={{
        name: itemName,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={certificateService}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      defaultIcon={itemConfig.icon}
      type={itemConfig.type}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <CertificateCard
        isDisabled={pageState.isDisabled}
        setting={model.certificate}
        update={updateCertificate}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const Certificate = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useCertificateService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }
  return <CertificatePage {...pageProps} testId="certificate-v2" />;
};

export default Certificate;
