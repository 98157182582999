import React from 'react';

// Bumblebee
import type { ButtonProps } from '@kandji-inc/nectar-ui';
import { Button, Flex } from '@kandji-inc/nectar-ui';
import styled from 'styled-components';

import CancelButton from './cancel-button';
import Empty from './empty';

type Props = Readonly<{
  onClose?: () => void;
  nextButtonDisabled?: boolean;
  downloadProgress?: number;
  handleNext: () => void;
  hasCancelButton?: boolean;
  isWorking?: boolean;
  nextButtonText?: string;
  cancelButtonText?: string;
  theme?: ButtonProps['variant'];
  textIcon?: string;
}>;

const StyledTextIcon = styled.img`
  margin-right: var(--b-gap-tiny);
`;
const FooterButtonsRight = (props: Props) => {
  const {
    onClose,
    nextButtonDisabled,
    downloadProgress = 0,
    handleNext,
    hasCancelButton = true,
    isWorking = false,
    nextButtonText = 'Next',
    cancelButtonText,
    theme = 'primary',
    textIcon,
  } = props;

  const isDownloadComplete = downloadProgress === 100;

  const action = handleNext;

  const text = isDownloadComplete ? 'Next' : nextButtonText;

  return (
    <Flex gap="sm">
      {hasCancelButton ? (
        <CancelButton
          onClick={() => onClose && onClose()}
          isDisabled={isWorking && isWorking}
          cancelButtonText={cancelButtonText}
        />
      ) : (
        <Empty />
      )}

      <Button
        onClick={() => action()}
        disabled={nextButtonDisabled || isWorking}
        icon={isWorking ? { name: 'arrows-rotate' } : null}
        loading={isWorking}
        variant={theme}
      >
        {textIcon && <StyledTextIcon src={textIcon} alt={`${text} icon`} />}
        {text}
      </Button>
    </Flex>
  );
};

export default FooterButtonsRight;
