// istanbul ignore file
import {
  Checkbox,
  Flex,
  Icon,
  type IconNames,
  type RenderItemProps,
  styled,
} from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import type { ReactNode } from 'react';
import { deviceFamilyToIconMap } from './utils';
export const ColumnItem = ({
  column,
  isPinned,
  columnVisibility,
  onColumnVisibilityChange,
  dndProps,
  enableReorder,
}: {
  column: Column<unknown, unknown>;
  isPinned: boolean;
  columnVisibility: Record<string, boolean>;
  onColumnVisibilityChange: (visibility: Record<string, boolean>) => void;
  dndProps: RenderItemProps;
  enableReorder?: boolean;
}) => {
  const meta = column.meta as { filterIcon: string; deviceFamily: string[] };

  return (
    <Container
      isDragging={dndProps.isDragging}
      dragDirection={dndProps.dragDirection as 'up' | 'down'}
    >
      <InnerContainer>
        <Checkbox
          label={column.header as ReactNode}
          checked={!!columnVisibility[column.id]}
          onCheckedChange={(state) =>
            onColumnVisibilityChange({
              ...columnVisibility,
              [column.id]: !!state,
            })
          }
          viewOnly={isPinned}
        />
        <FilterIcon name={meta.filterIcon as IconNames} />
      </InnerContainer>
      <DeviceFamilyGroup>
        {meta.deviceFamily?.map((deviceFamily) =>
          deviceFamily === 'all' ? (
            <>
              <DeviceFamilyIcon name="sf-desktop-computer" />
              <DeviceFamilyIcon name="sf-iphone" />
              <DeviceFamilyIcon name="sf-ipad-landscape" />
              <DeviceFamilyIcon name="sf-apple-tv" />
            </>
          ) : (
            <DeviceFamilyIcon
              key={deviceFamily}
              name={deviceFamilyToIconMap[deviceFamily] as IconNames}
            />
          ),
        )}
      </DeviceFamilyGroup>
      {!isPinned && enableReorder && (
        <DragHandle
          {...dndProps.listeners}
          {...dndProps.attributes}
          onMouseEnter={dndProps.handleGripMouseEnter}
          onMouseLeave={dndProps.handleGripMouseLeave}
        >
          <Icon name="grip-dots" />
        </DragHandle>
      )}
    </Container>
  );
};

const Container = styled(Flex, {
  display: 'flex',
  width: '376px',
  padding: '8px 8px 8px 0px',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '4px',
  background: '$surface_primary',
  boxShadow: '0px 0px 0px 0px rgba(15, 19, 23, 0.00)',

  variants: {
    isDragging: {
      true: {
        maxWidth: '400px',
        marginLeft: '4px',
        padding: '8px 4px',
        boxShadow: '0px 1px 4px 0px rgba(15, 19, 23, 0.24)',
      },
    },
    dragDirection: {
      up: {
        borderTop: '1px solid $blue50',
      },
      down: {
        borderBottom: '1px solid $blue50',
      },
    },
  },
});

const InnerContainer = styled(Flex, {
  alignItems: 'center',
  gap: '4px',
  flex: '1 0 0',
  padding: '0 2px',
});

const FilterIcon = styled(Icon, {
  height: '12px',
  width: '12px',
  color: '$icon_secondary',
});

const DeviceFamilyGroup = styled(Flex, {
  padding: '2px',
  alignItems: 'flex-start',
  gap: '4px',
  color: '$icon_secondary',
});

const DeviceFamilyIcon = styled(Icon, {
  height: '12px',
  width: '12px',
});

const DragHandle = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '4px',
  '& > svg': {
    height: '20px',
    width: '20px',
    color: '$icon_secondary',
  },
});
