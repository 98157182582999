import ScreenSaverService from './service/screen-saver-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  users: {
    invalidationsMap: {},
    idle_time: 1200, // Only present if configure screen saver for users is checked, time in seconds
    isScreenSaverConfigured: false,

    module_path: ScreenSaverService.SCREEN_SAVER_OPTIONS[0].value, // Default from the preconfigured list of screen savers, only displayed if isScreenSaverModuleConfigured is true
    isScreenSaverModuleConfigured: false,
  },

  loginWindow: {
    invalidationsMap: {},
    login_window_idle_time: 1200,
    isScreenSaverLoginWindowConfigured: false,

    login_window_module_path: ScreenSaverService.SCREEN_SAVER_OPTIONS[0].value,
    isScreenSaverModuleLoginWindowConfigured: false,
  },
  supportsRules: true,
};
