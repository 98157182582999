import type { FIXME_Any } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import type {
  PrismCategorySchema,
  PrismCategoryUri,
} from 'src/features/visibility/prism/types/prism.types';
import { getColumnsForPrismCategory } from 'src/features/visibility/prism/utils/column-helpers';

type UseColumnsHook = (
  uri: string,
  prismCategorySchema: PrismCategorySchema | undefined,
) => {
  columnDefs: FIXME_Any;
  filterableColumns: {
    filterOptions: {
      value: PrismCategoryUri;
      label: string;
    }[];
  };
};

export const useColumns: UseColumnsHook = (uri, prismCategorySchema) => {
  const [columns, setColumns] = useState<FIXME_Any>({
    columnDefs: [],
    filterableColumns: {
      filterOptions: [],
    },
  });

  useEffect(() => {
    if (!prismCategorySchema) {
      return;
    }
    setColumns(getColumnsForPrismCategory(uri, prismCategorySchema));

    return () => {
      setColumns({
        columnDefs: [],
        filterableColumns: {
          filterOptions: [],
        },
      });
    };
  }, [uri, prismCategorySchema]);

  return columns;
};
