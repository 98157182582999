import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SoftwareUpdateService extends NewLibraryItemService {
  static DEFER_MACOS_UPDATES_OPTIONS = {
    doNotDefer: {
      label: 'Do not defer macOS updates',
      value: 'doNotDefer',
    },
    deferAll: {
      label: 'Defer all macOS updates',
      value: 'deferAll',
    },
    deferByType: {
      label: 'Defer macOS updates by type',
      value: 'deferByType',
    },
  };
}

export const softwareUpdateService = new SoftwareUpdateService();

export default SoftwareUpdateService;
