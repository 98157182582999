/* istanbul ignore file */
import { useInfiniteQuery } from '@tanstack/react-query';

import { newLibraryItemService } from '../../data-service/library-item/new-library-item-service';
import { apiTypes } from '../common';
import allLibraryItemsConfigurations from '../library-item-configurations';

const getItemConfig = (item) =>
  Object.values(allLibraryItemsConfigurations).find((d) => {
    if (d.type === apiTypes.PROFILE) {
      return d.type === item.type && d.identifier === item.identifier;
    }
    return d.type === item.type;
  });

export const useGetLibraryItems = () =>
  useInfiniteQuery({
    queryKey: ['library-items'],
    queryFn: ({ pageParam }) =>
      newLibraryItemService
        // TODO: setting `limit` param to 20 to better show infinite loading
        // behavior on visible load more button scroll down. Most likely remove
        // the `limit` param to get the default count of 50 library items from
        // the API.
        .list({ offset: pageParam, limit: 20, include_data: true })
        .then((r) => ({
          ...r.data,
          results: r.data.results.map((item) => {
            const itemConfig = getItemConfig(item);
            return {
              ...item,
              icon: item.icon || itemConfig?.icon,
              backupIcon: itemConfig.icon,
              getUrl: itemConfig.getUrl,
              formattedItemType:
                Object.values(apiTypes).includes(itemConfig.type) &&
                itemConfig.name,
            };
          }),
        })),
    initialPageParam: 0,

    getNextPageParam: (lastPage) => {
      if (lastPage.next) {
        const searchParams = new URLSearchParams(lastPage.next);
        const offset = searchParams.get('offset');
        return Number(offset);
      }
      return null;
    },

    getPreviousPageParam: (firstPage, allPages) => {
      if (firstPage?.previous) {
        const searchParams = new URLSearchParams(firstPage.previous);
        const offset = searchParams.get('offset');
        return Number(offset);
      }
      return null;
    },

    placeholderData: {
      pageParams: [undefined],
      pages: [],
    },
    // // TODO: testing refetch in background every minute, determine if this is
    // // useful and adjust interval if necessary
    refetchIntervalInBackground: true,
    refetchInterval: 60000,
  });
