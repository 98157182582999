import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import type { Dispatch, SetStateAction } from 'react';
import React, { useMemo } from 'react';

const contentCopy = [
  'You will lose all unsaved work.',
  'Are you sure you want to leave this page?',
];

type Props = {
  onClose: Dispatch<SetStateAction<boolean>>;
  onLeave: () => void;
};

export function ConfirmNavigationModal({ onClose, onLeave }: Props) {
  const parts = useMemo(
    () => ({
      header: { text: 'Confirm navigation' },
      content: {
        children: (
          <>
            {contentCopy.map((copy, idx) => (
              <p key={`confirm-navigation-modal-content-${idx}`}>{copy}</p>
            ))}
          </>
        ),
      },
      footer: {
        children: (
          <>
            <Button onClick={onClose} kind="outline">
              Stay on this Page
            </Button>
            <Button onClick={onLeave} className="b-ml">
              Leave this Page
            </Button>
          </>
        ),
      },
    }),
    [],
  );

  return <Modal isOpen parts={parts} onClose={onClose} />;
}
