import { api, integrationsApi } from 'app/api/base';
import { URL_COUNTS } from '../api/urls';
import { handleError } from './helpers';
import { setSnackbar } from './ui';

export const USER_INTEGRATION_FETCHED = 'USER_INTEGRATION_FETCHED';
export const USER_INTEGRATION_ACCESS_REVOKE = 'USER_INTEGRATION_ACCESS_REVOKE';
export const USER_INTEGRATION_USERS_FETCHED = 'USER_INTEGRATION_USERS_FETCHED';
export const USER_INTEGRATION_USER_FETCHED = 'USER_INTEGRATION_USER_FETCHED';
export const USER_INTEGRATION_CLEAR_USER = 'USER_INTEGRATION_CLEAR_USER';
export const USER_INTEGRATION_CLEAR_USERS = 'USER_INTEGRATION_CLEAR_USERS';
export const USER_INTEGRATION_REMOVE_USERS = 'USER_INTEGRATION_REMOVE_USERS';
export const USER_INTEGRATION_USERS_COUNTS_FETCHED =
  'USER_INTEGRATION_USERS_COUNTS_FETCHED';
export const USER_INTEGRATION_FORCE_UPDATE = 'USER_INTEGRATION_FORCE_UPDATE';

export const integrationLogin = (method) => (dispatch) =>
  integrationsApi(method)
    .get()
    .then((res) => {
      window.location.href = res.data.redirect;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch(setSnackbar('You already have a configured integration'));
        return Promise.reject(err);
      }
      return handleError(err, dispatch);
    });

export const getUsers = (params) => (dispatch) =>
  integrationsApi('/user-integrations/users')
    .get(params)
    .then((res) => {
      const action = {
        type: USER_INTEGRATION_USERS_FETCHED,
        payload: {
          gSuiteUsers: res.data.results,
        },
      };

      // If this is a non-filtered result set,
      // then include total count in payload
      const isSubset = 'search' in params || 'filter' in params;
      if (!isSubset) {
        action.payload.gSuiteUsersTotal = res.data.count;
      }

      dispatch(action);

      return Promise.resolve(res.data);
    })
    .catch((err) => handleError(err, dispatch));

export const getUserIntegration = () => (dispatch) =>
  integrationsApi('/user-integrations/')
    .get()
    .then((res) => {
      dispatch({
        type: USER_INTEGRATION_FETCHED,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => handleError(err, dispatch));

export const archiveUsers = (data) => (dispatch) =>
  integrationsApi('/user-integrations/users')
    .patch(data)
    .then((res) => {
      dispatch({
        type: USER_INTEGRATION_REMOVE_USERS,
        payload: { usersToRemove: res.data },
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => handleError(err, dispatch));

export const restoreUsers = (data) => (dispatch) =>
  integrationsApi('/user-integrations/users')
    .patch(data)
    .then((res) => {
      dispatch({
        type: USER_INTEGRATION_REMOVE_USERS,
        payload: { usersToRemove: res.data },
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => handleError(err, dispatch));

export const retrieveUser = (userId) => (dispatch) =>
  integrationsApi(`/user-integrations/users/${userId}`)
    .get()
    .then((res) => {
      dispatch({
        type: USER_INTEGRATION_USER_FETCHED,
        payload: { currentGSuiteUser: res.data },
      });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      console.log('err', err);
    });

export const getUsersCounts = (fields) => (dispatch) =>
  api(URL_COUNTS)
    .get({ fields })
    .then((response) => {
      dispatch({
        type: USER_INTEGRATION_USERS_COUNTS_FETCHED,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    });

export const setForceUpdate = (value) => (dispatch) =>
  dispatch({
    type: USER_INTEGRATION_FORCE_UPDATE,
    payload: value,
  });

export const clearGSuiteUser = () => ({
  type: USER_INTEGRATION_CLEAR_USER,
});

export const clearGSuiteUsers = () => ({
  type: USER_INTEGRATION_CLEAR_USERS,
});
