import { Button } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import { validateEnrollmentCode } from 'app/components/EnrollmentPortal/api';
import { isIpad, isIphone } from 'app/components/common/helpers';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import CodeInput from '../../app/components/EnrollmentPortal/CodeInput';
import HeaderWithLogo from '../../app/components/EnrollmentPortal/HeaderWithLogo';
import NumberSign from '../../assets/img/number_sign.png';

// TODO: remove Redux

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
`;

const EnrollmentPortalCardFirstPageWrapper = styled.div`
  margin: 130px auto 82px;
`;

const EnrollmentPortalCardEnterCodePageWrapper = styled.div`
  //margin: 205px auto;
`;

const EnrollmentPortalCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 382px;
  height: 409px;
  background: ${colors.white};
  border-radius: 4px;
`;

const SignInWrapper = styled.div`
  width: 100%;
  height: 306px;
  padding: 34px 40px 40px 40px;
  color: #1a1d25;
`;

const SignInHeader = styled.div`
  font-family: var(--font-family-primary);
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 10px;
`;

const SignInText = styled.div`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
`;

const AccessCodeButton = styled.div`
  margin-top: 56px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
`;

const AccessCodeButtonIcon = styled.img`
  margin-right: 22px;
  height: 28px;
`;

const AccessCodeButtonText = styled.div`
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const InvalidCodeError = styled.div`
  height: 21px;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  color: #ee3434;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class EnrollmentPortal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAccessCodeMethodChosen: true,
      accessCode: null,
      isAccessCodeValid: true,
    };
    this.onContinueClick = this.onContinueClick.bind(this);
  }

  onContinueClick() {
    const { accessCode } = this.state;
    const { history } = this.props;
    this.setState({ isLoading: true }, () =>
      validateEnrollmentCode(accessCode)
        .then(() => {
          // TODO: reformat with adaptive page
          if (isIpad()) {
            history.push(`/enroll/tablet/access-code/${accessCode}`);
          } else if (isIphone()) {
            history.push(`/enroll/phone/access-code/${accessCode}`);
          } else {
            history.push(`/enroll/access-code/${accessCode}`);
          }
        })
        .catch(() =>
          this.setState({ isAccessCodeValid: false, accessCode: null }),
        )
        .finally(() => this.setState({ isLoading: false })),
    );
  }

  render() {
    const {
      isLoading,
      isAccessCodeMethodChosen,
      accessCode,
      isAccessCodeValid,
    } = this.state;
    if (isAccessCodeMethodChosen) {
      return (
        <Container id="enroll">
          <EnrollmentPortalCardEnterCodePageWrapper>
            <EnrollmentPortalCard>
              <HeaderWithLogo pageName="Enrollment Portal" />
              <SignInWrapper>
                <SignInHeader>Enter Code</SignInHeader>
                <SignInText>
                  Please enter the access code supplied to you by your IT
                  administrator.
                </SignInText>
                <CodeInput
                  focus
                  length={6}
                  onComplete={(code) => this.setState({ accessCode: code })}
                  onChange={(code) => {
                    this.setState({ isAccessCodeValid: true });
                    if (code.length < 6) {
                      this.setState({ accessCode: null });
                    }
                  }}
                />
                <InvalidCodeError isHidden={isAccessCodeValid}>
                  Access code is invalid
                </InvalidCodeError>
                <ButtonWrapper>
                  <Button
                    type="button"
                    onClick={this.onContinueClick}
                    disabled={isLoading || !accessCode}
                    style={{ width: '212px' }}
                  >
                    Continue
                  </Button>
                </ButtonWrapper>
              </SignInWrapper>
            </EnrollmentPortalCard>
          </EnrollmentPortalCardEnterCodePageWrapper>
        </Container>
      );
    }
    return (
      <Container>
        <EnrollmentPortalCardFirstPageWrapper>
          <EnrollmentPortalCard>
            <HeaderWithLogo pageName="Enrollment Portal" />
            <SignInWrapper>
              <SignInHeader>Sign in</SignInHeader>
              <SignInText>
                Welcome! Please choose your sign in method in order to continue.
              </SignInText>
              <AccessCodeButton
                onClick={() =>
                  this.setState({ isAccessCodeMethodChosen: true })
                }
              >
                <AccessCodeButtonIcon
                  role="presentation"
                  alt="number sign"
                  src={NumberSign}
                />
                <AccessCodeButtonText>
                  Sign in with access code
                </AccessCodeButtonText>
              </AccessCodeButton>
            </SignInWrapper>
          </EnrollmentPortalCard>
        </EnrollmentPortalCardFirstPageWrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(EnrollmentPortal));
