import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { emptyItem } from '../../initial-state';

const AddBackgroundItem = (props) => {
  const { setModel, isDisabled } = props;
  return (
    <Button
      kind="link"
      icon="circle-plus"
      isDisabled={isDisabled}
      onClick={() =>
        setModel((prev) => ({
          ...prev,
          editItem: emptyItem,
          itemIdx: prev.items.length,
          isModalOpen: true,
        }))
      }
    >
      Add Background Item
    </Button>
  );
};

export default AddBackgroundItem;
