import {
  Banner,
  DatePicker,
  Select,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import React, { memo, useMemo } from 'react';
/* istanbul ignore file */
import './updates-card.scss';

import { useValidate } from 'features/library-items/common/hooks';
import { apiTypes } from 'features/library-items/library/common';
import { Setting } from 'features/library-items/template';
import AutomaticAppService from '../../items/automatic-app/service/automatic-app-service';
import MacosReleasesService from '../../items/macos-releases/service/macos-releases-service';
import { RSREnforcementUpdateSection } from './RSREnforcementUpdateSection';
import { getBanners } from './get-banners';
import { getRsrBanners } from './getRsrBanners';
import {
  DELAY_OPTIONS,
  ENFORCEMENT_KEYS,
  FIELDS_TO_VALIDATE,
  MANAGED_OS_DDM,
  RSR_ENFORCEMENT_OPTIONS,
  TIMEZONES,
  TIME_OPTIONS,
  VERSION_ENFORCEMENT_OPTIONS,
  handleArticle,
  handlePreviousManagedOSName,
  hasMinimumRSRVersion,
  sortAutoAppVersionsDesc,
  sortVersionsDesc,
} from './updates-card-constants';
import type { ManagedOSName, UpdatesCardProps } from './updates-card.types';

function UpdatesCard({
  type = '',
  setting,
  update,
  isDisabled = false,
  selectedTimezone,
  appName,
  installationType,
  installationUpdateOnly,
  validationDep,
  name,
  version,
  isDevelopmentInstance,
  fall2023NewManagedOSLibraryItems,
  fall2023RSREnforcement,
}: UpdatesCardProps) {
  const enforcementValue = setting.versionEnforcement?.value;
  const isCustomEnforcement = enforcementValue === ENFORCEMENT_KEYS.custom;
  const isAnyEnforcement = enforcementValue === ENFORCEMENT_KEYS.any;
  const isNewestEnforcement = enforcementValue === ENFORCEMENT_KEYS.newest;
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    FIELDS_TO_VALIDATE,
    update,
  );

  const versionEnforcementOptions = useMemo(() => {
    if (
      installationType ===
        AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE &&
      installationUpdateOnly
    ) {
      return VERSION_ENFORCEMENT_OPTIONS.filter(
        (option) => option.value !== ENFORCEMENT_KEYS.any,
      );
    }

    return VERSION_ENFORCEMENT_OPTIONS;
  }, [installationType, installationUpdateOnly]);

  const versionsOptions = useMemo(
    () => setting.versionOptions?.sort(sortVersionsDesc),
    [setting.versionOptions],
  );

  const versionsAutoAppOptions = useMemo(
    () => setting.versionAutoAppOptions?.sort(sortAutoAppVersionsDesc),
    [setting.versionAutoAppOptions],
  );

  const canShowEnforcementDeadlineError = useValidate({
    error:
      isCustomEnforcement &&
      (!setting.enforcementDeadline ||
        setting.enforcementDeadline?.toString() === 'Invalid Date'),
    validationDep,
    reset: isCustomEnforcement,
    onInvalidate: onInvalidate(0),
  });

  const showTimezoneError = useValidate({
    error:
      isNewestEnforcement && (!selectedTimezone || !selectedTimezone.value),
    validationDep,
    reset: isNewestEnforcement || isDisabled,
    onInvalidate: onInvalidate(1),
  });

  const showContinuousEnforcementError = useValidate({
    error:
      isAnyEnforcement &&
      installationType ===
        MacosReleasesService.installationTypes.CONTINUOUSLY_ENFORCE,
    validationDep,
    reset: isAnyEnforcement || isDisabled,
    onInvalidate: onInvalidate(2),
  });

  const hasRsr =
    !isAnyEnforcement &&
    fall2023RSREnforcement &&
    hasMinimumRSRVersion(appName, version);

  const showRsrBanners =
    hasRsr &&
    setting.rsrEnforcement?.value !== RSR_ENFORCEMENT_OPTIONS[0].value;

  const banners = useMemo(
    () =>
      getBanners({
        appName,
        versions: setting.versions,
        versionsAutoApps: setting.versionsAutoApps,
        type,
        enforcementDelay: +(setting.enforcementDelay?.value || 0),
      }),
    [
      appName,
      setting.enforcementDelay?.value,
      setting.versions,
      setting.versionsAutoApps,
      type,
    ],
  );

  const rsrBanners = useMemo(
    () =>
      getRsrBanners({
        rsrVersions: setting.rsrVersions,
        appName,
        enforcementDelay: +(setting.rsrEnforcementDelay?.value || 0),
      }),
    [appName, setting.rsrEnforcementDelay?.value, setting.rsrVersions],
  );

  const isAutoApp = type === apiTypes.AUTO_APP;

  return (
    <Setting.Card className="b-mt3">
      <Setting.Header className="b-h3">Enforcement</Setting.Header>
      <Setting.SubHeader className="b-txt">
        Specify how {appName} versions are automatically enforced.{' '}
        <a
          href={handleArticle(name)}
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          Learn more...
        </a>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title className="b-txt">Version enforcement</Setting.Title>

          <Setting.Helpers>
            <p className="b-txt-light">
              Specify if updates should not be managed, automatically be
              enforced after they are released, or select a minimum {appName}{' '}
              version for enforcement.
            </p>
            {!isAnyEnforcement && (
              <Banner theme="info">
                <p>
                  {isAutoApp
                    ? `Kandji always installs the latest update of ${appName}`
                    : `Managed OS always installs the latest
                  update for this major version of ${appName}`}
                  .
                </p>
              </Banner>
            )}
          </Setting.Helpers>

          <Setting.Controls>
            <Select
              value={setting.versionEnforcement}
              onChange={(newValue) => update('versionEnforcement', newValue)}
              options={versionEnforcementOptions}
              disabled={isDisabled}
              errorText={
                showContinuousEnforcementError &&
                // @ts-expect-error typing seems wrong
                invalidations.versionEnforcement &&
                'This configuration is invalid. If you choose to continuously enforce upgrades, you need to set a schedule.'
              }
              compact
            />
          </Setting.Controls>
        </Setting.Row>

        {isCustomEnforcement && (
          <Setting.Row>
            <Setting.Title className="b-txt">Minimum version</Setting.Title>

            <Setting.Helpers className="b-txt-light">
              Specify the minimum {appName} version to enforce.
            </Setting.Helpers>

            <Setting.Controls>
              {versionsAutoAppOptions.length > 0 &&
              type === apiTypes.AUTO_APP ? (
                <Select
                  inputId="version-select"
                  // @ts-expect-error Incorrect typing from Select
                  value={setting.minimumAutoAppVersion}
                  // @ts-expect-error Incorrect typing from Select
                  options={versionsAutoAppOptions}
                  disabled={isDisabled}
                  compact
                  onChange={(newValue) =>
                    update('minimumAutoAppVersion', newValue)
                  }
                />
              ) : (
                <Select
                  inputId="version-select"
                  value={setting.minimumVersion}
                  options={versionsOptions}
                  disabled={isDisabled}
                  compact
                  onChange={(newValue) => update('minimumVersion', newValue)}
                />
              )}
            </Setting.Controls>
          </Setting.Row>
        )}

        {!isAnyEnforcement && (
          <Setting.Row>
            <Setting.Title className="b-txt">
              {isNewestEnforcement
                ? 'Enforcement timeframe'
                : 'Enforcement deadline'}
            </Setting.Title>

            <Setting.Helpers className="b-txt-light">
              Specify when the {appName} version should be enforced. The update
              will be cached and users will be able to voluntarily update before
              the enforcement deadline.
            </Setting.Helpers>

            <Setting.Controls>
              {isNewestEnforcement && (
                <div className="enforcement-delay-wrapper">
                  <Select
                    inputId="enforcement-timeframe-select"
                    options={
                      !isDevelopmentInstance
                        ? DELAY_OPTIONS
                        : [
                            { value: '0', label: 'Immediate [DEV]' },
                            ...DELAY_OPTIONS,
                          ]
                    }
                    disabled={isDisabled}
                    onChange={(newValue) =>
                      update('enforcementDelay', newValue)
                    }
                    value={setting.enforcementDelay}
                  />
                  <div className="b-txt">After an update is released</div>
                </div>
              )}
              {isCustomEnforcement && (
                <div>
                  <DatePicker
                    wrapperRef={refs[0]}
                    value={setting.enforcementDeadline}
                    onChange={(newDeadline) =>
                      update('enforcementDeadline', newDeadline)
                    }
                    isDisabled={isDisabled}
                    error={
                      canShowEnforcementDeadlineError &&
                      // @ts-expect-error typing seems wrong
                      invalidations.enforcementDeadline &&
                      'Required'
                    }
                  />
                </div>
              )}

              {!(
                fall2023NewManagedOSLibraryItems &&
                MANAGED_OS_DDM.includes(name as ManagedOSName)
              ) && (
                <div ref={refs[1]}>
                  <Select
                    inputId="enforcement-timezone-select"
                    options={TIMEZONES}
                    value={selectedTimezone}
                    disabled={isDisabled}
                    onChange={(newValue) =>
                      update('enforcementTimezone', newValue)
                    }
                    className="b-mt2"
                    compact
                    errorText={
                      showTimezoneError &&
                      setting.invalidationsMap?.enforcementTimezone?.isInvalid
                        ? 'Required'
                        : undefined
                    }
                  />
                </div>
              )}
              <Select
                options={TIME_OPTIONS}
                value={setting.enforcementTime}
                onChange={(newValue) => update('enforcementTime', newValue)}
                disabled={isDisabled}
                compact
                className="b-mt2"
              />
            </Setting.Controls>
          </Setting.Row>
        )}

        {fall2023NewManagedOSLibraryItems &&
          MANAGED_OS_DDM.includes(name as ManagedOSName) &&
          !isAnyEnforcement && (
            <Setting.Row>
              <Setting.Title className="b-txt">
                Timezone ({handlePreviousManagedOSName(name)} and earlier)
              </Setting.Title>

              <Setting.Helpers>
                <p className="b-txt-light" ref={refs.enforcementTimezone}>
                  For upgrades from {handlePreviousManagedOSName(name)} and
                  earlier, specify the timezone Kandji should use to enforce the
                  upgrade.
                </p>
                <Banner theme="info">
                  <p>
                    {name} enforces updates in the device&apos;s local timezone.
                  </p>
                </Banner>
              </Setting.Helpers>

              <Setting.Controls>
                <Select
                  inputId="enforcement-timezone-select"
                  options={TIMEZONES}
                  value={selectedTimezone}
                  disabled={isDisabled}
                  onChange={(newValue) =>
                    update('enforcementTimezone', newValue)
                  }
                  className="b-mt2"
                  compact
                />
              </Setting.Controls>
            </Setting.Row>
          )}

        {hasRsr && (
          <RSREnforcementUpdateSection
            name={name}
            rsrEnforcement={setting.rsrEnforcement}
            rsrEnforcementDelay={setting.rsrEnforcementDelay}
            rsrEnforcementTime={setting.rsrEnforcementTime}
            update={update}
            isDisabled={isDisabled}
          />
        )}

        {isNewestEnforcement &&
          banners.map(({ version: bannerVersion, text }) => (
            <Setting.Row key={bannerVersion}>
              <div style={{ gridColumn: '1/3' }}>
                <Banner key={bannerVersion} theme="info" kind="block">
                  <p> {text} </p>
                </Banner>
              </div>
            </Setting.Row>
          ))}

        {showRsrBanners &&
          rsrBanners.map(({ version: bannerVersion, text }) => (
            <Setting.Row key={bannerVersion}>
              <div style={{ gridColumn: '1/3' }}>
                <Banner key={bannerVersion} theme="info" kind="block">
                  <p> {text} </p>
                </Banner>
              </div>
            </Setting.Row>
          ))}
      </Setting.Rows>
    </Setting.Card>
  );
}

export default memo(UpdatesCard);
