import { Button, Icon } from '@kandji-inc/bumblebee';
import React, { useContext } from 'react';
import { useOldField } from 'src/old-honey-form';

import { links } from 'app/common/constants';
import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import { FieldText } from 'theme/components/molecules/Form';
import Dropdown from '../../theme/components/atoms/Dropdown';
import OldIcon from '../../theme/components/atoms/Icon';
import Modal from '../../theme/components/atoms/Modal';
import Table from '../../theme/components/atoms/Table';

import { Context } from './context';
import strategy from './strategy';
import useConnectionEnrollments from './useConnectionEnrollments';

// TODO: fix Offcanvas resetting the sort order of the table when opened

const getADELibraryItemUrl = (id) =>
  `${window.location.protocol}//${window.location.host}${links.customAutoEnroll}/${id}`;

const renderMenu = (data, menu, strategyType, isStandardAuth) => {
  const menuStandardAuth = () => {
    if (isStandardAuth || data?.length <= 1) {
      return null;
    }
    return menu;
  };

  switch (strategyType) {
    case 'email_password':
    case 'auth0':
      return menuStandardAuth();
    default:
      return menu;
  }
};

export { renderMenu };

export default () => {
  const {
    list: data,
    isWorking,
    onEnable,
    onDisable,
    onDelete,
    onWizardOpen,
  } = useContext(Context);
  const { isStandardAuth } = useContext(AccountContext);

  const { enrollments } = useConnectionEnrollments({
    connectionIds: data.map((c) => c.id),
  });

  const { onModalOpen, onModalClose } = useContext(InterfaceContext);
  const deleteConfirmValueField = useOldField('deleteConfirmValue');

  const formatterStrategy = (cell, row) => {
    const field = row.strategy;
    const { label, icon } = strategy[field];

    return (
      <div className={`strategy strategy-${field}`}>
        {icon === 'kandji-circle' ? (
          <span style={{ marginRight: '16px' }}>
            <Icon name="kandji-bee" />
          </span>
        ) : (
          <OldIcon icon={icon} />
        )}
        <span className="label">{label}</span>
      </div>
    );
  };
  const formatterStatus = (cell, row) => {
    const field = row.is_enabled;
    const toggle = field ? 'on' : 'off';
    const label = field ? 'active' : 'inactive';

    return (
      <div className={`status status-${toggle} chip`}>
        <span>{label}</span>
      </div>
    );
  };
  const formatterAction = (
    cell,
    row,
    rowIndex,
    { isWorking, deleteConfirmValue, enrollments },
  ) => {
    const name = row.display_name || 'no-name';
    const { id, strategy: strategyType, is_enabled: active } = row;

    const modalDisable = `disable-${id}`;
    const modalDelete = `delete-${id}`;

    const isEmailPassword =
      strategyType === 'email_password' || strategyType === 'auth0';

    const label = <Icon name="ellipsis-vertical" />;
    const render = (toggle, setToggle) => (
      <div className="dropdown-menu-body dropdown-menu-body-default">
        <ul className="dropdown-menu-list">
          <li className="dropdown-menu-list-item">
            {active ? (
              <Button
                kind="link"
                theme="dark"
                icon="circle-xmark"
                onClick={() => onModalOpen(modalDisable)}
              >
                Disable
              </Button>
            ) : (
              <Button
                kind="link"
                icon="circle-plus"
                onClick={() => onEnable(row)}
              >
                Enable
              </Button>
            )}
          </li>
          {!isEmailPassword && (
            <>
              <li className="dropdown-menu-list-item">
                <Button
                  kind="link"
                  icon="pencil"
                  onClick={() => onWizardOpen(id, strategyType)}
                >
                  Configure
                </Button>
              </li>
              <li className="dropdown-menu-list-item">
                <Button
                  kind="link"
                  theme="error"
                  icon="trash-can"
                  onClick={() => onModalOpen(modalDelete)}
                >
                  Delete
                </Button>
              </li>
            </>
          )}
        </ul>
      </div>
    );

    const menu = (
      <>
        <Dropdown
          type="action"
          alignment="right"
          name={name}
          label={label}
          render={render}
        />
        <Modal name={modalDisable}>
          <div className="paper">
            <header className="modal-division division with-cross">
              <div className="node">
                {isEmailPassword ? (
                  <h3 className="modal-title">{`Disable ${name}`}</h3>
                ) : (
                  <h3 className="modal-title">
                    {`Disable ${name} connection`}
                  </h3>
                )}
              </div>
              <div className="node">
                <Button kind="link" icon="xmark" onClick={onModalClose}>
                  Close
                </Button>
              </div>
            </header>
            <section className="modal-division division">
              <div className="node">
                {isEmailPassword ? (
                  <>
                    <p>
                      Disabling authentication via a Kandji Email / Password
                      will allow team members to only authenticate via Single
                      Sign-On connections.
                    </p>
                    <p>
                      Please ensure that you have tested your Single Sign-On
                      connection in a private browser window prior to disabling.
                    </p>
                  </>
                ) : (
                  <p>
                    Disabling this Single Sign-On connection may prevent team
                    members from signing in via the Identity Provider. Are you
                    sure you want to disable this SSO connection?
                  </p>
                )}
              </div>
            </section>
            <footer className="modal-division division divide">
              <div className="node d-flex justify-content-end">
                <Button
                  kind="outline"
                  disabled={isWorking}
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
                <Button
                  theme="dark"
                  disabled={isWorking}
                  onClick={() => onDisable(row)}
                  loader
                >
                  Disable
                </Button>
              </div>
            </footer>
          </div>
        </Modal>
        <Modal name={modalDelete}>
          <div className="paper">
            {enrollments[id] ? (
              <>
                <header className="modal-division division with-cross">
                  <div className="node">
                    <h3 className="modal-title">
                      Unable to delete {name} connection
                    </h3>
                  </div>
                  <div className="node">
                    <Button kind="link" icon="xmark" onClick={onModalClose} />
                  </div>
                </header>
                <section className="modal-division division">
                  <div className="node">
                    <p>
                      This connection is being used by the following Automated
                      Device Enrollment Library Items. Please remove the
                      connection before deletion.
                    </p>
                    {enrollments[id].map(({ id, name }) => (
                      <p>
                        <a
                          className="modal-strong"
                          href={getADELibraryItemUrl(id)}
                        >
                          {name}
                        </a>
                      </p>
                    ))}
                  </div>
                </section>
                <footer className="modal-division division divide">
                  <div className="node d-flex justify-content-end">
                    <Button
                      kind="outline"
                      label="Cancel"
                      disabled={isWorking}
                      onClick={onModalClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </footer>
              </>
            ) : (
              <>
                <header className="modal-division division with-cross">
                  <div className="node">
                    <h3 className="modal-title">
                      Are you sure you want to delete {name} connection?
                    </h3>
                  </div>
                  <div className="node">
                    <Button
                      kind="link"
                      icon="xmark"
                      onClick={() => {
                        onModalClose();
                        deleteConfirmValueField.setValue('');
                      }}
                    />
                  </div>
                </header>
                <section className="modal-division division">
                  <div className="node">
                    <p>
                      Deleting this Single Sign-On connection will prevent users
                      from authenticating via the associated identity provider.
                    </p>
                    <p>
                      If this is your only remaining Single Sign-On connection,
                      authenticating via Kandji Email / Password will be
                      automatically re-enabled.
                    </p>
                    <FieldText
                      name="deleteConfirmValue"
                      required
                      label={
                        <>
                          Please type{' '}
                          <strong className="modal-strong">{name}</strong> to
                          confirm.
                        </>
                      }
                    />
                  </div>
                </section>
                <footer className="modal-division division divide">
                  <div className="node d-flex justify-content-end">
                    <Button
                      kind="outline"
                      disabled={isWorking}
                      onClick={() => {
                        onModalClose();
                        deleteConfirmValueField.setValue('');
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      theme="error"
                      disabled={isWorking || deleteConfirmValue !== name}
                      onClick={async () => {
                        await onDelete(row);
                        deleteConfirmValueField.setValue('');
                      }}
                      loader={isWorking}
                    >
                      Delete
                    </Button>
                  </div>
                </footer>
              </>
            )}
          </div>
        </Modal>
      </>
    );

    return renderMenu(data, menu, strategyType, isStandardAuth);
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      isKey: true,
    },
    {
      dataField: 'display_name',
      text: 'Name',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'strategy',
      text: 'Identity Provider',
      sort: true,
      searchable: true,
      formatter: formatterStrategy,
    },
    {
      dataField: 'is_enabled',
      text: 'Status',
      sort: true,
      searchable: true,
      formatter: formatterStatus,
    },
    {
      dataField: '',
      text: '',
      isDummyField: true,
      headerStyle: {
        width: '60px',
        textAlign: 'right',
      },
      formatter: formatterAction,
      formatExtraData: {
        isWorking,
        deleteConfirmValue: deleteConfirmValueField.getValue(),
        enrollments,
      },
    },
  ];

  return <Table columns={columns} data={data} filter={null} />;
};
