/* istanbul ignore file */
import {
  Button,
  Flex,
  SelectV2 as Select,
  TextField,
} from '@kandji-inc/nectar-ui';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FACETS, OPS } from '../constants';

export const Filter = ({ filter, setFilter, setQuery, query }) => {
  const sendQuery = () => {
    const newQuery = filter.reduce((acc, f) => {
      if (f.value) {
        return {
          ...acc,
          [f.facet]: {
            ...(acc[f.facet] || {}),
            [f.op]: [f.value],
          },
        };
      }
      return acc;
    }, {});

    setQuery(newQuery);
  };

  const addFilter = () => {
    setFilter([
      ...filter,
      {
        id: uuidv4(),
        facet: FACETS[0].value,
        op: OPS[0].value,
        value: '',
      },
    ]);
  };

  const removeFilter = (index) => {
    setFilter((prevFilter) => prevFilter.filter((_, i) => i !== index));
  };

  const updateFilter = (index, updatedFilter) => {
    setFilter((prevFilter) =>
      prevFilter.map((filter, i) => (i === index ? updatedFilter : filter)),
    );
  };

  const resetFilters = () => {
    setFilter([
      {
        id: uuidv4(),
        facet: FACETS[0].value,
        op: OPS[0].value,
        value: '',
      },
    ]);
    setQuery({});
  };

  return (
    <Flex flow="column" gap="md">
      {filter.map((f, i) => {
        const usedFacets = filter.slice(0, i).map((f) => f.facet);
        const availableFacets = FACETS.filter(
          ({ value }) => !usedFacets.includes(value),
        );
        return (
          <Flex gap="md" key={f.id}>
            <Select
              value={f.facet || availableFacets[0].value}
              onValueChange={(e) => updateFilter(i, { ...f, facet: e })}
              triggerText={
                availableFacets.find((v) => v.value === f.facet)?.label ||
                availableFacets[0].label
              }
              options={availableFacets.map(({ value, label }) => ({
                value,
                label,
              }))}
            />
            <Select
              value={f.op || OPS[0].value}
              onValueChange={(e) => updateFilter(i, { ...f, op: e })}
              triggerText={
                OPS.find((v) => v.value === f.op)?.label || OPS[0].label
              }
              options={OPS.map(({ value, label }) => ({
                value,
                label,
              }))}
            />
            <TextField
              css={{ '& input': { padding: '20px', width: '200px' } }}
              compact
              icon="magnifying-glass"
              onChange={(e) => updateFilter(i, { ...f, value: e.target.value })}
            />
            {i === filter.length - 1 ? (
              <>
                <Button
                  compact
                  variant="subtle"
                  icon={{
                    name: 'plus',
                    position: 'left',
                  }}
                  onClick={addFilter}
                  disabled={!f.value}
                />
                <Flex
                  css={{ marginLeft: 'auto' }}
                  gap="md"
                  justifyContent="flex-end"
                >
                  <Button
                    compact
                    variant="subtle"
                    icon={{
                      name: 'filter',
                      position: 'left',
                    }}
                    onClick={sendQuery}
                  >
                    Apply
                  </Button>
                  {Object.keys(query).length > 0 && (
                    <Button
                      compact
                      variant="subtle"
                      icon={{
                        name: 'trash',
                        position: 'left',
                      }}
                      onClick={resetFilters}
                    >
                      Reset
                    </Button>
                  )}
                </Flex>
              </>
            ) : (
              <Button
                compact
                icon={{
                  name: 'minus',
                  position: 'left',
                }}
                onClick={() => removeFilter(i)}
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
