// import PropTypes from 'prop-types';
import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useEffect } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from './activity-tab';
import initial, { configurationsModelProperty } from './initial-state';
import Configurations from './sections/configurations';
import { airPrintService } from './service/air-print-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useAirPrintService from './service/use-air-print-service';

const AirPrintPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const settingsUpdate = useCallback(update('settings', setModel));

  const itemConfig = config.AirPrint;

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={airPrintService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <Configurations
        configurations={model.settings[configurationsModelProperty]}
        isDisabled={pageState.isDisabled}
        update={settingsUpdate}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

// AirPrintPage.propTypes = {};

const AirPrint = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useAirPrintService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <AirPrintPage {...pageProps} testId="air-print-v2" />;
};

export default AirPrint;
