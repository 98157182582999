/* istanbul ignore file */
import React from 'react';

const Head = ({ disableCustomIcon }) => (
  <p className="b-txt">
    {`Customize the ${
      disableCustomIcon ? '' : 'icon,'
    } name and content that will display in Self Service.`}
  </p>
);

export default Head;
