import { ButtonSelect, Select, TextField } from '@kandji-inc/nectar-ui';
import React, { useCallback } from 'react';
import type {
  AllowBlockFilters,
  EventTypeFilters,
  ItemTypeFilters,
} from '../../avert.types';
import AvertService from '../../service/avert.service';

const itemTypeSelectOptions: ItemTypeFilters = [
  { label: 'All types', value: 'all' },
  ...AvertService.itemTypeOptions,
];

const eventTypeChipOptions: EventTypeFilters = [
  { value: 'all', label: 'All' },
  ...AvertService.eventTypeOptions,
];

type AllowBlockListFiltersProps = {
  filters: AllowBlockFilters;
  onChangeFilters: (filters: AllowBlockFilters) => void;
};

const AllowBlockListFilters = (props: AllowBlockListFiltersProps) => {
  const { filters, onChangeFilters } = props;

  const handleItemTypeChange = useCallback(
    (itemType: AllowBlockFilters['itemType']) => {
      onChangeFilters({ ...filters, itemType });
    },
    [filters, onChangeFilters],
  );

  const handleSearchChange = useCallback(
    (e) => {
      const search = e.target.value;
      onChangeFilters({ ...filters, search });
    },
    [filters, onChangeFilters],
  );

  // istanbul ignore next -- clear button onClick event doesn't get triggered in tests
  const handleClearSearch = useCallback(() => {
    onChangeFilters({
      ...filters,
      search: filters.search ? '' : filters.search,
    });
  }, [filters, onChangeFilters]);

  const handleEventTypeChange = useCallback(
    (eventType: AllowBlockFilters['eventType']) => {
      onChangeFilters({ ...filters, eventType });
    },
    [filters, onChangeFilters],
  );

  return (
    <>
      <Select
        size="xs"
        options={itemTypeSelectOptions}
        value={filters.itemType}
        onChange={handleItemTypeChange}
        testId="type-select"
      />
      <ButtonSelect.Root
        type="single"
        value={filters.eventType}
        data-testid="chip-filter"
      >
        {eventTypeChipOptions.map((item) => (
          <ButtonSelect.Item
            key={item.value}
            value={item.value}
            onClick={() => handleEventTypeChange(item.value)}
          >
            {item.label}
          </ButtonSelect.Item>
        ))}
      </ButtonSelect.Root>
      <TextField
        placeholder="Search"
        value={filters.search}
        icon="magnifying-glass"
        onChange={handleSearchChange}
        showClearButton={Boolean(filters.search)}
        onClear={handleClearSearch}
        data-testid="filter-search"
        css={{ lineHeight: 'normal' }}
      />
    </>
  );
};

export default AllowBlockListFilters;
