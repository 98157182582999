import {
  Button,
  SelectV2,
  Text,
  styled,
  useDialog,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useADEListViewStore } from '../store/ADEListViewStore';
import { ADEBlueprintDialog } from './ADEBlueprintDialog';
import { ADEUserDialog } from './ADEUserDialog';

const PaginationSize = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'lighter',
  gap: 4,
});

const ResultsPerPageText = styled('div', {
  display: 'flex',
  marginRight: '10px',
  paddingRight: '10px',
  borderRight: '1px solid $neutral20',
  whiteSpace: 'nowrap',
  color: '$neutral60',
  gap: 8,
});

interface ADEPaginationActionsProps {
  sizePerPage: number;
  setSizePerPage: React.Dispatch<React.SetStateAction<number>>;
  isAllDevices: boolean;
}

export function ADEPaginationBulkActions() {
  const [isBlueprintOpen, blueprintToggle] = useDialog();
  const [isUserOpen, userToggle] = useDialog();

  return (
    <PaginationSize>
      <ADEBlueprintDialog isOpen={isBlueprintOpen} toggle={blueprintToggle} />
      <ADEUserDialog isOpen={isUserOpen} toggle={userToggle} />
      <ResultsPerPageText>
        <Button
          compact
          onClick={/* istanbul ignore next */ () => blueprintToggle()}
        >
          Assign Blueprint
        </Button>
        <Button compact onClick={/* istanbul ignore next */ () => userToggle()}>
          Assign user
        </Button>
      </ResultsPerPageText>
    </PaginationSize>
  );
}

export function ADEPaginationActions({
  sizePerPage,
  setSizePerPage,
  isAllDevices,
}: ADEPaginationActionsProps) {
  const { selectAllDevices, selectedDevices } = useADEListViewStore(
    (state) => ({
      selectAllDevices: state.selectAllDevices,
      selectedDevices: state.selectedDevices,
      setSelectedDevices: state.setSelectedDevices,
      setSelectAllDevices: state.setSelectAllDevices,
    }),
  );

  const showBulkActions =
    !isAllDevices && (selectAllDevices || selectedDevices.length > 0);

  const getSizePerPageOption = React.useCallback(
    (size: number) => ({
      label: `${size}`,
      value: size.toString(),
    }),
    [],
  );

  const sizePerPageOptions = [
    getSizePerPageOption(50),
    getSizePerPageOption(100),
    getSizePerPageOption(300),
  ];

  if (showBulkActions) {
    return <ADEPaginationBulkActions />;
  }

  return (
    <PaginationSize>
      <SelectV2
        triggerText={sizePerPage.toString()}
        onValueChange={
          /* istanbul ignore next */ (value) => setSizePerPage(parseInt(value))
        }
        value={sizePerPage.toString()}
        options={sizePerPageOptions}
        isCompact
      />
      <ResultsPerPageText>
        <Text>results per page</Text>
      </ResultsPerPageText>
    </PaginationSize>
  );
}
