import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SystemPreferencesService extends NewLibraryItemService {
  static preVenturaOptions = [
    {
      label: 'Accessibility',
      value: 'com.apple.preference.universalaccess',
      isChecked: false,
    },
    {
      label: 'App Store',
      value: 'com.apple.preferences.appstore',
      isChecked: false,
    },
    {
      label: 'Apple Account',
      value: 'com.apple.preferences.AppleIDPrefPane',
      isChecked: false,
    },
    {
      label: 'Bluetooth',
      value: 'com.apple.preferences.Bluetooth',
      isChecked: false,
    },
    {
      label: 'CDs & DVDs',
      value: 'com.apple.preference.digihub.discs',
      isChecked: false,
    },
    {
      label: 'Classroom',
      value: 'com.apple.ClassroomSettings',
      isChecked: false,
    },
    {
      label: 'Date & Time',
      value: 'com.apple.preference.datetime',
      isChecked: false,
    },
    {
      label: 'Desktop & Screen Saver',
      value: 'com.apple.preference.desktopscreeneffect',
      isChecked: false,
    },
    {
      label: 'Displays',
      value: 'com.apple.preference.displays',
      isChecked: false,
    },
    {
      label: 'Dock',
      value: 'com.apple.preference.dock',
      isChecked: false,
    },
    {
      label: 'Energy Saver',
      value: 'com.apple.preference.energysaver',
      isChecked: false,
    },
    {
      label: 'Extensions',
      value: 'com.apple.preferences.extensions',
      isChecked: false,
    },
    {
      label: 'Family Sharing',
      value: 'com.apple.preferences.FamilySharingPrefPane',
      isChecked: false,
    },
    {
      label: 'Fibre Channel',
      value: 'com.apple.prefpanel.fibrechannel',
      isChecked: false,
    },
    {
      label: 'General',
      value: 'com.apple.preference.general',
      isChecked: false,
    },
    {
      label: 'iCloud',
      value: 'com.apple.preferences.icloud',
      isChecked: false,
    },
    { label: 'Ink', value: 'com.apple.preference.ink', isChecked: false },
    {
      label: 'Internet Accounts',
      value: 'com.apple.preferences.internetaccounts',
      isChecked: false,
    },
    {
      label: 'Keyboard',
      value: 'com.apple.preference.keyboard',
      isChecked: false,
    },
    {
      label: 'Language & Region',
      value: 'com.apple.Localization',
      isChecked: false,
    },
    {
      label: 'Mission Control',
      value: 'com.apple.preference.expose',
      isChecked: false,
    },
    {
      label: 'Mouse',
      value: 'com.apple.preference.mouse',
      isChecked: false,
    },
    {
      label: 'Network',
      value: 'com.apple.preference.network',
      isChecked: false,
    },
    {
      label: 'Notifications',
      value: 'com.apple.preference.notifications',
      isChecked: false,
    },
    {
      label: 'Parental Controls',
      value: 'com.apple.preferences.parentalcontrols',
      isChecked: false,
    },
    {
      label: 'Printers & Scanners',
      value: 'com.apple.preference.printfax',
      isChecked: false,
    },
    {
      label: 'Profiles',
      value: 'com.apple.preferences.configurationprofiles',
      isChecked: false,
    },
    {
      label: 'Screen Time',
      value: 'com.apple.preference.screentime',
      isChecked: false,
    },
    {
      label: 'Security & Privacy',
      value: 'com.apple.preference.security',
      isChecked: false,
    },
    {
      label: 'Sharing',
      value: 'com.apple.preferences.sharing',
      isChecked: false,
    },
    {
      label: 'Sidecar',
      value: 'com.apple.preference.sidecar',
      isChecked: false,
    },
    {
      label: 'Siri',
      value: 'com.apple.preference.speech',
      isChecked: false,
    },
    {
      label: 'Software Update',
      value: 'com.apple.preferences.softwareupdate',
      isChecked: false,
    },
    {
      label: 'Sound',
      value: 'com.apple.preference.sound',
      isChecked: false,
    },
    {
      label: 'Spotlight',
      value: 'com.apple.preference.spotlight',
      isChecked: false,
    },
    {
      label: 'Startup Disk',
      value: 'com.apple.preference.startupdisk',
      isChecked: false,
    },
    {
      label: 'Time Machine',
      value: 'com.apple.prefs.backup',
      isChecked: false,
    },
    {
      label: 'Touch ID',
      value: 'com.apple.preferences.password',
      isChecked: false,
    },
    {
      label: 'Trackpad',
      value: 'com.apple.preference.trackpad',
      isChecked: false,
    },
    {
      label: 'Users & Groups',
      value: 'com.apple.preferences.users',
      isChecked: false,
    },
    {
      label: 'Wallet & Apple Pay',
      value: 'com.apple.preferences.wallet',
      isChecked: false,
    },
  ];

  static postVenturaOptions = [
    {
      label: 'Accessibility',
      value: 'com.apple.Accessibility-Settings.extension',
      isChecked: false,
    },
    {
      label: 'AirDrop & Handoff',
      value: 'com.apple.AirDrop-Handoff-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Apple Account',
      value: 'com.apple.systempreferences.AppleIDSettings',
      isChecked: false,
    },
    {
      label: 'Battery',
      value: 'com.apple.Battery-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Bluetooth',
      value: 'com.apple.BluetoothSettings',
      isChecked: false,
    },
    {
      label: 'CD & DVDs',
      value: 'com.apple.CD-DVD-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Classkit',
      value: 'com.apple.ClassKit-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Classroom',
      value: 'com.apple.Classroom-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Control Center',
      value: 'com.apple.ControlCenter-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Date & Time',
      value: 'com.apple.Date-Time-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Desktop',
      value: 'com.apple.Desktop-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Displays',
      value: 'com.apple.Displays-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Extensions',
      value: 'com.apple.ExtensionsPreferences',
      isChecked: false,
    },
    {
      label: 'Family Sharing',
      value: 'com.apple.Family-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Focus',
      value: 'com.apple.Focus-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Game Center',
      value: 'com.apple.Game-Center-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Game Controllers',
      value: 'com.apple.Game-Controller-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Headphones',
      value: 'com.apple.HeadphoneSettings',
      isChecked: false,
    },
    {
      label: 'Internet Accounts',
      value: 'com.apple.Internet-Accounts-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Keyboard',
      value: 'com.apple.Keyboard-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Language & Region',
      value: 'com.apple.Localization-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Lock Screen',
      value: 'com.apple.Lock-Screen-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Login Items',
      value: 'com.apple.LoginItems-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Mouse',
      value: 'com.apple.Mouse-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Network',
      value: 'com.apple.Network-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Network Extensions',
      value: 'com.apple.NetworkExtensionSettingsUI.NESettingsUIExtension',
      isChecked: false,
    },
    {
      label: 'Notifications',
      value: 'com.apple.Notifications-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Password',
      value: 'com.apple.Passwords-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Printers & Scanners',
      value: 'com.apple.Print-Scan-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Screen Saver',
      value: 'com.apple.ScreenSaver-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Screen Time',
      value: 'com.apple.Screen-Time-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Sharing',
      value: 'com.apple.Sharing-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Siri',
      value: 'com.apple.Siri-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Software Update',
      value: 'com.apple.Software-Update-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Sound',
      value: 'com.apple.Sound-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Startup Disk',
      value: 'com.apple.Startup-Disk-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Storage',
      value: 'com.apple.settings.Storage',
      isChecked: false,
    },
    {
      label: 'Time Machine',
      value: 'com.apple.Time-Machine-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Touch ID',
      value: 'com.apple.Touch-ID-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Trackpad',
      value: 'com.apple.Trackpad-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Transfer Reset',
      value: 'com.apple.Transfer-Reset-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Users & Groups',
      value: 'com.apple.Users-Groups-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Wallet & Apple Pay',
      value: 'com.apple.WalletSettingsExtension',
      isChecked: false,
    },
    {
      label: 'Wallpaper',
      value: 'com.apple.Wallpaper-Settings.extension',
      isChecked: false,
    },
    {
      label: 'Wi-Fi',
      value: 'com.apple.wifi-settings-extension',
      isChecked: false,
    },
  ];

  /**
   * Takes the API reponse for the General section options and transforms it into a list of objects for the checkboxes.
   * API => UI
   * @param {Array[String]} generalOptionsApiData The `DisabledPreferencePanes` array from the API response
   * @returns {Array} Mapping of option keys to their checkbox state
   */
  static serializeGeneralOptions(generalOptionsApiData, isPostVentura) {
    if (isPostVentura) {
      return this.postVenturaOptions.map((option) => ({
        ...option,
        isChecked: Boolean(generalOptionsApiData.includes(option.value)),
      }));
    }
    return this.preVenturaOptions.map((option) => ({
      ...option,
      isChecked: Boolean(generalOptionsApiData.includes(option.value)),
    }));
  }

  /**
   * Transforms the UI model for `DisabledPreferencePanes` into an array of preferences that are enabled/checked.
   * UI => API
   * @param {Array[Object]} generalOptionsUiData The `DisabledPreferencePanes` array from the UI model
   * @returns {Array[String]}
   */
  static deserializeGeneralOptions(generalOptionsUiData) {
    return generalOptionsUiData.reduce((arr, option) => {
      if (option.isChecked) {
        arr.push(option.value);
      }

      return arr;
    }, []);
  }
}

export const systemPreferencesService = new SystemPreferencesService();

export default SystemPreferencesService;
