import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import {
  Button,
  DropdownMenuPrimitives,
  Flex,
  Icon,
  Text,
  TextField,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { type MouseEvent, useEffect, useState } from 'react';
import { useTags } from 'src/features/tags';

type ExtendedDropdownMenuOption = DropdownMenuOption & {
  disabledTooltipContent?: string;
};

const EditSlide = (props: {
  name: string;
  id: string;
  goBack: () => void;
  closeDropdown: () => void;
  refetchTags: () => void;
}) => {
  const { name, id, goBack, closeDropdown, refetchTags } = props;
  const { updateTag } = useTags();
  const [newName, setNewName] = useState(name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const onSave = (e) => {
    e?.preventDefault();
    setIsSubmitting(true);
    updateTag({ id, name: newName.trim() })
      .then(() => {
        refetchTags();
        setIsSubmitting(false);
        closeDropdown();
      })
      .catch(
        /* istanbul ignore next */ (err) => {
          setIsErr(true);
          setIsSubmitting(false);
        },
      );
  };

  const onCancel = /* istanbul ignore next */ () => goBack();

  return (
    <form onSubmit={onSave} method="PATCH">
      <Flex flow="column" gap="sm" p2 css={{ width: '190px' }}>
        <TextField
          compact
          value={newName}
          state={/* istanbul ignore next */ isErr ? 'error' : 'default'}
          maxLength={50}
          hint={
            /* istanbul ignore next */ isErr
              ? { label: 'Tag name already in use.', variant: 'error' }
              : {}
          }
          onChange={(e) => {
            setNewName(e.target.value);
            // Clear the error when the user starts typing again
            /* istanbul ignore next */
            if (isErr) {
              setIsErr(false);
            }
          }}
          onKeyDown={
            /* istanbul ignore next */ (e) => {
              if (e.key === ',') {
                e.preventDefault();
              }
            }
          }
          data-testid={`edit-tag-input-${id}`}
        />
        <Flex justifyContent="end" gap="xs">
          <Button compact variant="subtle" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            compact
            variant="primary"
            disabled={isSubmitting || !newName || newName === name}
            onClick={onSave}
            type="submit"
            data-testid={`edit-tag-save-${id}`}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

const DeleteSlide = (props: {
  name: string;
  id: string;
  computerCount: number;
  goBack: () => void;
  closeDropdown: () => void;
  refetchTags: () => void;
}) => {
  const { name, id, computerCount, goBack, closeDropdown, refetchTags } = props;
  const { deleteTag } = useTags();

  const onDelete = () => {
    deleteTag(id).then(() => {
      refetchTags();
    });
    closeDropdown();
  };

  const onCancel = () => goBack();

  const devicesText =
    computerCount === 1 ? (
      <Text>
        This will remove the tag from{' '}
        <span style={{ fontWeight: '500' }}>1 device</span> that is currently
        using it.
      </Text>
    ) : (
      <Text>
        This will remove the tag from all{' '}
        <span style={{ fontWeight: '500' }}>{computerCount} devices</span> that
        are currently using it.
      </Text>
    );

  return (
    <Flex flow="column" gap="md" css={{ width: '230px', padding: '6px 12px' }}>
      <Text css={{ fontWeight: '$medium' }}>
        Are you sure you want to delete this tag?
      </Text>
      {computerCount > 0 && devicesText}
      <Flex justifyContent="space-between" gap="xs">
        <Button compact variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          compact
          variant="danger"
          onClick={onDelete}
          data-testid={`delete-tag-submit-${id}`}
        >
          Yes, delete tag
        </Button>
      </Flex>
    </Flex>
  );
};

const StyledDropdownMenuContent = styled(DropdownMenuPrimitives.Content, {
  zIndex: 999,
});

const TagActions = (props: {
  name: string;
  id: string;
  computerCount: number;
  isUsedInRule: boolean;
  isUserAuditor: boolean;
  refetchTags: () => void;
}) => {
  const { name, id, computerCount, isUsedInRule, isUserAuditor, refetchTags } =
    props;

  const [isOpen, setIsOpen] = useState(false);
  const [slide, setSlide] = useState<'menu' | 'edit' | 'delete'>('menu');

  const options: ExtendedDropdownMenuOption[] = [
    {
      label: 'Edit name',
      icon: 'pen',
      onClick: () => !isUserAuditor && setSlide('edit'),
      disabled: isUserAuditor,
    },
    {
      label: 'Delete tag',
      icon: 'trash-can',
      theme: isUsedInRule ? 'default' : 'danger',
      disabled: isUserAuditor || isUsedInRule,
      disabledTooltipContent: isUsedInRule
        ? 'Tags cannot be deleted while they are used in rules. View the info on this tag to see where it is used.'
        : null,
      onClick: () =>
        !isUsedInRule && !isUserAuditor ? setSlide('delete') : {},
    },
  ];

  const changeParentBackgroundColor = (cssSelector: string, color: string) => {
    document.querySelectorAll(cssSelector).forEach((button) => {
      let parent = button.parentElement;
      /* istanbul ignore next */
      while (parent) {
        if (parent.getAttribute('role') === 'option') {
          parent.style.backgroundColor = color;
          break;
        }
        parent = parent.parentElement;
      }
    });
  };

  // Reset the slide when the dropdown closes
  useEffect(() => {
    if (isOpen) {
      setSlide('menu');
    }

    changeParentBackgroundColor(
      'button[data-state="open"]',
      'var(--colors-button_subtle_background_hover)',
    );
    changeParentBackgroundColor('button[data-state="closed"]', '');
  }, [isOpen]);

  return (
    <DropdownMenuPrimitives.Root
      open={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
    >
      <DropdownMenuPrimitives.Trigger>
        <Button
          compact
          title="dropdown-actions"
          variant="default"
          icon={{ name: 'ellipsis' }}
          css={{
            width: 'var(--sizes-6)',
            height: 'var(--sizes-6)',
            padding: 'var(--space-1)',
            backgroundColor: isOpen ? '#C0C5CC' : 'transparent',

            '&:hover': {
              backgroundColor: '#C0C5CC',
            },
          }}
          onClick={
            /* istanbul ignore next */ (e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }
          }
          data-testid={`tag-actions-${id}`}
        />
      </DropdownMenuPrimitives.Trigger>

      <DropdownMenuPrimitives.Portal>
        <StyledDropdownMenuContent
          align="start"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {slide === 'menu' &&
            options.map((option) => {
              const item = (
                <DropdownMenuPrimitives.Item
                  key={option.label}
                  onClick={(e) => {
                    e.preventDefault();
                    option.onClick(e);
                  }}
                  disabled={option.disabled}
                  theme={option.theme || 'default'}
                  css={{
                    cursor: 'pointer',
                    gap: '$1',
                  }}
                >
                  <Icon name={option.icon} size="sm" />
                  {option.label}
                </DropdownMenuPrimitives.Item>
              );

              if (option.disabled && option.disabledTooltipContent) {
                return (
                  <Tooltip
                    content={option.disabledTooltipContent}
                    side="left"
                    css={{ zIndex: 999 }}
                  >
                    {item}
                  </Tooltip>
                );
              }
              return item;
            })}

          {slide === 'edit' && (
            <EditSlide
              name={name}
              id={id}
              goBack={/* istanbul ignore next */ () => setSlide('menu')}
              closeDropdown={() => setIsOpen(false)}
              refetchTags={refetchTags}
            />
          )}

          {slide === 'delete' && (
            <DeleteSlide
              name={name}
              id={id}
              computerCount={computerCount}
              goBack={/* istanbul ignore next */ () => setSlide('menu')}
              closeDropdown={() => setIsOpen(false)}
              refetchTags={refetchTags}
            />
          )}
        </StyledDropdownMenuContent>
      </DropdownMenuPrimitives.Portal>
    </DropdownMenuPrimitives.Root>
  );
};

export default TagActions;
