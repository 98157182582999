export const ACTIVATION_LOCK_OPTION_ITEM = {
  ALLOW: {
    value: 'allow',
    label: 'Allow user-based Activation Lock',
  },
  DISALLOW: {
    value: 'disallow',
    label: 'Disallow user-based Activation Lock',
  },
};

export const ACTIVATION_LOCK_OPTIONS = [
  ACTIVATION_LOCK_OPTION_ITEM.ALLOW,
  ACTIVATION_LOCK_OPTION_ITEM.DISALLOW,
];
