import { oneOf, shape, string } from 'prop-types';

const INT_TYPES = {
  msTeams: 'ms_teams',
  slack: 'slack',

  scim: 'scim',
  office365: 'office365',
  gsuite: 'gsuite',

  adcs: 'adcs',
  okta: 'okta_device_trust',

  servicenow: 'servicenow',
  msDeviceCompliance: 'ms_compliance',
} as const;

const INT_CATEGORIES = {
  assetManagement: 'Asset Management',
  communication: 'Communication',
  user: 'Directory integrations',
  security: 'Security',
} as const;

const USER_INT_TYPES = [
  INT_TYPES.office365,
  INT_TYPES.gsuite,
  INT_TYPES.scim,
] as const;

const INT_TYPE_CATEGORY_MAPPING = {
  [INT_CATEGORIES.communication]: [INT_TYPES.slack, INT_TYPES.msTeams],
  [INT_CATEGORIES.user]: USER_INT_TYPES,
  [INT_CATEGORIES.security]: [
    INT_TYPES.adcs,
    INT_TYPES.okta,
    INT_TYPES.msDeviceCompliance,
  ],
  [INT_CATEGORIES.assetManagement]: [INT_TYPES.servicenow],
} as const;

const FILTERS_SHAPE = shape({
  search: string.isRequired,
  category: oneOf([...Object.values(INT_CATEGORIES)]),
});

const ALL_INTEGRATIONS_FILTERS = 'All integrations';

const TYPE_PROP_TYPE = oneOf([...Object.values(INT_TYPES)]);

const USER_INT_ERRORS = {
  emailExistError: 'emailExistError',
  authError: 'authError',
} as const;

export {
  INT_TYPES,
  INT_TYPE_CATEGORY_MAPPING,
  INT_CATEGORIES,
  FILTERS_SHAPE,
  TYPE_PROP_TYPE,
  ALL_INTEGRATIONS_FILTERS,
  USER_INT_ERRORS,
  USER_INT_TYPES,
};
