import {
  Banner,
  Button,
  Checkbox,
  Chip,
  Flex,
  Select,
  TextInput,
  Toggle,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { Flex as NectarFlex, Text, styled } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';
import { MultiText } from 'features/util/components/multi-text';
import SingleSignOnExtensionService from '../service/single-sign-on-extension.service';
import UserGroup from './user-group';

const UserGroupsContainer = styled(NectarFlex, {
  gap: '24px', // @todo: use stitches variable for $2 space
});

const authModes = [
  {
    label: 'Standard',
    value: SingleSignOnExtensionService.AuthorizationMode.Standard,
  },
  {
    label: 'Admin',
    value: SingleSignOnExtensionService.AuthorizationMode.Admin,
  },
  {
    label: 'Groups',
    value: SingleSignOnExtensionService.AuthorizationMode.Groups,
  },
];

const authMethods = [
  {
    label: 'Password',
    value: SingleSignOnExtensionService.AuthorizationMethod.Password,
  },
  {
    label: 'User Secure Enclave Key',
    value:
      SingleSignOnExtensionService.AuthorizationMethod.UserSecureEnclaveKey,
  },
  {
    label: 'Smart Card',
    value: SingleSignOnExtensionService.AuthorizationMethod.SmartCard,
  },
];

const PlatformSSO = (props) => {
  const { setting, update, validationDep, isDisabled } = props;

  const { refs, onInvalidate } = useInputsValidators(
    ['AccountDisplayName', 'LoginFrequency', 'AccountName', 'FullName'],
    update,
  );

  const addUserGroup = () => {
    update('AuthorizationGroups', (p) => [
      ...p,
      SingleSignOnExtensionService.getEmptyUserGroup(),
    ]);
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Platform SSO (macOS 14+)</h3>
        <div className="b-flex">
          <Chip
            disabled={isDisabled}
            className="b-mr-tiny"
            text={setting.PlatformSSOActive ? 'Active' : 'Inactive'}
            kind={setting.PlatformSSOActive ? 'active' : 'primary'}
          />
          <Toggle
            disabled={isDisabled}
            checked={setting.PlatformSSOActive}
            onToggle={() => update('PlatformSSOActive', (p) => !p)}
          />
        </div>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Platform SSO requires a compatible Identity Provider app extension to
          be present on the device, which may also require vendor-specific
          configurations. It is supported on macOS 14 Sonoma or later.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000559822-single-sign-on-extension"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more
          </a>
        </p>
      </Setting.SubHeader>
      {setting.PlatformSSOActive && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Authentication method
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Specify the type of sign-on authorization to use for Platform
                SSO.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={authMethods}
                onChange={({ value }) => update('AuthenticationMethod', value)}
                value={authMethods.find(
                  ({ value }) => value === setting.AuthenticationMethod,
                )}
                disabled={isDisabled}
                compact
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Existing user permissions
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Set the permissions to apply to an account each time the user
                authenticates.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={authModes}
                onChange={({ value }) => update('UserAuthorizationMode', value)}
                value={authModes.find(
                  ({ value }) => value === setting.UserAuthorizationMode,
                )}
                disabled={isDisabled}
                compact
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              New user permissions
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Set the permissions to apply for newly created accounts at
                login.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={authModes}
                onChange={({ value }) =>
                  update('NewUserAuthorizationMode', value)
                }
                value={authModes.find(
                  ({ value }) => value === setting.NewUserAuthorizationMode,
                )}
                disabled={isDisabled}
                compact
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                checked={setting.UseSharedDeviceKeys}
                onChange={() => update('UseSharedDeviceKeys', (p) => !p)}
                label="Use shared device keys"
              />
            </Setting.Controls>
            <Setting.Helpers className="b-txt-light" style={{ gridArea: 1 }}>
              <div>
                <p>
                  Specify if the system uses the same signing and encryption
                  keys for all users.
                </p>
                <Banner theme="info">
                  <span>
                    Required to allow authorization and new account creation.
                  </span>
                </Banner>
              </div>
            </Setting.Helpers>
            {setting.UseSharedDeviceKeys && (
              <Setting.SecondaryControls>
                <Setting.SecondaryControlsRow>
                  <div>
                    <div>
                      <Checkbox
                        checked={setting.EnableAuthorization}
                        onChange={() =>
                          update('EnableAuthorization', (p) => !p)
                        }
                        label="Allow authorization (with identity provider account)"
                      />
                    </div>
                    <div>
                      <Checkbox
                        checked={setting.EnableCreateUserAtLogin}
                        onChange={() =>
                          update('EnableCreateUserAtLogin', (p) => !p)
                        }
                        label="Allow creation of new users at login window"
                      />
                    </div>
                  </div>
                </Setting.SecondaryControlsRow>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Account display name
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Enter the account name to be displayed in notifications and
                authentication requests.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs.AccountDisplayName}>
                <TextInput
                  compact
                  disabled={isDisabled}
                  value={setting.AccountDisplayName}
                  placeholder="Accuhive Platform SSO"
                  onChange={(e) => update('AccountDisplayName', e.target.value)}
                  validator={(v) => [
                    {
                      trigger: ['onBlur', validationDep],
                      message: 'Required',
                      invalid: () => !v,
                    },
                  ]}
                  onInvalidate={onInvalidate('AccountDisplayName')}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Require full login after
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Specify when the system requires a full login instead of a
                refresh.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs.LoginFrequency}>
                <Flex style={{ gap: '8px' }} align="center">
                  <TextInput
                    compact
                    type="number"
                    disabled={isDisabled}
                    value={setting.LoginFrequency}
                    placeholder="3600"
                    onChange={(e) => update('LoginFrequency', e.target.value)}
                    validator={(v) => [
                      {
                        trigger: ['onBlur', validationDep],
                        message: 'Required',
                        invalid: () => !v,
                      },
                      {
                        message: 'Must be at least 3600 seconds',
                        invalid: () => parseFloat(v) < 3600,
                        trigger: ['onBlur', validationDep],
                      },
                    ]}
                    onInvalidate={onInvalidate('LoginFrequency')}
                  />
                  <Text>seconds</Text>
                </Flex>
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">Token mapping</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                The attribute mapping to use when creating new users or for
                authorization.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Flex className="b-flex-vc b-flex-gtiny" style={{ gap: '8px' }}>
                <div ref={refs.AccountName}>
                  <TextInput
                    superCompact
                    disabled={isDisabled}
                    value={setting.AccountName}
                    placeholder="AccountName"
                    onChange={(e) => update('AccountName', e.target.value)}
                    validator={(v) => [
                      {
                        trigger: ['onBlur', validationDep],
                        message: 'Required',
                        invalid: () => !v,
                      },
                    ]}
                    onInvalidate={onInvalidate('AccountName')}
                  />
                </div>
                <div ref={refs.FullName}>
                  <TextInput
                    superCompact
                    disabled={isDisabled}
                    value={setting.FullName}
                    placeholder="FullName"
                    onChange={(e) => update('FullName', e.target.value)}
                    validator={(v) => [
                      {
                        trigger: ['onBlur', validationDep],
                        message: 'Required',
                        invalid: () => !v,
                      },
                    ]}
                    onInvalidate={onInvalidate('FullName')}
                  />
                </div>
              </Flex>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">Admin groups</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                The list of groups to use for administrator access. macOS
                requests membership during authentication.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <MultiText
                name="AdministratorGroups"
                values={setting.AdministratorGroups}
                placeholder="Type admin group and press enter..."
                onChange={(v) => update('AdministratorGroups', v.values)}
                disabled={isDisabled}
                hint="Press enter to add another group"
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">Additional groups</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Groups specified here will be created as local group if they
                don&lsquo;t exist, and will be available in the local directory
                service.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <MultiText
                name="AdditionalGroups"
                placeholder="Type additional group and press enter..."
                values={setting.AdditionalGroups}
                onChange={(v) => update('AdditionalGroups', v.values)}
                disabled={isDisabled}
                hint="Press enter to add another group"
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">User groups</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Groups specified here will be created as local group if they
                don&lsquo;t exist, and will be available in the local directory
                service.
              </p>
              <Banner theme="info">
                <span>
                  Each right can only be specified once and mapped to a single
                  group.
                </span>
              </Banner>
            </Setting.Helpers>
            <Setting.Controls>
              <UserGroupsContainer className="b-mb2 b-flex-vgtiny">
                {setting.AuthorizationGroups.map((ag) => (
                  <UserGroup
                    key={ag.id}
                    isDisabled={isDisabled}
                    setting={ag}
                    allUserGroups={setting.AuthorizationGroups}
                    validationDep={validationDep}
                    updateValidation={update}
                    update={(field, val) =>
                      update('AuthorizationGroups', (p) => {
                        const itemIdx = p.findIndex(
                          (authGroup) => authGroup.id === ag.id,
                        );

                        return [
                          ...p.slice(0, itemIdx),
                          {
                            ...p[itemIdx],
                            [field]: val,
                          },
                          ...p.slice(itemIdx + 1),
                        ];
                      })
                    }
                    canDelete
                    onDelete={() =>
                      update('AuthorizationGroups', (p) => {
                        const itemIdx = p.findIndex(
                          (authGroup) => authGroup.id === ag.id,
                        );

                        return [
                          ...p.slice(0, itemIdx),
                          ...p.slice(itemIdx + 1),
                        ];
                      })
                    }
                  />
                ))}
              </UserGroupsContainer>
              <Button
                kind="link"
                icon="circle-plus"
                onClick={addUserGroup}
                isDisabled={isDisabled}
              >
                Add group
              </Button>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default PlatformSSO;
