import {
  Banner,
  Checkbox,
  Label,
  Radio,
  RadioGroup,
  Select,
} from '@kandji-inc/nectar-ui';
import React, { memo } from 'react';
import { Setting } from 'src/features/library-items/template';
import type { VolumeMountsProps } from '../../devicecontrol.types';
import {
  AppliesToUsersValue,
  UserAccessValue,
} from '../../devicecontrol.types';
import DeviceControlService from '../../service/device-control-service';

const VolumeMounts = (props: VolumeMountsProps) => {
  const { update, isDisabled, settings } = props;

  const isUserAccessReadWrite =
    settings.userAccess === UserAccessValue.READ_WRITE;
  const isUserAccessReadOnly =
    settings.userAccess === UserAccessValue.READ_ONLY;
  const isUserAccessBlock = settings.userAccess === UserAccessValue.BLOCK;
  const appliesToStandardUsers =
    settings.appliesTo === AppliesToUsersValue.STANDARD;
  const appliesToAllUsers = settings.appliesTo === AppliesToUsersValue.ALL;
  const isRequiredAdminPassword = Boolean(settings.requireAdminPassword);

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">External volumes</h3>
        <div data-testid="isManaged">
          <Setting.HeaderToggle
            isEnabled={settings.isManaged}
            onChange={(v) => update('isManaged', !settings.isManaged)}
            isDisabled={isDisabled}
            chipText={{
              enabled: 'Managed',
              disabled: 'Not managed',
            }}
          />
        </div>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure access privileges for external volume mounts.
        </p>
        {!settings.isManaged && (
          <Banner
            className="mt-2"
            text="Not managed: All users will be able to mount external volumes."
          />
        )}
      </Setting.SubHeader>
      {settings.isManaged && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Title>
              <Label above>Access privileges</Label>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Specify the access privileges for external volume mounts.
              </p>
              {isUserAccessReadWrite && (
                <Banner text="Full access to external volume mounts (normal macOS behavior). To further restrict external volume access, set the access privileges to Read only or No access." />
              )}
              {isUserAccessReadOnly && (
                <Banner text="Read only access to external volume mounts. To further restrict external volume access, set the access privileges to No access." />
              )}
              {isUserAccessBlock && (
                <Banner text="External volume mounts will be blocked." />
              )}
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={DeviceControlService.userAccessSelectOptions}
                value={settings.userAccess}
                onChange={(v) => update('userAccess', v)}
                disabled={isDisabled}
                testId="userAccess"
              />
            </Setting.Controls>
            {!isUserAccessBlock && (
              <>
                <Setting.SecondaryControls className="k-dc-config-row__secondary">
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      <div data-testid="requireEncryption">
                        <Checkbox
                          label="Require encryption"
                          checked={settings.requireEncryption}
                          onCheckedChange={(v) =>
                            update('requireEncryption', v)
                          }
                          disabled={isDisabled}
                        />
                      </div>
                    </Setting.Controls>
                    <Setting.Helpers>
                      <p className="b-txt-light">
                        Only encrypted external volumes will be allowed to
                        mount. All non-encrypted volumes will be blocked.
                      </p>
                    </Setting.Helpers>
                  </Setting.SecondaryControlsRow>
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      <div data-testid="requireAdminPassword">
                        <Checkbox
                          label="Require admin password to access"
                          checked={settings.requireAdminPassword}
                          onCheckedChange={(v) =>
                            update('requireAdminPassword', v)
                          }
                          disabled={isDisabled}
                        />
                      </div>
                    </Setting.Controls>
                    <Setting.Helpers>
                      <p className="b-txt-light">
                        When an external volume is mounted, prompt users for an
                        admin password to access content.
                      </p>
                    </Setting.Helpers>
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              </>
            )}
          </Setting.Row>
          <Setting.Row>
            <Setting.Title>
              <Label above>Access privileges applies to</Label>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Specify whether the access privileges will apply to all users or
                only to standard users.
              </p>
              {appliesToAllUsers && (
                <Banner text="All user accounts (admin and standard) in macOS." />
              )}
              {appliesToStandardUsers && (
                <Banner text="All standard users in macOS." />
              )}
            </Setting.Helpers>
            <Setting.Controls>
              <div data-testid="appliesTo">
                <RadioGroup
                  value={settings.appliesTo}
                  onValueChange={(v) =>
                    update('appliesTo', v as AppliesToUsersValue)
                  }
                  orientation="vertical"
                >
                  {DeviceControlService.appliesToUsersRadioOptions.map(
                    (item) => (
                      <Radio
                        key={`appliesTo-${item.value}`}
                        disabled={isDisabled}
                        {...item}
                      />
                    ),
                  )}
                </RadioGroup>
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title>
              <Label above>Device alerts</Label>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Display an alert message to users when the mounting of an
                external volume is blocked.
              </p>
              {isRequiredAdminPassword && !isUserAccessBlock && (
                <Banner text="Device alerts cannot be managed when “Require admin password to access” is selected." />
              )}
            </Setting.Helpers>
            <Setting.Controls>
              <div data-testid="deviceAlerts">
                <Checkbox
                  label="Display alert messages"
                  checked={settings.deviceAlerts}
                  onCheckedChange={(v) => update('deviceAlerts', v)}
                  disabled={
                    isDisabled ||
                    (isRequiredAdminPassword && !isUserAccessBlock)
                  }
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default memo(VolumeMounts);
