import React from 'react';

import { Button, Flex } from '@kandji-inc/bumblebee';

import {
  IPCard,
  IPCardSection,
  IPCardTitle,
} from '../generic/building-blocks/card-parts';

import { usePanel } from './hooks';

import Menu from './menu';

import CONFIG_SHAPE from './util';

const Summary = ({ config }) => {
  const { icons, title, description } = config;

  const { openPanel } = usePanel();

  /* istanbul ignore next */
  const openPanelDeleteIntegration = () => {
    openPanel('delete-integration');
  };

  const menu = (
    <Button
      theme="error"
      kind="link"
      icon="trash-can"
      onClick={openPanelDeleteIntegration}
    >
      Delete integration
    </Button>
  );

  return (
    <IPCard>
      <IPCardTitle className="int-overview-card_title">
        <Flex align="center">
          <img
            className="summary-card_icon"
            src={icons.summary}
            alt="int-icon"
          />
          <h1 className="b-h1">{title}</h1>
        </Flex>
        <Menu menu={menu} />
      </IPCardTitle>
      <IPCardSection>
        <p className="summary-card_text">
          {description}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000569107-active-directory-certificate-services-ad-cs-integration-setup-and-configure"
            className="b-alink"
            target="_blank"
            rel="noreferrer"
          >
            Learn more...
          </a>
        </p>
      </IPCardSection>
    </IPCard>
  );
};

Summary.propTypes = {
  config: CONFIG_SHAPE.isRequired,
};

export default Summary;
