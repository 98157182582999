import { Button } from '@kandji-inc/bumblebee';
import { fetchCompanyState } from 'app/_actions/account';
import { InterfaceContext } from 'contexts/interface';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useOldField } from 'src/old-honey-form';
import styled from 'styled-components';
import { FieldText, Form } from 'theme/components/molecules/Form';
import { setModal as callSetModal } from '../../../_actions/ui';
import history from '../../../router/history';
import { moment } from '../../common/helpers';
import { LineLoader } from '../../interface/LineLoader';
import TokenExpirationIndicator from '../../interface/indicators/TokenExpirationIndicator';
import Block from '../elements/Block';
import Description from '../elements/Description';
import Title from '../elements/Title';
import DepIntegration from './DepIntegration';
import VPPIntegration from './VPPIntegration';
import { deletePushCert, getIntegration } from './api';

import Modal from '../../../../theme/components/atoms/Modal';

const WhiteCard = styled('div')`
  background: white;
  border-radius: 4px;
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  bottom: ${(props) => (props.sticky ? '0' : 'after')};
  z-index: ${(props) => (props.sticky ? 2 : 'auto')};
  padding: 15px 30px ${(props) => (props.button ? '60px' : '26px')};
  margin-bottom: 20px;
`;

const LoginWrapper = styled(Block.GreyBlock)`
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  white-space: nowrap;
  padding: 31px 30px;
  > * {
    margin-right: 20px;
  }
`;

const CertInfoBlock = styled('div')`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 20px;
  grid-template-areas: 'title value';
`;

const CertInfoTitleWrapper = styled('div')`
  grid-area: title;
  display: flex;
  flex-direction: column;
`;

const CertInfoTitle = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 25px;

  color: #1a1d25;
`;

const CertInfoValueWrapper = styled(CertInfoTitleWrapper)`
  grid-area: value;
`;

const CertInfoValue = styled(CertInfoTitle)`
  font-weight: 500;
`;

const MdmIntegrationDisconnect = ({ integrationData, fetchPushCert }) => {
  const { onModalOpen, onModalClose } = useContext(InterfaceContext);

  const [isWorking, setIsWorking] = useState(false);

  const delete_confirm = useOldField('delete_confirm');

  const delete_confirm_value = delete_confirm.getValue();

  const valueToConfirm = get(integrationData, 'serial', 'Not Available');

  const modalDisconnect = 'modal-disconnect';

  return (
    <>
      <Button
        style={{ marginLeft: 'auto' }}
        kind="link"
        theme="dark"
        onClick={() => onModalOpen(modalDisconnect)}
      >
        Disconnect APNs
      </Button>
      <Modal name={modalDisconnect}>
        <div className="paper">
          <header className="modal-division division with-cross">
            <div className="node">
              <h3 className="modal-title">
                Are you sure you want to disconnect your APNs certificate?
              </h3>
            </div>
            <div className="node">
              <Button
                icon="xmark"
                kind="link"
                theme="dark"
                onClick={() => {
                  onModalClose();
                  delete_confirm.setValue('');
                }}
              />
            </div>
          </header>
          <section className="modal-division division">
            <div className="node">
              <p>
                Please only disconnect your Apple Push Notification service
                certificate if you need to replace it with a new certificate.
              </p>
              <p>
                Replacing your APNs certificate with a new certificate will
                break MDM communication with your enrolled Apple devices. You
                will need to re-enroll all devices under the new APNs
                certificate.
              </p>
              <p>
                Disconnecting your APNs certificate will delete the certificate
                from Kandji, the certificate will not be revoked with Apple.
              </p>
              <p>
                Please contact support if you are unsure about performing this
                action.
              </p>
              <FieldText
                name="delete_confirm"
                required
                label={
                  <>
                    Please type{' '}
                    <strong className="modal-strong">{valueToConfirm}</strong>{' '}
                    to confirm.
                  </>
                }
              />
            </div>
          </section>
          <footer className="modal-division division divide">
            <div className="node d-flex justify-content-end">
              <Button
                kind="link"
                theme="dark"
                disabled={isWorking}
                onClick={() => {
                  onModalClose();
                  delete_confirm.setValue('');
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isWorking || delete_confirm_value !== valueToConfirm}
                theme="error"
                onClick={async () => {
                  setIsWorking(true);
                  await deletePushCert();
                  await onModalClose();
                  await fetchPushCert();
                  delete_confirm.setValue('');
                  setIsWorking(false);
                }}
                isProgress={isWorking}
                style={{ marginLeft: '20px' }}
              >
                Delete
              </Button>
            </div>
          </footer>
        </div>
      </Modal>
    </>
  );
};

class MdmIntegration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      integrationData: {},
    };
    this.fetchPushCert = this.fetchPushCert.bind(this);
  }

  componentDidMount() {
    const { setMdmLoaded } = this.props;
    const promises = [this.fetchPushCert()];
    Promise.all(promises).finally(() => {
      this.setState({ isLoading: false });
      setMdmLoaded();
    });
  }

  fetchPushCert() {
    this.setState({ isLoading: true });
    return getIntegration()
      .then((integrationData) => {
        const integrationDataForState =
          integrationData.days_left === null ? {} : integrationData;
        this.setState({ integrationData: integrationDataForState });
      })
      .catch(() => this.setState({ integrationData: {} }))
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const { setModal, setDepLoaded } = this.props;
    const { isLoading, integrationData } = this.state;
    if (isLoading) {
      /* istanbul ignore next */
      return <LineLoader isDelayed />;
    }
    return (
      <Form>
        <Block>
          <Title>Apple Integrations</Title>
          <LoginWrapper>
            <div className="d-flex flex-column w-100">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: 10 }}
              >
                <Title.SubTitle>
                  Apple Push Notification service (APNs)
                </Title.SubTitle>
                {!isEmpty(integrationData) && (
                  <TokenExpirationIndicator
                    daysLeft={integrationData.days_left}
                  />
                )}
                <Button
                  className="pendo-configure-apns"
                  style={{ marginLeft: 'auto' }}
                  kind={!isEmpty(integrationData) ? 'outline' : 'fill'}
                  theme="dark"
                  onClick={() =>
                    history.push(
                      `/my-company/integrations/${
                        isEmpty(integrationData) ? 'new' : 'renew'
                      }`,
                    )
                  }
                >
                  {!isEmpty(integrationData)
                    ? 'Renew certificate'
                    : 'Configure APNs'}
                </Button>
              </div>
              <Description.SubDescription
                style={{ whiteSpace: 'normal', marginBottom: 20 }}
              >
                APNs is a requirement for enrolling your Apple devices into
                Kandji and fully managing them.
              </Description.SubDescription>
              {!isEmpty(integrationData) && (
                <>
                  <WhiteCard>
                    <CertInfoBlock>
                      <CertInfoTitleWrapper>
                        <CertInfoTitle>Apple Account:</CertInfoTitle>
                        <CertInfoTitle>Common Name (CN):</CertInfoTitle>
                        <CertInfoTitle>Serial Number:</CertInfoTitle>
                        <CertInfoTitle>Issued by:</CertInfoTitle>
                        <CertInfoTitle>Renew by:</CertInfoTitle>
                      </CertInfoTitleWrapper>
                      <CertInfoValueWrapper>
                        <CertInfoValue>
                          {get(integrationData, 'apple_id', 'Not Available')}
                        </CertInfoValue>
                        <CertInfoValue>
                          {get(integrationData, 'cn', 'Not Available')}
                        </CertInfoValue>
                        <CertInfoValue>
                          {get(integrationData, 'serial', 'Not Available')}
                        </CertInfoValue>
                        <CertInfoValue>
                          {get(integrationData, 'issuer', 'Not Available')}
                        </CertInfoValue>
                        <CertInfoValue>
                          {`${moment(get(integrationData, 'expire_at')).format(
                            'LL',
                          )}
                      - ${moment(get(integrationData, 'expire_at')).fromNow()}`}
                        </CertInfoValue>
                      </CertInfoValueWrapper>
                    </CertInfoBlock>
                  </WhiteCard>
                  <MdmIntegrationDisconnect
                    integrationData={integrationData}
                    fetchPushCert={this.fetchPushCert}
                  />
                </>
              )}
            </div>
          </LoginWrapper>
          <DepIntegration setDepLoaded={setDepLoaded} />
          <div id="apps-and-books">
            <VPPIntegration />
          </div>
        </Block>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCompany: state.company,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal: callSetModal,
      fetchCompanyState,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MdmIntegration);
