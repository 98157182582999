import React, { useState } from 'react';
import {
  CardContainer,
  CardSection,
  CardTitle,
} from 'src/features/integrations/components/styled-components';

import { Button } from '@kandji-inc/bumblebee';
import styled from 'styled-components';

import ConfigurationModal from 'src/features/integrations/components/configuration-modal';
import type { DeviceType, ODTIntegration, OktaLibraryItem } from '../../types';
import macosIcon from '../assets/device-platforms.svg';
import iosDevicesIcon from '../assets/ios-devices.svg';
import { StyledImg as Img } from '../styled-components';
import IosSettings from './ios-settings';
import MacOsSettings from './macos-settings';
import { getModalContent } from './utils/get-modal-content';
import type { DeviceModalType } from './utils/get-modal-content';

type Props = {
  data?: ODTIntegration;
  oktaLibraryItemData: OktaLibraryItem | undefined;
};

type StyledOktaConfigModal = {
  isDeleteModal: boolean;
};

const Content = styled.div`
  height: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 72px 1fr;
  & > ${Content} {
    grid-column-start: 2;
    margin-left: var(--b-gap);
  }
`;

const StyledImg = styled(Img)`
  align-self: start;
  margin-right: var(--b-gap);
  color: var(--color-neutral-70);
`;

const StyledAddDeviceButton = styled(Button)`
  margin-left: auto;
`;

const StyledOktaConfigModal = styled(ConfigurationModal)<StyledOktaConfigModal>`
  min-height: ${(props) => (props.isDeleteModal ? '0' : '640px')};
  width: ${(props) => (props.isDeleteModal ? '560px' : '960px')};
  display: grid;
  align-items: center;
  text-align: center;
  padding: 0;
`;

const DevicePlatforms = (props: Props) => {
  const { data, oktaLibraryItemData } = props;
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [devicePlatformModal, setDevicePlatformModal] = useState<JSX.Element>();

  const {
    mac_scep_challenge_username: username,
    mac_scep_challenge_url: challengeUrl,
    mac_scep_url: scepUrl,
    ios_configured: iOSConfigured,
  } = data ?? {};

  const hasMacOsDevicePlatform = !!challengeUrl;
  const hasIOSDevicePlatform = iOSConfigured;

  const hasAddDevicePlatformButton =
    !hasIOSDevicePlatform || !hasMacOsDevicePlatform;

  const hasDeleteMenuOption =
    (hasIOSDevicePlatform && hasMacOsDevicePlatform) || false;

  const resetDeleteModal = () => {
    if (isDeleteModal) {
      setIsDeleteModal(false);
    }
  };

  const handleModalOpen = () => {
    resetDeleteModal();
    setIsDeviceModalOpen(true);
    if (!hasMacOsDevicePlatform && data) {
      const macOsModalContent = getModalContent('macOS')({
        data,
        handleCloseModal,
      });
      setDevicePlatformModal(macOsModalContent);
    }
    if (!hasIOSDevicePlatform && data) {
      const iosModalContent = getModalContent('iOS')({
        data,
        handleCloseModal,
      });
      setDevicePlatformModal(iosModalContent);
    }
  };

  const handleOpenEditModal = (deviceToEdit: DeviceModalType) => {
    resetDeleteModal();
    if (data) {
      setIsDeviceModalOpen(true);
      const modal = getModalContent(deviceToEdit)({
        data,
        handleCloseModal,
        isEditing: true,
      });
      setDevicePlatformModal(modal);
    }
  };

  const handleCloseModal = () => {
    resetDeleteModal();
    setIsDeviceModalOpen(false);
  };

  const handleDeleteModal = (deviceType: DeviceType) => {
    setIsDeleteModal(true);
    setIsDeviceModalOpen(true);
    if (data) {
      const oktaDeviceTrustId = data.id;
      const modal = getModalContent('deleteDevice')({
        handleCloseModal,
        oktaDeviceTrustId,
        blueprints: oktaLibraryItemData?.blueprints,
        type: deviceType,
      });

      setDevicePlatformModal(modal);
    }
  };

  return (
    <>
      <CardContainer>
        <CardTitle>
          <h4 className="b-h4 b-mr">Device platforms</h4>
          {hasAddDevicePlatformButton && (
            <StyledAddDeviceButton
              kind="link"
              size="small"
              icon="circle-plus"
              onClick={handleModalOpen}
            >
              Add device platform
            </StyledAddDeviceButton>
          )}
        </CardTitle>

        {hasMacOsDevicePlatform && (
          <CardSection>
            <Container>
              <StyledImg src={macosIcon} alt="device-platform-icon" />
              <Content>
                <MacOsSettings
                  scepUrl={scepUrl}
                  username={username}
                  challengeUrl={challengeUrl}
                  onEdit={handleOpenEditModal}
                  onDelete={handleDeleteModal}
                  hasDeleteMenuOption={hasDeleteMenuOption}
                />
              </Content>
            </Container>
          </CardSection>
        )}

        {hasIOSDevicePlatform && (
          <CardSection>
            <Container>
              <StyledImg src={iosDevicesIcon} alt="ios-icon" />
              <Content>
                <IosSettings
                  onEdit={handleOpenEditModal}
                  onDelete={handleDeleteModal}
                  hasDeleteMenuOption={hasDeleteMenuOption}
                />
              </Content>
            </Container>
          </CardSection>
        )}
      </CardContainer>
      {data && devicePlatformModal && (
        <StyledOktaConfigModal
          isModalOpen={isDeviceModalOpen}
          isDeleteModal={isDeleteModal}
        >
          {devicePlatformModal}
        </StyledOktaConfigModal>
      )}
    </>
  );
};

export default DevicePlatforms;
