import React, { useEffect } from 'react';

import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import { Setting } from 'features/library-items/template';

import Qos from './components/qos';

const keys = WifiService.keys.fastlane;

const FastLaneCard = ({
  setting,
  update,
  isDisabled,
  isSubmitted,
  validationDep,
}) => {
  const showRows = !!setting[keys.isEnabled];
  useEffect(() => {
    // when disabled card remove validations
    // not the best way to do it
    if (!setting[keys.isEnabled] || !setting.invalidationsMap) {
      update('invalidationsMap', {});
    }
  }, [setting[keys.isEnabled], isSubmitted]);

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">Fast Lane marking</h3>
        <Setting.HeaderToggle
          isEnabled={setting[keys.isEnabled]}
          onChange={() => update(keys.isEnabled, (p) => !p)}
          isDisabled={isDisabled}
          chipText={{
            enabled: 'Managed',
            disabled: 'Not managed',
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure Cisco Fast Lane support. If a network supports Fast Lane and
          these settings are not configured, any app may use Layer 2 and Layer 3
          QoS marking.
        </p>
      </Setting.SubHeader>
      {showRows && (
        <Qos
          update={update}
          isDisabled={isDisabled}
          setting={setting}
          validationDep={validationDep}
        />
      )}
    </Setting.Card>
  );
};

export default React.memo(FastLaneCard);
