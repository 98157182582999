import type { MouseEvent, MouseEventHandler } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import {
  Button,
  Card,
  DropdownMenu,
  Flex,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { UserRoles } from 'src/app/common/constants';
import useAccount from 'src/contexts/account';

import { paths } from '../common';

export const FlowBlueprintListItem = (props: {
  id: string;
  name: string;
  description: string;
  computers_count: number;
  onEdit: () => void;
  onDelete: MouseEventHandler;
  onDuplicate: MouseEventHandler;
}) => {
  const {
    id,
    name,
    description,
    computers_count: computersCount,
    onEdit,
    onDelete,
    onDuplicate,
  } = props;
  const { userRole } = useAccount();
  const isUserHelpDesk = userRole === UserRoles.helpdesk;
  const isAuditor = userRole === UserRoles.auditor;

  const actions: DropdownMenuOption[] = [
    {
      label: 'Edit name and description',
      icon: 'pen',
      onClick: onEdit,
    },
    {
      label: 'Duplicate Blueprint',
      icon: 'copy',
      onClick: onDuplicate,
    },
    {
      label: 'Delete Blueprint',
      icon: 'trash-can',
      theme: 'danger',
      onClick: onDelete,
    },
  ];

  const truncateTextStyles = {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
    overflow: 'hidden',
  };

  return (
    <Link
      to={paths.flowBlueprint(id)}
      className="bl-flow-blueprint-list__item decorate-off"
    >
      <Card hoverVariant="elevate-2" css={{ maxHeight: '125px' }}>
        <Flex flow="column" gap="md" hFull justifyContent="space-between">
          <Flex gap="sm" alignItems="start" justifyContent="space-between">
            <Flex gap="sm" alignItems="start">
              <Flex
                justifyContent="center"
                alignItems="center"
                css={{
                  backgroundColor: '$blue10',
                  borderRadius: '$round',
                  padding: '6px',
                }}
              >
                <Icon
                  name="kandji-blueprint"
                  color="var(--colors-blue60)"
                  size="sm"
                />
              </Flex>
              <Heading
                size="3"
                css={{
                  ...truncateTextStyles,
                  fontWeight: '$medium !important',
                }}
              >
                {name}
              </Heading>
            </Flex>

            {!isUserHelpDesk && !isAuditor && (
              <Flex>
                <DropdownMenu options={actions}>
                  <Button
                    id="actions"
                    title="actions"
                    variant="subtle"
                    icon={{ name: 'ellipsis' }}
                    compact
                    onClick={
                      // istanbul ignore next - browser functions
                      (e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }
                  />
                </DropdownMenu>
              </Flex>
            )}
          </Flex>

          {description && (
            <Text css={{ ...truncateTextStyles, color: '$neutral70' }}>
              {description}
            </Text>
          )}

          <Flex>
            <Text size="1">{computersCount}</Text>
            <Text
              size="1"
              variant="description"
              css={{ marginLeft: '4px' }}
            >{`Device${computersCount === 1 ? '' : 's'}`}</Text>
          </Flex>
        </Flex>
      </Card>
    </Link>
  );
};
