import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';
import { randValuesOptions } from '../protocols-card.model';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const MinimumRandValues = ({
  refs,
  isDisabled,
  canShowRandValuesError,
  minimumRandValuesError,
  setting,
  update,
}) => (
  <Setting.SecondaryControlsRow>
    <div>
      <p className="b-txt">Minimum number of RAND values</p>
    </div>
    <div>
      <p className="b-txt-light">
        The minimum number of RAND values accepted from the server.
      </p>
    </div>
    <div ref={refs[keys.randValue]}>
      <Select
        isDisabled={isDisabled}
        options={randValuesOptions}
        compact
        onChange={(v) => update(keys.randValue, v.value)}
        value={randValuesOptions.find(
          (option) => option.value === setting[keys.randValue],
        )}
        errorText={(canShowRandValuesError && minimumRandValuesError) || ''}
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(MinimumRandValues);
