import { Checkbox } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';

const General = (props) => {
  const { setting, update, isDisabled } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">General</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.SleepDisabled}
              label="Disable sleep"
              onChange={() => update('SleepDisabled', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Prevents devices from going to sleep, and disables the Sleep
              option in the Apple menu. Note that this does not prevent displays
              from turning off.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.DestroyFVKeyOnStandby}
              label="Destroy FileVault key when entering standby"
              onChange={() => update('DestroyFVKeyOnStandby', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Prevents macOS from storing a temporary FileVault key in the SMC
              or in RAM when devices enter standby mode.
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default General;
