import { updateSetting as update } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useCallback, useEffect } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import {
  InstallationCard,
  useSsInstall,
} from 'features/library-items/template';

import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithoutDefaultsPreset from '../../common/self-service-card/drawer/presets/without-defaults-preset';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import profileApps from '../../library/library-item-configurations/items/profile';
import ActivityTab from './activity-tab';
import CustomProfileIcon from './cprofile-library.png';
import initialState from './initial-state';
import InstSecondaryContol from './installation-card';
import ProfileDetailsCard from './profile-details-card';
import CustomProfileService, {
  customProfileService,
} from './service/custom-profile-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useCustomProfileService from './service/use-custom-profile-service';

const installationOptions = [
  {
    value: CustomProfileService.installationTypes.CONTINUOUSLY_ENFORCE,
    label: 'Install and continuously enforce',
  },
  {
    value: CustomProfileService.installationTypes.NO_ENFORCEMENT,
    label: 'Install on-demand from Self Service',
  },
];

const CustomProfilePage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const config = profileApps['Custom Profile'];

  const updateInstallation = useCallback(update('installation', setModel), []);
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateDetails = useCallback(update('details', setModel), []);

  const isSsForced = useSsInstall({
    installType: model.installation.type,
    ssType: CustomProfileService.installationTypes.NO_ENFORCEMENT,
    updateSs,
  });

  useEffect(() => populateSelectedDevices(model, setModel, config), []);

  const secondaryControl = (
    <InstSecondaryContol
      setting={model.installation}
      update={updateInstallation}
      isDisabled={pageState.isDisabled}
    />
  );

  return (
    <LibraryItemPage
      {...props}
      type={config.type}
      identifier={config.identifier}
      summaryInfoProps={{
        name: config.name,
        description: config.description,
        devices: config.devices,
        publisher: config.publisher,
        requirements: config.osRequirements,
      }}
      askFlush={() => false}
      service={customProfileService}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      triggerValidation={triggerValidation}
      crumb={model.name || config.name}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={config.icon}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      {/* Self Service/Installation need to be hidden until Agent is able to handle Custom Profiles in self service.
       Comments here: https://kandji.atlassian.net/browse/LIT-420 */}
      {false && (
        <>
          <InstallationCard
            setting={model.installation}
            update={updateInstallation}
            isDisabled={pageState.isDisabled}
            options={installationOptions}
            secondaryControlsSlot={secondaryControl}
          />
          <SelfServiceCard
            setting={model.selfService}
            update={updateSs}
            isDisabled={pageState.isDisabled}
            defaults={{
              icon: model.iconSrc || CustomProfileIcon,
              name: model.name,
            }}
            DrawerContent={WithoutDefaultsPreset}
            canBeDisabled={!isSsForced}
            isSubmitted={pageState.isSubmitted}
          />
        </>
      )}
      <ProfileDetailsCard
        setting={model.details}
        update={updateDetails}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const CustomProfile = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useCustomProfileService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <CustomProfilePage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="custom-profile-v2"
    />
  );
};

export default CustomProfile;
