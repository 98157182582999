import * as React from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';

import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import config from '../../library/library-item-configurations/items/profile';
import initialState from './initial-state';
import { AppAccess } from './sections/AppAccess';
import { privacyService } from './service/privacy-service';
import { transformFromApi, transformToApi } from './service/transformers';
import usePrivacyService from './service/use-privacy-service';

import type {
  BlueprintConflicts,
  LibraryItemPageState,
} from '../../library-items.types';
import type { PrivacyModel, UpdatePrivacyAction } from './privacy.types';

interface PrivacyPageProps {
  model: PrivacyModel;
  setModel: React.Dispatch<React.SetStateAction<PrivacyModel>>;
  pageState: LibraryItemPageState;
  blueprintConflicts: BlueprintConflicts;
  testId: string;
}

const PrivacyPage = (props: Readonly<PrivacyPageProps>) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const itemConfig = config.Privacy;

  const update = React.useCallback(
    (updateAction: UpdatePrivacyAction) => {
      switch (updateAction.type) {
        case 'setting': {
          return setModel((prev) => updateAction.updater(prev));
        }

        case 'appAccess': {
          return setModel((prev) => {
            const appAccess = prev.appAccess.map(
              (appAccessSetting, appAccessIndex) =>
                appAccessIndex === updateAction.appAccessIndex
                  ? updateAction.updater(appAccessSetting)
                  : appAccessSetting,
            );
            return {
              ...prev,
              appAccess,
            };
          });
        }

        case 'appAccessProperty': {
          return setModel((prev) => {
            const appAccess = prev.appAccess.map(
              (appAccessSetting, appAccessIndex) =>
                appAccessIndex === updateAction.appAccessIndex
                  ? {
                      ...appAccessSetting,
                      Properties: appAccessSetting.Properties?.map(
                        (appAccessProperty, appAccessPropertyIndex) =>
                          appAccessPropertyIndex ===
                          updateAction.appAccessPropertyIndex
                            ? updateAction.updater(appAccessProperty)
                            : appAccessProperty,
                      ),
                    }
                  : appAccessSetting,
            );
            return {
              ...prev,
              appAccess,
            };
          });
        }

        default: {
          return undefined;
        }
      }
    },
    [setModel],
  );

  React.useEffect(() => {
    const invalids = Object.entries(model.invalidations.invalidationsMap)
      .map(([_key, value]) =>
        value.isInvalid === false ? undefined : value.ref.current,
      )
      .filter(Boolean);

    const orderedByElementPos = invalids.sort((aEl, bEl) => {
      const aRect = aEl?.getBoundingClientRect();
      const bRect = bEl?.getBoundingClientRect();
      return aRect && bRect ? aRect.top - bRect.top : 0;
    });

    const firstInvalid = orderedByElementPos[0];
    if (firstInvalid) {
      elementScrollIntoView(firstInvalid, {
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [validationDep]);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name}`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={privacyService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <AppAccess
        setting={model.appAccess}
        update={update}
        isDisabled={pageState.isDisabled}
        showError={pageState.isSubmitted}
      />
    </LibraryItemPage>
  );
};

const Privacy = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: usePrivacyService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <PrivacyPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="privacy-v2"
    />
  );
};

export default Privacy;
