/* istanbul ignore file */
import React, { useState } from 'react';

import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';

// export interface AddLibraryItemProps = {};

const AddLibraryItem = (/* props: AddLibraryItemProps */) => {
  // const {} = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Dialog
        isOpen={isModalOpen}
        onOpenChange={onCancel}
        title="Add Library Item"
        css={{ width: '70%', minWidth: '$16', zIndex: 2000 }}
        content="placeholder for add library item UI"
        footer={
          <Flex gap="md" justifyContent="end" wrap="wrap">
            <Button variant="subtle" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => alert('coming soon...')}>
              Add...
            </Button>
          </Flex>
        }
      />

      <Button
        variant="primary"
        icon={{ name: 'plus', position: 'left' }}
        onClick={() => setIsModalOpen(true)}
      >
        Add Library Item
      </Button>
    </>
  );
};

export default AddLibraryItem;
