import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import {
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

const AppIdentifier = ({ update, isDisabled, settings, validationDep }) => {
  const identifierFields = new Array(1).fill(0).map((_, idx) => idx);
  const { refs, onInvalidate } = useInputsValidators(identifierFields, update);
  useRemoveValidationOnUnmount(identifierFields, update);

  useEffect(() => {
    onInvalidate(0)(!settings.value);
  }, []);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">App identifier</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt" ref={refs[0]}>
              Provide the bundle identifier of the application that should be
              locked on the device.
            </p>
          </Setting.Title>
          <Setting.Controls>
            <TextInput
              disabled={isDisabled}
              id="app-identifier"
              value={settings.value}
              placeholder="com.apple.mobilesafari"
              onChange={(e) => update('value', e.target.value)}
              validator={(v) => [
                {
                  message: 'Required',
                  invalid: () => !v,
                  trigger: ['onBlur', validationDep],
                },
              ]}
              onInvalidate={onInvalidate(0)}
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

AppIdentifier.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  validationDep: PropTypes.symbol.isRequired,
};

export default memo(AppIdentifier);
