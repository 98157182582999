import learnMoreIcon64 from '!url-loader!./screens/success-screen/assets/learn-more.png';
import selfServiceIcon64 from '!url-loader!./screens/success-screen/assets/ss-icon-2024.png';
import defaultKandjiDarkIcon from '!url-loader!./settings/default-logo-dark.png';
import defaultKandjiIcon from '!url-loader!./settings/default-logo.png';

import rocket from './settings/rocket.png';

const imageToFile = (image, filename) => {
  if (!image) {
    return null;
  }
  const arr = image.split(',');
  // const mime = arr[0].match(/:(.*?);/)[1];
  const mimeMatch = image.split('base64')[0].split('data:')[1];
  if (!mimeMatch) {
    return null;
  }
  const mime = mimeMatch.replace(';', '');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const selfServiceIcon = {
  dataUrl: selfServiceIcon64,
  file: imageToFile(selfServiceIcon64, 'kandji-setup-self-service.png'),
};

const learnMoreIcon = {
  dataUrl: learnMoreIcon64,
  file: imageToFile(learnMoreIcon64, 'kandji-setup-learn-more.png'),
};

const kandjiIcon = {
  dataUrl: defaultKandjiIcon,
  file: imageToFile(defaultKandjiIcon, 'kandji-setup-kandji-icon.png'),
};

const kandjiDarkIcon = {
  dataUrl: defaultKandjiDarkIcon,
  file: imageToFile(defaultKandjiDarkIcon, 'kandji-setup-kandji-dark-icon.png'),
};

const rocketIcon = {
  dataUrl: rocket,
  file: imageToFile(rocket, 'rocket.png'),
};

export {
  selfServiceIcon,
  learnMoreIcon,
  kandjiIcon,
  kandjiDarkIcon,
  rocketIcon,
};
