import { Flex } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

import LibraryListItem from './LibraryListItem';

export type LibraryListProps = {
  items: any;
};

const LibraryList = (props: LibraryListProps) => {
  const { items } = props;

  return (
    <Flex gap="lg" flow="column">
      {items.pages.map((page) =>
        page.results.map((item) => (
          <LibraryListItem key={item.id} item={item} />
        )),
      )}
    </Flex>
  );
};

export default LibraryList;
