import {
  Button,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { Flex, styled } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

const ErrorWithNoMargin = styled(Flex, {
  '& .b-txt-input': {
    marginBottom: 0,
  },
});

const UserGroup = (props) => {
  const {
    setting,
    allUserGroups = [],
    update,
    updateValidation,
    isDisabled,
    canDelete,
    onDelete,
    validationDep,
  } = props;
  const fieldsToValidate = ['group', 'authRight'];
  const getKey = (k) => `${setting.id}__${k}`;
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidate.map(getKey),
    updateValidation,
  );

  return (
    <ErrorWithNoMargin className="b-flex-gtiny b-flex-vc" ref={refs[0]}>
      <TextInput
        compact
        disabled={isDisabled}
        value={setting.authRight}
        placeholder="Authorization right"
        onChange={(e) => update('authRight', e.target.value)}
        validator={(v) => [
          onEmptyBlurValidator(v, {
            trigger: ['onBlur', validationDep],
            message: 'Required',
          }),
          {
            message: 'Duplicate use of auth right',
            invalid: () => {
              let duplicateCount = 0;
              allUserGroups.forEach((grp) => {
                duplicateCount += grp.authRight === v ? 1 : 0;
              });

              return duplicateCount > 1;
            },
            trigger: ['onBlur', 'onChange', validationDep],
          },
        ]}
        onInvalidate={(isInvalid) =>
          onInvalidate(getKey('authRight'))(isInvalid)
        }
      />
      <TextInput
        compact
        disabled={isDisabled}
        value={setting.group}
        placeholder="Group"
        onChange={(e) => update('group', e.target.value)}
        validator={(v) => [
          onEmptyBlurValidator(v, {
            trigger: ['onBlur', 'onChange', validationDep],
            message: 'Required',
          }),
          {
            message: 'Duplicate use of group name',
            invalid: () => {
              let duplicateCount = 0;
              allUserGroups.forEach((grp) => {
                duplicateCount += grp.group === v ? 1 : 0;
              });

              return duplicateCount > 1;
            },
            trigger: ['onBlur', 'onChange', validationDep],
          },
        ]}
        onInvalidate={(isInvalid) => onInvalidate(getKey('group'))(isInvalid)}
      />
      <Button
        size="small"
        icon="trash-can"
        theme="error"
        kind="link"
        isDisabled={!canDelete || isDisabled}
        onClick={onDelete}
      />
    </ErrorWithNoMargin>
  );
};

export default UserGroup;
