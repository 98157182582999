import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ComputersDEPProvider } from 'src/app/components/common/hooks/use-computersdep';
import styled from 'styled-components';
import NoDataToDisplay from '../../assets/img/no_data_to_display.png';
import { getDEPComputersCountsForViews } from '../_actions/DEPComputer';
import {
  getComputersForFilters as callGetComputersForFilters,
  getComputerCount,
  getComputersCountsForViews,
} from '../_actions/computer';
import {
  setSnackbar as callSetSnackbar,
  setModal as callSetmodal,
} from '../_actions/ui';
import { updateUser as callUpdateUser } from '../_actions/users';
import { DEPViews, KandjiViews, links, planTypes } from '../common/constants';
import history from '../router/history';
import SavedViews from './SavedViews';
import ComputersTableMain from './common/ComputersTableMain';
import DEPComputersTableMain from './common/DEPComputersTableMain';
import TableNoDataHelper from './common/TableNoDataHelper';
import {
  getFiltersFromUrl,
  parseDateFromFilter,
  setFiltersToUrl,
} from './common/helpers';
import { LineLoader } from './interface/LineLoader';

const queryString = require('query-string');

const Wrapper = styled.section`
  display: flex;
  margin-left: -50px;
`;

const defaultView = {
  selectFeature: [
    'id',
    'status',
    'computerName',
    'model',
    'serial',
    'blueprint',
    'assetUser',
    'lastCheckIn',
  ], // DON'T REMOVE ID AND STATUS!!!
  widths: {},
  percentWidths: {
    assetUser: 17,
    blueprint: 17,
    model: 17,
    computerName: 17,
    serial: 17,
    lastCheckIn: 17,
  },
  sortName: 'name',
  sortOrder: 'asc',
  showFilters: false,
};

/* istanbul ignore next */
const enrollmentUtilization = (max: number, current: number): string =>
  `${Math.round(max === 0 ? (current > 0 ? 100 : 0) : (current / max) * 100)}%`;

class ComputerList extends React.PureComponent {
  constructor(props) {
    super(props);
    const URLparams = queryString.parse(window.location.search, {
      arrayFormat: 'bracket',
    });

    this.state = {
      ...defaultView,
      computersCounts: {},
      DEPComputersCounts: {},
      activeViewId: URLparams.viewId ? URLparams.viewId : null,
      showFilters: !!URLparams.viewId,
      DEP: false,
    };
  }

  componentDidMount() {
    const {
      getComputersForFilters,
      history,
      location,
      match,
      planType,
      maxDevices,
      maxDevicesPerPlatform,
    } = this.props;

    const { url } = match;
    switch (url) {
      case links.depDevices:
        this.setState({
          activeViewId: 'total',
          DEP: true,
        });
        break;
      default:
        this.setState({
          DEP: false,
        });
    }

    const allViews = this.getAllViews(false);
    const allDEPViews = this.getAllViews(true);
    getComputersForFilters();
    getComputersCountsForViews(allViews).then((counts) =>
      this.setState({ computersCounts: counts }),
    );
    getDEPComputersCountsForViews(allDEPViews).then((counts) =>
      this.setState({ DEPComputersCounts: counts }, () => {
        const URLparams = queryString.parse(window.location.search, {
          arrayFormat: 'bracket',
        });

        /* istanbul ignore next */
        if (URLparams.viewId) {
          window.setTimeout(() => {
            this.acceptView(URLparams.viewId, true);
          }, 100);
        }
      }),
    );

    /* istanbul ignore next */
    getComputerCount().then(({ macos, other }) => {
      const singleLimitUtilization =
        planType === planTypes.SINGLE_LIMIT
          ? enrollmentUtilization(maxDevices || 0, macos + other)
          : 'N/A';
      const perPlatformUtilization =
        planType === planTypes.PER_PLATFORM_LIMIT
          ? {
              macos: enrollmentUtilization(
                maxDevicesPerPlatform?.macos || 0,
                macos,
              ),
              ios_ipados: enrollmentUtilization(
                maxDevicesPerPlatform?.ios_ipados || 0,
                other,
              ),
            }
          : { macos: 'N/A', ios_ipados: 'N/A' };
      // @ts-expect-error -- pendo injected in Meta.tsx
      pendo?.updateOptions({
        account: {
          single_limit_utilization: singleLimitUtilization,
          macos_utilization: perPlatformUtilization.macos,
          ios_ipados_tvos_utilization: perPlatformUtilization.ios_ipados,
        },
      });
    });
  }

  getAllViews = (DEP) => {
    const { userSettings } = this.props;
    let allViews;
    if (DEP) {
      allViews = [...DEPViews];
    } else {
      const savedViews = get(userSettings, 'saved_views.computers_list', []);
      const allComputersView = { id: 'allComputersViewId', filters: [] };
      allViews = [
        ...KandjiViews.filter(({ isHidden }) => !isHidden),
        ...savedViews,
        allComputersView,
      ];
    }

    const parsedAllViews = allViews.map((view) => {
      const parsedFilters = this.prepareFilters(view.filters);
      return { ...view, filters: parsedFilters };
    });

    return parsedAllViews.map((view) => ({
      id: view.id,
      filters: view.filters,
    }));
  };

  prepareFilters = (filters) => {
    const options = [];
    filters.map((filter) => {
      if (!Object.keys(filter).length) {
        return;
      }
      switch (filter.name) {
        case 'blueprint':
        case 'computerName':
        case 'model':
        case 'serial':
        case 'os':
        case 'assetUser':
        case 'assetTag':
        case 'agentVersion': {
          const option = { ...filter };
          options.push(option);
          break;
        }
        case 'status': {
          const option = { ...filter };
          const alertStatus = option.value
            ? option.value.indexOf('WARNING,ERROR')
            : -1;
          if (alertStatus > -1) {
            option.value = [
              ...option.value.filter((i) => i === 'WARNING,ERROR'),
              'WARNING',
              'ERROR',
            ];
          }
          const passStatus = option.value
            ? option.value.indexOf('PASS,REMEDIATED')
            : -1;
          if (passStatus > -1) {
            option.value = [
              ...option.value.filter((i) => i === 'PASS,REMEDIATED'),
              'PASS',
              'REMEDIATED',
            ];
          }
          options.push(option);
          break;
        }
        case 'lastCheckIn':
        case 'firstEnrollment':
        case 'lastEnrollment': {
          const option = {
            ...{ name: filter.name, operator: filter.operator },
          };
          if (filter.operator === 'between' && filter.value) {
            const splittedValues = filter.value.split('@');
            const from = parseDateFromFilter(splittedValues[0]).toISOString();
            const to = parseDateFromFilter(splittedValues[1]).toISOString();
            option.value = `${from}@${to}`;
          } else if (filter.operator !== 'between' && filter.value) {
            option.value = parseDateFromFilter(filter.value).toISOString();
          } else {
            option.value = null;
          }
          options.push(option);
          break;
        }
      }
    });
    return options;
  };

  updateComputerListState = (newState) => {
    this.setState(newState);
  };

  showAddNewViewModal = (DEP) => {
    const { setModal } = this.props;
    const { filters, selectFeature, percentWidths, sortName, sortOrder } =
      this.child.getCurrentViewState();
    const sorting = {
      sortName:
        sortName ||
        (selectFeature.includes('computerName') ? 'computerName' : 'id'),
      sortOrder: sortOrder || 'asc',
    };

    setModal('SAVE_NEW_VIEW', {
      for: 'computers_list',
      forDep: 'computers_dep_list',
      ifDep: DEP,
      updateComputerListState: this.updateComputerListState,
      getAllViews: this.getAllViews,
      view: {
        filters,
        columns: selectFeature,
        percentWidths: pick(percentWidths, [
          ...selectFeature.filter((i) => i !== 'id'),
        ]),
        sorting,
      },
    });
    return null;
  };

  needUpdate = () => {
    const { activeViewId } = this.state;
    if (this.isViewChanged(activeViewId)) {
      this.setState({ isViewChanged: true });
    } else {
      this.setState({ isViewChanged: false });
    }
  };

  isViewChanged = (viewId) => {
    const { userSettings } = this.props;
    if (this.child && userSettings) {
      const { selectFeature, percentWidths, sortName, sortOrder } =
        this.child.getCurrentViewState();
      const filters = getFiltersFromUrl(location);
      const sorting = {
        sortName:
          sortName ||
          (selectFeature.includes('computerName') ? 'computerName' : 'id'),
        sortOrder: sortOrder || 'asc',
      };
      const savedViews = get(userSettings, 'saved_views.computers_list', []);
      const savedDepViews = get(
        userSettings,
        'saved_views.computers_dep_list',
        [],
      );
      const view = [
        ...savedViews,
        ...savedDepViews,
        ...KandjiViews.filter(({ isHidden }) => !isHidden),
        ...DEPViews,
      ].find((view) => view.id === viewId);
      if (view) {
        const viewsNotEqual = !isEqual(
          {
            filters: view.filters,
            columns: view.columns,
            percentWidths: view.percentWidths,
            sorting: view.sorting,
          },
          {
            filters,
            columns: selectFeature,
            percentWidths: pick(percentWidths, [
              ...selectFeature.filter((i) => i !== 'id'),
            ]),
            sorting,
          },
        );
        return viewsNotEqual;
        // For default view
      }
      return !isEqual(
        {
          filters: [],
          columns: defaultView.selectFeature,
          percentWidths: defaultView.percentWidths,
          sorting: {
            sortName: defaultView.sortName,
            sortOrder: defaultView.sortOrder,
          },
        },
        {
          filters,
          columns: selectFeature,
          percentWidths: pick(percentWidths, [
            ...selectFeature.filter((i) => i !== 'id'),
          ]),
          sorting,
        },
      );
    }
    return false;
  };

  editView = (event, viewId, ifDep) => {
    event.stopPropagation();
    const { userSettings, updateUser, userId, setSnackbar } = this.props;
    const { filters, selectFeature, percentWidths, sortName, sortOrder } =
      this.child.getCurrentViewState();
    const sorting = {
      sortName:
        sortName ||
        (selectFeature.includes('computerName') ? 'computerName' : 'id'),
      sortOrder: sortOrder || 'asc',
    };
    const savedViews = get(userSettings, 'saved_views.computers_list');
    const savedDepViews = get(userSettings, 'saved_views.computers_dep_list');
    const oldView = savedViews.find((view) => view.id === viewId);
    const oldDepView = savedDepViews.find((view) => view.id === viewId);
    const newViewParams = {
      filters,
      columns: selectFeature,
      percentWidths: pick(percentWidths, [
        ...selectFeature.filter((i) => i !== 'id'),
      ]),
      sorting,
    };
    const editedView = { ...oldView, ...newViewParams };
    const editedDepView = { ...oldDepView, ...newViewParams };
    let newViews = {};
    if (ifDep) {
      newViews = {
        computers_list: [
          ...(get(userSettings, 'saved_views.computers_list') || []),
        ],
        computers_dep_list: [
          ...(get(userSettings, 'saved_views.computers_dep_list') || []).filter(
            (i) => i.id !== viewId,
          ),
          editedDepView,
        ],
      };
    } else {
      newViews = {
        computers_list: [
          ...(get(userSettings, 'saved_views.computers_list') || []).filter(
            (i) => i.id !== viewId,
          ),
          editedView,
        ],
        computers_dep_list: [
          ...(get(userSettings, 'saved_views.computers_dep_list') || []),
        ],
      };
    }
    const payload = {
      ...userSettings,
      saved_views: { ...userSettings.savedViews, ...newViews },
    };
    updateUser({ id: userId, settings: payload }, true, false)
      .then(() => {
        this.setState({ isLoading: false, isViewChanged: false });
        setSnackbar('View Was Updated');
      })
      .then(() => {
        getComputersCountsForViews(this.getAllViews()).then((counts) =>
          this.setState({ computersCounts: counts }),
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        setSnackbar(err.message);
      });
  };

  acceptView = (viewId, onMount = false) => {
    const { userSettings } = this.props;
    const savedViews = get(userSettings, 'saved_views.computers_list', []);
    const savedDepViews = get(
      userSettings,
      'saved_views.computers_dep_list',
      [],
    );

    let DEP;
    if (
      [...savedViews, ...KandjiViews.filter(({ isHidden }) => !isHidden)].find(
        (view) => view.id === viewId,
      ) &&
      !onMount
    ) {
      DEP = false;
      history.push(links.devices);
    } else if (
      /* istanbul ignore next */
      [...savedDepViews, ...DEPViews].find((view) => view.id === viewId) &&
      !onMount
    ) {
      DEP = true;
      history.push(links.depDevices);
    }
    const acceptingView = [
      ...savedViews,
      ...savedDepViews,
      ...KandjiViews.filter(({ isHidden }) => !isHidden),
      ...DEPViews,
    ].find((view) => view.id === viewId);
    const currentViewState = {
      selectFeature: acceptingView.columns,
      percentWidths: { ...acceptingView.percentWidths, id: 0 },
      sortName: acceptingView.sorting.sortName,
      sortOrder: acceptingView.sorting.sortOrder,
      showFilters: !isEmpty(acceptingView.filters),
      activeViewId: acceptingView.id,
    };

    this.child.updateViewState(currentViewState);
    /* istanbul ignore next */
    if (!onMount) {
      setFiltersToUrl(acceptingView.filters, viewId);
    }

    this.setState({ activeViewId: viewId, DEP });
  };

  toDefaultView = () => {
    this.child.toDefaultView();
    history.push(links.devices);
    this.setState({ activeViewId: null, DEP: false });
  };

  tableSwitch = (noDataViewForTable) => {
    const { location, match, history } = this.props;
    const { url } = match;
    const { isViewChanged, activeViewId, DEP } = this.state;
    const routerProps = () => ({ location, match, history });

    switch (url) {
      default:
        return (
          <ComputersTableMain
            {...routerProps()}
            activeViewId={activeViewId}
            noDataView={noDataViewForTable}
            onRef={(ref) => {
              this.child = ref;
            }}
            isViewChanged={isViewChanged}
            needUpdate={this.needUpdate}
            acceptView={this.acceptView}
          />
        );
      case links.depDevices:
        return (
          <DEPComputersTableMain
            {...routerProps()}
            activeViewId={activeViewId}
            noDataView={noDataViewForTable}
            onRef={(ref) => {
              this.child = ref;
            }}
            isViewChanged={isViewChanged}
            needUpdate={this.needUpdate}
            acceptView={this.acceptView}
            DEP={DEP}
          />
        );
    }
  };

  render() {
    const {
      isComputersFetched,
      history,
      setModal,
      isDEPConfigured,
      match,
      allDepComputers,
      isDepComputersFetched,
    } = this.props;
    const {
      isViewChanged,
      computersCounts,
      DEPComputersCounts,
      activeViewId,
      DEP,
    } = this.state;

    const noDataViewForTable = (
      <TableNoDataHelper
        key="##table-empty##"
        message={
          DEP && !isDEPConfigured
            ? 'Automated Device Enrollment not configured'
            : 'No devices enrolled'
        }
        btnText={
          DEP && !isDEPConfigured
            ? 'Configure Automated Device Enrollment'
            : 'Add A Device'
        }
        onBtnClick={
          DEP && !isDEPConfigured
            ? () => history.push('/my-company/integrations')
            : () => history.push('/add-devices')
        }
        image={NoDataToDisplay}
        borderless
      />
    );

    if (!isComputersFetched && !isDepComputersFetched) {
      /* istanbul ignore next */
      return <LineLoader isDelayed />;
    }

    return (
      <ComputersDEPProvider>
        <Wrapper data-loading={!isComputersFetched} className="devices-page">
          <SavedViews
            save={this.showAddNewViewModal}
            edit={this.editView}
            match={match}
            isViewChanged={isViewChanged}
            acceptView={this.acceptView}
            toDefaultView={this.toDefaultView}
            computersCounts={computersCounts}
            DEPComputersCounts={DEPComputersCounts}
            activeViewId={activeViewId}
          />
          {this.tableSwitch(noDataViewForTable)}
        </Wrapper>
      </ComputersDEPProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  userSettings: state.account.user.settings,
  isDEPConfigured: state.account.company.is_dep,

  isComputersFetched: state.filters.fetched,
  isDepComputersFetched: state.filters.depFetched,
  allDepComputers: state.filters.DEPComputers,
  feature_configuration: state.account?.company?.feature_configuration,
  planType: state.account.company.plan_type,
  maxDevices: state.account.company.max_devices,
  maxDevicesPerPlatform: state.account.company.max_devices_per_platform,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal: callSetmodal,
      updateUser: callUpdateUser,
      setSnackbar: callSetSnackbar,
      getComputersForFilters: callGetComputersForFilters,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ComputerList),
);
