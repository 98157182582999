/* istanbul ignore file */
import {
  Checkbox,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useRef } from 'react';

import AddableContent from 'features/library-items/common/addable-content';
import { Setting } from 'features/library-items/template';
import { v4 } from 'uuid';
import { useCallbackByKey } from '../../ethernet.model';
import EthernetService from '../../service/ethernet-service';

const keys = EthernetService.keys.trust;

const AddCertificateRow = ({
  updateCertificateName,
  validationDep,
  update,
  isDisabled,
  item,
}) => {
  const { value, keyField } = item;
  const key = `${keys.serverCertificateNames}-${keyField}`;
  const fieldsToValidate = [key];
  const firstDep = useRef(validationDep);
  const showValidation = firstDep.current !== validationDep;
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <div ref={refs[key]} style={{ width: '360px' }}>
      <TextInput
        disabled={isDisabled}
        value={value}
        onChange={(e) => updateCertificateName(e.target.value, keyField)}
        compact
        isOptional
        placeholder="*.radius.accuhive.io"
        validator={(v) => [
          onEmptyBlurValidator(v, {
            trigger: [
              'onBlur',
              validationDep,
              ...(showValidation ? ['onInput'] : []),
            ],
          }),
        ]}
        onInvalidate={onInvalidate(key)}
        removeValidationOnUnmount
        data-testid="add-cert-row"
      />
    </div>
  );
};

const ServerCertificateNames = ({
  setting,
  isDisabled,
  update,
  validationDep,
}) => {
  const specifyServerCertificateNames =
    setting[keys.specifyServerCertificateNames];
  const certificateNames = setting[keys.serverCertificateNames] || [];

  const getUpdate = useCallbackByKey(({ key, args: [value] }) => {
    update(keys.serverCertificateNames, (p = []) => {
      const result = [...p];
      const index = result.findIndex((item) => item.keyField === key);
      result[index] = { value, keyField: key };
      return result;
    });
  }, []);

  return (
    <Setting.Row testId="server-cert-names">
      <Setting.Controls>
        <Checkbox
          checked={setting[keys.specifyServerCertificateNames]}
          onChange={() => update(keys.specifyServerCertificateNames, (p) => !p)}
          disabled={isDisabled}
          label="Specify server certificate names"
          testId="cert-names"
        />
      </Setting.Controls>
      <Setting.Helpers>
        <p className="b-txt-light">
          The list of accepted server certificate common names. If a server
          presents a certificate that isn't in this list, it won't be trusted.
        </p>
      </Setting.Helpers>
      {specifyServerCertificateNames && (
        <Setting.SecondaryControls testId="specify-server-cert-names">
          <Setting.SecondaryControlsRow className="--no-grid">
            <Setting.Title>
              <p className="b-txt">Server certificate name</p>
            </Setting.Title>
            <AddableContent
              items={certificateNames}
              isDisabled={isDisabled}
              getEmptyItem={() => ({
                keyField: v4(),
                value: '',
              })}
              onChange={(v) => update(keys.serverCertificateNames, v)}
              max={20}
              itemsClassName="k-ssh-access-items-container"
              rowClassName="k-ethernetv2-trust-scn__input-wrapper"
              addButtonText="Add certificate name"
              renderItem={(index) => (
                <AddCertificateRow
                  item={certificateNames[index]}
                  isDisabled={isDisabled}
                  update={update}
                  validationDep={validationDep}
                  updateCertificateName={getUpdate(
                    certificateNames[index].keyField,
                  )}
                />
              )}
            />
          </Setting.SecondaryControlsRow>
        </Setting.SecondaryControls>
      )}
    </Setting.Row>
  );
};

export default ServerCertificateNames;
