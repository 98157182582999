import { formatDate } from './_helpers';

export default (activity) => [
  {
    label: 'Deleted by',
    values: [activity.actor_name || activity.ip || '-'],
  },
  {
    label: 'Deleted at',
    values: [formatDate(activity)],
  },
];
