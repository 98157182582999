/* istanbul ignore file */

import { useHistory } from 'react-router';

import { Toaster as toaster } from '@kandji-inc/bumblebee';

import { links } from 'app/common/constants';

import library from '../library';

import { advancedService } from '../services/advanced';
import { connectorService } from '../services/connector';

import { useAuthority, useConnector, useIs, useModal } from '.';

const useAdvanced = () => {
  const history = useHistory();

  const { authority } = useAuthority();

  const { connectorItem, setConnector, fetchConnectorList } = useConnector();

  const { setIsWorking } = useIs();

  const { modalCreateConnector, setModalCreateConnector } = useModal();

  const createIntegrationAndConnector = async () => {
    let connectorUUID;

    if (modalCreateConnector) {
      await advancedService.createADCSIntegration();

      connectorUUID = await connectorService.createConnector();
      connectorUUID = connectorUUID.id;

      setConnector(connectorUUID);
      setModalCreateConnector(false);

      fetchConnectorList();
    }

    return connectorUUID;
  };

  const updateConnectorItem = async (form) => {
    try {
      const { id, manage, add } = form;

      setIsWorking(true);

      await advancedService.assignExistingAndNewAuthoritiesToConnector({
        connectorId: id,
        existingAuthorityIds: manage,
        newAuthorities: add,
      });

      setIsWorking(false);

      toaster('Connector updated.');
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster('Something went wrong.');
    }
  };

  const deleteIntegration = async () => {
    try {
      setIsWorking(true);

      await advancedService.deleteIntegration();

      setIsWorking(false);

      toaster(
        'Your integration with Active Directory Certificate Services has been deleted.',
      );

      history.push(links.integrations.root);
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster('Something went wrong.');
    }
  };

  const deleteConnectorItem = async (form) => {
    try {
      const { id, manage } = form;

      setIsWorking(true);

      const connectorName = connectorItem.name;
      await advancedService.deleteConnectorAndReassignAuthorities({
        connectorId: id,
        authorities: manage,
      });

      setIsWorking(false);

      toaster(`Connector ${connectorName} has been deleted.`);
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster('Something went wrong.');
    }
  };

  const gotoCreateLibraryItem = (identifier) => {
    const { path, type } = library[identifier];

    const route = `/${path}/add`;

    const state = {
      previousPage: history.location.pathname,
      certificate: {
        server: authority,
        type,
      },
    };

    history.push(route, state);
  };

  return {
    createIntegrationAndConnector,
    updateConnectorItem,
    deleteIntegration,
    deleteConnectorItem,
    gotoCreateLibraryItem,
  };
};

export default useAdvanced;
