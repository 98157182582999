import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';

import {
  Button,
  Checkbox,
  Flex,
  Select,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

import SystemExtensionService from '../../service/system-extension-service';
import ExtensionsTable from './ExtensionsTable';

const TeamIds = ({ update, isDisabled, settings, validationDep }) => {
  const onRemoveTeamId = useCallback(
    (idx) => {
      update('teams', (teams) => {
        teams.splice(idx, 1);
        return teams;
      });
    },
    [update],
  );

  const onUpdateTeamId = useCallback(
    (property, teamId, value, isInvalid) => {
      update('teams', (teams) =>
        teams.map((team) => {
          if (team.id === teamId) {
            return {
              ...team,
              [property]: value,
              isInvalid,
            };
          }

          return team;
        }),
      );
    },
    [update],
  );

  const onUpdateTeamAllowedExtensionType = useCallback(
    (property, teamId) => {
      update('teams', (teams) =>
        teams.map((team) => {
          if (team.id === teamId) {
            return {
              ...team,
              ExtensionTypes: {
                ...team.ExtensionTypes,
                [property]: !team.ExtensionTypes[property],
              },
            };
          }

          return team;
        }),
      );
    },
    [update],
  );

  const onDeleteExtension = useCallback(
    (teamId) =>
      ({ id }) => {
        update('teams', (teams) =>
          // Find and remove the `AllowedKernelExtensions` item in the correct Team ID:
          teams.map((team) => {
            if (team.id === teamId) {
              return {
                ...team,
                Extensions: team.Extensions.filter(
                  (extension) => extension.id !== id,
                ),
              };
            }

            return team;
          }),
        );
      },
    [update],
  );

  const onUpdateExtension = useCallback(
    (teamId) => (property, extensionId, value, isInvalid) => {
      update('teams', (teams) =>
        teams.map((team) => {
          if (team.id === teamId) {
            return {
              ...team,
              Extensions: team.Extensions.map((extension) => {
                if (extension.id === extensionId) {
                  return {
                    ...extension,
                    [property]: value,
                    isInvalid: Boolean(isInvalid),
                  };
                }

                return extension;
              }),
              isInvalid: Boolean(isInvalid),
            };
          }

          return team;
        }),
      );
    },
    [],
  );

  const onAddExtension = useCallback(
    (teamId) => () => {
      update('teams', (teams) =>
        teams.map((team) => {
          if (team.id === teamId) {
            return {
              ...team,
              Extensions: [
                ...team.Extensions,
                SystemExtensionService.generateExtension(),
              ],
            };
          }

          return team;
        }),
      );
    },
    [update],
  );

  const teamIdIdentifiers = new Array(20).fill(0).map((_, idx) => idx);
  const { refs, onInvalidate } = useInputsValidators(teamIdIdentifiers, update);
  useRemoveValidationOnUnmount(teamIdIdentifiers, onUpdateTeamId);

  useEffect(() => {
    teamIdIdentifiers.forEach((n) => {
      const curr = settings?.teams[n];
      onInvalidate(n)(curr?.isInvalid);
    });
  }, [settings.teams]);

  return (
    <>
      {settings.teams.map((team, idx) => (
        <Setting.Card key={team.id}>
          <Setting.Header>
            <h3 className="b-h3">Team ID</h3>
            {!isDisabled && settings.teams.length > 1 && (
              <Button
                theme="error"
                kind="link"
                size="small"
                icon="xmark"
                onClick={() => onRemoveTeamId(idx)}
              >
                Remove
              </Button>
            )}
          </Setting.Header>
          <Setting.Rows>
            <Setting.Row>
              <Setting.Title>
                <p className="b-txt">Team Identifier</p>
              </Setting.Title>
              <Setting.Controls>
                <div ref={refs[idx]}>
                  <TextInput
                    disabled={isDisabled}
                    id={`team_id_${team.id}`}
                    isOptional
                    value={team.TeamId}
                    placeholder="9PTGMPNXZ2"
                    onChange={(e) =>
                      onUpdateTeamId('TeamId', team.id, e.target.value)
                    }
                    validator={(v) => [
                      {
                        message: 'Required.',
                        invalid: () => !v?.length,
                        trigger: ['onMount', 'onBlur', validationDep],
                      },
                    ]}
                    onInvalidate={(isInvalid) =>
                      onUpdateTeamId('TeamId', team.id, team.TeamId, isInvalid)
                    }
                  />
                </div>
              </Setting.Controls>
            </Setting.Row>
            <Setting.Row>
              <Setting.Title>
                <p className="b-txt">
                  Name <span className="b-txt-light">(optional)</span>
                </p>
              </Setting.Title>
              <Setting.Controls>
                <TextInput
                  disabled={isDisabled}
                  id={`display_name_${team.id}`}
                  isOptional
                  value={team.DisplayName}
                  placeholder="Symantec Endpoint Protection"
                  onChange={(e) =>
                    onUpdateTeamId('DisplayName', team.id, e.target.value)
                  }
                />
              </Setting.Controls>
            </Setting.Row>

            <Setting.Row>
              <Setting.Title>
                <p className="b-txt">System extensions</p>
              </Setting.Title>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Choose whether all system extensions signed by the team
                  identifier are allowed, or if specific extensions or extension
                  types are allowed.
                </p>
              </Setting.Helpers>
              <Setting.Controls>
                <Select
                  compact
                  options={SystemExtensionService.ALLOW_EXTENSIONS_OPTIONS}
                  onChange={(selected) =>
                    onUpdateTeamId('AllowedExtensions', team.id, selected.value)
                  }
                  value={SystemExtensionService.ALLOW_EXTENSIONS_OPTIONS.find(
                    (opt) => opt.value === team.AllowedExtensions,
                  )}
                  disabled={isDisabled}
                  inputId={`allowed_extensions_${team.id}`}
                />
              </Setting.Controls>
            </Setting.Row>

            {team.AllowedExtensions === 'SPECIFIC' && (
              <ExtensionsTable
                extensions={team.Extensions}
                isDisabled={isDisabled}
                onDelete={onDeleteExtension(team.id)}
                onUpdate={onUpdateExtension(team.id)}
                updateTeams={update}
                onAddRow={onAddExtension(team.id)}
                validationDep={validationDep}
              />
            )}

            {team.AllowedExtensions === 'SPECIFIC_TYPES' && (
              <Setting.Row>
                <Setting.Title>
                  <p className="b-txt">Allowed system extension types</p>
                </Setting.Title>
                <Setting.Controls>
                  <Flex direction="col" gapType="gap2">
                    <Checkbox
                      checked={team.ExtensionTypes.EndpointSecurityExtension}
                      onChange={() =>
                        onUpdateTeamAllowedExtensionType(
                          'EndpointSecurityExtension',
                          team.id,
                        )
                      }
                      disabled={isDisabled}
                      label="Endpoint Security extensions"
                      testId={`endpointSecurityCheckbox_${team.id}`}
                    />
                    <Checkbox
                      checked={team.ExtensionTypes.DriverExtension}
                      onChange={() =>
                        onUpdateTeamAllowedExtensionType(
                          'DriverExtension',
                          team.id,
                        )
                      }
                      disabled={isDisabled}
                      label="Driver Extensions"
                      testId={`driverExtensionsCheckbox_${team.id}`}
                    />
                    <Checkbox
                      checked={team.ExtensionTypes.NetworkExtension}
                      onChange={() =>
                        onUpdateTeamAllowedExtensionType(
                          'NetworkExtension',
                          team.id,
                        )
                      }
                      disabled={isDisabled}
                      label="Network extensions"
                      testId={`networkExtensionsCheckbox_${team.id}`}
                    />
                  </Flex>
                </Setting.Controls>
              </Setting.Row>
            )}
          </Setting.Rows>
        </Setting.Card>
      ))}

      <Flex justify="end">
        <Button
          disabled={isDisabled}
          icon="plus"
          iconPlacement="right"
          onClick={() =>
            update('teams', [
              ...settings.teams,
              SystemExtensionService.generateTeamId(),
            ])
          }
        >
          Add Team ID
        </Button>
      </Flex>
    </>
  );
};

TeamIds.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
};

export default memo(TeamIds);
