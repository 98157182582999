import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import VulnerabilityIcon from '../assets/vulnerability.png';

const NoVulnerabilities = () => (
  <Flex alignItems="center" justifyContent="center" flow="column" hFull>
    <img
      src={VulnerabilityIcon}
      alt="Vulnerability"
      style={{ width: '50px' }}
    />
    <Heading size="4" css={{ fontWeight: '$medium', paddingTop: '$3' }}>
      No vulnerabilities detected
    </Heading>
    <Text>No vulnerabilities have been detected in your Mac fleet.</Text>
  </Flex>
);

export { NoVulnerabilities };
