// istanbul ignore file
import {
  Box,
  Button,
  Flex,
  Heading,
  Loader,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';

import type { SortState } from 'src/components';
import { DataTable } from 'src/components';
import { Pagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/integrations/hooks/use-adjust-sidebar-chat-bubble';
import {
  ColumnEditorButton,
  ColumnEditorWindow,
} from 'src/features/visibility/shared/components/column-editor';
import { useDebouncedState } from 'src/hooks/useDebouncedState';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import {
  areColumnsInOrder,
  getColumnId,
  hasColumnVisibilityChanged,
} from '../../shared/components/column-editor/utils';
import PrismSavedViewsActionMenu from '../components/PrismTable/PrismSavedViewsActionMenu';
import PrismViewCreateDropdown from '../components/PrismTable/components/PrismViewCreateDropdown';
import PrismViewRenameModal from '../components/PrismTable/components/PrismViewRenameModal';
import PrismViewSaveDropdown from '../components/PrismTable/components/PrismViewSaveDropdown';
import { validateViewName } from '../components/PrismTable/utils/tableUtils';
import { usePrismViewsContext } from '../contexts/PrismViewsContext';
import {
  useCategoryDataCountQuery,
  usePagination,
  useSyncUrlWithTableState,
} from '../hooks';
import { useViewDataQuery } from '../hooks/use-view-data-query';
import PrismViewFilters from './PrismViewFilters';
import { DeviceBulkActions, DevicesExportButton } from './components';
import { ViewsNav } from './components/ViewsNav';
import {
  useCreateDeviceView,
  useDeleteDeviceView,
  useDeviceViewsQuery,
  useUpdateDeviceView,
} from './hooks';

export const removeEmptyValues = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v != null && v !== ''),
  );

const PrismViews = ({ bannerVisible }: { bannerVisible: boolean }) => {
  useWhiteBackground();
  useAdjustSidebarChatBubble();

  const history = useHistory();

  const { columns } = usePrismViewsContext();

  const {
    filter,
    sortBy,
    viewId,
    resetFilter,
    replaceFilter,
    setViewId,
    removeViewId,
    setSortBy,
  } = useSyncUrlWithTableState('/devices');

  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
    resetPagination,
  } = usePagination();

  const handleSort = React.useCallback(
    ({ col, direction }: SortState) => {
      if (direction === 'none') {
        setSortBy('');
        return;
      }
      const prefix = direction === 'asc' ? '' : '-';
      setSortBy(`${prefix}${col}`);
    },
    [setSortBy],
  );

  const sort = React.useMemo(() => {
    if (sortBy == null || sortBy === '') {
      return { col: '', direction: 'none' };
    }
    const [direction, col] = sortBy.startsWith('-')
      ? ['desc', sortBy.slice(1)]
      : ['asc', sortBy];
    return { col, direction };
  }, [sortBy]) as SortState;

  const [columnSizes, setColumnSizes] = React.useState<Record<string, number>>(
    {},
  );
  const [columnVisibility, setColumnVisibility] = React.useState<
    Record<string, boolean>
  >({});

  const [columnOrder, setColumnOrder] = React.useState<string[]>([]);
  const [selection, setSelection] = React.useState<string[]>([]);
  const [viewName, setViewName] = React.useState('');
  const [viewNameError, setViewNameError] = React.useState('');
  const [renameViewOpen, setRenameViewOpen] = React.useState(false);
  const [debouncedSearch, setSearch, search] = useDebouncedState<string>('');
  const [isColumnEditorOpen, setIsColumnEditorOpen] = React.useState(false);
  const handleToggleColumnEditor = () => {
    setIsColumnEditorOpen(!isColumnEditorOpen);
  };
  const { data: savedViews, isLoading: savedViewsLoading } =
    useDeviceViewsQuery();

  const view = React.useMemo(
    () =>
      savedViews?.find((v) => (viewId != null ? v.id === viewId : v.id === '')),
    [savedViews, viewId],
  );

  const resetTableViewState = React.useCallback(
    (view) => {
      if (!view) {
        return;
      }
      const columnSizes = view.columns?.reduce((sizes, col) => {
        if (col.size != null && col.name != null) {
          sizes[getColumnId(col)] = col.size;
        }
        return sizes;
      }, {}) as Record<string, number>;
      setColumnSizes(columnSizes);

      const { sort_by, filters } = view;
      setSortBy(sort_by || '');

      if (filters) {
        const newFilter = Object.entries(filters).reduce(
          (acc, [key, value]) => {
            acc[key || ''] = value;
            return acc;
          },
          {},
        );
        replaceFilter(newFilter);
      }

      setColumnOrder(view.columns?.map((col) => getColumnId(col)) || []);

      setColumnVisibility(
        view.columns?.reduce((visibility, col) => {
          visibility[getColumnId(col)] = col.visible;
          return visibility;
        }, {}),
      );
    },
    [replaceFilter, setSortBy],
  );

  React.useEffect(() => {
    resetPagination();
    resetTableViewState(view);
    setSearch('');
  }, [view]);

  const { data, isPending, refetch } = useViewDataQuery({
    params: {
      sort_by: sortBy,
      limit: pageSize,
      offset: pageIndex * pageSize,
    },
    filterBody: removeEmptyValues(filter),
    columns: (columns?.columnDefs || []).map((col) => {
      let [category, name] = col.id.split('.');
      if (name === undefined) {
        name = category;
        category = '';
      }
      return { name, category } as { name: string; category: string };
    }),
    search: debouncedSearch,
  });

  const { data: countData, refetch: refetchCount } = useCategoryDataCountQuery({
    params: {
      category: 'device_information', // TODO: use view count endpoint and remove this
    },
    filterBody: removeEmptyValues(filter),
  });

  const { toast } = useToast();
  const { sidebarOpened } = React.useContext(InterfaceContext);
  const toastOffset = React.useMemo(
    () => (sidebarOpened ? '276px' : '98px'),
    [sidebarOpened],
  );

  const getOrderedColumns = React.useCallback(
    () =>
      columnOrder.map((colId) => {
        let [category, name] = colId.split('.');
        if (name === undefined) {
          name = category;
          category = '';
        }
        return {
          category,
          name,
          visible:
            columnVisibility[colId] !== false &&
            !columns.alwaysHiddenColumns?.includes(colId),
          size: columnSizes[colId],
        };
      }),
    [columnOrder, columns.alwaysHiddenColumns, columnSizes, columnVisibility],
  );

  const createDeviceView = useCreateDeviceView({
    onSuccess: (newView) => {
      toast({
        title: 'Success!',
        content: `View ${newView.name} is now available as a saved view.`,
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      if (newView.id) {
        setViewId(newView.id);
      }
    },
  });

  const handleViewCreate = React.useCallback(
    (viewName) => {
      const existingNames = savedViews?.map((view) => view.name || '') || [];
      const viewNameError = validateViewName(viewName, existingNames);
      setViewNameError(viewNameError);
      if (viewNameError !== '') {
        return;
      }
      createDeviceView.mutate({
        name: viewName,
        sort_by: sortBy,
        columns: getOrderedColumns(),
        filters: filter,
      });
    },
    [savedViews, createDeviceView, filter, sortBy, getOrderedColumns],
  );

  const updatePrismView = useUpdateDeviceView({
    onSuccess: (view) => {
      toast({
        title: 'Success!',
        content: `${view.name} has been updated.`,
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      // setSaveViewOpen(false);
      if (view.id) {
        setViewId(view.id);
      }
    },
  });

  const handleViewUpdate = React.useCallback(() => {
    if (!view) {
      return;
    }
    updatePrismView.mutate({
      id: view.id,
      name: viewName || view.name,
      sort_by: sortBy,
      columns: getOrderedColumns(),
      filters: filter,
    });
  }, [view, viewName, getOrderedColumns, updatePrismView, filter, sortBy]);

  const renamePrismView = useUpdateDeviceView({
    onSuccess: (view) => {
      toast({
        title: 'Success!',
        content: 'Display name has been updated.',
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      setRenameViewOpen(false);
      if (view.id) {
        setViewId(view.id);
      }
    },
  });

  const handleViewRename = React.useCallback(
    (newName: string) => {
      if (!view?.id) {
        return;
      }
      const existingNames = savedViews?.map((view) => view.name || '') || [];
      const viewNameError = validateViewName(newName, existingNames);
      setViewNameError(viewNameError);
      if (viewNameError !== '') {
        return;
      }
      renamePrismView.mutate({
        id: view?.id,
        name: newName,
        sort_by: view?.sort_by,
        columns: view?.columns,
        filters: view?.filters,
      });
    },
    [view, renamePrismView, savedViews],
  );

  const deleteSavedView = useDeleteDeviceView({
    view,
    onSuccess: () => {
      resetFilter();
      removeViewId();
      history.push(history.location.pathname);
      toast({
        title: `${view?.name} has been removed`,
        content: 'The saved view will no longer be available.',
        variant: 'default',
        style: {
          // istanbul ignore next
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
    },
  });

  // istanbul ignore next
  const bulkActions = React.useMemo(() => {
    if (selection.length === 0) {
      return undefined;
    }

    return (
      <DeviceBulkActions
        devices={selection}
        onClear={() => setSelection([])}
        onChangedBlueprint={() => {
          setSelection([]);
          refetch();
        }}
        onDeleted={() => {
          setSelection([]);
          refetch();
          refetchCount();
        }}
      />
    );
  }, [selection, refetch, refetchCount]);

  const viewChanged = React.useMemo(() => {
    if (!view) {
      return false;
    }
    if (sortBy !== '' && view.sort_by !== sortBy) {
      return true;
    }
    if (isEqual(view.filters, filter) === false) {
      return true;
    }
    if (!areColumnsInOrder(view.columns || [], columnOrder)) {
      return true;
    }
    if (hasColumnVisibilityChanged(view.columns || [], columnVisibility)) {
      return true;
    }
    return (
      view.columns?.find((col) => {
        const colId = col.category ? `${col.category}.${col.name}` : col.name;
        return columnSizes[colId] != null && col.size !== columnSizes[colId];
      }) != null
    );
  }, [view, sortBy, filter, columnSizes, columnOrder, columnVisibility]);

  const viewActions = React.useMemo(() => {
    if (!view || (!viewChanged && view.id === '')) {
      return null;
    }
    return (
      <Flex
        flow="row"
        flex="none"
        gap="sm"
        css={{ ml: 'auto', alignSelf: 'baseline' }}
      >
        {viewChanged && (
          <Button
            variant="subtle"
            compact
            onClick={() => resetTableViewState(view)}
          >
            Reset table
          </Button>
        )}
        {viewChanged && view.id === '' && (
          <PrismViewCreateDropdown
            viewName={viewName}
            viewNameError={viewNameError}
            handleViewCreate={handleViewCreate}
            setViewNameError={setViewNameError}
          />
        )}
        {view.id !== '' && (
          <PrismViewSaveDropdown
            viewName={viewName}
            viewNameError={viewNameError}
            handleViewCreate={handleViewCreate}
            handleViewUpdate={handleViewUpdate}
            setViewNameError={setViewNameError}
          />
        )}
      </Flex>
    );
  }, [
    view,
    viewChanged,
    viewName,
    viewNameError,
    handleViewCreate,
    handleViewUpdate,
    resetTableViewState,
  ]);

  const heightModifier = React.useMemo(() => {
    if (bannerVisible) {
      return '197px';
    }
    return '140px';
  }, [bannerVisible]);
  const dataTable = React.useMemo(() => {
    const cols = columnOrder.reduce(
      (memo, colId) => {
        const columnDef = columns.columnDefs?.find((c) => c.id === colId);
        if (
          columnDef &&
          columnVisibility[colId] !== false &&
          !columns.alwaysHiddenColumns?.includes(colId)
        ) {
          memo.push(columnDef);
        }
        return memo;
      },
      [] as Column<{}, unknown>[],
    );
    return (
      <DataTable
        data={data?.data || []}
        columnSizing={{
          sizes: columnSizes,
          setSizes: setColumnSizes,
        }}
        pinnedColumns={columns.pinnedColumns || []}
        columns={cols || []}
        offsets={{ container: 152, content: 304, table: 454 }}
        selectionModel={{ selection, setSelection, maxCount: 300 }}
        rowId="device_information.device_id"
        searchTerm={debouncedSearch}
        sort={{ sortState: sort, setSortState: handleSort }}
        css={{ flex: 1 }}
      />
    );
  }, [
    data,
    columns,
    columnSizes,
    columnVisibility,
    columnOrder,
    debouncedSearch,
    handleSort,
    selection,
    sort,
  ]);

  return (
    <Flex
      gap="xl"
      flex="1"
      css={{
        pt: '$5',
        height: `calc(100vh - ${heightModifier})`,
        overflow: 'hidden',
      }}
    >
      {savedViewsLoading && (
        <Flex flex="1" alignItems="center" justifyContent="center">
          <Loader size="lg" />
        </Flex>
      )}
      {!savedViewsLoading && (
        <>
          <ViewsNav
            views={savedViews}
            viewId={viewId}
            collapsed={isColumnEditorOpen}
            onSelectView={(view) => {
              setViewId(view.id);
            }}
          />
          <Flex
            flow="column"
            gap="md"
            css={{ minWidth: '400px', height: '100%' }} // TODO: calc based on sidebar width
            flex="1"
          >
            <Flex
              flow="row"
              alignItems="center"
              flex="none"
              css={{ height: '36px' }}
            >
              <Heading size="3" css={{ fontWeight: '$medium' }}>
                {view?.id ? view.name : 'All devices'}
              </Heading>

              <Flex justifyContent="end" flex="1">
                <ColumnEditorButton
                  onToggleColumnEditor={handleToggleColumnEditor}
                />
                <DevicesExportButton
                  viewName={view?.name}
                  columns={columns.columnDefs?.map((col) => {
                    let [category, name] = col.id.split('.');
                    if (name === undefined) {
                      name = category;
                      category = '';
                    }
                    return {
                      category: category as string,
                      name: name as string,
                      visible: true,
                      size: columnSizes[col.id],
                    };
                  })}
                  filters={view?.filters || filter}
                  blueprintIds={filter.blueprint_ids || []}
                  deviceFamilies={filter.device_families || []}
                />
                {view?.id && (
                  <PrismSavedViewsActionMenu
                    appliedSavedView={view}
                    setRenameViewOpen={setRenameViewOpen}
                    deleteView={deleteSavedView}
                  />
                )}
              </Flex>
            </Flex>
            <Flex flex="none" flow="row">
              <PrismViewFilters search={search} setSearch={setSearch} />
              <Flex
                flow="row"
                gap="sm"
                css={{ ml: 'auto', alignSelf: 'flex-end' }}
              >
                {viewActions}
              </Flex>
            </Flex>
            {(columns.columnDefs?.length ?? 0) > 0 ? dataTable : null}
            {isPending && (
              <Flex
                flex="1"
                alignItems="center"
                justifyContent="center"
                css={{ height: '100%' }}
              >
                <Loader size="lg" />
              </Flex>
            )}
            <Box css={{ flexShrink: 0, padding: '$2 0' }}>
              <Pagination
                bulkActions={bulkActions}
                currentPage={pageIndex + 1}
                totalItems={countData?.count || 0}
                itemsPerPage={pageSize}
                onPageChange={(page) =>
                  setPagination({ pageIndex: page - 1, pageSize })
                }
                onItemsPerPageChange={(itemsPerPage) =>
                  setPagination({ pageIndex: 0, pageSize: itemsPerPage })
                }
              />
            </Box>
          </Flex>
        </>
      )}
      {isColumnEditorOpen && (
        <ColumnEditorWindow
          columnVisibility={columnVisibility}
          columnOrder={columnOrder}
          columns={columns}
          onColumnVisibilityChange={setColumnVisibility}
          onColumnOrderChange={setColumnOrder}
          onClose={() => setIsColumnEditorOpen(false)}
        />
      )}
      <PrismViewRenameModal
        isOpen={renameViewOpen}
        viewName={view?.name || ''}
        viewNameError={viewNameError}
        onClose={() => {
          setRenameViewOpen(false);
        }}
        onOpenChange={setRenameViewOpen}
        handleViewRename={handleViewRename}
        setViewNameError={setViewNameError}
      />
    </Flex>
  );
};

export default PrismViews;
