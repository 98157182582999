// istanbul ignore file
import { Flex, styled } from '@kandji-inc/nectar-ui';
import ThreatListErrorBoundary from '../../common/components/ThreatListErrorBoundary';
import useStateParams from '../../common/hooks/use-state-params';
import type {
  DetectionDateFilterFields,
  DevicesFilterFields,
  DevicesFiltersState,
  FiltersState,
  PaginationState,
  ShowToast,
  SortColumnState,
  ThreatDetail,
  ThreatPosture,
  ThreatStatus,
} from '../../threat.types';
import useGetByDevices from '../ThreatListByThreats/hooks/use-get-by-threats/use-get-by-threats';
import ThreatListFilters from '../ThreatListFilters';
import ThreatListFilterFields from './ThreatListFilterFields';
import {
  defaultFilters,
  filtersParamConfig,
  paginationParamConfig,
  sortParamConfig,
} from './params/params';

const Container = styled(Flex, {
  height: 'calc(100vh - 80px - 134px)',
  flexDirection: 'column',
});

const errorBoundaryTableCss = {
  flex: 1,
  minHeight: 400,
};

type ThreatListByThreatsProps = Readonly<{
  showToast: ShowToast;
}>;

const ThreatListByThreats = (props: ThreatListByThreatsProps) => {
  const { showToast } = props;

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    sort,
    setSort,
    isDefaultFilterState,
  } = useStateParams<FiltersState, PaginationState, SortColumnState>(
    filtersParamConfig,
    paginationParamConfig,
    sortParamConfig,
    defaultFilters,
  );

  const {
    data: dataThreats,
    isLoading: isLoadingThreats,
    isError: isErrorFetchingThreats,
    refetch: refetchThreats,
  } = useGetByDevices(filters, sort, pagination);

  const dataCount = dataThreats?.count;
  const malwareCount = dataThreats?.malware_count;
  const pupCount = dataThreats?.pup_count;
  const threats: ThreatDetail[] = dataThreats?.results || [];
  const isEmpty = dataCount === 0;
  const isFiltering = !isDefaultFilterState();

  const setFiltersAndResetPage = (newFilters: DevicesFiltersState) => {
    setFilters(newFilters);
    setPagination({ page: 1 });
  };

  const handleClearFilters = (filterName: DevicesFilterFields) => {
    setFiltersAndResetPage({ [filterName]: undefined });
  };

  const handleApplyFilters = (
    filterName: DevicesFilterFields,
    value: ThreatStatus[] | ThreatPosture | DetectionDateFilterFields,
  ) => {
    setFiltersAndResetPage({ [filterName]: value });
  };

  const handleClearAllFilters = () => {
    setFiltersAndResetPage({
      status: undefined,
      detectionDate: undefined,
      pupPosture: undefined,
      malwarePosture: undefined,
      query: undefined,
    });
  };

  return (
    <ThreatListErrorBoundary
      css={errorBoundaryTableCss}
      preReset={refetchThreats}
    >
      <Container>
        {/* <ThreatListFilters
          filters={filters}
          onApply={handleApplyFilters}
          onClear={handleClearFilters}
          onClearAll={handleClearAllFilters}
          isFiltering={isFiltering}
          malwareCount={malwareCount}
          pupCount={pupCount}
          isExportVisible={!isEmpty}
          onExport={() => {}}
          view="by-devices"
        >
          <ThreatListFilterFields
            filters={filters}
            onApply={handleApplyFilters}
            onClear={handleClearFilters}
          />
        </ThreatListFilters> */}
        {/* TABLE */}
      </Container>
    </ThreatListErrorBoundary>
  );
};

export default ThreatListByThreats;
