import { INT_TYPES } from '../../../../constants';
import Setup from './custom-layouts/setup';

import type { IntegratorViewConfig } from '../../types/integrator.types';

import type {
  MsDeviceComplianceInputs,
  MsDeviceComplianceState,
} from '../../types/ms-device-compliance.types';

// Icons
import msDeviceComplianceLogo from '../../../../generic-cards-view/assets/microsoft-device-compliance.svg';
import iosIcon from '../../../ms-device-compliance/assets/ios-icon.svg';
import macOsIcon from '../../../ms-device-compliance/assets/mac-os-icon.svg';
import microsoftLogo from '../../../ms-device-compliance/assets/microsoft-logo.png';

// Helpers
import {
  configTickets,
  handleInitialLoad,
  handleSetGroupPolicies,
  handleSignIn,
} from './helper';

import SetupComplete from './custom-layouts/setup-complete';

// Config
const config: IntegratorViewConfig<
  MsDeviceComplianceInputs,
  MsDeviceComplianceState,
  object
> = {
  type: INT_TYPES.msDeviceCompliance,
  name: 'Microsoft Device Compliance',
  logo: msDeviceComplianceLogo,
  initialLoad: handleInitialLoad,
  pages: [
    {
      name: 'msComplianceConfiguration',
      layout: 'one-column-checkbox-full',
      header: {
        title: 'Microsoft Device Compliance',
        description:
          'Protect corporate resources by ensuring only compliant devices are allowed to access applications protected by Microsoft conditional access policies. ',
        link: {
          text: 'Learn more',
          url: '#',
        },
      },
      body: {
        title: 'Configure Microsoft Entra ID user directory integration',
        description:
          'Access to your user directory through Entra ID is required for user group lookup and must be configured prior to setting up Microsoft Device Compliance.',
        options: [
          {
            name: 'directoryConfigured',
            label: 'I have read and completed the steps listed above.',
            validations: { required: 'Required' },
          },
        ],
      },
      tickets: {
        title: 'Before you begin...',
        description:
          'Make sure to complete the following steps in your Microsoft Intune instance.',
        list: configTickets,
      },
      cancelButtonText: 'Cancel',
    },
    {
      name: 'msComplianceAuthentication',
      layout: 'one-column-input-full',
      header: {
        title: 'Microsoft Device Compliance',
        description:
          'Protect corporate resources by ensuring only compliant devices are allowed to access applications protected by Microsoft conditional access policies. ',
        link: {
          text: 'Learn more',
          url: '#',
        },
      },
      body: {
        title: 'Microsoft Domain',
        description:
          'The Microsoft Primary Domain URL can be found on the Entra ID Overview screen. ',
        inputs: [
          {
            name: 'msPrimaryDomain',
            type: 'text',
            label: 'Microsoft Primary Domain',
            placeholder: 'domain.onmicrosoft.com',
            validations: {
              required: 'Required',
              maxLength: {
                value: 256,
                message: 'Must be 256 characters or fewer',
              },
            },
          },
        ],
      },
      handleNext: handleSignIn,
      nextButtonIcon: microsoftLogo,
      nextButtonText: 'Sign in with Microsoft',
    },
    {
      name: 'msComplianceSetup',
      layout: 'custom-layout',
      header: {
        title: 'Microsoft Device Compliance',
        description:
          'Protect corporate resources by ensuring only compliant devices are allowed to access applications protected by Microsoft conditional access policies. ',
        link: {
          text: 'Learn more',
          url: '#',
        },
      },
      Component: Setup,
      data: {
        platformInfo: {
          title: 'Device platforms',
          description:
            'All data from selected platforms will be sent to Intune to determine conditional access.',
          validationMessage: 'Please select at least one device platform',
          list: [
            {
              name: 'macOsDevices',
              label: 'macOS devices',
              icon: macOsIcon,
              altText: 'macOS icon',
            },
            {
              name: 'iosDevices',
              label: 'iOS devices',
              icon: iosIcon,
              altText: 'iOS icon',
            },
          ],
        },
      },
      handleNext: handleSetGroupPolicies,
      cancelButtonText: 'Cancel',
    },

    // Hold Off on this for now

    // {
    //   name: 'msComplianceSetupComplete',
    //   layout: 'custom-layout',
    //   header: {
    //     title: 'Microsoft Device Compliance',
    //     description:
    //       'Integrate device data managed by Kandji alongside all other managed devices within Intune.',
    //     link: {
    //       text: 'Learn more',
    //       url: '#',
    //     },
    //   },
    //   Component: SetupComplete,
    // },
  ],
};

export default config;
