import { Banner, Checkbox, Chip } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
/* istanbul ignore file */
import React from 'react';

const Devicename = (props: any) => {
  const { update, isDisabled, setting } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Device name modification</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          A restrictions profile will be installed on the device to restrict the
          user from changing the device name.
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Checkbox
              isDisabled={isDisabled}
              label="Prevent users from modifying device name and hostname"
              checked={setting.prevent}
              onChange={() => update('prevent', (p) => !p)}
            />
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Prevents the device name from being changed on iOS, iPadOS, tvOS,
              and macOS Sonoma and later. This setting requires supervision.
            </p>
            <div className="b-flex-vc b-flex-g1">
              <Chip kind="tertiary-light" text="iOS 14+" />
              <Chip kind="tertiary-light" text="iPadOS 14+" />
              <Chip kind="tertiary-light" text="tvOS 14+" />
              <Chip kind="tertiary-light" text="macOS 14+" />
              <Chip kind="tertiary-light" text="Supervised" />
            </div>
            <Banner
              kind="block"
              theme="info"
              icon="circle-info"
              style={{ padding: '8px' }}
            >
              <span>
                If users change the device name or hostname on macOS, it will
                automatically be remediated upon next check-in.
              </span>
            </Banner>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Devicename;
