import { Select, useInputsValidators } from '@kandji-inc/bumblebee';
import { get } from 'lodash';
import React from 'react';

import { Setting } from 'features/library-items/template';

import { useValidate } from '../../ethernet.hooks';
import EthernetService from '../../service/ethernet-service';
import { proxyTypeOptions } from '../proxy-card.model';
import Automatic from './automatic';
import Manual from './manual';

const keys = EthernetService.keys.proxy;

const fieldsToValidate = [keys.proxyType];

const ProxyType = ({ setting, update, isDisabled, validationDep }) => {
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  const canShowProxyTypeError = useValidate({
    error:
      (!setting[keys.proxyType] ||
        setting[keys.proxyType] === EthernetService.proxyTypes.NONE) &&
      'Required',
    onInvalidate: onInvalidate(keys.proxyType),
    validationDep,
    reset: null,
  });
  const proxyTypeError = get(
    setting,
    `invalidationsMap.${keys.proxyType}.isInvalid`,
  );

  const showAutomatic =
    setting[keys.proxyType] === EthernetService.proxyTypes.AUTOMATIC;
  const showManual =
    setting[keys.proxyType] === EthernetService.proxyTypes.MANUAL;

  return (
    <Setting.Row testId="proxy-type">
      <Setting.Title>
        <p className="b-txt">Proxy type</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light" ref={refs[keys.proxyType]}>
          Specify whether the proxy will be manually or automatically
          configured.
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <Select
          isDisabled={isDisabled}
          options={proxyTypeOptions}
          compact
          onChange={(v) => update(keys.proxyType, v.value)}
          value={proxyTypeOptions.find(
            (option) => option.value === setting[keys.proxyType],
          )}
          errorText={(canShowProxyTypeError && proxyTypeError) || ''}
          testId="proxy-type-select"
        />
      </Setting.Controls>
      {showAutomatic && (
        <Automatic
          setting={setting}
          update={update}
          isDisabled={isDisabled}
          validationDep={validationDep}
        />
      )}
      {showManual && (
        <Manual
          validationDep={validationDep}
          update={update}
          isDisabled={isDisabled}
          setting={setting}
        />
      )}
    </Setting.Row>
  );
};

export default ProxyType;
