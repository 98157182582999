import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import { Box, Flex, Tbody, Td, Tr, styled } from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import featureFlags from 'src/config/feature-flags';
import { constants } from '../common';
import ThreatListHighlightedText from '../common/components/ThreatListHighlightedText';
import TableActionsDropdown from '../common/components/ThreatListTable/TableActionsDropdown';
import ThreatListTableArrowButton from '../common/components/ThreatListTable/ThreatListTableArrowButton';
import ThreatListTableExpandedRow from '../common/components/ThreatListTable/ThreatListTableExpandedRow';
import useFileStatusPolling from '../common/hooks/use-file-status-polling';
import isoToDateString from '../common/utils/isoToDateString';
import type {
  FileStatus,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';

const TruncatedLink = styled(Link, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StatusColumnColorBadge = styled(Box, {
  width: '$2',
  height: '$2',
  borderRadius: '50%',
  flexShrink: 0,
});

const StatusColumnText = styled(Box, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const getClassificationLabel = (
  classification: ThreatClassification,
  shortPUP: boolean = false,
) => {
  if (shortPUP && classification === 'PUP') {
    return classification;
  }
  return constants.THREAT_CLASSIFICATION_LABELS[classification];
};

const getStatusLabel = (status: ThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP[status];

const getStatusColor = (status: ThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

type RowActionOptions = Partial<{
  [key in ThreatStatus]: DropdownMenuOption;
}>;

type ThreatListTableBodyProps = {
  threats: ThreatDetail[];
  onStatusEvent: (fileStatus: FileStatus) => void;
  onGetReleaseDetails: (threat: ThreatDetail) => void;
  query: string | undefined;
  timezone: string;
};

const ThreatListTableBody = (props: ThreatListTableBodyProps) => {
  const { threats, onStatusEvent, onGetReleaseDetails, query, timezone } =
    props;
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const isRecheckStatusFF = featureFlags.getFlag(
    'edr_100323_recheck-threat-status',
  );

  const { startFileStatusCheck, isPolling: isThreatBeingChecked } =
    useFileStatusPolling(onStatusEvent);

  const handleRecheckThreatStatus = (selectedThreat: ThreatDetail) => {
    startFileStatusCheck(
      selectedThreat.device_id,
      selectedThreat.file_hash,
      selectedThreat.file_path,
    );
  };

  const getRowActionOptions = (item: ThreatDetail): DropdownMenuOption[] => {
    const options: RowActionOptions = {
      [constants.THREAT_STATUS.QUARANTINED]: {
        label: 'Release threat',
        icon: 'square-arrow-up',
        onClick: () => onGetReleaseDetails(item),
      },
      [constants.THREAT_STATUS.NOT_QUARANTINED]: {
        label: 'Recheck status',
        icon: 'arrows-rotate',
        onClick: () =>
          isThreatBeingChecked ? null : handleRecheckThreatStatus(item),
        disabled: isThreatBeingChecked,
      },
    };

    // Exclusively for disabling the recheck status option for threats that are not quarantined while the feature flag is off
    // Condition should be removed as part of the feature flag clean up
    if (
      !isRecheckStatusFF &&
      item.status === constants.THREAT_STATUS.NOT_QUARANTINED
    ) {
      return [];
    }

    const virusTotalOption = {
      label: 'Search VirusTotal',
      icon: 'virus-total',
      onClick: /* istanbul ignore next -- Linking */ () =>
        window.open(`${constants.VIRUS_TOTAL_URL}${item.file_hash}`),
    };

    if (!(item.status in options)) {
      return [virusTotalOption];
    }

    return [options[item.status], virusTotalOption];
  };

  const handleExpandRowButtonClick = (key: string, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedRows((prev) => new Set(prev.add(key)));
    } else {
      setExpandedRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    }
  };

  return (
    <Tbody>
      {threats.map((item) => {
        const id = `${item.file_hash}${item.file_path}${item.device_id}`;
        const isExpanded = expandedRows.has(id);
        const actionOptions = getRowActionOptions(item);
        const statusEnumValue = getStatusLabel(item.status);

        const TrCss = {
          td: {
            boxShadow: isExpanded ? 'none' : undefined,
          },
        };

        return (
          <Fragment key={id}>
            <Tr css={TrCss} hoverAnchorUnderline={false}>
              <Td title={isExpanded ? 'Collapse' : 'Expand'}>
                <ThreatListTableArrowButton
                  expanded={isExpanded}
                  onToggle={(isExpanded) =>
                    handleExpandRowButtonClick(id, isExpanded)
                  }
                  testId="expand-row-button"
                />
              </Td>
              <Td title={item.threat_name}>
                <ThreatListHighlightedText search={query}>
                  {item.threat_name}
                </ThreatListHighlightedText>
              </Td>
              <Td title={getClassificationLabel(item.classification)}>
                {getClassificationLabel(item.classification, true)}
              </Td>
              <Td title={item.process_name}>{item.process_name}</Td>
              <Td title={item.device_name}>
                <TruncatedLink
                  to={`${links.devices}/${item.device_id}/threats`}
                >
                  <ThreatListHighlightedText search={query}>
                    {item.device_name}
                  </ThreatListHighlightedText>
                </TruncatedLink>
              </Td>
              <Td title={`${item.detection_date}Z`}>
                {isoToDateString(item.detection_date, timezone)}
              </Td>
              <Td title={statusEnumValue}>
                <Flex alignItems="center" gap="sm">
                  <StatusColumnColorBadge
                    css={{
                      backgroundColor: `$${getStatusColor(item.status)}50`,
                    }}
                  />
                  <StatusColumnText>{statusEnumValue}</StatusColumnText>
                </Flex>
              </Td>
              <Td title="Actions">
                {Boolean(actionOptions.length) && (
                  <TableActionsDropdown
                    options={actionOptions}
                    testId="action-ellipsis"
                  />
                )}
              </Td>
            </Tr>
            {isExpanded && <ThreatListTableExpandedRow threat={item} />}
          </Fragment>
        );
      })}
    </Tbody>
  );
};

export default ThreatListTableBody;
