import React, { useEffect, useRef, useState } from 'react';

import { Flex, Text, styled, theme } from '@kandji-inc/nectar-ui';

import { highlightedText } from 'src/features/blueprint-flow/helpers';
import { MATCH_RULE_ID } from 'src/features/blueprint-flow/pages/assignments/map-search';
import useBlueprintFlow from 'src/features/blueprint-flow/store';

import {
  transformJsonLogicToTag,
  useBuilder,
  useGetDisplayValue,
} from '../../../services';
import { FlowTippy, TippyContainer, statementLineHeight } from '../../../theme';

const TagChip = styled(Flex, {
  display: 'inline-flex',
  padding: '4px 6px',
  gap: '4px',
  alignItems: 'center',
  borderRadius: '2px',
  background: '$chip_surface',
  maxWidth: '340px',
  color: '$chip_text',
  lineHeight: '20px',

  '& > span': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
  },

  '& b': {
    fontWeight: 500,
  },

  variants: {
    truncated: {
      true: {
        '& > span::after': {
          content: '...',
          position: 'absolute',
          fontSize: '1px',
          bottom: 0,
          right: 0,
          background: theme.colors.yellow20.value,
        },
      },
    },
    compact: {
      true: {
        maxWidth: '299px',
      },
    },
  },
});

const isTextTruncated = (element) => {
  if (!element) {
    return false;
  }
  return element.scrollWidth > element.clientWidth;
};

function RuleTag(props: {
  rule: any;
  facetMap: object;
  compact: boolean;
}) {
  const { rule, facetMap, compact } = props;
  const [mapSearchTerm, activeMatches] = useBlueprintFlow((state) => [
    state.mapSearchTerm,
    state.activeMatches,
  ]);
  const { displayValue, getDisplayValue } = useGetDisplayValue();

  const [displayInput, setDisplayInput] = useState('');
  const [displayOperator, setDisplayOperator] = useState('');
  const displayRef = useRef(null);

  useEffect(() => {
    const { input, operator, value, jsonLogicOperator, facetData } =
      transformJsonLogicToTag(rule, facetMap);

    setDisplayInput(input);
    setDisplayOperator(operator);

    getDisplayValue(value, jsonLogicOperator, facetData);
  }, []);

  const activeMatch = activeMatches.matches[activeMatches.currentMatchIndex];
  const isRuleMatched = activeMatch && activeMatch?.rule?.ruleId === rule.id;
  const boldOptions = {
    color: theme.colors.yellow40.value,
    matchIndex: activeMatch?.rule?.ruleMatchIdx,
  };

  const displayMatched =
    isRuleMatched && activeMatch.field === 'displayValue' && boldOptions;
  const inputMatched =
    isRuleMatched && activeMatch.field === 'input' && boldOptions;

  const isTruncated = displayMatched && isTextTruncated(displayRef.current);

  return (
    <FlowTippy
      content={
        <TippyContainer gap="xs">
          <Text size="1">
            {highlightedText(
              displayInput,
              mapSearchTerm,
              theme.colors.yellow20.value,
              inputMatched,
            )}{' '}
            {displayOperator}{' '}
            {highlightedText(
              displayValue,
              mapSearchTerm,
              theme.colors.yellow20.value,
              displayMatched,
            )}
          </Text>
        </TippyContainer>
      }
      popperOptions={{ strategy: 'fixed' }}
    >
      <TagChip
        id={MATCH_RULE_ID(rule.id)}
        truncated={isTruncated}
        compact={compact}
      >
        <Text ref={displayRef}>
          {highlightedText(
            displayInput,
            mapSearchTerm,
            theme.colors.yellow20.value,
            inputMatched,
          )}{' '}
          <b>{displayOperator}</b>{' '}
          {highlightedText(
            displayValue,
            mapSearchTerm,
            theme.colors.yellow20.value,
            displayMatched,
          )}
        </Text>
      </TagChip>
    </FlowTippy>
  );
}

type RuleTagListProps = {
  compact?: boolean;
};

function RuleTagList(props: RuleTagListProps) {
  const { compact } = props;
  const { rules, facetMap } = useBuilder();

  if (rules) {
    const ruleList = rules?.and;

    return ruleList?.map((rule: any, idx, arr) => {
      const key = JSON.stringify(rule);
      let ruleEl = null;

      // Handle 'or' rules
      if (rule.or) {
        ruleEl = rule.or.map((orRule: any, orIdx, orArr) => {
          const orKey = JSON.stringify(orRule);
          const orRuleEl = (
            <Flex alignItems="center" css={{ gap: '2px' }}>
              {orArr.length > 1 && orIdx === 0 && <Text>{'('}</Text>}
              <RuleTag
                key={orKey}
                rule={orRule}
                facetMap={facetMap}
                compact={compact}
              />
              {orArr.length > 1 && orIdx === orArr.length - 1 && (
                <Text>{')'}</Text>
              )}
            </Flex>
          );

          const orEl = <Text key={`or_${orKey}`}>{' or '}</Text>;

          if (orIdx === orArr.length - 1) {
            return orRuleEl;
          }

          return [orRuleEl, orEl];
        });
      } else {
        ruleEl = (
          <RuleTag
            key={key}
            rule={rule}
            facetMap={facetMap}
            compact={compact}
          />
        );
      }

      const andEl = <Text key={`and_${key}`}>and</Text>;

      if (idx === arr.length - 1) {
        return ruleEl;
      }

      return [ruleEl, andEl];
    });
  }

  return (
    <Text
      variant="disabled"
      css={{ fontStyle: 'italic', lineHeight: statementLineHeight }}
    >
      no rule defined
    </Text>
  );
}

export default RuleTagList;
export { RuleTag, TagChip };
