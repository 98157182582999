/* istanbul ignore file - config only */
import { blueprintLibraryCategories } from 'features/blueprints/common';
import React from 'react';
import { IPALibraryItem } from 'src/old-library/IPALibraryItem';
import {
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
} from '../../common';
import LibraryItemConfig from './item-config.class';

const ipaApps = {
  'In-House Apps': new LibraryItemConfig({
    name: 'In-House Apps',
    description: ``,
    type: apiTypes.IPA_APP,
    publisher: null,
    devices: [deviceTypes.MAC],
    osRequirements: [],
    category: categories.IN_HOUSE_APPS,
    blueprintCategory: blueprintLibraryCategories.INSTALLERS_SCRIPTS,
    icon: '',
    canAdd: canAddLibraryTypes.CANNOT_ADD,
    identifier: '',
    routeType: 'ipa-apps',
    getUrl: ({ id }) => `/library/ipa-apps/${id}`,
    getAddUrl: () => null,
    component: () => <IPALibraryItem />,
  }),
};

export default ipaApps;
