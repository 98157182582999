import React from 'react';

import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from '../custom-profile/activity-tab';
import initialState from './initial-state';
import BackgroundItems from './sections/background-items';
import { loginBackgroundItemsService } from './service/login-background-items.service';
import { transformFromApi, transformToApi } from './service/transformers';
import useLoginWindowService from './service/use-login-background-items-service';

const LoginBackgroundItemsPage = (props) => {
  const { model, setModel, pageState } = props;
  const itemConfig = config['Login & Background Items'];

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      defaultIcon={itemConfig.icon}
      crumb={model.name || itemConfig.name}
      service={loginBackgroundItemsService}
      triggerValidation={() => {}}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      savedModel={{ name: 'Login & Background Items' }}
      supportsRules
      supportsDuplication
    >
      <BackgroundItems
        model={model}
        setModel={setModel}
        pageState={pageState}
      />
    </LibraryItemPage>
  );
};

const LoginBackgroundItems = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useLoginWindowService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LoginBackgroundItemsPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="login-background-items"
    />
  );
};

export default LoginBackgroundItems;
