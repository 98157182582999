import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'features/library-items/data-service/library-item/devices';
/* istanbul ignore file */
import { v4 } from 'uuid';

import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import { deviceVariables } from '../dnd/common';

type SendToApi = {
  name: string;
  active: boolean;
  blueprints: string[];
  is_all_blueprints: boolean;
  type?: string;
  identifier?: string;
  data: Record<string, any>;
  rules: string;
};

function transformSettings(settings) {
  return {
    deviceName: {
      variables: settings.deviceName.map((dVar) => {
        const deviceVariable = deviceVariables.find(
          ({ value }) => value === dVar.value,
        );
        return {
          id:
            dVar.isCustom || !deviceVariable?.value
              ? v4()
              : deviceVariable.value,
          value: dVar.value,
          isCustom: dVar.isCustom,
          deviceVariable,
        };
      }),
    },
    hostName: {
      variables: settings.hostName.map((dVar) => {
        const deviceVariable = deviceVariables.find(
          ({ value }) => value === dVar.value,
        );
        return {
          id:
            dVar.isCustom || !deviceVariable?.value
              ? v4()
              : deviceVariable.value,
          value: dVar.value,
          isCustom: dVar.isCustom,
        };
      }),
      useDeviceNamePattern: settings.useDeviceNamePattern,
    },
    deviceNameModification: {
      prevent: settings.preventModifyName,
    },
  };
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const result = {
    ...commonData,
    rules: apiData.rules,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

async function transformToApi(model) {
  const toSend: SendToApi = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      deviceName: model.deviceName.variables.map(({ value, isCustom }) => ({
        value,
        isCustom,
      })),
      hostName: model.hostName.variables.map(({ value, isCustom }) => ({
        value,
        isCustom,
      })),
      useDeviceNamePattern: model.hostName.useDeviceNamePattern,
      preventModifyName: model.deviceNameModification.prevent,
    },
    rules: model.rules || null,
  };

  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (!model.id) {
    const itemConfig = configs['Device Name'];
    toSend.type = itemConfig.type;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
