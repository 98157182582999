import { useQuery } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_DEP_COMPUTERS } from 'app/api/urls';
import { ADEListViewStore } from '../store/ADEListViewStore';

export interface GetADEDevicesParams {
  filters?: Array<{ name: string; operator: string; value: string | string[] }>;
  ordering?: string;
  sizePerPage?: number;
  page?: number;
  search?: string;
  search_columns?: string;
}

export async function getADEDevices(params: GetADEDevicesParams) {
  try {
    const state = ADEListViewStore.getState();
    const { setDevices } = state;
    const { filters } = params;
    const res = await api(URL_DEP_COMPUTERS).get({
      ...params,
      filters: JSON.stringify(filters),
    });

    const { data } = res;
    const devices = data.results ?? [];

    setDevices(devices);
    return devices;
  } catch (error) {
    return [];
  }
}

export function useGetADEDevices(params: GetADEDevicesParams) {
  return useQuery({
    queryKey: ['get-ade-devices', params],
    queryFn: () => getADEDevices(params),
  });
}
