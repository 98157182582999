import { updateSetting as update } from '@kandji-inc/bumblebee';
import React from 'react';
import { useLocation } from 'react-router';

import { timezones } from 'app/common/constants';
import LibraryItemPage from 'features/library-items/common/library-item-page';
import featureFlags from 'src/config/feature-flags';
import UpdatesCard from 'src/features/library-items/common/updates-card/UpdatesCard';
import PreviewHoverTippy from 'src/features/util/components/preview-hover-tippy';
import useDefaultTimezone from '../../../../components/common/hooks/use-default-timezone';
import useUniqValue from '../../../../components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import { ManagedOSNameShort } from '../../common/updates-card/updates-card.types';
import configs from '../../library/library-item-configurations/items/operating-systems';
import ActivityTab from '../custom-profile/activity-tab';
import { INITIAL_MODEL } from './constants';
import type { IosReleasesPageProps } from './ios-releases.types';
import { iosReleasesService } from './service/ios-releases-service';
import { createTransformToApi, transformFromApi } from './service/transformers';
import useIosReleasesService from './service/use-ios-releases-service';

function IosReleases() {
  const { pathname } = useLocation();
  const isAdd = pathname.includes('/add');
  const service = useIosReleasesService({ isAdd });

  const [validationDep, triggerValidation] = useUniqValue();
  const blueprintConflicts = useBlueprintConflicts();

  const {
    pageProps,
    PlaceHolder,
  }: {
    pageProps: IosReleasesPageProps;
    PlaceHolder: (props: unknown) => JSX.Element;
  } = useLibraryItem({
    initialState: INITIAL_MODEL,
    useService: service,
  });

  const { model, setModel, pageState } = pageProps;
  const {
    name,
    _data: data,
    instanceName,
    updates,
    description,
    publisher,
    osRequirements,
    currentVersion,
    isPreviewChip,
  } = model;

  const defaultTimezone = useDefaultTimezone();
  const selectedTimezone =
    model.updates?.enforcementTimezone ||
    timezones.find((tz) => tz.value === defaultTimezone);

  const breadcrumb = instanceName ? `${name} - ${instanceName}` : name;

  const config = configs['iOS Release'];

  const fall2023NewManagedOSLibraryItems = featureFlags.getFlag(
    'mdm_081123_fall-2023-managed-os-library-items',
  );

  const fall2023RSREnforcement = featureFlags.getFlag(
    'mdm-10312023-rsr-version-updates-fe-2',
  );

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LibraryItemPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      type={config.type}
      identifier={config.identifier}
      crumb={breadcrumb}
      service={iosReleasesService}
      triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={createTransformToApi({ selectedTimezone })}
      ActivityTab={ActivityTab}
      isNameEditable={false}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      canHaveInstanceName
      summaryInfoProps={{
        name: (
          <div className="b-flex">
            <div className="b-mr-tiny">{config.name}</div>
            {isPreviewChip && <PreviewHoverTippy />}
          </div>
        ),
        instanceName,
        description: description || '',
        publisher,
        devices: config.devices,
        requirements: [osRequirements],
        currentVersion,
      }}
      supportsRules
      supportsDuplication
    >
      <UpdatesCard
        name={name}
        update={update('updates', setModel)}
        setting={updates}
        isDisabled={pageState.isDisabled}
        selectedTimezone={selectedTimezone}
        appName={ManagedOSNameShort.IOS}
        version={data?.version}
        validationDep={validationDep}
        fall2023NewManagedOSLibraryItems={fall2023NewManagedOSLibraryItems}
        fall2023RSREnforcement={fall2023RSREnforcement}
      />
    </LibraryItemPage>
  );
}

export default IosReleases;
