import { Icon, setClass } from '@kandji-inc/bumblebee';
import _cond from 'lodash/cond';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import iPadFrameEmptyLandscape from '../assets/iPad-frame-empty-landscape.png';
import iPadFrameEmptyPortrait from '../assets/iPad-frame-empty-portrait.png';
import iPadHomeLandscape from '../assets/iPad-frame-home-screen-landscape.png';
import iPadHomePortrait from '../assets/iPad-frame-home-screen-portrait.png';
import iPadLockLandscape from '../assets/iPad-frame-lock-screen-landscape.png';
import iPadLockPortrait from '../assets/iPad-frame-lock-screen-portrait.png';
import iPhoneFrameEmpty from '../assets/iPhone-frame-empty.png';
import iPhoneHome from '../assets/iPhone-frame-home-screen.png';
import iPhoneLock from '../assets/iPhone-frame-lock-screen.png';
/* istanbul ignore file */
import './uploader.css';
import { toImage, toMb } from './utils';

const acceptableFileExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'tiff'];

const ScreenTypes = {
  LOCK: Symbol('LOCK'),
  HOME: Symbol('HOME'),
};

const DeviceTypes = {
  IPAD_PORTRAIT: Symbol('IPAD_PORTRAIT'),
  IPAD_LANDSCAPE: Symbol('IPAD_LANDSCAPE'),
  IPHONE: Symbol('IPHONE'),
};

const isIPadHomePortrait = ({ screenType, deviceType }) =>
  screenType === ScreenTypes.HOME && deviceType === DeviceTypes.IPAD_PORTRAIT;

const isIPadLockPortrait = ({ screenType, deviceType }) =>
  screenType === ScreenTypes.LOCK && deviceType === DeviceTypes.IPAD_PORTRAIT;

const isIPadHomeLandscape = ({ screenType, deviceType }) =>
  screenType === ScreenTypes.HOME && deviceType === DeviceTypes.IPAD_LANDSCAPE;

const isIPadLockLandscape = ({ screenType, deviceType }) =>
  screenType === ScreenTypes.LOCK && deviceType === DeviceTypes.IPAD_LANDSCAPE;

const isIPhoneHome = ({ screenType, deviceType }) =>
  screenType === ScreenTypes.HOME && deviceType === DeviceTypes.IPHONE;

const isIPhoneLock = ({ screenType, deviceType }) =>
  screenType === ScreenTypes.LOCK && deviceType === DeviceTypes.IPHONE;

const IPadHomePortrait = () => ({
  img: iPadHomePortrait,
  style: { width: '283px', height: '393px' },
  imageStyle: {
    borderRadius: 8,
    height: '100%',
    aspectRatio: 3 / 4,
  },
  innerStyle: { padding: 14 },
});

const IPadLockPortrait = () => ({
  img: iPadLockPortrait,
  style: { width: '283px', height: '393px' },
  imageStyle: { borderRadius: 8, height: '100%', aspectRatio: 3 / 4 },
  innerStyle: { padding: 14 },
});

const IPadHomeLandscape = () => ({
  img: iPadHomeLandscape,
  style: { width: '393px', height: '283px' },
  imageStyle: { borderRadius: 8, width: '100%', aspectRatio: 4 / 3 },
  innerStyle: { padding: 14 },
});

const IPadLockLandscape = () => ({
  img: iPadLockLandscape,
  style: { width: '393px', height: '283px' },
  imageStyle: { borderRadius: 8, width: '100%', aspectRatio: 4 / 3 },
  innerStyle: { padding: 14 },
});

const IPhoneHome = () => ({
  img: iPhoneHome,
  style: { width: '191px', height: '393px' },
  imageStyle: { borderRadius: 24, height: '100%', aspectRatio: 9 / 19.5 },
  innerStyle: { padding: 8 },
});

const IPhoneLock = () => ({
  img: iPhoneLock,
  style: { width: '191px', height: '393px' },
  imageStyle: { borderRadius: 24, height: '100%', aspectRatio: 9 / 19.5 },
  innerStyle: { padding: 8 },
});

const getScreen = _cond([
  [isIPadHomePortrait, IPadHomePortrait],
  [isIPadLockPortrait, IPadLockPortrait],
  [isIPadHomeLandscape, IPadHomeLandscape],
  [isIPadLockLandscape, IPadLockLandscape],
  [isIPhoneHome, IPhoneHome],
  [isIPhoneLock, IPhoneLock],
]);

const Uploader = (props) => {
  const {
    icon,
    style,
    className,
    onImage,
    maxSizeAllowed = 4e6,
    disabled = false,
    onSelect = () => {},
    onRemove = () => {},
    accept = acceptableFileExtensions
      .map((extension) => `.${extension}`)
      .join(','),
    renderTip,
    screenType = ScreenTypes.LOCK,
    deviceType = DeviceTypes.IPAD_PORTRAIT,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    isHoverPreview = false,
    setError = () => {},
  } = props;

  const [image, setImage] = useState({ dataUrl: '' });
  const [imgSrc, setImgSrc] = useState();
  const [isLoading, setIsLoading] = useState(null);

  const onDropAccepted = useCallback((acceptedFiles) => {
    setError(null);
    const file = acceptedFiles[0];
    setIsLoading(file.name);
    toImage(file)
      .then((theImage) => {
        setImage(theImage);
        onSelect(theImage);
      })
      .catch(console.log)
      .finally(() => setIsLoading(null));
  });

  const onDrop = useCallback(() => {
    /* gets called when dropped, arg passed in is files */
  }, []);

  const onDropRejected = useCallback(() => {
    /* gets call when rejected, arg is 'fileRejections' */
  }, []);

  useEffect(() => {
    // Icon can either be an icon object or a url/base64 of the icon.
    if (icon?.dataUrl || typeof icon === 'string') {
      setImgSrc(icon.dataUrl || icon);
    }
  }, [icon]);

  /* set the image when it's set */
  useEffect(() => {
    if (image.dataUrl) {
      setImgSrc(image.dataUrl);
      if (onImage) {
        onImage(image);
      }
    }
  }, [image]);

  const validator = useCallback((file) => {
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    if (!acceptableFileExtensions.includes(fileExtension.toLowerCase())) {
      setError({
        type: 'invalid-file-type',
        message: `File must be PNG, JPG, JPEG, BMP, or TIFF.`,
      });
      return;
    }
    if (file.size > maxSizeAllowed) {
      setError({
        type: 'max-size',
        message: `File size exceeds ${toMb(maxSizeAllowed)} MB.`,
      });
      return;
    }
  }, []);

  const screenImg = useMemo(
    () => getScreen({ screenType, deviceType }),
    [screenType, deviceType],
  );

  return (
    <div style={style} className={setClass([className])}>
      {imgSrc ? (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="wallpaper-device-container"
        >
          <div className="wallpaper-device-inner">
            <div
              className="wallpaper-inner-content"
              style={screenImg.innerStyle}
            >
              <div
                style={{
                  backgroundImage: `url(${imgSrc})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  ...screenImg.imageStyle,
                }}
              />
              {isHoverPreview && !disabled && (
                <div className="wallpaper-image-hover-overlay">
                  <div className="wallpaper-image-hover-overlay-screen"></div>
                </div>
              )}
            </div>
          </div>
          <div className={setClass('wallpaper-image-hover-iphone')}>
            <img src={screenImg.img} style={{ ...screenImg.style }} />
            {isHoverPreview && !disabled ? (
              <div className="wallpaper-image-delete">
                <div>
                  <Icon
                    name="trash"
                    onClick={() => {
                      onRemove();
                      setImage({ dataUrl: '' });
                      setImgSrc(null);
                      setError(null);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="wallpaper-container" style={{ ...screenImg.style }}>
          <div className="device-frame-container">
            {deviceType === DeviceTypes.IPHONE && (
              <img src={iPhoneFrameEmpty} style={{}} />
            )}
            {deviceType === DeviceTypes.IPAD_PORTRAIT && (
              <img src={iPadFrameEmptyPortrait} style={{}} />
            )}
            {deviceType === DeviceTypes.IPAD_LANDSCAPE && (
              <img src={iPadFrameEmptyLandscape} style={{}} />
            )}
          </div>
          <div
            className={setClass([
              'wallpaper-dropzone',
              disabled && 'wallpaper-dropzone-disabled',
            ])}
          >
            <Dropzone
              disabled={disabled}
              validator={validator}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              accept={accept}
              maxSize={maxSizeAllowed}
              onDrop={onDrop}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className={setClass([
                    'wallpaper-droparea',
                    isDragActive && 'wallpaper-droparea-active',
                  ])}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  {isLoading && (
                    <div className="wallpaper-loading">
                      <div className="wallpaper-loading-spinner"></div>
                      <p>{isLoading}</p>
                    </div>
                  )}

                  {!isLoading &&
                    renderTip({
                      icon: (
                        <Icon
                          name="file-arrow-up"
                          className="wallpaper-upload-icon"
                        />
                      ),
                    })}
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      )}
    </div>
  );
};

export { DeviceTypes, ScreenTypes, Uploader };
