import { Icon } from '@kandji-inc/bumblebee';
import Tippy from '@tippyjs/react';
import { withPermissions } from 'contexts/account';
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { arrayPush as callArrayPush, initialize } from 'redux-form';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

import { paths } from 'src/features/blueprints/common';

import { updateBlueprint } from 'app/_actions/blueprint';
import { setModal, setSnackbar } from 'app/_actions/ui';
import { updateUser } from 'app/_actions/users';
import {
  AwaitingEnrollment,
  KandjiViews,
  colors,
  columnOptions,
  links,
  omitVulnColumns,
} from 'app/common/constants';
import { InterfaceContext } from 'contexts/interface';
import { LineLoader } from '../interface/LineLoader';

import {
  annotateComputer,
  exportComputers as callExportComputers,
  queryComputers as callQueryComputers,
  setComputers as callSetComputers,
  setPageScroll as callSetPageScroll,
} from '../../_actions/computer';
import AwesomeCheckbox from '../interface/AwesomeCheckbox';
import TableFilters from '../interface/TableFilters';
import { BootstrapTable, TableHeaderColumn } from './BootstrapTable';
import {
  formatTime,
  getFiltersFromUrl,
  getHelperText,
  getStatusComputersCircleIconClass,
  getStatusComputersColorClass,
  parseDateFromFilter,
  setFiltersToUrl,
  sortFuncDate,
} from './helpers';

import featureFlags from 'src/config/feature-flags';
import history from '../../router/history';
import { getSystemVersion } from '../computer/computerHelpers';
import { HoveredSpan, TableHeader } from '../interface/Base';
import HollowDropdown from '../interface/HollowDropdown';
import SearchString from '../interface/SearchString';
import CircleButton from '../interface/buttons/CircleButton';
import { Tooltip } from '../interface/tooltips/Tooltip';
import Table from './Table';
import { renderTableActions } from './utils';

const classNames = require('classnames');
const queryString = require('query-string');

const FakeShadow = styled('div')`
  height: 20px;
  //background-image: linear-gradient(to bottom, rgb(240, 242, 247), rgba(0, 0, 0, 0));
  width: calc(100% + 20px);
  margin-bottom: -10px;
  position: sticky;
  top: ${(props) => props.top}px;
  margin-left: -10px;
  margin-right: -10px;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: calc(272px + 50px);
`;

const DropdownStyledToggle = styled(CircleButton)`
  background: white;
  border: 1px solid ${colors['grey-200']};
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  z-index: 3;
  position: absolute;
`;

const StyledCheckbox = styled(AwesomeCheckbox)`
  margin: 16px 20px;
`;

const TablePanelWrapper = styled.section`
  background-color: ${colors['button-secondary-new-bg']};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
  padding: 5px 10px 13px 10px;
  color: ${colors['grey-500']};
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

const CurrentViewWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const CurrentViewTitle = styled.div`
  font-weight: 500;
`;

const CurrentViewCaretIcon = styled.i`
  padding-bottom: 2px;
  margin: 0 10px;
  color: ${colors['marengo-700']};
`;

const ResetView = styled.div`
  padding-bottom: 3px;
  border-bottom: 5px solid ${colors['marengo-100']};
  &:hover {
    cursor: pointer;
  }
`;

const defaultView = () => ({
  selectFeature: [
    'id',
    'status',
    'computerName',
    'model',
    'OS',
    'serial',
    'blueprint',
    'assetUser',
    'lastCheckIn',
  ], // DON'T REMOVE ID AND STATUS!!!
  widths: {},
  percentWidths: {},
  sortName: 'computerName',
  sortOrder: 'asc',
  showFilters: false,
});

let isMounted = false;

class ComputersTableMain extends Table {
  static contextType = InterfaceContext;

  constructor(props) {
    super(props);

    const queryParams = queryString.parse(window.location.search, {
      arrayFormat: 'bracket',
    });

    this.serverSideSorting = true;
    this.selectRowId = 'id';
    this.serverSidePagination = true; // Don't remove! It's used on parent
    this.state = {
      ...this.state,
      trigger: 0,
      selectedRows: new Set(),
      currPage: queryParams.page || 1,
      integrationData: {},
      featuresOptions: [
        columnOptions.model,
        columnOptions.serial,
        columnOptions.blueprint,
        columnOptions.lastCheckIn,
        columnOptions.firstEnrollment,
        columnOptions.lastEnrollment,
        columnOptions.assetUser,
        columnOptions.assetTag,
        columnOptions.OS,
        columnOptions.agentVersion,
        columnOptions.is_mdm_enabled,
        columnOptions.is_agent_installed,
      ],
      ...defaultView(),
      showFilters: !!queryParams.viewId,
    };
    this.dragged = null;
    this.fetchFunc = this.fetchComputers.bind(this);
    this.fetchComputers = this.fetchComputers.bind(this);
    this.renderColumnChooser = this.renderColumnChooser.bind(this);
    this.renderExportData = this.renderExportData.bind(this);
    this.renderFilterToggle = this.renderFilterToggle.bind(this);
    this.resizeRow = this.resizeRow.bind(this);
    this.getInitSizeOfColumns = this.getInitSizeOfColumns.bind(this);
    this.toDefaultView = this.toDefaultView.bind(this);
    this.isVisibilityEnabled = true;
  }

  componentDidMount() {
    isMounted = true;
    this.props.onRef(this);
    this.fetchData(false);
    setTimeout(() => this.getInitSizeOfColumns(), 1);
    this.mountEvents();
    this.isVisibilityEnabled = featureFlags.getFlag(
      'visibility-fleet-attributes',
    );
  }

  componentDidUpdate(nextProps, nextState, _nextContext) {
    this.mountEvents();
    if (
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.location, this.props.location)
    ) {
      this.props.needUpdate();
    }
  }

  componentWillUnmount() {
    isMounted = false;
    this.props.onRef(undefined);
  }

  getInitSizeOfColumns() {
    const headers = Array.prototype.slice.call(
      document.querySelectorAll('.resizible-header'),
    );
    const widths = {};
    let totalWidths = 0;
    headers.forEach((header) => {
      widths[header.getAttribute('data-field')] = header.offsetWidth;
      totalWidths += header.offsetWidth;
    });

    const percentWidths = {};
    Object.entries(widths).map((width) => {
      percentWidths[width[0]] = Math.round((width[1] / totalWidths) * 100);
    });

    // Just a tiny cleanup to address React warning, does not change functionality
    /* istanbul ignore next */
    if (isMounted) {
      this.setState({ widths, percentWidths });
    }
  }

  toDefaultView(callback) {
    this.setState({ ...defaultView() }, () => {
      setFiltersToUrl([]);
      this.getInitSizeOfColumns();
      if (callback) {
        callback();
      }
    });
  }

  getCurrentViewState = () => ({
    filters: getFiltersFromUrl(this.props.location),
    selectFeature: this.state.selectFeature,
    widths: this.state.widths,
    percentWidths: this.state.percentWidths,
    sortName: this.state.sortName,
    sortOrder: this.state.sortOrder,
    showFilters: this.state.showFilters,
  });

  updateViewState = (viewState) => {
    this.setState(viewState);
  };

  onChangeBlueprint(e) {
    const { blueprintNames, computers } = this.props;
    e.preventDefault();
    const { selectedRows } = this.state;
    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      const blackListOfBlueprint = [...selectedRows].map((computerId) => {
        const computer = computers.find((comp) => comp.id === computerId);
        if (
          computer &&
          Object.keys(blueprintNames).includes(computer.blueprint_id)
        ) {
          return computer.blueprint_id;
        }
      });
      this.props.setModal('COMPUTER_CHANGE_BLUEPRINT', {
        isNewModal: true,
        computers: [...selectedRows],
        blackListOfBlueprint, // it needs for excluding from select
        fetchComputers: () => this.fetchData(true),
      });
    }
  }

  onEditTags(e) {
    e.preventDefault();
    const { selectedRows } = this.state;

    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      this.props.setModal('COMPUTERS_BULK_EDIT_TAGS', {
        selectedComputerIds: Array.from(selectedRows),
      });
    }
  }

  onManageTags(e) {
    e.preventDefault();

    this.props.setModal('COMPUTERS_MANAGE_TAGS', {});
  }

  onDeleteComputer(e) {
    e.preventDefault();
    const { selectedRows } = this.state;
    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      this.props.setModal('COMPUTER_DELETE', {
        computers: [...selectedRows],
        isNewModal: true,
        blueprintId: this.props.blueprintId,
        fetchComputers: () => {
          // Clear selected rows from state
          this.setState({
            ...this.state,
            selectedRows: new Set(),
          });
          this.fetchData(true);
        },
      });
    }
  }

  getRequestQuery(blueprintId) {
    const { location } = this.props;
    const filters = getFiltersFromUrl(location);
    const options = [];

    if (blueprintId) {
      options.push({
        name: 'blueprint',
        value: [blueprintId],
        operator: 'equals',
      });
    } // For blueprint page

    if (filters) {
      filters.map((filter) => {
        if (!Object.keys(filter).length) {
          return;
        }

        switch (filter.name) {
          case 'blueprint':
          case 'computerName':
          case 'model':
          case 'serial':
          case 'os':
          case 'assetUser':
          case 'assetTag':
          case 'agentVersion': {
            const option = { ...filter };
            options.push(option);
            break;
          }
          case 'status': {
            const option = { ...filter };
            const alertStatus = option.value
              ? option.value.indexOf('WARNING,ERROR')
              : -1;
            if (alertStatus > -1) {
              option.value = [
                ...option.value.filter((i) => i !== 'WARNING,ERROR'),
                'WARNING',
                'ERROR',
              ];
            }
            const passStatus = option.value
              ? option.value.indexOf('PASS,REMEDIATED')
              : -1;
            if (passStatus > -1) {
              option.value = [
                ...option.value.filter((i) => i !== 'PASS,REMEDIATED'),
                'PASS',
                'REMEDIATED',
              ];
            }
            options.push(option);
            break;
          }
          case 'lastCheckIn':
          case 'firstEnrollment':
          case 'lastEnrollment': {
            const option = {
              ...{ name: filter.name, operator: filter.operator },
            };
            if (filter.operator === 'between' && filter.value) {
              const splittedValues = filter.value.split('@');
              const from = parseDateFromFilter(splittedValues[0]).toISOString();
              const to = parseDateFromFilter(splittedValues[1]).toISOString();
              option.value = `${from}@${to}`;
            } else if (filter.operator !== 'between' && filter.value) {
              option.value = parseDateFromFilter(filter.value).toISOString();
            } else {
              option.value = null;
            }
            options.push(option);
            break;
          }
        }
      });
    }
    return options;
  }

  makeQueryParams = () => {
    // Don't remove! It's used on parent
    const { blueprintId, location } = this.props;
    const filters = this.getRequestQuery(blueprintId);
    const queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    const { ordering, sizePerPage, page, search } = queryParams;
    return {
      filters,
      ordering,
      sizePerPage,
      page,
      search,
    };
  };

  fetchComputers(queryParams) {
    const { setComputers, queryComputers } = this.props;

    return queryComputers(queryParams)
      .then((res) => {
        const computers = res.results;
        const { blueprintNames } = this.props;
        const result = computers.map((computer) =>
          annotateComputer(computer, blueprintNames),
        );
        setComputers(result);
        return Promise.resolve(res);
      })
      .catch((err) => console.log(err));
  }

  renderBulkActions() {
    const manageTags = ['Manage tags', this.onManageTags.bind(this), 'pen'];

    const actions = [
      ['Assign Blueprint', this.onChangeBlueprint.bind(this), 'circle-plus'],
      ['Edit tags', this.onEditTags.bind(this), 'pen'],
      ['Delete Devices', this.onDeleteComputer.bind(this), 'trash-can'],
    ];

    if (!this.isVisibilityEnabled) {
      actions.splice(1, 0, manageTags);
    }

    return (
      <HollowDropdown
        buttonTooltipMessage="Bulk Actions"
        buttonOpen={<DropdownStyledToggle icon="ellipsis" fontSize="17px" />}
        options={actions.map((action) => {
          const [actionName, actionFunction, iconClass] = action;
          return (
            <section
              key={camelCase(actionName)}
              className="cursor-pointer new-action-item-design"
              onClick={actionFunction}
            >
              {iconClass && (
                <Icon name={iconClass} aria-hidden="true" size="sm" />
              )}
              {actionName}
            </section>
          );
        })}
      >
        <CircleButton icon="ellipsis" fontSize="17px" />
      </HollowDropdown>
    );
  }

  renderTableFilters() {
    const { tableFilters, arrayPush } = this.props;
    const { showFilters } = this.state;
    if (showFilters) {
      const activeFilters = get(tableFilters, 'values.filters');
      if (!activeFilters || !activeFilters.length) {
        arrayPush('TableFilters', 'filters', {});
      }
    }
    return (
      <TableFilters
        computers={this.state.data}
        blueprintId={this.props.blueprintId}
        location={this.props.location}
        match={this.props.match}
        hidden={!showFilters}
      />
    );
  }

  selectFeatures(value) {
    const { selectFeature, widths } = this.state;

    const localSelectFeature = selectFeature.filter(
      (selected) => selected !== 'status' && selected !== 'id',
    );
    const totalWidth = localSelectFeature
      .map((selected) => widths[selected])
      .reduce((a, b) => a + b);

    const newWidths = {};
    const newPercentWidths = {};

    const pxToPercent = (a) => Math.round((a / totalWidth) * 100);

    if (selectFeature.includes(value)) {
      // if include
      const width = widths[value];
      localSelectFeature.map((selected) => {
        newWidths[selected] =
          widths[selected] + width / (localSelectFeature.length - 1);
        newPercentWidths[selected] = pxToPercent(
          widths[selected] + width / (localSelectFeature.length - 1),
        );
      });
      this.setState({
        selectFeature: selectFeature.filter((v) => v !== value),
        widths: { ...newWidths },
        percentWidths: { ...newPercentWidths },
        sortName: this.state.sortName === value ? 'id' : this.state.sortName,
      });
    } else {
      // if not include
      const avgWidth = Math.floor(totalWidth / (localSelectFeature.length + 1));
      localSelectFeature.map((selected) => {
        const width = widths[selected];
        newWidths[selected] = width - avgWidth / localSelectFeature.length;
        newPercentWidths[selected] = pxToPercent(
          width - avgWidth / localSelectFeature.length,
        );
      });
      this.setState({
        selectFeature: [...selectFeature, value],
        widths: { ...newWidths, [value]: avgWidth },
        percentWidths: { ...newPercentWidths, [value]: pxToPercent(avgWidth) },
      });
    }
  }

  featuresCheckbox(option) {
    const { selectFeature } = this.state;
    const { name, value } = option;
    const disabled = value === 'Computer';
    return (
      <StyledCheckbox
        key={name}
        disabled={disabled}
        keyId={name}
        label={value}
        columnChooser
        checked={selectFeature.includes(name)}
        onCheck={() => this.selectFeatures(name)}
      />
    );
  }

  renderColumnChooser() {
    const { featuresOptions } = this.state;

    return (
      <HollowDropdown
        buttonTooltipMessage="Column Chooser"
        buttonOpen={
          <DropdownStyledToggle icon="table-columns" fontSize="13px" />
        }
        options={featuresOptions.map((option) => this.featuresCheckbox(option))}
      >
        <CircleButton icon="table-columns" fontSize="13px" />
      </HollowDropdown>
    );
  }

  renderExportData() {
    const { isExporting, selectFeature, featuresOptions } = this.state;
    const { exportComputers, location } = this.props;
    const queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    const orderingField = get(queryParams, 'ordering', '');
    let ordering;
    if (orderingField.startsWith('-')) {
      const orderingFieldSliced = orderingField.slice(1);
      ordering = `-${get(
        columnOptions[orderingFieldSliced],
        'backendValue',
        'name',
      )}`;
    } else {
      ordering = get(columnOptions[orderingField], 'backendValue', 'name');
    }
    const exportData = () => {
      const filters = this.getRequestQuery();
      const fields = [
        'name',
        ...selectFeature
          .filter((field) =>
            featuresOptions.find((column) => column.name === field),
          )
          .map((field) =>
            get(
              featuresOptions.find((column) => column.name === field),
              'backendValue',
            ),
          ),
      ];
      this.setState({ isExporting: true }, () => {
        exportComputers(filters, fields, ordering).then(() =>
          this.setState({ isExporting: false }),
        );
      });
    };

    return (
      <Tooltip tooltipMessage="Export CSV">
        <CircleButton
          icon="arrow-down-to-line"
          onClick={exportData}
          isProcessing={isExporting}
          fontSize="13px"
        />
      </Tooltip>
    );
  }

  renderFilterToggle() {
    const { showFilters } = this.state;

    return (
      <Tooltip tooltipMessage="Show Filters">
        <CircleButton
          icon="sliders"
          onClick={() => this.setState({ showFilters: !showFilters })}
          fontSize="16px"
          color={showFilters ? 'white' : null}
          background={showFilters ? colors['button-primary-hover-bg'] : null}
        />
      </Tooltip>
    );
  }

  renderActionButtons = () => null;

  formatStatusRow = (cell, row) => {
    if (!['ERROR', 'WARNING'].includes(row.status) && !row.is_missing) {
      return null;
    }
    const title = getHelperText(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    const iconClass = getStatusComputersCircleIconClass(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    const iconColorClass = getStatusComputersColorClass(
      row.status,
      null,
      row.deferred_install,
      row.is_missing,
    );
    return (
      <div className="d-flex align-items-center justify-content-center height-100">
        <i
          className={`table-row-status-icon ${iconClass} ${iconColorClass}`}
          id={`statusRow${row.id}`}
        />
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`statusRow${row.id}`}
        >
          {title}
        </UncontrolledTooltip>
      </div>
    );
  };

  formatComputerNameRow = (cell, row) => {
    let color;
    if (cell === AwaitingEnrollment) {
      color = colors['grey-300'];
    }
    return (
      <span style={{ color }} title={row.computerName || 'No info found'}>
        {row.computerName || 'No info found'}
      </span>
    );
  };

  formatModelRow = (cell, row) => (
    <span title={row.model || 'No info found'}>
      {row.model || 'No info found'}
    </span>
  );

  /* istanbul ignore next */
  formatBlueprintRow = (cell, row) => {
    const rowBlueprint = this.props.blueprints.find(
      (blueprint) => blueprint.id === row.blueprint_id,
    );
    return (
      <span
        title={row.blueprint || 'No info found'}
        className="underline-cell"
        onClick={() =>
          history.push(paths.getBlueprintRouteByType(rowBlueprint))
        }
      >
        {row.blueprint || 'No info found'}
      </span>
    );
  };

  formatAgentVersionRow = (cell, row) => (
    <span title={row.agentVersion || 'No info found'}>
      {row.agentVersion || 'No info found'}
    </span>
  );

  formatIsMDMEnabledRow = (cell, row) => {
    const text = row.is_mdm_enabled ? 'Yes' : 'No';
    return <span title={text}>{text}</span>;
  };

  formatIsAgentInstalledRow = (cell, row) => {
    if (row.device_family !== 'Mac') {
      return null;
    }
    const text = row.is_agent_installed ? 'Yes' : 'No';
    return <span title={text}>{text}</span>;
  };

  formatOSRow = (cell, row) => {
    const text = row.os_version
      ? getSystemVersion(row, true, true, true, false)
      : row.info
        ? row.info['System Version'].split('(')[0]
        : null;
    return <span title={text || 'No info'}>{text || 'No info'}</span>;
  };

  formatLastEnrollRow = (cell, row) => (
    <HoveredSpan
      hoveredText={row.lastEnrollmentNotFormatted}
      text={row.lastEnrollmentNotFormatted}
    />
  );

  formatFirstEnrollRow = (cell, row) => (
    <HoveredSpan
      hoveredText={row.firstEnrollmentNotFormatted}
      text={row.firstEnrollmentNotFormatted}
    />
  );

  formatLastCheckInRow = (cell, row) => (
    <HoveredSpan
      hoveredText={
        row.lastCheckIn === 'No info'
          ? 'No info'
          : formatTime(row.lastCheckIn, null, null, null, true)
      }
      text={
        row.lastCheckIn === 'No info'
          ? 'No info'
          : formatTime(row.lastCheckIn, null, null, null, false)
      }
    />
  );

  formatLongLengthRow = (cell) => (
    <span title={cell || 'No info found'}>{cell || 'No info found'}</span>
  );

  formatDescriptionRow = (cell, row) => (
    <span title={row.description || 'No info found'}>
      {row.description || 'No info found'}
    </span>
  );

  formatAssignedDateRow = (cell, row) => (
    <span title={row.deviceAssignedDate || 'No info found'}>
      {row.deviceAssignedDate || 'No info found'}
    </span>
  );

  formatEnrollmentStatusRow = (cell, row) => (
    <span title={row.enrollmentStatus || 'No info found'}>
      {row.enrollmentStatus || 'No info found'}
    </span>
  );

  formatColorRow = (cell, row) => (
    <span title={row.color || 'No info found'}>
      {row.color || 'No info found'}
    </span>
  );

  formatDeviceFamilyRow = (cell, row) => (
    <span title={row.deviceFamily || 'No info found'}>
      {row.deviceFamily || 'No info found'}
    </span>
  );

  formatDeviceAssignedByRow = (cell, row) => (
    <span title={row.deviceAssignedBy || 'No info found'}>
      {row.deviceAssignedBy || 'No info found'}
    </span>
  );

  formatAssetUser = (cell, row) => {
    const title = get(row, 'user.name', null);
    const representationTitle = title === null ? null : title;
    return <span title={representationTitle}>{representationTitle}</span>;
  };

  formatAssetTag = (cell, row) => (
    <span title={row.assetTag === 'No info' ? null : row.assetTag}>
      {row.assetTag === 'No info' ? null : row.assetTag}
    </span>
  );

  formatDuplicatesRow = (cell, row) => {
    if (row.duplicates && row.duplicates.length > 0) {
      let title = 'Duplicates:\n';
      row.duplicates.map((el) => (title += `${el.name} - ${el.id}\n`));
      return (
        <div>
          <Icon
            id={`duplicatesRow${row.id}`}
            name="octagon-exclamation"
            className="c-dark-red"
          />
          <UncontrolledTooltip
            placement="top"
            delay={{ show: 400, hide: 0 }}
            innerClassName="custom-helper"
            target={`duplicatesRow${row.id}`}
          >
            {title}
          </UncontrolledTooltip>
        </div>
      );
    }
  };

  /* istanbul ignore next */
  renderLineLoader = () => <LineLoader isDelayed />;

  // Ignoring since testing drag and drop only works in a real browser
  // See: https://testing-library.com/docs/example-drag/
  /* istanbul ignore next */
  mountEvents() {
    const headers = Array.prototype.slice.call(
      document.querySelectorAll('.draggable-header'),
    );

    if (this.state.resizing) {
      headers.forEach((header) => {
        header.setAttribute('draggable', false);
      });
    } else {
      headers.forEach((header, i) => {
        header.setAttribute('draggable', true);

        // the dragged header
        header.ondragstart = (e) => {
          e.dataTransfer.setData('Text', this.id);
          e.stopPropagation();
          this.dragged = i + 2; // + 2 for `status` and `computerName` BOOKMARK
        };

        header.ondrag = (e) => {
          e.stopPropagation();
        };

        header.ondragend = (e) => {
          e.stopPropagation();
        };

        // the dropped header
        header.ondragover = (e) => {
          e.preventDefault();
        };

        header.ondrop = (e) => {
          e.preventDefault();

          const newSelectFeature = [...this.state.selectFeature];
          newSelectFeature.splice(
            i + 2,
            0,
            newSelectFeature.splice(this.dragged, 1)[0],
          );
          this.setState({ selectFeature: newSelectFeature });
        };
      });
    }
  }

  // Ignoring since testing drag and drop only works in a real browser
  // See: https://testing-library.com/docs/example-drag/
  /* istanbul ignore next */
  resizeRow(lastX, deltaX, dataField, nextDataField) {
    const minWidth = 50;
    this.setState((prevState) => {
      const prevWidths = prevState.widths;
      const prevPercentWidths = prevState.percentWidths;
      const { selectFeature } = prevState;

      const dataFieldWidth = prevWidths[dataField];
      const nextDataFieldWidth = prevWidths[nextDataField];

      const totalWidth = selectFeature
        .filter((item) => item !== 'status' && item !== 'id')
        .map((item) => prevWidths[item])
        .reduce((a, b) => a + b);

      const pxToPercent = (a) => Math.round((a / totalWidth) * 100);

      const columnWidths = () => {
        if (!(deltaX + dataFieldWidth > minWidth)) {
          return [prevWidths[dataField], prevWidths[nextDataField]];
        }
        if (!(nextDataFieldWidth - deltaX > minWidth)) {
          return [prevWidths[dataField], prevWidths[nextDataField]];
        }
        return [dataFieldWidth + deltaX, nextDataFieldWidth - deltaX];
      };

      return {
        widths: {
          ...prevWidths,
          [dataField]: columnWidths()[0],
          [nextDataField]: columnWidths()[1],
        },
        percentWidths: {
          ...prevPercentWidths,
          [dataField]: pxToPercent(columnWidths()[0]),
          [nextDataField]: pxToPercent(columnWidths()[1]),
        },
      };
    });
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({ currPage: 1 });
    this.changeFilterInUrl({
      ordering: this.getOrdering(sortName, sortOrder),
      page: 1,
    });
  };

  getOrdering = (sortName, sortOrder) =>
    sortOrder === 'desc' ? `-${sortName}` : sortName;

  render() {
    const {
      computers,
      showPaginationOnTop,
      isViewChanged,
      userSettings,
      acceptView,
      activeViewId,
      location,
      permissions,
    } = this.props;
    const {
      selectFeature: selectFeatureState,
      sortName,
      sortOrder,
      percentWidths,
      currPage,
      resizing,
      isLoading,
      showFilters,
    } = this.state;
    const { bannerTopOffset } = this.context;

    const selectFeature = omitVulnColumns(selectFeatureState);

    /* istanbul ignore next */
    const openComputer = (row) => {
      const lostModeActiveStates = ['PENDING', 'ENABLED', 'ERRORED'];
      this.props.setPageScroll(window.scrollY);
      if (lostModeActiveStates.includes(row.lost_mode_status)) {
        return history.push(`${links.devices}/${row.id}/lost-mode`);
      }
      history.push(`${links.devices}/${row.id}/status`);
    };
    const defaultSortFieldName = () =>
      selectFeature.includes('computerName') ? 'computerName' : 'id';
    const savedViews = get(userSettings, 'saved_views.computers_list', []);
    const allViews = [
      ...KandjiViews.filter(({ isHidden }) => !isHidden),
      ...savedViews,
    ];
    const activeView = allViews.find((view) => view.id === activeViewId);
    const activeViewName = activeView ? activeView.name : 'All Devices';
    const options = {
      ...this.defaultOptions,
      onRowClick: (row, columnIndex) => openComputer(row),
      sortName: sortName || defaultSortFieldName(),
      defaultSortName: sortName || defaultSortFieldName(),
      sortOrder: sortOrder || 'asc',
      onSortChange: this.onSortChange.bind(this),
      page: currPage,
      resultsString: 'Devices',
      showPaginationOnTop,
      noDataView: isLoading ? this.renderLineLoader() : this.props.noDataView,
      toolBar: this.renderActionButtons,
    };

    delete options.toolBar;
    delete options.btnGroup;

    const columns = {
      id: {
        dataField: 'id',
        isKey: true,
        hidden: true,
        searchable: false,
      },
      status: {
        dataField: 'status',
        dataAlign: 'right',
        dataFormat: this.formatStatusRow.bind(this),
        columnClassName: 'td-icon td-status-icon',
        className: 'td-icon',
        width: '15px',
        searchable: false,
      },
      computerName: {
        dataField: 'computerName',
        dataSort: true,
        sortName: 'name',
        dataFormat: this.formatComputerNameRow,
        children: 'Device Name',
      },
      model: {
        dataField: 'model',
        dataSort: true,
        sortName: 'model',
        dataFormat: this.formatModelRow,
        children: 'Model',
        searchable: false,
      },
      lastCheckIn: {
        dataField: 'lastCheckIn',
        dataSort: true,
        sortName: 'last_check_in',
        dataFormat: this.formatLastCheckInRow,
        children: 'Last Checked In',
        searchable: false,
        sortFunc: sortFuncDate,
      },
      serial: {
        dataField: 'serial',
        dataSort: true,
        sortName: 'serial_number',
        dataFormat: this.formatLongLengthRow,
        children: 'Serial',
      },
      blueprint: {
        dataField: 'blueprint',
        dataSort: true,
        sortName: 'blueprint',
        dataFormat: this.formatBlueprintRow,
        children: 'Blueprint',
        searchable: false,
        thStyle: { minWidth: 150 },
        tdStyle: { minWidth: 150 },
      },
      firstEnrollment: {
        dataField: 'firstEnrollment',
        dataSort: true,
        sortName: 'first_enrolled_at',
        dataFormat: this.formatFirstEnrollRow,
        children: 'First Enrollment',
        searchable: false,
        sortFunc: sortFuncDate,
      },
      lastEnrollment: {
        dataField: 'lastEnrollment',
        dataSort: true,
        sortName: 'enrolled_at',
        dataFormat: this.formatLastEnrollRow,
        children: 'Last Enrollment',
        searchable: false,
        sortFunc: sortFuncDate,
      },
      assetUser: {
        dataField: 'assetUser',
        dataFormat: this.formatAssetUser,
        dataSort: true,
        sortName: 'user__name',
        children: 'User',
        searchable: false,
      },
      assetTag: {
        dataField: 'assetTag',
        dataFormat: this.formatAssetTag,
        dataSort: true,
        sortName: 'asset_tag',
        children: 'Asset Tag',
        searchable: false,
      },
      OS: {
        dataField: 'OS',
        dataFormat: this.formatOSRow,
        dataSort: true,
        sortName: 'os_version',
        children: 'OS',
        searchable: false,
      },
      is_mdm_enabled: {
        dataField: 'is_mdm_enabled',
        dataFormat: this.formatIsMDMEnabledRow,
        dataSort: true,
        children: 'MDM Enabled',
        searchable: false,
      },
      is_agent_installed: {
        dataField: 'is_agent_installed',
        dataFormat: this.formatIsAgentInstalledRow,
        dataSort: true,
        children: 'Agent Installed',
        searchable: false,
      },
      agentVersion: {
        dataField: 'agentVersion',
        dataFormat: this.formatAgentVersionRow,
        dataSort: true,
        sortName: 'agent_version',
        children: 'Agent',
        searchable: false,
      },
      duplicates: {
        dataField: 'duplicates',
        dataFormat: this.formatDuplicatesRow,
        columnClassName: 'td-icon',
        className: 'td-icon',
        searchable: false,
      },
      description: {
        dataField: 'description',
        dataFormat: this.formatDescriptionRow,
        dataSort: true,
        children: 'Description',
        searchable: false,
      },
      assigned: {
        dataField: 'device_assigned_date',
        dataFormat: this.formatAssignedDateRow,
        dataSort: true,
        children: 'Assigned to Kandji',
        searchable: false,
      },
      enrollmentStatus: {
        dataField: 'enrollment_status',
        dataFormat: this.formatEnrollmentStatusRow,
        dataSort: true,
        children: 'Enrollment Status',
      },
      color: {
        dataField: 'color',
        dataFormat: this.formatColorRow,
        dataSort: true,
        children: 'Color',
      },
      deviceFamily: {
        dataField: 'device_family',
        dataFormat: this.formatDeviceFamilyRow,
        dataSort: true,
        children: 'Device Family',
      },
      deviceAssignedBy: {
        dataField: 'device_assigned_by',
        dataFormat: this.formatDeviceAssignedByRow,
        dataSort: true,
        children: 'Device Assigned By',
      },
    };

    const colTotal = selectFeature.map((item, index) => {
      const col = columns[item];
      if (
        index === selectFeature.length - 1 ||
        col.dataField === 'status' ||
        col.dataField === 'computerName'
      ) {
        return {
          ...col,
          className: classNames(col.className, {
            'header-column-border-right': col.dataField === 'computerName',
            // 'resizible-header': index !== selectFeature.length - 1 || col.dataField !== 'status',
            'resizible-header': col.dataField !== 'status',
            'draggable-header':
              col.dataField !== 'status' && col.dataField !== 'computerName',
          }),
          columnClassName: classNames(col.columnClassName, {
            'column-border-right': col.dataField === 'computerName',
          }),
          children: (
            <>
              <div className="header-column-content">
                {col.dataField !== 'computerName' &&
                  col.dataField !== 'status' && (
                    <div className="drag-icon">
                      <Icon
                        name="grip-dots-vertical"
                        className="drag-icon mr-1"
                      />
                    </div>
                  )}
                {col.tooltip ? (
                  <Tippy
                    content={col.tooltip}
                    theme="vuln"
                    interactive
                    appendTo={document.body}
                  >
                    <div className="b-flex b-flex-align-center">
                      {col.children}{' '}
                      <Icon className="b-ml-micro" name="circle-info" />
                    </div>
                  </Tippy>
                ) : (
                  col.children
                )}
              </div>
              {col.dataField !== 'status' &&
                index !== selectFeature.length - 1 && (
                  <Draggable
                    axis="x"
                    defaultClassName="DragHandle"
                    defaultClassNameDragging="DragHandleActive"
                    onDrag={(event, { lastX, deltaX }) => {
                      this.setState({ resizing: true });
                      this.resizeRow(
                        lastX,
                        deltaX,
                        col.dataField,
                        selectFeature[index + 1],
                      );
                    }}
                    onStop={() =>
                      setTimeout(() => this.setState({ resizing: false }), 1)
                    }
                    position={{ x: 0 }}
                    zIndex={9999}
                  >
                    <div className="resizer-wrapper">
                      {col.dataField !== 'status' && (
                        <span
                          className="resizer"
                          style={{
                            marginRight:
                              col.dataField === 'computerName' ? '-1px' : null,
                          }}
                        />
                      )}
                    </div>
                  </Draggable>
                )}
            </>
          ),
          thStyle: {
            ...col.thStyle,
            position: 'relative',
            color: '#666',
          },
          tdStyle: {
            ...col.tdStyle,
          },
          width:
            percentWidths && percentWidths[col.dataField]
              ? `${percentWidths[col.dataField]}%`
              : col.width,
          resizing,
        };
      }
      return {
        ...col,
        className: classNames(col.className, 'computers-table-column', {
          'first-after-line-column': index === 3,
          'resizible-header': col.dataField !== 'status',
          'draggable-header':
            col.dataField !== 'name' && col.dataField !== 'status',
        }),
        columnClassName: classNames(col.columnClassName, {
          'first-after-line-column': index === 3,
        }),
        children: (
          <>
            <div className="header-column-content">
              {col.dataField !== 'computerName' &&
                col.dataField !== 'status' &&
                index !== selectFeature.length - 1 && (
                  <div className="drag-icon">
                    <Icon
                      name="grip-dots-vertical"
                      className="drag-icon mr-1"
                    />
                  </div>
                )}
              {col.tooltip ? (
                <Tooltip tooltipMessage={col.tooltip}>{col.children}</Tooltip>
              ) : (
                col.children
              )}
            </div>
            <Draggable
              axis="x"
              defaultClassName="DragHandle"
              defaultClassNameDragging="DragHandleActive"
              onDrag={(event, { lastX, deltaX }) => {
                this.setState({ resizing: true });
                this.resizeRow(
                  lastX,
                  deltaX,
                  col.dataField,
                  selectFeature[index + 1],
                );
              }}
              onStop={() =>
                setTimeout(() => this.setState({ resizing: false }), 1)
              }
              position={{ x: 0 }}
              zIndex={9999}
            >
              <div className="resizer-wrapper">
                {col.dataField !== 'status' && <span className="resizer" />}
              </div>
            </Draggable>
          </>
        ),
        thStyle: {
          ...col.thStyle,
          position: 'relative',
        },
        tdStyle: {
          ...col.tdStyle,
          position: 'relative',
        },
        width:
          percentWidths && percentWidths[col.dataField]
            ? `${percentWidths[col.dataField]}%`
            : col.width,
        resizing,
      };
    });
    // todo: refactor top calculation, prevent hardcode, make it uniform
    const filtersOffset = showFilters
      ? get(this.tableFiltersBlock, 'clientHeight', 0)
      : 0;
    const viewPanelOffset = get(this.viewPanelBlock, 'clientHeight', 0);
    const URLparams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });

    return (
      <Wrapper>
        <TableHeader title="Devices" paddingBottom="14px" sticky>
          <SearchString
            defaultValue={URLparams.search}
            label="Search Devices..."
            searchFunc={debounce(
              (searchString) =>
                this.changeFilterInUrl({ search: searchString, page: 1 }),
              500,
            )}
            iconPosition="right"
          />
          {renderTableActions([
            this.renderFilterToggle(),
            this.renderColumnChooser(),
            this.renderExportData(),
            permissions.canManageDevices && this.renderBulkActions(),
          ])}
        </TableHeader>
        <section
          className="kandji-position-sticky"
          style={{
            top: bannerTopOffset + 80,
            zIndex: 23,
            backgroundColor: 'var(--color-neutral-10)',
          }}
          ref={(node) => {
            this.tableFiltersBlock = node;
          }}
        >
          {this.renderTableFilters()}
        </section>
        <TablePanelWrapper
          className="kandji-position-sticky"
          style={{
            top: bannerTopOffset + 80 + filtersOffset,
            zIndex: 22,
            backgroundColor: 'var(--color-neutral-10)',
          }}
          ref={(node) => {
            this.viewPanelBlock = node;
          }}
        >
          <CurrentViewWrapper>
            Current View
            <CurrentViewCaretIcon className="fas fa-caret-right" />
            <CurrentViewTitle>{activeViewName}</CurrentViewTitle>
          </CurrentViewWrapper>
          {isViewChanged && (
            <ResetView
              onClick={() =>
                activeViewId ? acceptView(activeViewId) : this.toDefaultView()
              }
            >
              Reset View
            </ResetView>
          )}
        </TablePanelWrapper>
        <FakeShadow
          top={bannerTopOffset + 80 + viewPanelOffset + filtersOffset}
        />
        <BootstrapTable
          ref={(node) => (this.table = node)}
          data={computers}
          version="4"
          // search
          remote={() => ({
            pagination: true,
          })}
          searchPlaceholder="Quick Search..."
          options={options}
          pagination
          selectRow={this.selectRowProp}
          trClassName="table-row-clickable"
          tableContainerClass="bst-borderless h-auto"
          containerClass="bst-borderless old-table"
          bodyStyle={{ borderBottom: '1px solid #dee2e6' }}
          headerContainerClass="kandji-position-sticky"
          containerStyle={{
            marginBottom: '110px',
          }}
          headerStyle={{
            zIndex: 2,
          }}
          tableStyle={{
            width: `${Math.max((colTotal.length - 2) * 13, 100)}%`,
          }}
          scrollable={colTotal.length - 2 > 5}
          activeViewId={activeViewId}
        >
          {colTotal.map((column) => (
            <TableHeaderColumn key={uuidv4()} {...column} />
          ))}
        </BootstrapTable>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  userSettings: state.account.user.settings,

  computers: state.data.computers,
  scrollY: state.data.scrollY,
  blueprints: state.data.blueprints,
  blueprintNames: state.data.blueprintNames,
  tableFilters: state.form.TableFilters,
  allComputers: state.filters.computers,
  feature_configuration: state.account?.company?.feature_configuration,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
      setSnackbar,
      setComputers: callSetComputers,
      setPageScroll: callSetPageScroll,
      updateBlueprint,
      queryComputers: callQueryComputers,
      initialize,
      exportComputers: callExportComputers,
      updateUser,
      arrayPush: callArrayPush,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(ComputersTableMain));
