import type {
  ColumnDef,
  ColumnResizeMode,
  InitialTableState,
  TableMeta,
  TableOptions,
  TableState,
} from '@tanstack/react-table';

import {
  PRISM_CONTENT_HEIGHT,
  PRISM_TABLE_HEIGHT,
} from 'src/features/visibility/prism/utils/constants';

export type TableConfigColumnDef<Data> = ColumnDef<Data> & {
  id: string;
};

export type PersistedTableColumns = Array<{
  name: string;
  visible: boolean;
  filter: Record<string, unknown>;
  size: number;
}>;

export interface OnPersistTableStateChange {
  <OtherParams extends object>(params: Partial<TableState> & OtherParams): void;
}

export interface TablePersistenceInitializer<TableData = unknown> {
  (args: {
    config: TableConfig<TableData>;
    columnMetaDefaultDefs: TableConfigColumnDef<TableData>[];
  }): {
    onInitialTableState?: (
      initialStateUpdater: (arg: {
        config: TableConfig<TableData>;
        columnMetaDefaultDefs: TableConfigColumnDef<TableData>[];
        persistedColumns: PersistedTableColumns;
      }) => readonly [
        {
          default: Partial<TableState>;
          persisted: Partial<TableState>;
        },
        () => void,
      ],
    ) => {
      default: Partial<TableState>;
      persisted: Partial<TableState>;
    };
    onPersistTableStateChange?: OnPersistTableStateChange;
  };
}

interface TableConfig<TableData = unknown> extends TableOptions<TableData> {
  /**
   * The array of object row data to be displayed in the table. Row object
   * property keys must match a unique `id` property of the `columns` column
   * definition in order to be displayed.
   */
  data: TableData[];
  /**
   * Array of column definitions to configure the table columns. Each column
   * definition must have a unique `id` property that matches a property key in
   * the `data` row objects.
   */
  columns: TableConfigColumnDef<TableData>[];
  /**
   * The initial table state on first table load. Any resetting of table state
   * will revert to this state. If using `tablePersistence`, the initial state
   * will be automatically set to the column defaults determined by the
   * {@link ColumnDef | column definitions}, their respective
   * {@link _ColumnMeta | `meta` properties} merged with any relevant
   * {@link _TableMeta | table `meta`} properties.
   * @default {}
   */
  initialState?: InitialTableState;
  /**
   * Allows column resizing based on `columnResizeMode` behavior.
   * @default true
   */
  enableColumnResizing: boolean;
  /**
   * If `enableColumnResizing` is `true`, configures committing resizing updates
   * on change/mouse drag or only on end/mouse up.
   * @default 'onChange'
   */
  columnResizeMode: ColumnResizeMode;
  /**
   * Determines if filtering should be handled by the server if `true` or by the
   * client if `false`.
   * @default false
   */
  manualFiltering: boolean;
  /**
   * Determines if pagination should be handled by the server if `true` or by the
   * client if `false`.
   * @default false
   */
  manualPagination: boolean;
  /**
   * The total number of pages in the table. Required if `manualPagination` is
   * `true`. Use `-1` if total number of pages is unknown.
   * @default -1
   */
  pageCount: number;
  /**
   * Determines the default column definition for all columns. Useful for setting
   * default column properties like `header`/`cell`/`footer` renderers and
   * column UI properties like `size`/`minSize`/`maxSize`.
   * @default {}
   */
  defaultColumn: Partial<ColumnDef<TableData, unknown>>;
  /**
   * Table metadata for applying global column defaults to
   * column definitions:

   * * Fixed/frozen columns: Pass `fixedColumns` as object of column id
   * key/`true` boolean key-value pairs into `meta` to fix/freeze those
   * columns. Automatically sets `enableHiding` to `false` for those column
   * definitions.
   * * Always hidden columns: Pass `alwaysHiddenColumns` as object of column id
   * key/`true` boolean key-value pairs into `meta` to never visually show
   * those columns, even if `alwaysHidden` is not set to `true` on the column
   * definition. Automatically sets `meta.alwaysHidden` to `true` for those
   * columns definitions.
   *
   * Enable additional table features through the `meta.use` property:
   *
   * * Table/column state persistence: Pass `tablePersistence` created by
   * calling {@link createTablePersistence | `createTablePersistence`} into
   * `meta.use` to persist table state changes and initialize table state with
   * any previously stored state in local or session storage.
   *
   * State values and setters for:
   * * `isDefaultDirty/setIsDefaultDirty`: Whether the table state has changed
   * from the default state as defined by its column definitions for column
   * order, visibility, sizing, etc.
   * @default {}
   */
  meta: TableMeta<TableData> & {
    use?: { tablePersistence?: TablePersistenceInitializer<TableData> };
    isDefaultDirty: boolean;
    setIsDefaultDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

export function getColumnDefinitionDefaults(columnDefs, viewColumns) {
  if (viewColumns && viewColumns.length) {
    return viewColumns.reduce(
      (memo, column) => {
        const { columnVisibility, columnOrder, columnSizing } = memo;
        columnVisibility[column.name] = !!column.visible;
        columnOrder.push(column.name);
        columnSizing[column.name] = column.size;

        return { columnVisibility, columnOrder, columnSizing };
      },
      { columnVisibility: {}, columnOrder: [], columnSizing: {} },
    );
  }

  return columnDefs.reduce(
    (memo, column) => {
      const { columnVisibility, columnOrder, columnSizing } = memo;
      columnVisibility[column.id] =
        column.meta?.defaultHidden !== true &&
        column.meta?.alwaysHidden !== true;
      columnOrder.push(column.id);
      columnSizing[column.id] = column.size;

      return { columnVisibility, columnOrder, columnSizing };
    },
    {
      columnVisibility: {},
      columnOrder: [],
      columnSizing: {},
    },
  );
}

export function getTableCss() {
  return {
    container: {
      minWidth: 350,
      height: PRISM_CONTENT_HEIGHT,
    },
    toolbar: {
      background: '$neutral0',
      height: 'fit-content',
      pb: '$3',
      width: '100%',
    },
    tableContainer: {
      flexBasis: 0,
      maxHeight: PRISM_TABLE_HEIGHT,
      bb: '$neutral30',
    },
    table: {
      // use bottom border from scroll container instead
      ':where(tbody) tr:last-of-type': {
        borderBottom: 'none',
      },

      // overrides anchor resets from `_reboot.scss`
      ':where(th, td) a': {
        color: '$neutral90',
      },
    },
    paginationContainer: {
      width: '100%',
      p: '$2 0',
      mt: '5px',
    },
  };
}

export function validateViewName(
  name: string,
  existingNames: string[],
): string {
  if (name.length === 0) {
    return 'Required';
  }
  if (existingNames.includes(name)) {
    return 'Name already in use. Please enter a new one.';
  }
  return '';
}
