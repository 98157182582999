import { formatDate } from './_helpers';

export default (activity) => [
  {
    label: 'Accessed by',
    values: [activity.actor_name || activity.ip || '-'],
  },
  {
    label: 'Accessed at',
    values: [formatDate(activity)],
  },
  {
    label: 'Endpoints accessed',
    values: [activity.data.endpoint.join(' /')],
  },
];
