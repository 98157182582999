import {
  Flex,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import CertificateService from '../../service/certificate-service';

const sanTypeOptions = [
  // {
  //   value: CertificateService.sanTypes.NONE,
  //   label: 'None',
  // },
  {
    value: CertificateService.sanTypes.DNS,
    label: 'DNS name',
  },
  {
    value: CertificateService.sanTypes.RFC_822,
    label: 'RFC 822 Name',
  },
  {
    value: CertificateService.sanTypes.URI,
    label: 'Uniform Resource Identifier',
  },
  {
    value: CertificateService.sanTypes.UPN,
    label: 'User Principal Name',
  },
];

const SanRaw = (props) => {
  const { setting, updateValidation, validationDep, isDisabled, update } =
    props;
  const { type, value, _id } = setting;
  const fieldsToValidate = [`san-type_${_id}`, `san-value_${_id}`];
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    updateValidation,
  );
  useEffect(() => {
    onInvalidate(fieldsToValidate[0])(!type);
  }, [type]);
  useRemoveValidationOnUnmount(fieldsToValidate, updateValidation);
  const isSubmitted = useIsChanged(validationDep);

  const selectedTypeOption = sanTypeOptions.find(
    (option) => option.value === type,
  );

  return (
    <div ref={refs[0]} className="k-cert-li__san-inputs">
      <div className="b-txt b-mb1" ref={refs[1]}>
        SAN type
      </div>
      <Flex className="react-select-test" gapType="gap2">
        <Select
          isDisabled={isDisabled}
          options={sanTypeOptions}
          compact
          onChange={(v) => update('type', v.value)}
          value={selectedTypeOption}
          // menuPortalTarget={document.body}
          errorText={isSubmitted && !type ? 'Required' : ''}
          testId="san-raw"
        />
        <TextInput
          disabled={isDisabled}
          value={value}
          onChange={(e) => update('value', e.target.value)}
          compact
          placeholder="Enter the subject alternative name"
          validator={(v) => [
            onEmptyBlurValidator(v, {
              trigger: ['onBlur', validationDep],
            }),
          ]}
          onInvalidate={onInvalidate(fieldsToValidate[1])}
          data-testid="san-value"
        />
      </Flex>
    </div>
  );
};

export default SanRaw;
