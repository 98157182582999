import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import { getEnterpriseApi } from 'app/api/domains';
import React from 'react';
import './view-integration.styles.scss';

function ViewIntegrationExtra() {
  const apiUrl = new URL('api/v1/scim', getEnterpriseApi()).href;

  return (
    <div className="view-scim-int-extra_wrapper">
      <p className="b-txt b-mb1">The SCIM API URL is:</p>
      <div className="b-flex b-flex-align-center">
        <div className="view-scim-int-extra_api-url">{apiUrl}</div>

        <Button
          kind="link"
          icon="copy"
          className="view-scim-int-extra_copy-btn"
          onClick={() =>
            navigator.clipboard
              .writeText(apiUrl)
              .then(() => toaster('API URL was copied to your clipboard'))
              .catch((e) => {
                console.log(e);
                toaster('Could not copy API URL, please try doing it manually');
              })
          }
        />
      </div>
    </div>
  );
}

export default ViewIntegrationExtra;
