/* istanbul ignore file */
import featureFlags from 'src/config/feature-flags';

const orderScreensById = (screens) =>
  screens().reduce((screensById, screen) => {
    screensById[screen.id] = screen;
    return screensById;
  }, {});

export const MAC_SKIP_SCREENS = () => {
  const LDFFwwdc23ADESkipEnableLockdownMode = featureFlags.getFlag(
    'lit_09192023_wwdc23-ade-skip-enable-lockdown-mode',
  );

  return [
    {
      id: 'Accessibility',
      label: 'Accessibility',
      description:
        'Skips the Accessibility pane, only if the Mac is connected to Ethernet and the cloud config is downloaded.',
      requirements: ['macOS 10.11+'],
      isSelected: false,
    },
    {
      id: 'Appearance',
      label: 'Appearance',
      description: 'Users will not be prompted to configure Light/Dark Mode.',
      requirements: ['macOS 10.14+'],
      isSelected: false,
    },
    {
      id: 'AppleID',
      label: 'Apple Account',
      description:
        'Users will not be prompted to sign in with their Apple Account.',
      requirements: ['macOS 10.9+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Payment',
      label: 'Apple Pay',
      description: 'Users will not be prompted to configure Apple Pay.',
      requirements: ['macOS 10.12.4+'],
      isSelected: false,
    },
    {
      id: 'Biometric',
      label: 'Biometric',
      description: 'Users will not be prompted configure Touch ID.',
      requirements: ['macOS 10.12.4+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Diagnostics',
      label: 'Diagnostics',
      description:
        'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
      requirements: ['macOS 10.9+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    ...(LDFFwwdc23ADESkipEnableLockdownMode
      ? [
          {
            id: 'EnableLockdownMode',
            label: 'Enable Lockdown Mode',
            description:
              'Users will not be prompted to configure the Lockdown Mode pane if an Apple Account/iCloud account is set up.',
            requirements: ['macOS 14.0+'],
            isSelected: false,
          },
        ]
      : []),
    {
      id: 'FileVault',
      label: 'FileVault',
      description:
        'Users will not be prompted to configure FileVault disk encryption.',
      requirements: ['macOS 10.10+'],
      isSelected: false,
    },
    {
      id: 'iCloudDiagnostics',
      label: 'iCloud Analytics',
      description:
        'Users will not be prompted to share iCloud usage and data details with Apple. When skipped, analytics information is not shared with Apple by default.',
      requirements: ['macOS 10.12.4+'],
      isSelected: false,
    },
    {
      id: 'iCloudStorage',
      label: 'iCloud Storage',
      description:
        'Users will not be prompted to configure the iCloud Desktop & Documents Folders sync feature. When skipped, this feature will be turned off.',
      requirements: ['macOS 10.13.4+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Location',
      label: 'Location Services',
      description:
        'Users will not be prompted to configure Location Services. When skipped, Location Services will be turned off.',
      requirements: ['macOS 10.11+'],
      isSelected: false,
    },
    {
      id: 'Privacy',
      label: 'Privacy',
      description:
        'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
      requirements: ['macOS 10.13.4+', 'STIG/SRG'],
      isSelected: false,
    },
    /* To be added -> */
    {
      id: 'UnlockWatch',
      label: 'Unlock with Watch',
      description:
        'Users will not be prompted to configure unlocking the Mac with Apple Watch. When skipped, the Mac will not unlock with Apple Watch unless the user configures it in System Preferences.',
      requirements: ['macOS 10.12+'],
      isSelected: false,
    },
    {
      id: 'Restore',
      label: 'Restore',
      description: 'Users will not be prompted to restore from a backup.',
      requirements: ['macOS 10.9+'],
      isSelected: false,
    },
    {
      id: 'ScreenTime',
      label: 'Screen Time',
      description:
        'Users will not be prompted to configure the Screen Time feature.',
      requirements: ['macOS 10.15+'],
      isSelected: false,
    },
    {
      id: 'Siri',
      label: 'Siri',
      description:
        'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
      requirements: ['macOS 10.12+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'TOS',
      label: 'Terms and Conditions',
      description:
        'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
      requirements: ['macOS 10.9+'],
      isSelected: false,
    },
    {
      id: 'TermsOfAddress',
      label: 'Terms of Address',
      description:
        'Users will not be prompted to set up Terms of Address. Will only be skipped if the Mac is on Ethernet.',
      requirements: ['macOS 13.0+'],
      isSelected: false,
    },
    {
      id: 'DisplayTone',
      label: 'True Tone Display',
      description:
        'Users will not be prompted to configure the True Tone Display feature.',
      requirements: ['macOS 10.13.6+'],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'Registration',
    //   label: 'Registration',
    //   description:
    //     'Users will not be prompted to register the device with Apple. When skipped, the device will not be registered. This does not impact AppleCare warranty coverage.',
    //   requirements: [],
    //   isSelected: false,
    // },
  ];
};

export const APPLE_TV_SKIP_SCREENS = () => [
  {
    id: 'AppleID',
    label: 'Apple Account',
    description:
      'Users will not be prompted to sign in with their Apple Account.',
    requirements: ['tvOS 10.2+', 'NIST', 'STIG/SRG'],
    isSelected: false,
  },
  {
    id: 'Diagnostics',
    label: 'Diagnostics',
    description:
      'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
    requirements: ['tvOS 10.2+', 'NIST', 'STIG/SRG'],
    isSelected: false,
  },
  {
    id: 'Privacy',
    label: 'Privacy',
    description:
      'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
    requirements: ['tvOS 11.13+', 'STIG/SRG'],
    isSelected: false,
  },
  {
    id: 'ScreenSaver',
    label: 'Screen Saver',
    description:
      'Skips the tvOS screen about using aerial screensavers in ATV.',
    requirements: ['tvOS 10.2+'],
    isSelected: false,
  },
  {
    id: 'Siri',
    label: 'Siri',
    description:
      'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
    requirements: ['tvOS 10.2+', 'NIST', 'STIG/SRG'],
    isSelected: false,
  },
  {
    id: 'TapToSetup',
    label: 'Tap To Set Up',
    description:
      'Skips the Tap To Set Up option in ATV about using an iOS device to set up your ATV.',
    requirements: ['tvOS 10.2+'],
    isSelected: false,
  },
  {
    id: 'TOS',
    label: 'Terms and Conditions',
    description:
      'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
    requirements: ['tvOS 10.2+'],
    isSelected: false,
  },
  {
    id: 'TVProviderSignIn',
    label: 'TV Provider Sign In',
    description: 'Skips the TV provider sign in screen in tvOS.',
    requirements: ['tvOS 11.0+'],
    isSelected: false,
  },
  {
    id: 'TVRoom',
    label: 'TV Room',
    description: 'Skips the "Where is this Apple TV?" screen in tvOS.',
    requirements: ['tvOS 11.4+'],
    isSelected: false,
  },
  /* To be added -> */
  {
    id: 'TVHomeScreenSync',
    label: 'TV Home Screen Sync',
    description:
      'Users will not be prompted to sync the home screen with other Apple TV devices logged in with the same Apple Account.',
    requirements: ['tvOS 11.0+'],
    isSelected: false,
  },
];

export const IPHONE_SKIP_SCREENS = () => {
  const LDFFhasIntendedUser = featureFlags.getFlag('LIT-WWDC23-intended-user');

  return [
    {
      id: 'Android',
      label: 'Android Migration',
      description:
        'Users will not be prompted to move from Android. Note that this pane is only displayed if the Restore pane is not skipped.',
      requirements: ['iOS 9.0+'],
      isSelected: false,
    },
    {
      id: 'Appearance',
      label: 'Appearance',
      description: 'Users will not be prompted to configure Light/Dark Mode.',
      requirements: ['iOS 13.0+'],
      isSelected: false,
    },
    {
      id: 'AppleID',
      label: 'Apple Account',
      description:
        'Users will not be prompted to sign in with their Apple Account.',
      requirements: ['iOS 7.0+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Payment',
      label: 'Apple Pay',
      description: 'Users will not be prompted to configure Apple Pay.',
      requirements: ['iOS 8.1+'],
      isSelected: false,
    },
    {
      id: 'WatchMigration',
      label: 'Apple Watch Migration',
      description:
        'Users will not be prompted to configure their Apple Watch when restoring from a previous backup that was paired with an Apple Watch.',
      requirements: ['iOS 11.0+'],
      isSelected: false,
    },
    {
      id: 'Biometric',
      label: 'Biometric',
      description: 'Users will not be prompted configure Touch ID or Face ID.',
      requirements: ['iOS 8.1+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'SIMSetup',
      label: 'Cellular Plan',
      description: 'Users will not be prompted to add a cellular plan.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    {
      id: 'DeviceToDeviceMigration',
      label: 'Device to Device Migration',
      description:
        'Users will not be prompted to initiate a device to device migration.',
      requirements: ['iOS 13.0+'],
      isSelected: false,
    },
    {
      id: 'Diagnostics',
      label: 'Diagnostics',
      description:
        'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
      requirements: ['iOS 7.0+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Zoom',
      label: 'Display Zoom',
      description:
        'Users will not be prompted to configure Display Zoom settings. When skipped, standard text sizes and control sizes will be used.',
      requirements: ['iOS 8.3+'],
      isSelected: false,
    },
    {
      id: 'HomeButtonSensitivity',
      label: 'Home Button Sensitivity',
      description:
        'Users will not be prompted to configure the Home button feedback sensitivity setting.',
      requirements: ['iOS 10.0+'],
      isSelected: false,
    },
    {
      id: 'iMessageAndFaceTime',
      label: 'iMessage and FaceTime',
      description:
        'Users will not be prompted to configure iMessage and FaceTime features.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    ...(LDFFhasIntendedUser
      ? [
          {
            id: 'IntendedUser',
            label: 'Intended User',
            description:
              'Skips the Intended User pane (the one that allows picking between self or children).',
            requirements: ['iOS 17.0+'],
            isSelected: false,
          },
        ]
      : []),
    /* To be added -> */
    {
      id: 'MessageActivatePhoneNumber',
      label: 'Messaging Activation Using Phone Number',
      description: 'Skips the iMessage screen.',
      requirements: ['iOS 10.0+'],
      isSelected: false,
    },
    {
      id: 'Location',
      label: 'Location Services',
      description:
        'Users will not be prompted to configure Location Services. When skipped, Location Services will be turned off.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'Passcode',
      label: 'Passcode',
      description: 'Users will not be prompted to create a passcode.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'Privacy',
      label: 'Privacy',
      description:
        'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
      requirements: ['iOS 11.13+', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Restore',
      label: 'Restore',
      description: 'Users will not be prompted to restore from a backup.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'RestoreCompleted',
      label: 'Restore Completed',
      description: 'Skips the Restore Completed pane.',
      requirements: ['iOS 14.0+'],
      isSelected: false,
    },
    {
      id: 'ScreenTime',
      label: 'Screen Time',
      description:
        'Users will not be prompted to configure the Screen Time feature.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    {
      id: 'Siri',
      label: 'Siri',
      description:
        'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
      requirements: ['iOS 7.0+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'SoftwareUpdate',
      label: 'Software Update',
      description:
        'Users will not be prompted to configure automatic software updates. When skipped, automatic software updates will be turned off.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    {
      id: 'UpdateCompleted',
      label: 'Software Update Complete',
      description: 'Skips the Software Update Complete pane.',
      requirements: ['iOS 14.0+'],
      isSelected: false,
    },
    {
      id: 'TOS',
      label: 'Terms and Conditions',
      description:
        'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'DisplayTone',
      label: 'True Tone Display',
      description:
        'Users will not be prompted to configure the True Tone Display feature.',
      requirements: ['iOS 9.3.2+'],
      isSelected: false,
    },
    {
      id: 'OnBoarding',
      label: 'User Education',
      description:
        'Users will not be presented with informational screens for features such as Control Center, Cover Sheet, and Multitasking.',
      requirements: ['iOS 11.0 - iOS 13.6'],
      isSelected: false,
    },
    {
      id: 'Welcome',
      label: 'Welcome',
      description: 'Users will not be presented with the Get Started pane.',
      requirements: ['iOS 13.0+'],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'Keyboard',
    //   label: 'Keyboard',
    //   description: 'Users will not be prompted to configure the keyboard.',
    //   requirements: [],
    //   isSelected: false,
    // },
  ];
};

export const IPAD_SKIP_SCREENS = () => {
  const LDFFhasIntendedUser = featureFlags.getFlag('LIT-WWDC23-intended-user');

  return [
    {
      id: 'Android',
      label: 'Android Migration',
      description:
        'Users will not be prompted to move from Android. Note that this pane is only displayed if the Restore pane is not skipped.',
      requirements: ['iOS 9.0+'],
      isSelected: false,
    },
    {
      id: 'Appearance',
      label: 'Appearance',
      description: 'Users will not be prompted to configure Light/Dark Mode.',
      requirements: ['iPadOS 13.0+'],
      isSelected: false,
    },
    {
      id: 'AppleID',
      label: 'Apple Account',
      description:
        'Users will not be prompted to sign in with their Apple Account.',
      requirements: ['iOS 7.0+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Payment',
      label: 'Apple Pay',
      description: 'Users will not be prompted to configure Apple Pay.',
      requirements: ['iOS 8.1+'],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'WatchMigration',
    //   label: 'Apple Watch Migration',
    //   description:
    //     'Users will not be prompted to configure their Apple Watch when restoring from a previous backup that was paired with an Apple Watch.',
    //   requirements: [],
    //   isSelected: false,
    // },
    {
      id: 'Biometric',
      label: 'Biometric',
      description: 'Users will not be prompted configure Touch ID or Face ID.',
      requirements: ['iOS 8.1+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'SIMSetup',
      label: 'Cellular Plan',
      description: 'Users will not be prompted to add a cellular plan.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    {
      id: 'DeviceToDeviceMigration',
      label: 'Device to Device Migration',
      description:
        'Users will not be prompted to initiate a device to device migration.',
      requirements: ['iPadOS 13.0+'],
      isSelected: false,
    },
    {
      id: 'Diagnostics',
      label: 'Diagnostics',
      description:
        'Users will not be prompted to configure diagnostics submission. When skipped, automatic diagnostics submission will be turned off.',
      requirements: ['iOS 7.0+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'Zoom',
      label: 'Display Zoom',
      description:
        'Users will not be prompted to configure Display Zoom settings. When skipped, standard text sizes and control sizes will be used.',
      requirements: ['iOS 8.3+'],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'HomeButtonSensitivity',
    //   label: 'Home Button Sensitivity',
    //   description:
    //     'Users will not be prompted to configure the Home button feedback sensitivity setting.',
    //   requirements: ['iOS 10.0+'],
    //   isSelected: false,
    //   hide: true,
    // },
    {
      id: 'iMessageAndFaceTime',
      label: 'iMessage and FaceTime',
      description:
        'Users will not be prompted to configure iMessage and FaceTime features.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    ...(LDFFhasIntendedUser
      ? [
          {
            id: 'IntendedUser',
            label: 'Intended User',
            description:
              'Skips the Intended User pane (the one that allows picking between self or children).',
            requirements: ['iOS 17.0+'],
            isSelected: false,
          },
        ]
      : []),
    /* To be added -> */
    {
      id: 'MessageActivatePhoneNumber',
      label: 'Messaging Activation Using Phone Number',
      description: 'Skips the iMessage screen.',
      requirements: ['iOS 10.0+'],
      isSelected: false,
    },
    {
      id: 'Location',
      label: 'Location Services',
      description:
        'Users will not be prompted to configure Location Services. When skipped, Location Services will be turned off.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'Passcode',
      label: 'Passcode',
      description: 'Users will not be prompted to create a passcode.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'Privacy',
      label: 'Privacy',
      description:
        'Users will not be prompted to review the Apple Privacy Policy. When skipped, the policy is automatically accepted.',
      requirements: ['iOS 11.13+', 'STIG/SRG'],
      isSelected: false,
    },

    {
      id: 'Restore',
      label: 'Restore',
      description: 'Users will not be prompted to restore from a backup.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'RestoreCompleted',
      label: 'Restore Completed',
      description: 'Skips the Restore Completed pane.',
      requirements: ['iPadOS 14.0+'],
      isSelected: false,
    },
    {
      id: 'ScreenTime',
      label: 'Screen Time',
      description:
        'Users will not be prompted to configure the Screen Time feature.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    {
      id: 'Siri',
      label: 'Siri',
      description:
        'Users will not be prompted to configure Siri. When skipped, Siri will be turned off.',
      requirements: ['iOS 7.0+', 'NIST', 'STIG/SRG'],
      isSelected: false,
    },
    {
      id: 'SoftwareUpdate',
      label: 'Software Update',
      description:
        'Users will not be prompted to configure automatic software updates. When skipped, automatic software updates will be turned off.',
      requirements: ['iOS 12.0+'],
      isSelected: false,
    },
    {
      id: 'UpdateCompleted',
      label: 'Software Update Complete',
      description: 'Skips the Software Update Complete pane.',
      requirements: ['iPadOS 14.0+'],
      isSelected: false,
    },
    {
      id: 'TOS',
      label: 'Terms and Conditions',
      description:
        'Users will not be prompted to review the Apple Terms and Conditions. When skipped, the terms and conditions are automatically agreed to.',
      requirements: ['iOS 7.0+'],
      isSelected: false,
    },
    {
      id: 'DisplayTone',
      label: 'True Tone Display',
      description:
        'Users will not be prompted to configure the True Tone Display feature.',
      requirements: ['iOS 9.3.2+'],
      isSelected: false,
    },
    {
      id: 'OnBoarding',
      label: 'User Education',
      description:
        'Users will not be presented with informational screens for features such as Control Center, Cover Sheet, and Multitasking.',
      requirements: ['iPadOS 13.6+', 'iOS 11.0+'],
      isSelected: false,
    },
    {
      id: 'Welcome',
      label: 'Welcome',
      description: 'Users will not be presented with the Get Started pane.',
      requirements: ['iOS 13.0+'],
      isSelected: false,
    },
    /* To be removed -> */
    // {
    //   id: 'Keyboard',
    //   label: 'Keyboard',
    //   description: 'Users will not be prompted to configure the keyboard.',
    //   requirements: [],
    //   isSelected: false,
    // },
  ];
};

export const screensById = {
  mac: () => orderScreensById(MAC_SKIP_SCREENS),
  iPhone: () => orderScreensById(IPHONE_SKIP_SCREENS),
  iPad: () => orderScreensById(IPAD_SKIP_SCREENS),
  appleTv: () => orderScreensById(APPLE_TV_SKIP_SCREENS),
};
