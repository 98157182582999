import type { Edge, Node } from 'reactflow';
import { v4 as uuid } from 'uuid';

import type {
  Blueprint,
  DraggableLibraryItem,
  LibraryItem,
} from '../../blueprint-flow.types';

import { NODE_TYPES, ORIGIN_TYPES } from '../../constants';
import { transformLibraryItemsFromApi } from '../library-items';

/**
 * Transforms a list of Library Item ids into a list of Library Items understandable by React Flow
 * @param libraryItems - a list of Library Item ids
 * @returns a list of Library Items understandable by React Flow
 */
const transformLibraryItems = (libraryItems: LibraryItem['id'][]) =>
  libraryItems?.map((id) => {
    const flowId = uuid();
    const origin = ORIGIN_TYPES.graph;

    return {
      id: flowId,
      data: {
        id,
        flowId,
        origin,
      },
    };
  });

/**
 * Transforms nodes from the API into React Flow understandable nodes
 * @param nodes a list of nodes from the API
 * @returns a list of nodes understandable by React Flow
 */
const transformNodesFromApi = (nodes) =>
  nodes?.map((node) => {
    const { type } = node;

    const transformedNode = {
      id: node.id,
      type: node.type,
      position: { x: 0, y: 0 },
    };

    const rules = node.data?.rules
      ? { and: node.data.rules?.and.map((rule) => ({ ...rule, id: uuid() })) }
      : undefined;

    switch (type) {
      case NODE_TYPES.start:
        return {
          ...transformedNode,
          data: {
            items: transformLibraryItems(node.data.libraryItems) || [],
          },
        };

      case NODE_TYPES.assignment:
        return {
          ...transformedNode,
          parentNode: node.parentNode,
          data: {
            items: transformLibraryItems(node.data.libraryItems) || [],
            rules,
          },
        };

      case NODE_TYPES.conditional:
        return { ...transformedNode, data: { path: node.data.path } };

      default:
        return transformedNode;
    }
  });

/**
 * Transforms Blueprint data from the GET single Blueprint API call
 * @param data - API data
 * @returns a Blueprint object ready to be used by the Flow Builder
 */
const transformBlueprintFromApi = (data: any) => {
  const transformed = {
    id: data?.id,
    name: data?.name,
    description: data?.description,

    nodes: transformNodesFromApi(data?.graph?.nodes) || [],
    edges: data?.graph?.edges || [],

    params: data.params || {},
    type: data?.type,

    libraryItems: transformLibraryItemsFromApi(data?.library_items),
  };

  return transformed;
};

/**
 * Transforms graph nodes to the format used by the create/update Blueprint API
 * @param nodes - a list of nodes
 * @returns an updated list of nodes
 */
const transformNodestoApi = (nodes: Node[]) =>
  nodes.map((node) => {
    const transformedNode = {
      id: node.id,
      type: node.type,
    };

    const rules = node.data?.rules
      ? {
          and: node.data.rules?.and.map((rule) => {
            // Handle 'or' rules
            /* istanbul ignore if */
            if (rule.or) {
              return {
                or: rule.or.map((orRule) => {
                  const { id, ...rest } = orRule;
                  return rest;
                }),
              };
            }

            const { id, ...rest } = rule;
            return rest;
          }),
        }
      : null;

    switch (node.type) {
      case NODE_TYPES.start:
        return {
          ...transformedNode,
          data: {
            libraryItems:
              node.data?.items?.map(
                (item: DraggableLibraryItem) => item?.data?.id,
              ) || [],
          },
        };

      case NODE_TYPES.assignment:
        return {
          ...transformedNode,
          parentNode: node.parentNode,
          data: {
            libraryItems:
              node.data?.items?.map(
                (item: DraggableLibraryItem) => item?.data?.id,
              ) || [],
            rules,
          },
        };

      case NODE_TYPES.conditional:
        return {
          ...transformedNode,
          data: {
            path: node.data.path,
            assignments: nodes
              .filter((n) => n.parentNode === node.id)
              .map(({ id }) => id),
          },
        };

      default:
        return transformedNode;
    }
  });

/**
 * Filter out unnecessary edge data for the API
 * @param edges - a list of edges
 * @returns a list of edge objects with only the necessary data
 */
const transformEdgesToApi = (edges: Edge[]) =>
  edges.map(({ id, source, target, type }) => ({ id, source, target, type }));

/**
 * Transforms a Blueprint flow object into the format used by the
 * create/update Blueprint API
 * @param blueprint - a Blueprint object
 * @returns an object in the format used by the create/update Blueprint API
 */
const transformBlueprintToApi = (blueprint: Blueprint) => {
  const { name, description, nodes, edges, params, source } = blueprint;

  const transformed = {
    name,
    description,
    type: 'flow',

    .../* istanbul ignore next */ (nodes && edges
      ? {
          graph: {
            nodes: transformNodestoApi(nodes),
            edges: transformEdgesToApi(edges),
          },
        }
      : {}),
    params,
    source,
  };

  return transformed;
};

export {
  transformBlueprintFromApi,
  transformBlueprintToApi,
  transformNodestoApi,
  transformEdgesToApi,
};
