import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Checkbox } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

const General = ({ update, isDisabled, settings }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">General</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            disabled={isDisabled}
            label="Allow users to approve kernel extensions"
            id="allow_users_to_approve_kernel_extensions"
            testId="allow_users_to_approve_kernel_extensions_checkbox"
            checked={settings.AllowUserOverrides}
            onChange={() =>
              update('AllowUserOverrides', (currentValue) => !currentValue)
            }
          />
        </Setting.Controls>
      </Setting.Row>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            disabled={isDisabled}
            label="Allow standard users to complete KEXT install"
            id="allow_standard_users_to_complete_kext_install"
            testId="standard_users_kext_install_checkbox"
            checked={settings.AllowNonAdminUserApprovals}
            onChange={() =>
              update(
                'AllowNonAdminUserApprovals',
                (currentValue) => !currentValue,
              )
            }
          />
        </Setting.Controls>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

General.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(General);
