import React from 'react';
import './scep-drawer-content.css';

import { Setting } from 'features/library-items/template';

import General from './general';
import Key from './key';
import Options from './options';
import Subject from './subject';

const DrawerContent = ({
  update,
  setting,
  triggerValidate,
  isVisible,
  validationDep,
  onInvalidate,
  refs,
}) => (
  <Setting.Card className="ethernet-scep-drawer-content">
    <Setting.SubHeader>
      <p className="b-txt">
        The Simple Certificate Enrollment Protocol (SCEP) profile allows you to
        securely issue certificates to your Apple devices from a SCEP server and
        Certificate Authority. These certificates can be used for services such
        as 802.1x, VPN, and others.
      </p>
    </Setting.SubHeader>
    <Setting.Rows>
      <General
        update={update}
        setting={setting}
        triggerValidate={triggerValidate}
        isVisible={isVisible}
        validationDep={validationDep}
        onInvalidate={onInvalidate}
        refs={refs}
      />
      <Subject
        update={update}
        setting={setting}
        triggerValidate={triggerValidate}
        isVisible={isVisible}
        validationDep={validationDep}
        onInvalidate={onInvalidate}
        refs={refs}
      />
      <Key
        update={update}
        setting={setting}
        onInvalidate={onInvalidate}
        refs={refs}
      />
      <Options
        update={update}
        setting={setting}
        onInvalidate={onInvalidate}
        refs={refs}
        triggerValidate={triggerValidate}
        validationDep={validationDep}
      />
    </Setting.Rows>
  </Setting.Card>
);

export default DrawerContent;
