import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { clearPasscode } from 'src/app/components/computer/api';

const ComputerIosUnlock = (props) => {
  const { info, onHide } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);

  const onUnlockDevice = () => {
    setIsLoading(true);
    clearPasscode(computerId)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster('Command initiated.');
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster('Something went wrong.');
      });
  };

  const content = (
    <Text>
      The current passcode on the device will be cleared. Make sure the user is
      in possession of the device before proceeding. The user will have 60
      minutes to enter a new passcode if a passcode is being enforced.
    </Text>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onUnlockDevice}>
        Clear passcode
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Clear passcode"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerIosUnlock;
