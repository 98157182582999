import React from 'react';
import { Setting } from 'src/features/library-items/template';
import CheckboxRow from '../components/CheckboxRow';
import NumberRow from '../components/NumberRow';
import RadioRow from '../components/RadioRow';
import SelectRow from '../components/SelectRow';
import type { RestrictionConfig, UpdateFn } from '../config/common';
import { fieldTypes, getIsChecked } from '../config/common';

export type RestrictionSectionProps = {
  setting: Record<string, any>;
  validationDep: number;
  update: UpdateFn;
  updateHelpers: UpdateFn;
  pageState: any;
  configuration: {
    category: string;
    config: Array<RestrictionConfig>;
    isHidden?: boolean;
  };
  allConfigurations: Record<string, RestrictionConfig>;
};

const RestrictionSection = (props: RestrictionSectionProps) => {
  const {
    setting,
    pageState,
    validationDep,
    update,
    updateHelpers,
    configuration,
    allConfigurations,
  } = props;
  const { category, config, isHidden } = configuration;
  const { isDisabled, isSubmitted } = pageState;

  if (isHidden) {
    return null;
  }

  const restrictionsSetting = setting.restrictions;
  const helpers = setting._helpers;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3" id={category} style={{ scrollMargin: '24px' }}>
          {category}
        </h3>
      </Setting.Header>
      <Setting.Rows>
        {config.map((keyConfiguration) => {
          const {
            key,
            type,
            dependencies,
            description,
            tags,
            name,
            supervisionWarning,
            legacyInversion,
            customWarning,
            selectOptions,
            radioOptions,
            minimum,
            maximum,
            helper,
            component,
            componentProps,
            descriptionLink,
          } = keyConfiguration;
          const isSelectOption = type === fieldTypes.select;
          const isRadioOption = type === fieldTypes.radio;
          const isNumberOption = type === fieldTypes.number;
          const isCustomComponent = type === fieldTypes.custom;
          const hasUnmetDependencies = dependencies?.some(
            (dep) => getIsChecked(restrictionsSetting, dep.key) !== dep.mustBe,
          );

          const commonProps = {
            key,
            hasUnmetDependencies,
            dependencies: dependencies?.map(({ key: depKey, ...rest }) => ({
              ...rest,
              key: allConfigurations[depKey]?.name,
            })),
            isDisabled,
            isSubmitted,
            validationDep,
            name,
            description,
            tags,
            supervisionWarning,
            descriptionLink,
            // TODO: isInverted is used for the V2 defaults of
            // Restriction.
            //
            // isInverted: isInverted,
            isInverted: legacyInversion,
            customWarning,
            helpers,
            updateHelpers,
          };

          if (isSelectOption) {
            return (
              <SelectRow
                {...commonProps}
                isChecked={restrictionsSetting[key]?.checked}
                options={selectOptions}
                onCheckedChange={(checked) =>
                  update(key, (p) => ({ ...p, checked }))
                }
                onSelectChange={(value) =>
                  update(key, (p) => ({ ...p, value }))
                }
                value={restrictionsSetting[key]?.value}
              />
            );
          }

          if (isRadioOption) {
            return (
              <RadioRow
                {...commonProps}
                isChecked={restrictionsSetting[key].checked}
                options={radioOptions}
                onCheckedChange={(checked) =>
                  update(key, (p) => ({ ...p, checked }))
                }
                onRadioChange={(value) => update(key, (p) => ({ ...p, value }))}
                value={restrictionsSetting[key].value}
              />
            );
          }

          if (isNumberOption) {
            return (
              <NumberRow
                {...commonProps}
                isChecked={restrictionsSetting[key].checked}
                onCheckedChange={(checked) =>
                  update(key, (p) => ({ ...p, checked }))
                }
                onInputChange={(value) => update(key, (p) => ({ ...p, value }))}
                value={restrictionsSetting[key].value}
                minimum={minimum}
                maximum={maximum}
                helper={helper}
              />
            );
          }

          if (isCustomComponent) {
            const CustomComponent = component;

            return (
              <CustomComponent
                {...commonProps}
                {...componentProps}
                isChecked={restrictionsSetting[key].checked}
                onCheckedChange={(checked) =>
                  update(key, (p) => ({ ...p, checked }))
                }
                value={restrictionsSetting[key].value}
                onChange={(fn) =>
                  update(key, (p) => ({ ...p, value: fn(p.value) }))
                }
              />
            );
          }

          return (
            <CheckboxRow
              {...commonProps}
              isChecked={restrictionsSetting[key]?.value}
              onCheckedChange={(checked) =>
                update(key, (p) => ({ ...p, value: checked }))
              }
            />
          );
        })}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default RestrictionSection;
