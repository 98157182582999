import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import {
  Flex,
  Hint,
  Radio,
  RadioGroup,
  Text,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';

import { createDisplayableError, getInvalidIdentifierMessage } from '../common';
import { privacyEnum } from '../schema/privacy.schema';
import { StyledSetting } from './StyledLibraryItemSetting';

import type {
  AppAccessIdentifierType,
  AppAccessPropertySetting,
  UpdatePrivacy,
} from '../privacy.types';

interface AppleEventsPropertyProps {
  appAccessPropertySetting: AppAccessPropertySetting;
  appAccessIndex: number;
  appAccessPropertyIndex: number;
  update: UpdatePrivacy;
  isDisabled: boolean;
  showError: boolean;
}

const { appAccessKey, identifierType, validationMessage, updateActionType } =
  privacyEnum;
const appAccessKeyEnum = appAccessKey.enum;
const identifierTypeEnum = identifierType.enum;
const validationMessageEnum = validationMessage.enum;
const updateActionTypeEnum = updateActionType.enum;

export function AppleEventsProperty(props: Readonly<AppleEventsPropertyProps>) {
  const {
    appAccessPropertySetting,
    appAccessIndex,
    appAccessPropertyIndex,
    update,
    isDisabled,
    showError,
  } = props;

  const {
    AEReceiverCodeRequirement,
    AEReceiverIdentifier,
    AEReceiverIdentifierType,
  } = appAccessPropertySetting;

  const validations = React.useMemo(() => {
    const fieldNames = [
      appAccessKeyEnum.IdentifierType,
      appAccessKeyEnum.Identifier,
      appAccessKeyEnum.CodeRequirements,
    ] as const;

    const fields = fieldNames.map(
      (name, position) => `appAccess-${appAccessIndex}_pos-${position}.${name}`,
    );
    const fieldKeys = Object.fromEntries(
      fieldNames.map((name, index) => [name, fields[index]]),
    );

    const getKey = (fieldName: keyof typeof appAccessKeyEnum) => {
      const fieldKey = fieldKeys[fieldName];
      return fieldKey || '';
    };

    return {
      fields,
      getKey,
    };
  }, [appAccessIndex]);

  const updateInvalidations = React.useCallback(
    (_key, invalidUpdater) =>
      update({
        type: updateActionTypeEnum.setting,
        updater: (setting) => {
          const invalidationsMap = invalidUpdater(
            setting.invalidations?.invalidationsMap,
          );
          return {
            ...setting,
            invalidations: {
              invalidationsMap,
            },
          };
        },
      }),
    [update],
  );

  const { refs, onInvalidate, invalidations } = useInputsValidators(
    validations.fields,
    updateInvalidations,
  );

  useRemoveValidationOnUnmount(validations.fields, updateInvalidations);

  const displayableError = React.useMemo(
    () => createDisplayableError(showError),
    [showError],
  );

  React.useEffect(() => {
    onInvalidate(validations.getKey(appAccessKeyEnum.IdentifierType))(
      !AEReceiverIdentifierType ? validationMessageEnum['Required.'] : false,
    );
    onInvalidate(validations.getKey(appAccessKeyEnum.Identifier))(
      getInvalidIdentifierMessage(
        AEReceiverIdentifier,
        AEReceiverIdentifierType,
      ),
    );
    onInvalidate(validations.getKey(appAccessKeyEnum.CodeRequirements))(
      !AEReceiverCodeRequirement
        ? { label: validationMessageEnum['Required.'] }
        : false,
    );
  }, [
    AEReceiverCodeRequirement,
    AEReceiverIdentifier,
    AEReceiverIdentifierType,
    appAccessIndex,
    appAccessPropertyIndex,
    validations,
  ]);

  return (
    // @ts-expect-error -- TODO fix this
    <StyledSetting.Card
      css={{
        // hack to override Bumblebee `Setting.Card` specificity
        [`&${StyledSetting.Card}`]: { background: 'transparent' },
      }}
    >
      <StyledSetting.Rows>
        <StyledSetting.Row>
          <StyledSetting.Title>
            <Text>Receiver identifier type</Text>
          </StyledSetting.Title>

          <Flex flow="column" gap="xs">
            <RadioGroup
              ref={refs[validations.getKey(appAccessKeyEnum.IdentifierType)]}
              value={AEReceiverIdentifierType}
              onValueChange={(value: AppAccessIdentifierType) =>
                update({
                  type: updateActionTypeEnum.appAccessProperty,
                  appAccessIndex,
                  appAccessPropertyIndex,
                  updater: (appAccessProperty) => ({
                    ...appAccessProperty,
                    AEReceiverIdentifierType: value,
                  }),
                })
              }
            >
              <Radio
                value={identifierTypeEnum.bundleID}
                label="Bundle ID"
                aria-label="Bundle ID"
                disabled={isDisabled}
              />
              <Radio
                value={identifierTypeEnum.path}
                label="Path"
                aria-label="Path"
                disabled={isDisabled}
              />
            </RadioGroup>

            {showError &&
              !!invalidations[
                validations.getKey(appAccessKeyEnum.IdentifierType)
              ] && (
                <Hint
                  label={validationMessageEnum['Required.']}
                  variant="error"
                />
              )}
          </Flex>
        </StyledSetting.Row>

        <StyledSetting.Row>
          <StyledSetting.Title>
            <Text>Receiver identifier</Text>
          </StyledSetting.Title>

          <TextField
            ref={refs[validations.getKey(appAccessKeyEnum.Identifier)]}
            value={AEReceiverIdentifier}
            onChange={(e) =>
              update({
                type: updateActionTypeEnum.appAccessProperty,
                appAccessIndex,
                appAccessPropertyIndex,
                updater: (appAccessProperty) => ({
                  ...appAccessProperty,
                  AEReceiverIdentifier: e.currentTarget.value,
                }),
              })
            }
            placeholder="com.vmware.fusion"
            readOnly={isDisabled}
            state={displayableError(
              !!invalidations[validations.getKey(appAccessKeyEnum.Identifier)],
              'error',
              'default',
            )}
            hint={displayableError(
              !!invalidations[validations.getKey(appAccessKeyEnum.Identifier)],
              invalidations[validations.getKey(appAccessKeyEnum.Identifier)],
              null,
            )}
          />
        </StyledSetting.Row>

        <StyledSetting.Row>
          <StyledSetting.Title>
            <Text>Receiver code requirement</Text>
          </StyledSetting.Title>

          <Flex
            flow="column"
            gap="md"
            css={{
              '& textarea': {
                minHeight: 150,
                resize: 'vertical',
              },
            }}
          >
            <TextArea
              // @ts-expect-error -- TODO fix this
              ref={refs[validations.getKey(appAccessKeyEnum.CodeRequirements)]}
              resize
              minHeight="500px"
              value={AEReceiverCodeRequirement}
              onChange={(e) =>
                update({
                  type: updateActionTypeEnum.appAccessProperty,
                  appAccessIndex,
                  appAccessPropertyIndex,
                  updater: (appAccess) => ({
                    ...appAccess,
                    AEReceiverCodeRequirement: e.currentTarget.value,
                  }),
                })
              }
              placeholder='identifier "com.vmware.fusion" and anchor apple generic and certificate 1[field.1.2.840.113635.100.6.2.6] /* exists */ and certificate leaf[field.1.2.840.113635.100.6.1.13] /* exists */ and certificate leaf[subject.OU] = EG7KH642X6'
              readOnly={isDisabled}
              state={displayableError(
                !!invalidations[
                  validations.getKey(appAccessKeyEnum.CodeRequirements)
                ],
                'error',
                'default',
              )}
              hint={displayableError(
                !!invalidations[
                  validations.getKey(appAccessKeyEnum.CodeRequirements)
                ],
                invalidations[
                  validations.getKey(appAccessKeyEnum.CodeRequirements)
                ],
                null,
              )}
            />
          </Flex>
        </StyledSetting.Row>
      </StyledSetting.Rows>
    </StyledSetting.Card>
  );
}
