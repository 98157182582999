export const bundleValidator = (v) =>
  v && !/^[A-Z0-9.-]+\.[A-Z0-9.-]+\.[A-Z0-9.-]+$/i.test(v)
    ? 'Invalid Bundle Identifier'
    : undefined;

export const devIDValidator = (v) =>
  v && !/^[A-Z0-9]+$/i.test(v) ? 'Invalid Developer ID format' : undefined;

export const fullPathValidator = (v) => {
  if (v && !v.startsWith('/')) {
    return 'Path must start with a /';
  }
  return undefined;
};
