import {
  Checkbox,
  MultiSelectSearch,
  Select,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import EnergySaverService from '../service/energy-saver.service';

const wakeOptions = [
  {
    label: 'Start up or wake',
    value: EnergySaverService.wakePower.WAKEPOWERON,
  },
  {
    label: 'Start up',
    value: EnergySaverService.wakePower.POWERON,
  },
  {
    label: 'Wake',
    value: EnergySaverService.wakePower.WAKE,
  },
];

const sleepOptions = [
  {
    label: 'Sleep',
    value: EnergySaverService.sleepPower.SLEEP,
  },
  {
    label: 'Restart',
    value: EnergySaverService.sleepPower.RESTART,
  },
  {
    label: 'Shut down',
    value: EnergySaverService.sleepPower.SHUTDOWN,
  },
];

const weekDaysOptions = [
  {
    label: 'Monday',
    value: EnergySaverService.weekDays.MONDAY,
  },
  {
    label: 'Tuesday',
    value: EnergySaverService.weekDays.TUESDAY,
  },
  {
    label: 'Wednesday',
    value: EnergySaverService.weekDays.WEDNESDAY,
  },
  {
    label: 'Thursday',
    value: EnergySaverService.weekDays.THURSDAY,
  },
  {
    label: 'Friday',
    value: EnergySaverService.weekDays.FRIDAY,
  },
  {
    label: 'Saturday',
    value: EnergySaverService.weekDays.SATURDAY,
  },
  {
    label: 'Sunday',
    value: EnergySaverService.weekDays.SUNDAY,
  },
];

const timeOptions = [
  {
    label: '12 midnight',
    value: 0,
  },
  {
    label: '12:30 a.m.',
    value: 30,
  },
  {
    label: '1:00 a.m.',
    value: 60,
  },
  {
    label: '1:30 a.m.',
    value: 90,
  },
  {
    label: '2:00 a.m.',
    value: 120,
  },
  {
    label: '2:30 a.m.',
    value: 150,
  },
  {
    label: '3:00 a.m.',
    value: 180,
  },
  {
    label: '3:30 a.m.',
    value: 210,
  },
  {
    label: '4:00 a.m.',
    value: 240,
  },
  {
    label: '4:30 a.m.',
    value: 270,
  },
  {
    label: '5:00 a.m.',
    value: 300,
  },
  {
    label: '5:30 a.m.',
    value: 330,
  },
  {
    label: '6:00 a.m.',
    value: 360,
  },
  {
    label: '6:30 a.m.',
    value: 390,
  },
  {
    label: '7:00 a.m.',
    value: 420,
  },
  {
    label: '7:30 a.m.',
    value: 450,
  },
  {
    label: '8:00 a.m.',
    value: 480,
  },
  {
    label: '8:30 a.m.',
    value: 510,
  },
  {
    label: '9:00 a.m.',
    value: 540,
  },
  {
    label: '9:30 a.m.',
    value: 570,
  },
  {
    label: '10:00 a.m.',
    value: 600,
  },
  {
    label: '10:30 a.m.',
    value: 630,
  },
  {
    label: '11:00 a.m.',
    value: 660,
  },
  {
    label: '11:30 a.m.',
    value: 690,
  },
  {
    label: '12 noon.',
    value: 720,
  },
  {
    label: '12:30 p.m.',
    value: 750,
  },
  {
    label: '1:00 p.m.',
    value: 780,
  },
  {
    label: '1:30 p.m.',
    value: 810,
  },
  {
    label: '2:00 p.m.',
    value: 840,
  },
  {
    label: '2:30 p.m.',
    value: 870,
  },
  {
    label: '3:00 p.m.',
    value: 900,
  },
  {
    label: '3:30 p.m.',
    value: 930,
  },
  {
    label: '4:00 p.m.',
    value: 960,
  },
  {
    label: '4:30 p.m.',
    value: 990,
  },
  {
    label: '5:00 p.m.',
    value: 1020,
  },
  {
    label: '5:30 p.m.',
    value: 1050,
  },
  {
    label: '6:00 p.m.',
    value: 1080,
  },
  {
    label: '6:30 p.m.',
    value: 1110,
  },
  {
    label: '7:00 p.m.',
    value: 1140,
  },
  {
    label: '7:30 p.m.',
    value: 1170,
  },
  {
    label: '8:00 p.m.',
    value: 1200,
  },
  {
    label: '8:30 p.m.',
    value: 1230,
  },
  {
    label: '9:00 p.m.',
    value: 1260,
  },
  {
    label: '9:30 p.m.',
    value: 1290,
  },
  {
    label: '10:00 p.m.',
    value: 1320,
  },
  {
    label: '10:30 p.m.',
    value: 1350,
  },
  {
    label: '11:00 p.m.',
    value: 1380,
  },
  {
    label: '11:30 p.m.',
    value: 1410,
  },
];

const Schedule = (props) => {
  const { setting, update, isDisabled, isSubmitted } = props;
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    ['powerOn', 'powerOff'],
    update,
  );

  useEffect(() => {
    onInvalidate('powerOff')(
      setting.isRepeatingPowerOff && !setting.RepeatingPowerOff.weekdays.length,
    );
    onInvalidate('powerOn')(
      setting.isRepeatingPowerOn && !setting.RepeatingPowerOn.weekdays.length,
    );
  }, [setting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Schedule</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isRepeatingPowerOn}
              label="Power on schedule"
              onChange={() => update('isRepeatingPowerOn', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>Define a schedule for when the computer should be powered on.</p>
          </Setting.Helpers>

          {setting.isRepeatingPowerOn && (
            <Setting.SecondaryControls>
              <div ref={refs.powerOn}>
                <div className="b-flex-vc b-flex-g1 b-mb1">
                  <Select
                    compact
                    className="b-flex1"
                    options={wakeOptions}
                    value={wakeOptions.find(
                      ({ value }) =>
                        value === setting.RepeatingPowerOn?.eventtype,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOn', (p) => ({
                        ...p,
                        eventtype: value,
                      }))
                    }
                  />
                  <p className="b-txt-light">at</p>
                  <Select
                    compact
                    className="b-flex1"
                    options={timeOptions}
                    value={timeOptions.find(
                      ({ value }) => value === setting.RepeatingPowerOn?.time,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOn', (p) => ({
                        ...p,
                        time: value,
                      }))
                    }
                  />
                </div>
                <div className="b-flex1">
                  <MultiSelectSearch
                    compact
                    searchPlaceholder="Search day of the week"
                    className="b-flex1"
                    options={weekDaysOptions}
                    values={weekDaysOptions
                      .map((lv) =>
                        setting.RepeatingPowerOn.weekdays.includes(lv.value)
                          ? lv
                          : null,
                      )
                      .filter(Boolean)}
                    disabled={isDisabled}
                    onChange={(_, { action, option }) => {
                      if (action === 'deselect-option') {
                        update('RepeatingPowerOn', (p) => ({
                          ...p,
                          weekdays: p.weekdays.filter(
                            (value) => value !== option.value,
                          ),
                        }));
                      } else if (action === 'select-option') {
                        update('RepeatingPowerOn', (p) => ({
                          ...p,
                          weekdays: [...p.weekdays, option.value],
                        }));
                      }
                    }}
                    errorText={
                      (isSubmitted && invalidations.powerOn && 'Required.') ||
                      ''
                    }
                  />
                </div>
              </div>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.isRepeatingPowerOff}
              label="Power off schedule"
              onChange={() => update('isRepeatingPowerOff', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Define a schedule for when the computer should be powered off.
            </p>
          </Setting.Helpers>

          {setting.isRepeatingPowerOff && (
            <Setting.SecondaryControls>
              <div ref={refs.powerOff}>
                <div className="b-flex-vc b-flex-g1 b-mb1">
                  <Select
                    compact
                    className="b-flex1"
                    options={sleepOptions}
                    value={sleepOptions.find(
                      ({ value }) =>
                        value === setting.RepeatingPowerOff?.eventtype,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOff', (p) => ({
                        ...p,
                        eventtype: value,
                      }))
                    }
                  />
                  <p className="b-txt-light">at</p>
                  <Select
                    compact
                    className="b-flex1"
                    options={timeOptions}
                    value={timeOptions.find(
                      ({ value }) => value === setting.RepeatingPowerOff?.time,
                    )}
                    disabled={isDisabled}
                    onChange={({ value }) =>
                      update('RepeatingPowerOff', (p) => ({
                        ...p,
                        time: value,
                      }))
                    }
                  />
                </div>
                <div className="b-flex1">
                  <MultiSelectSearch
                    compact
                    searchPlaceholder="Search day of the week"
                    className="b-flex1"
                    options={weekDaysOptions}
                    values={weekDaysOptions
                      .map((lv) =>
                        setting.RepeatingPowerOff.weekdays.includes(lv.value)
                          ? lv
                          : null,
                      )
                      .filter(Boolean)}
                    disabled={isDisabled}
                    onChange={(_, { action, option }) => {
                      if (action === 'deselect-option') {
                        update('RepeatingPowerOff', (p) => ({
                          ...p,
                          weekdays: p.weekdays.filter(
                            (value) => value !== option.value,
                          ),
                        }));
                      } else if (action === 'select-option') {
                        update('RepeatingPowerOff', (p) => ({
                          ...p,
                          weekdays: [...p.weekdays, option.value],
                        }));
                      }
                    }}
                    errorText={
                      (isSubmitted && invalidations.powerOff && 'Required.') ||
                      ''
                    }
                  />
                </div>
              </div>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Schedule;
