import WifiService from '../service/wifi-service';

export const securityTypeOptions = [
  {
    value: WifiService.securityTypes.NONE,
    label: 'None',
  },
  {
    value: WifiService.securityTypes.WEP,
    label: 'WEP (Personal)',
  },
  {
    value: WifiService.securityTypes.ANY,
    label: 'Any Personal',
  },
  {
    value: WifiService.securityTypes.WPA_PERSONAL,
    label: 'WPA Personal',
  },
  {
    value: WifiService.securityTypes.WPA2_PERSONAL,
    label: 'WPA2 Personal',
  },
  {
    value: WifiService.securityTypes.WPA3_PERSONAL,
    label: 'WPA3 Personal',
  },
  {
    value: WifiService.securityTypes.DYNAMIC_WEP,
    label: 'Dynamic WEP',
  },
  {
    value: WifiService.securityTypes.ANY_ENTERPRISE,
    label: 'Any Enterprise',
  },
  {
    value: WifiService.securityTypes.WPA_ENTERPRISE,
    label: 'WPA Enterprise',
  },
  {
    value: WifiService.securityTypes.WPA2_ENTERPRISE,
    label: 'WPA2 Enterprise',
  },
  {
    value: WifiService.securityTypes.WPA3_ENTERPRISE,
    label: 'WPA3 Enterprise',
  },
];

export const securityWithPasswordValues = [
  WifiService.securityTypes.WEP,
  WifiService.securityTypes.WPA_PERSONAL,
  WifiService.securityTypes.WPA2_PERSONAL,
  WifiService.securityTypes.WPA3_PERSONAL,
  WifiService.securityTypes.ANY,
];

export const securityWithLoginWindowConfigurationValues = [
  WifiService.securityTypes.DYNAMIC_WEP,
  WifiService.securityTypes.WPA_ENTERPRISE,
  WifiService.securityTypes.WPA2_ENTERPRISE,
  WifiService.securityTypes.WPA3_ENTERPRISE,
  WifiService.securityTypes.ANY_ENTERPRISE,
];

export const protocolOptions = [
  { label: 'TLS', value: WifiService.protocols.TLS },
  { label: 'TTLS', value: WifiService.protocols.TTLS },
  { label: 'LEAP', value: WifiService.protocols.LEAP },
  { label: 'PEAP', value: WifiService.protocols.PEAP },
  { label: 'EAP-FAST', value: WifiService.protocols.EAP_FAST },
  { label: 'EAP-SIM', value: WifiService.protocols.EAP_SIM },
  { label: 'EAP-AKA', value: WifiService.protocols.EAP_AKA },
];

export const authenticationOptions = [
  {
    label: 'Username and password',
    value: WifiService.authentications.USERNAME_AND_PASSWORD,
  },
  {
    label: 'Computer AD system authentication',
    value: WifiService.authentications.COMPUTER_AD,
  },
  {
    label: 'Computer OD system authentication',
    value: WifiService.authentications.COMPUTER_OD,
  },
];

export const innerAuthenticationOptions = [
  { label: 'CHAP', value: WifiService.innerAuthentications.CHAP },
  { label: 'EAP', value: WifiService.innerAuthentications.EAP },
  { label: 'MSCHAP', value: WifiService.innerAuthentications.MSCHAP },
  { label: 'MSCHAPv2', value: WifiService.innerAuthentications.MSCHAPv2 },
  { label: 'PAP', value: WifiService.innerAuthentications.PAP },
  {
    label: "Don't specify",
    value: WifiService.innerAuthentications.DONT_SPECIFY,
  },
];

export const tlsOptions = [
  { label: '1.0', value: WifiService.tlsVersions.v1_0 },
  { label: '1.1', value: WifiService.tlsVersions.v1_1 },
  { label: '1.2', value: WifiService.tlsVersions.v1_2 },
  { label: "Don't specify", value: WifiService.tlsVersions.DONT_SPECIFY },
];

export const randValuesOptions = [
  { label: '2', value: WifiService.randValues[2] },
  { label: '3', value: WifiService.randValues[3] },
  { label: "Don't specify", value: WifiService.randValues.DONT_SPECIFY },
];

export const identityCertificateOptions = [
  { label: 'None', value: WifiService.identityCertificates.NONE },
  { label: 'AD CS Certificate', value: WifiService.identityCertificates.ADCS },
  {
    label: 'SCEP',
    value: WifiService.identityCertificates.SCEP,
  },
  { label: 'PKCS #12', value: WifiService.identityCertificates.PKCS12 },
].filter(Boolean);

export const keySizeOptions = [
  { label: '1024', value: WifiService.keySizes.s1024 },
  { label: '2048', value: WifiService.keySizes.s2048 },
  { label: '4096', value: WifiService.keySizes.s4096 },
];

export const keyUsageOptions = [
  { value: WifiService.keyUsages.NONE, label: 'None' },
  { value: WifiService.keyUsages.SIGNING, label: 'Signing' },
  { value: WifiService.keyUsages.ENCRYPTION, label: 'Encryption' },
  { value: WifiService.keyUsages.BOTH, label: 'Both signing and encryption' },
];
