import {
  Callout,
  Dialog,
  Flex,
  Icon,
  Paragraph,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import type { ServicenowDataParams } from 'src/features/integrations/components-for-specific-types/servicenow/services';

import { LineLoader } from 'app/components/interface/LineLoader';
import FooterButtons from '../../../../components/footer-buttons';

import ActionDialog from '../../../../components/action-dialog';
import type { FooterStatus as FooterStatusType } from '../../../../components/footer-status/footer-status';
import FooterStatus from '../../../../components/footer-status/footer-status';
import servicenowLogo from '../../../../generic-cards-view/assets/servicenow.svg';
import { useGetServicenowIntegration } from '../../hooks/use-get-servicenow-integration';
import { useUpdateServicenowIntegration } from '../../hooks/use-update-servicenow-integration';

import {
  ParagraphMedium,
  StyledLink,
} from '../../../../components/styled-components/main';

const StyledTextField = styled('div', {
  width: '$15',
  marginTop: '$4',
});

const StyledBodyContainer = styled(Flex, {
  height: '445px',
  overflow: 'scroll',
});
const StyledFooterContainer = styled('div', {
  paddingTop: '$5',
  paddingBottom: '$5',
});

type Props = {
  integrationId: string;
  onClose: () => void;
};

const inputs = [
  {
    name: 'tenant',
    type: 'text',
    label: 'ServiceNow tenant',
    placeholder: 'tenant.service-now.com',
  },
  {
    name: 'client_id',
    type: 'text',
    label: 'Client ID',
    placeholder: 'Enter the client ID from ServiceNow ',
  },
  {
    name: 'client_secret',
    type: 'password',
    label: 'Client secret',
    placeholder: 'Enter the client secret from ServiceNow',
  },
  {
    name: 'username',
    type: 'text',
    label: 'Username',
    placeholder: 'Enter your ServiceNow username',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Enter your ServiceNow password',
    hint: 'We do not store your password. The username and password provided are used to grant an access token only to your ServiceNow tenant.',
  },
];

const inputValidation = {
  required: 'Required',
  maxLength: {
    value: 256,
    message: 'Must be 256 characters or fewer',
  },
};

const footerStatusOption = {
  pending: {
    title: 'Re-authenticating to ServiceNow',
    statusText: 'In progress',
  },
  success: {
    title: 'Success! ServiceNow is connected',
    statusText: 'Complete',
  },
  failed: {
    title: 'Unable to obtain access token from ServiceNow.',
    statusText: 'Authentication failed. Please try again.',
  },
};

export const UpdateIntegration = ({ integrationId, onClose }: Props) => {
  const [canceling, setCanceling] = useState(false);
  const [footerStatus, setFooterStatus] = useState<
    FooterStatusType | undefined
  >(undefined);

  const { data: integrationData, isLoading } =
    useGetServicenowIntegration(integrationId);
  const configs = integrationData?.servicenow_configs?.[0];
  const detailsFields = {
    tenant: configs?.servicenow_tenant,
    client_id: configs?.client_id,
    username: configs?.username,
  };

  const isProcessing = footerStatus === 'pending';
  const isError = footerStatus === 'failed';

  const methods = useForm<{ [key: string]: string | undefined }>({
    mode: 'onChange',
    values: detailsFields,
  });
  const {
    clearErrors,
    trigger,
    setError,
    register,
    getValues,
    formState: { errors },
  } = methods;

  const updateMutation = useUpdateServicenowIntegration();

  const updateIntegration = (
    updateIntegrationId: string,
    updateConfigId: string,
  ) => {
    const fieldValues = getValues() as ServicenowDataParams;
    setFooterStatus('pending');

    updateMutation.mutate(
      {
        integrationId: updateIntegrationId,
        configId: updateConfigId,
        data: fieldValues,
      },
      {
        onSuccess: () => {
          setFooterStatus('success');
          onClose();
        },
        onError: (error: any) => {
          const message = 'Validate input entry and try again';
          const formInputs = getValues();
          setFooterStatus('failed');

          switch (error.response.status) {
            case 401:
              Object.keys(getValues())
                .filter((f) => f !== 'tenant')
                .map((field) => setError(field, { message }));
              break;
            case 422:
              setError('tenant', { message });
              break;
            default:
              Object.keys(formInputs).map((field) =>
                setError(field, { message }),
              );
          }
        },
      },
    );
  };

  const handleClose = () => {
    setCanceling(true);
  };

  const handleNext = async () => {
    clearErrors();
    const validationResult = await trigger();

    if (validationResult && configs) {
      updateIntegration(integrationId, configs.id);
    }
  };

  const dialogContent = () => (
    <>
      <ParagraphMedium>Are you sure you want to cancel?</ParagraphMedium>
      <Paragraph>
        We are attempting to re-authenticate to ServiceNow. If you cancel and
        exit now, all unsaved work will be lost.
      </Paragraph>
    </>
  );

  return (
    <>
      <ActionDialog
        size="lg"
        isOpen={canceling}
        title="Cancel re-authentication to ServiceNow"
        content={dialogContent()}
        nextText="Cancel and exit"
        nextClick={onClose}
        backClick={() => setCanceling(false)}
      />

      <Dialog
        isOpen
        onOpenChange={onClose}
        title="Re-authenticate with ServiceNow"
        content={
          isLoading ? (
            <LineLoader />
          ) : (
            <StyledBodyContainer flow="column">
              <Paragraph>
                To re-authenticate with ServiceNow you will need to provide the
                client secret and your admin password credentials again.
                Additionally, the Kandji app must be installed in ServiceNow for
                the connection to be established successfully.
              </Paragraph>

              {isError && (
                <Callout
                  theme="danger"
                  text="Please review the fields below and resolve any errors. Additionally, the Kandji app must be installed in the ServiceNow domain you are attempting to connect with."
                  showCloseButton={false}
                />
              )}

              {inputs.map((v) => (
                <StyledTextField key={v.name}>
                  <TextField
                    {...register(v.name, inputValidation)}
                    placeholder={v.placeholder}
                    label={v.label}
                    type={v.type}
                    disabled={isProcessing}
                    state={errors[v.name] ? 'error' : 'default'}
                    hint={{
                      label: errors[v.name]?.message
                        ? errors[v.name]?.message
                        : v.hint,
                    }}
                  />
                </StyledTextField>
              ))}

              {isError && (
                <StyledFooterContainer>
                  <Flex>
                    <ParagraphMedium>
                      Need to install the Kandji app?{' '}
                    </ParagraphMedium>
                    <StyledLink
                      href="https://store.servicenow.com/sn_appstore_store.do#!/store/application/e330778897bfe150f89bf7021153af52"
                      rel="noreferrer"
                      target="_blank"
                    >
                      &nbsp; Open ServiceNow Store &nbsp;
                      <Icon name="arrow-up-right-from-square" size="sm" />
                    </StyledLink>
                  </Flex>
                  <Paragraph>
                    If you are moving to a new ServiceNow tenant and do not have
                    the Kandji application installed, you can install it by
                    navigating to the ServiceNow Store above.
                  </Paragraph>
                </StyledFooterContainer>
              )}
            </StyledBodyContainer>
          )
        }
        footer={
          <FooterButtons
            classWrapper={false}
            nextButtonText="Save and authenticate"
            handleNext={() => handleNext()}
            onClose={() => handleClose()}
            isWorking={isProcessing}
            customComponent={
              footerStatus ? (
                <FooterStatus
                  icon={servicenowLogo}
                  status={footerStatus}
                  options={footerStatusOption}
                />
              ) : undefined
            }
          />
        }
        css={{ width: '800px', height: '600px' }}
      />
    </>
  );
};

export default UpdateIntegration;
