import { bool, func, object, string } from 'prop-types';
import React from 'react';

import { Button, setClass } from '@kandji-inc/bumblebee';
import HubSpotHandler from 'components/common/hubspot-handler';

const Actions = ({
  className,
  style,
  page,
  isEditable,
  onBack,
  onCancel,
  onEdit,
  onSave,
}) => {
  const { isEditing, isSaving, isValid } = page;

  return (
    <>
      <HubSpotHandler />
      <div className={setClass('b-library__actions', className)} style={style}>
        <div className="b-library__actions-inner">
          <div className="b-library__actions-primary b-grid-ctas">
            {isEditing ? (
              <Button kind="outline" onClick={onCancel} disabled={isSaving}>
                Cancel
              </Button>
            ) : (
              <Button kind="outline" onClick={onBack}>
                Back
              </Button>
            )}
            {isEditable &&
              (isEditing ? (
                <Button onClick={onSave} disabled={!isValid}>
                  Save
                </Button>
              ) : (
                <Button onClick={onEdit}>Edit</Button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

Actions.propTypes = {
  className: string,
  style: object,
  page: object.isRequired,
  isEditable: bool,
  onBack: func.isRequired,
  onCancel: func.isRequired,
  onEdit: func.isRequired,
  onSave: func.isRequired,
};

Actions.defaultProps = {
  className: null,
  style: {},
  isEditable: true,
};

export default Actions;
