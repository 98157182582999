/* istanbul ignore file - remove when we use this component */
import { Pagination } from 'src/components/ui';
import { usePagination } from 'src/features/visibility/prism/hooks';
import useVulnerability from '../../store';

const Footer = () => {
  const selectedVulnerability = useVulnerability(
    (state) => state.selectedVulnerability,
  );
  const { totalDevices, devicePagination } = selectedVulnerability;
  const { page, size } = devicePagination;

  const { paginationState: pagination, setPagination } = usePagination();

  return (
    <Pagination
      currentPage={page}
      totalItems={totalDevices}
      itemsPerPage={size}
      onPageChange={
        /* istanbul ignore next */ (page) =>
          setPagination({ ...pagination, pageIndex: page - 1 })
      }
      onItemsPerPageChange={
        /*  istanbul ignore next */ (itemsPerPage) => {
          setPagination({
            ...pagination,
            pageIndex: 0,
            pageSize: itemsPerPage,
          });
        }
      }
    />
  );
};

export { Footer };
