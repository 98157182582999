import { api } from 'src/app/api/base';
import {
  URL_BLUEPRINTS_CHANGE_CODE,
  URL_BLUEPRINTS_TOGGLE_CODE,
  URL_BLUEPRINT_AUTH_CONFIG,
  URL_BLUEPRINT_AUTH_CONFIG_UPDATE,
} from 'src/app/api/urls';
import type {
  AuthConfigUpsert,
  BlueprintAuthConfig,
} from './ManualEnrollment.types';

export async function upsertAuthConfig(authConfig: BlueprintAuthConfig) {
  try {
    const updateExisting = authConfig.id != null;

    const upsertUrl = updateExisting
      ? URL_BLUEPRINT_AUTH_CONFIG_UPDATE(authConfig.id)
      : URL_BLUEPRINT_AUTH_CONFIG;

    const authConfigApi = api(upsertUrl);

    const updateAuthConfig: (
      upsert: Partial<AuthConfigUpsert>,
    ) => Promise<void> = updateExisting
      ? authConfigApi.patch
      : authConfigApi.post;

    const upsertBody: Partial<AuthConfigUpsert> = {
      auth0_configuration:
        authConfig.auth0_client.auth0_configuration.connection_id,
      blueprints: authConfig.blueprints,
      enabled: authConfig.enabled,
      assign_user: authConfig.assign_user,
    };

    await updateAuthConfig(upsertBody);
  } catch {}
}

export async function changeBlueprintEnrollmentCode(blueprintId: string) {
  try {
    const response = await api(URL_BLUEPRINTS_CHANGE_CODE(blueprintId)).patch(
      {},
    );

    return response.data;
  } catch {}
}

export async function toggleBlueprintEnrollmentCode(
  blueprintId: string,
  isActive: boolean,
) {
  try {
    const response = await api(URL_BLUEPRINTS_TOGGLE_CODE(blueprintId)).patch({
      is_active: isActive,
    });

    return response.data;
  } catch {}
}
