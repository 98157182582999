import { Checkbox, SelectSearch } from '@kandji-inc/bumblebee';
import { getData, getName } from 'country-list';
import { Setting } from 'features/library-items/template';
import { getAllLanguageCode, getLanguageInfo } from 'languages';
import * as React from 'react';

export const LANGUAGE_OPTIONS = getAllLanguageCode().map((code: string) => ({
  value: code,
  label: `${getLanguageInfo(code).name} (${code.toUpperCase()})`,
}));

export const REGION_OPTIONS = getData().map(
  (countryData: { code: string; name: string }) => ({
    value: countryData.code,
    label: `${countryData.name} (${countryData.code.toUpperCase()})`,
  }),
);

function convertSelectOption(
  selected: string,
  converter: (s: string) => string,
) {
  return {
    label: `${converter(selected)} (${selected.toUpperCase()})`,
    value: selected,
  };
}

export interface LanguageRegionSettingProps {
  deviceType: string;
  setting: Record<string, any>;
  isDisabled: boolean;
  update: (key: string, update: any) => void;
}

export function LanguageRegionSetting({
  deviceType,
  setting,
  isDisabled,
  update,
}: LanguageRegionSettingProps) {
  const { language, region, isRegionSet, isLanguageSet } = setting;

  return (
    <>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            checked={isRegionSet}
            onChange={
              /* istanbul ignore next */
              () => update('isRegionSet', (p) => !p)
            }
            label={`Set region for ${deviceType} devices`}
            disabled={isDisabled}
          />
        </Setting.Controls>

        <Setting.Helpers className="b-txt-light">
          Specify which region format to use upon enrollment.
        </Setting.Helpers>

        {isRegionSet && (
          <Setting.SecondaryControls>
            <div className="b-library-form" data-testid="region-setting">
              <Setting.Controls className="k-ade-adjust-secondary-width">
                <SelectSearch
                  testId="region-setting-2"
                  compact
                  options={REGION_OPTIONS}
                  disabled={isDisabled}
                  value={convertSelectOption(region, getName)}
                  onChange={
                    /* istanbul ignore next */
                    (selected) => update('region', selected.value)
                  }
                  placeholder="Search Region"
                  searchPlaceholder="Search Region"
                />
              </Setting.Controls>
            </div>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            checked={isLanguageSet}
            onChange={
              /* istanbul ignore next */
              () => update('isLanguageSet', (p) => !p)
            }
            label={`Set language for ${deviceType} devices`}
            disabled={isDisabled}
          />
        </Setting.Controls>

        <Setting.Helpers className="b-txt-light">
          Specify the display language to use upon enrollment.
        </Setting.Helpers>

        {isLanguageSet && (
          <Setting.SecondaryControls>
            <div className="b-library-form" data-testid="language-setting">
              <Setting.Controls className="k-ade-adjust-secondary-width">
                <SelectSearch
                  compact
                  options={LANGUAGE_OPTIONS}
                  disabled={isDisabled}
                  value={convertSelectOption(
                    language,
                    (v) => getLanguageInfo(v).name,
                  )}
                  onChange={
                    /* istanbul ignore next */
                    (selected) => update('language', selected.value)
                  }
                  placeholder="Search Language"
                  searchPlaceholder="Search Language"
                />
              </Setting.Controls>
            </div>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
    </>
  );
}
