import { Toggle } from '@kandji-inc/bumblebee';
import { styled } from '@kandji-inc/nectar-ui';
import React, { useEffect, useState } from 'react';

const StyledToggle = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '4rem',
});

const Container = styled('div', {
  marginTop: '$6',
});

const INITIAL_EVENT_TRIGGER_STATE = {
  remediations: false,
  computerGoesMissing: false,
  computerGoesActive: false,
  newMacEnrolled: false,
  macCheckedOut: false,
  newMacFirstRunSummary: false,
  applicationBlocked: false,
  threatAlertDetected: false,
};

type Props = {
  onChange: (value: unknown, name: string) => void;
  value: Record<string, boolean>;
  className?: string;
  isOpen: boolean;
};

type ToggleName = keyof typeof INITIAL_EVENT_TRIGGER_STATE;

const EventTriggers = (props: Props) => {
  const { onChange, value, className, isOpen } = props;

  const [eventTriggers, setEventTriggers] = useState(
    INITIAL_EVENT_TRIGGER_STATE,
  );

  const handleToggle = (toggleName: ToggleName) => {
    setEventTriggers((prevState) => ({
      ...prevState,
      [toggleName]: !prevState[toggleName],
    }));

    const triggers = {
      ...eventTriggers,
      [toggleName]: !eventTriggers[toggleName],
    };

    onChange(triggers, 'actions');
  };

  useEffect(() => {
    if (!isOpen) {
      setEventTriggers(INITIAL_EVENT_TRIGGER_STATE);
    }
  }, [isOpen]);

  // toggle appropriate switches for editing
  useEffect(() => {
    const hasExistingEventTriggers = !!Object.keys(value).length;
    if (hasExistingEventTriggers) {
      setEventTriggers((prev) => ({
        ...prev,
        ...value,
      }));
    }
  }, [value]);

  return (
    <Container className={`b-txt ${className}`}>
      <StyledToggle>
        <span>Remediations</span>
        <Toggle
          checked={eventTriggers.remediations}
          onToggle={() => handleToggle('remediations')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>Device goes offline</span>
        <Toggle
          checked={eventTriggers.computerGoesMissing}
          onToggle={() => handleToggle('computerGoesMissing')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>Device no longer offline</span>
        <Toggle
          checked={eventTriggers.computerGoesActive}
          onToggle={() => handleToggle('computerGoesActive')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>New device enrolled</span>
        <Toggle
          checked={eventTriggers.newMacEnrolled}
          onToggle={() => handleToggle('newMacEnrolled')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>MDM profile removed</span>
        <Toggle
          checked={eventTriggers.macCheckedOut}
          onToggle={() => handleToggle('macCheckedOut')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>New Mac first run summary</span>
        <Toggle
          checked={eventTriggers.newMacFirstRunSummary}
          onToggle={() => handleToggle('newMacFirstRunSummary')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>An application is blocked</span>
        <Toggle
          checked={eventTriggers.applicationBlocked}
          onToggle={() => handleToggle('applicationBlocked')}
        />
      </StyledToggle>
      <StyledToggle>
        <span>Threat detected on device</span>
        <Toggle
          checked={eventTriggers.threatAlertDetected}
          onToggle={() => handleToggle('threatAlertDetected')}
        />
      </StyledToggle>
    </Container>
  );
};

export default EventTriggers;
