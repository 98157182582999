import { Button, ButtonGroup, setClass } from '@kandji-inc/bumblebee';
import React, { useState, useContext } from 'react';

import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import CustomizePanel from '../customize-panel';
import useCompanyLogo from '../hooks/use-company-logo.hook';
import KandjiLogo from '../kandji-logo';
import setPreviewImage, { previewPaths } from '../set-preview-image';
import ToggleButton from '../toggle-button';

const HelpScreen = (props) => {
  const { isDarkMode, setIsDarkMode, isDisabled, setGlobalValidations } =
    useContext(KandjiSetupCtx);
  const { model, setModel, initialStateCopy, beforeEditModel } = props;

  const form = {
    key: 'helpScreen',
    title: 'Customize Help screen',
    header: {
      label: 'Header',
      maxLength: 35,
    },
    subHeader: {
      label: 'Body',
      maxLength: 600,
    },
  };

  const [isHelpConfig, setIsHelpConfig] = useState(true);

  const onValidations = (v) => {
    setGlobalValidations((p) => ({ ...p, [v.name]: v.isInvalid }));
  };

  const companyLogo = useCompanyLogo({ model, isDarkMode });

  return (
    <div className="b-library__setting-card">
      <h3 className="b-h3 b-library__setting-card--title">Help screen</h3>
      <p className="b-txt b-library__setting-card--subtitle">
        Customize the header and body text on the Help screen. Users can access
        this screen by clicking the help button (?) in the bottom-left corner of
        the Install screen.
      </p>
      <div className="b-library__setting-rows">
        <div className="b-library__setting-row--single-col">
          <div className="b-flex-vc b-flex-btw">
            <div className="b-flex-g b-flex-vc">
              <CustomizePanel
                form={form}
                model={model}
                setModel={setModel}
                beforeEditModel={beforeEditModel}
                disabled={isDisabled}
                multiLineTextAllowed
                initialStateCopy={initialStateCopy}
                onValidations={onValidations}
              />
              <Button
                onClick={() => setIsHelpConfig((p) => !p)}
                icon={isHelpConfig ? 'angle-up' : 'angle-down'}
                kind="link"
                size="small"
              >
                {isHelpConfig ? 'Collapse preview' : 'Expand preview'}
              </Button>
            </div>
            <div
              className={setClass('k-ks-toggle', !isHelpConfig && '--hidden')}
            >
              <ButtonGroup
                initialSelected={isDarkMode ? 1 : 0}
                activeWatch={isDarkMode ? 1 : 0}
                onToggle={(index) => setIsDarkMode(index !== 0)}
              >
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(false)}
                >
                  Light Mode
                </ToggleButton>
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(true)}
                >
                  Dark Mode
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div
            className={setClass([
              'k-library-preview',
              !isHelpConfig ? 'k-library-preview--collapsed' : '',
            ])}
          >
            <div className="k-library-preview__bg">
              <img
                draggable="false"
                src={setPreviewImage({
                  isDarkMode,
                  displayMode: model.general.displayMode,
                })}
                alt="Preivew"
              />
              <div className="k-library-preview__item">
                <img
                  draggable="false"
                  className="k-library-preview__frame k-library-preview__frame--help"
                  src={
                    isDarkMode
                      ? previewPaths.helpFrameDark
                      : previewPaths.helpFrame
                  }
                  alt="Help Screen"
                />

                <KandjiLogo isDarkMode={isDarkMode} />
                <div
                  className={`k-library-preview__content preview-content--help ${
                    isDarkMode ? 'k-library-preview__content--dark' : ''
                  }`}
                >
                  <img
                    draggable="false"
                    className={setClass(
                      'k-library-preview__logo',
                      'preview-logo--center',
                      companyLogo.isSquare && '--round-corner-preview',
                    )}
                    src={companyLogo.src}
                    alt="Company Logo"
                  />
                  <h2 className="k-library-preview__title --help">
                    {model.helpScreen.header}
                  </h2>
                  <p className="k-library-preview__copy">
                    {model.helpScreen.subHeader}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpScreen;
