import {
  Radio,
  Select,
  TextInput,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';
import { authenticationOptions } from '../protocols-card.model';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const AuthenticationCredentials = ({
  isDisabled,
  setting,
  update,
  validationDep,
}) => {
  const isUseAsLoginWindowConfiguration = setting.useLoginWindow;

  const fieldsToValidate = [keys.username, keys.password];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">
            Username
            {isUseAsLoginWindowConfiguration && (
              <span className="b-txt-light2"> (optional)</span>
            )}
          </p>
        </div>
        <div>
          <p className="b-txt-light">
            The user name for the account. If not provided, the user is prompted
            during login. If not using a Login Window configuration, this field
            is required.
          </p>
        </div>
        <div ref={refs[keys.username]}>
          <TextInput
            disabled={isDisabled}
            value={setting[keys.username]}
            onChange={(e) => update(keys.username, e.target.value)}
            compact
            isOptional={isUseAsLoginWindowConfiguration}
            validator={(v) => [
              {
                message: 'Required',
                invalid: () => !isUseAsLoginWindowConfiguration && !v,
                trigger: [
                  'onBlur',
                  'onInput',
                  validationDep,
                  isUseAsLoginWindowConfiguration,
                ],
              },
            ]}
            onInvalidate={onInvalidate(keys.username)}
            placeholder="$EMAIL"
            data-testid="auth-email"
          />
        </div>
      </Setting.SecondaryControlsRow>
      <Setting.SecondaryControlsRow>
        <div>
          <p className="b-txt">Prompt for password</p>
        </div>
        <div>
          <p className="b-txt-light">
            How often the user will be prompted for a password when connecting
            to the network.
          </p>
        </div>
        <div className="b-grid-controls">
          <Radio
            label="Once (if not provided below)"
            value={WifiService.promptPasswordValues.ONCE}
            checked={
              setting[keys.promptForPassword] ===
              WifiService.promptPasswordValues.ONCE
            }
            onChange={() =>
              update(
                keys.promptForPassword,
                WifiService.promptPasswordValues.ONCE,
              )
            }
            isDisabled={isDisabled}
          />
          <Radio
            label="Every time the user connects to the network"
            value={WifiService.promptPasswordValues.EVERY_TIME}
            checked={
              setting[keys.promptForPassword] ===
              WifiService.promptPasswordValues.EVERY_TIME
            }
            onChange={() =>
              update(
                keys.promptForPassword,
                WifiService.promptPasswordValues.EVERY_TIME,
              )
            }
            isDisabled={isDisabled}
          />
        </div>
      </Setting.SecondaryControlsRow>
      {setting[keys.promptForPassword] ===
        WifiService.promptPasswordValues.ONCE && (
        <Setting.SecondaryControlsRow>
          <div>
            <p className="b-txt">
              Password
              {isUseAsLoginWindowConfiguration && (
                <span className="b-txt-light2"> (optional)</span>
              )}
            </p>
          </div>
          <div>
            <p className="b-txt-light">
              The user&apos;s password. If not provided, the user will be
              prompted when joining the network. If not using a Login Window
              configuration, this field is required.
            </p>
          </div>
          <div ref={refs[keys.password]}>
            <TextInput
              disabled={isDisabled}
              value={setting[keys.password]}
              onChange={(e) => update(keys.password, e.target.value)}
              compact
              isOptional={isUseAsLoginWindowConfiguration}
              validator={(v) => [
                {
                  message: 'Required',
                  invalid: () => !isUseAsLoginWindowConfiguration && !v,
                  trigger: [
                    'onBlur',
                    'onInput',
                    validationDep,
                    isUseAsLoginWindowConfiguration,
                  ],
                },
              ]}
              onInvalidate={onInvalidate(keys.password)}
              placeholder="Password"
              type="password"
              data-testid="auth-password"
            />
          </div>
        </Setting.SecondaryControlsRow>
      )}
    </Setting.SecondaryControls>
  );
};

const fieldsToValidate = [keys.authentication];

const Authentication = ({
  isDisabled,
  update,
  setting,
  validationDep,
  showAuthenticationCredentials,
}) => {
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  useEffect(() => {
    // i didnt find where it is being overwritten
    // thats why i have to do this way
    setTimeout(() => {
      onInvalidate(keys.authentication)(
        !setting[keys.authentication] && 'Required',
      );
    }, 0);
  }, [setting[keys.authentication]]);
  const showError = useIsChanged(validationDep);

  return (
    <Setting.SecondaryControlsRow>
      <div className="b-library-second-ctrl-row-title --first">
        <p className="b-txt" ref={refs[keys.authentication]}>
          Authentication
        </p>
      </div>
      <div className="b-library-second-ctrl-row-helper">
        <p className="b-txt-light">
          The account type used to connect to the network.
        </p>
      </div>
      <div className="b-library-second-ctrl-row-option --last">
        <Select
          isDisabled={isDisabled}
          options={authenticationOptions}
          compact
          onChange={(v) => update(keys.authentication, v.value)}
          value={authenticationOptions.find(
            (option) => option.value === setting[keys.authentication],
          )}
          errorText={(showError && invalidations[keys.authentication]) || ''}
          testId="auth-options"
        />
      </div>
      {showAuthenticationCredentials && (
        <AuthenticationCredentials
          isDisabled={isDisabled}
          setting={setting}
          update={update}
          validationDep={validationDep}
        />
      )}
    </Setting.SecondaryControlsRow>
  );
};

export default React.memo(Authentication);
