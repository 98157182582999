import { Button, Icon, TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

import { useSearchParamsUiHelper } from 'features/vulnerability/old/hooks';

const isTestEnv = process.env.NODE_ENV === 'test';

export default function Filters(props) {
  const {
    children,
    searchTermParamKey,
    searchPlaceholder,
    searchTermDelayMs = 1000,
    offsetKey = 'offset',
    filterParamKeys = [],
    moreFilters,
    initialMoreFiltersVisibility = false,
    onParamsChange,
    onClear,
  } = props;

  const { searchParamsUi, history } = useSearchParamsUiHelper({
    onParamsChange,
    // @ts-expect-error -- may re-type this when refactoring out `useSearchParamsUiHelper`
    uiConfig: {
      [searchTermParamKey]: {
        handleOnChange: (() => {
          let timeoutId: number | undefined;

          return (e, setValue, search) => {
            // @ts-expect-error -- may re-type this when refactoring out `useSearchParamsUiHelper`
            e.persist?.();
            clearTimeout(timeoutId);
            // @ts-expect-error -- may re-type this when refactoring out `useSearchParamsUiHelper`
            timeoutId = setTimeout(
              () => {
                search.set(offsetKey, '0');
                setValue(e);
              },
              // istanbul ignore next
              isTestEnv ? 0 : searchTermDelayMs,
            );
          };
        })(),
      },
    },
  });

  const [searchTermFilter, setSearchTermFilter] = useState(() =>
    searchTermParamKey ? searchParamsUi[searchTermParamKey].value : null,
  );
  const [isMoreFiltersVisible, setIsMoreFiltersVisible] = useState(
    initialMoreFiltersVisibility,
  );

  const filterParams = filterParamKeys.map((key) =>
    typeof key === 'function' ? key(searchParamsUi) : key,
  );

  const isAnyFilterApplied = searchParamsUi.hasSome(
    searchTermParamKey,
    ...filterParams,
  );

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const search = new URLSearchParams(location.search);
      setSearchTermFilter(search.get(searchTermParamKey));
    });

    return unlisten;
  }, [history, searchTermParamKey]);

  const handleOnClear = (e) => {
    setIsMoreFiltersVisible(false);

    if (searchTermParamKey) {
      setSearchTermFilter('');
    }

    const clearedKeys = [searchTermParamKey, ...filterParams].filter(Boolean);
    const clearedValues = clearedKeys.map(() => '');

    const unfilteredParams = Object.fromEntries(
      clearedKeys.map((key) => [key, '']),
    );

    searchParamsUi.setAll(unfilteredParams);
    onClear?.({ clickEvent: e, searchParams: searchParamsUi });
    searchParamsUi.updateSearchUrl({
      updatedKey: clearedKeys,
      updatedValue: clearedValues,
    });
  };

  const renderSearchTermFilter = () => {
    if (!searchTermParamKey) {
      return null;
    }

    return (
      <TextInput
        value={searchTermFilter}
        onChange={(e) => {
          setSearchTermFilter(e.target.value);
          searchParamsUi[searchTermParamKey].textInputProps.onChange(e);
        }}
        compact
        placeholder={searchPlaceholder}
        style={{
          width: 300,
        }}
        icon={searchTermFilter ? 'circle-xmark' : 'magnifying-glass'}
        onIconClick={() =>
          setSearchTermFilter((prev) => {
            if (prev) {
              searchParamsUi[searchTermParamKey].textInputProps.onChange({
                target: { value: '' },
              });
              return '';
            }
            return prev;
          })
        }
        data-testid="filter-search-input"
      />
    );
  };

  return (
    <div className="b-mb">
      <div
        className="v-table-filters __v-table-ns b-flex-btw b-flex-align-end"
        style={{
          padding: '16px 24px',
          borderRadius: '4px',
          background: 'white',
        }}
      >
        <div
          className="v-table-filters__controls __v-table-ns"
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            gridAutoColumns: 'minmax(300px, auto)',
            gridGap: '24px',
          }}
        >
          {children}
        </div>

        <div className="b-flex-g b-flex-align-center">
          {renderSearchTermFilter()}

          {moreFilters && (
            <Button
              onClick={() => setIsMoreFiltersVisible((prev) => !prev)}
              icon="sliders"
              kind="link"
              size="small"
            >
              More Filters
            </Button>
          )}

          {isAnyFilterApplied && (
            <Button
              onClick={handleOnClear}
              icon="xmark-small"
              kind="link"
              size="small"
            >
              Clear
            </Button>
          )}
        </div>
      </div>

      {isMoreFiltersVisible && moreFilters && (
        <div className="v-table-filters__more-filters __v-table-ns b-flex-g">
          <div className="v-table-filters__more-filters-arrow" />
          <button type="button" onClick={() => setIsMoreFiltersVisible(false)}>
            <Icon
              name="xmark"
              className="v-table-filters__more-filters-close __v-table-ns"
            />
          </button>

          {moreFilters}
        </div>
      )}
    </div>
  );
}
