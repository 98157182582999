import React, { useMemo, memo, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import useBlueprintFlow from 'src/features/blueprint-flow/store';

import { logout } from 'app/_actions/account';
import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import history from '../../router/history';
import CompanySwitcher from './CompanySwitcher';
import { ConfirmLogoutModal } from './confirm-logout-modal';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const isEditingAssignments = useBlueprintFlow(
    (state) => state.isEditingAssignments,
  );
  const { sidebarOpened, isEditingLibraryItem } = useContext(InterfaceContext);
  const { userFirstName, userLastName, userEmail } = useContext(AccountContext);
  const displayName = useMemo(
    () => (userFirstName ? `${userFirstName} ${userLastName}` : userEmail),
    [userFirstName, userLastName, userEmail],
  );

  const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] =
    useState(false);
  const onConfirmLogoutModalOpen = () => setIsConfirmLogoutModalOpen(true);
  const onConfirmLogoutModalClose = () => setIsConfirmLogoutModalOpen(false);
  const onConfirmLogoutModalConfirm = () => {
    setIsConfirmLogoutModalOpen(false);
    dispatch(logout());
  };

  const options = useMemo(
    () => [
      {
        label: (
          <section className="kandji-sidebar__dropdown">
            <span>{displayName}</span>
            <span>{userEmail}</span>
          </section>
        ),
      },
      {},
      {
        action: () => history.push('/user-profile'),
        label: 'My Profile',
        iconClass: 'user',
      },
      {},
      {
        action:
          isEditingLibraryItem || isEditingAssignments
            ? () => onConfirmLogoutModalOpen()
            : () => dispatch(logout()),
        label: 'Logout',
        iconClass: 'arrow-right-from-bracket',
      },
    ],
    [displayName, isEditingLibraryItem, isEditingAssignments],
  );

  return (
    <>
      <CompanySwitcher
        options={options}
        sidebarOpen={sidebarOpened}
        valueLabel={displayName}
        buttonIcon="ss-icon ss-smile"
        mBottom="20px"
      />
      <ConfirmLogoutModal
        isOpen={isConfirmLogoutModalOpen}
        onClose={onConfirmLogoutModalClose}
        onConfirm={onConfirmLogoutModalConfirm}
      />
    </>
  );
};

export default memo(ProfileDropdown);
