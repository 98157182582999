import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const LABEL_HELPER_TEXT_MAP = {
  macOS: 'macOS 14+',
  iOS: 'iOS 17+',
  iPadOS: 'iPadOS 17+',
};

const MinimumOSVersionRow = ({
  setting,
  update,
  isDisabled,
  minimumOSVersionOptions,
  typeOS,
}) => (
  <Setting.Row>
    <Setting.Controls>
      <Checkbox
        checked={setting.isMinimumOSVersionSet}
        label={`Require minimum OS version (${LABEL_HELPER_TEXT_MAP[typeOS]})`}
        onChange={() => update('isMinimumOSVersionSet', (p) => !p)}
        disabled={isDisabled}
        testId="min-os-version-checkbox"
      />
    </Setting.Controls>
    <Setting.Helpers>
      <p className="b-txt-light">
        Specify a minimum version of {typeOS} that the device must be running
        before the enrollment process continues.
      </p>
    </Setting.Helpers>
    {setting.isMinimumOSVersionSet && (
      <Setting.SecondaryControls>
        <div className="b-library-form">
          <Setting.Title>
            <p className="b-txt b-mb1">
              Version must be greater than or equal to
            </p>
          </Setting.Title>
          <Select
            isDisabled={isDisabled}
            options={minimumOSVersionOptions}
            placeholder="Select public release"
            compact
            onChange={(v) => update('minimumOSVersion', v.value)}
            value={minimumOSVersionOptions.find(
              (option) => option.value === setting.minimumOSVersion,
            )}
            inputId="min-os-version-select"
          />
        </div>
      </Setting.SecondaryControls>
    )}
  </Setting.Row>
);

export default MinimumOSVersionRow;
