import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { Banner, Button, modal as Modal } from '@kandji-inc/bumblebee';

type Props = {
  itemName: string;
  itemType: string;
  isDeleting: boolean;
  isOpen: boolean;
  onClose: () => Dispatch<SetStateAction<boolean>>;
  onConfirm: () => Dispatch<SetStateAction<boolean>>;
};

function DeleteModal(props: Props) {
  const { itemName, itemType, isDeleting, isOpen, onClose, onConfirm } = props;

  const parts = {
    header: {
      text: 'Delete Library Item',
    },
    content: {
      children: (
        <>
          <p>
            Are you sure you want to delete{' '}
            <span className="b-txt-bold">
              {itemType || 'Library'} - {itemName}
            </span>{' '}
            and remove it from the Library?
          </p>

          <Banner theme="warning" kind="block" style={{ marginBottom: '30px' }}>
            <p>This action is permanent, and can not be undone</p>
          </Banner>
        </>
      ),
    },
    footer: {
      children: (
        <>
          <Button kind="outline" onClick={onClose} isDisabled={isDeleting}>
            Cancel
          </Button>

          <Button
            className="b-ml"
            theme="error"
            iconPlacement="right"
            onClick={onConfirm}
          >
            Delete
          </Button>
        </>
      ),
    },
  };

  return (
    <Modal
      classes={{ root: 'k-delete-modal' }}
      isOpen={isOpen}
      onClose={onClose}
      parts={parts}
    />
  );
}

export default DeleteModal;
