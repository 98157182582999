/*
 * This module handles transforming data return from the endpoint, to the format
 * that the front-end can use.
 */

const ADJUST_SECTION_NAME_MAPPING = {
  'Apple Integrations': ['Automated Device Enrollment'],
  Blueprints: ['Blueprints Management'],
  Devices: ['Device Information', 'Device Actions', 'Device Secrets'],
  Users: ['Users'],
  Library: [
    'Custom Apps',
    'Custom Profiles',
    'Custom Scripts',
    'Library Items',
  ],
  Prism: ['Prism Categories'],
};

const ADJUST_CAT_DESC_MAPPING = {
  'Device Information':
    'Permissions related to collecting details about enrolled devices.',
  'Device Actions':
    'Permissions related to committing actions against a device.',
  'Device Secrets':
    'Permissions related to collecting sensitive authentication credentials from enrolled devices.',
  'Automated Device Enrollment':
    'Permissions related to managing Automated Device Enrollment integrations.',
  'Blueprints Management':
    'Permissions related to managing and creating Blueprints.',
  'Prism Categories': 'Permissions related to Prism categories.',
  Users: 'Permissions related to user directory integrations.',
};

const ADJUST_NAME_MAPPING = {};

const ADJUST_DESC_MAPPING = {};

/*
 * This transform function is meant to be executed once, right after
 * the response is recieved from the api. Then the data is passed
 * to the UI to render.
 */
const transformData = (tokenFromApi) => {
  const grouped = {};

  tokenFromApi.permissions.forEach((permissionData) => {
    const {
      category,
      http_method,
      name: originalName,
      description: originalDescription,
      path,
    } = permissionData;
    const sectionName =
      Object.entries(ADJUST_SECTION_NAME_MAPPING).find(([, categories]) =>
        categories.includes(category),
      )?.[0] || category;
    const categoryName = category;
    if (!grouped[sectionName]) {
      grouped[sectionName] = {};
    }
    if (!grouped[sectionName][categoryName]) {
      grouped[sectionName][categoryName] = [];
    }

    const name = ADJUST_NAME_MAPPING[originalName] || originalName;
    const description =
      ADJUST_DESC_MAPPING[originalName] || originalDescription;

    grouped[sectionName][categoryName].push({
      ...permissionData,
      id: `${http_method}::${originalName
        .trim()
        .toLowerCase()
        .replace(/\s/g, '_')}::${path}`,
      subgroup: categoryName,
      learn_more: 'https://kandji.io',
      group: sectionName.toLowerCase(),
      category_description: ADJUST_CAT_DESC_MAPPING[categoryName] || '',
      name,
      description,
    });
  });

  Object.keys(grouped).forEach((sectionName) => {
    Object.keys(grouped[sectionName]).forEach((categoryName) => {
      grouped[sectionName][categoryName].sort((a, b) =>
        a.http_method > b.http_method ? -1 : 1,
      );
    });
  });

  return {
    ...tokenFromApi,
    permissions: grouped,
  };
};

/* similar to above, but transforms to flast list before sending */
const transformToApi = (dataFromUi) => {
  const token = { ...dataFromUi };
  token.permissions = Object.values(dataFromUi.permissions)
    .map((sectionData) =>
      Object.values(sectionData)
        .flat()
        .filter((perm) => perm.allowed),
    )
    .flat();
  return token;
};

export default transformData;
export { transformToApi, transformData };
