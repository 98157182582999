import { INT_TYPES } from '../constants';
import adcs from './assets/adcs.svg';
import azure from './assets/azure.svg';
import googleWorkspace from './assets/google-workspace.svg';
import msDeviceCompliance from './assets/microsoft-device-compliance.svg';
import microsoftTeams from './assets/microsoft-teams.svg';
import okta from './assets/okta.svg';
import scim from './assets/scim.svg';
import servicenow from './assets/servicenow.svg';
import slack from './assets/slack.svg';

const cardConfigurations = {
  [INT_TYPES.slack]: {
    text: 'Add a Slack workspace and configure event-driven notifications.',
    title: 'Slack',
    img: slack,
  },
  [INT_TYPES.msTeams]: {
    text: 'Add a Microsoft Teams account and configure event-driven notifications.',
    title: 'Microsoft Teams',
    img: microsoftTeams,
  },
  [INT_TYPES.office365]: {
    text: 'Import a user directory from Microsoft Entra ID and assign devices to your users.',
    title: 'Microsoft Entra ID',
    img: azure,
  },
  [INT_TYPES.gsuite]: {
    text: 'Import a user directory from Google Workspace and assign devices to your users.',
    title: 'Google Workspace',
    img: googleWorkspace,
  },
  [INT_TYPES.scim]: {
    text: 'Import a user directory through the SCIM protocol and assign devices to your users.',
    title: 'SCIM protocol',
    img: scim,
  },
  [INT_TYPES.adcs]: {
    text: 'Allows you to issue certificates from an Active Directory Certificate Authority.',
    title: 'Active Directory Certificate Services',
    img: adcs,
  },
  [INT_TYPES.okta]: {
    text: 'Protect corporate resources by ensuring only compliant devices are allowed to access applications through Okta.',
    title: 'Okta Device Trust',
    img: okta,
  },
  [INT_TYPES.servicenow]: {
    text: 'Add an integration with ServiceNow for hardware asset management with Kandji.',
    title: 'ServiceNow',
    img: servicenow,
  },
  [INT_TYPES.msDeviceCompliance]: {
    text: 'Protect corporate resources by ensuring only compliant devices are allowed to access applications through Microsoft.',
    title: 'Microsoft Device Compliance',
    img: msDeviceCompliance,
  },
};

export default cardConfigurations;
