/* istanbul ignore file */
import { Badge, Flex, Text } from '@kandji-inc/nectar-ui';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import featureFlags from 'src/config/feature-flags';
import { ThreatTabs } from './threat-tabs';

const THREAT_PATHS = {
  threats: { path: 'threats', label: 'Threats' },
  vulnerability: {
    path: 'vulnerability',
    label: (
      <Flex gap="xs" alignItems="center">
        <Text css={{ fontWeight: '$medium' }}>Vulnerability</Text>{' '}
        <Badge color="blue" icon="sparkles" compact>
          New
        </Badge>
      </Flex>
    ),
  },
};

const ThreatRouter = () => {
  const { path } = useRouteMatch();

  const isThreatsV3On = featureFlags.getFlag('edr_041724_threats-v3');
  const isVulnOn = featureFlags.getFlag('vm_06252024_vuln-poc');

  const AVAILABLE_THREAT_PATHS = isVulnOn
    ? THREAT_PATHS
    : { threats: THREAT_PATHS.threats };

  const threatRouteTabs = Object.values(AVAILABLE_THREAT_PATHS)
    .map(({ path }) => path)
    .join('|');

  return (
    <Switch>
      <Route
        exact={!isThreatsV3On}
        path={`${path}/:tab(${threatRouteTabs})`}
        component={ThreatTabs}
      />

      {/* Default to the Threat tab */}
      <Redirect to={`${path}/${AVAILABLE_THREAT_PATHS.threats.path}`} />
    </Switch>
  );
};

export { ThreatRouter, THREAT_PATHS };
