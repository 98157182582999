import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { useState } from 'react';
import { msTeamsService } from '../services/teams';

const LOCAL_STORAGE_KEY = 'msteams-state';

const useMSTeamsAuth = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  const getAuthorizationData = async () => {
    const resp = await msTeamsService.getAuthorizationData();

    setData(resp);
  };

  const redirectToAuthUrl = () => {
    // TODO: show error if data.state doesn't exist

    localStorage.setItem(LOCAL_STORAGE_KEY, data.state);
    window.location.assign(data.authorization_url);
  };

  const createIntegration = async () => {
    setLoading(true);
    const state = localStorage.getItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_KEY);

    // TODO: show error slide if state doesn't exist

    const response = await msTeamsService.createIntegration(state);

    /* istanbul ignore next */
    if (response.status !== 201) {
      // TODO: show error slide if integration was not created
      toaster('Something went wrong');
    }

    setLoading(false);
    return response;
  };

  return {
    getAuthorizationData,
    redirectToAuthUrl,
    createIntegration,
    data,
    loading,
  };
};

export default useMSTeamsAuth;
