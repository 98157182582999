// Note: This is structured as an object instead of array so we can easily apply UI logic based on each option
// i.e. (value === ENFORCEMENT_OPTIONS.ALLOW_DEFERRAL) instead of (value === ENFORCEMENT_OPTIONS[1])
const ENFORCEMENT_OPTIONS = {
  ENFORCE_IMMEDIATELY: {
    label: 'Enforce immediately upon next login',
    value: 'enforce_immediately',
  },
  ALLOW_DEFERRAL: {
    label: 'Allow user deferral before enforcing',
    value: 'allow_deferral',
  },
};

// Note: This is structured as an object instead of array so we can easily apply UI logic based on each option
const RESTART_OPTIONS = {
  FORCE: {
    label: 'Force a restart after',
    value: 'force_restart_after',
  },
  REMIND: {
    label: 'Remind to restart every',
    value: 'remind_restart_every',
  },
};

const FORCE_RESTART_OPTIONS = [
  {
    label: '5 minutes',
    value: 5,
  },
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '1 hour',
    value: 60,
  },
  {
    label: '4 hours',
    value: 240,
  },
];

const REMIND_RESTART_OPTIONS = [
  {
    label: '1 hour',
    value: 1,
  },
  {
    label: '4 hours',
    value: 4,
  },
  {
    label: '24 hours',
    value: 24,
  },
  {
    label: '48 hours',
    value: 48,
  },
  {
    label: '7 days',
    value: 168,
  },
];

const LOGIN_ATTEMPT_OPTIONS = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];

export {
  ENFORCEMENT_OPTIONS,
  RESTART_OPTIONS,
  FORCE_RESTART_OPTIONS,
  REMIND_RESTART_OPTIONS,
  LOGIN_ATTEMPT_OPTIONS,
};
