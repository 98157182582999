import React from 'react';

import { Button } from '@kandji-inc/bumblebee';

const RedownloadButton = ({ onClick }) => (
  <Button className="redownload" kind="link" onClick={onClick}>
    Redownload connector
  </Button>
);

export default RedownloadButton;
