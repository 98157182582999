import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const CertificateCheckboxRows = (props) => {
  const { isDisabled, setting, update } = props;

  return (
    <>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            checked={setting.isAccessByAllApps}
            label="Allow apps to access the private key"
            onChange={() => update('isAccessByAllApps', (p) => !p)}
            isDisabled={isDisabled}
            testId="ccr-allow-apps"
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            If enabled, all apps will have access to the private key.
          </p>
        </Setting.Helpers>
      </Setting.Row>

      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            checked={!setting.isKeychainExport}
            label="Prevent the private key data from being extracted in the keychain"
            onChange={() => update('isKeychainExport', (p) => !p)}
            isDisabled={isDisabled}
            testId="ccr-key-export"
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            By default, private key data is extractable in the keychain.
          </p>
        </Setting.Helpers>
      </Setting.Row>
    </>
  );
};

export default CertificateCheckboxRows;
