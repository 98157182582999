import { useEffect, useLayoutEffect, useRef } from 'react';

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '15px';
    return () => {
      document.body.style.overflow = 'visible';
      document.body.style.paddingRight = '';
    };
  }, []);
};

export const useOnClickOutside = (ref, handler) => {
  const handlerRef = useRef(handler);
  const events = ['mousedown', 'touchstart'];

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (!handler) {
      return;
    }

    const listener = (event) => {
      if (
        !ref.current ||
        !handlerRef.current ||
        ref.current.contains(event.target)
      ) {
        return;
      }

      handlerRef.current(event);
    };

    events.forEach((event) => document.addEventListener(event, listener));

    return () =>
      events.forEach((event) => document.removeEventListener(event, listener));
  }, [!handler]);
};
