import React from 'react';

export const Loader = () => (
  <div className="loader-with-text2">
    <div className="loader-with-text-data">
      <i className="fas fa-spinner fa-spin" />
      <span>Loading...</span>
    </div>
  </div>
);
