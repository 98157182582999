import React from 'react';

import { array } from 'prop-types';

import iconEmptyStateServers from '../../assets/empty-state.svg';

import Row from './row';

/* istanbul ignore next */
const Table = ({ servers }) => {
  /* istanbul ignore next */
  const loopServer =
    servers.length > 0 &&
    servers.map((server) => <Row key={server.id} server={server} />);

  return servers.length > 0 ? (
    <div className="k-adcs-table k-table">
      <header className="k-adcs-table-header k-table-header">
        <div className="k-adcs-table-row k-table-row">
          <div className="k-adcs-table-column-toggle k-table-column" />

          <div className="k-adcs-table-column-server k-table-column">
            Server Name
          </div>

          <div className="k-adcs-table-column-connector k-table-column">
            Connector
          </div>

          <div className="k-adcs-table-column-library-items k-table-column">
            Library Items
          </div>

          <div className="k-adcs-table-column-status k-table-column">
            Status
          </div>

          <div className="k-adcs-table-column-action k-table-column" />
        </div>
      </header>

      {loopServer}
    </div>
  ) : (
    <div className="k-adcs-table-empty">
      <div className="k-adcs-table-body">
        <img src={iconEmptyStateServers} alt="empty" />

        <h3 className="b-h3 b-mt">Servers have not been configured</h3>

        <p className="b-txt b-mt1 b-mb1">
          Add an AD CS server and specify whether it should be assigned to a{' '}
          <br />
          connector or left unassigned.
        </p>
      </div>
    </div>
  );
};

Table.propTypes = {
  servers: array.isRequired,
};

export default Table;
