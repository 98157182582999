import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class FirewallService extends NewLibraryItemService {
  /** @note These options are inverted in the UI since we want users to check
   *  to allow these settings instead of check to block.
   */
  static managePfOptions = [
    {
      label: 'AFP',
      serverField: 'afp_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'Bonjour',
      serverField: 'bonjour_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'Finger',
      serverField: 'finger_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'FTP',
      serverField: 'ftp_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'HTTP',
      serverField: 'http_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'ICMP',
      serverField: 'icmp_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'IMAP',
      serverField: 'imap_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'IMAPS',
      serverField: 'imaps_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'iTunes sharing',
      serverField: 'itunes_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'mDNSResponder',
      serverField: 'mdnsresponder_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'NFS',
      serverField: 'nfs_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'Optical drive sharing',
      serverField: 'optical_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'POP',
      serverField: 'pop_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'POPS',
      serverField: 'pops_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'Printer sharing',
      serverField: 'printer_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'Screen sharing',
      serverField: 'screensharing_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'SMB',
      serverField: 'smb_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'SMTP',
      serverField: 'smtp_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'SSH',
      serverField: 'ssh_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'Telnet',
      serverField: 'telnet_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'TFTP',
      serverField: 'tftp_packets_blocked',
      defaultValue: false,
    },
    {
      label: 'UUCP',
      serverField: 'uucp_packets_blocked',
      defaultValue: false,
    },
  ];
}

export const firewallService = new FirewallService();
export default FirewallService;
