import React from 'react';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import Ticket from '../../../components/ticket';
import useModal from '../../../hooks/use-modal';
import eventNotifications from '../assets/event-notifications-icon.svg';
import realTimeUpd from '../assets/real-time-upd-icon.svg';
import headerIcon from '../assets/welcome-icons.svg';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const Welcome = () => {
  const { nextModal, closeModal } = useModal();
  const onClose = () => closeModal();
  const handleNext = () => {
    nextModal();
  };
  return (
    <Container>
      <div className="welcome-modal_first-slide">
        <img src={headerIcon} className="b-mb2 b-mt3" alt="header-icons" />
        <StyledSlideHeader>Welcome to Microsoft Teams</StyledSlideHeader>
        <p className="b-txt-light">
          Establishing an integration with the Microsoft Teams messaging and
          communications platform allows you to receive notifications about
          device activity from your Kandji instance.
        </p>
        <Ticket
          title="Real-time status updates"
          text="Provides real-time status updates about devices and delivers them directly to your Microsoft Teams account."
          icon={realTimeUpd}
        />

        <Ticket
          title="Event-driven notifications"
          text="Customize notifications based on certain event-based triggers and specify which channels you would like to be notified on."
          link="https://support.kandji.io/support/solutions/articles/72000579712-microsoft-teams-integration"
          icon={eventNotifications}
        />
      </div>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        nextButtonText="Get started"
      />
    </Container>
  );
};

export default Welcome;
