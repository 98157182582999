import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import type {
  AppliesToUsersRadioOptions,
  UserAccessSelectOptions,
} from '../devicecontrol.types';
import {
  AppliesToUsersValue,
  UserAccessLabel,
  UserAccessValue,
} from '../devicecontrol.types';

class DeviceControlService extends NewLibraryItemService {
  static userAccessSelectOptions: UserAccessSelectOptions = [
    {
      label: UserAccessLabel.READ_WRITE,
      value: UserAccessValue.READ_WRITE,
    },
    {
      label: UserAccessLabel.READ_ONLY,
      value: UserAccessValue.READ_ONLY,
    },
    {
      label: UserAccessLabel.BLOCK,
      value: UserAccessValue.BLOCK,
    },
  ];

  static userAccessSelectOptionsNetworkMounts: UserAccessSelectOptions = [
    {
      label: UserAccessLabel.READ_WRITE,
      value: UserAccessValue.READ_WRITE,
    },
    {
      label: UserAccessLabel.BLOCK,
      value: UserAccessValue.BLOCK,
    },
  ];

  static appliesToUsersRadioOptions: AppliesToUsersRadioOptions = [
    {
      label: 'All users',
      value: AppliesToUsersValue.ALL,
    },
    {
      label: 'Standard users',
      value: AppliesToUsersValue.STANDARD,
    },
  ];
}

export const deviceControlService = new DeviceControlService();

export default DeviceControlService;
