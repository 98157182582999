import { popover as Popover, PreLoadImages } from '@kandji-inc/bumblebee';
import React from 'react';
import appPreview from './assets/app.png';
import modalPreview from './assets/modal.png';
import question from './assets/question.png';
import star from './assets/star.svg';

const paths = [appPreview, modalPreview];

const getSizeLabel = (size) => {
  const units = [
    {
      label: 'GB',
      bytes: 1024 ** 3,
    },
    {
      label: 'MB',
      bytes: 1024 ** 2,
    },
    {
      label: 'KB',
      bytes: 1024,
    },
    {
      label: 'Bytes',
      bytes: 1,
    },
  ];

  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    if (unit.bytes <= size) {
      return `${Math.round((10 * size) / unit.bytes) / 10} ${unit.label}`;
    }
  }

  return '-';
};

const Preview = ({ defaultIcon, defaultName, categoryName, setting }) => {
  const {
    iconUrl,
    iconFile,
    displayName,
    isRecommended,
    shortDescription,
    longDescription,
    size,
    version,
    installBtnText,
  } = setting;

  return (
    <div className="k-ss2-preview">
      <PreLoadImages paths={paths} />
      <div className="k-ss2-preview__images">
        <div className="k-ss2-preview__item --list">
          <img
            className="k-ss2-preview-frame --list"
            src={appPreview}
            alt="App List Preview"
          />
          <div className="k-ss2-preview-app">
            <div className="k-ss2-preview-app-image">
              <img
                src={iconUrl || iconFile?.dataUrl || defaultIcon}
                alt="Icon Preview"
              />
            </div>
            <div className="k-ss2-preview-app__details">
              <p>
                {displayName ?? defaultName}{' '}
                {isRecommended && (
                  <img
                    className="k-ss2-preview-app-star"
                    src={star}
                    alt="Recommended"
                  />
                )}
              </p>
              <p> {shortDescription} </p>
              <div className="k-ss2-preview-app__button">
                <span> {installBtnText ?? 'Install'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="k-ss2-preview__item --modal">
          <img
            className="k-ss2-preview-frame --modal"
            src={modalPreview}
            alt="Modal Preview"
          />
          <div className="k-ss2-preview-app --modal">
            <div className="k-ss2-preview-app-image">
              <img
                src={iconUrl || iconFile?.dataUrl || defaultIcon}
                alt="Icon Preview"
              />
            </div>
            <div className="k-ss2-preview-app__details --modal">
              <p> {displayName ?? defaultName} </p>
              <p> {shortDescription} </p>
              <div className="k-ss2-preview-app__button">
                <span> {installBtnText ?? 'Install'}</span>
              </div>
            </div>
          </div>
          <div className="k-ss2-preview-modal-extras">
            <div className="k-ss2-preview-modal-grid">
              <div>
                <p className="k-ss2-preview-modal-extras-category">Category</p>
                <p className="k-ss2-preview-modal-extras-category-detail">
                  {categoryName}
                </p>
              </div>
              <div>
                <p className="k-ss2-preview-modal-extras-category">Size</p>
                <p className="k-ss2-preview-modal-extras-category-detail">
                  {getSizeLabel(size)}
                </p>
              </div>
              <div>
                <p className="k-ss2-preview-modal-extras-category">Version</p>
                <p className="k-ss2-preview-modal-extras-category-detail">
                  {version}
                </p>
              </div>
            </div>
            <div>
              <p className="k-ss2-preview-modal-extras-long-text">
                {longDescription}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Popover
        placement="right-end"
        popoverContent={
          <>
            <Popover.Header title="Self Service previews" />
            <Popover.Content>
              <Popover.Block>
                <p className="b-txt-ctrl3 b-mb-tiny">In-App preview</p>
                <p className="b-txt">
                  The preview on the left is an example of how the Library Item
                  will display in any list view in the Self Service app.
                </p>
              </Popover.Block>
              <Popover.Block>
                <p className="b-txt-ctrl3 b-mb-tiny">Modal preview</p>
                <p className="b-txt">
                  The preview on the right is an example of how the Library Item
                  will display in the item detail page or modal in Self Service.
                </p>
              </Popover.Block>
            </Popover.Content>
          </>
        }
      >
        <div className="k-ss2-preview__question-wrapper">
          <img
            className="k-ss2-preview__question"
            src={question}
            alt="More Info"
          />
        </div>
      </Popover>
    </div>
  );
};

export default Preview;
