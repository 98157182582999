import { formatDate } from './_helpers';

export default (activity) => [
  {
    label: 'Created by',
    values: [activity.actor_name],
  },
  {
    label: 'Created at',
    values: [formatDate(activity)],
  },
];
