import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  Button,
  Checkbox,
  Flex,
  TextInput,
  onEmptyBlurValidator,
} from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

import ConfigsContext from './configurations.context';

const patternIpv4Ipv6 = new RegExp(
  '((^\\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.)' +
    '{3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\\s*$)|(^\\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]' +
    '{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]' +
    '\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\\d|1\\d\\d' +
    '|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})' +
    '|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))' +
    '|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\\d|1\\d\\d|' +
    '[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})' +
    '|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))' +
    '|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]' +
    '?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:' +
    '((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:)))(%.+)?\\s*$))',
);

const shouldShowField = (isDisabled, value) => !(isDisabled && !value);

const ConfigurationCard = ({
  config,
  onChangeInput,
  onChangeNumber,
  onChangeCheckbox,
  onRemove,
  idx,
  canRemove,
  validationDep,
}) => {
  const { isDisabled, refs } = useContext(ConfigsContext);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">AirPrint Configuration</h3>
        {!isDisabled && canRemove && (
          <Button
            theme="dark"
            kind="link"
            size="small"
            icon="xmark"
            onClick={() => onRemove(idx)}
          >
            Remove
          </Button>
        )}
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <div className="b-form-grid" ref={refs ? refs[idx] : null}>
              {shouldShowField(isDisabled, config.PrinterName) && (
                <TextInput
                  fieldsGrid
                  disabled={isDisabled}
                  label="Display Name"
                  id={`display_name_${idx}`}
                  isOptional
                  value={config.PrinterName}
                  placeholder="Floor 3 Printer"
                  onChange={(e) =>
                    onChangeInput('PrinterName', idx, e.target.value)
                  }
                />
              )}
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                label="IP Address"
                id={`ip_address_${idx}`}
                value={config.IPAddress}
                placeholder="192.168.1.100"
                onChange={(e) =>
                  onChangeInput('IPAddress', idx, e.target.value)
                }
                validator={(v) => {
                  const ip = {
                    message: 'Input valid IP Address, i.e.  192.168.1.100',
                    invalid: () => !patternIpv4Ipv6.test(v),
                    trigger: ['onBlur', validationDep],
                  };

                  return [
                    onEmptyBlurValidator(v, {
                      trigger: ['onBlur', validationDep],
                    }),
                    ip,
                  ];
                }}
                onInvalidate={(isInvalid) =>
                  onChangeInput('IPAddress', idx, config.IPAddress, isInvalid)
                }
              />
              {shouldShowField(isDisabled, config.Port) && (
                <TextInput
                  fieldsGrid
                  type="number"
                  disabled={isDisabled}
                  label="Port"
                  id={`port_${idx}`}
                  isOptional
                  placeholder="65535"
                  value={config.Port}
                  onChange={(e) => {
                    if (e.target.value <= 65535) {
                      onChangeNumber('Port', idx, e.target.value);
                    }
                  }}
                />
              )}
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                label="Resource Path"
                id={`resource_path_${idx}`}
                value={config.ResourcePath}
                placeholder="/ipp/print"
                onChange={(e) =>
                  onChangeInput('ResourcePath', idx, e.target.value)
                }
                validator={(v) => [
                  onEmptyBlurValidator(v, {
                    trigger: ['onBlur', validationDep],
                  }),
                ]}
                onInvalidate={(isInvalid) =>
                  onChangeInput(
                    'ResourcePath',
                    idx,
                    config.ResourcePath,
                    isInvalid,
                  )
                }
              />
              <Flex>
                <Checkbox
                  disabled={isDisabled}
                  label="Use TLS"
                  id={`use_tls_${idx}`}
                  testId="tls_checkbox"
                  checked={config.ForceTLS}
                  onChange={() => onChangeCheckbox('ForceTLS', idx)}
                />
              </Flex>
            </div>
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

ConfigurationCard.propTypes = {
  config: PropTypes.shape({
    ForceTLS: PropTypes.bool,
    IPAddress: PropTypes.string,
    Port: PropTypes.number,
    PrinterName: PropTypes.string,
    ResourcePath: PropTypes.string,
  }).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  canRemove: PropTypes.bool.isRequired,
  validationDep: PropTypes.number.isRequired,
};

export default ConfigurationCard;
