import {
  Badge,
  Box,
  Button,
  Chip,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { getItemTypeDisplayName, installTypes } from '../../common';

const ItemLinkContainer = styled(Link, {
  '&:hover': {
    textDecoration: 'none',
  },
});

const CustomIcon = styled(Icon, {
  width: '16px',
  height: '16px',
  color: '$neutral70', // default color for this design
  variants: {
    inactive: {
      true: { color: '$neutral50' },
    },
  },
});

const IconLabel = styled(Text, {
  fontSize: '$1',
  color: '$neutral70',
  fontWeight: '$medium',
  variants: {
    inactive: {
      true: { color: '$neutral50' },
    },
  },
});

const ColumnTitle = styled(Flex, {
  flexGrow: '1',
  gap: '$4',
  alignItems: 'center',
  minWidth: '220px',
});

const ColumnActive = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  width: '70px',
});

const ColumnBlueprints = styled(Flex, {
  gap: '$2',
  alignItems: 'center',
  width: '44px',
});

const ColumnStatuses = styled(Flex, {
  gap: '$3',
  alignItems: 'center',
  width: '350px',
});

const StatusContainer = styled(Flex, {
  gap: '$1',
  alignItems: 'start',
  width: '108px',
});

const ColumnDeviceFamilies = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  width: '76px',
});

const ColumnInstallation = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  width: '56px',
});

export type LibraryListItemProps = {
  item: any;
};

const LibraryListItem = (props: LibraryListItemProps) => {
  const { item } = props;
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);

  return (
    <ItemLinkContainer to={item.getUrl({ id: item.id, account, environment })}>
      <Flex
        alignItems="center"
        gap="xl"
        css={{
          borderRadius: '$2',
          border: '1px solid $neutral20',
          backgroundColor: '$neutral0', // @todo replace with --card-card-surface when available from nectar-ui
          padding: '$4',
          minWidth: '1168px', // @todo replace this with more flexible responsive styles
          '&:hover': {
            backgroundColor: '#505E7114', // @todo replace with --card-surface-overlay-hover when available from nectar-ui
          },
          '&:active': {
            backgroundColor: '#505E7129',
          },
        }}
      >
        <ColumnTitle>
          <img
            src={item.icon || item.backupIcon}
            width="32"
            height="32"
            alt={`Library item ${item.name} icon`}
          />
          <Box css={{ width: 'calc(100% - $7)' /* <-- for the ellipsis */ }}>
            <Heading
              as="h4"
              size="4"
              css={{
                fontWeight: '$medium',
                lineHeight: '$3',
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {item.name}
            </Heading>
            <Text
              size="1"
              variant="description"
              css={{ fontWeight: '$medium' }}
            >
              {item.formattedItemType || getItemTypeDisplayName(item.type)}
            </Text>
          </Box>
        </ColumnTitle>
        <Flex justifyContent="end" gap="xl">
          <ColumnActive>
            <Badge
              color={item.active ? 'blue' : 'neutral'}
              css={{ alignSelf: 'center' }}
            >
              {item.active ? 'Active' : 'Inactive'}
            </Badge>
          </ColumnActive>
          {item.blueprints.length ? (
            <Tooltip
              interactive
              css={{
                maxHeight: '$11',
                maxWidth: '$14',
                overflowY: 'scroll',
                padding: '$3 $4',
              }}
              // @ts-expect-error -- TODO fix this
              content={
                <Flex wrap="wrap" gap="sm">
                  {item.blueprints?.map((bp) => (
                    <Chip
                      key={bp.id}
                      label={bp.name}
                      css={{ fontSize: '$1' }}
                    />
                  ))}
                </Flex>
              }
              side="bottom"
              theme="light"
            >
              <ColumnBlueprints>
                <CustomIcon name="kandji-blueprint" />{' '}
                <IconLabel>{item.blueprints.length}</IconLabel>
              </ColumnBlueprints>
            </Tooltip>
          ) : (
            <ColumnBlueprints>
              <CustomIcon name="kandji-blueprint" inactive={!item.active} />{' '}
              <IconLabel inactive={!item.active}>0</IconLabel>
            </ColumnBlueprints>
          )}
          <ColumnStatuses>
            <StatusContainer>
              <CustomIcon
                name="circle-check"
                css={{ color: item.active ? '$green50' : '$neutral50' }}
              />{' '}
              <IconLabel inactive={!item.active}>
                {item.counts.success} Success
              </IconLabel>
            </StatusContainer>
            <StatusContainer>
              <CustomIcon
                name="clock-five"
                css={{ color: item.active ? '$purple50' : '$neutral50' }}
              />{' '}
              <IconLabel inactive={!item.active}>
                {item.counts.pending} Pending
              </IconLabel>
            </StatusContainer>
            <StatusContainer>
              <CustomIcon
                name="octagon-exclamation"
                css={{ color: item.active ? '$red50' : '$neutral50' }}
              />{' '}
              <IconLabel inactive={!item.active}>
                {item.counts.failed} Error
              </IconLabel>
            </StatusContainer>
          </ColumnStatuses>
          <ColumnDeviceFamilies>
            {item.runs_on_iphone && (
              // @ts-expect-error -- TODO fix this
              <Tooltip content={<span>iPhone</span>} side="bottom">
                <span>
                  <CustomIcon name="sf-iphone" />
                </span>
              </Tooltip>
            )}
            {item.runs_on_ipad && (
              <Tooltip content="iPad" side="bottom">
                <span>
                  <CustomIcon name="sf-ipad-landscape" />
                </span>
              </Tooltip>
            )}
            {item.runs_on_mac && (
              <Tooltip content="Mac" side="bottom">
                <span>
                  <CustomIcon name="sf-desktop-computer" />
                </span>
              </Tooltip>
            )}
            {item.runs_on_tv && (
              <Tooltip content="Apple TV" side="bottom">
                <span>
                  <CustomIcon name="sf-apple-tv" />
                </span>
              </Tooltip>
            )}
          </ColumnDeviceFamilies>
          <ColumnInstallation>
            {item.has_rules && (
              <Tooltip content="Assignment Rules" side="bottom">
                <span>
                  <CustomIcon name="list" />
                </span>
              </Tooltip>
            )}
            {item.is_self_service && (
              <Tooltip content="Self Service" side="bottom">
                <span>
                  <CustomIcon name="kandji-logo" />
                </span>
              </Tooltip>
            )}
            {item.install_enforcement === installTypes.CONTINUOUS && (
              <Tooltip content="Continuously Enforce" side="bottom">
                <span>
                  <CustomIcon name="infinity" />
                </span>
              </Tooltip>
            )}
            {item.install_enforcement === installTypes.ONCE && (
              <Tooltip content="Install Once" side="bottom">
                <span>
                  <CustomIcon name="rotate-right-one" />
                </span>
              </Tooltip>
            )}
          </ColumnInstallation>
          <Flex>
            <Button
              variant="subtle"
              icon={{ name: 'ellipsis', position: 'left' }}
              compact
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </ItemLinkContainer>
  );
};

export default LibraryListItem;
