import {
  Badge,
  Box,
  Flex,
  ScrollContainer,
  SortableList,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import {
  COLUMN_EDITOR_DIMENSIONS as DIMENSIONS,
  zUtils,
} from '../../utils/columnEditorUtils';
import type { ColumnEditorContentProps } from '../PrismColumnEditor';
import { ColumnItem } from './ColumnItem';
import { ColumnSeparator } from './ColumnSeparator';
import { EmptyColumnEditorState } from './EmptyColumnEditorState';

type ColumnMeta = {
  id: string;
  displayName: string;
  enableHiding: boolean;
};

export const ColumnEditorContent = ({
  columns,
  columnOrder,
  onMoveToHidden,
  onMoveToVisible,
  onReorder,
  columnVisibility,
}: ColumnEditorContentProps) => {
  const [search, setSearch] = React.useState('');

  const { hiddenColumns, visibleColumns, neverColumns } = React.useMemo(
    () =>
      columns
        // .filter((c) => c.columnDef.meta?.alwaysHidden !== true)
        .reduce<{
          hiddenColumns: ColumnMeta[];
          visibleColumns: ColumnMeta[];
          neverColumns: ColumnMeta[];
        }>(
          (
            {
              hiddenColumns: hCols,
              visibleColumns: vCols,
              neverColumns: nCols,
            },
            col,
          ) => {
            const colMeta = {
              id: col.id,
              displayName: col.columnDef.meta?.displayName || col.id,
              enableHiding: col.columnDef.enableHiding !== false,
            };
            if (col.columnDef.meta?.alwaysHidden === true) {
              nCols.push(colMeta);
            } else if (columnVisibility[col.id]) {
              vCols.push(colMeta);
            } else {
              hCols.push(colMeta);
            }
            return {
              hiddenColumns: hCols,
              visibleColumns: vCols,
              neverColumns: nCols,
            };
          },
          { hiddenColumns: [], visibleColumns: [], neverColumns: [] },
        ),
    [columns, columnVisibility],
  );

  // memoize rendered empty state component to prevent flash of blank icon while loading
  const allEmptyColumnsJsx = React.useMemo(
    () => <EmptyColumnEditorState emptyKind="all-columns-empty" />,
    [],
  );

  const columnItemCss = {
    padding: '12px 12px 8px 16px',
    width: '320px',
    height: '44px',
  };

  const hiddenColumnsJsx = hiddenColumns
    .filter((col) =>
      col.displayName.toLowerCase().includes(search.toLowerCase()),
    )
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((col) => ({
      id: col.id,
      content: (
        <ColumnItem
          displayName={col.displayName}
          icon="fa-plus-minus-small"
          onIconClick={() => {
            onMoveToVisible(col.id);
          }}
        />
      ),
      css: columnItemCss,
    }));

  const visibleColumnsJsx = visibleColumns
    .filter((col) =>
      col.displayName.toLowerCase().includes(search.toLowerCase()),
    )
    .sort((a, b) => {
      // Sort columns with enableHiding === false to the top
      if (a.enableHiding === false && b.enableHiding !== false) {
        return -1;
      }
      if (a.enableHiding !== false && b.enableHiding === false) {
        return 1;
      }

      // For the rest, sort by columnOrder
      const aIndex = columnOrder.indexOf(a.id);
      const bIndex = columnOrder.indexOf(b.id);

      return aIndex - bIndex;
    })
    .map((col) => ({
      id: col.id,
      disabled: col.enableHiding === false,
      content: (
        <ColumnItem
          displayName={col.displayName}
          pinned={col.enableHiding === false}
          icon="fa-dash-14px"
          onIconClick={() => {
            onMoveToHidden(col.id);
          }}
        />
      ),
      css: columnItemCss,
    }));

  const hasHiddenColumns = !!hiddenColumns.length;
  const hasVisibleColumns = !!visibleColumns.length;
  const hasAllVisibleColumns = hasVisibleColumns && !hasHiddenColumns;
  const hasAllEmptyColumns = !hasHiddenColumns && !hasVisibleColumns;

  return (
    <Box
      css={{
        '& [data-overflow-state]': {
          width: DIMENSIONS.COLUMN_LIST_CONTAINER_WIDTH_PX,
        },
      }}
    >
      <TextField
        value={search}
        icon="magnifying-glass"
        placeholder="Search attributes"
        onChange={(e) => setSearch(e.target.value)}
        onClear={() => setSearch('')}
        showClearButton
      />

      <Flex
        css={{
          width: DIMENSIONS.COLUMNS_CONTAINER_WIDTH_PX,
          height: DIMENSIONS.COLUMNS_CONTAINER_HEIGHT_PX,
          mt: 20,
        }}
      >
        {hasAllEmptyColumns ? (
          allEmptyColumnsJsx
        ) : (
          <>
            <Flex flow="column" alignItems="start" wFull>
              <Badge color="neutral">Hidden</Badge>

              <Text
                variant="description"
                size="1"
                css={{
                  mt: '$1',
                  mb: '$3',
                }}
              >
                Select attribute to add and re-order on the right.
              </Text>

              {hasAllVisibleColumns ? (
                <EmptyColumnEditorState
                  emptyKind={zUtils.hiddenColumnsEmptyKind.parse({
                    search,
                    hiddenColumnsCount: hiddenColumns.length,
                    visibleColumnsCount: visibleColumns.length,
                  })}
                />
              ) : (
                <ScrollContainer
                  height={DIMENSIONS.COLUMN_LIST_HEIGHT_PX}
                  showScrollShadowBottom
                  scrollShadowLayer={DIMENSIONS.COLUMN_LIST_LAYER}
                >
                  <SortableList
                    // compute new key to force re-render sortable list and
                    // overflow shadow when search, column visibility, or column
                    // order changes
                    key={`${search}-${hiddenColumns.length}-${columnOrder.join(
                      '-',
                    )}
                      )}`}
                    items={hiddenColumnsJsx}
                    css={{
                      width: DIMENSIONS.COLUMN_LIST_WIDTH_PX,
                    }}
                    disabled
                  />
                </ScrollContainer>
              )}
            </Flex>

            <ColumnSeparator />

            <Flex flow="column" alignItems="start" wFull>
              <Badge color="green">Visible</Badge>

              <Text
                variant="description"
                size="1"
                css={{
                  mt: '$1',
                  mb: '$3',
                }}
              >
                Drag to re-order the table view.
              </Text>

              {!hasVisibleColumns ? (
                <EmptyColumnEditorState emptyKind="single-empty-columns" />
              ) : (
                <ScrollContainer
                  height={DIMENSIONS.COLUMN_LIST_HEIGHT_PX}
                  scrollShadowLayer={DIMENSIONS.COLUMN_LIST_LAYER}
                  showScrollShadowBottom
                >
                  <SortableList
                    // compute new key to force re-render sortable list and
                    // overflow shadow when search, column visibility, or column
                    // order changes
                    key={`${search}-${visibleColumns.length}-${columnOrder.join(
                      '-',
                    )}
                      )}`}
                    items={visibleColumnsJsx}
                    css={{
                      width: DIMENSIONS.COLUMN_LIST_WIDTH_PX,
                    }}
                    onChange={
                      /* istanbul ignore next -- formatting change false positive */
                      (sortedCols: Array<{ id: string }>) => {
                        queueMicrotask(() => {
                          onReorder([
                            ...sortedCols.map((col) => col.id),
                            ...hiddenColumns.map((col) => col.id),
                            ...neverColumns.map((col) => col.id),
                          ]);
                        });
                      }
                    }
                  />
                </ScrollContainer>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};
