import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import Arabesque from '../assets/Arabesque.png';
import Drift from '../assets/Drift.png';
import Flurry from '../assets/Flurry.png';
import Other from '../assets/Other.png';
import Shell from '../assets/Shell.png';
import WordOfTheDay from '../assets/WordOfTheDay.png';

class ScreenSaverService extends NewLibraryItemService {
  static SCREEN_SAVER_START_AFTER = [
    { label: 'Never', value: 0 },
    { label: '1 minute', value: 60 },
    { label: '2 minutes', value: 120 },
    { label: '5 minutes', value: 300 },
    { label: '10 minutes', value: 600 },
    { label: '15 minutes', value: 900 },
    { label: '20 minutes', value: 1200 },
    { label: '30 minutes', value: 1800 },
    { label: '1 hour', value: 3600 },
  ];

  static SCREEN_SAVER_OPTIONS = [
    {
      label: 'Arabesque',
      value: '/System/Library/Screen Savers/Arabesque.saver',
      src: Arabesque,
    },
    {
      label: 'Drift',
      value: '/System/Library/Screen Savers/Drift.saver',
      src: Drift,
    },
    {
      label: 'Flurry',
      value: '/System/Library/Screen Savers/Flurry.saver',
      src: Flurry,
    },
    {
      label: 'Shell',
      value: '/System/Library/Screen Savers/Shell.saver',
      src: Shell,
    },
    {
      label: 'Word of the Day',
      value: '/System/Library/Screen Savers/Word of the Day.saver',
      src: WordOfTheDay,
    },
    {
      isOther: true,
      label: 'Other...',
      value: '',
      src: Other,
    },
  ];

  static OTHER_SS = Other;
}

export const screenSaverService = new ScreenSaverService();

export default ScreenSaverService;
