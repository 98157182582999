/* istanbul ignore file */
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class RestrictionsService extends NewLibraryItemService {
  static appRestrictionsOptions = [
    {
      label: "Don't allow specific apps",
      value: 'disallow',
    },
    {
      label: 'Only allow specific apps',
      value: 'allow',
    },
  ];
}

export const restrictionsService = new RestrictionsService();

export default RestrictionsService;
