import { Banner, Icon, setClass } from '@kandji-inc/bumblebee';
import { Box } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';

import { devices } from 'features/library-items/data-service/library-item/devices';
import { Setting } from 'features/library-items/template';
import KandjiSupportLink from 'src/app/components/common/KandjiSupportLink';
import IPadLandscape from '../assets/iPad-switch-landscape';
import IPadPortrait from '../assets/iPad-switch-portrait';
import { DeviceTypes, ScreenTypes, Uploader } from '../uploader';

const OrientationSwitcher = ({ orientation, setOrientation, disabled }) => (
  <div className="ipad-orientation-switch">
    <div
      className={setClass([
        'switch',
        'portrait',
        orientation === DeviceTypes.IPAD_PORTRAIT && 'active',
        disabled && 'disabled',
      ])}
      onClick={() => !disabled && setOrientation(DeviceTypes.IPAD_PORTRAIT)}
    >
      <IPadPortrait />
    </div>
    <div
      className={setClass([
        'switch',
        'landscape',
        orientation === DeviceTypes.IPAD_LANDSCAPE && 'active',
        disabled && 'disabled',
      ])}
      onClick={() => !disabled && setOrientation(DeviceTypes.IPAD_LANDSCAPE)}
    >
      <IPadLandscape />
    </div>
  </div>
);

const ApplyLink = ({ variant, handleClick, disabled }) =>
  !disabled && (
    <a
      href=""
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
      className="b-alink"
    >
      Apply to {variant} screen
    </a>
  );

const CustomizeIpad = (props: any) => {
  const {
    update,
    isDisabled,
    setting,
    devices: runsOnDevices,
    showValidation,
  } = props;

  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000605067-configure-the-wallpaper-library-item"
      text="Learn more"
      className="b-alink"
    />
  );

  const [orientation, setOrientation] = useState(DeviceTypes.IPAD_PORTRAIT);
  const [isHoverPreviewLock, setIsHoverPreviewLock] = useState(false);
  const [isHoverPreviewHome, setIsHoverPreviewHome] = useState(false);
  const [lockScreenError, setLockScreenError] = useState(null);
  const [homeScreenError, setHomeScreenError] = useState(null);

  const handleApplyToHomeClick = () => {
    if (setting.lockScreen) {
      const newLockScreen = { ...setting.lockScreen };
      update('homeScreen', newLockScreen);
    }
    if (setting.lockScreenUrl) {
      update('homeScreenUrl', setting.lockScreenUrl);
      update('homeScreenS3Key', setting.lockScreenS3Key);
    }
    setIsHoverPreviewLock(false);
    setIsHoverPreviewHome(false);
  };

  const handleApplyToLockScreenClick = () => {
    if (setting.homeScreen) {
      const newHomeScreen = { ...setting.homeScreen };
      update('lockScreen', newHomeScreen);
    }
    if (setting.homeScreenUrl) {
      update('lockScreenUrl', setting.homeScreenUrl);
      update('lockScreenS3Key', setting.homeScreenS3Key);
    }
    setIsHoverPreviewLock(false);
    setIsHoverPreviewHome(false);
  };

  const [isLockScreenImage, setIsLockScreenImage] = useState(false);
  const [isHomeScreenImage, setIsHomeScreenImage] = useState(false);

  useEffect(() => {
    if (!!setting?.lockScreenUrl || !!setting?.lockScreen) {
      setIsLockScreenImage(true);
    } else {
      setIsLockScreenImage(false);
    }
  }, [setting?.lockScreenUrl, setting?.lockScreen]);

  useEffect(() => {
    if (!!setting?.homeScreenUrl || !!setting?.homeScreen) {
      setIsHomeScreenImage(true);
    } else {
      setIsHomeScreenImage(false);
    }
  }, [setting?.homeScreenUrl, setting?.homeScreen]);

  const removeLockScreen = () => {
    update('lockScreenUrl', '');
    update('lockScreenS3Key', '');
    update('lockScreen', null);
  };

  const removeHomeScreen = () => {
    update('homeScreenUrl', '');
    update('homeScreenS3Key', '');
    update('homeScreen', null);
  };

  const isIpadExcluded = !runsOnDevices?.find(
    ({ value }) => value === devices.IPAD,
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Customize iPad</h3>
      </Setting.Header>
      <Setting.Rows>
        <Box className="outerBox">
          <div className="top-description">
            {isIpadExcluded && (
              <Banner
                kind="block"
                theme={isDisabled ? 'info' : 'warning'}
                icon={isDisabled ? 'circle-info' : ''}
                className="b-mt-micro"
                style={{ padding: '8px' }}
              >
                <span>
                  Wallpaper for iPadOS will not be customized because iPad was
                  excluded in the device family assignment for this Library
                  item.
                </span>
              </Banner>
            )}
            <p
              className="b-txt the-recommended"
              style={isIpadExcluded ? { opacity: 0.4 } : {}}
            >
              The recommended resolution is 2732 x 2732 pixels with main content
              centered within a 2048 x 1536 pixel area (4:3 aspect ratio). If
              your image exceeds the recommended resolution, results on versions
              older than iPadOS 17 will not match this preview. You can upload
              PNG, JPG, JPEG, BMP, or TIFF files, as long as they are no larger
              than 5 MB. {supportLink}
            </p>
          </div>
          <Box className="innerBox">
            <div className="switcher-container">
              <OrientationSwitcher
                disabled={isIpadExcluded || isDisabled}
                orientation={orientation}
                setOrientation={setOrientation}
              />
            </div>
            <div className="device-container">
              <div className="ipad-container">
                <div className="ipad-wrapper">
                  <div className="ipad-inner">
                    <p className="b-txt">Lock screen</p>
                    <Uploader
                      setError={setLockScreenError}
                      onMouseEnter={() => setIsHoverPreviewLock(true)}
                      onMouseLeave={() => setIsHoverPreviewLock(false)}
                      isHoverPreview={isHoverPreviewLock}
                      screenType={ScreenTypes.LOCK}
                      deviceType={orientation}
                      disabled={isIpadExcluded || isDisabled}
                      className="b-mt1"
                      maxSizeAllowed={5e6}
                      icon={setting.lockScreenUrl || setting.lockScreen}
                      onRemove={() => {
                        removeLockScreen();
                      }}
                      onImage={(img) => {
                        update('lockScreenUrl', '');
                        update('lockScreenS3Key', '');
                        update('lockScreen', img);
                      }}
                      renderTip={({ icon }) => (
                        <>
                          {icon}
                          <p className="b-txt">
                            Drop files here or{' '}
                            <a
                              href=""
                              onClick={(e) => e.preventDefault()}
                              className={setClass([
                                'b-alink',
                                (isIpadExcluded || isDisabled) &&
                                  'b-alink--disabled',
                              ])}
                            >
                              select file
                            </a>
                          </p>
                          <p className="b-txt k-ssw-style-drawer-bg-tip">
                            {/* JPEG, PNG, HEIC */}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  <div className="apply-link-container">
                    {isLockScreenImage && !isHomeScreenImage && (
                      <ApplyLink
                        variant="home"
                        handleClick={handleApplyToHomeClick}
                        disabled={isDisabled || isIpadExcluded}
                      />
                    )}
                    {lockScreenError && (
                      <div className="wallpaper-upload-error">
                        <Icon
                          name="octagon-exclamation"
                          width={16}
                          height={16}
                        />
                        <p className="b-txt">{lockScreenError.message}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ipad-wrapper">
                  <div className="ipad-inner">
                    <p className="b-txt">Home screen</p>
                    <Uploader
                      setError={setHomeScreenError}
                      onMouseEnter={() => setIsHoverPreviewHome(true)}
                      onMouseLeave={() => setIsHoverPreviewHome(false)}
                      isHoverPreview={isHoverPreviewHome}
                      screenType={ScreenTypes.HOME}
                      deviceType={orientation}
                      disabled={isIpadExcluded || isDisabled}
                      className="b-mt1"
                      maxSizeAllowed={5e6}
                      icon={setting.homeScreenUrl || setting.homeScreen}
                      onRemove={() => {
                        removeHomeScreen();
                      }}
                      onImage={(img) => {
                        update('homeScreenUrl', '');
                        update('homeScreenS3Key', '');
                        update('homeScreen', img);
                      }}
                      renderTip={({ icon }) => (
                        <>
                          {icon}
                          <p className="b-txt">
                            Drop files here or{' '}
                            <a
                              href=""
                              onClick={(e) => e.preventDefault()}
                              className={setClass([
                                'b-alink',
                                (isIpadExcluded || isDisabled) &&
                                  'b-alink--disabled',
                              ])}
                            >
                              select file
                            </a>
                          </p>
                          <p className="b-txt k-ssw-style-drawer-bg-tip">
                            {/* JPEG, PNG, HEIC */}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  <div className="apply-link-container">
                    {isHomeScreenImage && !isLockScreenImage && (
                      <ApplyLink
                        variant="lock"
                        handleClick={handleApplyToLockScreenClick}
                        disabled={isDisabled || isIpadExcluded}
                      />
                    )}
                    {homeScreenError && (
                      <div className="wallpaper-upload-error">
                        <Icon
                          name="octagon-exclamation"
                          width={16}
                          height={16}
                        />
                        <p className="b-txt">{homeScreenError.message}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="helper-text-container">
              {!isIpadExcluded && showValidation && (
                <Icon name="octagon-exclamation" size="sm" />
              )}
              <p
                className={setClass([
                  'helperText',
                  !isIpadExcluded && showValidation && 'disabled',
                ])}
              >
                Upload at least one image to lock or home screen. If one screen
                is left empty, the device’s existing wallpaper will be
                preserved.
              </p>
            </div>
          </Box>
        </Box>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default CustomizeIpad;
