import { Button, Chip, Table } from '@kandji-inc/bumblebee';
import { wAmount } from 'features/util/misc';
import React, { useState } from 'react';
import ExpandedNotificationRow from '../../../../components/integration-details/expanded-notification-row';
import NoNotificationsMessage from '../../../../components/integration-details/no-content-message';
import { StyledOptionsMenu } from '../../../../components/styled-components';
import { SORT_ORDER } from '../../../../utils';
import channelChipIcon from '../../assets/channel-chip-icon.svg';

const IntegrationTable = (props) => {
  const { data, openNotificationEditor, onDeleteNotificationFromTable } = props;

  const [sortOrder, setSortOrder] = useState(SORT_ORDER.asc);

  // istanbul ignore next
  const sortedData = data?.notifications?.sort((a, b) =>
    a.name > b.name ? sortOrder : -sortOrder,
  );

  const integrationId = data?.integration_id;

  return (
    <Table
      className="integration-card_table"
      ExpandedComponent={ExpandedNotificationRow}
      data={sortedData}
      noDataMessage={
        <NoNotificationsMessage
          header="You have not set up any event notifications"
          text="Add an event notification below to be alerted on specific events that are triggered by the system on a device."
        />
      }
      sort={sortOrder === SORT_ORDER.asc ? 'name' : '-name'}
      setSort={() => {
        setSortOrder((prevState) => -1 * prevState);
      }}
      columns={[
        {
          fieldName: 'name',
          header: 'Notification Name',
          isSortable: true,
          ratio: 250,
          Cell: ({ item }) => (
            <div className="b-txt-bold integration-card_name-cell">
              <span>{item.name}</span>
            </div>
          ),
        },
        {
          fieldName: 'team_name',
          header: 'Team',
          ratio: 170,
          Cell: ({ item }) => (
            <div className="b-txt-light integration-card_name-cell">
              {item.team.name}
            </div>
          ),
        },
        {
          fieldName: 'channels',
          header: 'Channels',
          className: 'integration-card_channels-column',
          ratio: 428,
          Cell: ({ item }) => (
            <div className="integration-card_channels-cell">
              {item.channels.map((channel) => (
                <Chip
                  key={channel.id}
                  text={channel.name}
                  kind="primary"
                  asImage
                  iconLeft={channelChipIcon}
                />
              ))}
            </div>
          ),
        },
        {
          fieldName: 'actions',
          header: 'Event triggers',
          ratio: 212,
          className: 'integration-card_actions-column',
          Cell: ({ item }) => (
            <>
              <div className="b-txt-light">
                {wAmount(item.events.length, 'event trigger')}
              </div>

              <StyledOptionsMenu
                className="integration-card_options-btn"
                render={(close) => (
                  <>
                    <Button
                      icon="pencil"
                      kind="link"
                      onClick={() => {
                        close();
                        openNotificationEditor(item, integrationId);
                      }}
                    >
                      Edit event notification
                    </Button>
                    <Button
                      icon="trash-can"
                      kind="link"
                      onClick={() => {
                        close();
                        onDeleteNotificationFromTable(item);
                      }}
                      theme="error"
                    >
                      Delete event notification
                    </Button>
                  </>
                )}
              >
                <div data-testid={`notification-row:${item.name}`}>
                  <Button
                    icon="ellipsis"
                    kind="link"
                    theme="action"
                    data-testid="test-me"
                    name="test-me"
                  />
                </div>
              </StyledOptionsMenu>
            </>
          ),
        },
      ]}
    />
  );
};

export default IntegrationTable;
