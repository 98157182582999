import {
  BoolFilterType,
  DateFilterType,
  EnumFilterType,
  FilterSearchButton,
  Flex,
  NumFilterType,
  StringFilterType,
  TextField,
  TimeFilterType,
  VersionFilterType,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import {
  createApplyFilter,
  createClearFilter,
  createRemoveFilter,
  getInitialFilterOptions,
} from '../components/PrismTable/utils/prismFiltersUtils';
import { usePrismViewsContext } from '../contexts/PrismViewsContext';
import { useSyncUrlWithTableState } from '../hooks/use-sync-url-with-table-state';
import type { PrismCategoryFilterProperties as ColFilters } from '../types/filter.types';

const PrismViewFilters = ({
  search,
  setSearch,
}: { search: string; setSearch: Dispatch<SetStateAction<string>> }) => {
  const { columns, prismCategorySchemas } = usePrismViewsContext();
  const { filter, removeFilter, setFilter } =
    useSyncUrlWithTableState('/devices');

  const filterOptions = React.useMemo(() => {
    return parseFilterOptionMetaFromColumnDefs({
      columns: (columns?.columnDefs || []).map((def) => ({ columnDef: def })),
      columnSchemas: prismCategorySchemas,
      columnFilters: filter,
    });
  }, [columns, prismCategorySchemas, filter]);

  const columnFilters = React.useMemo(() => {
    if (filter == null) {
      return [];
    }
    return Object.entries(filter)
      .map(getInitialFilterOptions(filterOptions))
      .filter(Boolean);
  }, [filter, filterOptions]);

  const handleFilterSelect = React.useCallback(
    (filterKey: string) => {
      setFilter({ [filterKey]: '' });
    },
    [setFilter],
  );

  return (
    <Flex flow="row" wrap="wrap" gap="sm">
      <TextField
        iconLeft
        compact
        showClearButton={search.length > 0}
        onClear={() => setSearch('')}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        icon="magnifying-glass"
        type="input"
        placeholder="Search"
        css={{ width: '240px', flex: 'none' }}
      />
      {prismCategorySchemas &&
        columnFilters?.map((filter: ColFilters['unionOf']['details']) => {
          const handleApply = createApplyFilter(
            'device_views',
            filter,
            setFilter,
          );

          const handleRemove = createRemoveFilter(
            'device_views',
            filter,
            removeFilter,
          );

          const handleClear = createClearFilter(
            'device_views',
            filter,
            removeFilter,
          );

          const handleCancel = ({ isFilterEmpty }) => {
            if (isFilterEmpty) {
              handleRemove();
            }
          };

          const dropdownProps = {
            css: {
              zIndex: 10,
            },
            defaultOpen: !!(filter.value == null || filter.value.length === 0),
            contentProps: {
              align: 'start' as const,
              preventOutsideInteraction: true,
            },
          };

          const filterProps = {
            showRemove: true,
            key: filter.key,
            dropdownProps,
            handleApply,
            handleCancel,
            handleClear,
            handleRemove,
          };

          // istanbul ignore next
          switch (filter.type) {
            case 'string': {
              return (
                <StringFilterType
                  hideNullOperators={filter.key === 'blueprint_name'}
                  filter={filter}
                  {...filterProps}
                />
              );
            }

            case 'enum':
              return (
                <EnumFilterType
                  multi
                  filter={filter}
                  enumOptions={filter.enumOptions}
                  {...filterProps}
                />
              );

            case 'date-time':
              return <DateFilterType filter={filter} {...filterProps} />;

            case 'boolean': {
              return (
                <BoolFilterType
                  filter={filter}
                  boolOptions={filter.boolOptions}
                  {...filterProps}
                />
              );
            }

            case 'number': {
              return <NumFilterType filter={filter} {...filterProps} />;
            }

            case 'time': {
              return <TimeFilterType filter={filter} {...filterProps} />;
            }

            case 'version': {
              return <VersionFilterType filter={filter} {...filterProps} />;
            }

            default:
              return null;
          }
        })}

      <FilterSearchButton
        text={columnFilters.length ? '' : 'Add Filter'}
        asButton={columnFilters.length ? 'button' : 'filter-button'}
        options={filterOptions}
        onFilterSelect={handleFilterSelect}
        componentCss={{
          menu: {
            zIndex: 11,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '$1',
            },
            '&:hover': {
              '&::-webkit-scrollbar-track': {
                background: 'rgba(243, 247, 250)',
                borderRadius: '$rounded',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(80, 94, 113, 0.24)',
                borderRadius: '$rounded',
                height: '50px',
              },
            },
          },
        }}
      />
    </Flex>
  );
};

export default PrismViewFilters;

function parseFilterOptionMetaFromColumnDefs({
  columns,
  columnSchemas,
  columnFilters,
}: {
  columns: any[];
  columnSchemas: {} | undefined;
  columnFilters: {};
}) {
  const filterOptions = columns
    ?.map((col) => {
      const colMeta = col.columnDef?.meta;

      if (!colMeta?.filterType) {
        return undefined;
      }

      const disabled = columnFilters[col.columnDef.id] != null;

      let [category, name] = col.columnDef.id.split('.');
      // TODO: Remove this once we have schemas for global columns
      if (name == null) {
        category = 'device_information';
      }
      const columnSchema = columnSchemas ? columnSchemas[category] : null;

      return {
        label: col.columnDef.header,
        value: col.columnDef.id,
        icon: colMeta.filterIcon,
        disabled,
        tooltip: disabled ? 'Already an active filter' : null,
        meta: {
          ...colMeta,
          ...(colMeta.filterType === 'enum'
            ? {
                filterOptions:
                  colMeta.filterOptions ||
                  columnSchema?.find((schema) => schema?.value === col.id)
                    ?.enumOptions,
              }
            : {}),
        },
      };
    })
    .filter(Boolean)
    .sort((a, b) =>
      a.label.localeCompare(b.label, undefined, {
        sensitivity: 'base',
        ignorePunctuation: true,
      }),
    );

  return filterOptions;
}
