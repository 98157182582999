import { Button, TextInput } from '@kandji-inc/bumblebee';
import { addAppModes } from 'features/library-items/items/wifi/fast-lane-card/fast-lane-card.model';
import React, { useState, useMemo } from 'react';
import { useSearchedAppsActions } from '../../../fast-lane-card.hooks';
import EmptyResults from './empty-results';
import Item from './item';

const getFilteredApps = (apps, searchText) => {
  if (!searchText) {
    return [];
  }
  return apps.filter((app) => {
    const { name } = app;
    if (!name) {
      return false;
    }
    return name.toLowerCase().includes(searchText.toLowerCase());
  });
};

const SearchApp = ({ update, setting, apps, appsLoaded, setAddAppMode }) => {
  const { isAppAdded, toggleApp } = useSearchedAppsActions({
    update,
    setting,
  });
  const [searchText, setSearchText] = useState('');
  const filteredApps = useMemo(
    () => getFilteredApps(apps, searchText),
    [apps, searchText],
  );
  const showApps = filteredApps.length > 0;
  const showEmptyResultsBlock =
    appsLoaded && searchText && !filteredApps.length;

  return (
    <>
      <div className="k-ss2-drawer-setting-extra k-wifiv2-protocols-pkcs-drawer__row">
        <div>
          <p className="b-txt-bold k-wifiv2-drawer__row-header k-wifiv2-drawer__row-header--big">
            Search by name
          </p>
        </div>
        <div className="k-wifiv2-fastlane-drawer__search-input">
          <TextInput
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            icon={searchText ? 'circle-xmark' : 'magnifying-glass'}
            onIconClick={() => setSearchText((prev) => (prev ? '' : prev))}
          />
        </div>
      </div>
      {showApps && (
        <div className="k-ss2-drawer-setting-extra k-wifiv2-protocols-pkcs-drawer__row --no-padding-bottom --no-padding-top">
          <div className="k-wifiv2-fastlane-drawer__search-result">
            {filteredApps.map((app) => {
              const { id, data } = app;
              const isAdded = isAppAdded(data.bundle_id);

              return (
                <Item
                  app={app}
                  isAdded={isAdded}
                  toggleApp={toggleApp}
                  key={id}
                />
              );
            })}
          </div>
        </div>
      )}
      {showEmptyResultsBlock && <EmptyResults />}
      <div className="k-ss2-drawer-setting-extra k-wifiv2-protocols-pkcs-drawer__row">
        <Button
          kind="link"
          onClick={() => setAddAppMode(addAppModes.CUSTOM)}
          icon="circle-plus"
        >
          Add Bundle ID
        </Button>
      </div>
    </>
  );
};

export default SearchApp;
