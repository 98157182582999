import { Button, Flex } from '@kandji-inc/nectar-ui';
import type * as React from 'react';
import type {
  Blueprint,
  DeviceFamily,
} from 'src/features/visibility/prism/hooks';

type GlobalFilterModalFooterProps = {
  handleApplyfilters: () => void;
  setEditViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBlueprints: React.Dispatch<React.SetStateAction<Blueprint[]>>;
  setSelectedDeviceFamilies: React.Dispatch<
    React.SetStateAction<DeviceFamily[]>
  >;
  setBlueprintFilterOption: React.Dispatch<React.SetStateAction<string>>;
};

const GlobalFilterModalFooter = ({
  handleApplyfilters,
  setEditViewModalOpen,
  setSelectedBlueprints,
  setSelectedDeviceFamilies,
  setBlueprintFilterOption,
}: GlobalFilterModalFooterProps) => (
  <Flex>
    <Button
      variant="subtle"
      onClick={() => {
        setSelectedBlueprints([]);
        setSelectedDeviceFamilies(['Mac', 'iPhone', 'iPad', 'AppleTV']);
        setBlueprintFilterOption('all_blueprints');
      }}
    >
      Reset to default
    </Button>
    <Flex css={{ ml: 'auto' }} gap="sm">
      <Button variant="default" onClick={() => setEditViewModalOpen(false)}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleApplyfilters}>
        Apply
      </Button>
    </Flex>
  </Flex>
);

export default GlobalFilterModalFooter;
