import { Box, Heading, Tabs } from '@kandji-inc/nectar-ui';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { links } from 'src/app/common/constants';
import { TARIFF_NOTIFICATION_ID } from 'src/components/region/DisabledTariffNotificator';
import featureFlags from 'src/config/feature-flags';
import { InterfaceContext } from 'src/contexts/interface';
import { Vulnerabilities } from '../vulnerability';
import ThreatList from './ThreatList';
import { THREAT_PATHS } from './route';

const tabsContainerCss = {
  '& > div[data-nectar-styled-name="group"]': {
    zIndex: 1,
  },
};

type Tab = { path: string; label: string | JSX.Element };
type AvailableTabs = { threats: Tab; vulnerability?: Tab };

const getTab = ({
  availablePaths,
  url,
}: { availablePaths: AvailableTabs; url: string }) => {
  const paths = Object.values(availablePaths);
  const tab = paths.find((path) => url.includes(path.path));
  return tab?.path || THREAT_PATHS.threats.path;
};

const noop = (v: any) => v;

const ThreatTabs = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { bannerTopOffset } = useContext(InterfaceContext);
  const accountLimitBannerHeight =
    document.getElementById(TARIFF_NOTIFICATION_ID)?.clientHeight || 0;

  const vulnFlag = featureFlags.getFlag('vm_06252024_vuln-poc');

  const AVAILABLE_THREAT_PATHS = vulnFlag
    ? THREAT_PATHS
    : { threats: THREAT_PATHS.threats };

  const tabs = {
    threats: ThreatList,
    ...(vulnFlag ? { vulnerability: Vulnerabilities } : {}),
  };

  const currentTab = getTab({
    availablePaths: AVAILABLE_THREAT_PATHS,
    url: match.url,
  });

  const threatPageStyle = {
    margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
    height: `calc(100vh - ${bannerTopOffset + accountLimitBannerHeight}px)`,
    position: 'relative',
    backgroundColor: '$neutral0',
    overflow: 'hidden',

    "& > div > div > [role='tablist']": {
      padding: '0 24px',
    },
  };

  const tabsContentStyle = {
    height: `calc(100vh - ${bannerTopOffset + accountLimitBannerHeight}px)`,
    overflow: 'auto',
  };

  return (
    <Box pt4 css={threatPageStyle}>
      <Heading size="1" css={{ padding: '$2 $5 $5' }}>
        Threats & Vulnerabilities
      </Heading>

      <Tabs.Container
        css={tabsContainerCss}
        compact
        tabId={currentTab}
        defaultTabId={currentTab}
        tabs={Object.keys(tabs).map((tab) => {
          const threatPath =
            AVAILABLE_THREAT_PATHS[tab as keyof typeof AVAILABLE_THREAT_PATHS];
          return {
            label: threatPath.label,
            tabId: threatPath.path,
            onClick: /* istanbul ignore next */ () =>
              history.push(`${links.threat}/${threatPath.path}`),
          };
        })}
      >
        {Object.keys(tabs).map((tab) => {
          const ThreatComponent = tabs[tab as keyof typeof tabs] || noop;
          return (
            <Tabs.Content
              tabId={
                AVAILABLE_THREAT_PATHS[
                  tab as keyof typeof AVAILABLE_THREAT_PATHS
                ].path
              }
              key={tab}
            >
              <Box css={tabsContentStyle}>
                <ThreatComponent />
              </Box>
            </Tabs.Content>
          );
        })}
      </Tabs.Container>
    </Box>
  );
};

export { ThreatTabs };
