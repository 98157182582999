import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';
import { updateComputer } from 'src/app/_actions/computer';

import { toggleRemoteDesktop } from 'src/app/components/computer/api';

const RemoteDesktop = (props) => {
  const { computer, info, isEnabled, onHide } = props;
  const { computerId, computerName } = info;

  const onOffText = isEnabled ? 'on' : 'off';

  const [isLoading, setIsLoading] = useState(false);

  const handleOnConfirm = () => {
    setIsLoading(true);
    toggleRemoteDesktop(computerId, isEnabled)
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster(
          `Command sent to turn ${onOffText} Remote Desktop for ${computerName}.`,
        );

        updateComputer({
          ...computer,
          security_information: {
            ...computer?.security_information,
            enable_remote_desktop: !isEnabled,
          },
        });
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster('Something went wrong.');
      });
  };

  const content = isEnabled ? (
    <Flex flow="column" gap="md">
      <Text>
        Turning on Remote Desktop allows all users to observe and control the
        Mac using Apple Remote Desktop or Screen Sharing. macOS will also
        indicate to users in the menu bar when they are being observed.
      </Text>
      <Text>
        Once turned on, more granular settings can be configured using a custom
        script.{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://support.kandji.io/support/solutions/articles/72000590260"
        >
          <Text
            variant="primary"
            css={{
              display: 'inline-block',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Learn more...
          </Text>
        </a>
      </Text>
    </Flex>
  ) : (
    <Text>
      Turning off Remote Desktop will stop the ability for any user to observe
      or control the Mac using Apple Remote Desktop or Screen Sharing.
    </Text>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" loading={isLoading} onClick={handleOnConfirm}>
        Confirm
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title={`Turn ${onOffText} Remote Desktop`}
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default RemoteDesktop;
