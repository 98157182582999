import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { closeModalDefaults } from '../../initial-state';

function ModalFooter(props) {
  const { model, setModel } = props;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        position: 'relative',
      }}
    >
      {model.isExisting && (
        <Button
          style={{ position: 'absolute', top: '0', left: '0' }}
          theme="error"
          kind="link"
          onClick={() =>
            setModel((prev) => ({
              ...prev,
              ...closeModalDefaults,
              items: prev.items.filter(
                (_, innerIdx) => innerIdx !== prev.itemIdx,
              ),
            }))
          }
        >
          Delete
        </Button>
      )}
      <Button
        kind="outline"
        style={{ marginRight: '16px' }}
        onClick={() => {
          setModel((prev) => ({
            ...prev,
            ...closeModalDefaults,
          }));
        }}
      >
        Cancel
      </Button>
      <Button
        isDisabled={
          !model.editItem.identifier || !model.editItem.identifierType
        }
        onClick={() => {
          const newItems = model.items.concat();
          newItems[model.itemIdx] = model.editItem;
          setModel((prev) => ({
            ...prev,
            ...closeModalDefaults,
            items: newItems,
          }));
        }}
      >
        Save
      </Button>
    </div>
  );
}

export default ModalFooter;
