import {
  Flex,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import './san-raw.css';

import EthernetService from '../../../service/ethernet-service';

const sanTypeOptions = [
  {
    value: EthernetService.subjectAlternativeNameTypes.DNS,
    label: 'DNS Name',
  },
  {
    value: EthernetService.subjectAlternativeNameTypes.RFC,
    label: 'RFC 822 Name',
  },
  {
    value: EthernetService.subjectAlternativeNameTypes.URI,
    label: 'Uniform Resource Identifier',
  },
  {
    value: EthernetService.subjectAlternativeNameTypes.UPN,
    label: 'NT Principal Name',
  },
];

const SanRaw = (props) => {
  const {
    allSettings,
    setting,
    updateValidation,
    validationDep,
    isDisabled,
    update,
    refs: drawerRefs,
    isVisible,
    allowOnlySingleNtPrincipal,
  } = props;
  const { type, value, _id } = setting;
  const fieldsToValidate = [`san-type_${_id}`, `san-value_${_id}`];
  const { refs, onInvalidate } = useInputsValidators(
    fieldsToValidate,
    updateValidation,
  );
  useEffect(() => {
    onInvalidate(fieldsToValidate[0])(!type);
  }, [type]);

  useRemoveValidationOnUnmount(fieldsToValidate, updateValidation);
  const isSubmitted = useIsChanged(validationDep, isVisible);

  const selectedTypeOption = sanTypeOptions.find(
    (option) => option.value === type,
  );

  const isSelectErrorNtPrincipal =
    allowOnlySingleNtPrincipal &&
    isSubmitted &&
    type === EthernetService.subjectAlternativeNameTypes.UPN &&
    allSettings?.filter(
      ({ type: optionType }) =>
        optionType === EthernetService.subjectAlternativeNameTypes.UPN,
    )?.length > 1;

  return (
    <div ref={refs[0]} className="k-ethernet-li__san-inputs">
      <div
        className="b-txt b-mb1"
        ref={
          drawerRefs &&
          type === EthernetService.subjectAlternativeNameTypes.UPN &&
          EthernetService.keys.authentication.scep.sanUpn in drawerRefs
            ? drawerRefs[EthernetService.keys.authentication.scep.sanUpn]
            : null
        }
      >
        SAN type
      </div>
      <Flex className="react-select-test" gapType="gap2">
        <Flex direction="col" className="b-flex1">
          <Select
            isDisabled={isDisabled}
            options={sanTypeOptions}
            compact
            placeholder="Select the SAN type"
            onChange={(v) => update('type', v.value)}
            value={selectedTypeOption}
            errorText={
              isSubmitted && !type
                ? 'Required'
                : isSelectErrorNtPrincipal
                  ? 'Note: Only one NT Principal name is supported.'
                  : ''
            }
            isOptionDisabled={(option) =>
              allowOnlySingleNtPrincipal &&
              option.value ===
                EthernetService.subjectAlternativeNameTypes.UPN &&
              allSettings?.filter(
                ({ type: optionType }) =>
                  optionType ===
                  EthernetService.subjectAlternativeNameTypes.UPN,
              )?.length
            }
            testId="san-raw"
          />
          {allowOnlySingleNtPrincipal &&
            type === EthernetService.subjectAlternativeNameTypes.UPN &&
            !isSelectErrorNtPrincipal && (
              <p className="b-txt-light">
                Note: Only one NT Principal name is supported.
              </p>
            )}
        </Flex>
        <TextInput
          disabled={isDisabled}
          value={value}
          onChange={(e) => update('value', e.target.value)}
          compact
          placeholder="Enter the subject alternative name"
          validator={(v) => [
            onEmptyBlurValidator(v, {
              trigger: ['onBlur', validationDep],
            }),
          ]}
          onInvalidate={onInvalidate(fieldsToValidate[1])}
          data-testid="san-value"
        />
      </Flex>
    </div>
  );
};

export default SanRaw;
