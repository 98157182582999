import { TextInput } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';

import { AddableTextField } from '../common';

const Kerberos = (props) => {
  const { setting, update, validationDep, isDisabled } = props;
  return (
    <>
      <Setting.Row>
        <Setting.Title className="b-txt">
          Realm <span className="b-txt-light">(optional)</span>
        </Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>Properly capitalized realm name for the credential.</p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            compact
            disabled={isDisabled}
            value={setting.realm}
            onChange={(e) => update('realm', e.target.value)}
            placeholder="accuhive.io"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title className="b-txt">Hosts</Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>
            The domains or host names which can be authenticated through the app
            extension. Names must be unique across all Single Sign-On Extension
            profiles installed on a device.
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <AddableTextField
            validationDep={validationDep}
            isDisabled={isDisabled}
            update={update}
            items={setting.hosts}
            settingKey="hosts"
            placeholder="identity.accuhive.io"
            isRequired={false}
          />
        </Setting.Controls>
      </Setting.Row>
    </>
  );
};

export default Kerberos;
