import {
  Box,
  Button,
  Card,
  Chip,
  Flex,
  Grid,
  Heading,
  Icon,
  Switch,
  Text,
  TextField,
  Toaster_UNSTABLE as Toaster,
  styled,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import deepcopy from 'deepcopy';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { UserRoles } from 'src/app/common/constants';
import { Loader } from 'src/app/components/interface/Loader';
import useAccount from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import {
  BP_PARAMS_VALIDATION_FIELDS,
  parameterComponents,
  parametersWithAlerts,
} from 'src/features/blueprints/blueprint/blueprint-parameters/parameter-details';
import useSyncValidations from 'src/features/blueprints/blueprint/blueprint-parameters/use-sync-validations';
import { paths } from 'src/features/blueprints/common';
import useAdjustSidebarChatBubble from 'src/features/integrations/hooks/use-adjust-sidebar-chat-bubble';
import {
  deviceTypes,
  osPrefixes,
} from 'src/features/library-items/library/common';
import { useBlueprints } from 'src/features/visibility/prism/hooks';

import { noParameters } from '../../assets';
import type {
  Blueprint,
  BlueprintParameterValue,
} from '../../blueprint-flow.types';
import { Empty } from '../../components';
import {
  FLOW_PATHS,
  SIDEBAR_DOCKED_WIDTH,
  SIDEBAR_TOASTER_PADDING,
  SIDEBAR_WIDTH,
} from '../../constants';
import { pluralizeString, pluralizeWord } from '../../helpers';
import useBlueprint from '../../services/use-blueprint';
import useParameters from '../../services/use-parameters';
import useBlueprintFlow from '../../store';
import {
  ParameterDropdownFilter,
  ParameterImportButton,
  ParametersNavItem,
  SideNavigation,
} from './components';
import { onParameterScroll, onScrollToParameter } from './helpers';
import type { ActiveCategory, Parameter } from './parameter.types';

const ParameterChip = styled(Chip, {
  padding: '2px 6px',
  border: '1px solid $neutral30',
  backgroundColor: '$neutral0 !important',
  color: '$neutral60 !important',
  fontSize: '$1 !important',
  fontWeight: '$medium !important',
  lineHeight: '$1 !important',
});

const paramsFieldsToValidate = Object.keys(BP_PARAMS_VALIDATION_FIELDS);
const defaultFilters = { term: '', framework: [], compatibility: [] };

type ParametersProps = {
  headerHeight: number;
};
const Parameters = (props: ParametersProps) => {
  const { headerHeight } = props;
  useAdjustSidebarChatBubble();
  const { userRole } = useAccount();
  const isUserHelpDesk = userRole === UserRoles.helpdesk;
  const isAuditor = userRole === UserRoles.auditor;
  const { id } = useParams<{ id: string }>();
  const match = useRouteMatch<{ isEditing: string }>();
  const history = useHistory();
  const { sidebarDocked } = useContext(InterfaceContext);
  const { toast } = useToast();

  const { patch, isLoading: isPendingPatch } = useBlueprint();
  const { data: allBlueprints, isLoading: isLoadingAllBlueprints } =
    useBlueprints();
  const { data, isLoading, isFetched } = useParameters();
  const [isImportingParameters, setIsImportingParameters] = useState(false);
  const sidebarWidth = sidebarDocked ? SIDEBAR_DOCKED_WIDTH : SIDEBAR_WIDTH;
  const [blueprint, isEditing, setIsEditing] = useBlueprintFlow((state) => [
    state.blueprint,
    state.isEditingParameters,
    state.setIsEditingParameters,
  ]);
  const paramsContainerRef = useRef<HTMLDivElement>(null);
  const [parametersModel, setParametersModel] = useState<
    Record<string, BlueprintParameterValue>
  >({});
  const [activeCategory, setActiveCategory] = useState<ActiveCategory>();
  const [filters, setFilters] = useState(deepcopy(defaultFilters));

  const [isSubmitted, setIsSubmitted] = useState(false);
  const { validations, validationRootRef, createValidationTrigger } =
    useSyncValidations({
      namespace: 'am-params',
      fieldsToValidate: paramsFieldsToValidate,
      triggerOn: (isSubmittedUpdate) => Boolean(isSubmittedUpdate),
      displayOn: () => Boolean(isSubmitted),
    });
  const setIsSubmittedWithValidation = createValidationTrigger(setIsSubmitted);

  const onToggle = (
    param: Parameter,
    isEnabled: boolean,
    isMuted = param.default?.is_mute || false,
    allParameters: Record<string, Parameter>,
  ) =>
    setParametersModel((prev) => {
      if (!isEnabled) {
        Object.keys(prev).forEach((id) => {
          const p = allParameters[id];
          if (p.dependency === param.id) {
            delete prev[id];
          }
        });
        delete prev[param.id];
      } else {
        prev[param.id] = {
          ...(prev[param.id] || {}),
          daily: param.default?.is_daily,
          mute: isMuted,
        };
      }

      return {
        ...prev,
      };
    });

  const getOnParameterUpdate =
    (id) =>
    (obj, del = []) =>
      setParametersModel((prev) => {
        if (obj) {
          prev[id] = {
            ...prev[id],
            ...obj,
          };
        }
        del.forEach((k) => delete prev[id][k]);
        return {
          ...prev,
        };
      });

  const afterAction = ({
    isEditing,
    revertToBeforeEdit,
    scrollToTopContainer,
  }) => {
    if (isEditing) {
      history.push(
        `${paths.flowBlueprint(id, FLOW_PATHS.parameters.path)}/edit`,
      );
    } else {
      history.push(`${paths.flowBlueprint(id, FLOW_PATHS.parameters.path)}`);
    }
    setIsEditing(isEditing);

    if (revertToBeforeEdit) {
      setParametersModel(deepcopy(blueprint.params) || {});
    }

    if (scrollToTopContainer) {
      setTimeout(() => {
        paramsContainerRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const onSave = () => {
    setIsSubmittedWithValidation(true);
    if (
      Object.values(parametersModel).filter(
        (paramValue) => paramValue.isInvalid,
      ).length
    ) {
      toast({
        title: 'Error!',
        content: 'Cannot save blueprint, errors exist.',
        variant: 'error',
        style: {
          left: `${sidebarWidth + SIDEBAR_TOASTER_PADDING}px`,
          bottom: '12px',
          position: 'absolute',
        },
      });
      return;
    }

    const paramsWithoutExtras = Object.keys(parametersModel).reduce((a, c) => {
      const curr = parametersModel[c];
      delete curr.parameter;
      delete curr.isInvalid;

      const createUserAccountsId = 'bb40515e-4b28-48fc-9596-59c8db14a7a3';

      if (c === createUserAccountsId && curr?.details?.length) {
        curr.details.forEach((userAcct) => {
          delete userAcct.repeatPassword;
          delete userAcct.isInvalid;
        });
      }

      return {
        ...a,
        [c]: curr,
      };
    }, {});

    patch({ ...blueprint, params: paramsWithoutExtras })
      .then(() =>
        afterAction({
          isEditing: false,
          revertToBeforeEdit: false,
          scrollToTopContainer: true,
        }),
      )
      .finally(() => setIsSubmittedWithValidation(false));
  };

  useEffect(() => {
    const isLandingOnEdit = Boolean(match.params.isEditing);
    afterAction({
      isEditing: isLandingOnEdit,
      revertToBeforeEdit: true,
      scrollToTopContainer: false,
    });
    return () => {
      setIsEditing(false);
    };
  }, []);

  useEffect(() => {
    const onScroll = () =>
      onParameterScroll(
        filteredParamsByCategory,
        paramsContainerRef.current,
        setActiveCategory,
      );

    if (!isLoading && paramsContainerRef.current) {
      paramsContainerRef.current.addEventListener('scroll', onScroll);

      onParameterScroll(
        filteredParamsByCategory,
        paramsContainerRef.current,
        setActiveCategory,
      );
    }

    return () =>
      paramsContainerRef.current?.removeEventListener('scroll', onScroll);
  }, [isLoading, isEditing]);

  const filterParameter = (parameter: Parameter) => {
    const parameterTags = parameter.tags.filter((tag) => !tag.hidden);
    const parameterCompatibility = parameter.compatibility;

    return (
      (parameter.name.toLowerCase().includes(filters.term.toLowerCase()) ||
        parameter.description
          .toLowerCase()
          .includes(filters.term.toLowerCase())) &&
      (!filters.framework.length ||
        parameterTags.some((tag) => filters.framework.includes(tag.name))) &&
      (!filters.compatibility.length ||
        filters.compatibility.some(
          (compatibility) =>
            Number.parseFloat(
              parameterCompatibility.replace(/[^0-9\.]+/g, ''),
            ) <= Number.parseFloat(compatibility),
        ))
    );
  };

  const { tags } = data || {};
  const { paramsByCategory, parametersById } = data?.parameters || {};
  const filteredParamsByCategory = useMemo(
    (): any[] =>
      paramsByCategory
        ?.map((category) => ({
          ...category,
          subcategories: category.subcategories
            .map((subcategory) => ({
              ...subcategory,
              parameters: subcategory.parameters.filter(
                (sParam) =>
                  (isEditing || blueprint.params[sParam.id]) &&
                  filterParameter(sParam),
              ),
            }))
            .filter((subcategory) => subcategory.parameters.length),
        }))
        .filter((category) => category.subcategories.length),
    [isEditing, paramsByCategory, blueprint, filters],
  );
  const frameworkTags = tags?.filter(({ kind }) => kind === 'framework');
  const compatibilityTags = tags?.filter(
    ({ kind }) => kind === 'compatibility',
  );
  const isDisabled =
    !isEditing || isPendingPatch || isAuditor || isUserHelpDesk;
  const isShowEmptyState =
    isFetched && !isEditing && !Object.keys(blueprint.params).length;
  const isFilterActive = useMemo(
    () => JSON.stringify(filters) !== JSON.stringify(defaultFilters),
    [filters],
  );
  const isShowNoResultsState =
    isFilterActive && !filteredParamsByCategory.length;

  if (isLoading) {
    return (
      <Flex hFull alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  if (isShowEmptyState) {
    const blueprintsWithParams: Array<Blueprint> = (allBlueprints || []).filter(
      ({ params }) => Object.keys(params).length > 0,
    );
    return (
      <Empty
        image={noParameters}
        title={isImportingParameters ? '' : 'No Parameters added'}
        message={
          isImportingParameters
            ? 'Importing Parameters...'
            : 'Add Parameters to set additional configurations for devices in this Blueprint.'
        }
        link={{
          render: isImportingParameters ? (
            <Loader />
          ) : (
            <Flex gap="sm">
              <Button
                variant="default"
                onClick={() =>
                  afterAction({
                    isEditing: true,
                    revertToBeforeEdit: true,
                    scrollToTopContainer: false,
                  })
                }
                disabled={isUserHelpDesk || isAuditor}
              >
                Add Parameters
              </Button>
              {Boolean(blueprintsWithParams.length) && (
                <ParameterImportButton
                  blueprints={blueprintsWithParams}
                  disabled={
                    isUserHelpDesk || isAuditor || isLoadingAllBlueprints
                  }
                  onImport={({ name: importedName, params }) => {
                    const paramsCount = Object.keys(params).length;
                    setIsImportingParameters(true);
                    patch({ ...blueprint, params }, { withToaster: false })
                      .then(() =>
                        toast({
                          title: 'Success',
                          content: `${paramsCount} ${pluralizeWord(
                            'Parameter',
                            paramsCount,
                          )} ${pluralizeString(
                            paramsCount,
                            'was',
                            'were',
                          )} imported from ${importedName}.`,
                          variant: 'success',
                          style: {
                            left: `${sidebarWidth + SIDEBAR_TOASTER_PADDING}px`,
                            bottom: '12px',
                            position: 'absolute',
                          },
                        }),
                      )
                      .catch(() =>
                        toast({
                          title: 'Error',
                          content: `Failed to import Parameters from ${importedName}.`,
                          variant: 'error',
                          style: {
                            left: `${sidebarWidth + SIDEBAR_TOASTER_PADDING}px`,
                            bottom: '12px',
                            position: 'absolute',
                          },
                        }),
                      )
                      .finally(() => setIsImportingParameters(false));
                  }}
                />
              )}
            </Flex>
          ),
          show: true,
        }}
        fullHeight
      />
    );
  }

  return (
    <Box data-testid="am-parameters" hFull>
      <Flex>
        <SideNavigation
          css={{
            height: `calc(100vh - ${
              isEditing ? headerHeight + 61 : headerHeight
            }px)`,
          }}
        >
          <Flex flow="column" gap="xs">
            {filteredParamsByCategory.map((category) => {
              const { id, name, subcategories } = category;
              const isActiveCategory = activeCategory?.category === id;

              return (
                <Flex key={id} flow="column" gap="xs">
                  <ParametersNavItem
                    isHeader
                    selected={isActiveCategory}
                    onClick={() =>
                      onScrollToParameter(
                        `${name}-${id}`,
                        paramsContainerRef.current,
                      )
                    }
                    data-testid={`am-parameters-sidenav-${name}-${id}`}
                  >
                    {name}
                  </ParametersNavItem>
                  <Flex flow="column" gap="xs">
                    {subcategories.map((subcategory) => {
                      const { name: subcategoryName, id: subcategoryId } =
                        subcategory;
                      const isActiveSubcategory =
                        isActiveCategory &&
                        activeCategory?.subCategory === subcategoryId;

                      if (!isActiveCategory) {
                        return null;
                      }

                      return (
                        <ParametersNavItem
                          key={subcategoryName}
                          selected={isActiveSubcategory}
                          onClick={() =>
                            onScrollToParameter(
                              `${subcategoryName}-${subcategoryId}`,
                              paramsContainerRef.current,
                            )
                          }
                          data-testid={`am-parameters-sidenav-${subcategoryName}-${subcategoryId}`}
                        >
                          {subcategoryName}
                        </ParametersNavItem>
                      );
                    })}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </SideNavigation>

        <Box hFull ref={validationRootRef} css={{ flex: 1 }}>
          <Flex
            justifyContent="space-between"
            gap="sm"
            p2
            pr5
            css={{
              backgroundColor: '$neutral0',
              borderBottom: '1px solid rgba(53, 65, 78, 0.24)',
            }}
          >
            <Flex gap="sm" alignItems="center">
              <TextField
                compact
                placeholder="Search Parameters"
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, term: e.target.value }))
                }
                icon="magnifying-glass"
                css={{ maxWidth: '240px' }}
                data-testid="am-parameters-search"
              />
              <ParameterDropdownFilter
                name="Compliance framework"
                options={frameworkTags.map(({ name }) => ({
                  key: name,
                  label: name,
                  value: name,
                }))}
                value={filters.framework}
                onChange={(selected) =>
                  setFilters((prev) => ({
                    ...prev,
                    framework: selected,
                  }))
                }
                onClear={() =>
                  setFilters((prev) => ({
                    ...prev,
                    framework: [],
                  }))
                }
                filtersSelectedCount={filters.framework.length}
              />
              <ParameterDropdownFilter
                name="Minimum macOS version"
                options={compatibilityTags.map(({ name }) => ({
                  key: name,
                  label: name,
                  value: name,
                }))}
                value={filters.compatibility}
                onChange={(selected) =>
                  setFilters((prev) => ({
                    ...prev,
                    compatibility: selected,
                  }))
                }
                onClear={() =>
                  setFilters((prev) => ({
                    ...prev,
                    compatibility: [],
                  }))
                }
                filtersSelectedCount={filters.compatibility.length}
              />
            </Flex>
            {!isEditing && !isUserHelpDesk && (
              <Button
                icon={{ name: 'pen' }}
                disabled={isUserHelpDesk || isAuditor}
                onClick={() =>
                  afterAction({
                    isEditing: true,
                    revertToBeforeEdit: true,
                    scrollToTopContainer: false,
                  })
                }
              >
                Edit Parameters
              </Button>
            )}
          </Flex>
          <Flex
            p5
            hFull
            flow="column"
            css={{
              height: `calc(100vh - ${
                isEditing ? headerHeight + 108 : headerHeight + 53
              }px)`,
              overflow: 'auto',
              paddingBottom: '300px',
              gap: '60px',
            }}
            ref={paramsContainerRef}
          >
            {filteredParamsByCategory.map((category) => {
              const { id, name, subcategories } = category;
              const catScrollId = `${name}-${id}`;

              return (
                <Box key={id} id={catScrollId}>
                  <Heading size="2">{name}</Heading>
                  <Flex mt4 gap="md" flow="column">
                    {subcategories.map((subcategory) => {
                      const {
                        name: subcategoryName,
                        id: subcategoryId,
                        parameters: paramsForThisSubcategory,
                      } = subcategory;
                      const subScrollId = `${subcategory.name}-${subcategory.id}`;

                      return (
                        <Flex
                          key={subcategoryId}
                          id={subScrollId}
                          flow="column"
                          // gap="md"
                          css={{ gap: '14px' }}
                        >
                          <Heading
                            size="3"
                            css={{
                              fontWeight: 500,
                            }}
                          >
                            {subcategoryName}
                          </Heading>
                          {paramsForThisSubcategory.map((param) => {
                            const {
                              id: paramId,
                              name: paramName,
                              description,
                              tags: paramTags,
                              warning,
                              compatibility,
                              dependency,
                            } = param;
                            const isEnabled =
                              parametersModel[paramId] !== undefined;
                            const isDependencyEnabled =
                              !param.dependency ||
                              parametersModel[param.dependency.id];
                            const hasAlert =
                              parametersWithAlerts[paramId] && isEnabled;

                            const paramState = parametersModel[paramId];
                            const Component =
                              isEnabled && parameterComponents[paramId];

                            return (
                              <Card
                                key={paramId}
                                variant="flat"
                                css={{
                                  borderRadius: '6px',
                                  padding: '$5',
                                  paddingTop: '20px',
                                }}
                              >
                                <Flex
                                  flow="column"
                                  css={{ gap: '10px' }}
                                  data-testid={`am-parameter-${paramId}`}
                                >
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Heading
                                      size="4"
                                      css={{
                                        fontWeight: '$medium',
                                        lineHeight: '$3',
                                        color: 'rgba(0, 0, 0, 0.80)',
                                      }}
                                    >
                                      {paramName}
                                    </Heading>
                                    <Flex gap="sm">
                                      {hasAlert && paramState && (
                                        <Button
                                          compact
                                          variant="subtle"
                                          disabled={isDisabled}
                                          onClick={() =>
                                            onToggle(
                                              param,
                                              isEnabled,
                                              !paramState.mute,
                                              parametersById,
                                            )
                                          }
                                          data-testid={`am-parameters-alert-${paramId}`}
                                        >
                                          <Icon
                                            name={
                                              paramState.mute
                                                ? 'bell-slash'
                                                : 'bell'
                                            }
                                          />
                                        </Button>
                                      )}
                                      {isEditing && (
                                        <Switch
                                          data-testid={`am-parameters-toggle-${paramId}`}
                                          disabled={
                                            isDisabled || !isDependencyEnabled
                                          }
                                          checked={isEnabled}
                                          onCheckedChange={(checked) =>
                                            onToggle(
                                              param,
                                              checked,
                                              false,
                                              parametersById,
                                            )
                                          }
                                        />
                                      )}
                                    </Flex>
                                  </Flex>
                                  {description && (
                                    <Text css={{ lineHeight: '20px' }}>
                                      {description}
                                    </Text>
                                  )}
                                  {(warning || dependency) && (
                                    <Grid
                                      gap="1"
                                      css={{ gridTemplateColumns: 'auto 1fr' }}
                                    >
                                      {warning && (
                                        <>
                                          <Text
                                            variant="description"
                                            css={{ fontWeight: 500 }}
                                          >
                                            Warning:
                                          </Text>
                                          <Text variant="description">
                                            {warning}
                                          </Text>
                                        </>
                                      )}
                                      {dependency && (
                                        <>
                                          <Text
                                            variant="description"
                                            css={{ fontWeight: 500 }}
                                          >
                                            Dependency:
                                          </Text>
                                          <Text variant="description">
                                            {dependency.name}
                                          </Text>
                                        </>
                                      )}
                                    </Grid>
                                  )}
                                  <Flex gap="sm">
                                    {compatibility && (
                                      <ParameterChip
                                        label={`${
                                          osPrefixes[deviceTypes.MAC]
                                        } ${compatibility}`}
                                      />
                                    )}
                                    {paramTags
                                      .filter((tag) => !tag.hidden)
                                      .sort((a, b) =>
                                        a.name.localeCompare(b.name),
                                      )
                                      .map((tag) => (
                                        <ParameterChip
                                          key={tag.name}
                                          label={tag.name}
                                        />
                                      ))}
                                  </Flex>
                                  {Component && (
                                    <Box mt3>
                                      <Component
                                        key={id}
                                        update={getOnParameterUpdate(paramId)}
                                        validations={validations}
                                        blueprint={blueprint}
                                        param={{
                                          ...paramState,
                                          parameter: param,
                                        }}
                                        isDisabled={isDisabled}
                                      />
                                    </Box>
                                  )}
                                </Flex>
                              </Card>
                            );
                          })}
                        </Flex>
                      );
                    })}
                  </Flex>
                </Box>
              );
            })}
            {isShowNoResultsState && (
              <Empty
                image={noParameters}
                title="No Results found"
                message="We couldn't find a match. Try changing your filter parameters, or search with different keywords."
                fullHeight
              />
            )}
          </Flex>
        </Box>
      </Flex>
      {isEditing && (
        <Flex
          justifyContent="end"
          alignItems="center"
          gap="sm"
          css={{
            position: 'fixed',
            bottom: 0,
            padding: '$3 $5',
            borderTop: '1px solid $neutral20',
            width: `calc(100% - ${sidebarWidth}px)`,
            backgroundColor: '$neutral0',
          }}
        >
          <Button
            disabled={isDisabled}
            onClick={() =>
              afterAction({
                isEditing: false,
                revertToBeforeEdit: true,
                scrollToTopContainer: true,
              })
            }
          >
            Cancel
          </Button>
          <Button variant="primary" disabled={isDisabled} onClick={onSave}>
            Save
          </Button>
        </Flex>
      )}
      <Toaster />
    </Box>
  );
};

export default Parameters;
