import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  BytesCell,
  DateCell,
  NaCell,
  TextCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getBlueprintCell,
  getDeviceCell,
  getDeviceUserCell,
  getSpecificDeviceFamilyCell,
} from './column-utils';

const GB = 1000000000;

export const getDeviceInfoColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const deviceInfoColumnHelper = createColumnHelper<z.infer<typeof schema>>();

  const columnDefs = [
    deviceInfoColumnHelper.accessor((row) => row.device__name, {
      id: 'device__name',
      enableHiding: false,
      cell: (info) => getDeviceCell(info),
      meta: {
        displayName: 'Device Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.model_name, {
      id: 'model_name',
      meta: {
        displayName: 'Model Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.display_os_version, {
      id: 'display_os_version',
      meta: {
        displayName: 'OS Version',
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.serial_number, {
      id: 'serial_number',
      meta: {
        displayName: 'Serial Number',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.device__user_name, {
      id: 'device__user_name',
      cell: getDeviceUserCell,
      meta: {
        displayName: 'Device User',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.blueprint_name, {
      id: 'blueprint_name',
      cell: getBlueprintCell,
      meta: {
        displayName: 'Blueprint',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.asset_tag, {
      id: 'asset_tag',
      cell: TextCell,
      meta: {
        displayName: 'Asset Tag',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_enrollment_date, {
      id: 'last_enrollment_date',
      cell: DateCell,
      meta: {
        displayName: 'Last Enrollment Date',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_collected_at, {
      id: 'last_collected_at',
      cell: DateCell,
      meta: {
        displayName: 'Last Collected',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.device_id, {
      id: 'device_id',
      meta: {
        displayName: 'Device ID',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.device__user_email, {
      id: 'device__user_email',
      meta: {
        displayName: 'User Email',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor(
      (row) =>
        Number.isNaN(row.device_capacity) ? null : row.device_capacity * GB,
      {
        id: 'device_capacity',
        cell: BytesCell,
        meta: {
          displayName: 'Device Capacity',
          defaultHidden: true,
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    deviceInfoColumnHelper.accessor((row) => row.host_name, {
      id: 'host_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Hostname',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.apple_silicon, {
      id: 'apple_silicon',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: 'Apple Silicon',
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.local_hostname, {
      id: 'local_hostname',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Local Hostname',
        defaultHidden: true,
        filterIcon: 'text-size',
        filterType: 'string',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.model, {
      id: 'model',
      meta: {
        displayName: 'Model',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.model_id, {
      id: 'model_id',
      meta: {
        displayName: 'Model Identifier',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.os_build, {
      id: 'os_build',
      meta: {
        displayName: 'OS Build',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.marketing_name, {
      id: 'marketing_name',
      meta: {
        displayName: 'OS Name',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.supplemental_build_version, {
      id: 'supplemental_build_version',
      meta: {
        displayName: 'Supplemental Build Version',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor(
      (row) => row.supplemental_os_version_extra,
      {
        id: 'supplemental_os_version_extra',
        meta: {
          displayName: 'Supplemental OS Version Extra',
          defaultHidden: true,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    deviceInfoColumnHelper.accessor((row) => row.os_version, {
      id: 'os_version',
      meta: {
        displayName: 'Simple OS Version',
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.first_enrollment_date, {
      id: 'first_enrollment_date',
      cell: DateCell,
      meta: {
        displayName: 'First Enrollment Date',
        defaultHidden: true,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.agent_version, {
      id: 'agent_version',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Agent Version',
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.mdm_enabled, {
      id: 'mdm_enabled',
      cell: YesNoCell,
      meta: {
        displayName: 'MDM Enabled',
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.agent_installed, {
      id: 'agent_installed',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: 'Agent Installed',
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.shared_ipad, {
      id: 'shared_ipad',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad'], 'boolean'),
      meta: {
        displayName: 'Shared iPad',
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.cellular_technology, {
      id: 'cellular_technology',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone']),
      meta: {
        displayName: 'Cellular Technology',
        defaultHidden: true,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'None', label: 'None' },
          { value: 'GSM', label: 'GSM' },
          { value: 'CDMA', label: 'CDMA' },
          { value: 'GSM and CDMA', label: 'Both GSM and CDMA' },
        ],
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.data_roaming, {
      id: 'data_roaming',
      cell: (info) => {
        if (
          info.row.original.device__family === 'iPad' &&
          info.row.original.cellular_technology === 'None'
        ) {
          return NaCell();
        }
        return getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'boolean');
      },

      meta: {
        displayName: 'Data Roaming',
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.hotspot, {
      id: 'hotspot',
      cell: (info) => {
        if (
          info.row.original.device__family === 'iPad' &&
          info.row.original.cellular_technology === 'None'
        ) {
          return NaCell();
        }
        return getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'boolean');
      },
      meta: {
        displayName: 'Personal Hotspot',
        defaultHidden: true,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    deviceInfoColumnHelper.accessor((row) => row.last_changed_at, {
      id: 'last_changed_at',
      cell: DateCell,
      meta: {
        displayName: 'Last Changed',
        defaultHidden: true,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    /* this column is always hidden but needs to be in column defs to enable */
    /* filtering for export purposes */
    deviceInfoColumnHelper.accessor((row) => row.device__user_id, {
      id: 'device__user_id',
      meta: {
        displayName: 'Device User ID',
        alwaysHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
