import { Flex, Text } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import EmptyIcon from '../../../../assets/bp-filter-empty-state.svg';

const SearchBlueprintsEmptyState = () => (
  <Flex
    flow="column"
    alignItems="center"
    justifyContent="center"
    gap="md"
    css={{
      p: '32px 24px',
      gap: '10px',
    }}
  >
    <img src={EmptyIcon} alt="Empty Icon" />
    <Text
      css={{
        color: '$neutral60',
        fontSize: '$2',
        fontWeight: '$medium',
      }}
    >
      No results found
    </Text>
    <Text css={{ color: '$neutral60', textAlign: 'center' }}>
      We couldn&apos;t find a match. Try changing the search query with
      different keywords.
    </Text>
  </Flex>
);

export default SearchBlueprintsEmptyState;
