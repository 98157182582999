import React, { useState } from 'react';

import { array, bool, func, number, object } from 'prop-types';

import { Button } from '@kandji-inc/bumblebee';

import { BuilderProvider } from '../builder/use-builder';

import BuilderModal from './builder-modal';
import DeleteModal from './delete-modal';
import RuleTagList, { EmptyRuleTagList } from './tags';

const RuleActions = ({ rules }) => {
  const [isBuilderModalOpen, setIsBuilderModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onBuilderClick = () => {
    setIsBuilderModalOpen(true);
  };

  const onDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  return (
    <div className="k-rules-library-action">
      {rules ? (
        <>
          <Button kind="link" icon="pencil" onClick={onBuilderClick}>
            Edit
          </Button>
          <Button
            className="b-ml-tiny"
            kind="link"
            icon="trash-can"
            theme="error"
            onClick={onDeleteClick}
          >
            Remove
          </Button>
        </>
      ) : (
        <Button kind="link" icon="circle-plus" onClick={onBuilderClick}>
          Add
        </Button>
      )}

      <BuilderModal
        isOpen={isBuilderModalOpen}
        setIsOpen={setIsBuilderModalOpen}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
      />
    </div>
  );
};

RuleActions.propTypes = {
  rules: object,
};

RuleActions.defaultProps = {
  rules: null,
};

const AssignRules = ({
  facetMap,
  rules,
  setModel,
  isDisabled,
  supportedDeviceFamilies,
  installOnDeviceFamilies,
  countOfUserDirectoryIntegrations,
  hasUserDirectoryIntegration,
}) => (
  <BuilderProvider
    facetMap={facetMap}
    rules={rules}
    setModel={setModel}
    supportedDeviceFamilies={supportedDeviceFamilies}
    installOnDeviceFamilies={installOnDeviceFamilies}
    countOfUserDirectoryIntegrations={countOfUserDirectoryIntegrations}
    hasUserDirectoryIntegration={hasUserDirectoryIntegration}
  >
    <div className="k-rules-library">
      <p className="b-txt label">Rules</p>

      <div className="k-rules-library-tags">
        {isDisabled && !rules ? (
          <EmptyRuleTagList />
        ) : (
          <RuleTagList
            facetMap={facetMap}
            rules={rules}
            installOnDeviceFamilies={installOnDeviceFamilies}
          />
        )}

        {!isDisabled && <RuleActions rules={rules} />}
      </div>
    </div>
  </BuilderProvider>
);

AssignRules.propTypes = {
  facetMap: object.isRequired,
  rules: object,
  setModel: func.isRequired,
  isDisabled: bool,
  supportedDeviceFamilies: array,
  installOnDeviceFamilies: array,
  countOfUserDirectoryIntegrations: number,
  hasUserDirectoryIntegration: bool,
};

AssignRules.defaultProps = {
  rules: null,
  isDisabled: true,
  supportedDeviceFamilies: [],
  installOnDeviceFamilies: [],
  countOfUserDirectoryIntegrations: 0,
  hasUserDirectoryIntegration: false,
};

export default AssignRules;
