import {
  Box,
  Chip,
  Flex,
  Icon,
  Text,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import CopyButton from 'src/features/threat/common/components/CopyButton';
import TableActionsDropdown from 'src/features/threat/common/components/ThreatListTable/TableActionsDropdown';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';
import { SeverityProgressChips } from '../components/severity-progress-chips';
import { SeverityTooltip } from '../components/severity-tooltip';
import { formatDate } from '../helpers';
import useVulnerability from '../store';
import type { Vulnerability } from '../vulnerability.types';

const getVulnerabilityIdCell = (info: CellContext<Vulnerability, string>) => {
  const { cve_id } = info.row.original;

  const [isHovering, setIsHovering] = useState(false);

  const filter = useVulnerability((state) => state.filter);

  return (
    <Flex
      flow="row"
      gap="xs"
      alignItems="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {highlightedText(cve_id, filter.term)}
      {isHovering && <CopyButton value={cve_id} />}
    </Flex>
  );
};

const getApplicationCell = (info: CellContext<Vulnerability, string>) => {
  const { app_name } = info?.row?.original;

  const filter = useVulnerability((state) => state.filter);

  /* istanbul ignore next */
  if (!app_name) {
    return null;
  }

  return <Text>{highlightedText(app_name, filter.term)}</Text>;
};

const getVersionsHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>Version</Text>
      <Tooltip side="top" content="Latest version" css={{ zIndex: 2 }}>
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getVersionCell = (info: CellContext<Vulnerability, string>) => {
  const { versions } = info?.row?.original;

  /* istanbul ignore next */
  if (!versions && !versions.length) {
    return null;
  }

  return (
    <Flex gap="xs">
      <Text>{`${versions[0]}${versions.length > 1 ? ',' : ''}`}</Text>
      {versions.length > 1 && (
        <Tooltip
          content={versions.slice(1).join(', ')}
          side="bottom"
          theme="dark"
          css={{ zIndex: 100 }}
        >
          <Text> +{versions.length - 1}</Text>
        </Tooltip>
      )}
    </Flex>
  );
};

const getSeverityCell = (info: CellContext<Vulnerability, string>) => {
  const { severity } = info?.row?.original;

  /* istanbul ignore next */
  if (!severity) {
    return null;
  }

  return (
    <Flex>
      <SeverityTooltip severity={severity}>
        <Flex>
          <SeverityProgressChips severity={severity} />
          <Text>{severity}</Text>
        </Flex>
      </SeverityTooltip>
    </Flex>
  );
};

const getDetectionDateCell = (date: string) => (
  <Text title={formatDate(date)}>{formatDate(date)}</Text>
);

const getDevicesHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>Devices</Text>
      <Tooltip side="top" content="Devices impacted" css={{ zIndex: 2 }}>
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getDevicesCell = (info: CellContext<Vulnerability, string>) => {
  const { device_count } = info?.row?.original;

  /* istanbul ignore next */
  if (!device_count) {
    return null;
  }

  return (
    <Chip
      title="Devices impacted"
      color="unfilled"
      iconLeft={{ icon: 'sf-desktop-computer', size: 'sm' }}
      label={device_count}
    />
  );
};

const getActionsDropdownCell = (info: CellContext<Vulnerability, string>) => {
  const { cve_link } = info?.row?.original;

  /* istanbul ignore next */
  if (!cve_link) {
    return null;
  }

  const options: DropdownMenuOption[] = [
    {
      label: 'Search NVD',
      icon: 'nvd',
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(cve_link, '_blank');
      },
    },
  ];

  const css = {
    svg: {
      width: '16px',
      height: '16px',
    },
  };

  return (
    <TableActionsDropdown
      options={options}
      testId="actions-dropdown"
      css={css}
    />
  );
};

const columnHelper = createColumnHelper<Vulnerability>();

export const columns: ColumnDef<Vulnerability>[] = [
  columnHelper.accessor('cve_id', {
    id: 'cve_id',
    cell: getVulnerabilityIdCell,
    header: () => 'Vulnerability ID',
    minSize: 259,
  }),
  columnHelper.accessor('app_name', {
    id: 'app_name',
    cell: getApplicationCell,
    header: () => 'Application',
    minSize: 225,
  }),
  columnHelper.accessor('versions', {
    id: 'versions',
    cell: getVersionCell,
    header: getVersionsHeader,
    enableSorting: false,
    // @ts-ignore
    title: null,
  }),
  columnHelper.accessor('severity', {
    id: 'severity',
    cell: getSeverityCell,
    header: () => 'Severity',
    minSize: 200,
  }),
  columnHelper.accessor('cvss_score', {
    id: 'cvss_score',
    cell: (row) => row.getValue(),
    header: () => 'CVSS score',
  }),
  columnHelper.accessor('first_detection_date', {
    id: 'first_detection_date',
    cell: (row) => getDetectionDateCell(row.getValue()),
    header: () => 'First detected',
  }),
  columnHelper.accessor('latest_detection_date', {
    id: 'latest_detection_date',
    cell: (row) => getDetectionDateCell(row.getValue()),
    header: () => 'Last detected',
  }),
  columnHelper.accessor('device_count', {
    id: 'device_count',
    cell: getDevicesCell,
    header: getDevicesHeader,
  }),
  columnHelper.accessor((row) => row, {
    id: 'actions',
    cell: getActionsDropdownCell,
  }),
];
