import { Button } from '@kandji-inc/bumblebee';
import React, { useState, useMemo } from 'react';
import Drawer from './drawer';
import Preview from './preview';

const Customization = (props) => {
  const {
    setting,
    update,
    defaults,
    categoryOptions,
    isDisabled,
    DrawerContent,
  } = props;
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const categoryName = useMemo(
    () =>
      categoryOptions.find((opt) => opt.value === setting.category)?.label ||
      '-',
    [categoryOptions, setting.category],
  );

  return (
    <div className="k-ss2-customize">
      <div className="k-ss2-customize__buttons">
        <Button
          isDisabled={isDisabled}
          onClick={() => setIsDrawerOpen((p) => !p)}
        >
          Customize
        </Button>
        <Button
          onClick={() => setIsPreviewOpen((p) => !p)}
          kind="link"
          size="small"
          icon={isPreviewOpen ? 'angle-up' : 'angle-down'}
        >
          {isPreviewOpen ? 'Collapse previews' : 'Expand previews'}
        </Button>
      </div>
      <div hidden={!isPreviewOpen}>
        <Preview
          setting={setting}
          defaultIcon={defaults.icon}
          defaultName={defaults.name}
          categoryName={categoryName}
        />
      </div>
      <div>
        <Drawer
          isVisible={isDrawerOpen}
          setIsVisible={setIsDrawerOpen}
          setting={setting}
          update={update}
          defaults={defaults}
          DrawerContent={DrawerContent}
        />
      </div>
    </div>
  );
};

export default Customization;
