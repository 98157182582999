import { onEmptyBlurValidator, urlValidator } from '@kandji-inc/bumblebee';

export const validationLimit = {
  clientId: 25,
  clientSecret: 64,
};

export const handleOktaValidation = (value: string) => [
  onEmptyBlurValidator(value, {
    message: 'Required',
    trigger: ['onBlur'],
  }),
  urlValidator(value, {
    message: 'Invalid URL',
    trigger: 'onBlur',
    options: {
      require_protocol: false,
      allow_underscores: true,
      protocols: ['http', 'https'],
    },
  }),
];
