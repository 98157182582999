import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Checkbox } from '@kandji-inc/bumblebee';
import '../../app-lock.css';

import { Setting } from 'features/library-items/template';

import AppLockService from '../../service/app-lock-service';

const AppOptions = ({ update, isDisabled, settings }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">App options</h3>
    </Setting.Header>
    <Setting.SubHeader>
      <p className="b-txt">
        Specify options that should be enabled on the device while locked in
        single app mode.
      </p>
    </Setting.SubHeader>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <div className="app-lock--checkbox-grid">
            {Object.entries(AppLockService.appOptionsMap).map(
              ([field, { label }]) => (
                <Checkbox
                  key={`${field}_checkbox`}
                  disabled={isDisabled}
                  label={label}
                  id={`app_options_${field}`}
                  testId={`app_options_${field}`}
                  checked={settings[field]}
                  onChange={() => update(field, (isChecked) => !isChecked)}
                />
              ),
            )}
          </div>
        </Setting.Controls>
      </Setting.Row>
    </Setting.Rows>
  </Setting.Card>
);

AppOptions.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired, // @todo define the shape of `settings`
};

export default memo(AppOptions);
