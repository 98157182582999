import SoftwareUpdateService from './service/software-update-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  updateSettings: {
    deferMacOSUpdates:
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.doNotDefer.value, // ui-only, not sent to API
    'restrict-software-update-require-admin-to-install': false,
    AllowPreReleaseInstallation: true,
    enforcedSoftwareUpdateDelay: 30,
    canDeferMajorMacOSUpdates: false, // ui-only, not sent to API
    enforcedSoftwareUpdateMajorOSDeferredInstallDelay: 30,
    canDeferMinorMacOSUpdates: false, // ui-only, not sent to API
    enforcedSoftwareUpdateMinorOSDeferredInstallDelay: 30,
    canDeferNonOSUpdates: false, // ui-only, not sent to API
    enforcedSoftwareUpdateNonOSDeferredInstallDelay: 30,
  },

  automaticUpdates: {
    AutomaticCheckEnabled: true,
    AutomaticDownload: true,
    AutomaticallyInstallMacOSUpdates: true,
    AutomaticallyInstallAppUpdates: true,
    ConfigDataInstall: true,
    CriticalUpdateInstall: true,
  },

  supportsRules: true,
};
