import { INT_TYPES } from '../../../../constants';
import type { IntegratorViewConfig } from '../../types/integrator.types';
import type {
  ServicenowInputs,
  ServicenowState,
} from '../../types/servicenow.types';

// Helpers
import { connectHandleNext, connectStatusOptions } from './helper';

// Icons
import servicenowLogo from '../../../../generic-cards-view/assets/servicenow.svg';

// Config
const config: IntegratorViewConfig<ServicenowInputs, ServicenowState, object> =
  {
    type: INT_TYPES.servicenow,
    name: 'ServiceNow',
    logo: servicenowLogo,
    pages: [
      {
        name: 'servicenowConfiguration',
        layout: 'one-column-input-full',
        header: {
          title: 'Configure ServiceNow',
          description:
            'The ServiceNow integration enables organizations to sync their Kandji device information into ServiceNow for asset management. ',
          link: {
            text: 'Learn more',
            url: 'https://support.kandji.io/solutions/articles/72000613963',
          },
        },
        banner: {
          title: 'We do not store your admin password in our system. ',
          description:
            'The password is only used to establish a connection between ServiceNow and Kandji. Please use credentials that have admin access in the ServiceNow tenant specified.',
        },
        body: {
          title: 'Authentication',
          description:
            'Provide the following information to establish the connection with your ServiceNow tenant.',
          inputs: [
            {
              name: 'tenant',
              type: 'text',
              label: 'Tenant name',
              placeholder: 'Enter the tenant name from ServiceNow',
              hint: 'If your domain is subdomain.service-now.com, just enter subdomain',
              validations: {
                required: 'Required',
                maxLength: {
                  value: 256,
                  message: 'Must be 256 characters or fewer',
                },
                pattern: {
                  value: /[a-zA-Z0-9-_]*[a-zA-Z0-9]+$/,
                  message: 'Must contain only subdomain',
                },
              },
            },
            {
              name: 'client_id',
              type: 'text',
              label: 'Client ID',
              placeholder: 'Enter the client ID from ServiceNow ',
              validations: {
                required: 'Required',
                maxLength: {
                  value: 256,
                  message: 'Must be 256 characters or fewer',
                },
              },
            },
            {
              name: 'client_secret',
              type: 'password',
              label: 'Client secret',
              placeholder: 'Enter the client secret from ServiceNow',
              validations: {
                required: 'Required',
                maxLength: {
                  value: 256,
                  message: 'Must be 256 characters or fewer',
                },
              },
            },
            {
              name: 'username',
              type: 'text',
              label: 'Username',
              placeholder: 'Enter your ServiceNow username',
              validations: {
                required: 'Required',
                maxLength: {
                  value: 256,
                  message: 'Must be 256 characters or fewer',
                },
              },
            },
            {
              name: 'password',
              type: 'password',
              label: 'Password',
              placeholder: 'Enter your ServiceNow password',
              validations: {
                required: 'Required',
                maxLength: {
                  value: 256,
                  message: 'Must be 256 characters or fewer',
                },
              },
            },
          ],
        },
        footer: {
          title: 'Install the Kandji application in ServiceNow',
          description:
            'If you haven’t already, please install the Kandji application in ServiceNow before proceeding.',
          link: {
            text: 'Open ServiceNow Store to install',
            url: 'https://store.servicenow.com/sn_appstore_store.do#!/store/application/e330778897bfe150f89bf7021153af52',
          },
        },
        footerStatusOptions: connectStatusOptions,
        nextButtonText: 'Connect to ServiceNow',
        handleNext: connectHandleNext,
        cancelButtonText: 'Cancel setup',
      },
    ],
  };

export default config;
