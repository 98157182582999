import {
  Banner,
  Button,
  Checkbox,
  modal as Modal,
} from '@kandji-inc/bumblebee';
import React, { useState } from 'react';

const DeleteBlueprint = (props) => {
  const { onClose, onDelete } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      isOpen
      onClose={onClose}
      animationDuration={150}
      disableCloseOnOutsideClick
      classes={{
        root: 'bl-blueprints-delete__modal',
      }}
      parts={{
        header: {
          text: `Delete Blueprint`,
        },
        content: {
          children: (
            <>
              <Banner className="b-mb" theme="warning" kind="block">
                <p>
                  Deleting a Blueprint will permanently destroy its Device
                  Records, History, and Notes. This action cannot be undone.
                </p>
              </Banner>
              <p className="b-txt b-mb">
                This process may take some time to complete.
              </p>
              <Checkbox
                label="I understand this Blueprint will be permanently deleted, along with its Device Records, History, and Notes."
                onChange={() => setIsChecked((prev) => !prev)}
                checked={isChecked}
                testId="understand-confirm"
              />
            </>
          ),
        },
        footer: {
          children: (
            <div className="b-flex-g">
              <Button disabled={isLoading} onClick={onClose} kind="outline">
                Cancel
              </Button>
              <Button
                disabled={!isChecked || isLoading}
                isProgress={isLoading}
                icon={isLoading ? 'arrows-rotate' : ''}
                onClick={() => {
                  setIsLoading(true);
                  return onDelete()
                    .then(onClose)
                    .catch((e) => {
                      setIsLoading(false);
                      console.error('Failed to delete', e);
                    });
                }}
                theme="error"
              >
                Delete
              </Button>
            </div>
          ),
        },
      }}
    />
  );
};

export default DeleteBlueprint;
