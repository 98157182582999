import React, { useEffect } from 'react';

import { bool, func, string } from 'prop-types';

import { Flex, modal as Modal } from '@kandji-inc/bumblebee';
import { Button } from '@kandji-inc/nectar-ui';

import RulesBuilder, { useBuilder } from '../builder';

function BuilderModal(props) {
  const {
    origin = 'library',
    isOpen,
    setIsOpen,
    withoutCloseButton = false,
    disableCloseOnOutsideClick = false,
  } = props;

  const { resetForm, fetchRuleset, updateRuleset, isConfirmDisabled } =
    useBuilder();

  const isBlueprintFlow = origin === 'flow';

  const onClose = (closed) => {
    setIsOpen(false);

    if (closed) {
      setTimeout(() => closed(), 500);
    }
  };

  const onCancel = (event) => {
    /* istanbul ignore next - browser event methods */
    if (isBlueprintFlow && event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onClose(resetForm);
  };

  const onConfirm = (event) => {
    /* istanbul ignore next - browser event methods */
    if (isBlueprintFlow) {
      event.preventDefault();
      event.stopPropagation();
    }

    updateRuleset();

    onClose(resetForm);
  };

  useEffect(() => {
    if (isOpen) {
      fetchRuleset();
    }
    return () => {
      resetForm();
    };
  }, [isOpen]);

  return (
    <Modal
      classes={{ root: 'k-rules-library-modal' }}
      isOpen={isOpen}
      onClose={onCancel}
      withoutCloseButton={withoutCloseButton}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      animationDuration={250}
      parts={{
        header: {
          text: 'Assignment Rules',
        },
        content: {
          children: (
            <>
              <p className="b-txt">
                Use rules to limit which devices within the Blueprint will be
                assigned this Library Item. The Library Item is applied when all
                criteria below are met.
              </p>

              <div className="k-rules-library-builder b-mt">
                <RulesBuilder />
              </div>
            </>
          ),
        },
        footer: {
          children: (
            <Flex>
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                variant="primary"
                className="l-ml"
                onClick={onConfirm}
                disabled={isConfirmDisabled}
                id="for-pendo__rules-confirm-btn"
              >
                Confirm
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
}

BuilderModal.propTypes = {
  origin: string,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  withoutCloseButton: bool,
  disableCloseOnOutsideClick: bool,
};

export default BuilderModal;
