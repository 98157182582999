import EthernetService from '../service/ethernet-service';

export const interfaceTypeOptions = [
  {
    value: EthernetService.interfaces.GLOBAL,
    label: 'Global Ethernet',
  },
];

export const protocolOptions = [
  { label: 'TLS', value: EthernetService.protocols.TLS },
  { label: 'TTLS', value: EthernetService.protocols.TTLS },
  { label: 'LEAP', value: EthernetService.protocols.LEAP },
  { label: 'PEAP', value: EthernetService.protocols.PEAP },
  { label: 'EAP-FAST', value: EthernetService.protocols.EAP_FAST },
];

export const authenticationOptions = [
  {
    label: 'Username and password',
    value: EthernetService.authentications.USERNAME_AND_PASSWORD,
  },
  {
    label: 'Computer AD system authentication',
    value: EthernetService.authentications.COMPUTER_AD,
  },
  {
    label: 'Computer OD system authentication',
    value: EthernetService.authentications.COMPUTER_OD,
  },
];

export const innerAuthenticationOptions = [
  { label: 'CHAP', value: EthernetService.innerAuthentications.CHAP },
  { label: 'EAP', value: EthernetService.innerAuthentications.EAP },
  { label: 'MSCHAP', value: EthernetService.innerAuthentications.MSCHAP },
  { label: 'MSCHAPv2', value: EthernetService.innerAuthentications.MSCHAPv2 },
  { label: 'PAP', value: EthernetService.innerAuthentications.PAP },
  {
    label: "Don't specify",
    value: EthernetService.innerAuthentications.DONT_SPECIFY,
  },
];

export const tlsOptions = [
  { label: '1.0', value: EthernetService.tlsVersions.v1_0 },
  { label: '1.1', value: EthernetService.tlsVersions.v1_1 },
  { label: '1.2', value: EthernetService.tlsVersions.v1_2 },
  { label: "Don't specify", value: EthernetService.tlsVersions.DONT_SPECIFY },
];

export const randValuesOptions = [
  { label: '2', value: EthernetService.randValues[2] },
  { label: '3', value: EthernetService.randValues[3] },
  { label: "Don't specify", value: EthernetService.randValues.DONT_SPECIFY },
];

export const identityCertificateOptions = [
  { label: 'None', value: EthernetService.identityCertificates.NONE },
  {
    label: 'AD CS Certificate',
    value: EthernetService.identityCertificates.ADCS,
  },
  {
    label: 'SCEP',
    value: EthernetService.identityCertificates.SCEP,
  },
  { label: 'PKCS #12', value: EthernetService.identityCertificates.PKCS12 },
].filter(Boolean);

export const keySizeOptions = [
  { label: '1024', value: EthernetService.keySizes.s1024 },
  { label: '2048', value: EthernetService.keySizes.s2048 },
  { label: '4096', value: EthernetService.keySizes.s4096 },
];

export const keyUsageOptions = [
  { value: EthernetService.keyUsages.NONE, label: 'None' },
  { value: EthernetService.keyUsages.SIGNING, label: 'Signing' },
  { value: EthernetService.keyUsages.ENCRYPTION, label: 'Encryption' },
  {
    value: EthernetService.keyUsages.BOTH,
    label: 'Both signing and encryption',
  },
];
