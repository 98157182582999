import { Flex, styled } from '@kandji-inc/nectar-ui';
import { AccountContext } from 'contexts/account';
import { useContext, useMemo, useState } from 'react';
import ThreatListErrorBoundary from '../common/components/ThreatListErrorBoundary';
import ThreatListExportButton from '../common/components/ThreatListExportButton';
import ThreatListReleaseModal from '../common/components/ThreatListReleaseModal';
import ThreatListTable from '../common/components/ThreatListTable/ThreatListTable';
import toRequestFormat from '../common/hooks/use-get-threats/toRequestFormat';
import useGetThreats from '../common/hooks/use-get-threats/use-get-threats';
import useStateParams from '../common/hooks/use-state-params';
import downloadCSVFile from '../common/utils/downloadCSVFile';
import getDateRange from '../common/utils/getDateRange';
import getDisplayDetectionPeriod from '../common/utils/getDisplayDetectionPeriod';
import { ThreatService } from '../data-service';
import type {
  DetectionDateFilterFields,
  FileStatus,
  FilterFields,
  FiltersState,
  PageSize,
  PaginationState,
  ShowToast,
  SortColumnDirection,
  SortColumnName,
  SortColumnState,
  TableHeaderColumn,
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../threat.types';
import ThreatListFilters from './ThreatListFilters';
import ThreatListTableBody from './ThreatListTableBody';
import ThreatListTiles from './ThreatListTiles/ThreatListTiles';
import useGetStatusCounts from './hooks/use-get-status-counts';
import {
  defaultFilters,
  filtersParamConfig,
  paginationParamConfig,
  sortParamConfig,
} from './params/params';

const Container = styled(Flex, {
  position: 'sticky',
  top: 0,
  backgroundColor: '$neutral0',
  zIndex: 1,
});

const errorBoundaryTableCss = {
  flex: 1,
  minHeight: 400,
};

const errorBoundaryTilesCss = {
  minHeight: 214,
  border: '1px solid $neutral20',
  borderRadius: '$2',
  marginTop: '$4',
  marginBottom: '$4',
};

const columns: TableHeaderColumn[] = [
  {
    name: 'threat_name',
    title: 'Threat Name',
    size: 11,
  },
  {
    name: 'classification',
    title: 'Classification',
    size: 10,
  },
  {
    name: 'process_name',
    title: 'Process',
    size: 10,
  },
  {
    name: 'device_name',
    title: 'Device',
    size: 11,
  },
  {
    name: 'detection_date',
    title: 'Detection Date',
    size: 11,
  },
  {
    name: 'status',
    title: 'Status',
    size: 11,
  },
];

type ThreatListEventsProps = Readonly<{
  deviceId?: string | undefined;
  showToast: ShowToast;
  showChartTiles?: boolean | undefined;
  isTableScrollable?: boolean | undefined;
  isOnDeviceRecord?: boolean | undefined;
}>;

const ThreatListEvents = (props: ThreatListEventsProps) => {
  const { deviceId, isOnDeviceRecord, showToast } = props;
  const { userSettings } = useContext(AccountContext);
  const threatService = useMemo(() => new ThreatService(), []);
  const [threatToRelease, setSelectedThreatForRelease] = useState<
    ThreatDetail | undefined
  >();

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    sort,
    setSort,
    isDefaultFilterState,
  } = useStateParams<FiltersState, PaginationState, SortColumnState>(
    filtersParamConfig,
    paginationParamConfig,
    sortParamConfig,
    defaultFilters,
  );

  const {
    data: dataThreats,
    isLoading: isLoadingThreats,
    isError: isErrorFetchingThreats,
    refetch: refetchThreats,
  } = useGetThreats(filters, sort, pagination, deviceId);

  const {
    data: dataStatusCounts,
    isLoading: isLoadingStatusCounts,
    isError: isErrorFetchingStatusCounts,
    refetch: refetchStatusCounts,
  } = useGetStatusCounts(
    getDateRange(filters.detectionDate),
    !isOnDeviceRecord,
  );

  const dataCount = dataThreats?.count;
  const malwareCount = dataThreats?.malware_count;
  const pupCount = dataThreats?.pup_count;
  const threats: ThreatDetail[] = dataThreats?.results || [];
  const isEmpty = dataCount === 0;
  const isFiltering = !isDefaultFilterState();
  const isFilteringOmittingDate = !isDefaultFilterState(['detectionDate']);
  const displayDetectionPeriod: string = getDisplayDetectionPeriod(
    filters.detectionDate,
    userSettings?.timezone,
  );

  const handlePaginationChange = (page: number, pageSize: PageSize) => {
    setPagination({ page, pageSize });
  };

  const handleExportThreatDetailsClick = async () => {
    const { limit, offset, ...rest } = toRequestFormat(
      filters,
      sort,
      pagination,
      deviceId,
    );

    showToast(`export-preparing`);
    try {
      const rawData = await threatService.getExportThreats(rest);
      downloadCSVFile(rawData?.data);
      showToast(`export-complete`);
    } catch {
      showToast(`export-error`);
    }
  };

  const setFiltersAndResetPage = (newFilters: FiltersState) => {
    setFilters(newFilters);
    setPagination({ page: 1 });
  };

  const handleClearFilters = (filterName: FilterFields) => {
    setFiltersAndResetPage({ [filterName]: undefined });
  };

  const handleApplyFilters = (
    filterName: FilterFields,
    value: ThreatStatus[] | ThreatClassification | DetectionDateFilterFields,
  ) => {
    setFiltersAndResetPage({ [filterName]: value });
  };

  const handleClearAllFilters = () => {
    setFiltersAndResetPage({
      status: undefined,
      classification: undefined,
      detectionDate: undefined,
      query: undefined,
    });
  };

  const handleStatusEventChange = (event: FileStatus) => {
    showToast(`status-${event}`);
    if (event === 'resolved') {
      refetchThreats();
      refetchStatusCounts();
    }
  };

  const handleColumnSortChange = (
    sortBy: SortColumnName | undefined,
    sortOrder: SortColumnDirection | undefined,
  ) => {
    setSort({ sortBy, sortOrder });
  };

  const handleRelease = (deviceCount: number) => {
    handleCloseModal();
    showToast('release-complete', {
      content: `${threatToRelease?.threat_name} will be released on ${deviceCount} devices upon next check in.`,
    });
  };

  const handleReleaseError = (type: string) => {
    handleCloseModal();
    showToast(type);
  };

  const handleCloseModal = () => {
    setSelectedThreatForRelease(undefined);
  };

  const handleGetReleaseDetails = (item: ThreatDetail) => {
    setSelectedThreatForRelease(item);
  };

  return (
    <>
      {!isOnDeviceRecord && (
        <ThreatListErrorBoundary
          css={errorBoundaryTilesCss}
          preReset={refetchStatusCounts}
        >
          <ThreatListTiles
            data={dataStatusCounts}
            isLoading={isLoadingStatusCounts}
            isError={isErrorFetchingStatusCounts}
            dateValue={filters?.detectionDate?.value}
            displayDetectionPeriod={displayDetectionPeriod}
          />
        </ThreatListErrorBoundary>
      )}
      <ThreatListErrorBoundary
        css={errorBoundaryTableCss}
        preReset={refetchThreats}
      >
        <Container
          flow="row"
          gap="sm"
          alignItems="end"
          justifyContent="space-between"
          py2
          px2={isOnDeviceRecord}
        >
          <ThreatListFilters
            filters={filters}
            onApply={handleApplyFilters}
            onClear={handleClearFilters}
            onClearAll={handleClearAllFilters}
            isFiltering={isFiltering}
            malwareCount={malwareCount}
            pupCount={pupCount}
          />
          {!isEmpty && (
            <ThreatListExportButton onClick={handleExportThreatDetailsClick} />
          )}
        </Container>
        <ThreatListTable
          isLoading={isLoadingThreats}
          isError={isErrorFetchingThreats}
          sort={sort}
          onColumnSort={handleColumnSortChange}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
          dataCount={dataCount}
          displayDetectionPeriod={displayDetectionPeriod}
          isFiltering={isFilteringOmittingDate}
          showToast={showToast}
          columns={columns}
          isOnDeviceRecord={isOnDeviceRecord}
        >
          <ThreatListTableBody
            threats={threats}
            query={filters.query}
            onStatusEvent={handleStatusEventChange}
            onGetReleaseDetails={handleGetReleaseDetails}
            timezone={userSettings?.timezone}
          />
        </ThreatListTable>
        {threatToRelease && (
          <ThreatListReleaseModal
            name={threatToRelease?.threat_name}
            fileHash={threatToRelease?.file_hash}
            deviceId={threatToRelease?.device_id}
            libraryItemId={threatToRelease?.library_item_id}
            onClose={handleCloseModal}
            onRelease={handleRelease}
            onError={handleReleaseError}
          />
        )}
      </ThreatListErrorBoundary>
    </>
  );
};

export default ThreatListEvents;
