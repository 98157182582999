import { Button, DialogPrimitives as Dialog } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { getDisplayName } from 'src/features/visibility/prism/implemented-category-uris';

export type PrismDeleteViewConfirmModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  viewName: string;
  categoryName: string;
};

const PrismDeleteViewConfirmModal = ({
  onConfirm,
  onClose,
  isOpen,
  onOpenChange,
  viewName,
  categoryName,
}: PrismDeleteViewConfirmModalProps) => (
  <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
    <Dialog.Content
      hideClose
      onOpenAutoFocus={(e: Event) => e.preventDefault()}
      css={{
        width: '480px',
        height: 'auto',
      }}
    >
      <Dialog.Header>
        <Dialog.Title>Delete View</Dialog.Title>
      </Dialog.Header>
      <Dialog.Description as="div">
        <p>
          {' '}
          Are you sure you want to delete {viewName}
          {categoryName
            ? ` and remove it from ${getDisplayName(categoryName)}`
            : ''}
          ?
        </p>
        <p>This action is permanent and can not be undone.</p>
      </Dialog.Description>
      <Dialog.Footer>
        <Button variant="subtle" compact onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" compact onClick={onConfirm}>
          Delete
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Root>
);

export default PrismDeleteViewConfirmModal;
