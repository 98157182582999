import { Toggle } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import useModal from '../../../hooks/use-modal';
import { useModalState } from '../../../hooks/use-modal-state';
import {
  eventTriggers,
  initialNotificationToggleState,
  mapNotificationsToSnakeCase,
} from '../../../utils';
import eventNotifcationIcon from '../assets/event-notifications-icon.svg';
import useEventNotifications from '../hooks/use-event-notifications';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const SyledToggle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4rem;
`;

const StyledNotificationContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  gap: 1.5rem;
  width: 100%;
  margin: auto 100px;
  position: relative;
  top: 2rem;
`;

const ModalContent = styled.div`
  display: grid;
  justify-items: center;
  margin: auto;
`;

const EventNotifications = () => {
  const { nextModal, closeModal, previousModal } = useModal();
  const { setModalState, state: modalState } = useModalState();
  const [notifications, setNotifications] = useState(
    initialNotificationToggleState,
  );
  const { mutateAsync, isLoading } = useEventNotifications();

  const onClose = () => closeModal();

  const notificationId = modalState?.msTeamsNotificationId;

  const handleNext = () => {
    setModalState({ notifications });
    const activatedEventNotifications =
      mapNotificationsToSnakeCase(notifications);

    mutateAsync({
      notificationId,
      data: { events: activatedEventNotifications },
    }).then(() => {
      nextModal();
    });
  };

  const handleToggle = (toggleName) => {
    setNotifications((prevState) => ({
      ...prevState,
      [toggleName]: !prevState[toggleName],
    }));
  };

  const isNextDisabled = !Object.values(notifications).some(Boolean);

  return (
    <Container>
      <ModalContent>
        <img src={eventNotifcationIcon} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>Set up event notifications</StyledSlideHeader>
        <p className="b-txt-light">
          Specify which events should trigger a notification from the system.
        </p>
        <StyledNotificationContainer>
          {eventTriggers.map(
            ({ name, toggleName, isDisabled }) =>
              !isDisabled && (
                <SyledToggle key={toggleName}>
                  <span>{name}</span>
                  <Toggle
                    checked={notifications[toggleName]}
                    onToggle={() => handleToggle(toggleName)}
                  />
                </SyledToggle>
              ),
          )}
        </StyledNotificationContainer>
      </ModalContent>
      <FooterButtons
        showStepper
        stepperIdx={1}
        stepsAmount={4}
        onClose={onClose}
        handleNext={handleNext}
        showBackButton
        backOnClick={previousModal}
        isWorking={isLoading}
        nextButtonDisabled={isNextDisabled}
      />
    </Container>
  );
};

export default EventNotifications;
