/* istanbul ignore file */

import React, { createContext, useState, useContext } from 'react';

import { node, object } from 'prop-types';

import { Toaster as toaster } from '@kandji-inc/bumblebee';

import { connectorService } from '../services/connector';

import { useIs } from '.';

const ConnectorContext = createContext({
  connector: '',
  connectorItem: {},
  connectorList: [],
  connectorLimit: 0,

  setConnector: () => {},
  setConnectorItem: () => {},
  setConnectorList: () => {},
  setConnectorLimit: () => {},

  clearConnector: () => {},
  clearConnectorItem: () => {},
  clearConnectorList: () => {},

  addConnectorItem: () => {},
  fetchConnectorItem: () => {},
  fetchConnectorList: () => {},
  fetchConnectorLimit: () => {},
  deleteConnectorItem: () => {},

  getInstallerDownloadLink: () => {},
});

const ConnectorProvider = ({ children, value }) => {
  const { setIsLoading, setIsWorking } = useIs();

  const [connector, setConnector] = useState('');
  const [connectorItem, setConnectorItem] = useState({});
  const [connectorList, setConnectorList] = useState([]);
  const [connectorLimit, setConnectorLimit] = useState(0);

  // console.log('Connector', connector); // delete
  // console.log('Connector Item', connectorItem); // delete
  // console.log('Connector List', connectorList); // delete

  const clearConnector = () => setConnector('');
  const clearConnectorItem = () => setConnectorItem({});
  const clearConnectorList = () => setConnectorList([]);

  const addConnectorItem = async (form, shouldSetConnector = false) => {
    try {
      setIsWorking(true);

      const data = await connectorService.createConnector(form);

      if (shouldSetConnector) {
        setConnector(data.id);
      }
      setIsWorking(false);

      toaster('Connector added.');
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster('Something went wrong.');
    }
  };

  const fetchConnectorItem = async () => {
    try {
      setIsLoading(true);

      const data = await connectorService.getConnector(connector);
      // console.log('Data', data); // delete

      setConnectorItem(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error); // delete

      setIsLoading(false);

      toaster('Something went wrong.');
    }
  };

  const fetchConnectorList = async () => {
    try {
      setIsLoading(true);

      const data = await connectorService.listConnectors();

      setConnectorList(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error); // delete

      setIsLoading(false);

      toaster('Something went wrong.');
    }
  };

  const fetchConnectorLimit = async () => {
    try {
      setIsLoading(true);

      const limit = await connectorService.getConnectorLimit();

      setConnectorLimit(limit);
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      setIsLoading(false);

      toaster('Something went wrong');
    }
  };

  const deleteConnectorItem = async (id) => {
    try {
      setIsWorking(true);

      const connectorName = connectorItem.name;
      await connectorService.deleteConnector(id);

      setIsWorking(false);

      toaster(`Connector ${connectorName} has been deleted.`);
    } catch (error) {
      console.error(error); // delete

      setIsWorking(false);

      toaster('Something went wrong.');
    }
  };

  const getInstallerDownloadLink = async () => {
    try {
      const data = await connectorService.getInstallerDownloadLink();
      console.log('getInstallerDownloadLink data', data);
      return data;
    } catch (err) {
      console.error('ERROR: getInstallerDownloadLink: ', err);
    }

    return {};
  };

  return (
    <ConnectorContext.Provider
      value={{
        connector,
        connectorItem,
        connectorList,
        connectorLimit,

        setConnector,
        setConnectorItem,
        setConnectorList,
        setConnectorLimit,

        clearConnector,
        clearConnectorItem,
        clearConnectorList,

        addConnectorItem,
        fetchConnectorItem,
        fetchConnectorList,
        fetchConnectorLimit,
        deleteConnectorItem,

        getInstallerDownloadLink,

        ...value,
      }}
    >
      {children}
    </ConnectorContext.Provider>
  );
};

ConnectorProvider.propTypes = {
  children: node.isRequired,
  value: object,
};

ConnectorProvider.defaultProps = {
  value: {},
};

const useConnector = () => useContext(ConnectorContext);

export default useConnector;
export { ConnectorContext, ConnectorProvider };
