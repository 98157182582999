import { Body, Button, DialogV2 as Dialog, Flex } from '@kandji-inc/nectar-ui';

export function ManualEnrollmentCancelDialog({
  blueprint,
  isOpen,
  toggleDialog,
  handleCancel,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      title={`Cancel editing ${blueprint.name}'s enrollment portal settings?`}
      onOpenChange={toggleDialog}
      css={{ width: 500 }}
      content={
        <Flex flow="column">
          <Body css={{ marginBottom: 20 }}>
            Changes you made will not be saved.
          </Body>
          <Flex gap="sm" justifyContent="end" css={{ padding: '$4 0' }}>
            <Button
              compact
              onClick={/* istanbul ignore next */ () => toggleDialog(false)}
            >
              Continue editing
            </Button>
            <Button variant="primary" compact onClick={handleCancel}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
}
