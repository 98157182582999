import { Button } from '@kandji-inc/bumblebee';
import { Chip } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';
import {
  CardContainer,
  CardSection,
  CardTitle,
  StyledOptionsMenu,
} from 'src/features/integrations/components/styled-components';
import styled from 'styled-components';
import { StyledImg as Img } from '../styled-components';

import ConfigurationModal from '../../../../components/configuration-modal';
import oktaOutline from '../../modals/assets/okta-icon-outline.svg';
import type { ODTIntegration } from '../../types';
import EditIntegrationSettings from './edit-integration-settings';

type Close = () => void;

type Props = {
  data?: ODTIntegration;
};

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  & > ${Content} {
    margin-left: var(--b-gap);
  }
`;

const StyledImg = styled(Img)`
  align-self: start;
  margin-right: var(--b-gap);
`;

const StyledChipContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: var(--b-gap);
  height: 100%;
  > .styled-chip {
    height: 100%;
    align-self: center;
  }
`;

const CardSubheading = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const StyledOktaConfigModal = styled(ConfigurationModal)`
  min-height: 640px;
  width: 960px;
  display: grid;
  align-items: center;
  text-align: center;
  padding: 0;
`;

const General = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data } = props;

  const { domain, client_id: clientId } = data ?? {};

  const labelText = 'Connected';

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CardContainer>
        <CardTitle>
          <h4 className="b-h4 b-mr">General</h4>
        </CardTitle>
        <CardSection>
          <Container>
            <StyledImg src={oktaOutline} alt="okta-outline" />
            <Content>
              <CardSubheading>
                <h4 className="b-h4 b-mr">Integration settings</h4>
                <StyledChipContainer data-testid="integration-settings-chip-container">
                  {/* TODO: Updating this chip will be captured in another ticket */}
                  <Chip
                    className="styled-chip"
                    label={labelText}
                    css={{ backgroundColor: '$green10' }}
                  />
                  <StyledOptionsMenu
                    render={(close: Close) => (
                      <>
                        <Button
                          icon="pencil"
                          kind="link"
                          onClick={() => {
                            close();
                            setIsModalOpen(true);
                          }}
                        >
                          Edit settings
                        </Button>
                      </>
                    )}
                  >
                    <Button size="small" icon="ellipsis" kind="link" />
                  </StyledOptionsMenu>
                </StyledChipContainer>
              </CardSubheading>
              <DataContainer>
                <div>
                  <p className="b-txt-light">Okta domain</p>
                  <p>{domain}</p>
                </div>
                <div>
                  <p className="b-txt-light">Client ID</p>
                  <p>{clientId}</p>
                </div>
              </DataContainer>
            </Content>
          </Container>
        </CardSection>
      </CardContainer>
      {data && (
        <StyledOktaConfigModal isModalOpen={isModalOpen}>
          <EditIntegrationSettings
            integrationData={data}
            onClose={handleCloseModal}
          />
        </StyledOktaConfigModal>
      )}
    </>
  );
};

export default General;
