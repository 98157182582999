import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Icon,
  type IconNames,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';

type PulseCheckType =
  | 'file'
  | 'processes'
  | 'users'
  | 'preferences'
  | undefined;

export type SelectPulseDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpenChange: (isOpen: boolean) => void;
  onSubmit: (pulseType: PulseCheckType) => void;
};

export const SelectPulseDialog = ({
  isOpen,
  onClose,
  onOpenChange,
  onSubmit,
}: SelectPulseDialogProps) => {
  const [pulseType, setPulseType] = React.useState<PulseCheckType>();

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content
        onClose={onClose}
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
        css={{
          width: '800px',
          height: 'auto',
        }}
      >
        <Dialog.Header>
          <Dialog.Title>Create Pulse report</Dialog.Title>
        </Dialog.Header>
        <Flex p5 gap="lg" flow="column">
          <Flex gap="lg">
            <PulseTypeOption
              title="File Check"
              description="Return whether a file exists on Mac computers. Search for a specific file path or hash and return the metadata for the file."
              selected={pulseType === 'file'}
              icon="list-search"
              disabled={false}
              onClick={() =>
                setPulseType(pulseType === 'file' ? undefined : 'file')
              }
            />
            <PulseTypeOption
              title="Processes"
              description="Target a specific process or list of processes and the process state on Mac computers."
              selected={pulseType === 'processes'}
              icon="wave-pulse"
              disabled={false}
              onClick={() =>
                setPulseType(
                  pulseType === 'processes' ? undefined : 'processes',
                )
              }
            />
          </Flex>
          <Flex gap="lg">
            <PulseTypeOption
              title="Logged In Users"
              description="Target Mac computers in your fleet and return a list of logged in users for those computers."
              selected={pulseType === 'users'}
              icon="user-arrow-right"
              disabled={false}
              onClick={() =>
                setPulseType(pulseType === 'users' ? undefined : 'users')
              }
            />
            <PulseTypeOption
              title="Preferences"
              description="Query on a specific preference domain or plist on Mac computers and return key-pair values."
              selected={pulseType === 'preferences'}
              icon="settings-gear"
              disabled={false}
              onClick={() =>
                setPulseType(
                  pulseType === 'preferences' ? undefined : 'preferences',
                )
              }
            />
          </Flex>
        </Flex>
        <Dialog.Footer>
          <Button
            variant="subtle"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={pulseType === undefined}
            onClick={() => onSubmit(pulseType)}
            variant="primary"
          >
            Configure report
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const PulseTypeCard = styled(Flex, {
  border: '1px solid $neutral20',
  cursor: 'pointer',
  flexDirection: 'column',
  flex: '0 1 50%',
  padding: '$4',
  gap: '$2',
  borderRadius: '$2',
  '&:hover': {
    backgroundColor: '$neutral5',
    boxShadow: '$elevation2',
  },
  variants: {
    selected: {
      true: {
        boxShadow: '$elevation2',
        backgroundColor: '$blue10',
        borderColor: '$blue40',
      },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});

const PulseTypeOption = ({
  title,
  description,
  icon,
  disabled,
  selected,
  onClick,
}: {
  title: string;
  description: string;
  icon: IconNames;
  disabled: boolean;
  selected: boolean;
  onClick?: () => void;
}) => (
  <PulseTypeCard onClick={onClick} disabled={disabled} selected={selected}>
    <Flex gap="sm" alignItems="center">
      <Icon size="md" name={icon} />
      <Text size="3" css={{ fontWeight: '$medium' }}>
        {title}
      </Text>
    </Flex>
    <Flex gap="md">
      <Text variant="description">{description}</Text>
    </Flex>
    <Icon size="sm" name="laptop-mobile" />
  </PulseTypeCard>
);
