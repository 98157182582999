import {
  Button,
  DropdownMenu,
  type DropdownMenuOption,
  styled,
} from '@kandji-inc/nectar-ui';

const TriggerButton = styled(Button, {
  '& svg': {
    width: '$5',
    height: '$5',
  },
  fontVariantNumeric: 'unset',
  gap: '2px',
});

type ThreatListDropdownButtonProps = {
  value: string | number;
  triggerLabel: string;
  options: DropdownMenuOption[];
  onChange: (value: number) => void;
  disabled?: boolean;
  testId?: string;
};

const ThreatListDropdownButton = ({
  value,
  triggerLabel,
  options,
  onChange,
  disabled = false,
  testId,
}: ThreatListDropdownButtonProps) => {
  return (
    <DropdownMenu
      contentProps={{ align: 'start' }}
      css={{ zIndex: 1, minWidth: 68 }}
      withArrow={false}
      options={options.map((option) => ({
        ...option,
        onClick: () => onChange(option.value),
        selected: option.value === value,
      }))}
    >
      <TriggerButton
        compact
        variant="subtle"
        icon={{ name: 'fa-angle-down-small', position: 'right' }}
        disabled={disabled}
        css={{
          padding: '0 3px 0 $2',
        }}
        data-testid={testId}
      >
        {triggerLabel}
      </TriggerButton>
    </DropdownMenu>
  );
};

export default ThreatListDropdownButton;
