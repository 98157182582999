import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useContext } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import PreferencesPane from './sections/preference-pane';
import { systemPreferencesService } from './service/system-preferences-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useSystemPreferencesService from './service/use-system-preferences-service';

const SystemPreferencesPage = (props) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const preVenturaUpdate = useCallback(update('preVentura', setModel), []);
  const postVenturaUpdate = useCallback(update('postVentura', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  return (
    <div style={{ height: '1940px' }}>
      <LibraryItemPage
        {...props}
        type={itemConfig.type}
        identifier={itemConfig.identifier}
        crumb={model.name || itemConfig.name}
        summaryInfoProps={{
          name: `${itemConfig.name} Profile`,
          description: itemConfig.description,
          publisher: itemConfig.publisher,
          devices: itemConfig.devices,
          requirements: itemConfig.osRequirements,
        }}
        triggerValidation={triggerValidation}
        transformToApi={transformToApi}
        transformFromApi={transformFromApi}
        service={systemPreferencesService}
        defaultIcon={itemConfig.icon}
        ActivityTab={ActivityTab}
        StatusTab={NewStatusTab}
        getStatusTabProps={getNewStatusTabProps}
        supportsRules
        supportsDuplication
      >
        <PreferencesPane
          title="System Preferences (macOS 12.x and below)"
          subtitle="Block the following preference panes for devices running macOS 12.x and below."
          settings={model.preVentura}
          isDisabled={pageState.isDisabled}
          update={preVenturaUpdate}
        />
        <PreferencesPane
          title="System Settings (macOS 13.0+)"
          subtitle="Block the following preference panes for devices running macOS 13.0 and above."
          settings={model.postVentura}
          isDisabled={pageState.isDisabled}
          update={postVenturaUpdate}
        />
      </LibraryItemPage>
    </div>
  );
};

const SystemPreferences = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useSystemPreferencesService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <SystemPreferencesPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="system-preferences-v2"
    />
  );
};

export default SystemPreferences;
