import { Button, Grid } from '@kandji-inc/nectar-ui';
import * as React from 'react';

import { AppAccessCard } from '../components/AppAccessCard';
import { INITIAL_APP_ACCESS_SETTING } from '../initial-state';
import { privacyEnum } from '../schema/privacy.schema';

import type { PrivacyModel, UpdatePrivacy } from '../privacy.types';

interface AppAccessProps {
  setting: PrivacyModel['appAccess'];
  update: UpdatePrivacy;
  isDisabled: boolean;
  showError: boolean;
}

const { updateActionType } = privacyEnum;
const updateActionTypeEnum = updateActionType.enum;

export const AppAccess = (props: Readonly<AppAccessProps>) => {
  const { setting, update, isDisabled, showError } = props;

  return (
    <Grid gapY="6">
      {setting.map((appAccessSetting, appAccessIndex) => (
        <AppAccessCard
          /* eslint-disable-next-line react/no-array-index-key -- stable id key
           * from API not available  */
          key={appAccessIndex}
          appAccessSetting={appAccessSetting}
          appAccessIndex={appAccessIndex}
          update={update}
          isDisabled={isDisabled}
          showError={showError}
        />
      ))}

      {!isDisabled && (
        <Button
          variant="primary"
          icon={{
            name: 'plus',
            position: 'left',
          }}
          onClick={() =>
            update({
              type: updateActionTypeEnum.setting,
              updater: (prev) => ({
                ...prev,
                appAccess: [...prev.appAccess, INITIAL_APP_ACCESS_SETTING],
              }),
            })
          }
          css={{
            justifySelf: 'end',
          }}
        >
          Add app access
        </Button>
      )}
    </Grid>
  );
};
