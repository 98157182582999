import React, { useContext } from 'react';
import { useOldForm } from 'src/old-honey-form';
import { Button } from 'theme/components/molecules/Action';
import { Context } from './context';

export default () => {
  const { isWorking, onCancel } = useContext(Context);
  const { handleReset } = useOldForm();
  const onClick = () => {
    handleReset();
    onCancel();
  };
  return (
    <Button tier="two" label="Cancel" disabled={isWorking} onClick={onClick} />
  );
};
