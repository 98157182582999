import type { EffectCallback, MouseEvent } from 'react';
import type React from 'react';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import {
  Badge,
  Box,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DropdownMenu,
  Flex,
  Heading,
  Icon,
  Tabs,
  Text,
  styled,
  useDialog,
} from '@kandji-inc/nectar-ui';

import { TARIFF_NOTIFICATION_ID } from 'components/region/DisabledTariffNotificator';
import { InterfaceContext } from 'contexts/interface';
import { UserRoles } from 'src/app/common/constants';
import useAccount from 'src/contexts/account';
import BlueprintListModals, {
  modalTypes,
} from 'src/features/blueprints/blueprints-list/modals';
import { paths } from 'src/features/blueprints/common';

import Overlay from '../components/Overlay';
import { FLOW_PATHS } from '../constants';
import {
  calculateHeight,
  numberToCommaString,
  pluralizeWord,
} from '../helpers';
import { DetailsModal } from '../modals';
import useBlueprint from '../services/use-blueprint';
import useComputers from '../services/use-computers';
import useBlueprintFlow from '../store';

const Root = styled(Box, {
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  zIndex: 10,
  backgroundColor: '$neutral0',
});

// Prevents description text from going over two lines and truncates with an ellipsis
const Description = styled(Text, {
  display: '-webkit-box',
  maxHeight: 'var(--sizes-8)',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  overflow: 'hidden',
});

const TabsWrapper = styled(Box, {
  "& [role='tablist']": {
    padding: '0 24px',
  },
});

const getTab = (url: string) => {
  const paths = Object.values(FLOW_PATHS);
  const tab = paths.find((path) => url.includes(path.path));
  return tab?.path || FLOW_PATHS.assignments.path;
};

type HeaderProps = {
  tabs: Partial<Record<keyof typeof FLOW_PATHS, React.FunctionComponent>>;
};

function Header(props: HeaderProps) {
  const { tabs } = props;
  const { userRole } = useAccount();
  const isUserHelpDesk = userRole === UserRoles.helpdesk;
  const isAuditor = userRole === UserRoles.auditor;
  const { patch, duplicate, del } = useBlueprint();
  const { count: devicesCount } = useComputers();
  const [
    blueprintModel,
    isEditingAssignments,
    isEditingParameters,
    isDeletingNode,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.model,
      state.isEditingAssignments,
      state.isEditingParameters,
      state.isDeletingNode,
    ]),
  );
  const match = useRouteMatch();
  const history = useHistory();
  const summaryRef = useRef<HTMLDivElement>();
  const tabsRef = useRef<HTMLDivElement>();
  const [isOpen, toggle] = useDialog(false);
  const [blueprintModalType, setBlueprintModalType] = useState(modalTypes.NONE);
  const [headerHeight, setHeaderHeight] = useState(0);

  const { id, name, description } = blueprintModel;
  const dropdownMenuActions: DropdownMenuOption[] = [
    {
      label: 'Edit name and description',
      icon: 'pen',
      onClick: () => toggle(true),
    },
    {
      label: 'Duplicate Blueprint',
      icon: 'copy',
      onClick: () => setBlueprintModalType(modalTypes.DUPLICATE),
    },
    {
      label: 'Delete Blueprint',
      icon: 'trash-can',
      theme: 'danger',
      onClick: () => setBlueprintModalType(modalTypes.DELETE),
    },
  ];
  const isInEdit = isEditingAssignments || isEditingParameters;

  const { bannerTopOffset } = useContext(InterfaceContext);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setHeaderHeight(
        calculateHeight(tabsRef?.current?.childNodes[0] as HTMLElement) +
          calculateHeight(summaryRef?.current as HTMLElement) || 0,
      );
    });

    if (summaryRef.current) {
      observer.observe(summaryRef.current, {
        childList: true,
        subtree: true,
      });
    }

    setHeaderHeight(
      calculateHeight(tabsRef?.current?.childNodes[0] as HTMLElement) +
        calculateHeight(summaryRef?.current as HTMLElement) || 0,
    );

    return () => observer.disconnect();
  }, [summaryRef]);

  useEffect((): ReturnType<EffectCallback> => {
    if (isInEdit) {
      const tabsList = document.querySelector(
        "[role='tablist']",
      ) as HTMLElement;
      const separator = (document.querySelector(
        "[role='separator'][data-nectar-variants-variant='tab']",
      ) ||
        document.querySelector(
          "[role='separator'][data-nectar-variants-variant='defaultTab']",
        )) as HTMLElement;
      if (tabsList && separator) {
        const oldTabsListDisplay = tabsList.style.display;
        const oldSeparatorDisplay = separator.style.display;

        tabsList.style.display = 'none';
        separator.style.display = 'none';

        return () => {
          tabsList.style.display = oldTabsListDisplay;
          separator.style.display = oldSeparatorDisplay;
        };
      }
    }
  }, [isInEdit]);

  const accountLimitBannerHeight =
    document.getElementById(TARIFF_NOTIFICATION_ID)?.clientHeight || 0;

  const tabsStyle = {
    height: `calc(100vh - ${
      headerHeight + bannerTopOffset + accountLimitBannerHeight
    }px)`,
  };
  return (
    <Root>
      <Flex
        ref={summaryRef}
        flow="column"
        gap="md"
        css={{
          position: 'relative',
          padding: '24px 16px',
          paddingBottom: '18px',
        }}
      >
        {!isEditingAssignments && (
          <Breadcrumbs isNavigation>
            <BreadcrumbsItem item={{ title: 'Blueprints', url: paths.root }} />
            <BreadcrumbsItem
              item={{
                title: name,
                url: isEditingParameters && paths.flowBlueprint(id),
              }}
            />
            {isEditingParameters && (
              <BreadcrumbsItem item={{ title: 'Edit Parameters' }} />
            )}
          </Breadcrumbs>
        )}

        <Flex alignItems="center" justifyContent="space-between">
          {!isEditingParameters && (
            <Flex gap="sm" alignItems="center">
              <Flex
                p2
                css={{
                  backgroundColor: '$blue10',
                  borderRadius: '$round',
                  height: isInEdit ? '36px' : '40px',
                  width: isInEdit ? '36px' : '40px',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Icon name="kandji-blueprint" color="var(--colors-blue60)" />
              </Flex>
              <Box>
                <Flex alignItems="center" css={{ gap: '6px' }}>
                  <Heading
                    size={isInEdit ? '4' : '2'}
                    css={{ lineHeight: isInEdit ? '24px' : '36px' }}
                  >
                    {name}
                  </Heading>
                  {isEditingAssignments && (
                    <Button
                      compact
                      variant="subtle"
                      icon={{ name: 'pen' }}
                      css={{
                        backgroundColor: 'rgba(255, 255, 255, 0.00)',
                        height: '28px',
                        width: '28px',
                        padding: '$1',
                        alignSelf: 'center',
                      }}
                      onClick={() => toggle(true)}
                      data-testid="edit-details-pen"
                    />
                  )}
                  {!isInEdit && typeof devicesCount === 'number' && (
                    <Badge
                      css={{ alignSelf: 'center', marginLeft: '$1' }}
                    >{`${numberToCommaString(devicesCount)} ${pluralizeWord(
                      'device',
                      devicesCount,
                    )}`}</Badge>
                  )}
                </Flex>
                <Description variant="description">{description}</Description>
              </Box>
            </Flex>
          )}
          {isEditingParameters && (
            <Heading size="2" css={{ lineHeight: '36px' }}>
              Edit Parameters
            </Heading>
          )}
          <Flex id="blueprint-flow-header-actions" alignItems="center" gap="md">
            {!isUserHelpDesk && !isAuditor && !isInEdit && (
              <DropdownMenu
                options={dropdownMenuActions}
                contentProps={{ align: 'end' }}
                css={{ zIndex: 10 }}
              >
                <Button
                  compact
                  id="dropdown-actions"
                  data-testid="dropdown-actions"
                  title="dropdown-actions"
                  variant="default"
                  icon={{ name: 'ellipsis' }}
                  css={{ width: 'var(--sizes-7)', height: 'var(--sizes-7)' }}
                  onClick={
                    // istanbul ignore next - browser functions
                    (e: MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }
                />
              </DropdownMenu>
            )}
          </Flex>
        </Flex>
      </Flex>

      <TabsWrapper>
        <Tabs.Container
          compact
          tabId={getTab(match.url)}
          ref={tabsRef}
          defaultTabId={getTab(match.url)}
          tabs={Object.keys(tabs).map((tab) => {
            const flowPath = FLOW_PATHS[tab];
            return {
              label: flowPath.label,
              tabId: flowPath.path,
              onClick: () =>
                history.push(`${paths.flowBlueprint(id, flowPath.path)}`),
            };
          })}
        >
          {Object.keys(tabs).map((tab) => {
            const Component = tabs[tab];
            return (
              <Tabs.Content key={tab} tabId={tab}>
                <Box css={tabsStyle}>
                  <Component headerHeight={headerHeight} />
                </Box>
              </Tabs.Content>
            );
          })}
        </Tabs.Container>
      </TabsWrapper>

      <Overlay isHidden={!isDeletingNode} style={{ height: headerHeight }} />

      <DetailsModal
        isOpen={isOpen}
        toggle={toggle}
        onConfirm={(bp) => {
          if (!isInEdit) {
            patch(bp);
          }
        }}
      />
      <BlueprintListModals
        isFlowBlueprint
        type={blueprintModalType}
        blueprint={blueprintModel}
        onDelete={() => del(blueprintModel)}
        onClose={() => {
          setBlueprintModalType(modalTypes.NONE);
        }}
        onDuplicate={duplicate}
      />
    </Root>
  );
}

export default memo(Header);
