/* istanbul ignore file -- various configs don't need individual tests */
import { rootApi } from 'app/api/base';
import axios from 'axios';

class S3Uploader {
  constructor(backendUrl) {
    this.backendUrl = backendUrl;
  }

  generateForm(s3Fields, file) {
    const formData = new FormData();
    Object.entries(s3Fields).forEach((values) => formData.append(...values));
    formData.append('file', file);
    return formData;
  }

  trackUploadProgress(cb) {
    return (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (typeof cb === 'function') {
        cb(percentCompleted, {
          loaded: progressEvent.loaded,
          total: progressEvent.total,
        });
      }
    };
  }

  /**
   * Returns s3 data, including a presigned post url, for each file. Note: The
   * actual file does not need to be passed to this endpoint, just the name of the
   * file.
   * @param {Array} files - Objects with single key/value {name: nameOfFile.zip}
   */
  getS3UploadData(files) {
    return rootApi(this.backendUrl).post({ files });
  }

  /**
   * s3Data contains a url and fields.
   * url is the base url for s3.
   * fields is an object following the shape of: 
   * {
      AWSAccessKeyId: '',
      key: '',
      policy: '',
      signature: '',
  * }
  *
  * file is a File instance (generally the object returned from an input file select)
  */
  upload({ file, s3Data }, onUploadProgress, axiosCancel, options = {}) {
    const { url, fields } = s3Data;
    const form = this.generateForm(fields, file);
    const withCancel = axiosCancel ? { cancelToken: axiosCancel.token } : {};

    return axios.post(url, form, {
      onUploadProgress: this.trackUploadProgress(onUploadProgress),
      ...withCancel,
      ...options,
    });
  }
}

// private files

export const PassportUploader = new S3Uploader(
  'library/v1/library-items/passport-list-upload',
);
export const LiftoffUploader = new S3Uploader(
  'library/v1/library-items/liftoff-list-upload',
);
export const WallpaperUploader = new S3Uploader(
  'library/v1/library-items/wallpaper-list-upload',
);
export const SelfServiceLogoUploader = new S3Uploader(
  'library/v1/library-items/self-service-list-upload',
);

// public files

export const PublicSelfServiceIconUploader = new S3Uploader(
  'library/v1/library-items/public-self-service-icon-list-upload',
);

export const PublicBookmarkIconUploader = new S3Uploader(
  'library/v1/library-items/public-bookmark-icon-list-upload',
);
