import { Box, Flex, Pagination, Text, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import usePagination from './usePagination';

const PaginationFooter = styled(Flex, {
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: '$5',
  paddingBlock: '$2',
});

type AllowBlockListPaginationProps = {
  onPageChange: (page: number) => void;
  totalItems: number;
  page: number;
  pageSize: number;
};

const AllowBlockListPagination = (props: AllowBlockListPaginationProps) => {
  const { onPageChange, totalItems, page, pageSize } = props;

  const { startCount, endCount, totalPages } = usePagination(
    page,
    pageSize,
    totalItems,
  );

  return (
    <PaginationFooter>
      <Text variant="description">
        {`${startCount} - ${endCount} of ${totalItems}`} results
      </Text>
      <Box>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={onPageChange}
          data-testid="pagination"
        />
      </Box>
    </PaginationFooter>
  );
};

export default AllowBlockListPagination;
