import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';
import './airplay-security.css';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from '../../common/activity-tab';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import configs from '../../library/library-item-configurations/items/profile';
import AccessCard from './access-card';
import initialState from './initial-state';
import SecurityCard from './security-card';
import { airplaySecurityService } from './service/airplay-security-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useAirplaySecurityService from './service/use-airplay-security-service';

const AirplaySecurityPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const accessUpdate = useCallback(update('access', setModel), []);
  const securityUpdate = useCallback(update('security', setModel), []);

  const itemConfig = configs['Airplay Security'];

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={airplaySecurityService}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={itemConfig.icon}
      supportsRules
      supportsDuplication
    >
      <AccessCard
        setting={model.access}
        update={accessUpdate}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
      />
      <SecurityCard
        setting={model.security}
        update={securityUpdate}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const AirplaySecurity = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useAirplaySecurityService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AirplaySecurityPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
    />
  );
};

export default AirplaySecurity;
