import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export interface UseSyncUrlWithTableStateReturnType {
  prismCategory: string;
  blueprints: string[];
  deviceFamilies: string[];
  filter: any; // Adjust the type according to your filter object structure
  limit: number;
  offset: number;
  viewId: string | null;
  sortBy: string | null;
  setViewId: (viewId: string) => void;
  setBlueprints: (blueprints: string[]) => void;
  resetBlueprints: () => void;
  setDeviceFamilies: (deviceFamilies: string[]) => void;
  setFilter: (filter: any) => void;
  removeFilter: (filterKey: string) => void;
  removeViewId: () => void;
  setLimit: (limit: number) => void;
  setOffset: (offset: number) => void;
  resetBlueprintsAndDeviceFamilies: () => void;
  resetFilter: () => void;
  replaceFilter: (filter: any) => void;
  setCategoryAndFilters: (url: string) => void;
  setSortBy: (sortBy: string) => void;
}

export function useSyncUrlWithTableState(
  baseUrl = '/devices/prism',
): UseSyncUrlWithTableStateReturnType {
  const { prismCategory } = useParams<{ prismCategory: string }>();
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const categoryUrl = useMemo(
    () => `${baseUrl}/${prismCategory || ''}`,
    [baseUrl, prismCategory],
  );

  const blueprints = searchParams.get('blueprints')?.split(',') ?? [];
  const deviceFamilies = searchParams.get('deviceFamilies')?.split(',') ?? [];
  const filterString = searchParams.get('filter');
  const viewId = searchParams.get('view_id');

  const filter: Record<string, any> = useMemo(
    () => (filterString ? JSON.parse(filterString.replace(/'/g, '')) : {}),
    [filterString],
  );
  const limit = parseInt(searchParams.get('limit') ?? '25', 10);
  const offset = parseInt(searchParams.get('offset') ?? '0', 10);
  const sortBy = searchParams.get('sort_by');

  const updateUrl = useCallback(
    (key: string, value: string | number | string[] | any) => {
      if (Array.isArray(value)) {
        searchParams.set(key, value.join(','));
      } else if (typeof value === 'object') {
        searchParams.set(key, JSON.stringify(value));
      } else {
        searchParams.set(key, value.toString());
      }
      history.push(`${categoryUrl}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [history, categoryUrl, searchParams],
  );

  const setViewId = useCallback(
    (viewId: string) => updateUrl('view_id', viewId),
    [updateUrl],
  );

  const setBlueprints = useCallback(
    (blueprints: string[]) => updateUrl('blueprints', blueprints),
    [updateUrl],
  );

  const resetBlueprints = useCallback(() => {
    searchParams.delete('blueprints');
    history.push(`${categoryUrl}?${searchParams.toString()}`, {
      replace: true,
    });
  }, [history, categoryUrl, searchParams]);

  const setDeviceFamilies = useCallback(
    (deviceFamilies: string[]) => updateUrl('deviceFamilies', deviceFamilies),
    [updateUrl],
  );

  const setFilter = useCallback(
    (newFilter: Record<string, any>) => {
      const updatedFilter = { ...filter, ...newFilter };
      updateUrl('filter', updatedFilter);
    },
    [filter, updateUrl],
  );

  const replaceFilter = useCallback(
    (newFilter: Record<string, any>) => updateUrl('filter', newFilter),
    [updateUrl],
  );

  const removeViewId = useCallback(() => {
    searchParams.delete('view_id');
    history.push(`${categoryUrl}?${searchParams.toString()}`, {
      replace: true,
    });
  }, [history, searchParams, categoryUrl]);

  const removeFilter = useCallback(
    (filterKey: string) => {
      const newFilter = { ...filter };
      delete newFilter[filterKey];
      updateUrl('filter', newFilter);
    },
    [filter, updateUrl],
  );

  const resetFilter = useCallback(() => {
    searchParams.delete('filter');
    history.push(`${categoryUrl}?${searchParams.toString()}`, {
      replace: true,
    });
  }, [history, categoryUrl, searchParams]);

  const setLimit = useCallback(
    (limit: number) => updateUrl('limit', limit),
    [updateUrl],
  );

  const setOffset = useCallback(
    (offset: number) => updateUrl('offset', offset),
    [updateUrl],
  );

  const resetBlueprintsAndDeviceFamilies = useCallback(() => {
    searchParams.delete('blueprints');
    searchParams.delete('deviceFamilies');
    history.push(`${categoryUrl}?${searchParams.toString()}`, {
      replace: true,
    });
  }, [searchParams, categoryUrl, history]);

  const setCategoryAndFilters = useCallback(
    (url: string) => {
      history.push(`${baseUrl}${url}`, {
        replace: true,
      });
    },
    [baseUrl, history],
  );

  const setSortBy = useCallback(
    (sortBy: string) => {
      updateUrl('sort_by', sortBy);
    },
    [updateUrl],
  );

  return {
    prismCategory,
    blueprints,
    deviceFamilies,
    filter,
    limit,
    offset,
    viewId,
    sortBy,
    setViewId,
    setBlueprints,
    resetBlueprints,
    setDeviceFamilies,
    setFilter,
    removeFilter,
    removeViewId,
    setLimit,
    setOffset,
    resetBlueprintsAndDeviceFamilies,
    resetFilter,
    replaceFilter,
    setCategoryAndFilters,
    setSortBy,
  };
}
