import {
  Button,
  Flex,
  LazyScriptEditor,
  Radio,
  Uploader,
  defaultRenderInfo,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { apiTypes } from 'features/library-items/library/common';
/* istanbul ignore file */
import React, { useEffect, useMemo, useState, memo } from 'react';

import { Setting } from 'features/library-items/template';

import {
  getS3InfoForFile,
  uploadCustomApp,
  waitForSHA256,
} from 'templates/library/custom-apps/api';

const PrinterFileCard = (props) => {
  const { setting, update, isDisabled, isSubmitted, onValidate } = props;
  const fieldsToValidate = ['file'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useEffect(() => {
    if (setting.hasFile && !setting.file?.sha256) {
      onInvalidate(0)('Required');
    } else {
      onInvalidate(0)(false);
    }
  }, [setting.hasFile, setting.file?.sha256]);

  const [uploadCancelFn, setUploadCancelFn] = useState();
  const [isPreInstallScriptOpened, setIsPreInstallScriptOpened] = useState(
    !!setting.preInstallScript,
  );
  const [isPostInstallScriptOpened, setIsPostInstallScriptOpened] = useState(
    !!setting.postInstallScript,
  );

  const forceWithFile = useMemo(
    () =>
      (setting.file?.sha256 && {
        file: {
          name: setting.file.name,
          size: setting.file.size,
        },
        sha256: setting.file.sha256,
      }) ||
      null,
    [setting.file],
  );

  const setIsSaveEnabled = onValidate((isValid) => isValid);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Printer Files</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Upload a printer software package and customize the install process
          with pre/post install scripts.
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <div className="b-mb1">
              <Radio
                label="Don't provide printer files"
                checked={!setting.hasFile}
                onChange={() => update('hasFile', false)}
                isDisabled={isDisabled}
              />
            </div>
            <div>
              <Radio
                label="Install printer software package"
                checked={setting.hasFile}
                onChange={() => update('hasFile', true)}
                isDisabled={isDisabled}
              />
            </div>
          </Setting.Controls>

          {setting.hasFile && (
            <Setting.SecondaryControls>
              <div className="b-library-form">
                {!isPreInstallScriptOpened && (
                  <Button
                    icon="circle-plus"
                    kind="link"
                    className="b-mb1"
                    onClick={() => setIsPreInstallScriptOpened(true)}
                    isDisabled={isDisabled}
                  >
                    Add Preinstall Script
                  </Button>
                )}
                {isPreInstallScriptOpened && (
                  <>
                    <Flex
                      justify="space-between"
                      align="center"
                      className="b-txt b-mb1"
                    >
                      <div> Preinstall Script </div>
                      <Button
                        onClick={() => {
                          setIsPreInstallScriptOpened(false);
                          update('preInstallScript', '');
                        }}
                        isDisabled={isDisabled}
                        icon="trash-can"
                        kind="link"
                        theme="error"
                      />
                    </Flex>
                    <LazyScriptEditor
                      value={setting.preInstallScript}
                      onChange={(value) => update('preInstallScript', value)}
                      language="shell"
                      options={{ readOnly: isDisabled }}
                      className={isDisabled && 'k-mobile-config-info__preview'}
                    />
                  </>
                )}

                <p ref={refs[0]} className="b-txt b-mb1 b-mt2">
                  Installer Package
                </p>
                <Uploader
                  withError={(isSubmitted && invalidations[0]) || ''}
                  onError={(error) => {
                    setIsSaveEnabled(true);
                    onInvalidate(0)(!!error);
                  }}
                  forceWithFile={forceWithFile}
                  onUpload={(file, updateProgress) =>
                    getS3InfoForFile(file, apiTypes.CUSTOM_PRINTER).then(
                      (r) => {
                        setIsSaveEnabled(false);
                        const s3Data = r.data.s3_post_data;
                        const upWithCancel = uploadCustomApp(
                          file,
                          updateProgress,
                          s3Data,
                        );
                        update('file', (p) => ({
                          ...p,
                          id: r.data.id,
                          filePath: s3Data.fields.key,
                          name: file.name,
                          size: file.size,
                          s3key: s3Data.fields.key,
                          uploadedAt: new Date().toISOString(),
                        }));
                        setUploadCancelFn(() => upWithCancel.cancel);
                        return upWithCancel.upload;
                      },
                    )
                  }
                  onUploaded={(file) => {
                    setIsSaveEnabled(true);
                    update('file', (p) => ({
                      ...p,
                      name: file.name,
                      size: file.size,
                    }));
                  }}
                  onValidate={() =>
                    waitForSHA256(setting.file.id).then((sha256) => {
                      update('file', (p) => ({
                        ...p,
                        sha256,
                      }));
                      return sha256;
                    })
                  }
                  onCancel={uploadCancelFn}
                  onDelete={() => {
                    setUploadCancelFn(null);
                    update('file', null);
                  }}
                  allowedTypes={['.pkg']}
                  className="b-mb2"
                  isDisabled={isDisabled}
                  uploadInstructions={
                    <span>
                      Drag file here or{' '}
                      <span className="b-alink">click to upload</span>
                    </span>
                  }
                  typeAlert=".pkg (or zipped .pkg) file"
                  renderInfo={({ currentFile }) => (
                    <div>
                      {defaultRenderInfo({ isDisabled, currentFile })}
                      {setting.file?.downloadLink && (
                        <Button
                          kind="link"
                          theme="dark"
                          target="_blank"
                          href={setting.file?.downloadLink}
                          style={{ marginLeft: -8 }}
                        >
                          Download
                        </Button>
                      )}
                    </div>
                  )}
                />

                {!isPostInstallScriptOpened && (
                  <Button
                    icon="circle-plus"
                    kind="link"
                    className="b-mb1"
                    onClick={() => setIsPostInstallScriptOpened(true)}
                    isDisabled={isDisabled}
                  >
                    Add Postinstall Script
                  </Button>
                )}
                {isPostInstallScriptOpened && (
                  <>
                    <Flex
                      justify="space-between"
                      align="center"
                      className="b-txt b-mb1"
                    >
                      <div> Postinstall Script </div>
                      <Button
                        onClick={() => {
                          setIsPostInstallScriptOpened(false);
                          update('postInstallScript', '');
                        }}
                        isDisabled={isDisabled}
                        icon="trash-can"
                        kind="link"
                        theme="error"
                      />
                    </Flex>
                    <LazyScriptEditor
                      value={setting.postInstallScript}
                      onChange={(value) => update('postInstallScript', value)}
                      language="shell"
                      options={{ readOnly: isDisabled }}
                      className={isDisabled && 'k-mobile-config-info__preview'}
                    />
                  </>
                )}
              </div>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(PrinterFileCard);
