/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import featureFlags from 'src/config/feature-flags';

const useGetDeviceVulnerabilities = (deviceId: string) => {
  // Do not make the API call if the feature flag is not enabled
  if (!featureFlags.getFlag('vm_06252024_vuln-poc')) {
    return { data: null, isLoading: false };
  }

  const {
    data: apiRes,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: ['device-vulnerabilities', deviceId],
    queryFn: () => vulnerabilityApi(`devices/${deviceId}/summary`).get({}),
  });

  const data = apiRes?.data;

  return { data, isLoading, ...rest };
};

export { useGetDeviceVulnerabilities };
