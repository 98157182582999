import { Button } from '@kandji-inc/bumblebee';
import { API_BASE } from 'app/api/domains';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import IPadImage from '../../../assets/img/enrollment_portal/ipad_kandji.svg';
import { StepCard } from '../MDMIntegrationPage';
import { downloadFromUrl } from '../common/helpers';
import { LineLoader } from '../interface/LineLoader';
import HeaderWithLogo from './HeaderWithLogo';
import { validateEnrollmentCode } from './api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${document.documentElement.clientWidth};
`;

const ComputerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Header = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
  color: #1a1d25;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const HelperText = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1a1d25;
`;

const HeaderText = styled.div`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #1a1d25;
`;

const HelperTextGray = styled(HelperText)`
  margin: 0;
  text-align: left;
  color: #181c24;
  opacity: 0.6;
`;

const ButtonWrapper = styled.div`
  margin: 24px auto 74px;
`;

const InstructionsHeader = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  max-width: ${document.documentElement.clientWidth};
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #1a1d25;
  box-shadow:
    0px 2px 0px 0px rgba(29, 33, 42, 1),
    inset 0px -1px 0px 0px rgba(29, 33, 42, 1);
`;

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0 auto 20px auto;
  background-color: #b8bdca;
  border: 0 none;
`;

const InstructionsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  max-width: ${document.documentElement.clientWidth};
  padding: 20px;
  margin: 20px auto;
`;

const StepCardStyled = styled(StepCard)`
  max-width: calc(${document.documentElement.clientWidth} - 40px);
  img {
    margin-left: 15px;
  }
`;

class EnrollTabletPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAccessCodeValid: false,
      selectedBlueprint: null,
      validatedAccessCode: null,
    };
    this.buttonRef = React.createRef();
    this.onDownload = this.onDownload.bind(this);
  }

  componentDidMount() {
    const { history, match } = this.props;
    const accessCode = get(match, 'params.accessCode');
    if (accessCode) {
      validateEnrollmentCode(accessCode)
        .then(({ blueprint_id }) => {
          this.setState({
            isAccessCodeValid: true,
            selectedBlueprint: blueprint_id,
            validatedAccessCode: accessCode,
          });
        })
        .catch(() => history.push('/enroll'))
        .finally(() => this.setState({ isLoading: false }));
    }
  }

  onDownload() {
    const { selectedBlueprint, validatedAccessCode } = this.state;
    if (!selectedBlueprint) {
      return;
    }
    downloadFromUrl(
      `${API_BASE}mdm/enroll/${selectedBlueprint}?code=${validatedAccessCode}`,
    );
  }

  scrollToRef() {
    window.scrollTo(0, this.buttonRef.current.offsetTop);
  }

  render() {
    const { isLoading, isAccessCodeValid } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    if (!isAccessCodeValid) {
      return <h1>Access code is invalid</h1>;
    }
    return (
      <Container id="enroll">
        <HeaderWithLogo pageName="Enrollment Portal" large />
        <ComputerImageWrapper>
          <img role="presentation" alt="computer" src={IPadImage} />
        </ComputerImageWrapper>
        <Header>Enroll your iPad with Kandji</Header>
        <HelperText>
          To enroll, click the button below to download and install Kandji
        </HelperText>
        <ButtonWrapper>
          <Button
            type="button"
            innerRef={this.buttonRef}
            onClick={() => {
              this.onDownload();
              this.scrollToRef();
            }}
            style={{ width: '210px', background: '#FFCF01', color: '#1A1D25' }}
          >
            Download Kandji
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }
}

export default EnrollTabletPage;
