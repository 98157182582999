import { Flex } from '@kandji-inc/nectar-ui';
import { useEffect } from 'react';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import DetectionDateFilter from 'src/features/threat/common/components/CommonFilters/DetectionDateFilter';
import SearchFilter from 'src/features/threat/common/components/CommonFilters/SearchFilter';
import { useShallow } from 'zustand/react/shallow';
import { DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS } from '../../constants';
import useVulnerability, { defaultDateFilter } from '../../store';
import type { DetectionDateFilterFields } from '../../vulnerability.types';
import { SeverityMultiSelect } from './severity-multi-select';

const FilterBar = () => {
  const [filter, setFilter] = useVulnerability(
    useShallow((state) => [state.filter, state.setFilter]),
  );

  const [debouncedSearchValue, setSearchValue, searchValue] =
    useDebouncedState<string>('', 250);

  useEffect(() => {
    setFilter({ term: debouncedSearchValue });
  }, [debouncedSearchValue]);

  return (
    <Flex p3 gap="md">
      <SearchFilter
        name="vulnerability"
        value={searchValue}
        onChange={(_, value) => setSearchValue(value as string)}
        onClear={() => setSearchValue('')}
        placeholder="Search ID and Application"
      />

      <SeverityMultiSelect />

      <DetectionDateFilter
        filter={filter.firstDetected}
        label="First detected"
        options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        onChange={
          /*  istanbul ignore next */ (_, fields: DetectionDateFilterFields) =>
            setFilter({ firstDetected: fields })
        }
        onClear={
          /*  istanbul ignore next */ () =>
            setFilter({ firstDetected: defaultDateFilter })
        }
      />

      <DetectionDateFilter
        filter={filter.latestDetected}
        label="Last detected"
        options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        onChange={
          /* istanbul ignore next */ (_, fields: DetectionDateFilterFields) =>
            setFilter({ latestDetected: fields })
        }
        onClear={
          /*  istanbul ignore next */ () =>
            setFilter({ latestDetected: defaultDateFilter })
        }
      />
    </Flex>
  );
};

export { FilterBar };
