import { Box, Flex, Loader } from '@kandji-inc/nectar-ui';
import { SidePanel } from 'src/features/threat/common/components/SidePanel';
import { useGetVulnerability } from '../../hooks/use-get-vulnerability';
import useVulnerability from '../../store';
import { Body } from './body';
import { Footer } from './footer';
import { Header } from './header';

const VulnerabilitySidePanel = (props: {
  selectedVulnerabilityId: string;
  clearSelectedVulnerabilityId: () => void;
}) => {
  const { selectedVulnerabilityId, clearSelectedVulnerabilityId } = props;
  const setSelectedVulnerability = useVulnerability(
    (state) => state.setSelectedVulnerability,
  );

  const { data, isLoading } = useGetVulnerability(selectedVulnerabilityId);
  setSelectedVulnerability({
    cveInfo: data?.cve_info,
    devices: data?.devices,
    totalDevices: data?.total,
    devicePagination: { page: data?.page, size: data?.size },
  });

  const shouldShowVulnerability = !isLoading && data;

  const loader = (
    <Flex
      hFull
      wFull
      flow="column"
      alignItems="center"
      justifyContent="center"
      gap="sm"
      data-testid="loading-vulnerability"
    >
      <Loader size="lg" />
    </Flex>
  );

  const header = shouldShowVulnerability ? <Header /> : <Box />;

  const body = shouldShowVulnerability ? <Body /> : loader;

  const footer = shouldShowVulnerability ? <Footer /> : null;

  return (
    <SidePanel
      onClose={clearSelectedVulnerabilityId}
      header={header}
      body={body}
      /* TODO: reintroduce when there are more than 2 devices on a CVE */
      /* footer={footer} */
      css={{ containerCss: { zIndex: 2 } }}
    />
  );
};

export { VulnerabilitySidePanel };
