/* Adding tests after implementation */
/* istanbul ignore file */
import {
  ScrollContainer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  styled,
} from '@kandji-inc/nectar-ui';
import * as reactTable from '@tanstack/react-table';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { functionalUpdate } from '@tanstack/react-table';
import * as React from 'react';
import { Loader } from 'src/app/components/interface/Loader';
import PrismTableHeader from 'src/features/visibility/prism/components/PrismTable/components/PrismTableHeader';
import { useTableState } from 'src/features/visibility/prism/components/PrismTable/hooks/use-table-state';
import type { DeviceCounts } from '../hooks/useGetADEDeviceCount';
import { ADEListViewControls } from './ADEListViewControls';
import { ADEListViewPagination as Pagination } from './ADEListViewPagination';
import { type Device, getADETableColumns } from './ADEListViewTableColumns';
import { ADEListViewTableRow } from './ADEListViewTableRow';
import { ADEPaginationActions } from './ADEPaginationActions';
import {
  ADETableNoAwaitingDevices,
  ADETableNoDevices,
} from './ADETableNoDevices';
import { ADETableNoResults } from './ADETableNoResults';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  flex: 1,
  overflow: 'hidden',
});

const ADEListViewTableContainer = styled('div', {
  overflowX: 'scroll',
  flex: 1,
  paddingRight: '$1',
});

const ADEListViewPagination = styled('div', {
  borderTop: '1px solid $neutral20',
  display: 'flex',
  marginTop: 'auto',
  padding: '12px $5',
});

const LoadingContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$10',
});

export function ADETableSelectHeader({
  header,
}: {
  header: reactTable.Header<Device, unknown>;
}) {
  return (
    <Th title={header.id} key={header.id} css={{ width: header.getSize() }}>
      {reactTable.flexRender(
        header.column.columnDef.header,
        header.getContext(),
      )}
    </Th>
  );
}

export interface ADEListViewTableProps {
  isAllDevices: boolean;
  devices: Device[];
  tokens: string[];
  deviceCounts: DeviceCounts;
  lastSync: number;
  sync: (options: any) => Promise<any>;
  refetchDevices: (options: any) => Promise<any>;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<PaginationState>>;
  sorting: SortingState;
  sizePerPage: number;
  setSizePerPage: React.Dispatch<React.SetStateAction<number>>;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  hasNoADEDevices: boolean;
  hasNoAwaitingDevices: boolean;
  setIsAllDevices: (isAllDevices: boolean) => void;
}

export const ADEListViewTable = React.memo(function ADEListViewTable({
  isAllDevices,
  devices = [],
  tokens = [],
  deviceCounts = { all: 0, awaitingEnrollment: 0 },
  lastSync,
  sync,
  refetchDevices,
  isLoading,
  page,
  setPage,
  sizePerPage,
  setSizePerPage,
  sorting,
  setSorting,
  setSearch,
  hasNoADEDevices,
  hasNoAwaitingDevices,
  setIsAllDevices,
}: ADEListViewTableProps) {
  const adeTableColumns = React.useMemo(
    () => getADETableColumns(isAllDevices),
    [isAllDevices],
  );

  const deviceCount = isAllDevices
    ? deviceCounts.all
    : deviceCounts.awaitingEnrollment;

  const {
    columnFilters,
    columnVisibility,
    columnOrder,
    columnSizing,
    columnSizingInfo,
    isDefaultDirty,
    setColumnFilters,
    setColumnVisibility,
    setColumnOrder,
    setColumnSizing,
    setColumnSizingInfo,
    setIsDefaultDirty,
  } = useTableState(adeTableColumns, null);

  const table = reactTable.useReactTable({
    data: devices,
    columns: adeTableColumns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    manualFiltering: true,
    manualPagination: true,
    pageCount: Math.ceil(deviceCount / sizePerPage),
    getCoreRowModel: reactTable.getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onColumnSizingInfoChange: setColumnSizingInfo,
    onPaginationChange: setPage,
    onSortingChange: (updater) => {
      const newSorting = functionalUpdate(updater, sorting);
      setSorting(newSorting);
    },
    state: {
      columnFilters,
      columnVisibility,
      columnOrder,
      columnSizing,
      columnSizingInfo,
      pagination: { pageIndex: page, pageSize: sizePerPage },
      sorting,
    },
    meta: {
      isDefaultDirty,
      setIsDefaultDirty,
      frozenColumns: {
        selected: {},
      },
    },
  });

  const { rows } = table.getRowModel();

  const totalPages = Math.ceil(deviceCount / sizePerPage);

  if (!isLoading && !isAllDevices && hasNoAwaitingDevices) {
    return (
      <ADETableNoAwaitingDevices
        setIsAllDevices={setIsAllDevices}
        lastSync={lastSync}
        sync={sync}
        refetchDevices={refetchDevices}
      />
    );
  }

  if (!isLoading && isAllDevices && hasNoADEDevices) {
    return (
      <ADETableNoDevices
        lastSync={lastSync}
        sync={sync}
        refetchDevices={refetchDevices}
      />
    );
  }

  return (
    <Container>
      <ADEListViewControls
        table={table}
        columnOrder={columnOrder}
        columnVisibility={columnVisibility}
        isAllDevices={isAllDevices}
        sync={sync}
        refetchDevices={refetchDevices}
        lastSync={lastSync}
        setSearch={setSearch}
        tokens={tokens}
        sorting={sorting}
      />

      {isLoading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}

      {deviceCount < 1 && !isLoading && <ADETableNoResults />}

      {deviceCount >= 1 && !isLoading && (
        <ADEListViewTableContainer>
          <ScrollContainer showScrollShadowRight>
            <Table aria-label="Automatic Device Enrollment List View Table">
              <Thead data-pinned>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr
                    key={headerGroup.id}
                    css={{
                      'th:first-of-type': {
                        paddingLeft: '$5',
                      },
                    }}
                  >
                    {headerGroup.headers.map((header) => {
                      if (header.id === 'selected' && !isAllDevices) {
                        return <ADETableSelectHeader header={header} />;
                      }

                      return (
                        <PrismTableHeader
                          key={header.id}
                          header={header}
                          columnVisibility={columnVisibility}
                          columnOrder={columnOrder}
                          isResizingColumn={columnSizingInfo.isResizingColumn}
                          columnFilters={columnFilters}
                          selectedPrismCategory={undefined}
                          onSort={header.column.getToggleSortingHandler()}
                          sortState={header.column.getIsSorted() || 'none'}
                        />
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                <>
                  {rows.map((row) => (
                    <ADEListViewTableRow row={row} />
                  ))}
                </>
              </Tbody>
            </Table>
          </ScrollContainer>
        </ADEListViewTableContainer>
      )}

      {totalPages > 0 && !isLoading && (
        <ADEListViewPagination>
          <ADEPaginationActions
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            isAllDevices={isAllDevices}
          />
          <Pagination
            currentPage={page + 1}
            totalPages={totalPages}
            totalItems={deviceCount}
            itemsPerPage={sizePerPage}
            onPageChange={(p) => table.setPageIndex(p - 1)}
            isAllDevices={isAllDevices}
          />
        </ADEListViewPagination>
      )}
    </Container>
  );
});
