import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from '../../common/activity-tab';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import configs from '../../library/library-item-configurations/items/profile';
import { OldAppStoreStatusTab } from './OldAppStoreStatusTab';
import GeneralCard from './general-card';
import initialState from './initial-state';
import { appStoreService } from './service/app-store-service';
import { transformFromApi, transformToNewApi } from './service/transformers';
import useAppStoreService from './service/use-app-store-service';

const AppStorePage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel), []);

  const itemConfig = configs['App Store'];

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: itemConfig.name,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToNewApi}
      transformFromApi={transformFromApi}
      service={appStoreService}
      ActivityTab={ActivityTab}
      StatusTab={OldAppStoreStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={itemConfig.icon}
      supportsRules
      supportsDuplication
    >
      <GeneralCard
        setting={model.general}
        update={generalUpdate}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const AppStore = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useAppStoreService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AppStorePage {...pageProps} blueprintConflicts={blueprintConflicts} />
  );
};

export default AppStore;
