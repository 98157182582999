import { styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import NoContentMessage from 'src/features/integrations/components/integration-details/no-content-message';
import arrowUpRightSquareIcon from '../../../modals/assets/arrow-up-right-from-square.svg';

const LinkContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '$1',
  marginTop: '$3',
  justifyItems: 'center',
});

const NoContent = () => (
  <NoContentMessage
    header="Okta Verify is not synced to your Library"
    text="Sign in to your Apple administrative account to provision Okta Verify app licenses. Once Okta Verify is added to your Library, you can finish deploying this integration to your Apple device fleet."
  >
    <LinkContainer>
      <a
        href="https://business.apple.com/"
        rel="noopener noreferrer"
        target="_blank"
        className="b-alink b-text"
      >
        <img src={arrowUpRightSquareIcon} alt="up-arrow-right" />
        Apple Business Manager
      </a>

      <a
        href="https://school.apple.com/"
        rel="noopener noreferrer"
        target="_blank"
        className="b-alink b-text"
      >
        <img src={arrowUpRightSquareIcon} alt="up-arrow-right" />
        Apple School Manager
      </a>
    </LinkContainer>
  </NoContentMessage>
);

export default NoContent;
