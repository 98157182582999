import { Chip, Code, Flex, Td, Text, Tr, styled } from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { AccountContext } from 'contexts/account';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import constants from '../../../common/constants';
import isoToDateString from '../../../common/utils/isoToDateString';
import type { ThreatDetail } from '../../../threat.types';
import ThreatListTableArrowButton from './ThreatListTableArrowButton';

const StyledTr = styled(Tr, {
  marginTop: '-10px',
  '& td': {
    $$tdActiveBg: '$neutral0',
    verticalAlign: 'top',
  },
  '& td a': {
    color: '$blue50',
  },
});

const StyleCode = styled(Code, {
  borderRadius: '4px',
  border: '1px solid $neutral40',
  background: '$neutral5',
  color: '$neutral70',
  fontSize: '$1',
  lineHeight: '12px',
  padding: '0px 8px',
});

const TextPadded = styled(Text, {
  paddingLeft: 24,
});

const FlexLabels = styled(Flex, {
  span: { color: '$neutral70', lineHeight: '20px' },
});

const FlexValues = styled(Flex, {
  '& > span': { lineHeight: '20px', minHeight: '20px' },
});

const CapitalizedChip = styled(Chip, {
  '&:not(:disabled)': {
    textTransform: 'capitalize',
    backgroundColor: '$neutral0',
    border: '1px solid $neutral30',
    color: '$neutral60',
    padding: '1px 5px',
  },
});

const PostureFlex = styled(Flex, {
  width: 200,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const {
  THREAT_STATUS: { NOT_QUARANTINED, RELEASED, RESOLVED },
} = constants;

type ThreatListTableExpandedRowProps = {
  threat: ThreatDetail;
};

const ThreatListTableExpandedRow = (props: ThreatListTableExpandedRowProps) => {
  const { threat } = props;
  const { userSettings } = useContext(AccountContext);
  const {
    date_of_quarantine: dateOfQuarantine,
    date_of_release: dateOfRelease,
    released_by: releasedBy,
    release_note: releaseNote,
    resolved_at: resolvedAt,
    resolved_by: resolvedBy,
    blueprint_id: blueprintId,
    blueprint_name: blueprintName,
    library_item_id: libraryItemId,
    library_item_name: libraryItemName,
    malware_posture: malwarePosture,
    pup_posture: pupPosture,
    bundle_path: bundlePath,
    status,
    file_path: filePath,
    file_hash: fileHash,
    process_owner: processOwner,
    device_name: deviceName,
    device_serial_number: deviceSerialNumber,
    device_id: deviceId,
    detection_date: detectionDate,
  } = threat;

  const [isDeviceDetailsExpanded, setIsDeviceDetailsExpanded] =
    useState<boolean>(false);

  const handleExpandRowButtonClick = (isExpanded: boolean) => {
    setIsDeviceDetailsExpanded(isExpanded);
  };

  const isNotNotQuarantined = status !== NOT_QUARANTINED;
  const isReleased = status === RELEASED;
  const isResolved = status === RESOLVED;

  const dateOfQuarantineField = isNotNotQuarantined ? (
    <Text title={`${dateOfQuarantine}Z`}>
      {isoToDateString(dateOfQuarantine, userSettings?.timezone, true)}
    </Text>
  ) : null;

  const dateOfReleaseField = isReleased ? (
    <Text title={`${dateOfRelease}Z`}>
      {isoToDateString(dateOfRelease, userSettings?.timezone, true)}
    </Text>
  ) : null;

  const releasedByField = isReleased ? (
    <Text title={releasedBy}>{releasedBy}</Text>
  ) : null;

  const releaseNoteField =
    releaseNote && isReleased ? (
      <Text title={releaseNote}>{releaseNote}</Text>
    ) : null;

  const resolvedAtField = isResolved ? (
    <Text title={`${resolvedAt}Z`}>
      {isoToDateString(resolvedAt, userSettings?.timezone, true)}
    </Text>
  ) : null;

  const resolvedByField = isResolved ? (
    <Text title={resolvedBy}>{resolvedBy}</Text>
  ) : null;

  const bundlePathField = bundlePath ? (
    <Text title={bundlePath}>{bundlePath}</Text>
  ) : null;

  const deviceDetails = (
    <>
      <Text title={blueprintName || blueprintId}>
        {blueprintId ? (
          <Link to={`${links.blueprints}/${blueprintId}`}>
            {blueprintName || blueprintId}
          </Link>
        ) : (
          <Text variant="description">
            No Blueprint currently assigned to this device.
          </Text>
        )}
      </Text>

      <Text title={libraryItemName || libraryItemId}>
        {libraryItemId ? (
          <Link to={`/library/avert/${libraryItemId}`}>
            {libraryItemName || libraryItemId}
          </Link>
        ) : (
          <Text variant="description">
            No Library Item currently assigned to this device.
          </Text>
        )}
      </Text>

      {libraryItemId && malwarePosture && (
        <Text title="Malware Posture">
          <PostureFlex>
            Malware Posture &nbsp;
            <CapitalizedChip
              dismissible={false}
              size="compact"
              color="neutral"
              label={malwarePosture}
            />
          </PostureFlex>
        </Text>
      )}

      {libraryItemId && pupPosture && (
        <Text title="PUP Posture">
          <PostureFlex>
            PUP Posture &nbsp;
            <CapitalizedChip
              dismissible={false}
              size="compact"
              color="neutral"
              label={pupPosture}
            />
          </PostureFlex>
        </Text>
      )}
    </>
  );

  return (
    <StyledTr
      key={`exp${fileHash}${filePath}`}
      hoverBg="none"
      hoverAnchorUnderline={false}
    >
      <Td title="">&nbsp;</Td>

      <Td title="">
        <FlexLabels flow="column" gap="lg" pt1 pb3>
          <Text>Detected on</Text>
          {dateOfQuarantineField && <Text>Quarantined on</Text>}
          {dateOfReleaseField && <Text>Released on</Text>}
          {releasedByField && <Text>Released by</Text>}
          {releaseNoteField && <Text>Release note</Text>}
          {resolvedAtField && <Text>Resolved on</Text>}
          {resolvedByField && <Text>Resolved by</Text>}
          <Text>Path</Text>
          {bundlePathField && <Text>Bundle Path</Text>}
          <Text>Hash</Text>
          <Text>User</Text>
          <Text>
            <Flex alignItems="center" gap="sm">
              <ThreatListTableArrowButton
                compact
                expanded={isDeviceDetailsExpanded}
                onToggle={handleExpandRowButtonClick}
                testId="expand-device-details-button"
              />
              Device details
            </Flex>
          </Text>
          {isDeviceDetailsExpanded && (
            <>
              <TextPadded>Blueprint</TextPadded>
              <TextPadded>Avert Library Item</TextPadded>
            </>
          )}
        </FlexLabels>
      </Td>

      <Td title="" colSpan={5}>
        <FlexValues flow="column" gap="lg" pt1 pb3>
          <Text title={`${detectionDate}Z`}>
            {isoToDateString(detectionDate, userSettings?.timezone, true)}
          </Text>
          {dateOfQuarantineField}
          {dateOfReleaseField}
          {releasedByField}
          {releaseNoteField}
          {resolvedAtField}
          {resolvedByField}
          <Text title={filePath}>
            <StyleCode>{filePath}</StyleCode>
          </Text>
          {bundlePathField}
          <Text title={fileHash}>
            <StyleCode>{fileHash}</StyleCode>
          </Text>
          <Text title={processOwner}>{processOwner}</Text>
          <Text title={`${deviceName} - ${deviceSerialNumber}`}>
            <Link to={`${links.devices}/${deviceId}`}>{deviceName}</Link>
            {` - ${deviceSerialNumber}`}
          </Text>
          {isDeviceDetailsExpanded && deviceDetails}
        </FlexValues>
      </Td>

      <Td title="">&nbsp;</Td>
    </StyledTr>
  );
};

export default ThreatListTableExpandedRow;
