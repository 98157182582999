import {
  Select,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';
import EthernetService from '../../service/ethernet-service';
import { useShowCategories } from '../protocols-card.hooks';
import { identityCertificateOptions } from '../protocols-card.model';
import ConfigureAdcs from './configure-adcs';
import ConfigurePkcs from './configure-pkcs';
import ConfigureScep from './configure-scep';

const identityCertificateKey =
  EthernetService.keys.authentication.identityCertificate;

const IdentityCertificateRow = (props) => {
  const { setting, update, isDisabled, validationDep } = props;
  const { onInvalidate, invalidations, refs } = useInputsValidators(
    [identityCertificateKey],
    update,
  );
  useRemoveValidationOnUnmount([identityCertificateKey], update);

  const {
    showConfigureScep,
    showConfigurePkcs,
    showConfigureAdcs,
    adcsServers,
    canShowIdentityCertificateError,
  } = useShowCategories({
    validationDep,
    onInvalidate,
    setting,
  });

  return (
    <Setting.Row>
      <Setting.Title>
        <p ref={refs[identityCertificateKey]} className="b-txt">
          Identity certificate
        </p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          The identity certificate used to connect to the network.
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <Select
          disabled={isDisabled}
          options={identityCertificateOptions.filter(({ value }) => {
            const ff = {
              [EthernetService.identityCertificates.ADCS]:
                !!adcsServers?.length,
              [EthernetService.identityCertificates.SCEP]:
                featureFlags.getFlag('wi-fi-v-2-scep'),
            };
            if (value in ff) {
              return ff[value];
            }

            return true;
          })}
          compact
          onChange={(v) => update(identityCertificateKey, v.value)}
          value={identityCertificateOptions.find(
            (opt) => opt.value === setting[identityCertificateKey],
          )}
          searchPlaceholder="Search"
          testId="id-cert-row"
          errorText={
            (canShowIdentityCertificateError &&
              invalidations[identityCertificateKey]) ||
            ''
          }
        />
        {showConfigureAdcs && !!adcsServers?.length && (
          <ConfigureAdcs
            update={update}
            isDisabled={isDisabled}
            setting={setting}
            validationDep={validationDep}
            onInvalidate={onInvalidate}
            adcsServers={adcsServers}
          />
        )}
        {showConfigureScep && (
          <ConfigureScep
            update={update}
            isDisabled={isDisabled}
            setting={setting}
            validationDep={validationDep}
            onInvalidate={onInvalidate}
          />
        )}
        {showConfigurePkcs && (
          <ConfigurePkcs
            update={update}
            isDisabled={isDisabled}
            setting={setting}
            validationDep={validationDep}
            onInvalidate={onInvalidate}
          />
        )}
      </Setting.Controls>
    </Setting.Row>
  );
};

export default IdentityCertificateRow;
