import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';

const keys = WifiService.keys.trust;

const AllowTrustException = ({ setting, isDisabled, update }) => (
  <Setting.Row testId="trust-exception">
    <Setting.Controls>
      <Checkbox
        checked={setting[keys.allowTrustExceptions]}
        onChange={() => update(keys.allowTrustExceptions, (p) => !p)}
        disabled={isDisabled}
        label="Allow trust exceptions"
        testId="allow-trust-exception"
      />
    </Setting.Controls>
    <Setting.Helpers>
      <p className="b-txt-light">
        If the certificate is not already trusted, ask the user whether to trust
        it. Deprecated. Unsupported on iOS since iOS 8.0.
      </p>
    </Setting.Helpers>
  </Setting.Row>
);

export default AllowTrustException;
