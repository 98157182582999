import uuid from 'uuid';

import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SystemExtensionService extends NewLibraryItemService {
  static generateExtension() {
    return {
      Name: '',
      BundleId: '',
      canRemoveItself: false,
      id: uuid(),
    };
  }

  static generateTeamId() {
    return {
      AllowedExtensions: 'ALL',
      DisplayName: '',
      ExtensionTypes: {
        DriverExtension: true,
        EndpointSecurityExtension: true,
        NetworkExtension: true,
      },
      Extensions: [],
      TeamId: '',
      id: uuid(), // Only used for client-side UI, not sent to API
    };
  }

  static ALLOW_EXTENSIONS_OPTIONS = [
    {
      label: 'Allow all system extensions',
      value: 'ALL',
    },
    {
      label: 'Allow specific system extensions',
      value: 'SPECIFIC',
    },
    {
      label: 'Allow specific system extension types',
      value: 'SPECIFIC_TYPES',
    },
  ];

  static REMOVE_ITSELF_OPTIONS = [
    {
      label: 'No (Default)',
      value: false,
    },
    {
      label: 'Yes',
      value: true,
    },
  ];
}

export const systemExtensionService = new SystemExtensionService();

export default SystemExtensionService;
