import { INITIAL_STATE } from './constants';
import getFirstSlideTickets from './getFirstSlideTickets';

import ConnectionPendingSlide from './ConnectionPendingSlide/ConnectionPendingSlide';
import DownloadSlide from './DownloadSlide/DownloadSlide';
import FooterButtons from './FooterButtons';

export {
  getFirstSlideTickets as getTickets,
  DownloadSlide,
  FooterButtons,
  ConnectionPendingSlide,
  INITIAL_STATE,
};
