import oauthErrorIcon from 'features/integrations/components-for-specific-types/ms-teams/modals/assets/oauth-error-icon.svg';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router';
import { links } from 'src/app/common/constants';
import FooterButtons from '../../../components/footer-buttons';
import useModal from '../../../hooks/use-modal';
import { useModalState } from '../../../hooks/use-modal-state';
import msTeamsIcon from '../assets/ms-teams-icon.svg';

import { useMSTeamsAuth } from '../hooks';
import useCreateNotificationDetail from '../hooks/use-create-notification-detail';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const SUCCESS_MODAL_SLIDE_INDEX = 2;

const SuccessfulConnection = () => {
  const history = useHistory();

  const { nextModal, modalSlideIdx, closeModal } = useModal();
  const [isFailedState, setIsFailedState] = useState(false);
  const [isSuccessState, setIsSuccessState] = useState(false);
  const { createIntegration, loading: isIntegrationLoading } = useMSTeamsAuth();

  const {
    mutateAsync: mutateCreateNotification,
    isLoading: isNotificationLoading,
  } = useCreateNotificationDetail();
  const { setModalState } = useModalState();

  const isWorking = isIntegrationLoading || isNotificationLoading;

  const onClose = () => {
    // TODO: handle what happens when 'set this up later' is clicked
    closeModal();
    history.push(`${links.integrations.root}/ms_teams`);
  };

  const handleCreateIntegration = useCallback(() => {
    if (!localStorage.getItem('msteams-state')) {
      return;
    }

    createIntegration()
      .then((resp) => {
        setIsFailedState(false);
        setIsSuccessState(true);
        setModalState({
          integrationId: resp.data.id,
        });
      })
      .catch((err) => {
        if (err.response.status !== 201) {
          setIsFailedState(true);
        }
      });
  }, []);

  const handleNext = () => {
    if (isFailedState) {
      handleCreateIntegration();
    } else {
      mutateCreateNotification()
        .then((resp) => {
          setModalState({
            msTeamsNotificationId: resp?.data?.id,
          });
          nextModal();
        })
        .catch(() => {
          setIsFailedState(true);
        });
    }
  };

  const getText = () => {
    if (isFailedState) {
      return 'There was an unexpected error.';
    }

    return 'Microsoft Teams was successfully connected.';
  };

  const getSubText = () => {
    if (isFailedState) {
      return 'Microsoft Teams could not be set up. Please try again and make sure you are using an admin account to sign in.';
    }

    return (
      'Add and configure your first event notification, or choose to skip\n' +
      'this step and set them up later.'
    );
  };

  const getCancelButtonText = () => {
    if (isFailedState) {
      return 'Cancel';
    }

    return 'Set this up later';
  };

  const getNextButtonText = () => {
    if (isFailedState) {
      return 'Try again';
    }

    return 'Create event notification';
  };

  useEffect(() => {
    if (modalSlideIdx === SUCCESS_MODAL_SLIDE_INDEX) {
      handleCreateIntegration();
    }
  }, [handleCreateIntegration, modalSlideIdx]);

  return (
    <Container>
      <div
        className={
          isFailedState
            ? 'welcome-modal_first-slide oauth-failure'
            : 'welcome-modal_first-slide'
        }
      >
        {/* <img src={headerIcon} className="b-mb2" alt="header-icons" /> */}
        {isFailedState && (
          <img src={oauthErrorIcon} className="b-mb2" alt="header-icons" />
        )}
        {!isFailedState && isSuccessState && (
          <img src={msTeamsIcon} className="b-mb2" alt="header-icons" />
        )}
        <StyledSlideHeader>{getText()}</StyledSlideHeader>
        <p className="b-txt-light">{getSubText()}</p>
      </div>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        cancelButtonText={getCancelButtonText()}
        nextButtonText={getNextButtonText()}
        isWorking={isWorking}
      />
    </Container>
  );
};

export default SuccessfulConnection;
