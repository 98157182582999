import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { useEffect, useRef } from 'react';

const useSkipScreensValidation = (setting, update) => {
  const { isEnabled, canSkip, isSkipAll, screens, isSaving } = setting;
  const canShowValidation = useRef(false);
  const fieldsToValidate = ['screens'];

  const { refs, onInvalidate, invalidations, setInvalidations } =
    useInputsValidators(fieldsToValidate, update);

  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const resetValidation = () => {
    setInvalidations([false]);
    canShowValidation.current = false;
  };

  useEffect(() => {
    const selectedScreens = screens.filter((s) => s.isSelected);
    onInvalidate(0)(
      isEnabled && canSkip && !isSkipAll && !selectedScreens.length,
    );

    if (!canShowValidation.current && canSkip && !isSkipAll && isSaving) {
      canShowValidation.current = true;
    }

    if (!canSkip || isSkipAll) {
      resetValidation();
    }
  }, [canSkip, isSkipAll, screens, isSaving]);

  return {
    skipScreensRef: refs[0],
    isSkipScreenInvalid: invalidations[0] && canShowValidation.current,
  };
};

export { useSkipScreensValidation };
