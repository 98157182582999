import { TextInput, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

const LocationRow = ({ setting, update, isDisabled, isSubmitted }) => {
  const fieldsToValidate = ['location'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  const validator = (v) => [
    {
      message: 'Required',
      invalid: () => !v,
      trigger: ['onBlur', isSubmitted && 'onSubmit'],
    },
  ];
  useEffect(() => {
    const isInvalid = validator(setting.location)
      .map(({ invalid }) => invalid())
      .some((v) => v);

    if (isInvalid) {
      onInvalidate(0)(true);
    }
  }, []);

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">Location</p>
      </div>
      <Setting.Helpers className="b-txt-light">
        Specify location details for the printer that will be visible to users
        in the printer preferences.
      </Setting.Helpers>
      <div ref={refs[0]}>
        <TextInput
          value={setting.location}
          onChange={(e) => update('location', e.target.value)}
          disabled={isDisabled}
          placeholder="Third Floor"
          compact
          onInvalidate={onInvalidate(0)}
          validator={validator}
        />
      </div>
    </Setting.Row>
  );
};

export default LocationRow;
