import { Icon, setClass } from '@kandji-inc/bumblebee';
import Drawer from 'features/library-items/items/wifi/components/drawer';
import DrawerContent from 'features/library-items/items/wifi/protocols-card/identity-certificate-row/configure-pkcs/drawer-content';
import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import { useDrawerValidate } from 'features/library-items/items/wifi/wifi.hooks';
import { Setting } from 'features/library-items/template';
import moment from 'moment';
import React, { useState } from 'react';
import certSrc from './cert.png';
import './configure-pkcs.css';

const SpecifyPkcs = ({ file, hasError, openOptions, disabled, drawerRef }) => (
  <div
    style={{ display: 'flex', cursor: 'pointer' }}
    ref={drawerRef}
    data-testid="specify-pkcs"
  >
    <div
      className={setClass(
        'k-skip-screen-tags',
        hasError ? 'k-skip-screen-tags--error' : '',
      )}
      onClick={() => !disabled && openOptions()}
    >
      <div className="k-skip-screen-tags__main">
        {file && (
          <div className="pkcs-d__cert-container">
            <img
              src={certSrc}
              alt="certificate"
              className="pkcs-d__cert-image"
            />
            <div>
              <div
                className={setClass('b-txt-bold', disabled && 'b-txt-light')}
              >
                {' '}
                {file.name}{' '}
              </div>
              <div className="b-txt-ctrl6 pkcs-d__cert-date">
                uploaded {moment(file.uploadedAt).format('MM/DD/YYYY')}
              </div>
            </div>
          </div>
        )}
        {!file && (
          <p className="b-txt">
            <button
              type="button"
              className={setClass([
                hasError ? 'b-txt b-txt--error' : 'b-alink b-decorate-off',
                disabled && 'b-alink--disabled',
              ])}
              disabled={disabled}
            >
              Configure PKCS #12
            </button>
          </p>
        )}
      </div>
      <div className="k-skip-screen-tags__icon">
        <button
          type="button"
          className={setClass([
            'k-skip-screen-tags__icon-button',
            'b-alink',
            'b-decorate-off',
            disabled && 'b-alink--disabled',
          ])}
          disabled={disabled}
        >
          <Icon name="pencil" />
        </button>
      </div>
    </div>
  </div>
);

const externalFields = [
  ...Object.values(WifiService.keys.authentication.scep),
  ...Object.values(WifiService.keys.authentication.acceptedEapTypes),
  WifiService.keys.authentication.identityCertificate,
];

const fieldsToValidate = [
  WifiService.keys.authentication.pkcs.file,
  WifiService.keys.authentication.pkcs.password,
];

const ConfigurePkcs = ({ update, isDisabled, setting, validationDep }) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    onInvalidate,
    refs,
    invalidations,
    drawerRef,
    isValidationFailed,
    canShowDrawerError,
  } = useDrawerValidate({
    fieldsToValidate,
    isVisible,
    update,
    validationDep,
    drawerKey: WifiService.keys.authentication.pkcs.drawer,
  });

  return (
    <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
      <Setting.SecondaryControlsRow>
        <SpecifyPkcs
          file={setting[WifiService.keys.authentication.pkcs.file]}
          disabled={isDisabled}
          hasError={canShowDrawerError && isValidationFailed}
          openOptions={() => setIsVisible(true)}
          drawerRef={drawerRef}
        />
      </Setting.SecondaryControlsRow>
      <Drawer
        update={update}
        defaults={{}}
        setting={setting}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        validationDep={validationDep}
        onInvalidate={onInvalidate}
        DrawerContent={DrawerContent}
        title="Configure PKCS #12"
        externalFields={externalFields}
        refs={refs}
        invalidations={invalidations}
      />
    </Setting.SecondaryControls>
  );
};

export default React.memo(ConfigurePkcs);
