import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';

const SuccessBanner = () => (
  <Banner theme="success" icon="circle-check" kind="normal">
    <p>
      <strong>Kandji AD CS Connector</strong> was successfully downloaded.
    </p>
  </Banner>
);

export default SuccessBanner;
