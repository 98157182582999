import React from 'react';
import colors from '../../colors';
import './icon-chooser.css';

const IconDisplay = (props) => {
  const { icon, color, withBorder, onEdit, className, size } = props;

  return (
    <div
      data-testid="blueprint-icon"
      className={`bl-blueprints-ic__display${size ? `--${size}` : ''} ${
        className || ''
      } ${!withBorder ? '--no-border' : ''}`}
      style={{
        background: `linear-gradient(90deg, ${colors[color]} 50%, ${colors[color]}dd 50%)`,
      }}
      onClick={onEdit}
    >
      <i className={`ss-icon ${icon}`} />
    </div>
  );
};

IconDisplay.defaultProps = {
  withBorder: true,
};

export default IconDisplay;
