import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { useDispatch } from 'react-redux';

import get from 'lodash/get';
import { cancelPendingCommand } from 'src/app/components/ActivityTabNew/api';

import { getComputer, getComputerFileVaultPRK } from 'app/_actions/computer';
import { setModal } from 'app/_actions/ui';
import {
  blankPush,
  restartDevice,
  shutdownDevice,
} from 'app/components/computer/api';

const useActions = () => {
  const dispatch = useDispatch();
  const actions = {
    onEraseDevice: (computer) =>
      dispatch(
        setModal('COMPUTER_ERASE', {
          computerId: computer.id,
          computerName: computer.name,
          deviceFamily: computer.device_family,
          enrollmentType: computer.enrollment_type,
          osVersion: computer.os_version,
          getComputer,
        }),
      ),
    onEnableRemoteDesktop: (computer) =>
      dispatch(
        setModal('ENABLE_REMOTE_DESKTOP', {
          computerId: computer.id,
          computerName: computer.name,
        }),
      ),
    onDisableRemoteDesktop: (computer) =>
      dispatch(
        setModal('DISABLE_REMOTE_DESKTOP', {
          computerId: computer.id,
          computerName: computer.name,
        }),
      ),
    onLockDevice: (computer) =>
      dispatch(
        setModal('COMPUTER_LOCK', {
          computerId: computer.id,
          computerName: computer.name,
        }),
      ),
    onLockIosDevice: (computer) =>
      dispatch(
        setModal('COMPUTER_IOS_LOCK', {
          computerId: computer.id,
          deviceType: computer.device_family,
        }),
      ),
    onUnlockIosDevice: (computer) =>
      dispatch(
        setModal('COMPUTER_IOS_UNLOCK', {
          computerId: computer.id,
          deviceType: computer.device_family,
        }),
      ),
    onReinstallAgent: (computer) =>
      dispatch(
        setModal('COMPUTER_REINSTALL_AGENT', {
          computerId: computer.id,
        }),
      ),
    onEnableLostMode: (computer) =>
      dispatch(
        setModal('ENABLE_LOST_MODE', {
          computerId: computer.id,
        }),
      ),
    onCancelLostMode: (computer, commandId) =>
      cancelPendingCommand(computer.id)(commandId)
        .then(() => toaster('Command initiated'))
        .catch((err) => toaster('Something went wrong')),
    onDisableLostMode: (computer) => {
      dispatch(
        setModal('DISABLE_LOST_MODE', {
          computerId: computer.id,
        }),
      );
    },
    onRenewMDM: (computer) => {
      dispatch(
        setModal('COMPUTER_RENEW_MDM', {
          computerId: computer.id,
        }),
      );
    },
    onSetMDMDeviceName: (computer) =>
      dispatch(
        setModal('COMPUTER_SET_MDM_DEVICE_NAME', {
          computerId: computer.id,
          deviceFamily: computer.device_family,
        }),
      ),
    onSetAutoAdminPassword: (computer) =>
      dispatch(
        setModal('COMPUTER_SET_AUTO_ADMIN_PASSWORD', {
          computerId: computer.id,
          deviceFamily: computer.device_family,
        }),
      ),
    onUnlockUser: (computer) =>
      dispatch(
        setModal('COMPUTER_UNLOCK_USER', {
          computerId: computer.id,
        }),
      ),
    onDeleteUser: (computer) =>
      dispatch(
        setModal('COMPUTER_DELETE_USER', {
          computerId: computer.id,
          computerOSVersion: computer.os_version,
          computerDeviceFamily: computer.device_family,
        }),
      ),
    onSendBlankPush: (computer) =>
      blankPush(computer.id)
        .then(() => toaster('Command initiated'))
        .catch(() => toaster('Something went wrong')),
    onLogOutUser: (computer) =>
      dispatch(
        setModal('COMPUTER_LOG_OUT_IPAD_USER', {
          computerId: computer.id,
        }),
      ),
    onRestartDevice: (computer) =>
      restartDevice(computer.id)
        .then(() => toaster('Command initiated'))
        .catch(() => toaster('Something went wrong')),
    onShutdownDevice: (computer) =>
      shutdownDevice(computer.id)
        .then(() => toaster('Command initiated'))
        .catch(() => toaster('Something went wrong')),
    onDeleteComputer: (
      computer,
      isErased,
      isLocked,
      activationLockSupported,
      osVersion,
      deviceFamily,
      model,
    ) =>
      dispatch(
        setModal('COMPUTER_DELETE', {
          computers: [computer.id],
          computerId: computer.id,
          erased: isErased,
          locked: isLocked,
          activationLockSupported,
          osVersion,
          deviceFamily,
          model,
        }),
      ),
    onChangeBlueprint: (computer) =>
      dispatch(
        setModal('COMPUTER_CHANGE_BLUEPRINT', {
          computerId: computer.id,
          blackListOfBlueprint: [computer.blueprint_id],
        }),
      ),
    onShowRecoveryKey: (computer) =>
      getComputerFileVaultPRK(computer.id)
        .then((data) =>
          dispatch(
            setModal('FILE_VAULT_2_RECOVERY_KEY', {
              computerId: computer.id,
              recoveryKey: data.value,
              receivedAt: data.received_at,
            }),
          ),
        )
        .catch(() =>
          dispatch(
            setModal('FILE_VAULT_2_RECOVERY_KEY', {
              computerId: computer.id,
              recoveryKey: null,
              receivedAt: null,
            }),
          ),
        ),
    onShowBypassCode: (computer) =>
      dispatch(
        setModal('BYPASS_CODE', {
          computerId: computer.id,
          bypassCodeFailed: get(
            computer,
            'activation_lock.bypass_code_failed',
            false,
          ),
        }),
      ),
    onViewRecoveryLockPassword: (computer) =>
      dispatch(
        setModal('RECOVERY_LOCK_PASSWORD', {
          deviceId: computer.id,
        }),
      ),
  };

  return actions;
};

export default useActions;
