import {
  Badge,
  Button,
  Code,
  DropdownMenu,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  styled,
} from '@kandji-inc/nectar-ui';
import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import React, { useCallback, useState } from 'react';
import type {
  AllowBlockEvent,
  SortColumnDirection,
  SortColumnName,
} from '../../avert.types';
import { EventTypeLabel, EventTypeValue } from '../../avert.types';
import AvertService from '../../service/avert.service';

const Path = styled(Code, {
  display: 'inline-block',
  background: 'var(--b-primary-marengo-super-ultralight)',
  color: 'var(--b-system-slate-dark)',
  border: '1px solid var(--b-primary-marengo-ultralight)',
  paddingInline: '$2',
  maxWidth: 'max-content',
  wordBreak: 'break-all',
  fontSize: '$1',
});

const EllipsisButton = styled(Button, {
  padding: '3px',
  width: '36px',
  height: '36px',
});

const TableStyled = styled(Table, {
  borderBottom: '1px solid var(--color-neutral-20)',
  '& th, td': {
    boxShadow: 'var(--shadows-inset_border_bottom_1) var(--color-neutral-20)',
  },
  '& th:first-child, td:first-child': {
    paddingLeft: '$5',
  },
  '& th:last-child, td:last-child': {
    padding: 0,
    paddingRight: '$5',
  },
});

const getNextSortOrder = (
  direction: SortColumnDirection,
  column: SortColumnName,
  targetColumn: SortColumnName,
) => {
  const orders: SortColumnDirection[] = ['none', 'asc', 'desc'];
  // Sets the next in line sort order
  const nextDirection: SortColumnDirection =
    targetColumn === column
      ? orders[(orders.indexOf(direction) + 1) % orders.length]
      : 'asc';

  return nextDirection;
};

type SortColumn = {
  name: SortColumnName;
  direction: SortColumnDirection;
};

type AllowBlockListTableProps = {
  events: AllowBlockEvent[];
  isDisabled: boolean;
  onDeleteEvent: (id: string) => void;
  onEditEvent: (id: string) => void;
  onColumnSort: (
    column: SortColumnName,
    direction: SortColumnDirection,
  ) => void;
};

const AllowBlockListTable = (props: AllowBlockListTableProps) => {
  const { events, isDisabled, onDeleteEvent, onEditEvent, onColumnSort } =
    props;

  const [sortColumn, setSortColumn] = useState<SortColumn>({
    name: 'none',
    direction: 'none',
  });

  const findItemTypeLabel = (itemType: string) =>
    AvertService.itemTypeOptions.find(({ value }) => value === itemType)?.label;

  const actionsOptions = (id: string): DropdownMenuOption[] => [
    {
      label: 'Edit',
      icon: 'pencil',
      onClick: () => onEditEvent(id),
    },
    {
      label: 'Delete',
      icon: 'trash-can',
      theme: 'danger',
      onClick: () => onDeleteEvent(id),
    },
  ];

  const handleHeaderClick = useCallback(
    (column: SortColumnName) => () => {
      const nextDirection = getNextSortOrder(
        sortColumn.direction,
        sortColumn.name,
        column,
      );

      setSortColumn({ name: column, direction: nextDirection });
      onColumnSort(column, nextDirection);
    },
    [sortColumn, onColumnSort],
  );

  const applySort = (column: SortColumnName) => ({
    state: sortColumn.name === column ? sortColumn.direction : 'none',
    onSort: handleHeaderClick(column),
  });

  return (
    <TableStyled aria-label="Allow and Block items">
      <Thead>
        <Tr>
          <Th
            css={{ $$cellWidth: '$sizes-9' }}
            title="Name"
            sort={applySort('name')}
            showSortOnHover={false}
            data-testid="th-name"
          >
            Name
          </Th>
          <Th
            title="Type"
            css={{ $$cellWidth: '$sizes-6' }}
            sort={applySort('item_type')}
            showSortOnHover={false}
          >
            Type
          </Th>
          <Th
            title="Hasp/Path"
            sort={applySort('details')}
            showSortOnHover={false}
          >
            Hash/Path
          </Th>
          <Th
            title="Permission"
            css={{ $$cellWidth: '$sizes-7' }}
            sort={applySort('event_type')}
            showSortOnHover={false}
          >
            Permission
          </Th>
          <Th title="Actions" css={{ $$cellWidth: '$sizes-6' }}>
            &nbsp;
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {events.map((item) => {
          const isAllowed = item?.event_type === EventTypeValue.ALLOW;

          return (
            <Tr key={item?.id} hoverBg="none" css={{ $$cellActiveBg: 'none' }}>
              <Td title={item?.name} data-testid="td-name">
                {item?.name}
              </Td>
              <Td title={item?.item_type}>
                {findItemTypeLabel(item?.item_type)}
              </Td>
              <Td title={item?.details}>
                <Path>{item?.details}</Path>
              </Td>
              <Td css={{ $$cellTruncate: 'clip' }} title={item?.event_type}>
                <Badge color={isAllowed ? 'green' : 'red'}>
                  {isAllowed ? EventTypeLabel.ALLOW : EventTypeLabel.BLOCK}
                </Badge>
              </Td>
              <Td title="Actions" css={{ textAlign: 'right' }}>
                <DropdownMenu
                  withArrow={false}
                  contentProps={{ align: 'end' }}
                  options={actionsOptions(item?.id)}
                >
                  <EllipsisButton
                    variant="subtle"
                    compact
                    icon={{ name: 'ellipsis' }}
                    disabled={isDisabled}
                    data-testid="action-ellipsis"
                  />
                </DropdownMenu>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </TableStyled>
  );
};

export default AllowBlockListTable;
