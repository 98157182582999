import { TextField, styled } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';

import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Formik } from 'formik';
import FooterButtons from 'src/features/integrations/components/footer-buttons';
import {
  Container,
  StyledSlideHeader,
} from 'src/features/integrations/components/styled-components';
import FooterStatus from '../../../../../components/footer-status/footer-status';
import macOsOdt from '../../../assets/mac-os-icon.svg';
import AddMacOSErrorBanner from '../../../components/add-mac-os-error-banner';
import LabelTooltip from '../../../components/label-tooltip';
import {
  useGetOktaDeviceTrust,
  useUpdateOktaDeviceTrust,
} from '../../../hooks';
import footerIcon from '../../../modals/assets/okta-small.svg';
import type { FooterStatusState, ODTIntegration } from '../../../types';

type Props = {
  integrationData: ODTIntegration;
  onClose: () => void;
  isEditing?: boolean;
};

type MacFormValues = {
  scepUrl: string;
  challengeUrl: string;
  username: string;
  password: string;
};

const HeaderContainer = styled('div', {
  display: 'grid',
  gap: '$3',
  maxWidth: '640px',
  justifySelf: 'center',
  justifyContent: 'center',
  '& img': {
    justifySelf: 'center',
    width: '112px',
  },
});

const InputContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '$6',
});

const StyledTextInputContainer = styled('div', {
  width: '360px',
  textAlign: 'left',
});

const StyledInputGroup = styled('div', {
  [`& ${StyledTextInputContainer}:nth-child(2)`]: {
    marginTop: '32px',
  },
});

const ModalContent = styled('div', {
  display: 'grid',
  margin: 'auto',
  textAlign: 'center',
  justifyContent: 'center',
  [`${InputContainer}`]: {
    marginTop: '$4',
  },
});

const MacOsDevicePlatform = (props: Props) => {
  const { integrationData, onClose, isEditing } = props;

  const {
    mac_scep_challenge_url: macChallengeUrl,
    mac_scep_challenge_username: macScepUsername,
    mac_scep_url: macScepUrl,
  } = integrationData;

  const initialValues = {
    scepUrl: isEditing ? macScepUrl : '',
    challengeUrl: isEditing ? macChallengeUrl : '',
    username: isEditing ? macScepUsername : '',
    password: '',
  };

  const [footerStatus, setFooterStatus] = useState<FooterStatusState>();
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const { id: integrationId } = integrationData;

  const { updateOktaDeviceTrust, loading } = useUpdateOktaDeviceTrust();
  const { getOktaDeviceTrustDetails } = useGetOktaDeviceTrust();

  const headingCopy = isEditing
    ? 'Edit macOS device platform settings'
    : 'Add macOS as a device platform';

  const headingDescription = isEditing
    ? 'These settings are provided in Okta when adding or managing a device management provider.'
    : 'Provide the following information for macOS devices. Each field was generated in Okta when adding macOS as a device management provider.';

  const toastCopy = isEditing
    ? 'macOS setting updated successfully.'
    : 'macOS added as a device platform.';

  const handleValidate = ({
    scepUrl,
    challengeUrl,
    username,
    password,
  }: typeof initialValues) => ({
    ...(!scepUrl && { scepUrl: ['Required'] }),
    ...(!challengeUrl && { challengeUrl: ['Required'] }),
    ...(!username && { username: ['Required'] }),
    ...(!password && { password: ['Required'] }),
  });

  const handleSubmitData = async (values: MacFormValues) => {
    setFooterStatus('pending');
    try {
      await updateOktaDeviceTrust(
        {
          mac_scep_url: values.scepUrl,
          mac_scep_challenge_url: values.challengeUrl,
          mac_scep_challenge_username: values.username,
          mac_scep_challenge_password: values.password,
        },
        integrationId,
      );
      await getOktaDeviceTrustDetails();
      toaster(toastCopy);
      setFooterStatus('');
      onClose();
    } catch (e) {
      setShowErrorBanner(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={handleSubmitData}
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        errors,
        touched,
      }) => (
        <Container>
          <ModalContent>
            <HeaderContainer>
              <img src={macOsOdt} alt="header-icons" />
              <StyledSlideHeader>{headingCopy}</StyledSlideHeader>
              <p className="b-txt-light">{headingDescription}</p>
              <AddMacOSErrorBanner showErrorBanner={showErrorBanner} />
            </HeaderContainer>

            <InputContainer>
              <StyledInputGroup>
                <StyledTextInputContainer>
                  <LabelTooltip
                    label="SCEP URL"
                    tooltipMessage="The Simple Certificate Enrollment Protocol (SCEP) URL that was generated in Okta when adding macOS a device management provider."
                  />
                  <TextField
                    name="scepUrl"
                    value={values.scepUrl}
                    placeholder="Enter the base URL for the SCEP server"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={250}
                    hint={{
                      label:
                        touched.scepUrl && errors?.scepUrl
                          ? errors.scepUrl
                          : '',
                    }}
                    state={
                      touched.scepUrl && errors?.scepUrl ? 'error' : undefined
                    }
                  />
                </StyledTextInputContainer>
                <StyledTextInputContainer>
                  <LabelTooltip
                    label="Challenge URL"
                    tooltipMessage="The Challenge URL that was generated in Okta when adding macOS a device management provider."
                  />
                  <TextField
                    name="challengeUrl"
                    value={values.challengeUrl}
                    placeholder="Enter the URL to retrieve the SCEP challenge"
                    maxLength={250}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hint={{
                      label:
                        touched.challengeUrl && errors.challengeUrl
                          ? errors.challengeUrl
                          : '',
                    }}
                    state={
                      touched.challengeUrl && errors.challengeUrl
                        ? 'error'
                        : undefined
                    }
                  />
                </StyledTextInputContainer>
              </StyledInputGroup>
              <StyledInputGroup>
                <StyledTextInputContainer>
                  <label>Username</label>
                  <TextField
                    name="username"
                    value={values.username}
                    placeholder="Enter the Okta username"
                    maxLength={25}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hint={{
                      label:
                        touched.username && errors.username
                          ? errors.username
                          : '',
                    }}
                    state={
                      touched.username && errors.username ? 'error' : undefined
                    }
                  />
                </StyledTextInputContainer>
                <StyledTextInputContainer>
                  <label>Password</label>
                  <TextField
                    name="password"
                    type="password"
                    value={values.password}
                    placeholder="Enter the password"
                    maxLength={50}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hint={{
                      label:
                        touched.password && errors.password
                          ? errors.password
                          : '',
                    }}
                    state={
                      touched.password && errors.password ? 'error' : undefined
                    }
                  />
                </StyledTextInputContainer>
              </StyledInputGroup>
            </InputContainer>
          </ModalContent>
          <FooterButtons
            nextButtonText="Save"
            handleNext={handleSubmit}
            onClose={onClose}
            nextButtonDisabled={!!Object.keys(errors).length}
            isWorking={loading}
            customComponent={
              footerStatus ? (
                <FooterStatus
                  icon={footerIcon}
                  status={footerStatus}
                  options={{
                    pending: {
                      title: 'Checking the macOS connection...',
                      statusText: 'In progress',
                    },
                  }}
                />
              ) : undefined
            }
          />
        </Container>
      )}
    </Formik>
  );
};

export default MacOsDevicePlatform;
