import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useContext } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import LibraryContext from 'features/library-items/routes/library.context';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import initial from './initial-state';
import LoginWindow from './sections/login-window';
import Users from './sections/users';
import { screenSaverService } from './service/screen-saver-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useScreenSaverService from './service/use-screen-saver-service';

const ScreenSaverPage = (props) => {
  const { model, savedModel, setModel, pageState } = props;
  const { itemConfig } = useContext(LibraryContext);
  const [validationDep, triggerValidation] = useUniqValue();
  const updateUsers = useCallback(update('users', setModel));
  const updateLoginWindow = useCallback(update('loginWindow', setModel));

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={screenSaverService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <Users
        update={updateUsers}
        setting={model.users}
        beforeEditModel={savedModel.users}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        triggerValidation={triggerValidation}
      />
      <LoginWindow
        update={updateLoginWindow}
        setting={model.loginWindow}
        beforeEditModel={savedModel.loginWindow}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        triggerValidation={triggerValidation}
      />
    </LibraryItemPage>
  );
};

const ScreenSaver = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useScreenSaverService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <ScreenSaverPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="screen-saver-v2"
    />
  );
};

export default ScreenSaver;
