import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

import EthernetService from '../service/ethernet-service';
import ProxyType from './proxy-type';

const keys = EthernetService.keys.proxy;

const ProxyCard = ({
  setting,
  update,
  isDisabled,
  validationDep,
  isSubmitted,
}) => {
  const showRows = !!setting[keys.isEnabled];
  useEffect(() => {
    // when disabled card remove validations
    // not the best way to do it
    if (!setting[keys.isEnabled] || !setting.invalidationsMap) {
      update('invalidationsMap', {});
    }
  }, [setting[keys.isEnabled], isSubmitted]);

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">Proxy</h3>
        <Setting.HeaderToggle
          isEnabled={setting[keys.isEnabled]}
          onChange={() => update(keys.isEnabled, (p) => !p)}
          isDisabled={isDisabled}
          chipText={{
            enabled: 'Managed',
            disabled: 'Not managed',
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">Configure network proxy settings. </p>
      </Setting.SubHeader>
      {showRows && (
        <Setting.Rows>
          <ProxyType
            isDisabled={isDisabled}
            setting={setting}
            update={update}
            validationDep={validationDep}
          />
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default React.memo(ProxyCard);
