import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import EmptyBeehive from 'features/blueprints/assets/empty-beehive.svg';
import { transformParamsToAPI } from 'features/blueprints/blueprint/api/transformers';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { usePermissions } from 'src/contexts/account';
import Loader from '../../../../theme/components/atoms/Loader';
import { blueprintService } from '../../../library-items/data-service/blueprint/blueprint-service';
import { paths } from '../../common';
import BlueprintActions from '../actions';
import BlueprintContext from '../blueprint.context';
import Filter from '../filter';
import BlueprintParametersList from './blueprint-parameters-list';
import './blueprint-parameters.css';
import { BP_PARAMS_VALIDATION_FIELDS } from './parameter-details';
import useSyncValidations from './use-sync-validations';

const paramsFieldsToValidate = Object.keys(BP_PARAMS_VALIDATION_FIELDS);

const BlueprintParameters = () => {
  const {
    blueprint,
    getBlueprintData,
    setActiveCategory,
    setCurrentFilteredItems,
  } = useContext(BlueprintContext);
  const history = useHistory();
  const [blueprintModel, setBlueprintModel] = useState({
    ...deepcopy(blueprint),
    beforeEditEnabledItems: deepcopy(blueprint.params),
  });
  const [currentFilter, setCurrentFilter] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [focusedParameter, setFocusedParameter] = useState();
  const [currentFiltered, setCurrentFiltered] = useState();
  const permissions = usePermissions();

  const { validations, validationRootRef, createValidationTrigger } =
    useSyncValidations({
      namespace: 'blueprint-params',
      fieldsToValidate: paramsFieldsToValidate,
      triggerOn: (isSubmittedUpdate) => Boolean(isSubmittedUpdate),
      displayOn: () => Boolean(isSubmitted),
    });
  const setIsSubmittedWithValidation = createValidationTrigger(setIsSubmitted);

  useEffect(() => {
    if (blueprint) {
      setBlueprintModel({
        ...deepcopy(blueprint),
        beforeEditEnabledItems: deepcopy(blueprint.params),
      });
    }
  }, [blueprint]);

  const update =
    (id) =>
    (obj, del = []) =>
      setBlueprintModel((prev) => {
        if (obj) {
          prev.params[id] = {
            ...prev.params[id],
            ...obj,
          };
        }
        del.forEach((k) => delete prev.params[id][k]);
        return {
          ...prev,
          params: { ...prev.params },
        };
      });

  const onToggle = (
    param,
    isEnabled,
    isMuted = param.default?.is_mute || false,
  ) => {
    setBlueprintModel((prev) => {
      if (!isEnabled) {
        Object.keys(prev.params).forEach((id) => {
          const p = prev.params[id].parameter;
          if (p.dependency?.id === param.id) {
            delete prev.params[id];
          }
        });
        delete prev.params[param.id];
      } else {
        prev.params[param.id] = {
          ...(prev.params[param.id] || {}),
          parameter: param,
          daily: param.default?.is_daily,
          mute: isMuted,
        };
      }
      return {
        ...prev,
        params: { ...prev.params },
      };
    });
  };

  const afterAction = (
    isEditing,
    isEnabled,
    revertToBeforeEdit,
    scrollToTopContainer,
  ) => {
    setIsEditing(isEditing);
    setCurrentFilter((prev) => ({ ...prev, enabledOnly: isEnabled }));

    if (revertToBeforeEdit) {
      setBlueprintModel({
        ...deepcopy(blueprint),
        beforeEditEnabledItems: deepcopy(blueprint.params),
      });
    }

    if (scrollToTopContainer) {
      setTimeout(() => {
        document
          .querySelector('.bl-blueprint-page__main')
          ?.scrollTo({ top: 0, behavior: 'smooth', block: 'start' });
      }, 100);
    }
  };

  const onSave = () => {
    setIsSubmittedWithValidation(true);
    if (
      Object.values(blueprintModel.params).filter(({ isInvalid }) => isInvalid)
        .length
    ) {
      return toaster('Cannot save blueprint, errors exist.');
    }
    const paramsToSend = transformParamsToAPI(blueprintModel);
    setIsSaving(true);

    blueprintService
      .patch(blueprintModel.id, { params: paramsToSend })
      .then(() => {
        toaster('Successfully saved Blueprint.');
        return getBlueprintData(blueprint.id)
          .then(() => afterAction(false, true, false, true))
          .catch(() =>
            toaster('An error occurred retrieving the updated Blueprint.'),
          )
          .finally(() => {
            setIsSaving(false);
            setIsSubmittedWithValidation(false);
          });
      })
      .catch(() => {
        toaster('An error occurred saving Blueprint Parameters.');
        setIsSaving(false);
        setIsSubmittedWithValidation(false);
      });
  };

  useEffect(() => {
    if (currentFiltered) {
      setCurrentFilteredItems(currentFiltered);
    }
  }, [currentFiltered]);

  const BlueprintParamList = useMemo(() => {
    const filtered = {
      ...blueprintModel,
      parameters: blueprintModel.parameters
        .map((p) => {
          const pFiltered = {
            ...p,
            subcategories: p.subcategories
              .map((pSub) => {
                const pSubFiltered = {
                  ...pSub,
                  parameters: pSub.parameters.filter(
                    ({ name, id, tags }) =>
                      (!currentFilter.searchTerm ||
                        name
                          .toLowerCase()
                          .includes(currentFilter.searchTerm.toLowerCase())) &&
                      (!currentFilter.enabledOnly ||
                        id in blueprintModel.beforeEditEnabledItems ||
                        id in blueprintModel.params) &&
                      !!tags.find(
                        ({ id }) =>
                          currentFilter.isAllFrameworks ||
                          currentFilter.frameworks?.includes(id),
                      ) &&
                      !!tags.find(
                        ({ id }) =>
                          currentFilter.isAllCompatibility ||
                          currentFilter.compatibility?.includes(id),
                      ),
                  ),
                };
                return pSubFiltered;
              })
              .filter(({ parameters }) => parameters.length),
          };

          return pFiltered;
        })
        .filter(({ subcategories }) => subcategories.length),
    };

    setCurrentFiltered(filtered);

    if (
      !filtered.parameters.length &&
      blueprintModel.parameters &&
      !Object.keys(currentFilter).length
    ) {
      return <Loader type={Loader.types.LINE} />;
    }

    if (
      !isEditing &&
      !Object.keys(blueprintModel.params).length &&
      currentFilter.enabledOnly
    ) {
      return (
        <div className="bl-blueprint-parameter-item b-flex-vg bl-blueprint-parameters__enable">
          <h2 className="b-h2">No Parameters enabled</h2>
          {permissions.canManageBlueprints && (
            <Button onClick={() => afterAction(true, false)}>
              Enable Parameters
            </Button>
          )}
        </div>
      );
    }

    if (!filtered.parameters.length) {
      return (
        <div className="bl-blueprint-parameters__empty-filter">
          <ReactSVG className="b-mb" src={EmptyBeehive} />
          <h3 className="b-h3">No results found</h3>
          <p className="b-txt">
            Try changing the filter or search with different keywords.
          </p>
        </div>
      );
    }

    return (
      <BlueprintParametersList
        blueprint={filtered}
        isEditing={isEditing}
        filter={currentFilter}
        update={update}
        onToggle={onToggle}
        onActiveCategory={(a) => {
          setActiveCategory(a);
          setFocusedParameter(a?.subCategory); // Not being used yet, but this is for when we want to 'edit' and keep the viewport on a Parameter.
        }}
        validations={validations}
      />
    );
  }, [
    blueprintModel.params,
    blueprintModel.beforeEditEnabledItems,
    blueprintModel.parameters,
    currentFilter,
    isEditing,
    validations,
  ]);

  return (
    <div className="bl-blueprint-parameters" ref={validationRootRef}>
      <h1 className="b-h1 b-mt3 b-mb3">Blueprint Parameters</h1>
      <Filter
        className="b-mb2"
        searchFor="Parameters"
        filter={currentFilter}
        frameworks={blueprint.parameterTags.filter(
          ({ kind }) => kind === 'framework',
        )}
        compatibility={blueprint.parameterTags.filter(
          ({ kind }) => kind === 'compatibility',
        )}
        onFilter={setCurrentFilter}
      />
      {BlueprintParamList}
      <BlueprintActions
        isValid={Object.values(blueprintModel.params).every(
          ({ isInvalid }) => !isInvalid,
        )}
        isEditable={permissions.canManageBlueprints}
        isEditing={isEditing}
        isSaving={isSaving}
        onEdit={() => {
          afterAction(true, false);
          /* Put this in below if anyone complains about onEdit not scrolling to
          element */
          // setTimeout(() => {
          //   elementScrollIntoView(
          //     document.querySelector(`[id='${focusedParameter}']`),
          //     {
          //       behavior: 'smooth',
          //       block: 'center',
          //     },
          //   );
          // }, 500);
        }}
        onCancel={() => afterAction(false, true, true, true)}
        onClose={() => history.push(paths.root)}
        onSave={onSave}
        actionsFor="Parameters"
        isTextEnable={!Object.keys(blueprintModel?.params || {}).length}
      />
    </div>
  );
};

export default BlueprintParameters;
