import React from 'react';
import EmptyAppsImg from '../../../assets/empty-apps-img.png';

const EmptyResults = () => (
  <div className="k-ss2-drawer-setting-extra k-wifiv2-protocols-pkcs-drawer__row k-wifiv2-fastlane-drawer__search-empty">
    <div className="k-wifiv2-fastlane-drawer__search-empty__img">
      <img src={EmptyAppsImg} alt="Empty apps" />
    </div>
    <div className="k-wifiv2-fastlane-drawer__search-empty__title">
      <p className="b-txt-bold">No results found</p>
    </div>
    <div className="k-wifiv2-fastlane-drawer__search-empty__text">
      <p className="b-txt">
        We couldn’t find a match. Try changing the search or to add a Bundle ID
        manually
      </p>
    </div>
  </div>
);

export default EmptyResults;
