import {
  LogoUploader,
  TextInput,
  Toggle,
  useInvalidations,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useEffect } from 'react';
import useRefs from '../../use-refs';
import { useCustomOrDefaultValue } from '../hooks';
import { mapInvalidIndexToField } from '../input-validations';

const Branding = (props) => {
  const {
    update,
    defaults,
    setting,
    needReset,
    triggerValidate,
    disableCustomIcon,
  } = props;
  const { iconS3Key, iconUrl, iconFile, displayName } = setting;
  const refs = useRefs(2);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 2 });
  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    ['brandingIcon', 'brandingName'],
    refs,
  );

  const [isDefaultName, setIsDefaultName] = useCustomOrDefaultValue(
    defaults.name,
    displayName,
    (v) => update('displayName', v),
    needReset,
  );

  const [isDefaultIcon, setIsDefaultIcon] = useCustomOrDefaultValue(
    null,
    iconUrl,
    (v) => update('iconUrl', v),
    needReset,
    { getIsDefault: (v) => !v },
  );

  // src/features/library-items/items/wifi/service/transformers/transformFromApi/self-service/index.js
  // check this comment why did I do this
  useEffect(() => {
    update('useDefaultIcon', isDefaultIcon);
  }, [isDefaultIcon]);

  useEffect(() => {
    const hasIcon = iconUrl || iconFile;
    onInvalidate(0)(!isDefaultIcon && !hasIcon);
  }, [isDefaultIcon, iconUrl, iconFile]);

  useEffect(() => {
    onInvalidate(1)(!isDefaultName && !displayName);
  }, [isDefaultName]);

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  const requiredFileValidator = (file) => {
    if (!file && !iconUrl) {
      return 'Upload a custom icon or turn the toggle off to use the default icon.';
    }
    return null;
  };

  return (
    <div className="k-ss2-drawer-section --branding">
      <h3 className="b-h3">Branding</h3>
      {!disableCustomIcon && (
        <div className="k-ss2-drawer-setting">
          <div className="k-ss2-drawer-setting-with-toggle">
            <div>
              <p className="b-txt">Customize icon</p>
              <p className="b-txt-light">
                Override the default icon displayed in Self Service.
              </p>
            </div>
            <div>
              <Toggle
                checked={!isDefaultIcon}
                onToggle={() => {
                  setIsDefaultIcon(!isDefaultIcon);
                  if (!isDefaultIcon) {
                    update('iconUrl', null);
                    update('iconS3Key', '');
                    update('iconFile', null);
                  }
                }}
              />
            </div>
          </div>
          {!isDefaultIcon && (
            <div className="b-flex-col b-mt2">
              <p className="b-txt">Icon</p>
              <p className="b-txt-light">
                A 128x128 pixel PNG file with transparent background is
                recommended.
              </p>
            </div>
          )}
          {!isDefaultIcon && (
            <div ref={refs[0]} className="k-ss2-drawer-setting-extra1">
              <LogoUploader
                className="k-ss2-icon-selector"
                smallPreview
                icon={iconUrl || iconFile || defaults.icon}
                onRemove={() => {
                  update('iconUrl', null);
                  update('iconS3Key', '');
                  update('iconFile', null);
                }}
                onImage={(img) => {
                  update('iconUrl', null);
                  update('iconS3Key', '');
                  update('iconFile', img);
                }}
                canRemove={(iconS3Key && iconUrl) || iconFile}
                validators={[requiredFileValidator]}
                runValidatorsOn={[triggerValidate, needReset]}
                resetDep={setting.resetSelfServiceIcon}
              />
            </div>
          )}
        </div>
      )}
      <div className="k-ss2-drawer-setting">
        <div className="k-ss2-drawer-setting-with-toggle">
          <div>
            <p className="b-txt">Customize name</p>
            <p className="b-txt-light">
              Override the default Library Item name displayed in Self Service.
            </p>
          </div>
          <div>
            <Toggle
              checked={!isDefaultName}
              onToggle={() => setIsDefaultName(!isDefaultName)}
            />
          </div>
        </div>
        {!isDefaultName && (
          <div ref={refs[1]} className="k-ss2-drawer-setting-extra">
            <TextInput
              placeholder={defaults.name}
              maxLength={30}
              fieldsGrid="k-ss2-input-grid"
              value={displayName}
              validator={(v) => [
                {
                  message: 'Add a custom name or turn the toggle off.',
                  invalid: () => !isDefaultName && !v?.length,
                  trigger: ['onBlur', triggerValidate],
                },
              ]}
              onInvalidate={onInvalidate(1)}
              onChange={(e) => update('displayName', e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Branding;
