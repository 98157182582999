import featureFlags from 'src/config/feature-flags';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import config from '../../../library/library-item-configurations/items';

const transformFromOldApi = async (apiData, preset) => {
  const settings = apiData.settings;

  const result = {
    ...preset,
    isActive: apiData.is_active,
    menuBar: {
      showInputMenu: settings.showInputMenu,
      AdminHostInfo: settings.AdminHostInfo,
    },

    options: {
      SleepDisabled: settings.SleepDisabled,
      RestartDisabled: settings.RestartDisabled,
      ShutDownDisabled: settings.ShutDownDisabled,
      DisableFDEAutoLogin: settings.DisableFDEAutoLogin,
      'com.apple.login.mcx.DisableAutoLoginClient':
        settings['com.apple.login.mcx.DisableAutoLoginClient'],
      RetriesUntilHint: settings.RetriesUntilHint,
    },

    loggedInUsers: {
      MultipleSessionEnabled: settings.MultipleSessionEnabled,
      RestartDisabledWhileLoggedIn: settings.RestartDisabledWhileLoggedIn,
      ShutDownDisabledWhileLoggedIn: settings.ShutDownDisabledWhileLoggedIn,
      DisableScreenLockImmediate: settings.DisableScreenLockImmediate,
      LogOutDisabledWhileLoggedIn: settings.LogOutDisabledWhileLoggedIn,
      'com.apple.autologout.AutoLogOutDelay':
        settings['com.apple.autologout.AutoLogOutDelay'],
    },

    lockMessage: {
      LoginwindowText: settings.LoginwindowText,
    },

    userVisibility: {
      SHOWFULLNAME: settings.SHOWFULLNAME,
      HideAdminUsers: settings.HideAdminUsers,
      HideLocalUsers: settings.HideLocalUsers,
      SHOWOTHERUSERS_MANAGED: settings.SHOWOTHERUSERS_MANAGED,
      HiddenUsersList: settings.HiddenUsersList,
    },
  };

  return result;
};

async function transformFromNewApi(apiData, preset) {
  const data = apiData.data;
  const result = {
    ...preset,
    menuBar: {
      showInputMenu: data.showInputMenu,
      AdminHostInfo: data.AdminHostInfo,
    },

    options: {
      SleepDisabled: data.SleepDisabled,
      RestartDisabled: data.RestartDisabled,
      ShutDownDisabled: data.ShutDownDisabled,
      DisableFDEAutoLogin: data.DisableFDEAutoLogin,
      'com.apple.login.mcx.DisableAutoLoginClient':
        data['com.apple.login.mcx.DisableAutoLoginClient'],
      RetriesUntilHint: data.RetriesUntilHint,
    },

    loggedInUsers: {
      MultipleSessionEnabled: data.MultipleSessionEnabled,
      RestartDisabledWhileLoggedIn: data.RestartDisabledWhileLoggedIn,
      ShutDownDisabledWhileLoggedIn: data.ShutDownDisabledWhileLoggedIn,
      DisableScreenLockImmediate: data.DisableScreenLockImmediate,
      LogOutDisabledWhileLoggedIn: data.LogOutDisabledWhileLoggedIn,
      'com.apple.autologout.AutoLogOutDelay':
        data['com.apple.autologout.AutoLogOutDelay'],
    },

    lockMessage: {
      LoginwindowText: data.LoginwindowText,
    },

    userVisibility: {
      SHOWFULLNAME: data.SHOWFULLNAME,
      HideAdminUsers: data.HideAdminUsers,
      HideLocalUsers: data.HideLocalUsers,
      SHOWOTHERUSERS_MANAGED: data.SHOWOTHERUSERS_MANAGED,
      HiddenUsersList: data.HiddenUsersList,
    },
    isActive: apiData.active,

    ...(featureFlags.getFlag('lit_08042023_login-window-auto-login') && {
      autoLogin: {
        AutologinIsManaged: data.AutologinIsManaged,
        AutologinUsername: data.AutologinUsername,
        AutologinPassword: data.AutologinPassword,
        invalidationsMap: {},
      },
    }),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

const transformToOldApi = (model) => {
  const withoutInvalidations = (o) => {
    return {
      ...Object.keys(o)
        .filter((k) => k !== 'invalidationsMap')
        .reduce((a, c) => ({ ...a, [c]: o[c] }), {}),
    };
  };
  const itemConfig = config['Login Window'];
  const result = {
    name: model.name,
    is_active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    settings: {
      ...withoutInvalidations(model.menuBar),
      ...withoutInvalidations(model.options),
      ...withoutInvalidations(model.loggedInUsers),
      ...withoutInvalidations(model.lockMessage),
      ...withoutInvalidations(model.userVisibility),
      ...(featureFlags.getFlag('lit_08042023_login-window-auto-login')
        ? model.autoLogin.AutologinIsManaged
          ? withoutInvalidations(model.autoLogin)
          : {
              AutologinIsManaged: false,
              AutologinUsername: null,
              AutologinPassword: null,
            }
        : {}),
    },
  };

  if (!model.id) {
    result.identifier = itemConfig.identifier;
    result.type = itemConfig.type;
  }

  return result;
};

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);
  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  return toSend;
}

function transformToApi(model) {
  const transformer = transformToNewApi;

  return transformer(model);
}

export { transformToApi, transformFromApi };
