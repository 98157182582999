import {
  Button,
  Checkbox,
  Radio,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const UserVisibilitySection = (props) => {
  const { isDisabled, update, setting, validationDep } = props;
  const MAX_USERS = 20;

  const validationKeys = new Array(MAX_USERS).fill(0).map((_, idx) => idx);
  const { refs, onInvalidate } = useInputsValidators(validationKeys, update);
  useRemoveValidationOnUnmount(validationKeys, update);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">User visibility</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.SHOWFULLNAME.checked}
              label="Manage user visibility"
              onChange={() =>
                update('SHOWFULLNAME', (p) => ({ ...p, checked: !p.checked }))
              }
              testId="show-full-name"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Display individual user icons at the login window, or display the
            username and password fields.
          </Setting.Helpers>

          {setting.SHOWFULLNAME.checked && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls
                  className="b-flex-vg1"
                  style={{ alignItems: 'flex-start' }}
                >
                  <Radio
                    isDisabled={isDisabled}
                    checked={!setting.SHOWFULLNAME.value}
                    label="Display a list of users"
                    onChange={() =>
                      update('SHOWFULLNAME', (p) => ({ ...p, value: !p.value }))
                    }
                    name="display-list-users"
                  />
                  <Radio
                    isDisabled={isDisabled}
                    checked={setting.SHOWFULLNAME.value}
                    label="Display username and password fields"
                    onChange={() =>
                      update('SHOWFULLNAME', (p) => ({
                        ...p,
                        value: !p.value,
                      }))
                    }
                    name="display-user-pass"
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        {setting.SHOWFULLNAME.checked && !setting.SHOWFULLNAME.value && (
          <>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  isDisabled={isDisabled}
                  checked={setting.HideAdminUsers}
                  label="Hide administrators"
                  onChange={() => update('HideAdminUsers', (p) => !p)}
                  testId="hide-admin"
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                Disable local administrator icons at the login window, and in
                the fast user switching menu.
              </Setting.Helpers>
            </Setting.Row>

            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  isDisabled={isDisabled}
                  checked={setting.HideLocalUsers}
                  label="Hide local users"
                  onChange={() => update('HideLocalUsers', (p) => !p)}
                  testId="hide-local"
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                Disable local user icons at the login window, and in the fast
                user switching menu.
              </Setting.Helpers>
            </Setting.Row>

            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  isDisabled={isDisabled}
                  checked={setting.SHOWOTHERUSERS_MANAGED}
                  label={`Hide Other button`}
                  onChange={() => update('SHOWOTHERUSERS_MANAGED', (p) => !p)}
                  testId="hide-other"
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                Disable the Other button at the login window, which allows
                additional users to log in. Note that the login window must
                display at least one user icon, otherwise the Other button will
                be displayed regardless of this setting.
              </Setting.Helpers>
            </Setting.Row>

            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  isDisabled={isDisabled}
                  checked={setting.HiddenUsersList.checked}
                  label="Hide specific users"
                  onChange={() =>
                    update('HiddenUsersList', (p) => ({
                      ...p,
                      checked: !p.checked,
                    }))
                  }
                  testId="hide-specific"
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                Disable specific user icons at the login window, and in the fast
                user switching menu. Enter the short names (account names) of
                users you would like to hide, separated by commas.
              </Setting.Helpers>

              {setting.HiddenUsersList.checked && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      <div>
                        <div className="b-flex-vg1 b-mb1">
                          {setting.HiddenUsersList.value
                            .split(',')
                            .map((user, idx) => (
                              <div key={idx} ref={refs[idx]} className="b-flex">
                                <TextInput
                                  compact
                                  value={user}
                                  placeholder="Local user short name"
                                  disabled={isDisabled}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    update('HiddenUsersList', (p) => {
                                      const users = p.value.split(',');
                                      users[idx] = value;
                                      return { ...p, value: users.join(',') };
                                    });
                                  }}
                                  validator={(v) => [
                                    {
                                      message: 'Required.',
                                      invalid: () => !v.length,
                                      trigger: ['onBlur', validationDep],
                                    },
                                  ]}
                                  onInvalidate={onInvalidate(idx)}
                                  data-testid={`${idx}_${user}`}
                                />
                                {setting.HiddenUsersList.value.split(',')
                                  .length > 1 && (
                                  <Button
                                    className="b-ml1"
                                    icon="trash-can"
                                    theme="error"
                                    kind="link"
                                    isDisabled={isDisabled}
                                    onClick={() => {
                                      update('HiddenUsersList', (p) => ({
                                        ...p,
                                        value: p.value
                                          .split(',')
                                          .filter((_, i) => i !== idx)
                                          .join(','),
                                      }));
                                      onInvalidate(idx)(false);
                                    }}
                                    data-testid="hey"
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                        <Button
                          icon="circle-plus"
                          kind="link"
                          isDisabled={
                            isDisabled ||
                            setting.HiddenUsersList.value.split(',').length >=
                              MAX_USERS
                          }
                          onClick={() => {
                            update('HiddenUsersList', (p) => ({
                              ...p,
                              value: `${p.value},`,
                            }));
                          }}
                        >
                          Add user
                        </Button>
                      </div>
                    </Setting.Controls>
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          </>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

UserVisibilitySection.defaultProps = {
  update: () => {},
};

export default UserVisibilitySection;
