// istanbul ignore file
import {
  Banner,
  Button,
  Checkbox,
  LazyScriptEditor,
  Radio,
  TextInput,
  Uploader,
  defaultRenderInfo,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { Cancel as AxiosCancel } from 'axios';
import { apiTypes } from 'features/library-items/library/common';
import React, { useState, useMemo, useEffect, memo } from 'react';

import { Setting } from 'features/library-items/template';

import {
  getS3InfoForFile,
  uploadCustomApp,
  waitForSHA256,
} from 'templates/library/custom-apps/api';
import OldDmg from '../icons/old_dmg.png';
import OldPkg from '../icons/old_pkg.png';
import OldZip from '../icons/old_zip.png';
import CustomAppsService from '../service/custom-apps-service';

const options = [
  {
    label: 'Installer Package',
    hint: '(install .pkg or .mpkg)',
    value: CustomAppsService.installDetailsType.PACKAGE,
    extensions: ['.pkg', '.mpkg'],
    typeAlert: '.pkg or .mpkg file',
  },
  {
    label: 'Disk Image',
    hint: '(copy .app from disk image to /Applications)',
    value: CustomAppsService.installDetailsType.IMAGE,
    extensions: ['.dmg'],
    typeAlert: '.dmg file',
    bannerText:
      'Kandji will locate the .app file within the disk image and copy it to /Applications. An error will be returned if no .app file is found, or if multiple .app files exist in the disk image.',
  },
  {
    label: 'ZIP File',
    hint: '(unzip contents into specified directory)',
    value: CustomAppsService.installDetailsType.ZIP,
    extensions: ['.zip'],
    bannerText:
      'Kandji will unzip the contents of the ZIP file into the directory specified below. Please be careful and test before deploying: files and/or directories can be overwritten.',
  },
];
const fileIcons = {
  '.pkg': <img src={OldPkg} alt="" />,
  '.mpkg': <img src={OldPkg} alt="" />,
  '.dmg': <img src={OldDmg} alt="" />,
  '.zip': <img src={OldZip} alt="" />,
};

const InstallDetailsCard = (props) => {
  const {
    setting,
    update,
    isDisabled,
    onFileChange,
    isSubmitted,
    onValidate,
    validationDep,
  } = props;
  const [uploadCancelFn, setUploadCancelFn] = useState();
  const [isPreInstallScriptOpened, setIsPreInstallScriptOpened] = useState(
    !!setting.preInstallScript,
  );
  const [isPostInstallScriptOpened, setIsPostInstallScriptOpened] = useState(
    !!setting.postInstallScript,
  );
  const fieldsToValidate = ['unzipLocation', 'file'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  const setIsSaveEnabled = onValidate((isValid) => isValid);

  useEffect(() => {
    if (
      setting.type === CustomAppsService.installDetailsType.ZIP &&
      !setting.unzipLocation
    ) {
      onInvalidate(0)(true);
    } else {
      onInvalidate(0)(false);
    }
  }, [setting.type, setting.unzipLocation]);

  useEffect(() => {
    if (!setting.file?.sha256) {
      onInvalidate(1)('Required');
    } else {
      onInvalidate(1)(false);
    }
  }, [setting.file?.sha256]);

  const selectedOption = useMemo(
    () => options.find(({ value }) => value === setting.type),
    [setting.type],
  );

  const forceWithFile = useMemo(
    () =>
      (setting.file?.sha256 && {
        file: {
          name: setting.file.name,
          size: setting.file.size,
        },
        sha256: setting.file.sha256,
      }) ||
      null,
    [setting.file],
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Install Details</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Upload an installer, customize the install process with pre/post
          install scripts and specify restart requirements.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558748"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more...
          </a>
        </p>
      </Setting.SubHeader>

      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            {options.map(({ value, label, hint }) => (
              <div key={value} className="b-mb-tiny">
                <Radio
                  label={
                    <>
                      {label}
                      <span className="b-txt-light"> {hint} </span>
                    </>
                  }
                  checked={setting.type === value}
                  onChange={() => update('type', value)}
                  disabled={isDisabled}
                />
              </div>
            ))}
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Specify the installer type. Max file size is 5 GB.
            </p>

            {selectedOption.bannerText && (
              <Banner theme="info" kind="block" className="b-mb1 b-mt1">
                <p> {selectedOption.bannerText} </p>
              </Banner>
            )}
          </Setting.Helpers>
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <div
                style={{ gridColumn: '1/3' }}
                className="k-custom-apps-unzip-loc-ctrl"
              >
                {setting.type === CustomAppsService.installDetailsType.ZIP && (
                  <>
                    <p ref={refs[0]} className="b-txt b-mb1">
                      Unzip Location
                    </p>
                    <TextInput
                      value={setting.unzipLocation}
                      onChange={(e) => update('unzipLocation', e.target.value)}
                      placeholder="Enter Location"
                      className="b-mb2"
                      disabled={isDisabled}
                      validator={(v) => [
                        {
                          message: 'Required',
                          invalid: () => !v,
                          trigger: [
                            'onBlur',
                            isSubmitted && 'onMount',
                            validationDep,
                          ],
                        },
                      ]}
                      onInvalidate={onInvalidate('unzipLocation')}
                    />
                  </>
                )}

                {!isPreInstallScriptOpened && (
                  <Button
                    icon="circle-plus"
                    kind="link"
                    className="b-mb1"
                    onClick={() => setIsPreInstallScriptOpened(true)}
                    isDisabled={isDisabled}
                  >
                    Add Preinstall Script
                  </Button>
                )}
                {isPreInstallScriptOpened && (
                  <>
                    <p className="b-txt b-mb1">Preinstall Script</p>
                    <LazyScriptEditor
                      value={setting.preInstallScript}
                      onChange={(value) => update('preInstallScript', value)}
                      language="shell"
                      options={{ readOnly: isDisabled }}
                      className={isDisabled && 'k-mobile-config-info__preview'}
                    />
                  </>
                )}

                <p ref={refs[1]} className="b-txt b-mb1 b-mt2">
                  {selectedOption.label}
                </p>
                <Uploader
                  withError={(isSubmitted && invalidations[1]) || ''}
                  onUpload={(file, updateProgress) =>
                    getS3InfoForFile(file, apiTypes.CUSTOM_APP).then((r) => {
                      setIsSaveEnabled(false);
                      const s3Data = r.data.s3_post_data;
                      const upWithCancel = uploadCustomApp(
                        file,
                        updateProgress,
                        s3Data,
                      );
                      update('file', (p) => ({
                        ...p,
                        filePath: r.data.s3_post_data.fields.key,
                        id: r.data.id,
                        name: file.name,
                        size: file.size,
                      }));
                      onFileChange(file);
                      setUploadCancelFn(() => upWithCancel.cancel);
                      return upWithCancel.upload;
                    })
                  }
                  onUploaded={(file) => {
                    setIsSaveEnabled(true);
                    update('file', (p) => ({
                      ...p,
                      name: file.name,
                      size: file.size,
                    }));
                  }}
                  onError={(error, { type, errorTypes }) => {
                    setIsSaveEnabled(true);
                    if (type !== errorTypes.fileSelectError) {
                      onInvalidate(1)(error);
                    }
                  }}
                  onValidate={(
                    _file,
                    { getUploadCurrentlyCancelled, CancelError },
                  ) => {
                    const [isUploadCancelled, getUploadPrevCancelled] =
                      getUploadCurrentlyCancelled({
                        isTrackAlreadyCancelled: true,
                      });

                    if (isUploadCancelled || !setting.file) {
                      const cancelError = new CancelError(
                        'Upload cancelled during validation',
                      );
                      return Promise.reject(cancelError);
                    }

                    return waitForSHA256(setting.file.id).then((sha256) => {
                      const isAlreadyCancelled = getUploadPrevCancelled();

                      if (getUploadCurrentlyCancelled() || isAlreadyCancelled) {
                        const cancelError = new CancelError(
                          'Upload cancelled after validation',
                        );
                        return Promise.reject(cancelError);
                      }

                      if (setting.file) {
                        update('file', (p) => ({
                          ...p,
                          sha256,
                        }));
                        return sha256;
                      }

                      const noSHACreated = '';
                      return noSHACreated;
                    });
                  }}
                  onCancel={uploadCancelFn}
                  onCancelError={AxiosCancel}
                  onDelete={() => {
                    setUploadCancelFn(null);
                    onFileChange(null);
                    update('file', null);
                  }}
                  allowedTypes={selectedOption.extensions}
                  className="b-mb2"
                  isDisabled={isDisabled}
                  uploadInstructions={
                    <span>
                      Drag file here or{' '}
                      <span className="b-alink">click to upload</span>
                    </span>
                  }
                  fileIcons={fileIcons}
                  maxFileSize={5e9}
                  forceWithFile={forceWithFile}
                  renderInfo={({ currentFile }) => (
                    <div>
                      {defaultRenderInfo({ isDisabled, currentFile })}
                      {setting.file?.downloadLink && (
                        <Button
                          kind="link"
                          theme="dark"
                          target="_blank"
                          href={setting.file?.downloadLink}
                          style={{ marginLeft: -8 }}
                        >
                          Download
                        </Button>
                      )}
                    </div>
                  )}
                />

                {!isPostInstallScriptOpened && (
                  <Button
                    icon="circle-plus"
                    kind="link"
                    className="b-mb1"
                    onClick={() => setIsPostInstallScriptOpened(true)}
                    isDisabled={isDisabled}
                  >
                    Add Postinstall Script
                  </Button>
                )}
                {isPostInstallScriptOpened && (
                  <>
                    <p className="b-txt b-mb1">Postinstall Script</p>
                    <LazyScriptEditor
                      value={setting.postInstallScript}
                      onChange={(value) => update('postInstallScript', value)}
                      language="shell"
                      options={{ readOnly: isDisabled }}
                      className={isDisabled && 'k-mobile-config-info__preview'}
                    />
                  </>
                )}
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.isRestart}
              onChange={() => update('isRestart', (p) => !p)}
              disabled={isDisabled}
              label="Restart after successful install"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Require a restart on the device after successful installation. End
              users will be given a 30 minute countdown before a restart occurs.
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(InstallDetailsCard);
