import {
  Flex,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';
import type { ReactNode } from 'react';
import { severityColorMap } from '../../constants';
import type { Severity } from '../../vulnerability.types';

type SeverityTooltipProps = {
  severity: Severity;
  children: ReactNode;
};

const SeverityKey = styled(Text, {
  width: '73px',
});

const SeverityTooltip = (props: SeverityTooltipProps) => {
  const { severity, children } = props;

  const severities = [
    { label: 'None*', range: '0.0' },
    { label: 'Low', range: '0.1 - 3.9' },
    { label: 'Medium', range: '4.0 - 6.9' },
    { label: 'High', range: '7.0 - 8.9' },
    { label: 'Critical', range: '9.0 - 10.0' },
  ];

  const content = (
    <Flex flow="column" gap="xs">
      <Text css={{ fontWeight: '$medium', fontSize: '$1' }}>
        CVSS v4.0 Ratings
      </Text>

      <Flex flow="column" gap="xs">
        <Flex flow="row">
          <SeverityKey css={{ fontWeight: '$medium' }}>Severity</SeverityKey>
          <Text css={{ fontWeight: '$medium' }}>Severity Score Range</Text>
        </Flex>

        {severities.map((item) => {
          const { label, range } = item;
          const css = label.toLowerCase().includes(severity.toLowerCase())
            ? {
                fontWeight: '$medium',
                color: severityColorMap[severity.toLowerCase()],
              }
            : {};

          return (
            <Flex flow="row">
              <SeverityKey css={css}>{label}</SeverityKey>
              <Text css={css}>{range}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );

  return (
    <TooltipProvider>
      <Tooltip
        content={content}
        side="bottom"
        theme="light"
        css={{ zIndex: 100, padding: '$3 $4', maxWidth: '230px' }}
      >
        {children}
      </Tooltip>
    </TooltipProvider>
  );
};

export { SeverityTooltip };
