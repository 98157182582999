import { Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';
import { tlsOptions } from '../protocols-card.model';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const TslMinimumVersion = ({
  refs,
  isDisabled,
  update,
  setting,
  canShowMinimumTlsVersionError,
  minimumTlsVersionError,
}) => (
  <Setting.SecondaryControlsRow>
    <div ref={refs[keys.tlsMinimumVersion]}>
      <p className="b-txt">TLS minimum version</p>
    </div>
    <div>
      <p className="b-txt-light">
        The minimum TLS version allowed for EAP authentication.
      </p>
    </div>
    <div>
      <Select
        isDisabled={isDisabled}
        options={tlsOptions}
        compact
        onChange={(v) => update(keys.tlsMinimumVersion, v.value)}
        value={tlsOptions.find(
          (option) => option.value === setting[keys.tlsMinimumVersion],
        )}
        errorText={
          (canShowMinimumTlsVersionError && minimumTlsVersionError) || ''
        }
        testId="tsl-min-select"
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(TslMinimumVersion);
