import type { DetectionDateFilterFields } from '../../../threat.types';

const adjustedUTC = (
  date: string,
  days: number = 0,
  resetHours: boolean = true,
): string => {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + days);
  if (resetHours) dateObj.setHours(0, 0, 0, 0);
  return dateObj.toISOString();
};

function getDateRange(detectionDate?: DetectionDateFilterFields): {
  from: string;
  to: string;
} {
  let from: string = '';
  let to: string = '';

  if (!detectionDate) {
    return { from, to };
  }

  const { operator, selectedDate, dateRangeFrom, dateRangeTo, value } =
    detectionDate;

  const now = new Date().toISOString();
  if (value === 'custom_date_range') {
    if (selectedDate) {
      if (operator === 'eq') {
        from = adjustedUTC(selectedDate);
        to = adjustedUTC(selectedDate, 1);
      }
      if (operator === 'gte') {
        from = adjustedUTC(selectedDate);
      }
      if (operator === 'lte') {
        to = adjustedUTC(selectedDate, 1);
      }
    }
    if (operator === 'ib' && dateRangeFrom && dateRangeTo) {
      from = adjustedUTC(dateRangeFrom);
      to = adjustedUTC(dateRangeTo, 1);
    }
  } else if (value === 'all_time') {
    from = '';
    to = '';
  } else if (value?.startsWith('last_24_hours')) {
    from = adjustedUTC(now, -1, false);
    to = adjustedUTC(now, 0, false);
  } else if (value?.startsWith('last_')) {
    const days = Number(value.split('_')[1]);
    from = adjustedUTC(now, -(days - 1));
    to = adjustedUTC(now, 1);
  }

  return { from, to };
}

export default getDateRange;
