import { TextInput, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

const DisplayNameRow = ({ setting, update, isDisabled, isSubmitted }) => {
  const fieldsToValidate = ['displayName'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useEffect(() => {
    if (!setting.displayName) {
      onInvalidate(0)(true);
    }
  }, []);

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">Display Name</p>
      </div>
      <Setting.Helpers className="b-txt-light">
        Provide a display name that will be visible for users in the printer
        preferences.
      </Setting.Helpers>
      <div ref={refs[0]}>
        <TextInput
          value={setting.displayName}
          onChange={(e) => update('displayName', e.target.value)}
          disabled={isDisabled}
          placeholder="Marketing_Printer"
          compact
          onInvalidate={onInvalidate(0)}
          validator={(v) => [
            {
              message: 'Required',
              invalid: () => !v,
              trigger: ['onBlur', isSubmitted && 'onSubmit'],
            },
          ]}
        />
      </div>
    </Setting.Row>
  );
};

export default DisplayNameRow;
