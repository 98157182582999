import { Checkbox, ChipSelect, useChipSelect } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React from 'react';

import { Setting } from 'features/library-items/template';

const ENABLE_OPTIONS = [
  {
    label: 'Enable',
    value: true,
  },
  {
    label: 'Disable',
    value: false,
  },
];

const EnableToggle = ({ value, onChange, disabled, testId }) => {
  const { selectOne } = useChipSelect({
    options: ENABLE_OPTIONS,
    defaultSelected: value,
    callback: onChange,
  });

  return (
    <ChipSelect
      selected={value}
      setSelected={selectOne}
      options={ENABLE_OPTIONS}
      disabled={disabled}
      testId={testId}
    />
  );
};

const General = ({ settings, isDisabled, update }) => {
  const onChangeBlockAllIncoming = (selectedValue) => {
    if (selectedValue) {
      update('BlockAllIncoming', true);

      // Force Stealth Mode to also be Enabled:
      update('canEnableStealthMode', true);
      update('EnableStealthMode', true);
    } else {
      update('BlockAllIncoming', false);
    }
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">General</h3>
        <Setting.HeaderToggle
          isEnabled={settings.EnableFirewall}
          onChange={() => update('EnableFirewall', (p) => !p)}
          isDisabled={isDisabled}
          chipText={{ enabled: 'On', disabled: 'Off' }}
          testId="enable_firewall_toggle"
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure the macOS firewall to prevent unauthorized applications,
          programs, and services from accepting incoming connections.
        </p>
      </Setting.SubHeader>
      {settings.EnableFirewall && (
        <Setting.Rows>
          <>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label="Block all incoming connections"
                  id="block_all_incoming_connections"
                  testId="block_all_incoming_checkbox"
                  checked={settings.canBlockAllIncoming}
                  onChange={() =>
                    update('canBlockAllIncoming', (checked) => !checked)
                  }
                  disabled={isDisabled}
                />
              </Setting.Controls>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Block all incoming connections except those required for basic
                  services such as DHCP, Bonjour, and IPSec.
                </p>
              </Setting.Helpers>
              {settings.canBlockAllIncoming && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <EnableToggle
                      value={settings.BlockAllIncoming}
                      onChange={(selected) =>
                        onChangeBlockAllIncoming(selected.value)
                      }
                      disabled={isDisabled}
                      testId="block_all_incoming_toggle"
                    />
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label="Stealth mode"
                  id="stealth_mode"
                  testId="stealth_mode_checkbox"
                  checked={settings.canEnableStealthMode}
                  onChange={() =>
                    update('canEnableStealthMode', (checked) => !checked)
                  }
                  disabled={
                    isDisabled ||
                    (settings.canBlockAllIncoming && settings.BlockAllIncoming)
                  }
                />
              </Setting.Controls>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Don&apos;t respond or acknowledge attempts to access the Mac
                  from the network by devices using ICMP such as Ping.
                </p>
              </Setting.Helpers>
              {settings.canEnableStealthMode && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <EnableToggle
                      value={settings.EnableStealthMode}
                      onChange={(selected) =>
                        update('EnableStealthMode', selected.value)
                      }
                      disabled={
                        isDisabled ||
                        (settings.canBlockAllIncoming &&
                          settings.BlockAllIncoming)
                      }
                      testId="stealth_mode_toggle"
                    />
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          </>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

General.propTypes = {
  settings: PropTypes.shape({
    EnableFirewall: PropTypes.bool,
    canBlockAllIncoming: PropTypes.bool,
    BlockAllIncoming: PropTypes.bool,
    canEnableStealthMode: PropTypes.bool,
    EnableStealthMode: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
};

export default General;
