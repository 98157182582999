// istanbul ignore file
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { prismService } from '../../data-service/prism-service';
import type { PrismViewSchema } from '../../types/prism.types';

export const useUpdateDeviceView = ({
  onSuccess,
}: {
  onSuccess: (view: PrismViewSchema) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: prismService.updateDeviceView,
    onSuccess: (view) => {
      onSuccess(view);
      queryClient.invalidateQueries({
        queryKey: ['prism', 'deviceViews'],
      });
    },
  });
};
