import React, { useMemo } from 'react';
import { Handle, Position } from 'reactflow';

import { useDroppable } from '@dnd-kit/core';
import { Card, Flex, styled } from '@kandji-inc/nectar-ui';

import type { DraggableLibraryItem } from '../blueprint-flow.types';
import CopyUUID from '../components/CopyUUID';

import Overlay from '../components/Overlay';
import { PENDO_IDS } from '../constants';
import useBlueprintFlow from '../store';
import { getDevicePathStyle, styleNode } from '../theme';
import Items from './parts/items';
import { TagChip } from './parts/rules/rule-tag-list';
import Statement from './parts/rules/statement';

const Container = styled(Flex, {
  display: 'inline-flex',
  padding: '20px 18px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  borderRadius: '10px',
  border: '1px solid $neutral20',
  background: '#FBFDFE',
});

const handleStyle = { opacity: 0 };

function StartNode(props: {
  id: string;
  data: {
    items: DraggableLibraryItem[];
  };
}) {
  const { id, data } = props;
  const { items } = data;
  const [isEditingAssignments, { devicePath }] = useBlueprintFlow((state) => [
    state.isEditingAssignments,
    state.selectedDevice,
  ]);
  const { isOver, setNodeRef } = useDroppable({ id });
  const nodeStyle = styleNode(isOver);
  const isDeletingNode = useBlueprintFlow((state) => state.isDeletingNode);

  const isShowingDevicePath = !isEditingAssignments && devicePath;
  const isOnDevicePath = isShowingDevicePath && devicePath.nodes.includes(id);
  const devicePathItems = devicePath?.libraryItems.filter((itemId) =>
    items.find((item) => item.data.id === itemId),
  );

  const MemoizedItems = useMemo(
    () => (
      <Items
        items={items}
        itemIdsOnDevicePath={isShowingDevicePath && devicePathItems}
        isScrollable
      />
    ),
    [items, isShowingDevicePath],
  );

  return (
    <>
      <Container>
        <Flex wFull className="nodrag nopan" ref={setNodeRef}>
          <Card
            css={{
              position: 'relative',
              ...nodeStyle,
              ...(isShowingDevicePath &&
                getDevicePathStyle(isOnDevicePath).node),
            }}
          >
            <Flex flow="column" id={PENDO_IDS.allDevicesNode}>
              <Flex wFull css={{ padding: '5px 10px', width: '436px' }}>
                <Flex gap="sm" alignItems="center" wrap="wrap">
                  <CopyUUID nodeId={id} />
                  <Statement kind="for" />
                  <TagChip>All devices</TagChip>
                </Flex>
              </Flex>
              {MemoizedItems}
            </Flex>
            <Handle
              type="source"
              position={Position.Right}
              isConnectable={false}
              style={handleStyle}
            />
          </Card>
        </Flex>
        <Overlay isHidden={!isDeletingNode} style={{ borderRadius: '10px' }} />
      </Container>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        style={handleStyle}
      />
    </>
  );
}

export default StartNode;
