import { Flex, Icon, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import {
  ParagraphMedium,
  StyledHeaderImage,
  StyledLink,
  StyledParagraphLight,
} from './styled-components/main';

const StyledTicket = styled(Flex, {
  alignItems: 'flex-start',
  gap: '$4',
  marginTop: '$5',
  maxWidth: '528px',
});
const StyledText = styled(StyledParagraphLight, {
  marginTop: '$2',
  marginBottom: '$2',
});

type Props = Readonly<{
  title: string;
  text: string | JSX.Element;
  icon: string;
  link?: string;
  linkButtonText?: string;
  className?: string;
  testId?: string;
}>;

const Ticket = (props: Props) => {
  const {
    title,
    text,
    icon,
    link,
    linkButtonText = 'Learn more',
    className,
    testId,
  } = props;
  return (
    <StyledTicket data-testid={testId} className={`${className}`}>
      <StyledHeaderImage src={icon} alt="ticket icon" />
      <div>
        <ParagraphMedium>{title}</ParagraphMedium>
        {React.isValidElement(text) ? text : <StyledText>{text}</StyledText>}

        {link && (
          <StyledLink href={link} rel="noreferrer" target="_blank">
            <Icon name="circle-info" size="sm" />
            &nbsp;{linkButtonText}
          </StyledLink>
        )}
      </div>
    </StyledTicket>
  );
};

export default Ticket;
