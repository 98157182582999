import { Button } from '@kandji-inc/bumblebee';
import { API_BASE } from 'app/api/domains';
import { colors } from 'app/common/constants';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import ComputerImage from '../../../assets/img/enrollment_portal/imac_kandji.svg';
import kandjiEnrollMacDoubleClick from '../../../assets/img/enrollment_portal/kandji-enroll-mac-double-click.png';
import kandjiEnrollMacInstall from '../../../assets/img/enrollment_portal/kandji-enroll-mac-install.png';
import KandjiEnrollMacMenuBar from '../../../assets/img/enrollment_portal/kandji-enroll-mac-menu-bar.png';
import KandjiEnrollMacProfileInstall from '../../../assets/img/enrollment_portal/kandji-enroll-mac-profile-install.png';
import KandjiEnrollMacSuccess from '../../../assets/img/enrollment_portal/kandji-enroll-mac-success.png';
import KandjiEnrollMac from '../../../assets/img/enrollment_portal/kandji-enroll-mac.png';
import { StepCard } from '../MDMIntegrationPage';
import { downloadFromUrl } from '../common/helpers';
import { LineLoader } from '../interface/LineLoader';
import HeaderWithLogo from './HeaderWithLogo';
import { validateEnrollmentCode } from './api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComputerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Header = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
  color: #1a1d25;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const HelperText = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1a1d25;
`;

const ButtonWrapper = styled.div`
  margin: 24px auto 74px;
`;

const InstructionsHeader = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 322px;
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #1a1d25;
  box-shadow:
    0px 2px 0px 0px rgba(29, 33, 42, 1),
    inset 0px -1px 0px 0px rgba(29, 33, 42, 1);
`;

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0 auto 20px auto;
  background-color: #b8bdca;
  border: 0 none;
`;

const InstructionsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  width: 983px;
  margin: 20px auto;
`;

class EnrollComputerPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAccessCodeValid: false,
      selectedBlueprint: null,
      validatedAccessCode: null,
    };
    this.buttonRef = React.createRef();
    this.onDownload = this.onDownload.bind(this);
  }

  componentDidMount() {
    const { history, match } = this.props;
    const accessCode = get(match, 'params.accessCode');
    if (accessCode) {
      validateEnrollmentCode(accessCode)
        .then(({ blueprint_id }) => {
          this.setState({
            isAccessCodeValid: true,
            selectedBlueprint: blueprint_id,
            validatedAccessCode: accessCode,
          });
        })
        .catch(() => history.push('/enroll'))
        .finally(() => this.setState({ isLoading: false }));
    }
  }

  onDownload() {
    const { selectedBlueprint, validatedAccessCode } = this.state;
    if (!selectedBlueprint) {
      return;
    }
    downloadFromUrl(
      `${API_BASE}mdm/enroll/${selectedBlueprint}?code=${validatedAccessCode}`,
    );
  }

  scrollToRef() {
    window.scrollTo(0, this.buttonRef.current.offsetTop);
  }

  render() {
    const { isLoading, isAccessCodeValid } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    if (!isAccessCodeValid) {
      return <h1>Access code is invalid</h1>;
    }
    return (
      <Container id="enroll">
        <HeaderWithLogo pageName="Enrollment Portal" large />
        <ComputerImageWrapper>
          <img role="presentation" alt="computer" src={ComputerImage} />
        </ComputerImageWrapper>
        <Header>Enroll your Mac into Kandji</Header>
        <HelperText>
          To enroll, click the button below to download and install Kandji
        </HelperText>
        <ButtonWrapper>
          <Button
            type="button"
            innerRef={this.buttonRef}
            onClick={() => {
              this.onDownload();
              this.scrollToRef();
            }}
            style={{ width: '210px', background: '#FFCF01', color: '#1A1D25' }}
          >
            Download Kandji
          </Button>
        </ButtonWrapper>
        <InstructionsHeader>Enrollment Instructions</InstructionsHeader>
        <Separator />
        <InstructionsWrapper>
          <StepCard
            icon="1"
            title="Locate and open the Kandji enrollment profile."
            instruction={[KandjiEnrollMac, KandjiEnrollMacProfileInstall]}
            fullWidthText
            cardColor={colors.white}
          >
            <span>
              After clicking the Download Kandji button above, locate the
              <BoldText> kandji-enroll.mobileconfig</BoldText> file in your
              Downloads folder and open it.
              <BoldText> Note:</BoldText> Clicking the &quot;Profile
              installation&quot; notification will not open System Settings or
              System Preferences for you.
            </span>
          </StepCard>
          <StepCard
            icon="2"
            title="Install the profile and authenticate."
            instruction={[
              kandjiEnrollMacDoubleClick,
              kandjiEnrollMacInstall,
              KandjiEnrollMacSuccess,
            ]}
            fullWidthText
            cardColor={colors.white}
          >
            <span>
              <BoldText>macOS 13+:</BoldText> Open System Settings {'>'} Privacy{' '}
              {'& '}
              Security {'>'} Profiles <br />
              <BoldText>macOS 11/12:</BoldText> Open System Preferences {'>'}{' '}
              Profiles <br />
              <br />
              Then follow the steps to complete installation of the MDM
              enrollment profile. When prompted, authenticate with an
              administrator username and password.
            </span>
          </StepCard>
          <StepCard
            icon="3"
            title="Congratulations! You're all done."
            instruction={[KandjiEnrollMacMenuBar]}
            fullWidthText
            cardColor={colors.white}
          >
            <span>The Kandji icon will soon appear in your menu bar.</span>
          </StepCard>
        </InstructionsWrapper>
      </Container>
    );
  }
}

export default EnrollComputerPage;
