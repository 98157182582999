import { api } from 'app/api/base';
import {
  URL_COMPANY_INFO,
  URL_ENROLLMENT_PORTAL_TOGGLE,
  URL_USERS,
} from '../api/urls';
import { fetchAccount } from './account';
import { account } from './action-types';
import { setSnackbar } from './ui';

export const updateCompanyReq =
  (companyId, company, needFetch = true) =>
  (dispatch) =>
    api(URL_COMPANY_INFO)
      .patch(company)
      .then((response) => {
        dispatch({
          type: account.ACCOUNT_COMPANY_FETCHED,
          company: response.data.company,
        });
        if (needFetch) {
          dispatch(fetchAccount());
        }
        dispatch(setSnackbar('Info was saved'));
      });

export const toggleEnrollmentPortalStatus = (company) => (dispatch) => {
  dispatch({
    type: account.ACCOUNT_COMPANY_FETCHED,
    company,
  });
  return api(URL_ENROLLMENT_PORTAL_TOGGLE).patch(company);
};

export const sendInviteToUser = (data) => api(`${URL_USERS}invite`).post(data);
export const resendInviteToUser = (data) =>
  api(`${URL_USERS}invite/resend-email`).post(data);
