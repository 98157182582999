import type { CheckedState } from '@kandji-inc/nectar-ui';
import { Banner, Checkbox, Chip, Text, styled } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import type React from 'react';

export type CheckboxRowProps = {
  isDisabled: boolean;
  isSubmitted: boolean;
  name: string;
  description: string;
  descriptionLink?: { link: string; text: string };
  tags: Array<string>;
  supervisionWarning?: boolean;
  customWarning?: string;
  isChecked: boolean;
  isInverted?: boolean;
  hasUnmetDependencies?: boolean;
  dependencies?: Array<{ key: string; mustBe: boolean }>;
  onCheckedChange: (checked: CheckedState) => void;
  children?: (isChecked: boolean) => React.ReactNode;
  validationDep: number;
  helpers: Record<string, any>;
  updateHelpers: (key: string, v: any | ((prev: any) => void)) => void;
};

const CheckboxNectar = styled(Checkbox, {
  width: '14px !important',
  height: '14px !important',
});

const CheckboxRow = (props: CheckboxRowProps) => {
  const {
    isDisabled,
    name,
    description,
    descriptionLink,
    tags,
    supervisionWarning,
    customWarning,
    isChecked,
    isInverted,
    hasUnmetDependencies,
    dependencies,
    onCheckedChange,
    children,
  } = props;

  const _isChecked = isInverted ? !isChecked : isChecked;
  return (
    <Setting.Row>
      <Setting.Title>
        <CheckboxNectar
          disabled={isDisabled || hasUnmetDependencies}
          label={name}
          checked={_isChecked}
          onCheckedChange={() => onCheckedChange(!isChecked)}
          id={name}
          data-testid={name}
        />
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          {description}{' '}
          {descriptionLink && (
            <a target="_blank" rel="noreferrer" href={descriptionLink.link}>
              <Text
                variant="primary"
                css={{
                  display: 'inline-block',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {descriptionLink.text}
              </Text>
            </a>
          )}
        </p>
        <div className="b-flex-vc b-flex-g1">
          {tags.map((tag) => (
            <Chip key={tag} label={tag} color="default" />
          ))}
        </div>
        {supervisionWarning && (
          <Banner
            icon="circle-info"
            text={
              <div>
                <span className="b-txt-bold">Warning:</span> This restriction
                will require supervision by Apple at a later date.
              </div>
            }
          />
        )}
        {customWarning && <Banner icon="circle-info" text={customWarning} />}
        {hasUnmetDependencies && (
          <Banner
            icon="circle-info"
            text={`This option is not needed if ${dependencies
              ?.map(({ key }) => `"${key}"`)
              .join(' and ')} is enabled.`}
          />
        )}
      </Setting.Helpers>
      {children?.(_isChecked)}
    </Setting.Row>
  );
};

export default CheckboxRow;
