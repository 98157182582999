import React, { useContext, useCallback, useMemo } from 'react';
/* istanbul ignore file */
import './restrictions.css';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import { Box, Grid } from '@kandji-inc/nectar-ui';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';

import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import { configMapping } from './config/allConfigs';
import { getIsChecked, tagKinds } from './config/common';
import initialState from './initial-state';
import { RestrictionSection, Filter as RestrictionsFilter } from './sections';
import Navigation from './sections/Navigation';
import { restrictionsService } from './service/restrictions-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useRestrictionsService from './service/use-restrictions-service';

const RestrictionsPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const updateFilter = useCallback(update('filters', setModel), []);
  const updateRestriction = useCallback(update('restrictions', setModel), []);
  const updateHelpers = useCallback(update('_helpers', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  /** Returns the mapping for categories -> config, in order */
  const configurations = configMapping();
  const filterConfigurations = useMemo(
    () =>
      configurations.categoryMapping
        .map(({ config, ...rest }) => ({
          ...rest,
          config: config
            .filter(({ tags }) => {
              // Restriction type
              const restrictionTypeFilter = model.filters.restrictionType;

              if (restrictionTypeFilter === 'supervised') {
                return tags.includes(tagKinds.supervised);
              }
              if (restrictionTypeFilter === 'non-supervised') {
                return !tags.includes(tagKinds.supervised);
              }

              return true;
            })
            .filter(({ tags }) => {
              // Device type
              const deviceTypeFilter = model.filters.deviceType;

              if (deviceTypeFilter === 'all') {
                return true;
              }
              return tags.includes(deviceTypeFilter);
            })
            .filter(({ name, description }) => {
              // Search term
              const searchTermFilter = model.filters.searchTerm.toLowerCase();
              return (
                name.toLowerCase().includes(searchTermFilter) ||
                description.toLowerCase().includes(searchTermFilter)
              );
            })
            .filter(({ key, secondaryKeys, legacyInversion }) => {
              // Is Enabled
              const isEnabledFilter = model.filters.isShowEnabledOnly;
              const isEnabledValue = getIsChecked(
                model.restrictions,
                key,
                legacyInversion,
              );

              if (isEnabledFilter) {
                return (
                  isEnabledValue ||
                  secondaryKeys?.some((sKey) =>
                    getIsChecked(model.restrictions, sKey, legacyInversion),
                  )
                );
              }

              return true;
            })
            .filter(({ isHidden }) => !isHidden),
        }))
        .filter(({ config }) => config.length),
    [model.filters, pageState],
  );

  const Sections = useMemo(
    () =>
      filterConfigurations.map((configuration) => (
        <RestrictionSection
          key={configuration.category}
          configuration={configuration}
          pageState={pageState}
          setting={model}
          validationDep={validationDep}
          update={updateRestriction}
          updateHelpers={updateHelpers}
          allConfigurations={configurations.keyToDescription}
        />
      )),
    [model.restrictions, model._helpers, filterConfigurations],
  );

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={restrictionsService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsDuplication
      supportsRules
      supportsInstallOn
    >
      <Grid
        css={{
          gridTemplateColumns: '235px 1fr',
          gridTemplateRows: 'auto 1fr',
          gap: '$2',
        }}
      >
        <Box css={{ gridColumn: '1 / -1' }}>
          <RestrictionsFilter onChange={updateFilter} values={model.filters} />
        </Box>
        <Navigation
          categories={filterConfigurations
            .filter(({ isHidden }) => !isHidden)
            .map((fc) => ({
              category: fc.category,
              key: fc.category,
            }))}
        />
        <Box className="b-library__settings">{Sections}</Box>
      </Grid>
    </LibraryItemPage>
  );
};

const Restrictions = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initialState(),
    useService: useRestrictionsService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <RestrictionsPage {...pageProps} testId="restrictions-v2" />;
};

export default Restrictions;
