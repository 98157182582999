import type { IconComponentProps } from '@kandji-inc/nectar-ui';
import {
  Box,
  Button,
  Dialog,
  Flex,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import * as React from 'react';

import TableHeaderIconButton from '../components/TableHeaderIconButton';
import { useColumnEditorState } from '../hooks/use-column-editor-state';
import type { EmptyColumnsKind } from '../utils/columnEditorUtils';
import { COLUMN_EDITOR_DIMENSIONS as DIMENSIONS } from '../utils/columnEditorUtils';
import { ColumnEditorContent } from './components/ColumnEditorContent';

type PrismColumnEditorProps = {
  title: string;
  sidebarOpened: boolean;
  columns: Array<Column<unknown, unknown>>;
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  handleUpdateColumns: (args: {
    columnVisibility: Record<string, boolean>;
    columnOrder: string[];
  }) => void;
  columnDefaults: {
    columnVisibility: {
      [k: string]: boolean;
    };
    columnOrder: string[];
  };
  trigger?: any;
};

export type ColumnEditorContentProps = {
  columns: Array<Column<unknown, unknown>>;
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  onMoveToVisible: (colId: string) => void;
  onMoveToHidden: (colId: string) => void;
  onReorder: (newOrder: string[]) => void;
};

export type ColumnItemProps = {
  displayName: string | undefined;
  pinned?: boolean;
  icon: IconComponentProps['name'];
  onIconClick: (event: React.MouseEvent<SVGElement>) => void;
};

export type EmptyColumnEditorStateProps = {
  emptyKind: EmptyColumnsKind;
};

const PrismColumnEditor = (props: PrismColumnEditorProps) => {
  const {
    sidebarOpened,
    title,
    columns,
    columnOrder,
    handleUpdateColumns,
    columnDefaults,
    columnVisibility,
    trigger: Trigger,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const {
    makeVisible,
    makeHidden,
    reorder,
    reset,
    getColumnState,
    discard,
    isDefault,
    isDirty,
  } = useColumnEditorState({
    currentState: {
      columnVisibility,
      columnOrder,
    },
    defaultState: {
      columnVisibility: columnDefaults.columnVisibility,
      columnOrder: columnDefaults.columnOrder,
    },
  });

  const currentColumnState = getColumnState();
  const toggleIsOpen = () => {
    discard();
    setIsOpen((prev) => !prev);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={toggleIsOpen}
      css={{
        left: sidebarOpened
          ? `calc(50% + ${DIMENSIONS.SIDEBAR_MODAL_OPEN_OFFSET_PX}px)`
          : `calc(50% + ${DIMENSIONS.SIDEBAR_MODAL_CLOSED_OFFSET_PX}px)`,

        '& h1': {
          fontWeight: '$medium',
          color: '$neutral90',
        },
      }}
      title={`Edit ${title} columns`}
      content={
        <ColumnEditorContent
          columns={columns}
          columnOrder={currentColumnState.columnOrder}
          columnVisibility={currentColumnState.columnVisibility}
          onMoveToHidden={makeHidden}
          onMoveToVisible={makeVisible}
          onReorder={reorder}
        />
      }
      footer={
        <Flex justifyContent="space-between">
          <Button variant="subtle" onClick={reset} disabled={isDefault}>
            Reset to default
          </Button>

          <Flex gap="xs">
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>

            <Button
              variant="primary"
              disabled={!isDirty}
              onClick={() => {
                handleUpdateColumns({
                  columnVisibility: currentColumnState.columnVisibility,
                  columnOrder: currentColumnState.columnOrder,
                });
                return setIsOpen(false);
              }}
            >
              Apply
            </Button>
          </Flex>
        </Flex>
      }
    >
      <Box onClick={toggleIsOpen}>
        <TooltipProvider>
          <Tooltip content="Edit columns" side="top">
            {Trigger != null ? (
              <Trigger onClick={toggleIsOpen} />
            ) : (
              <TableHeaderIconButton
                icon="table-columns"
                onClick={toggleIsOpen}
              />
            )}
          </Tooltip>
        </TooltipProvider>
      </Box>
    </Dialog>
  );
};

export default PrismColumnEditor;
