import {
  sidePanel as SidePanelCmp,
  setClass,
  Toaster as toaster,
  useShadowOnScroll,
} from '@kandji-inc/bumblebee';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useMemo } from 'react';
import './side-panel.css';

import initialState from '../initial-state';
import AddEditPanel from './add-edit-panel';
import { TYPES } from './constants';
import CustomizePanel from './customize-panel';
import DeletePanel from './delete-panel';

const SidePanel = ({
  type,
  isOpen,
  closePanel,
  openPanelType,
  currArgs,
  model,
  update,
  getConflicts,
  onDelete,
  onSave,
  onSaveRecAllCategory,
  patch,
}) => {
  const onDone = useMemo(
    () => () => patch(model).catch(() => toaster('Something went wrong.')),
    [model],
  );
  const handlers = {
    closePanel,
    openPanelType,
    update,
    getConflicts,
    onDelete,
    onSave,
    onSaveRecAllCategory,
    onDone: () => patch(model).catch(() => toaster('Something went wrong.')),
  };
  const { isShowHeaderShadow, setBody } = useShadowOnScroll();

  const titleByType = {
    [TYPES.CUSTOMIZE]: model.branding.isInitialCustomization
      ? 'Customize branding'
      : 'Self Service customization',
    [TYPES.ADD]: 'Add new category',
    [TYPES.EDIT]: 'Edit category',
    [TYPES.EDIT_RECOMMENDED]: 'Edit recommended category',
    [TYPES.EDIT_ALL]: 'Edit All category',
    [TYPES.DELETE]: 'Delete category',
  };

  const panelByType = {
    [TYPES.CUSTOMIZE]: (
      <CustomizePanel
        branding={model.branding}
        handlers={handlers}
        setBody={setBody}
        currArgs={currArgs}
        onDone={onDone}
      />
    ),
    [TYPES.ADD]: (
      <AddEditPanel
        addEditCategory={model.addEditCategory}
        type={TYPES.ADD}
        handlers={handlers}
        setBody={setBody}
        currArgs={currArgs}
      />
    ),
    [TYPES.EDIT]: (
      <AddEditPanel
        addEditCategory={model.addEditCategory}
        type={TYPES.EDIT}
        handlers={handlers}
        setBody={setBody}
        currArgs={currArgs}
      />
    ),
    [TYPES.EDIT_RECOMMENDED]: (
      <AddEditPanel
        type={TYPES.EDIT_RECOMMENDED}
        addEditCategory={model.addEditCategory}
        handlers={handlers}
        setBody={setBody}
        currArgs={currArgs}
      />
    ),
    [TYPES.EDIT_ALL]: (
      <AddEditPanel
        type={TYPES.EDIT_ALL}
        addEditCategory={model.addEditCategory}
        handlers={handlers}
        setBody={setBody}
        currArgs={currArgs}
      />
    ),
    [TYPES.DELETE]: (
      <DeletePanel
        model={model}
        handlers={handlers}
        setBody={setBody}
        currArgs={currArgs}
      />
    ),
    [TYPES.INACTIVE]: <></>,
  };

  return (
    <div>
      <SidePanelCmp isVisible={isOpen} size="default">
        <div className="b-side-panel-layout">
          <div
            className={setClass(
              'b-side-panel-layout__header',
              isShowHeaderShadow && '--with-shadow',
            )}
          >
            <h2 className="b-h2">{titleByType[type]}</h2>
          </div>
          {panelByType[type]}
        </div>
      </SidePanelCmp>
    </div>
  );
};

SidePanel.propTypes = {
  closePanel: func,
  isOpen: bool,
  model: shape({
    categories: arrayOf(
      shape({
        icon: string,
        id: string,
        name: string,
      }),
    ),
    recommended: shape({
      icon: string,
      description: string,
      name: string,
    }),
    all: shape({
      icon: string,
      description: string,
      name: string,
    }),
    addEditCategory: shape({
      name: string,
      description: string,
      icon: string,
    }),
    branding: shape({
      header: string,
      subheader: string,
      logoUrl: string,
      logoS3Key: string,
      darkLogoUrl: string,
      darkLogoS3Key: string,
      isInitialCustomization: bool,
    }),
    deleteCategory: {
      reassignTo: string,
    },
  }),
  openPanelType: shape({
    add: func,
    edit: func,
    editRecommended: func,
    editAll: func,
  }),
  currArgs: shape({
    id: string,
    icon: string,
    iconImg: string,
    name: string,
    description: string,
  }),
  type: string,
  update: func,
  onDelete: func,
  onSave: func,
  getConflicts: func,
  patch: func,
  onSaveRecAllCategory: func,
};

SidePanel.defaultProps = {
  closePanel: () => {},
  isOpen: false,
  model: initialState,
  openPanelType: () => {},
  currArgs: {},
  type: '',
  update: () => {},
  onDelete: () => {},
  onSave: () => {},
  getConflicts: () => {},
  patch: () => {},
  onSaveRecAllCategory: () => {},
};

export default SidePanel;
