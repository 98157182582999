import React, { createContext, useState, useEffect, useContext } from 'react';
import uuidv4 from 'uuid/v4';

import { api } from 'app/api/base';
import { URL_AUTH_CONNECTOR } from 'app/api/urls';

import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import toaster from '../../theme/toaster';
import isValidCert from './certificateRegex';

const defaultValues = {
  data: undefined,
  fieldValidation: undefined,
  list: undefined,
  wizard: undefined,
  isFetching: false,
  isLoading: false,
  isWorking: false,
  setList: () => {},
  setWizard: () => {},
  setIsFetching: () => {},
  setIsLoading: () => {},
  setIsWorking: () => {},
  fetchData: () => {},
  fetchList: () => {},
  initializeData: () => {},
  initializeList: () => {},
  resetData: () => {},
  resetWizard: () => {},
  handleSave: () => {},
  onEnable: () => {},
  onDisable: () => {},
  onDelete: () => {},
  onCancel: () => {},
  onWizardOpen: () => {},
  onWizardClose: () => {},
};

const Context = createContext(defaultValues);

const Provider = ({ value, children }) => {
  const { currentCompany } = useContext(AccountContext);
  const { onModalClose, onOffcanvasOpen, onOffcanvasClose } =
    useContext(InterfaceContext);

  const [data, setData] = useState(undefined);
  const [list, setList] = useState(undefined);
  const [wizard, setWizard] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const requiredField =
    (message = 'Required') =>
    (val) =>
      !val && wizard.strategy !== 'samlp' && message;
  const requiredSamlField =
    (message = 'Required') =>
    (val) =>
      !val && wizard.strategy === 'samlp' && message;
  const validateSigningCert =
    (message = "This doesn't appear to be a valid certificate.") =>
    (val) =>
      wizard.strategy === 'samlp' && !isValidCert(val) && message;
  const fieldValidation = {
    display_name: requiredField(),
    options_domain: requiredField(),
    options_client_id: requiredField(),
    options_client_secret: requiredField(),
    options_sign_in_endpoint: requiredSamlField(),
    options_user_id_attribute: requiredSamlField(),
    options_signing_cert: [requiredSamlField(), validateSigningCert()],
  };

  const fetchData = async (id) => {
    try {
      setIsFetching(true);
      const fetchResp = await api(`${URL_AUTH_CONNECTOR}/${id}`).get();
      const { connections } = fetchResp.data;

      setData(connections[0]);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      toaster('Something went wrong.');
    }
  };
  const fetchList = async (runIsLoading) => {
    try {
      if (runIsLoading) {
        setIsLoading(true);
      }
      const { data: connData } = await api(URL_AUTH_CONNECTOR).get();
      const { connections } = connData;
      // Add isConfirmed field to each row item(connection)
      setList(connections.map((c) => ({ ...c, isConfirm: false })));
      if (runIsLoading) {
        setIsLoading(false);
      }
    } catch (error) {
      if (runIsLoading) {
        setIsLoading(false);
      }
      toaster('Something went wrong.');
    }
  };

  const initializeData = (initial) => {
    const { id } = wizard;

    const isExisting = !!id;
    if (isExisting) {
      fetchData(id);
    } else {
      setData(initial);
    }
  };
  const initializeList = () => fetchList(true);
  const initializeWizard = () =>
    setWizard({
      id: null,
      strategy: null,
    });

  const resetData = () => setData(undefined);
  const resetWizard = () => setWizard({});

  const handleSave = async (form, connectionNameNew) => {
    const { id, strategy } = wizard;

    const isExisting = !!id;
    try {
      setIsWorking(true);
      if (isExisting) {
        // form.strategy = strategy; // fallback to have a successful PATCH immediately after a POST
        const fetchResp = await api(`${URL_AUTH_CONNECTOR}/${id}`).patch(form);

        const { connections } = fetchResp.data;
        const conn = connections[0];

        setData(conn);
        fetchData(conn.id);
        toaster(`${conn.display_name} updated.`);
      } else {
        form.strategy = strategy;
        form.connection_name =
          connectionNameNew ||
          `${currentCompany.subdomain}-${strategy}-${uuidv4()}`;
        const postResp = await api(URL_AUTH_CONNECTOR).post(form);

        const conn = postResp.data.connections[0];

        setWizard({
          ...wizard,
          id: conn.id,
        });
        setData(conn);

        fetchData(conn.id);
        toaster(`${conn.display_name} added.`);
      }
      fetchList(true);
      setIsWorking(false);
    } catch (error) {
      setIsWorking(false);
      toaster('Something went wrong.');
    }
  };

  const onEnable = async (row) => {
    const { id, connection_name, display_name, strategy, options } = row;
    try {
      setIsWorking(true);
      await api(`${URL_AUTH_CONNECTOR}/${id}`).patch({
        is_enabled: true,
        connection_name,
        display_name,
        strategy,
        options,
      });
      setIsLoading(true);
      await fetchList(true);
      setIsWorking(false);
      setIsLoading(false);
      toaster(`${display_name} updated.`);
    } catch (error) {
      setIsWorking(false);
      setIsLoading(false);
      fetchList(); /* revert back if failure */
      toaster('Something went wrong.');
    }
  };
  const onDisable = async (row) => {
    const { id, connection_name, display_name, strategy, options } = row;
    try {
      setIsWorking(true);
      await api(`${URL_AUTH_CONNECTOR}/${id}`).patch({
        is_enabled: false,
        connection_name,
        display_name,
        strategy,
        options,
      });
      onModalClose();
      await fetchList(true);
      setIsWorking(false);
      setIsLoading(false);
      toaster(`${display_name} updated.`);
    } catch (error) {
      setIsWorking(false);
      fetchList(); /* revert back if failure */
      toaster('Something went wrong.');
    }
  };
  const onDelete = async (row) => {
    const { id, display_name } = row;
    try {
      setIsWorking(true);
      await api(`${URL_AUTH_CONNECTOR}/${id}`).delete();
      onModalClose();
      setIsLoading(true);
      await fetchList();
      setIsWorking(false);
      setIsLoading(false);
      toaster(`${display_name} deleted.`);
    } catch (error) {
      setIsWorking(false);
      setIsLoading(false);
      onModalClose();
      fetchList(); /* revert back if failure */
      toaster('Something went wrong.');
    }
  };
  const onCancel = () => onWizardClose(false);
  const onWizardOpen = (id, strategy, openOffcanvas = true) => {
    setWizard({
      id,
      strategy,
    });
    if (openOffcanvas) {
      onOffcanvasOpen('single-sign-on');
    }
  };
  const onWizardClose = (initialize = true, closeOffcanvas = true) => {
    if (closeOffcanvas) {
      onOffcanvasClose();
    }
    if (initialize) {
      initializeWizard();
    }
  };

  useEffect(() => {
    initializeList();
  }, []);
  useEffect(() => {
    initializeWizard();
    return () => resetWizard();
  }, []);

  return (
    <Context.Provider
      value={{
        ...defaultValues,
        data,
        fieldValidation,
        list,
        wizard,
        isFetching,
        isLoading,
        isWorking,
        setList,
        setWizard,
        setIsFetching,
        setIsLoading,
        setIsWorking,
        fetchData,
        fetchList,
        initializeData,
        initializeList,
        resetData,
        resetWizard,
        handleSave,
        onEnable,
        onDisable,
        onDelete,
        onCancel,
        onWizardOpen,
        onWizardClose,
        ...value,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
