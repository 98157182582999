import React from 'react';

import authIcon from '../../assets/authenticated-icon.svg';
import serverIcon from '../../assets/server-icon.svg';

export const INITIAL_STATE = {
  isProcessing: false,
  nextButtonDisabled: false,
  newIntegrationData: { name: '' },
  showDownloadProgress: true,
  downloadProgress: 25,
  includeAuthTokenWithDownload: false,
};

export const NEXT_BUTTON_TEXT = ['Get started', 'Download connector', 'Close'];

const ServerComponent = () => (
  <>
    Transfer the <span className="cmn-txt-code">Kandji ADCS.exe</span> file to
    your Windows Server machine and run the file.
  </>
);

export const TICKETS = [
  {
    key: 'inst-adcs-conn',
    icon: serverIcon,
    text: <ServerComponent />,
    title: 'Install the AD CS connector',
  },
  {
    key: 'auth-w-kandji',
    icon: authIcon,
    text: 'Once installed, open the AD CS Connector app to authenticate with Kandji and complete setup of the connection.',
    title: 'Authenticate with Kandji',
    link: 'https://support.kandji.io/support/solutions/articles/72000568715-active-directory-certificate-services-ad-cs-integration-ad-cs-connector-installation ',
  },
];
