import {
  Flex,
  Select,
  TextInput,
  onEmptyBlurValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useRef } from 'react';

import SshProfileService from '../service/ssh-profile-service';

const typeOptions = [
  {
    value: SshProfileService.limitAccessTypes.USER,
    label: 'User',
  },
  {
    value: SshProfileService.limitAccessTypes.GROUP,
    label: 'Group',
  },
];

const LimitServerAccessItem = ({
  isDisabled,
  item,
  update,
  index,
  validationDep,
}) => {
  const firstDep = useRef(validationDep);
  const showValidation = firstDep.current !== validationDep;
  const fieldsToValidate = [`access-type${index}`, `access-name${index}`];

  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate(0)(!item.type);
  }, [item.type]);
  useEffect(() => {
    onInvalidate(1)(!item.name);
  }, [item.name]);

  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <Flex gapType="gap2">
      <div className="k-ssh-access-col" ref={refs[0]}>
        <Select
          options={typeOptions}
          value={typeOptions.find((o) => o.value === item.type)}
          defaultValue=""
          onChange={(v) => {
            update('limitAccessValue', (p) => {
              const newItems = [...p];
              newItems[index] = {
                ...newItems[index],
                type: v.value,
              };
              return newItems;
            });
          }}
          placeholder="Select user or group"
          disabled={isDisabled}
          menuPortalTarget={document.body}
          errorText={(showValidation && !item.type && 'Required') || ''}
        />
      </div>

      <div className="k-ssh-access-col" ref={refs[1]}>
        <TextInput
          value={item.name}
          onChange={(e) => {
            const newName = e.target.value; // instead of e.persist()

            update('limitAccessValue', (p) => {
              const newItems = [...p];
              newItems[index] = {
                ...newItems[index],
                name: newName,
              };
              return newItems;
            });
          }}
          disabled={isDisabled}
          placeholder="Enter the user short name or the group name "
          validator={(v) => [
            onEmptyBlurValidator(v, {
              trigger: [
                'onBlur',
                validationDep,
                ...(showValidation ? ['onInput'] : []),
              ],
            }),
          ]}
        />
      </div>
    </Flex>
  );
};

export default LimitServerAccessItem;
