import _get from 'lodash/get';
import _split from 'lodash/split';

import { SAML_CONNECTION_ID, webApiAudience } from 'src/config';
import { api } from './base';
import { URL_AUTH_CONNECTOR, URL_COMPANY_INFO, URL_GET_ACCOUNT } from './urls';

export const apiFetchUser = () =>
  api(URL_GET_ACCOUNT)
    .get()
    .then((response) => {
      const { data } = response;
      if (!data) {
        return Promise.reject(new Error('No user found'));
      }
      return {
        user: data.user,
        tariffBlocked: data.tariffBlocked,
      };
    });

export const apiFetchCompany = () =>
  api(URL_COMPANY_INFO)
    .get()
    .then((response) => response.data);

export const isSAMLFederatedLogoutRequired = async (claims = {}) => {
  // saml connection is identified from the sub claim (user id)
  // auth0 prefixes this to the user id
  const [connectionType = ''] = _split(claims.sub, '|');
  if (connectionType.toLowerCase() !== SAML_CONNECTION_ID) {
    return false;
  }
  try {
    const id = claims[`${webApiAudience}claims/connectionId`];
    const { data } = await api(`${URL_AUTH_CONNECTOR}/${id}`).get();
    const connection = _get(data, ['connections', '0'], {});
    return !!_get(connection, ['options', 'sign_out_endpoint'], '');
  } catch (e) {
    return false;
  }
};
