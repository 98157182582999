import type { blueprintLibraryCategories } from 'src/features/blueprints/common';
/* istanbul ignore file */
import type { LibraryApiTypeKind } from 'src/features/library-items/library-items.types';
import type {
  canAddLibraryTypes,
  categories,
  deviceTypes,
  osRequirements as osReqs,
  publishers,
} from '../../common';

interface LibraryItemConfigOptions {
  name: string;
  blueprintLibraryName?: string;
  description: string;
  type: LibraryApiTypeKind;
  publisher: (typeof publishers)[keyof typeof publishers];
  devices: Array<(typeof deviceTypes)[keyof typeof deviceTypes]>;
  osRequirements: Array<(typeof osReqs)[keyof typeof osReqs]>;
  category: (typeof categories)[keyof typeof categories];
  blueprintCategory: (typeof blueprintLibraryCategories)[keyof typeof blueprintLibraryCategories];
  icon: string;
  canAdd: (typeof canAddLibraryTypes)[keyof typeof canAddLibraryTypes];
  identifier: string;
  routeType: string;
  getUrl: (o: { id: string }) => string;
  getAddUrl: (o: { id: string }) => string;
  component: (props: any) => JSX.Element;
  isHidden?: (o: { account: any; environment: any }) => boolean;
  isOldPath?: (o: { account: any; environment: any }) => boolean;
  singleBlueprintAllowed?: boolean;
  singleAssignmentMappingAllowed?: boolean;
}

class LibraryItemConfig {
  name: LibraryItemConfigOptions['name'];
  blueprintLibraryName?: LibraryItemConfigOptions['blueprintLibraryName'];
  description: LibraryItemConfigOptions['description'];
  type: LibraryItemConfigOptions['type'];
  publisher: LibraryItemConfigOptions['publisher'];
  devices: LibraryItemConfigOptions['devices'];
  osRequirements: LibraryItemConfigOptions['osRequirements'];
  category: LibraryItemConfigOptions['category'];
  blueprintCategory: LibraryItemConfigOptions['blueprintCategory'];
  icon: LibraryItemConfigOptions['icon'];
  canAdd: LibraryItemConfigOptions['canAdd'];
  identifier: LibraryItemConfigOptions['identifier'];
  routeType: LibraryItemConfigOptions['routeType'];
  getUrl: LibraryItemConfigOptions['getUrl'];
  getAddUrl: LibraryItemConfigOptions['getAddUrl'];
  component: LibraryItemConfigOptions['component'];
  isHidden?: LibraryItemConfigOptions['isHidden'];
  isOldPath?: LibraryItemConfigOptions['isOldPath'];
  singleBlueprintAllowed?: LibraryItemConfigOptions['singleBlueprintAllowed'];
  singleAssignmentMappingAllowed?: LibraryItemConfigOptions['singleAssignmentMappingAllowed'];

  constructor({
    name,
    blueprintLibraryName,
    description,
    type,
    publisher,
    devices,
    osRequirements,
    category,
    blueprintCategory,
    icon,
    canAdd,
    identifier,
    routeType,
    getUrl,
    getAddUrl,
    component,
    isHidden,
    isOldPath,
    singleBlueprintAllowed,
    singleAssignmentMappingAllowed,
  }: LibraryItemConfigOptions) {
    this.name = name;
    this.blueprintLibraryName = blueprintLibraryName; // The name that appears for this item in blueprints.
    this.description = description;
    this.type = type;
    this.publisher = publisher;
    this.devices = devices;
    this.osRequirements = osRequirements;
    this.category = category;
    this.blueprintCategory = blueprintCategory; // Defines the category under the blueprint page for which the item falls under.
    this.icon = icon;
    this.canAdd = canAdd;
    this.identifier = identifier;
    this.routeType = routeType;
    this.getUrl = getUrl.bind(this);
    this.getAddUrl = getAddUrl;
    this.component = component.bind(this);
    this.isHidden = isHidden || (() => false);
    this.isOldPath = isOldPath?.bind(this) || (() => false);
    this.singleBlueprintAllowed = singleBlueprintAllowed;
    this.singleAssignmentMappingAllowed = singleAssignmentMappingAllowed;
  }
}

export default LibraryItemConfig;
