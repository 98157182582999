import _get from 'lodash/fp/get';
import { useEffect, useState } from 'react';

import { api } from 'app/api/base';
import { URL_AUTH_CONNECTOR_ENROLLMENTS } from 'app/api/urls';

const getConn = _get([0, 'custom_web_view', 'sso', 'auth0_connection_id']);
const getEnrollment = (id) => api(URL_AUTH_CONNECTOR_ENROLLMENTS(id)).get();

function useConnectionEnrollments({ connectionIds = [] }) {
  const [enrollments, setEnrollments] = useState({});

  useEffect(() => {
    Promise.all(connectionIds.map(getEnrollment))
      .then((res) => {
        const adeEnrollments = res.reduce(
          (acc, { data }) => ({
            ...acc,
            ...(getConn(data) && {
              [getConn(data)]: data.map(({ id, name }) => ({ id, name })),
            }),
          }),
          {},
        );
        setEnrollments(adeEnrollments);
      })
      .catch(() => null);
  }, []);

  return { enrollments };
}

export default useConnectionEnrollments;
