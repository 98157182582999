/* istanbul ignore file */
import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../service/ethernet-service';

const keys = EthernetService.keys.authentication.acceptedEapTypes;

const Pac = ({
  setting,
  isDisabled,
  update,
  showProvisionPac,
  showProvisionPacAnonym,
}) => (
  <Setting.SecondaryControlsRow>
    <Setting.Title>
      <p className="b-txt"> EAP-FAST </p>
    </Setting.Title>
    <Setting.Helpers>
      <p className="b-txt-light">
        Configure Protected Access Credentials. If using PAC, Provision PAC must
        be selected. There are known exploits of anonymous PAC provisioning.
      </p>
    </Setting.Helpers>
    <Setting.Controls className="k-ethernet__eap-fast-control">
      <Checkbox
        checked={setting[keys.usePac]}
        onChange={() => update(keys.usePac, (p) => !p)}
        disabled={isDisabled}
        label="Use PAC"
      />
    </Setting.Controls>
    {showProvisionPac && (
      <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
        <Setting.SecondaryControlsRow>
          <div className="b-grid-controls">
            <Checkbox
              checked={setting[keys.provisionPac]}
              onChange={() => update(keys.provisionPac, (p) => !p)}
              disabled={isDisabled}
              label="Provision PAC"
            />
            {showProvisionPacAnonym && (
              <Checkbox
                checked={setting[keys.provisionPacAnonymously]}
                onChange={() => update(keys.provisionPacAnonymously, (p) => !p)}
                disabled={isDisabled}
                label="Provision PAC anonymously"
              />
            )}
          </div>
        </Setting.SecondaryControlsRow>
      </Setting.SecondaryControls>
    )}
  </Setting.SecondaryControlsRow>
);

export default React.memo(Pac);
