import PropTypes from 'prop-types';
import React from 'react';
import { slugify } from '../../function';

const Bar = ({ type, offset, children, id = '' }) => (
  <aside
    id={id}
    className={`bar bar-${slugify(
      type,
    )} bar-offset-${offset} d-flex align-items-center justify-content-center sticky-top`}
  >
    {children}
  </aside>
);

Bar.propTypes = {
  type: PropTypes.string,
  offset: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Bar.defaultProps = {
  type: 'default',
  offset: 'none',
};

export default Bar;
