import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ text, href, className, internal }) => (
  <a
    className={className}
    href={href}
    target={internal ? undefined : `_blank`}
    rel={internal ? undefined : `noopener noreferrer`}
  >
    {text}
  </a>
);

Link.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  internal: PropTypes.bool,
};

Link.defaultProps = {
  href: '/',
  text: 'Learn More',
  className: 'no-class',
  internal: false,
};

export default Link;
