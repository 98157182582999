import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

import { Button, Checkbox, Chip, Toggle } from '@kandji-inc/bumblebee';

import { Setting } from 'features/library-items/template';

const SettingCard = styled(Setting.Card)`
  .preference-pane__toggle_container {
    display: flex;

    .b-toggle > input:before {
      transition: transform ease 0.3s;
    }
  }
  .b-library__setting-card__header {
    padding-bottom: 16px;
    ${(props) => !props.isActive && 'box-shadow: none;'};
  }

  .b-library__setting-rows {
    padding-bottom: 16px;

    .preference-pane__checkbox_container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      max-height: 440px;
      margin-bottom: 24px;
      box-shadow: none;

      .preference-pane__checkbox {
        padding: 8px;
      }
    }
  }
`;

const PreferencePane = (props) => {
  const { update, isDisabled, settings, title, subtitle } = props;
  const { isActive } = settings;

  const handleOnChange = (fieldValue) => {
    update('DisabledPreferencePanes', (options) =>
      options.map((opt) => {
        if (opt.value === fieldValue) {
          return {
            ...opt,
            isChecked: !opt.isChecked,
          };
        }

        return opt;
      }),
    );
  };

  const handleClearAll = () => {
    update('DisabledPreferencePanes', (options) =>
      options.map((opt) => ({
        ...opt,
        isChecked: false,
      })),
    );
  };

  const handleToggle = () => {
    update('isActive', (innerIsActive) => !innerIsActive);
  };

  return (
    <SettingCard isActive={isActive}>
      <Setting.Header>
        <h3 className="b-h3">{title}</h3>{' '}
        <div className="preference-pane__toggle_container">
          <Chip
            className="b-mr-tiny"
            kind={isActive ? 'active' : 'inactive'}
            text={isActive ? 'Active' : 'Inactive'}
          />
          <Toggle
            disabled={isDisabled}
            checked={isActive}
            onToggle={handleToggle}
          />
        </div>
      </Setting.Header>
      {isActive && (
        <>
          <Setting.SubHeader>
            <p className="b-txt">{subtitle}</p>
          </Setting.SubHeader>
          <Setting.Rows>
            <div className="preference-pane__checkbox_container">
              {settings.DisabledPreferencePanes.map((field) => (
                <Checkbox
                  className="preference-pane__checkbox"
                  key={field.value}
                  disabled={isDisabled}
                  label={field.label}
                  id={`${field.value}_checkbox`}
                  testId={`${field.value}_checkbox`}
                  checked={field.isChecked}
                  onChange={() => handleOnChange(field.value)}
                />
              ))}
            </div>
            <Button
              isDisabled={isDisabled}
              onClick={handleClearAll}
              kind="link"
            >
              Clear selection
            </Button>
          </Setting.Rows>
        </>
      )}
    </SettingCard>
  );
};

PreferencePane.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    DisabledPreferencePanes: PropTypes.array,
  }).isRequired,
};

export default memo(PreferencePane);
