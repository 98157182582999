import {
  Box,
  Flex,
  Icon,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';

import { useHistory } from 'react-router';
import { usePrismContext } from '../../contexts/PrismContext';
import { usePrismUrlContext } from '../../contexts/PrismUrlContext';
import { IMPLEMENTED_URIS } from '../../implemented-category-uris';
import { PRISM_CONTENT_HEIGHT } from '../../utils/constants';
import PrismNavItem from './PrismNavItem';
import { getIconName, isDisabled, sortPrismCategories } from './prismNavUtils';

import { usePrismAIContext } from '../../contexts/PrismAIContext';

export const PrismNavList = styled(Flex, {
  flexDirection: 'column',
  maxWidth: 273,
  minWidth: 81,
  borderRight: '1px solid $neutral30',
  pr: '$5',
  maxHeight: PRISM_CONTENT_HEIGHT,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '$1',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      background: 'rgba(243, 247, 250)',
      borderRadius: '$1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(80, 94, 113, 0.24)',
      borderRadius: '$1',
      height: '50px',
    },
  },
});

// istanbul ignore next
const NavItemContainer = ({
  children,
  showTooltip,
  tooltipContent,
}: {
  children: React.ReactNode;
  showTooltip: boolean;
  tooltipContent: string;
}) => {
  return showTooltip ? (
    <Tooltip content={tooltipContent} side="right" css={{ zIndex: 2 }}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};

export const PrismNav = () => {
  const { selectedPrismCategory, prismCategories } = usePrismContext();

  const { blueprints, deviceFamilies } = usePrismUrlContext();

  const history = useHistory();

  const { isOpen: isAiChatWindowOpen } = usePrismAIContext();

  return selectedPrismCategory ? (
    <TooltipProvider>
      <PrismNavList flow="column" gap="xs">
        {prismCategories
          ?.filter((f) => IMPLEMENTED_URIS.includes(f.uri))
          .sort(sortPrismCategories)
          .map((category) => (
            <NavItemContainer
              key={category.uri}
              showTooltip={isAiChatWindowOpen}
              tooltipContent={category.display_name}
            >
              <PrismNavItem
                onSelect={
                  /* istanbul ignore next */ () => {
                    let url = `/devices/prism/${category.uri}`;
                    // istanbul ignore next
                    if (blueprints.length > 0 || deviceFamilies.length > 0) {
                      url += '?';
                      // istanbul ignore next
                      if (blueprints.length > 0) {
                        url += `blueprints=${blueprints.join(',')}&`;
                      }
                      // istanbul ignore next
                      if (deviceFamilies.length > 0) {
                        url += `deviceFamilies=${deviceFamilies.join(',')}`;
                      }
                    }
                    // istanbul ignore next
                    history.push(url);
                  }
                }
                selected={selectedPrismCategory.uri === category.uri}
                disabled={isDisabled(deviceFamilies, category.uri)}
                uri={category.uri}
              >
                <Icon name={getIconName(category.uri)} />
                {!isAiChatWindowOpen && (
                  <Box id="prism-nav-label">{category.display_name}</Box>
                )}
              </PrismNavItem>
            </NavItemContainer>
          ))}
      </PrismNavList>
    </TooltipProvider>
  ) : null;
};
