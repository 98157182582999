/* istanbul ignore file */
import { create } from 'zustand';
import type {
  DetectionDateFilterFields,
  SelectedVulnerability,
} from './vulnerability.types';

type State = {
  selectedVulnerability: SelectedVulnerability | null;
  filter: {
    term: string;
    severity: string[];
    firstDetected: DetectionDateFilterFields;
    latestDetected: DetectionDateFilterFields;
  };
};

type Action = {
  setSelectedVulnerability: (vulnerability: SelectedVulnerability) => void;
  setFilter: (filter: Partial<State['filter']>) => void;
};

const defaultDateFilter: DetectionDateFilterFields = {
  value: '',
  operator: '',
  dateRangeFrom: '',
  dateRangeTo: '',
  selectedDate: '',
};

const defaultFilter = {
  term: '',
  severity: [],
  firstDetected: null,
  latestDetected: null,
};

const useVulnerability = create<Partial<State> & Partial<Action>>((set) => ({
  selectedVulnerability: null,
  filter: defaultFilter,

  setSelectedVulnerability: (vulnerability) =>
    set(() => ({ selectedVulnerability: vulnerability })),
  setFilter: (filter) =>
    set((state) => ({ filter: { ...state.filter, ...filter } })),
}));

export type { State as VulnerabilityState };
export { defaultDateFilter, defaultFilter };
export default useVulnerability;
