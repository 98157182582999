import { Box, type CSS, Checkbox } from '@kandji-inc/nectar-ui';
import type React from 'react';

const CheckboxCell = ({
  disabled,
  selection,
  isHeader,
  row,
  rowId,
  table,
  onCheckedChange,
}: {
  disabled?: boolean;
  selection: string[];
  isHeader: boolean;
  row: any;
  rowId: string;
  table: any;
  onCheckedChange?: ({ checked, row, rows, isHeader }) => void;
}) => {
  const rows = table.getRowModel().rows;
  const allSelected =
    rows?.length > 0 &&
    rows?.every((row) => selection.includes(row.original[rowId]));
  const someSelected = rows?.some((row) =>
    selection.includes(row.original[rowId]),
  );
  const headerChecked = allSelected || (someSelected ? 'indeterminate' : false);
  const checked = isHeader
    ? headerChecked
    : Boolean(selection.includes(row.original[rowId]));
  return (
    <Box>
      <Checkbox
        data-testid={`checkbox-${isHeader ? 'header' : row.original[rowId]}`}
        checked={checked}
        onCheckedChange={() =>
          onCheckedChange && onCheckedChange({ checked, row, rows, isHeader })
        }
        disabled={onCheckedChange === undefined || disabled}
      />
    </Box>
  );
};

export type TableColumn = {
  id: string;
  headerCell?: React.FunctionComponent<any>;
  cell?: React.FunctionComponent<any>;
  isSortable?: boolean;
  meta?: {
    header?: {
      css?: CSS;
    };
    row?: {
      css?: CSS;
    };
  };
};

export const SelectionColumn = ({
  id = 'selection',
  rowId = 'id',
  onCheckedChange,
  selection,
}): TableColumn => {
  return {
    id,
    headerCell: (props) => {
      return (
        <CheckboxCell
          {...props}
          isHeader
          rowId={rowId}
          selection={selection}
          onCheckedChange={onCheckedChange}
        />
      );
    },
    cell: (props) => {
      return (
        <CheckboxCell
          {...props}
          rowId={rowId}
          selection={selection}
          onCheckedChange={onCheckedChange}
        />
      );
    },
    isSortable: false,
    meta: {
      header: {
        css: {
          padding: '$2 $3 $2 $3',
          width: 40,
        },
      },
      row: {
        css: {
          padding: '$3',
        },
      },
    },
  };
};
