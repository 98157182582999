import { node, object, string } from 'prop-types';
import React from 'react';
import { formatTime } from 'src/app/components/common/helpers';
import styled from 'styled-components';
import { EVENT_ACTION_NAME_MAPPING } from '../../../utils';
import { withBorder } from '../../styled-components/index';

const StyledExpandedDetailsRow = styled.div`
  padding: 18px 60px 18px 0;
  display: flex;
  ${withBorder};

  div {
    padding: 0 24px;

    &:nth-of-type(1) {
      width: calc(236 / 950 * 100%);
    }

    &:nth-of-type(2) {
      width: calc(714 / 950 * 100%);
    }
  }
`;

const StyledRowDetails = styled.div`
  background: #fafcff;
  padding-left: 110px;
`;

const StyledEventNotifications = styled.p`
  margin: 0;
`;

const DetailsRow = ({ title, description }) => (
  <StyledExpandedDetailsRow>
    <div className="b-txt-bold">{title}</div>
    <div className="b-txt-light">{description}</div>
  </StyledExpandedDetailsRow>
);

DetailsRow.propTypes = {
  title: string.isRequired,
  description: node.isRequired,
};

const ExpandedNotificationRow = ({ item }) => {
  const { events, created_by, created_at } = item;

  return (
    <StyledRowDetails>
      <DetailsRow title="Created by" description={created_by} />
      <DetailsRow title="Created on" description={formatTime(created_at)} />
      <DetailsRow
        title="Event triggers"
        description={events.map((event) => (
          <StyledEventNotifications key={event.id}>
            {EVENT_ACTION_NAME_MAPPING[event.name]}
          </StyledEventNotifications>
        ))}
      />
    </StyledRowDetails>
  );
};

ExpandedNotificationRow.propTypes = {
  item: object.isRequired,
};

export default ExpandedNotificationRow;
