import { Banner, Button, Flex } from '@kandji-inc/bumblebee';
import {
  STATUS_CONNECTED,
  STATUS_PENDING,
} from 'features/integrations/components-for-specific-types/adcs/status';
import { bool, func, string } from 'prop-types';
import React from 'react';
import './banners.styles.scss';

const SHARED_PROP_TYPES = {
  onClose: func.isRequired,
  onClick: func.isRequired,
  isVisible: bool.isRequired,
};

function ADCSBanner({
  isVisible,
  onClick,
  onClose,
  text,
  whatToManage,
  whatToClose,
  theme,
}) {
  if (!isVisible) {
    return null;
  }
  return (
    <Banner theme={theme} kind="block" className="adcs-banner b-mt">
      <Flex align="center" justify="space-between">
        <div>{text}</div>

        <Flex align="center" className="b-ml3">
          <Button onClick={onClick} size="small">
            Manage {whatToManage}
          </Button>
          <div className="adcs-banner_close-wrapper" onClick={onClose}>
            <i
              aria-label={`Close ${whatToClose} Banner`}
              className="far fa-times"
            />
          </div>
        </Flex>
      </Flex>
    </Banner>
  );
}

ADCSBanner.propTypes = {
  ...SHARED_PROP_TYPES,
  text: string.isRequired,
  whatToManage: string.isRequired,
  whatToClose: string.isRequired,
  theme: string.isRequired,
};

export function ADCSPendingBanner(props) {
  const hasSinglePendingConnectorWithNoAuthorities =
    props?.connectorList?.length === 1 &&
    props.connectorList[0].status === STATUS_PENDING &&
    props.connectorList[0].adcs_authorities.length === 0;

  return (
    <ADCSBanner
      {...props}
      isVisible={props.isVisible && hasSinglePendingConnectorWithNoAuthorities}
      whatToManage="servers"
      text={
        'You can start adding AD CS servers while the integration' +
        ' with Active Directory Certificate Services is still pending.'
      }
      whatToClose="Pending"
      theme="info-strong"
    />
  );
}

ADCSPendingBanner.propTypes = { ...SHARED_PROP_TYPES };

export function ADCSConnectSuccessBanner(props) {
  const hasSingleConnectedConnectorWithNoAuthorities =
    props?.connectorList?.length === 1 &&
    props.connectorList[0].status === STATUS_CONNECTED &&
    props.connectorList[0].adcs_authorities.length === 0;

  return (
    <ADCSBanner
      {...props}
      isVisible={
        props.isVisible && hasSingleConnectedConnectorWithNoAuthorities
      }
      whatToManage="integration"
      text={
        'Your integration with AD CS was successfully connected. You will now ' +
        'be able to assign servers to an AD CS connector.'
      }
      whatToClose="Connect Success"
      theme="success"
    />
  );
}

ADCSConnectSuccessBanner.propTypes = { ...SHARED_PROP_TYPES };
