import { Chip, Toggle } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import ManagedDomainsTable from './managed-domains-table';

const ManagedDomains = (props) => {
  const { setting, update, isDisabled, isSaving, validationDep } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Managed domains</h3>
        <div className="b-flex">
          <Chip
            disabled={isDisabled}
            className="b-mr-tiny"
            text={setting.isUsingManagedDomains ? 'Managed' : 'Not managed'}
            kind={setting.isUsingManagedDomains ? 'active' : 'primary'}
          />
          <Toggle
            disabled={isDisabled}
            checked={setting.isUsingManagedDomains}
            onToggle={() => update('isUsingManagedDomains', (p) => !p)}
          />
        </div>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Provide a list of domains to manage. For each domain specify if it is
          managed for email, Safari password autofill, and web.
        </p>
      </Setting.SubHeader>

      {setting.isUsingManagedDomains && (
        <ManagedDomainsTable
          isDisabled={isDisabled}
          isSaving={isSaving}
          setting={setting.domains}
          update={update}
          validationDep={validationDep}
        />
      )}
    </Setting.Card>
  );
};

export default ManagedDomains;
