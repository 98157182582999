import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { api, enterpriseExternalApi, integrationsApi } from 'app/api/base';
import { useEffect, useState } from 'react';

import { formatDisplayValue } from './library/tags/tag-transformers';
import { ruleService } from './rule-service';
import { transformFacetDataFromApi } from './transformers';

/**
 * Retrieves and transforms facets from the API
 * @returns a map of facets indexed by facet key
 */
const useGetFacets = () => {
  const [facets, setFacets] = useState({});

  useEffect(() => {
    ruleService
      .get()
      .then((res) => {
        const transformedData = transformFacetDataFromApi(res.data);
        setFacets(transformedData);
      })
      .catch(() => toaster('Unable to fetch facets.'));
  }, []);

  return facets;
};

/**
 * Retrieves and transforms facets from the API depending on whether or not the library item is eligible
 * @param {Boolean} itemSupportsRules
 * @returns a map of facets indexed by facet key
 */
const useGetFacetsForLibraryItem = (itemSupportsRules = false) => {
  const [facets, setFacets] = useState({});

  useEffect(() => {
    if (itemSupportsRules) {
      ruleService
        .get()
        .then((res) => {
          const transformedData = transformFacetDataFromApi(res.data);
          setFacets(transformedData);
        })
        .catch(() => toaster('Unable to fetch facets.'));
    }
  }, []);

  return facets;
};

/**
 * Gets the correct client to call to retrieve dynamic display names. Calls the base API by default.
 * @param {string} type - the type of API we call. currently only 'integrations' is supported
 * @param {string} endpoint - the endpoint to call on the provided type of API
 * @returns the correct client to call to retrieve dynamic display names (if any)
 */
const getValueClient = (type, endpoint) => {
  switch (type) {
    case 'integrations':
      return integrationsApi(endpoint);
    case 'enterprise-api':
      /* istanbul ignore next */
      return enterpriseExternalApi(endpoint);
    case 'api':
    default:
      return api(endpoint);
  }
};

/* istanbul ignore next */
const getDisplayValue = async (value, jsonLogicOperator, facetData) => {
  try {
    let unformattedValue = value;

    // If values are ids with dynamic display names, retrieve the display names from the API
    if (facetData.api) {
      const { type, endpoint } = facetData.api;
      const client = getValueClient(type, endpoint);
      const params = {
        id__in: value.join(','),
        sizePerPage: value.length,
      };

      const resp = await client.get(params);
      unformattedValue = resp.data.results.map((result) => result.name);
    }

    // Format the display value to be human readable
    return formatDisplayValue(unformattedValue, jsonLogicOperator, facetData);
  } catch (e) {
    toaster('Unable to fetch display names.');
  }

  return null;
};

/**
 * A hook to handle how the display values for rules values are retrieved and formatted
 * @returns the formatted display value and the function to retrieve the display value
 */
const useGetDisplayValue = () => {
  const [displayValue, setDisplayValue] = useState('');

  /**
   * Gets the rule value and formats it into a human-readable string
   * @param {string} value - the string value in a JsonLogic rule
   * @param {string} jsonLogicOperator - the oeprator in a JsonLogic rule that helps
   *                                     determine how the value should be formatted
   * @param {object} facetData - the facet information related to the particular value
   */
  const getDisplayValue = async (value, jsonLogicOperator, facetData) => {
    try {
      let unformattedValue = value;

      // If values are ids with dynamic display names, retrieve the display names from the API
      if (facetData.api) {
        const { type, endpoint } = facetData.api;
        const client = getValueClient(type, endpoint);
        const params = {
          id__in: value.join(','),
          sizePerPage: value.length,
        };

        const resp = await client.get(params);
        unformattedValue = resp.data.results.map((result) => result.name);
      }

      // Format the display value to be human readable
      setDisplayValue(
        formatDisplayValue(unformattedValue, jsonLogicOperator, facetData),
      );
    } catch (e) {
      toaster('Unable to fetch display names.');
    }
  };

  return { displayValue, getDisplayValue };
};

export {
  useGetFacets,
  useGetFacetsForLibraryItem,
  getValueClient,
  useGetDisplayValue,
  getDisplayValue,
};
