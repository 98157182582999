import { bool, number, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import circleBellIcon from '../../../assets/bell-filled-circle-icon.svg';
import msTeamsIcon from '../../../assets/teams-circle-icon.svg';

const ModalContent = styled.div`
  padding: 0;
  display: grid;
  width: 496px;
  grid-gap 16px;
`;
const DeleteTeamsBanner = styled.div`
  display: grid-inline;
  padding: 10px 16px;
  border-radius: 4px;
  align-items: center;
  grid-gap: 8px;
  grid-template columns: auto auto;
`;

const Content = (props) => {
  const { notificationCount, name, isLastIntegration } = props;

  let DeleteWarningBanner = () => '';
  let extraDeleteInfo = '.';
  let TeamsInfoBanner = () => '';

  if (isLastIntegration) {
    DeleteWarningBanner = () => (
      <DeleteTeamsBanner
        className="b-kandji-banner__icon banner-warning"
        data-testid="deleteTeamsBanner"
      >
        <i className=" bi bi-exclamation-triangle" />
        <p className="b-txt">
          Deleting the only Teams account will also delete the integration.
        </p>
      </DeleteTeamsBanner>
    );
    extraDeleteInfo =
      ', and remove the entire integration\n' +
      'with Microsoft Teams from your Kandji instance.';
    TeamsInfoBanner = () => (
      <div className="b-kandji-banner b-kandji-banner--info b-kandji-banner">
        <img src={msTeamsIcon} alt="MS Teams Icon" />
        <p className="b-txt">
          <b className="b-txt-bold">Microsoft Teams integration</b> will be
          deleted.
        </p>
      </div>
    );
  }

  return (
    <ModalContent>
      <DeleteWarningBanner />
      <p className="b-txt">
        Are you sure you would like to delete{' '}
        <b className="b-txt-bold">{name}</b>?
      </p>
      <p className="b-txt">
        Deleting this integration will remove any event notifications that have
        been configured under this account{extraDeleteInfo} This action cannot
        be undone.
      </p>

      <div className="b-kandji-banner b-kandji-banner--info b-kandji-banner">
        <img src={circleBellIcon} alt="bell icon" />
        <p className="b-txt">
          <b className="b-txt-bold">{notificationCount} event notification</b>{' '}
          will be removed.
        </p>
      </div>
      <TeamsInfoBanner />
    </ModalContent>
  );
};

export default Content;

Content.propTypes = {
  name: string.isRequired,
  isLastIntegration: bool.isRequired,
  notificationCount: number.isRequired,
};
