import {
  Badge,
  Banner,
  Checkbox,
  Flex,
  Heading,
  Paragraph,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';

import { Toaster as toaster } from '@kandji-inc/bumblebee';

import { DataContext } from 'contexts/data';
import React, { useContext, useState } from 'react';
import type colors from '../../../../../../blueprints/colors';
import FooterButtons from '../../../../../components/footer-buttons';
import { Container } from '../../../../../components/styled-components';
import BlueprintIcon from '../../../components/blueprint-icon';
import {
  useGetOktaDeviceTrust,
  useUpdateOktaDeviceTrust,
} from '../../../hooks';
import useMapBlueprints from '../../../hooks/use-map-blueprints';
import type { DeviceType } from '../../../types';
import { getDevicePlatformDataToDelete } from '../utils/get-device-platform-data-to-delete';

type Props = {
  onClose: () => void;
  blueprints: string[] | undefined;
  oktaDeviceTrustId: string;
  type: DeviceType;
};

type DataContext = {
  appData?: AppData;
  appDataReady: boolean;
  setAppDataReady: () => void;
  getBlueprints: () => void;
  getParamMeta: () => void;
};

type AppData = {
  blueprints: Blueprint[];
};

export type Blueprint = {
  id: string;
  color: keyof typeof colors;
  computersCount: number;
  icon: string;
  name: string;
};

const StyledContainer = styled(Container, {
  display: 'grid',
  gap: '$4',
  padding: '48px 32px',
  textAlign: 'left',
});

const BadgeWrapper = styled('div', {
  marginLeft: 'auto',
});

const BlueprintContainer = styled('div', {
  display: 'grid',
  gap: '$4',
  maxHeight: '200px',
  overflowY: 'auto',
});

const BlueprintRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: '24px 200px auto',
  gap: '$2',
  alignItems: 'center',
});
const BlueprintRowBadgeContainer = styled('div', {});

const DeleteDevicePlatform = (props: Props) => {
  const { onClose, blueprints: odtBlueprints, oktaDeviceTrustId, type } = props;

  const [isConfirmedChecked, setIsConfirmedChecked] = useState(false);

  const { getOktaDeviceTrustDetails } = useGetOktaDeviceTrust();

  // get blueprints from redux store since these are already loaded
  const { appData } = useContext(DataContext) as DataContext;

  const blueprintsList = appData?.blueprints;

  const { odtBlueprintsWithMetaData } = useMapBlueprints(
    odtBlueprints,
    blueprintsList,
  );

  const blueprintCount = odtBlueprintsWithMetaData?.length;

  const { updateOktaDeviceTrust } = useUpdateOktaDeviceTrust();

  const bannerText = odtBlueprints
    ? `By removing this device platform from the integration, Okta Device Trust certificates will no longer be deployed to ${type} devices.`
    : `By removing this device platform from the integration, Okta Device Trust cannot be deployed to ${type} devices.`;

  const handleSubmit = async () => {
    const deviceTypeDeleteData = getDevicePlatformDataToDelete(type);

    if (deviceTypeDeleteData) {
      try {
        await updateOktaDeviceTrust(deviceTypeDeleteData, oktaDeviceTrustId);
        toaster(`${type} device platform settings deleted.`);
        await getOktaDeviceTrustDetails();
        onClose();
      } catch (e) {
        toaster('Something went wrong.');
      }
    }
  };

  const handleSetIsConfirmedChecked = () => {
    setIsConfirmedChecked((prevState) => !prevState);
  };

  return (
    <>
      <StyledContainer>
        <Heading as="h2" size="2" data-testid="delete-device:header">
          Are you sure you want to delete {type}?
        </Heading>
        {!odtBlueprints?.length && (
          <Text>
            The{' '}
            <Text css={{ fontWeight: '$semibold', display: 'inline-block' }}>
              {type} device platform
            </Text>{' '}
            {type} configuration will be permanently removed. This action cannot
            be undone.
          </Text>
        )}

        <Banner
          icon="triangle-exclamation"
          theme="warning"
          text={
            <Text>
              {bannerText}{' '}
              <a
                href="https://support.kandji.io/support/solutions/articles/72000580342"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </Text>
          }
        />

        {!!odtBlueprints?.length && (
          <>
            <Paragraph>
              <Text css={{ fontWeight: '$semibold', display: 'inline-block' }}>
                Okta Verify
              </Text>{' '}
              has the Okta Device Trust integration turned on. By removing this
              device platform configuration, we will remove all settings for the
              platform type on the Library item and the integration.{' '}
            </Paragraph>
            <Paragraph>
              Okta Verify will remain installed on {type} devices, but device
              trust will be removed upon next check-in.{' '}
            </Paragraph>

            <div>
              <Flex alignItems="center" css={{ paddingBottom: '$3' }}>
                <Heading as="h5" size="5">
                  The following blueprints include {type} devices:
                </Heading>
                <BadgeWrapper>
                  <Badge color="neutral">{blueprintCount} Blueprints</Badge>
                </BadgeWrapper>
              </Flex>
              <BlueprintContainer>
                {odtBlueprintsWithMetaData?.map((blueprint) => (
                  <BlueprintRow key={blueprint.id}>
                    <BlueprintIcon
                      color={blueprint.color}
                      iconName={blueprint.icon}
                    />
                    <Text
                      css={{ fontWeight: '$medium', display: 'inline-block' }}
                    >
                      {blueprint.name}
                    </Text>
                    <BlueprintRowBadgeContainer>
                      <Badge color="neutral">
                        {blueprint.computersCount} Devices
                      </Badge>
                    </BlueprintRowBadgeContainer>
                  </BlueprintRow>
                ))}
              </BlueprintContainer>
            </div>
            <Heading as="h5" size="5">
              Are you sure you want to delete {type} as a device platform?
            </Heading>
            <Checkbox
              data-testid="confirm-checkbox"
              checked={isConfirmedChecked}
              onClick={handleSetIsConfirmedChecked}
              label="I understand, and would like to proceed"
            />
          </>
        )}
      </StyledContainer>
      <FooterButtons
        onClose={onClose}
        handleNext={handleSubmit}
        nextButtonDisabled={!!odtBlueprints?.length && !isConfirmedChecked}
        cancelButtonText="Cancel"
        nextButtonText="Delete device platform"
        theme="danger"
        showBackButton={false}
      />
    </>
  );
};

export default DeleteDevicePlatform;
