import { isEmpty } from 'lodash';
import { formatTime } from '../common/helpers';

const replaceBooleanField = (key, value) => {
  const yesNoList = [
    'IsSupervised',
    'AwaitingConfiguration',
    'IsActivationLockSupported',
    'IsMDMLostModeEnabled',
    'IsMultiUser',
    'PersonalHotspotEnabled',
    'IsRoaming',
    'DataRoamingEnabled',
    'VoiceRoamingEnabled',
    'IsNetworkTethered',
    'AppAnalyticsEnabled',
    'DiagnosticSubmissionEnabled',
    'IsCloudBackupEnabled',
    'IsDeviceLocatorServiceEnabled',
    'IsDoNotDisturbInEffect',
    'PasscodePresent',
    'PasscodeCompliant',
    'PasscodeCompliantWithProfiles',
    'PINRequiredForDeviceLock',
    'PINRequiredForEraseDevice',
  ];
  const activeDisabledList = [];
  if (yesNoList.includes(key)) {
    return value === true ? 'Yes' : 'No';
  }
  if (activeDisabledList.includes(key)) {
    return value === true ? 'Active' : 'Disabled';
  }
  return value === true ? 'True' : 'False';
};

export const checkField = (key, value, type = 'default') => {
  let checkedValue = value;
  switch (typeof checkedValue) {
    case 'boolean':
      checkedValue = replaceBooleanField(key, value);
      break;
    case 'string':
      if (type === 'date') {
        checkedValue = formatTime(value, null, null, null, true);
      }
      if (type === 'uppercase') {
        checkedValue = value.toUpperCase();
      }
      break;
    case 'number':
      if (type === 'encryptChoice') {
        if (value === 1) {
          checkedValue = 'Block-level encryption';
        } else if (value === 2) {
          checkedValue = 'File-level encryption';
        } else if (value === 3) {
          checkedValue = 'Block-level and file-level encryption';
        }
      }
      if (type === 'seconds') {
        checkedValue = `${value} seconds`;
      }
      if (type === 'gsmChoice') {
        checkedValue = 'None';
        if (value === 1) {
          checkedValue = 'GSM';
        } else if (value === 2) {
          checkedValue = 'GSM';
        } else if (value === 3) {
          checkedValue = 'GSM and CDMA';
        }
      }
      if (type === 'percentage') {
        checkedValue = `${(value * 100).toFixed()}%`;
      }
      if (type === 'gigabyte') {
        checkedValue = `${value.toFixed(2)} GB`;
      }
      break;
    default:
      checkedValue = 'No info';
      break;
  }
  return checkedValue;
};

export const getSystemVersion = (
  computer,
  showFamily = true,
  showVersion = true,
  showVersionExtra = true,
  showBuild = true,
  noInfoText = '',
) => {
  const computerInfo = { ...computer.info } || {};
  const { os_version } = computer;
  // Use supplemental_build_version if we have it, otherwise fallback to build_version
  const build_version =
    computer.supplemental_build_version || computer.build_version;

  let text = '';
  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
      text += showFamily ? 'iOS ' : '';
      break;
    case 'iPad':
      text += showFamily
        ? `${computer.os_version < '13.0' ? 'iOS ' : 'iPadOS '}`
        : '';
      break;
    case 'Mac':
      text += showFamily
        ? `${computer.os_version < '10.12' ? 'OS X ' : 'macOS '}`
        : '';
      break;
    case 'AppleTV':
      text += showFamily ? 'tvOS ' : '';
      break;
    case 'RealityDevice':
      text += showFamily ? 'visionOS ' : '';
      break;

    default:
      break;
  }
  text += showVersion && os_version ? os_version : '';
  text +=
    showVersionExtra && computer.supplemental_os_version_extra
      ? ` ${computer.supplemental_os_version_extra}`
      : '';
  text += showBuild && build_version ? ` (${build_version})` : '';
  return text || noInfoText;
};

export const getDeviceModel = (computer) => {
  const computerInfo = { ...computer.info } || {};
  const model = computer.model || computerInfo.Model;
  const modelID = computerInfo['Model Identifier'];

  if (modelID && modelID.includes('VMware', 'Parallels', 'VirtualBox')) {
    return 'Virtual Machine';
  }

  return model;
};

export const getMDMEnabledUsers = (computer) => {
  const { device_users_list: deviceUsersList } = computer;

  if (!isEmpty(deviceUsersList)) {
    const formattedDeviceUsersList = [];

    // List names in one of the following formats, depending on what is available:
    // long_name (short_name) OR long_name OR short_name
    deviceUsersList.forEach((user) => {
      const { full_name, user_name } = user;
      if (full_name && user_name) {
        formattedDeviceUsersList.push(`${full_name} (${user_name})`);
      } else if (full_name) {
        formattedDeviceUsersList.push(full_name);
      } else if (user_name) {
        formattedDeviceUsersList.push(user_name);
      }
    });

    return formattedDeviceUsersList.join(', ');
  }
  return 'No info';
};

export const getAgentLastCheckIn = (computer) =>
  [
    computer.last_checkins['agent-library'],
    computer.last_checkins['agent-report'],
  ]
    .filter((el) => el != null)
    .sort()
    .reverse()[0];

export const iOSDeviceInformationMapping = {
  General: {
    DeviceName: {
      title: 'Device Name',
      weight: 1,
      type: 'default',
    },
    OSVersion: {
      title: 'OS Version',
      weight: 2,
      type: 'default',
    },
    BuildVersion: {
      title: 'OS Build',
      weight: 3,
      type: 'default',
    },
    ModelName: {
      title: 'Model Name',
      weight: 4,
      type: 'default',
    },
    ProductName: {
      title: 'Model Identifier',
      weight: 5,
      type: 'default',
    },
    Model: {
      title: 'Model Number',
      weight: 6,
      type: 'default',
    },
    SerialNumber: {
      title: 'Serial Number',
      weight: 7,
      type: 'default',
    },
    UDID: {
      title: 'UDID',
      weight: 8,
      type: 'default',
    },
    EASDeviceIdentifier: {
      title: 'EAS Device Identifier',
      weight: 9,
      type: 'default',
    },
    TimeZone: {
      title: 'TimeZone',
      weight: 10,
      type: 'default',
    },
  },
  Hardware: {
    BatteryLevel: {
      title: 'Battery Level',
      weight: 1,
      type: 'percentage',
    },
    AvailableDeviceCapacity: {
      title: 'Available Device Capacity',
      weight: 2,
      type: 'gigabyte',
    },
    DeviceCapacity: {
      title: 'Device Capacity',
      weight: 3,
      type: 'gigabyte',
    },
    WiFiMAC: {
      title: 'Wi-Fi Address',
      weight: 4,
      type: 'uppercase',
    },
    BluetoothMAC: {
      title: 'Bluetooth Address',
      weight: 5,
      type: 'uppercase',
    },
    CellularTechnology: {
      title: 'Cellular Technology',
      weight: 6,
      type: 'gsmChoice',
    },
    ModemFirmwareVersion: {
      title: 'Modem Firmware',
      dependency: 'CellularTechnology',
      wrongValueDependency: [0],
      weight: 7,
      type: 'default',
    },
  },
  Management: {
    IsSupervised: {
      title: 'Supervised',
      weight: 1,
      type: 'default',
    },
    AwaitingConfiguration: {
      title: 'Awaiting Configuration',
      weight: 2,
      type: 'default',
    },
    IsMDMLostModeEnabled: {
      title: 'MDM Lost Mode Enabled',
      weight: 5,
      type: 'default',
    },
    PINRequiredForDeviceLock: {
      title: 'PIN Required For Device Lock',
      weight: 7,
      type: 'default',
    },
    PINRequiredForEraseDevice: {
      title: 'PIN Required For Erase Device',
      weight: 8,
      type: 'default',
    },
  },
  Cellular: {
    PhoneNumber: {
      title: 'Phone Number',
      weight: 1,
      type: 'default',
    },
    IMEI: {
      title: 'IMEI',
      weight: 2,
      type: 'default',
    },
    ICCID: {
      title: 'ICCID',
      weight: 3,
      type: 'default',
    },
    MEID: {
      title: 'MEID',
      weight: 4,
      type: 'default',
    },
    PersonalHotspotEnabled: {
      title: 'Personal Hotspot Enabled',
      weight: 5,
      type: 'default',
    },
    IsRoaming: {
      title: 'Roaming Enabled',
      weight: 6,
      type: 'default',
    },
    DataRoamingEnabled: {
      title: 'Data Roaming Enabled',
      weight: 7,
      type: 'default',
    },
    VoiceRoamingEnabled: {
      title: 'Voice Roaming Enabled',
      weight: 8,
      type: 'default',
    },
    CurrentCarrierNetwork: {
      title: 'Current Carrier Network',
      weight: 9,
      type: 'default',
    },
    CarrierSettingsVersion: {
      title: 'Carrier Settings Version',
      weight: 10,
      type: 'default',
    },
    CurrentMCC: {
      title: 'Current MCC',
      weight: 11,
      type: 'default',
    },
    CurrentMNC: {
      title: 'Current MNC',
      weight: 12,
      type: 'default',
    },
    SubscriberCarrierNetwork: {
      title: 'Subscriber Carrier Network',
      weight: 13,
      type: 'default',
    },
    SubscriberMCC: {
      title: 'Subscriber MCC',
      weight: 14,
      type: 'default',
    },
    SubscriberMNC: {
      title: 'Subscriber MNC',
      weight: 15,
      type: 'default',
    },
    SIMCarrierNetwork: {
      title: 'SIM Carrier Network',
      weight: 16,
      type: 'default',
    },
    SIMMCC: {
      title: 'SIM MCC',
      weight: 17,
      type: 'default',
    },
    SIMMNC: {
      title: 'SIM MNC',
      weight: 18,
      type: 'default',
    },
    ServiceSubscriptions: {
      title: 'Service Subscriptions',
      weight: 19,
      type: 'multiLineListOutput',
      list: {
        CarrierSettingsVersion: 'Carrier Settings Version',
        CurrentCarrierNetwork: 'Current Carrier Network',
        CurrentMCC: 'Current MCC',
        CurrentMNC: 'Current MNC',
        IMEI: 'IMEI',
        ICCID: 'ICCID',
        IsDataPreferred: 'Data Preferred',
        IsRoaming: 'Roaming',
        IsVoicePreferred: 'Voice Preferred',
        Label: 'Label',
        LabelID: 'Label ID',
        MEID: 'MEID',
        PhoneNumber: 'Phone Number',
        Slot: 'Slot',
        EID: 'eSIM ID',
      },
    },
  },
  Additional: {
    IsNetworkTethered: {
      title: 'Network Tethered',
      weight: 1,
      type: 'default',
    },
    AppAnalyticsEnabled: {
      title: 'App Analytics Enabled',
      weight: 2,
      type: 'default',
    },
    DiagnosticSubmissionEnabled: {
      title: 'Diagnostic Submission Enabled',
      weight: 3,
      type: 'default',
    },
    IsCloudBackupEnabled: {
      title: 'iCloud Backup Enabled',
      weight: 4,
      type: 'default',
    },
    LastCloudBackupDate: {
      title: 'Last iCloud Backup Date',
      weight: 5,
      type: 'default',
    },
    IsDeviceLocatorServiceEnabled: {
      title: 'Location Services Enabled',
      weight: 6,
      type: 'default',
    },
    IsDoNotDisturbInEffect: {
      title: 'Do Not Disturb In Effect',
      weight: 7,
      type: 'default',
    },
    iTunesStoreAccountIsActive: {
      title: 'iTunes Store Account Active',
      weight: 8,
      type: 'default',
    },
  },
  'Shared iPad': {
    IsMultiUser: {
      title: 'Shared iPad',
      weight: 1,
      type: 'default',
    },
    setResidentUsers: {
      title: 'Maximum Number of Shared iPad Users',
      weight: 2,
      type: 'default',
    },
    EstimatedResidentUsers: {
      title: 'Estimated Resident Users',
      weight: 3,
      type: 'default',
    },
  },
};

export const tvOSDeviceInformationMapping = {
  General: {
    DeviceName: {
      title: 'Device Name',
      weight: 1,
      type: 'default',
    },
    OSVersion: {
      title: 'OS Version',
      weight: 2,
      type: 'default',
    },
    BuildVersion: {
      title: 'OS Build',
      weight: 3,
      type: 'default',
    },
    ModelName: {
      title: 'Model Name',
      weight: 4,
      type: 'default',
    },
    ProductName: {
      title: 'Model Identifier',
      weight: 5,
      type: 'default',
    },
    Model: {
      title: 'Model Number',
      weight: 6,
      type: 'default',
    },
    SerialNumber: {
      title: 'Serial Number',
      weight: 7,
      type: 'default',
    },
    UDID: {
      title: 'UDID',
      weight: 8,
      type: 'default',
    },
    DeviceID: {
      title: 'Device Identifier',
      weight: 9,
      type: 'default',
    },
  },
  Hardware: {
    WiFiMAC: {
      title: 'Wi-Fi Address',
      weight: 1,
      type: 'uppercase',
    },
    BluetoothMAC: {
      title: 'Bluetooth Address',
      weight: 2,
      type: 'uppercase',
    },
  },
  Management: {
    IsSupervised: {
      title: 'Supervised',
      weight: 1,
      type: 'default',
    },
    AwaitingConfiguration: {
      title: 'Awaiting Configuration',
      weight: 2,
      type: 'default',
    },
  },
  Additional: {
    iTunesStoreAccountIsActive: {
      title: 'iTunes Store Account Active',
      weight: 1,
      type: 'default',
    },
  },
};

export const SecurityMapping = {
  HardwareEncryptionCaps: {
    title: 'Hardware Encryption Capabilities',
    weight: 1,
    type: 'encryptChoice',
  },
  PasscodePresent: {
    title: 'Passcode Present',
    weight: 2,
    type: 'default',
  },
  PasscodeCompliant: {
    title: 'Passcode Compliant',
    weight: 3,
    type: 'default',
  },
  PasscodeCompliantWithProfiles: {
    title: 'Passcode Compliant With Installed Profiles',
    weight: 4,
    type: 'default',
  },
  PasscodeLockGracePeriodEnforced: {
    title: 'Passcode Lock Grace Period (Enforced)',
    weight: 5,
    type: 'seconds',
  },
  PasscodeLockGracePeriod: {
    title: 'Passcode Lock Grace Period (User-Defined)',
    weight: 6,
    type: 'seconds',
  },
};

export const ABMMapping = {
  color: {
    title: 'Color',
    weight: 2,
    type: 'default',
  },
  description: {
    title: 'Description',
    weight: 3,
    type: 'default',
  },
  device_assigned_by: {
    title: 'Device Assigned By',
    weight: 8,
    type: 'default',
  },
  device_assigned_date: {
    title: 'Device Assigned Date',
    weight: 7,
    type: 'date',
  },
  device_family: {
    title: 'Device Family',
    weight: 5,
    type: 'default',
  },
  model: {
    title: 'Model',
    weight: 1,
    type: 'default',
  },
  os: {
    title: 'OS',
    weight: 6,
    type: 'default',
  },
  serial_number: {
    title: 'Serial Number',
    weight: 4,
    type: 'default',
  },
};

export const getDeviceGeneralInfo = (computer) => {
  let info = {};
  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
    case 'iPad':
    case 'AppleTV':
      break;
    case 'Mac':
      const computerInfo = { ...computer.info } || {};
      info = {
        ...info,
        ...{
          'Device Name': computerInfo['Computer Name'] || computer.name,
          'Device ID': computer.id,
          'Last Enrollment': computer.lastEnrollmentNotFormatted,
          'First Enrollment': computer.firstEnrollmentNotFormatted,
          Model: getDeviceModel(computer),
          'OS Version': getSystemVersion(computer, false, true, true, false),
          'System Version': getSystemVersion(computer),
          'Boot Volume': computerInfo['Boot Volume'],
          'Time Since Boot': computerInfo['Time since boot'],
          'Last User': computerInfo['User Name'],
        },
      };
      break;
    default:
      info = {};
      break;
  }
  return info;
};

export const getDeviceProfileInfo = (computer) => {
  const info = [];
  let value;
  const mdmProfileList = {
    IsManaged: 'Managed',
    IsEncrypted: 'Encrypted',
    PayloadUUID: 'Payload UUID',
    PayloadContent: 'Payload Content',
    PayloadVersion: 'Payload Version',
    PayloadIdentifier: 'Payload Identifier',
    HasRemovalPasscode: 'Has Removal Passcode',
    PayloadDescription: 'Payload Description',
    PayloadDisplayName: 'Payload Display Name',
    SignerCertificates: 'Signer Certificates',
    PayloadOrganization: 'Payload Organization',
    PayloadRemovalDisallowed: 'Payload Removal Disallowed',
  };

  const PayloadContent = {
    PayloadType: 'Payload Type',
    PayloadUUID: 'Payload UUID',
    PayloadVersion: 'Payload Version',
    PayloadIdentifier: 'Payload Identifier',
    PayloadDescription: 'Payload Description',
    PayloadDisplayName: 'Payload Display Name',
    PayloadOrganization: 'Payload Organization',
  };

  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
    case 'iPad':
    case 'AppleTV':
      computer.mdm_profile_list.map((profMdm, index) => {
        info.push({});
        Object.keys(computer.mdm_profile_list[index])
          .filter((profileKey) =>
            Object.keys(mdmProfileList).includes(profileKey),
          )
          .map((profileKey) => {
            if (profileKey === 'PayloadContent') {
              info[index][mdmProfileList[profileKey]] = [];
              if (computer.mdm_profile_list[index][profileKey] !== null) {
                computer.mdm_profile_list[index][profileKey].map(
                  (profContent, contentIndex) => {
                    info[index][mdmProfileList[profileKey]].push({});
                    Object.keys(
                      computer.mdm_profile_list[index][profileKey][
                        contentIndex
                      ],
                    )
                      .filter((contentKey) =>
                        Object.keys(PayloadContent).includes(contentKey),
                      )
                      .map((contentKey) => {
                        value = checkField(
                          contentKey,
                          computer.mdm_profile_list[index][profileKey][
                            contentIndex
                          ][contentKey],
                        );
                        info[index][mdmProfileList[profileKey]][contentIndex][
                          PayloadContent[contentKey]
                        ] = value;
                      });
                  },
                );
              }
            } else {
              value = checkField(
                profileKey,
                computer.mdm_profile_list[index][profileKey],
              );
              info[index][mdmProfileList[profileKey]] = value;
            }
          });
      });
      break;
    case 'Mac':
      break;
    default:
      break;
  }
  return info;
};

export const getDeviceMdmCertificateInfo = (computer) => {
  const info = [];
  const certificate = {
    CommonName: 'Common Name',
    IsIdentity: 'Identity',
  };
  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
    case 'iPad':
    case 'AppleTV':
      computer.mdm_certificate_list.map((_, index) => {
        info.push({});
        Object.keys(computer.mdm_certificate_list[index])
          .filter((profileKey) => Object.keys(certificate).includes(profileKey))
          .map((profileKey) => {
            info[index][certificate[profileKey]] = checkField(
              profileKey,
              computer.mdm_certificate_list[index][profileKey],
            );
          });
      });
      break;
    case 'Mac':
      break;
    default:
      break;
  }
  return info;
};
