import type {
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import type { Column } from '@tanstack/react-table';
import * as React from 'react';

import {
  usePagination,
  usePrismSchemas,
} from 'src/features/visibility/prism/hooks';
import { getColumnsForDeviceView } from 'src/features/visibility/prism/utils/column-helpers';
import { parseSchemas } from '../types/prism.schema';
import type { CategorySchema, PrismCategorySchema } from '../types/prism.types';

export type Option = {
  id: string;
  name: string;
};

export type PrismViewsContextType = {
  columns: {
    pinnedColumns?: string[];
    columnDefs?: Column<CategorySchema>[];
    alwaysHiddenColumns?: string[];
  };
  prismCategories: PrismCategorySchema[] | undefined;
  prismCategorySchemas: Record<string, PrismCategorySchema> | undefined;
  isLoading: boolean;
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

const PrismViewsContext = React.createContext<PrismViewsContextType>({
  columns: {},
  prismCategories: [],
  prismCategorySchemas: {},
  isLoading: false,
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  setPagination: {} as OnChangeFn<PaginationState>,
  sorting: [] as SortingState,
  setSorting: {} as OnChangeFn<SortingState>,
});

const PrismViewsProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const prismSchemas = usePrismSchemas();

  const [prismCategorySchemas, setPrismCategorySchemas] = React.useState({});

  const [sorting, setSorting] = React.useState<SortingState>([]);

  React.useEffect(() => {
    if (prismSchemas.data) {
      setPrismCategorySchemas(parseSchemas(prismSchemas.data));
    }
  }, [prismSchemas.data]);

  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
  } = usePagination();

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const columns = React.useMemo(() => {
    const { columnDefs, alwaysHiddenColumns, pinnedColumns } =
      getColumnsForDeviceView({
        schema: prismCategorySchemas,
      });

    return {
      pinnedColumns,
      columnDefs: columnDefs,
      alwaysHiddenColumns,
    };
  }, [prismCategorySchemas]);

  return (
    <PrismViewsContext.Provider
      value={{
        columns,
        prismCategories: prismSchemas.data,
        prismCategorySchemas,
        isLoading: prismSchemas.isLoading,
        pagination,
        setPagination,
        sorting,
        setSorting,
      }}
    >
      {children}
    </PrismViewsContext.Provider>
  );
};

const usePrismViewsContext = () => {
  return React.useContext(PrismViewsContext);
};

export { PrismViewsProvider, usePrismViewsContext, PrismViewsContext };
