/* istanbul ignore file */
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import featureFlags from 'src/config/feature-flags';
import { usePermissions } from 'src/contexts/account';

import BlueprintFlowRouter from '../blueprint-flow/route';
import Blueprint from './blueprint/blueprint';
import ConnectedBlueprintsList from './blueprints-list/blueprints-list';

const Blueprints = () => {
  const { path } = useRouteMatch();
  const { canManageBlueprints } = usePermissions();
  const LDFF_BLUEPRINT_FLOW = featureFlags.getFlag(
    'mead_082323_blueprint-flow',
  );

  return (
    <Switch>
      {LDFF_BLUEPRINT_FLOW && (
        <Route
          exact
          path={`${path}/maps/add`}
          component={
            !canManageBlueprints
              ? () => <Redirect to="/blueprints" />
              : BlueprintFlowRouter
          }
        />
      )}
      {LDFF_BLUEPRINT_FLOW && (
        <Route path={`${path}/maps/:id`} component={BlueprintFlowRouter} />
      )}
      <Route path={`${path}/:id/:tab?`} component={Blueprint} />
      <Route exact path={path} component={ConnectedBlueprintsList} />
      <Redirect to="/blueprints" />
    </Switch>
  );
};

export default Blueprints;
