import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const MenuBarSection = (props) => {
  const { isDisabled, update, setting } = props;
  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Menu Bar</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.showInputMenu}
              label="Show input menu"
              onChange={() => update('showInputMenu', (p) => !p)}
              testId="show-input-menu"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Display the keyboard input menu at the login window and lock screen.
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              checked={setting.AdminHostInfo}
              label="Show additional computer details"
              onChange={() => update('AdminHostInfo', (p) => !p)}
              testId="show-computer-details"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Cycle through various computer details at the login window by
            clicking on the clock in the menu bar. Details include hostname,
            macOS version, build number, serial number, IP address, and network
            account info.
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default MenuBarSection;
