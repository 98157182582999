import { TextInput } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import useModal from '../../../hooks/use-modal';
import writePencil from '../assets/write-pencil.svg';
import useEventNotifications from '../hooks/use-event-notifications';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const StyledTextInputContainer = styled.div`
  width: 360px;
`;

const StyledModalContent = styled.div`
  display: grid;
  gap: 12px;
  align-items: center;
  justify-items: center;
  max-width: 520px;
  margin: auto;
  text-align: center;
  & > ${StyledTextInputContainer} {
    margin-top: 12px;
  }
`;

const EventNotificationDetails = () => {
  const { nextModal, closeModal, previousModal } = useModal();
  const [notificationName, setNotificationName] = useState('');
  const { mutateAsync, isLoading } = useEventNotifications();
  const { state: modalState } = useModalState();

  const notificationId = modalState?.msTeamsNotificationId;
  const onClose = () => closeModal();
  const handleNext = () => {
    mutateAsync({ notificationId, data: { name: notificationName } }).then(
      () => {
        nextModal();
      },
    );
  };
  const handleOnChange = (event) => {
    setNotificationName(event.target.value);
  };

  return (
    <Container>
      <StyledModalContent>
        <img src={writePencil} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>Event notification details</StyledSlideHeader>
        <p className="b-txt">
          Provide a name for this event notification. This will be useful when
          viewing your event notifications later on.
        </p>
        <StyledTextInputContainer>
          <TextInput
            placeholder="Enter a name"
            name="notification-name"
            value={notificationName}
            onChange={handleOnChange}
          />
        </StyledTextInputContainer>
      </StyledModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        nextButtonDisabled={!notificationName}
        showStepper
        showBackButton
        backOnClick={previousModal}
        stepsAmount={4}
        stepperIdx={2}
        isWorking={isLoading}
      />
    </Container>
  );
};

export default EventNotificationDetails;
