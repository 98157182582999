import {
  Badge,
  Button,
  Card,
  DropdownMenu,
  Flex,
  Heading,
  Icon,
  Paragraph,
  styled,
} from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { LineLoader } from 'app/components/interface/LineLoader';
import type { AxiosError } from 'axios';
import kandjiIntegrationCardIcon from 'features/integrations/generic-cards-view/assets/kandji-integration-card.svg';
import serviceNowIntegrationCardIcon from 'features/integrations/generic-cards-view/assets/servicenow-integration-card.svg';
import serviceNowIcon from 'features/integrations/generic-cards-view/assets/servicenow.svg';
import { Whoops404 } from 'pages/404';
/* istanbul ignore file */
// todo remove ignore file once functionality is implemented
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RenderBreadcrumbs from '../../../../components/render-breadcrumbs';
import { useGetServicenowVersion } from '../../hooks/use-get-servicenow-version';
import UpdateIntegration from '../update-integration';

import {
  StyledHeaderH2,
  StyledHeaderImage,
  StyledLink,
  StyledNav,
} from '../../../../components/styled-components/main';
import { useDeleteServicenowIntegraton } from '../../hooks/use-delete-servicenow-integration';
import { useGetServicenowIntegration } from '../../hooks/use-get-servicenow-integration';
import DeleteIntegration from '../delete-integration/delete-integration';

const StyledCard = styled(Card, {
  padding: '0px !important',
});
const StyledMainHeader = styled(Flex, {
  padding: '$3 $5',
  alignItems: 'center',
  img: {
    paddingRight: '$4',
    height: '$8',
  },
});
const StyledHeader = styled('div', {
  padding: '10px $5',
});
const StyledBody = styled(Flex, {
  padding: '$5 $5 $6 $5',
  borderTop: '1px solid $neutral10',
});
const StyledFooter = styled(Flex, {
  padding: '$4 $5',
  alignItems: 'center',
  borderTop: '1px solid $neutral10',
  justifyContent: 'space-between',
  svg: {
    color: '$neutral70',
  },
  '>:first-child': {
    display: 'flex',
    alignItems: 'center',
  },
});

const StyledContent = styled(Flex, {
  width: '100%',
  div: {
    flex: 1,
  },
  '>:last-child': {
    flex: '0 0 144px',
    span: {
      float: 'right',
    },
  },
  '.title': {
    color: '$neutral70',
    '&.space': {
      paddingTop: '16px',
    },
  },
});

type Params = {
  integrationId: string;
};

const DetailsIntegration = () => {
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const deleteMutation = useDeleteServicenowIntegraton();
  const history = useHistory();
  const params = useParams<Params>();

  const { data: versionData } = useGetServicenowVersion(params.integrationId);

  const {
    data: integrationData,
    isLoading,
    error,
    isError,
  } = useGetServicenowIntegration(params.integrationId);

  const { id: integrationId, servicenow_configs: servicenowConfigsData } =
    integrationData || {};

  const configs = servicenowConfigsData?.[0];
  const servicenowTenant = `${configs?.servicenow_tenant}.service-now.com`;

  const hasReauth = configs?.status?.includes('CREDENTIAL_REFRESH_FAILED');

  const breadcrumbItems = [
    {
      title: 'Integrations',
      url: links.integrations.root,
    },
    { title: 'ServiceNow' },
  ];

  const openServicenowPortal = () => {
    window.open(`https://${servicenowTenant}`, '_blank', 'noreferrer');
  };

  const handleToggleDeleteIntegrationDialog = () => {
    setIsDeleteDialogOpen((prev) => !prev);
  };

  const handleDeleteIntegration = () => {
    if (integrationId) {
      deleteMutation.mutate(integrationId, {
        onSuccess: () => {
          setIsDeleteDialogOpen(false);
          history.push('/integrations');
        },
      });
    }
  };
  const handleOpenLink = () => {
    window.open(
      'https://store.servicenow.com/sn_appstore_store.do#!/store/application/e330778897bfe150f89bf7021153af52',
      '_blank',
      'noreferrer',
    );
  };

  useEffect(() => {
    if (!params.integrationId) {
      history.replace('/integrations');
    }
  }, [history, params]);

  // TODO: We should implement a proper errorBoundary
  if (isError) {
    const err = error as AxiosError;
    const status = err.response?.status;
    switch (status) {
      case 422:
      case 404:
        return <Whoops404 />;
      default:
        break;
    }
  }

  return isLoading ? (
    <LineLoader />
  ) : (
    <>
      <Flex flow="column" gap="md">
        <StyledNav>
          <RenderBreadcrumbs items={breadcrumbItems} />
        </StyledNav>

        <StyledCard>
          <StyledMainHeader>
            <img src={serviceNowIcon} alt="servicenow icon" />
            <Heading>ServiceNow</Heading>
            <DropdownMenu
              options={[
                {
                  label: 'Re-authenticate',
                  onClick: () => setIsUpdateDialogOpen(true),
                },
                {
                  testId: 'dropdown-menu:delete-integration',
                  label: 'Delete integration',
                  onClick: () => {
                    handleToggleDeleteIntegrationDialog();
                  },
                },
              ]}
            >
              <Button
                data-testid="dropdown-menu:ellipsis"
                variant="default"
                css={{
                  color: '$neutral70',
                  backgroundColor: '$neutral0',
                  position: 'absolute',
                  right: 32,
                }}
              >
                <Icon name="ellipsis" />
              </Button>
            </DropdownMenu>
          </StyledMainHeader>

          <StyledFooter>
            <Paragraph>
              The ServiceNow integration enables organizations to sync their
              Kandji device information into ServiceNow for asset management.
              <StyledLink
                href="https://support.kandji.io/solutions/articles/72000613963"
                rel="noreferrer"
                target="_blank"
              >
                &nbsp;Learn more
              </StyledLink>
            </Paragraph>
          </StyledFooter>
        </StyledCard>

        <StyledCard>
          <StyledHeader>
            <StyledHeaderH2 as="h2">Integration settings</StyledHeaderH2>
          </StyledHeader>

          <StyledBody>
            <StyledContent gap="lg">
              <StyledHeaderImage
                src={serviceNowIntegrationCardIcon}
                alt="servicenow icon"
              />
              <div>
                <Paragraph className="title">ServiceNow tenant</Paragraph>
                <Paragraph>{servicenowTenant}</Paragraph>

                <Paragraph className="title space">Username</Paragraph>
                <Paragraph>{configs?.username}</Paragraph>
              </div>
              <div>
                {/* TODO: uncomment when we have this data */}
                {/*
                  <Paragraph className='title'>
                    ServiceNow instance
                  </Paragraph>
                  <Paragraph>Utah</Paragraph>
                */}

                <Paragraph className="title space">Client ID</Paragraph>
                <Paragraph>{configs?.client_id}</Paragraph>
              </div>
              <div>
                <Badge color={hasReauth ? 'red' : 'green'}>
                  {hasReauth ? 'Disconnected' : 'Connected'}
                </Badge>
              </div>
            </StyledContent>
          </StyledBody>

          <StyledFooter>
            <div>
              <Button variant="subtle" onClick={openServicenowPortal}>
                Open ServiceNow Portal
              </Button>
              <Icon name="arrow-up-right-from-square" />
            </div>
            {hasReauth && (
              <Button
                variant="primary"
                onClick={() => setIsUpdateDialogOpen(true)}
              >
                Re-authenticate
              </Button>
            )}
          </StyledFooter>
        </StyledCard>

        {versionData?.version && (
          <StyledCard>
            <StyledHeader>
              <StyledHeaderH2 as="h2">Kandji app</StyledHeaderH2>
            </StyledHeader>

            <StyledBody>
              <StyledContent gap="lg">
                <StyledHeaderImage
                  src={kandjiIntegrationCardIcon}
                  alt="kandji app icon"
                />

                <div>
                  <Paragraph className="title">App version</Paragraph>
                  <Paragraph>{versionData?.version}</Paragraph>
                </div>

                <div>
                  <Badge color="green">Installed</Badge>
                </div>
              </StyledContent>
            </StyledBody>

            <StyledFooter>
              <div>
                <Button variant="subtle" onClick={handleOpenLink}>
                  Open ServiceNow Store
                </Button>
                <Icon name="arrow-up-right-from-square" />
              </div>
            </StyledFooter>
          </StyledCard>
        )}
      </Flex>

      {isUpdateDialogOpen && integrationData && (
        <UpdateIntegration
          integrationId={integrationData.id}
          onClose={() => setIsUpdateDialogOpen(false)}
        />
      )}

      <DeleteIntegration
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleToggle={handleToggleDeleteIntegrationDialog}
        handleDelete={handleDeleteIntegration}
      />
    </>
  );
};

export default DetailsIntegration;
