import { Box, Flex, Loader } from '@kandji-inc/nectar-ui';
import { useContext, useState } from 'react';
import { DataTable } from 'src/components';
import { Pagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/integrations/hooks/use-adjust-sidebar-chat-bubble';
import { usePagination } from 'src/features/visibility/prism/hooks';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { useGetVulnerabilities } from '../hooks/use-get-vulnerabilities';
import { useVulnerabilitySort } from '../hooks/use-vulnerability-sort';
import useVulnerability from '../store';
import { FilterBar } from './filter-bar';
import { NoResultsFound } from './no-results-found';
import { NoVulnerabilities } from './no-vulnerabilities';
import { VulnerabilitySidePanel } from './side-panel';
import { columns } from './table-columns';
import { transformFilterToApi } from './transformers/transformToApi';

const Vulnerabilities = () => {
  useWhiteBackground();

  useAdjustSidebarChatBubble();
  const { paginationState: pagination, setPagination } = usePagination();
  const { sidebarDocked } = useContext(InterfaceContext);
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;

  const [selectedVulnerabilityId, setSelectedVulnerabilityId] = useState(null);
  const filter = useVulnerability((state) => state.filter);

  const { sort, sortBy, handleSort } = useVulnerabilitySort();

  const { vulnerabilities, count, isLoading } = useGetVulnerabilities(
    [pagination, sortBy, filter],
    {
      size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      sort_by: sortBy,
      filter: transformFilterToApi(filter),
      search: filter.term,
    },
  );

  const hasFilterApplied =
    filter.term ||
    filter.severity.length > 0 ||
    filter.firstDetected ||
    filter.latestDetected;
  const hasVulnerabilities = vulnerabilities?.length > 0;
  const hasNoVulnerabilities = !isLoading && !hasVulnerabilities;
  const shouldShowTable = !isLoading && hasVulnerabilities;

  return (
    <Box hFull>
      <FilterBar />

      <Box
        css={{
          height: 'calc(100% - 179px)',
          overflow: 'auto',
        }}
      >
        {shouldShowTable && (
          <DataTable
            columns={columns}
            data={vulnerabilities}
            pinnedColumns={[]}
            offsets={{}}
            onRowClick={(row) => setSelectedVulnerabilityId(row.cve_id)}
            sort={{ sortState: sort, setSortState: handleSort }}
          />
        )}

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader size="lg" data-testid="loader" />
          </Flex>
        )}

        {hasNoVulnerabilities && hasFilterApplied && <NoResultsFound />}

        {hasNoVulnerabilities && !hasFilterApplied && <NoVulnerabilities />}
      </Box>

      {!hasNoVulnerabilities && (
        <Box
          css={{
            position: 'fixed',
            bottom: 0,
            padding: '$3 $5',
            borderTop: '1px solid $neutral20',
            backgroundColor: '$neutral0',
            width: sidebarDocked
              ? `calc(100% - ${SIDEBAR_DOCKED_OFFSET}px)`
              : `calc(100% - ${SIDEBAR_CLOSE_OFFSET}px)`,
          }}
        >
          <Pagination
            currentPage={pagination.pageIndex + 1}
            totalItems={count}
            itemsPerPage={pagination.pageSize}
            onPageChange={
              /* istanbul ignore next */ (page) =>
                setPagination({ ...pagination, pageIndex: page - 1 })
            }
            onItemsPerPageChange={
              /*  istanbul ignore next */ (itemsPerPage) => {
                setPagination({
                  ...pagination,
                  pageIndex: 0,
                  pageSize: itemsPerPage,
                });
              }
            }
          />
        </Box>
      )}

      {selectedVulnerabilityId && (
        <VulnerabilitySidePanel
          selectedVulnerabilityId={selectedVulnerabilityId}
          clearSelectedVulnerabilityId={() => setSelectedVulnerabilityId(null)}
        />
      )}
    </Box>
  );
};

export { Vulnerabilities };
