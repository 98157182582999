/* istanbul ignore file */

import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';
import './custom-apps.css';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import {
  InstallationCard,
  useSsInstall,
} from 'features/library-items/template';

import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithoutDefaultsPreset from '../../common/self-service-card/drawer/presets/without-defaults-preset';
import ActivityTab from './activity-tab';
import initialState from './initial-state';
import InstallDetailsCard from './install-details-card';
import InstallationExtraRow from './installation-card';
import CustomAppsService, {
  customAppsService,
} from './service/custom-apps-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useCustomAppsService from './service/use-custom-apps-service';

const installationOptions = [
  {
    label: 'Install once per device',
    value: CustomAppsService.installationTypes.INSTALL_ONCE,
  },
  {
    label: 'Audit and enforce',
    value: CustomAppsService.installationTypes.CONTINUOUSLY_ENFORCE,
  },
  {
    label: 'Install on-demand from Self Service',
    value: CustomAppsService.installationTypes.NO_ENFORCEMENT,
  },
];

const CustomAppsPage = (props) => {
  const { model, setModel, pageState, handlers } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const defaultIcon =
    CustomAppsService.defaultIconsMap[model.installDetails.type];

  const updateInstallation = useCallback(update('installation', setModel), []);
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateDetails = useCallback(update('installDetails', setModel), []);
  const onFileChange = useCallback(
    (file) =>
      setModel((p) => ({
        ...p,
        selfService: {
          ...p.selfService,
          size: file?.size || 0,
        },
      })),
    [],
  );

  const isSsForced = useSsInstall({
    installType: model.installation.type,
    ssType: CustomAppsService.installationTypes.NO_ENFORCEMENT,
    updateSs,
  });

  return (
    <LibraryItemPage
      {...props}
      defaultIcon={defaultIcon}
      crumb={model.name || 'Custom App'}
      summaryInfoProps={{
        name: 'Custom App',
        description:
          'Upload and configure a .pkg, .dmg or .zip for the Kandji agent to install on your macOS devices.',
        publisher: 'Apple, Inc.',
        devices: ['Mac'],
        requirements: ['macOS 10.11+'],
      }}
      service={customAppsService}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      triggerValidation={triggerValidation}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      isIconEditable
      supportsRules
      supportsDuplication
      askFlush={() =>
        model.installation.type ===
        CustomAppsService.installationTypes.INSTALL_ONCE
      }
    >
      <InstallationCard
        setting={model.installation}
        update={updateInstallation}
        isDisabled={pageState.isDisabled}
        options={installationOptions}
        rowsSlot={
          <InstallationExtraRow
            setting={model.installation}
            update={updateInstallation}
            isDisabled={pageState.isDisabled}
            validationDep={validationDep}
          />
        }
      />

      <SelfServiceCard
        setting={model.selfService}
        update={updateSs}
        isDisabled={pageState.isDisabled}
        defaults={{
          icon: model.iconSrc || defaultIcon,
          name: model.name,
        }}
        canBeDisabled={!isSsForced}
        DrawerContent={WithoutDefaultsPreset}
        isSubmitted={pageState.isSubmitted}
        validationDep={validationDep}
      />

      <InstallDetailsCard
        setting={model.installDetails}
        update={updateDetails}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        onValidate={handlers.onValidate}
        validationDep={validationDep}
        onFileChange={onFileChange}
      />
    </LibraryItemPage>
  );
};

const CustomApps = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useCustomAppsService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <CustomAppsPage {...pageProps} />;
};

export default CustomApps;
