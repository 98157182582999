/* istanbul ignore file */
import './ethernet.scss';
import { updateSetting as update } from '@kandji-inc/bumblebee';
import { useCallback, useEffect, useMemo } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { useLocation } from 'react-router-dom';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import configs from '../../library/library-item-configurations/items/profile';
import ActivityTab from './activity-tab';
import initialState from './initial-state';
import ProtocolsCard from './protocols-card';
import ProxyCard from './proxy-card';
import EthernetService, { ethernetService } from './service/ethernet-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useEthernetService from './service/use-ethernet-service';
import TrustCard from './trust-card';

const EthernetPage = (props) => {
  const { model, setModel, pageState } = props;
  const { state } = useLocation<{
    certificate: { type: string; server: string };
  }>();
  const [validationDep, triggerValidation] = useUniqValue();

  const authenticationUpdate = useCallback(
    update('authentication', setModel),
    [],
  );
  const trustUpdate = useCallback(update('trust', setModel), []);
  const proxyUpdate = useCallback(update('proxy', setModel), []);
  const ethernetConfig = configs.Ethernet;

  useEffect(() => populateSelectedDevices(model, setModel, ethernetConfig), []);

  useEffect(() => {
    /* istanbul ignore next */
    if (
      pageState.isAdding &&
      state?.certificate?.type &&
      Object.values(EthernetService.identityCertificates).includes(
        state.certificate.type,
      ) &&
      state?.certificate?.server
    ) {
      authenticationUpdate(
        EthernetService.keys.authentication.identityCertificate,
        state.certificate.type,
      );
      authenticationUpdate(
        EthernetService.keys.authentication.adcs.server,
        state.certificate.server,
      );
      delete state.certificate.type;
      delete state.certificate.server;
    }
  }, []);

  const summaryInfoProps = useMemo(
    () => ({
      name: `${ethernetConfig.name} Profile`,
      description: ethernetConfig.description,
      publisher: ethernetConfig.publisher,
      devices: ethernetConfig.devices,
      requirements: ethernetConfig.osRequirements,
    }),
    [
      ethernetConfig.description,
      ethernetConfig.devices,
      ethernetConfig.osRequirements,
      ethernetConfig.publisher,
    ],
  );

  const keys = EthernetService.keys.authentication;
  const showTrustCard = model.authentication[keys.interface];

  return (
    <LibraryItemPage
      {...props}
      crumb={model.name || 'Ethernet'}
      summaryInfoProps={summaryInfoProps}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={ethernetService}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={ethernetConfig.icon}
      supportsRules
      supportsDuplication
    >
      <>
        <ProtocolsCard
          setting={model.authentication}
          update={authenticationUpdate}
          isDisabled={pageState.isDisabled}
          isSubmitted={pageState.isSubmitted}
          validationDep={validationDep}
        />
        {showTrustCard && (
          <TrustCard
            setting={model.trust}
            update={trustUpdate}
            isDisabled={pageState.isDisabled}
            validationDep={validationDep}
          />
        )}
        <ProxyCard
          setting={model.proxy}
          update={proxyUpdate}
          isDisabled={pageState.isDisabled}
          isSubmitted={pageState.isSubmitted}
          validationDep={validationDep}
        />
      </>
    </LibraryItemPage>
  );
};

const Ethernet = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useEthernetService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <EthernetPage {...pageProps} testId="ethernet-v2" />;
};

export default Ethernet;
