import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ViewportList } from 'react-viewport-list';
import { useShallow } from 'zustand/react/shallow';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Badge,
  Box,
  Button,
  DropdownMenu,
  FilterButton,
  Flex,
  Icon,
  Loader,
  MultiSelect,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';

import { useOnClickOutside } from 'src/app/components/common/hooks';
import { useDebounce } from 'src/features/util/hooks/use-debounce';
import { useBlueprints } from 'src/features/visibility/prism/hooks';

import type { LibraryItem, LibraryItemFilter } from '../blueprint-flow.types';

import { noLibraryItems, noResults } from '../assets';
import { Empty } from '../components';
import Overlay from '../components/Overlay';
import { DraggableLibraryItem } from '../components/library-item';
import {
  deviceFamiliesSelectOptions,
  deviceFamilySelectOptionToRunsOn,
  deviceToIcon,
  libraryItemSortOptions,
} from '../constants';
import {
  getItemConfig,
  getLibraryItemTypesForFilter,
  sortLibraryItems,
} from '../helpers';
import useBlueprintFlow from '../store';

const defaultFilterState = {
  term: '',
  sort: 'li_type_az',
  devices: [],
  types: [],
  blueprints: [],
};
const Sidebar = () => {
  const [
    libraryItems,
    isLoadingLibraryItems,
    selectedAssignmentLibraryItems,
    draggingLibraryItems,
    setSelectedAssignmentLibraryItems,
    clearSelectedAssignmentLibraryItems,
    isDeletingNode,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.libraryItems,
      state.isLoadingLibraryItems,
      state.selectedAssignmentLibraryItems,
      state.draggingLibraryItems,
      state.setSelectedAssignmentLibraryItems,
      state.clearSelectedAssignmentLibraryItems,
      state.isDeletingNode,
    ]),
  );

  const {
    data: blueprints,
  }: {
    data: Array<{ id: string; name: string; library_item_ids: Array<string> }>;
  } = useBlueprints();
  const blueprintsWithLibraryItems = blueprints?.filter(
    (bp) => bp.library_item_ids.length,
  );

  const [libraryItemFilter, setLibraryItemFilter] = useState<LibraryItemFilter>(
    defaultFilterState as LibraryItemFilter,
  );
  const [isShowingFilters, setIsShowingFilters] = useState(false);

  const isDraggingLibraryItems = draggingLibraryItems?.items?.length;

  const hasFilterActive = useMemo(
    () =>
      JSON.stringify(libraryItemFilter) !== JSON.stringify(defaultFilterState),
    [libraryItemFilter],
  );

  const [debounceLibraryFilter] = useDebounce([libraryItemFilter], 200);

  const scrollableItemsRef = useRef();

  useEffect(() => {
    setSelectedAssignmentLibraryItems((prev) => ({
      ...prev,
      lastItemClicked: [],
    }));
  }, [debounceLibraryFilter]);

  /*
  // TODO: Add the following back if filtering in the sidebar becomes "searching" again via API.
  const setFilter = useBlueprintFlow((state) => state.setLibraryItemFilter);

  useEffect(() => {
    setFilter(debounceLibraryFilter);
  }, [debounceLibraryFilter]);
  */

  const numActiveFilters =
    libraryItemFilter.devices.length +
    libraryItemFilter.types.length +
    libraryItemFilter.blueprints.length;

  const typeOptions = getLibraryItemTypesForFilter(libraryItems);
  const numTypeOptions = typeOptions.reduce((a, c) => a + c.options.length, 0);

  const numBlueprintOptions = blueprintsWithLibraryItems?.length;

  const isItemInSearchTerm = (item: LibraryItem) =>
    [item.name, getItemConfig(item).name, item.instanceName].some((field) =>
      field?.toLowerCase().includes(libraryItemFilter.term.toLowerCase()),
    );

  const isItemInDevicesFilter = (item: LibraryItem) =>
    libraryItemFilter.devices.some(
      (device) => item[deviceFamilySelectOptionToRunsOn[device]],
    );

  const isItemInTypeFilter = (item: LibraryItem) =>
    libraryItemFilter.types.includes(getItemConfig(item).name);

  const isItemInBlueprintFilter = (item: LibraryItem) =>
    libraryItemFilter.blueprints.some((bp) =>
      blueprintsWithLibraryItems
        .find(({ id }) => id === bp)
        .library_item_ids.includes(item.id),
    );

  const filteredSortedLibraryItems = useMemo(() => {
    const filtered = libraryItems.filter(
      (item: LibraryItem) =>
        // Only apply the filters if they have been added
        (!libraryItemFilter.term.length || isItemInSearchTerm(item)) &&
        (!libraryItemFilter.devices.length || isItemInDevicesFilter(item)) &&
        (!libraryItemFilter.types.length || isItemInTypeFilter(item)) &&
        (!libraryItemFilter.blueprints.length || isItemInBlueprintFilter(item)),
    );

    return sortLibraryItems(libraryItemFilter.sort, filtered);
  }, [debounceLibraryFilter, libraryItems]);

  const [blueprintFilterSearch, setBlueprintFilterSearch] = useState('');
  const blueprintFilterRef = useRef(null);
  useOnClickOutside(blueprintFilterRef, () => setBlueprintFilterSearch(''));

  // Hard to test this interaction, we can't properly test the selection logic
  // since we mock the set fn.
  /* istanbul ignore next */
  const onItemSelect = useCallback(
    (e, item) => {
      const { id, flowId } = item;
      const isShiftKey = e.shiftKey;
      const isMetaKey = e.metaKey || e.ctrlKey;
      const isBothShiftMeta = isShiftKey && isMetaKey;
      const isRegularClick = !isShiftKey && !isMetaKey;

      if (isBothShiftMeta) {
        return;
      }

      if (isRegularClick) {
        setSelectedAssignmentLibraryItems({
          origin: 'bank',
          items: { [id]: { id, flowId } },
          lastItemClicked: [id],
        });
      } else if (isMetaKey) {
        // Meta click result in single selection or deselection of an item.
        setSelectedAssignmentLibraryItems((prev) => {
          const { items } = prev;
          if (items[id]) {
            const { [id]: _, ...rest } = items;

            return {
              origin: 'bank',
              items: rest,
              lastItemClicked: prev.lastItemClicked.filter(
                (item) => item !== id,
              ),
            };
          }

          return {
            origin: 'bank',
            items: {
              ...items,
              [id]: {
                id,
                flowId,
              },
            },
            lastItemClicked: [...prev.lastItemClicked, id],
          };
        });
      } else if (isShiftKey) {
        // Shift click results in looking at the last clicked item index and
        // selecting all items between the last clicked item and the current
        // clicked item. If there was no last clicked item, it will select
        // starting from the 0th index of the current filtered items list.
        setSelectedAssignmentLibraryItems((prev) => {
          const { items, lastItemClicked } = prev;
          const itemClickedIdx = filteredSortedLibraryItems.findIndex(
            ({ id }) => id === item.id,
          );
          const lastItemIdx = filteredSortedLibraryItems.findIndex(
            ({ id }) => id === lastItemClicked.at(-1),
          );

          if (lastItemIdx === -1) {
            const toAddItems = filteredSortedLibraryItems
              .slice(0, itemClickedIdx + 1)
              .reduce(
                (a, { id, flowId }) => ({ ...a, [id]: { id, flowId } }),
                {},
              );

            return {
              origin: 'bank',
              items: {
                ...items,
                ...toAddItems,
              },
              lastItemClicked: [id],
            };
          }

          const isGoingUp = itemClickedIdx < lastItemIdx;

          const toAddItems = filteredSortedLibraryItems
            .slice(
              isGoingUp ? itemClickedIdx : lastItemIdx,
              isGoingUp ? lastItemIdx : itemClickedIdx + 1,
            )
            .reduce(
              (a, { id, flowId }) => ({ ...a, [id]: { id, flowId } }),
              {},
            );

          return {
            origin: 'bank',
            items: {
              ...items,
              ...toAddItems,
            },
            lastItemClicked: [...prev.lastItemClicked, id],
          };
        });
      }
    },
    [selectedAssignmentLibraryItems, filteredSortedLibraryItems],
  );

  const FilteredLibraryItemsSection = () => {
    if (isLoadingLibraryItems) {
      return (
        <Flex justifyContent="center" data-testid="loader-library">
          <Loader size="lg" />
        </Flex>
      );
    }

    if (!libraryItems.length && !hasFilterActive) {
      return (
        <Empty
          image={noLibraryItems}
          title="There are no Library Items in your Library."
          message="Add new Library Items to your Library to see them here."
          link={{
            label: 'Visit Library',
            route: '/library/add',
            show: true,
          }}
          fullHeight
        />
      );
    }

    if (filteredSortedLibraryItems?.length) {
      return (
        <ViewportList
          viewportRef={scrollableItemsRef}
          items={filteredSortedLibraryItems}
        >
          {(item) => {
            const isDisabled = Boolean(
              draggingLibraryItems?.items.find(
                (active) => active.data.flowId === item.flowId,
              ),
            );

            // Items in the sidebar are unique, use the item's id to check if its
            // selected, as opposed to the graph where we check the item's flow id
            // for isSelected.
            const isSelected = Boolean(
              selectedAssignmentLibraryItems.origin === 'bank' &&
                selectedAssignmentLibraryItems.items[item.id],
            );

            return (
              <DraggableLibraryItem
                key={item.flowId}
                item={item}
                isSelected={isSelected}
                onClick={onItemSelect}
                isDisabled={isDisabled}
                searchText={libraryItemFilter.term}
                searchTextDebounce={debounceLibraryFilter}
              />
            );
          }}
        </ViewportList>
      );
    }

    return (
      <Empty
        image={noResults}
        title="No results found."
        message="We couldn't find a match. Try searching with different keywords."
      />
    );
  };

  const getSidebarOffset = () => {
    if (isShowingFilters) {
      return blueprintsWithLibraryItems?.length ? 110 : 74;
    }

    return 40;
  };

  return (
    <Box
      pr2
      css={{
        position: 'relative',
        zIndex: 5,
        backgroundColor: '$neutral0',
        borderRight: isDeletingNode ? 'none' : '1px solid $neutral10',
        width: '310px',
        boxShadow: '$elevation1',
      }}
    >
      <Box pt5 pl4>
        <Text size={1} variant="description" css={{ color: '$neutral70' }}>
          Drag items onto the map to assign to devices.
        </Text>
      </Box>
      <Box pl4 pt4 pb4 css={{ height: `calc(100% - ${getSidebarOffset()}px)` }}>
        <Flex justifyContent="space-between">
          <Text
            css={{
              fontWeight: '500',
              paddingBottom: '10px',
            }}
          >
            Library Items
          </Text>
          {selectedAssignmentLibraryItems.origin === 'bank' &&
            Object.keys(selectedAssignmentLibraryItems.items).length > 0 && (
              <Flex css={{ height: 'fit-content' }}>
                <Text size="1" css={{ color: '$neutral70' }}>
                  {`${
                    Object.keys(selectedAssignmentLibraryItems.items).length
                  } selected`}
                </Text>
                <Box
                  css={{
                    height: '12px',
                    width: '1px',
                    backgroundColor: '$neutral20',
                    margin: '2px 2px',
                  }}
                />
                <Text
                  size="1"
                  variant="primary"
                  onClick={clearSelectedAssignmentLibraryItems}
                  css={{
                    cursor: 'pointer',
                    fontWeight: '$medium',
                  }}
                >
                  Clear selection
                </Text>
              </Flex>
            )}
        </Flex>
        <TextField
          pb3
          compact
          icon="magnifying-glass"
          showClearButton={Boolean(libraryItemFilter.term)}
          onClear={() =>
            setLibraryItemFilter((prev) => ({
              ...prev,
              term: '',
            }))
          }
          placeholder="Search Library Item title or type"
          onChange={(e) =>
            setLibraryItemFilter((prev) => ({
              ...prev,
              term: e.target.value,
            }))
          }
          value={libraryItemFilter.term}
          data-testid="search-li"
        />

        <Flex justifyContent="space-between" alignItems="start">
          <Accordion
            type="single"
            onValueChange={() => setIsShowingFilters((prev) => !prev)}
          >
            <AccordionItem value="filters">
              <AccordionTrigger
                css={{ justifyContent: 'start', margin: '$1 $0 $2' }}
                customIcon={<Icon name="fa-angle-down-small" size="sm" />}
              >
                <Flex gap="sm" alignItems="center">
                  <Text css={{ fontWeight: '$medium' }}>Filters</Text>
                  {numActiveFilters > 0 && (
                    <Badge
                      compact
                      css={{
                        color: '$neutral0',
                        backgroundColor: '$blue50',
                        fontVariantNumeric: 'tabular-nums',
                      }}
                    >
                      {numActiveFilters}
                    </Badge>
                  )}
                </Flex>
              </AccordionTrigger>
              <AccordionContent>
                <Flex
                  gap="sm"
                  alignItems="center"
                  wrap="wrap"
                  css={{ paddingRight: '$2' }}
                >
                  <MultiSelect
                    multi
                    selectAll={{ selectAllLabel: 'Select All' }}
                    options={typeOptions}
                    value={libraryItemFilter.types}
                    onChange={(selected) =>
                      setLibraryItemFilter((prev) => ({
                        ...prev,
                        types: selected,
                      }))
                    }
                    footer={{
                      showClear: true,
                      clearLabel: 'Clear',
                      clearDisabled: libraryItemFilter.types.length === 0,
                      handleClear: () =>
                        setLibraryItemFilter((prev) => ({
                          ...prev,
                          types: [],
                        })),
                    }}
                  >
                    <FilterButton
                      filtersSelected={Boolean(libraryItemFilter.types.length)}
                      showRemove={false}
                      aria-label="type-filter"
                    >
                      <Flex
                        flow="row"
                        alignItems="center"
                        css={{
                          maxWidth: libraryItemFilter.devices.length
                            ? '60px'
                            : null,
                        }}
                      >
                        <Text
                          css={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          Type
                        </Text>
                        {libraryItemFilter.types.length === numTypeOptions && (
                          <Flex flow="row" gap="xs">
                            <Text>: </Text>
                            <Text css={{ fontWeight: '$medium' }}>
                              {' All'}
                            </Text>
                          </Flex>
                        )}
                        {libraryItemFilter.types.length !== numTypeOptions &&
                          libraryItemFilter.types.length > 0 && (
                            <Badge
                              compact
                              css={{
                                color: '$neutral0',
                                backgroundColor: '$blue50',
                                marginLeft: '$1',
                              }}
                            >
                              {libraryItemFilter.types.length}
                            </Badge>
                          )}
                      </Flex>
                    </FilterButton>
                  </MultiSelect>

                  <MultiSelect
                    multi
                    options={deviceFamiliesSelectOptions.map(
                      ({ label, value }) => ({
                        label,
                        value,
                        icon: deviceToIcon[value],
                      }),
                    )}
                    value={libraryItemFilter.devices}
                    onChange={(selected) =>
                      setLibraryItemFilter((prev) => ({
                        ...prev,
                        devices: selected,
                      }))
                    }
                    footer={{
                      showClear: true,
                      clearLabel: 'Clear',
                      clearDisabled: libraryItemFilter.devices.length === 0,
                      handleClear: () =>
                        setLibraryItemFilter((prev) => ({
                          ...prev,
                          devices: [],
                        })),
                    }}
                  >
                    <FilterButton
                      filtersSelected={Boolean(
                        libraryItemFilter.devices.length,
                      )}
                      showRemove={false}
                      aria-label="device-family-filter"
                    >
                      <Flex
                        flow="row"
                        alignItems="center"
                        css={{
                          maxWidth: libraryItemFilter.types.length
                            ? '100px'
                            : null,
                        }}
                      >
                        <Text
                          css={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          Device family
                        </Text>
                        {libraryItemFilter.devices.length ===
                          deviceFamiliesSelectOptions.length && (
                          <Flex flow="row" gap="xs">
                            <Text>: </Text>
                            <Text css={{ fontWeight: '$medium' }}>
                              {' All'}
                            </Text>
                          </Flex>
                        )}
                        {libraryItemFilter.devices.length !==
                          deviceFamiliesSelectOptions.length &&
                          libraryItemFilter.devices.length > 0 && (
                            <Badge
                              compact
                              css={{
                                color: '$neutral0',
                                backgroundColor: '$blue50',
                                marginLeft: '$1',
                              }}
                            >
                              {libraryItemFilter.devices.length}
                            </Badge>
                          )}
                      </Flex>
                    </FilterButton>
                  </MultiSelect>

                  {/* Hide Blueprints filter if there are no Blueprints with Library Items */}
                  {blueprintsWithLibraryItems?.length && (
                    <Box ref={blueprintFilterRef}>
                      <MultiSelect
                        multi
                        options={blueprintsWithLibraryItems
                          .filter(({ name }) =>
                            name.includes(blueprintFilterSearch),
                          )
                          .map(({ id, name }) => ({ value: id, label: name }))}
                        value={libraryItemFilter.blueprints}
                        onChange={(selected) =>
                          setLibraryItemFilter((prev) => ({
                            ...prev,
                            blueprints: selected,
                          }))
                        }
                        noOptionsFoundMessage="No Blueprints with Library Items found."
                        footer={{
                          showClear: true,
                          clearLabel: 'Clear',
                          clearDisabled:
                            libraryItemFilter.blueprints.length === 0,
                          handleClear: () =>
                            setLibraryItemFilter((prev) => ({
                              ...prev,
                              blueprints: [],
                            })),
                        }}
                        customHeader={
                          <TextField
                            compact
                            p2
                            placeholder="Search Blueprints"
                            icon="magnifying-glass"
                            value={blueprintFilterSearch}
                            onChange={(e) =>
                              setBlueprintFilterSearch(e.target.value)
                            }
                            onClear={() => setBlueprintFilterSearch('')}
                          />
                        }
                      >
                        <FilterButton
                          filtersSelected={Boolean(
                            libraryItemFilter.blueprints.length,
                          )}
                          showRemove={false}
                          aria-label="blueprint-filter"
                        >
                          <Flex flow="row" alignItems="center">
                            <Text
                              css={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              Blueprint
                            </Text>
                            {libraryItemFilter.blueprints.length ===
                              numBlueprintOptions && (
                              <Flex flow="row" gap="xs">
                                <Text>: </Text>
                                <Text css={{ fontWeight: '$medium' }}>
                                  {' All'}
                                </Text>
                              </Flex>
                            )}
                            {libraryItemFilter.blueprints.length !==
                              numBlueprintOptions &&
                              libraryItemFilter.blueprints.length > 0 && (
                                <Badge
                                  compact
                                  css={{
                                    color: '$neutral0',
                                    backgroundColor: '$blue50',
                                    marginLeft: '$1',
                                  }}
                                >
                                  {libraryItemFilter.blueprints.length}
                                </Badge>
                              )}
                          </Flex>
                        </FilterButton>
                      </MultiSelect>
                    </Box>
                  )}
                </Flex>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <DropdownMenu
            css={{
              zIndex: 10,
              "& [role='menuitem']:has(svg)": {
                backgroundColor: '$dropdown_surface_selected_enabled',
                color: '$blue50',
              },
            }}
            options={libraryItemSortOptions.map((opt) => ({
              ...opt,
              icon:
                libraryItemFilter.sort === opt.value
                  ? 'fa-check-14px'
                  : undefined,
              iconPosition: 'right',
              onClick: () =>
                setLibraryItemFilter((prev) => ({ ...prev, sort: opt.value })),
            }))}
            contentProps={{ align: 'start' }}
          >
            <Button
              compact
              variant="subtle"
              icon={{ name: 'arrow-down-arrow-up' }}
              css={{ marginTop: '2px' }}
            />
          </DropdownMenu>
        </Flex>
        <Box
          wFull
          css={{
            backgroundColor: '$neutral20',
            height: '1px',
            margin: '10px 0',
          }}
        />
        <Flex
          ref={scrollableItemsRef}
          flow="column"
          justifyContent={
            isLoadingLibraryItems || !libraryItems?.length
              ? 'center'
              : undefined
          }
          css={{
            height: 'calc(100% - 110px)',
            overflowX: 'hidden',
            overflowY: isDraggingLibraryItems
              ? /* istanbul ignore next - purely visual */ 'hidden'
              : 'auto',
            padding: '2px',
            '& > *:not(:last-child):not(:first-child)': {
              marginBottom: '$1',
            },
          }}
        >
          {FilteredLibraryItemsSection()}
        </Flex>
      </Box>
      <Overlay isHidden={!isDeletingNode} />
    </Box>
  );
};

export default Sidebar;
