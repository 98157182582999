import { Toaster as toaster } from '@kandji-inc/bumblebee/lib/atoms';
import {
  Flex,
  Icon,
  Paragraph,
  TextField,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import useGetOktaDeviceTrust from 'features/integrations/components-for-specific-types/okta/hooks/use-get-okta-device-trust';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useUpdateOktaDeviceTrust } from 'features/integrations/components-for-specific-types/okta/hooks';
import useOktaDeviceTrustPlatform from 'features/integrations/components-for-specific-types/okta/hooks/use-okta-device-trust-platform';
import FooterButtons from 'features/integrations/components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from 'features/integrations/components/styled-components';
import type { AddIosDetailProps } from 'src/features/integrations/components-for-specific-types/okta/types';

import iosOdt from '../../../modals/assets/ios-odt.svg';

const StyledTextInputContainer = styled.div`
  margin-top: 24px;
  width: 360px;
  height: 95px;
  text-align: left;
`;

const IOSDevicePlatform = (props: AddIosDetailProps) => {
  const { integrationData, onClose, isEditing } = props;
  const { getOktaDeviceTrustDetails } = useGetOktaDeviceTrust();
  const { updateOktaDeviceTrust } = useUpdateOktaDeviceTrust();
  const { isLoading } = useOktaDeviceTrustPlatform();

  const initialValues = {
    iosSecret: integrationData.ios_configured ? '****************' : '',
  };

  const handleNext = async (values: typeof initialValues) => {
    const { id: integrationId } = integrationData;

    try {
      await updateOktaDeviceTrust(
        {
          ios_provider_secret_key: values.iosSecret,
        },
        integrationId,
      );
      await getOktaDeviceTrustDetails();
      toaster('Integration settings updated successfully.');
      onClose();
    } catch (e) {
      toaster('Something went wrong');
      onClose();
    }
  };

  const handleValidate = ({ iosSecret }: typeof initialValues) => ({
    ...(!iosSecret && { iosSecret: ['Required'] }),
  });

  const title = isEditing
    ? 'Edit iOS device platform settings'
    : 'Add iOS as a device platform';

  const iosCopy = isEditing
    ? 'The secret key is provided in Okta when adding or managing a device management provider.'
    : 'Provide the following information for iOS devices. The secret key was generated in Okta when adding iOS as a device management provider.';

  return (
    <TooltipProvider>
      <Formik
        initialValues={initialValues}
        validate={handleValidate}
        onSubmit={handleNext}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
        }) => (
          <Container>
            <ModalContent>
              <img src={iosOdt} className="b-mb2" alt="header-icons" />
              <StyledSlideHeader>{title}</StyledSlideHeader>
              <p className="b-txt-light">{iosCopy}</p>
              <StyledTextInputContainer>
                <Flex flow="column" gap="sm">
                  <Flex gap="xs">
                    <p className="b-txt">Secret key</p>
                    <Tooltip
                      side="right"
                      theme="light"
                      maxWidth="376px"
                      css={{
                        zIndex: 10000,
                      }}
                      // @ts-expect-error -- TODO fix this
                      content={
                        <Paragraph>
                          The secret key that was generated in Okta when adding
                          iOS as a device management provider.
                        </Paragraph>
                      }
                    >
                      <span>
                        <Icon
                          name="circle-exclamation"
                          size="sm"
                          color="#0053D2"
                        />
                      </span>
                    </Tooltip>
                  </Flex>

                  <TextField
                    name="iosSecret"
                    type="password"
                    placeholder="Enter the secret key"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.iosSecret}
                    maxLength={50}
                    hint={{
                      label:
                        touched.iosSecret && errors?.iosSecret
                          ? errors.iosSecret
                          : '',
                    }}
                    state={
                      touched.iosSecret && errors?.iosSecret
                        ? 'error'
                        : undefined
                    }
                    data-testid="ios-secret:input"
                  />
                </Flex>
              </StyledTextInputContainer>
            </ModalContent>
            <FooterButtons
              onClose={onClose}
              handleNext={handleSubmit}
              nextButtonDisabled={!!Object.keys(errors).length}
              cancelButtonText="Cancel"
              nextButtonText="Save"
              showBackButton={false}
              isWorking={isLoading}
            />
          </Container>
        )}
      </Formik>
    </TooltipProvider>
  );
};

export default IOSDevicePlatform;
