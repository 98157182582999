/* istanbul ignore file - legacy code, moved */
import { libraryAxios } from 'app/api/base';
import { usePermissions } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import { get } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toaster from 'theme/toaster';
import { LibraryItemsAPI } from './SHAREDApi';

const DEFAULT_STATUS_COUNTS = {
  ALL: 0,
  PENDING: 0,
  ERROR: 0,
  PASS: 0,
  INCOMPATIBLE: 0,
  REMEDIATED: 0,
};

const blueprintDataAndActionsDefaultValues = {
  isPendingValidation: false,
  blueprintConflicts: [],
  setSelectedBlueprints: () => [],
  closeConflictResolution: () => null,
  onBlueprintAssignmentExit: () => null,
  onBlueprintAssignmentOpen: () => null,
  onBlueprintAssignmentToggle: () => null,
};

const defaultValues = {
  type: undefined,
  item: undefined,
  data: undefined,
  manage: undefined,
  dispatch: undefined,
  fieldValidation: undefined,
  refInputTitle: undefined,
  canEdit: false,
  canDelete: false,
  isAppStoreApp: false,
  isAutoApp: false,
  isOperatingSystem: false,
  isCustomApp: false,
  isCustomScript: false,
  isCustomPrinter: false,
  isLoading: false,
  isWorking: false,
  isEditing: false,
  isErrorName: false,
  isExisting: false,
  isReadOnly: false,
  isFlushConfirmOpen: false,
  setData: () => {},
  setManage: () => {},
  setIsLoading: () => {},
  setIsWorking: () => {},
  setIsEditing: () => {},
  setIsErrorName: () => {},
  setIsFlushConfirmOpen: () => {},
  fetchData: () => {},
  initializeData: () => {},
  initializeManage: () => {},
  makeSaveHandler: () => {},
  onDelete: () => {},
  onClose: () => {},
  onEdit: () => {},
  onCancel: () => {},
  onModalOpen: () => {},
  onModalClose: () => {},
  // blueprints
  selectedBlueprints: [],
  statusCounts: DEFAULT_STATUS_COUNTS,
  /**
   *
   * @param {(function(Object):Object)|Object} upd
   */
  setStatusCounts: (upd) => {},
  ...blueprintDataAndActionsDefaultValues,
};

const scrollTo = (callback, offset = 0) => {
  const fixedOffset = offset.toFixed();
  const onScroll = () => {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback && callback();
    }
  };
  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
};

const useBlueprintAssignment = (data, checkConflicts) => {
  const [isPendingValidation, setIsPendingValidation] = useState(false);
  const [selectedBlueprints, setSelectedBlueprints] = useState([]);
  const [blueprintConflicts, setBlueprintConflicts] = useState([]);

  const onBlueprintAssignmentExit = (selectedValues, type) => {
    if (selectedValues.toString() === data.blueprints.toString()) {
      setIsPendingValidation(false);
      return;
    }
    checkConflicts(type, data.identifier, data.id, selectedValues)
      .then((conflicts) => {
        if (_isEmpty(conflicts)) {
          setSelectedBlueprints(selectedValues);
          setIsPendingValidation(false);
          return;
        }
        setBlueprintConflicts(conflicts);
      })
      .catch(() => {
        setSelectedBlueprints(data.blueprints);
        setIsPendingValidation(false);
      });
  };

  const closeConflictResolution = (blueprints) => {
    setSelectedBlueprints(blueprints);
    setBlueprintConflicts([]);
    setIsPendingValidation(false);
  };

  const onBlueprintAssignmentOpen = () => {
    setIsPendingValidation(true);
  };

  const onBlueprintAssignmentToggle = (selectedValues) => {
    setSelectedBlueprints(selectedValues);
  };

  useEffect(() => {
    if (data) {
      setSelectedBlueprints(data.blueprints);
    }
  }, [data]);

  return {
    isPendingValidation,
    selectedBlueprints,
    blueprintConflicts,
    setSelectedBlueprints,
    closeConflictResolution,
    onBlueprintAssignmentExit,
    onBlueprintAssignmentOpen,
    onBlueprintAssignmentToggle,
  };
};

const convertDataToFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      for (let i = 0; i < data[key].length; i++) {
        const isObject = typeof data[key][i] === 'object';
        const value = isObject ? JSON.stringify(data[key][i]) : data[key][i];
        formData.append(key, value);
      }
      if (!data[key].length) {
        formData.set(key, '');
      }
    } else {
      const isObject = typeof data[key] === 'object';
      const isFile = data[key] instanceof File;
      const value = isObject && !isFile ? JSON.stringify(data[key]) : data[key];
      formData.append(key, value);
    }
  });
  return formData;
};

const checkBlueprintAssignmentConflicts = (
  type,
  identifier,
  itemId,
  blueprints,
) =>
  libraryAxios('check-conflicts/')
    .post({
      type,
      identifier,
      item_id: itemId,
      blueprints,
    })
    .then((res) => res.data.conflicts);

export const IPALibraryContext = createContext(defaultValues);

export const IPALibraryProvider = ({ children }) => {
  const { onModalOpen, onModalClose } = useContext(InterfaceContext);

  const history = useHistory();
  const { type, item } = useParams();

  const [data, setData] = useState(undefined);
  const [statusCounts, setStatusCounts] = useState(DEFAULT_STATUS_COUNTS);
  const [manage, setManage] = useState(undefined);

  const [fieldValidation, setFieldValidation] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isErrorName, setIsErrorName] = useState('');
  const [isFlushConfirmOpen, setIsFlushConfirmOpen] = useState(false);

  const refInputTitle = useRef();
  const maxLenName = 50;

  const permissions = usePermissions();
  const canEdit = permissions.canManageBlueprints;

  const isCustomApp =
    get(data, 'type') === 'custom-apps' || get(data, 'type') === 'custom-app';
  const isCustomScript =
    get(data, 'type') === 'custom-scripts' ||
    get(data, 'type') === 'custom-script';
  const isCustomPrinter =
    get(data, 'type') === 'custom-printers' ||
    get(data, 'type') === 'custom-printer';
  const isIPAApp = get(data, 'type') === 'ipa-app';
  const isAppStoreApp = get(data, 'type') === 'vpp-app';
  const isAutoApp = get(data, 'type') === 'automatic-app';
  const isOperatingSystem = get(data, 'type') === 'macos-release';
  const isKandjiSetup = get(data, 'type') === 'kandji-setup';

  const isFormData =
    isCustomApp ||
    isIPAApp ||
    isAppStoreApp ||
    isAutoApp ||
    isOperatingSystem ||
    isCustomPrinter ||
    isCustomScript;
  const isExisting = !!item;
  const isReadOnly = !canEdit || !isEditing || isWorking;

  const canDelete = !isIPAApp && !isAppStoreApp;

  const { selectedBlueprints, ...blueprintDataAndActions } =
    useBlueprintAssignment(data, checkBlueprintAssignmentConflicts);

  const fetchData = async (runIsLoading, id) => {
    try {
      if (runIsLoading) {
        setIsLoading(true);
      }
      const libItemsData = await LibraryItemsAPI.retrieve(item || id);
      setData(libItemsData);
      if (runIsLoading) {
        setIsLoading(false);
      }
    } catch (error) {
      if (runIsLoading) {
        setIsLoading(false);
      }
      toaster('Something went wrong.');
      history.push(`/library`);
    }
  };
  const initializeData = (initial) => {
    if (isExisting) {
      fetchData(true);
    } else {
      setData(initial);
      setIsEditing(true);
    }
  };
  const initializeManage = (optional) => {
    if (!manage) {
      setManage(optional);
    }
  };

  const validateName = (name) =>
    name.length > maxLenName && 'The title is too long.';

  const makeSaveHandler = async (
    serialize,
    form,
    initialType,
    initialIdentifier,
    defaultName,
    showFlushModal = false,
  ) => {
    try {
      const name = form.name || defaultName;
      const nameError = validateName(name);
      if (nameError) {
        scrollTo(() => refInputTitle.current.focus());
      } else {
        setIsWorking(true);
        const theForm = {
          ...form,
          name,
        };
        const serializedData = {
          ...serialize(theForm, manage),
          blueprints: selectedBlueprints,
          reassign_lib_item_to_bp: true,
        };
        const postableData = isFormData
          ? convertDataToFormData(serializedData)
          : serializedData;
        if (isExisting) {
          const libItemsData = await LibraryItemsAPI.update(item, postableData);
          toaster(`${libItemsData.name} saved!`);
          setData(libItemsData);
          fetchData();
          if (
            showFlushModal &&
            serializedData.data.install_enforcement === 'install_once'
          ) {
            setIsFlushConfirmOpen(true);
          }
        } else {
          if (
            !isFormData &&
            !isKandjiSetup &&
            !isCustomApp &&
            !isCustomScript &&
            !isCustomPrinter
          ) {
            postableData.type = initialType;
            postableData.identifier = initialIdentifier;
          }
          if (
            isKandjiSetup ||
            isCustomApp ||
            isCustomScript ||
            isCustomPrinter
          ) {
            if (isFormData) {
              postableData.append('type', initialType);
            } else {
              postableData.type = initialType;
            }
          }
          const libItemsData = await LibraryItemsAPI.create(postableData);
          toaster(`${libItemsData.name} added!`);
          history.push(`/library/${type}/${libItemsData.id}`);
          setData(libItemsData);
          fetchData(false, libItemsData.id);
          scrollTo();
        }
        setIsEditing(false);
        setIsWorking(false);
      }
    } catch (error) {
      setIsWorking(false);
      toaster('Something went wrong.');
    }
  };
  const onDelete = async () => {
    try {
      if (isExisting) {
        setIsWorking(true);
        await LibraryItemsAPI.delete(item);
        onModalClose();
        setIsWorking(false);
        toaster(`${data.name} deleted.`);
        const previousPage = get(history, 'location.state.previousPage', null);
        history.push(previousPage || `/library`);
      }
    } catch (error) {
      setIsWorking(false);
      toaster('Something went wrong.');
    }
  };
  const onClose = () => {
    const previousPage = get(history, 'location.state.previousPage', null);
    history.push(previousPage || `/library`);
  };
  const onEdit = () => {
    setIsEditing(true);
    toaster(`Now editing ${data.name}.`);
  };
  const onCancel = () => {
    if (isExisting) {
      fetchData();
      setIsErrorName('');
      setIsEditing(false);
      toaster('Editing cancelled.');
    } else {
      toaster('Adding cancelled.');
      history.push(`/library`);
    }
  };

  return (
    <IPALibraryContext.Provider
      value={{
        ...defaultValues,
        type,
        item,
        data,
        manage,
        fieldValidation,
        refInputTitle,
        canEdit,
        canDelete,
        isAppStoreApp,
        isAutoApp,
        isOperatingSystem,
        isCustomApp,
        isCustomScript,
        isCustomPrinter,
        isLoading,
        isWorking,
        isEditing,
        isErrorName,
        isExisting,
        isReadOnly,
        isFlushConfirmOpen,
        setData,
        setManage,
        setIsLoading,
        setIsWorking,
        setIsEditing,
        setIsErrorName,
        setIsFlushConfirmOpen,
        fetchData,
        initializeData,
        initializeManage,
        makeSaveHandler,
        validateName,
        onDelete,
        onClose,
        onEdit,
        onCancel,
        onModalOpen,
        onModalClose,
        selectedBlueprints,
        statusCounts,
        setStatusCounts,
        ...blueprintDataAndActions,
      }}
    >
      {children}
    </IPALibraryContext.Provider>
  );
};
