import { styled } from '@kandji-inc/nectar-ui';

const HighlightedText = styled('span', {
  backgroundColor: '$yellow20',
});

type ThreatListHighlightedTextProps = {
  children: string;
  search: string | undefined;
};

const ThreatListHighlightedText = (props: ThreatListHighlightedTextProps) => {
  const { children, search } = props;

  if (!search || !children) {
    return children;
  }

  const parts = children.split(new RegExp(`(${search})`, 'gi'));

  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === search.toLowerCase() ? (
          <HighlightedText key={i}>{part}</HighlightedText>
        ) : (
          part
        ),
      )}
    </>
  );
};

export default ThreatListHighlightedText;
