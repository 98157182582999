import { Flex, Heading, Toast, styled } from '@kandji-inc/nectar-ui';
import type { Computer } from '@shared/types/common.types';
import { InterfaceContext } from 'contexts/interface';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { useContext, useRef } from 'react';
import featureFlags from 'src/config/feature-flags';
import ThreatListUnassignedEmptyState from '../common/components/ThreatListUnassignedEmptyState';
import useThreatsToast from '../common/hooks/use-threats-toast';
import ThreatListEvents from './ThreatListEvents';

const Container = styled(Flex, {
  flexDirection: 'column',
  mb: 134,
  minHeight: 'calc(100vh - 134px)',
});

type ThreatListProps = Readonly<{
  computer?: Computer | undefined;
  isOnDeviceRecord?: boolean;
}>;

const ThreatList = (props: ThreatListProps) => {
  const { computer, isOnDeviceRecord } = props;
  useWhiteBg(!isOnDeviceRecord);
  const { sidebarOpened } = useContext(InterfaceContext);
  const toastRef = useRef(null);
  const { showToast } = useThreatsToast(toastRef);

  const isVulnFeatureFlagOn = featureFlags.getFlag('vm_06252024_vuln-poc');

  return (
    <>
      <Container>
        {!isOnDeviceRecord && !isVulnFeatureFlagOn && (
          <Heading as="h2" size="1" css={{ pt: '$6', pb: '$2' }}>
            Threats
          </Heading>
        )}
        {!isOnDeviceRecord && <ThreatListUnassignedEmptyState />}
        <ThreatListEvents
          deviceId={computer?.id}
          showToast={showToast}
          isOnDeviceRecord={isOnDeviceRecord}
        />
      </Container>
      <Toast
        testId="threat-toaster"
        managerRef={toastRef}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
};

export default ThreatList;
