import { BannerWrapper } from 'app/components/interface/banners/UniversalAlert';
import { VPPWarningBanner } from 'app/components/interface/banners/VPPWarningBanner';

import { AccountContext, usePermissions } from 'contexts/account';
import { DataContext } from 'contexts/data';
import get from 'lodash/get';
import React, { useContext, useEffect } from 'react';

import { InterfaceContext } from 'contexts/interface';
import { billingTypes } from 'src/app/common/constants';
import ABMExpiredBanner from '../../app/components/interface/banners/ABMExpiredBanner';
import IntegrationIsBrokenBanner from '../../app/components/interface/banners/IntegrationIsBrokenBanner';
import MDMConfigureBanner from '../../app/components/interface/banners/MDMConfigureBanner';
import RenewalBanner from '../../app/components/interface/banners/RenewalBanner';
import TokenExpirationBanner from '../../app/components/interface/banners/TokenExpirationBanner';
import TrialPeriodBanner from '../../app/components/interface/banners/TrialPeriodBanner';

// TODO: possibly rename this region
// TODO: ideally, combine all banners into one component and create content logic
// TODO: create a Banner shared/theme component
// TODO: ideally, bring BannerWrapper code into this file

const TRIAL_BILLING = [billingTypes.TRIAL];

const Banner = () => {
  const { currentCompany, accountReady } = useContext(AccountContext);
  const { Banner, setBannerTopOffset } = useContext(InterfaceContext);
  const { appDataReady } = useContext(DataContext);
  const permissions = usePermissions();

  useEffect(() => {
    setBannerTopOffset();
  }, [setBannerTopOffset, Banner]);

  const getBanner = () => {
    if (Banner !== null) {
      return Banner;
    }

    if (!get(currentCompany, 'is_mdm')) {
      return <MDMConfigureBanner />;
    }

    const vppInfo = get(currentCompany, 'vpp_info');
    const mdmInfo = get(currentCompany, 'mdm_info');
    const depInfo = get(currentCompany, 'dep_info');

    if (vppInfo && vppInfo.is_configured && !vppInfo.has_control) {
      return <VPPWarningBanner manager={vppInfo.current_manager} />;
    }

    const brokenIntegrationService = get(
      currentCompany,
      'broken_integration_service',
    );
    if (accountReady && brokenIntegrationService !== null) {
      return <IntegrationIsBrokenBanner service={brokenIntegrationService} />;
    }

    const billingType = get(currentCompany, 'billing_type');
    const showTrialPeriodBanner =
      TRIAL_BILLING.includes(billingType) &&
      get(currentCompany, 'trial_banner_data.is_enabled');
    if (showTrialPeriodBanner) {
      return (
        <TrialPeriodBanner
          trialBannerData={get(currentCompany, 'trial_banner_data')}
          billingType={billingType}
        />
      );
    }

    const renewal_end_date = get(
      currentCompany,
      'renewal_banner_data.renewal_end_date',
    );
    const isRenewalExpired =
      Date.parse(`${renewal_end_date} PST`) <= Date.now();
    const showRenewalBanner =
      get(currentCompany, 'renewal_banner_data.is_enabled') &&
      !isRenewalExpired;
    if (showRenewalBanner) {
      return (
        <RenewalBanner
          renewalBannerData={get(currentCompany, 'renewal_banner_data')}
        />
      );
    }

    const showABMExpiredBanner =
      get(currentCompany, 'dep_info.status') !== 'SUCCESS' &&
      get(currentCompany, 'dep_info.status_reason') === 'T_C_NOT_SIGNED';
    if (showABMExpiredBanner) {
      return <ABMExpiredBanner />;
    }

    return (
      <TokenExpirationBanner
        mdmInfo={mdmInfo}
        depInfo={depInfo}
        vppInfo={vppInfo}
        canManageSettings={permissions.canManageSettings}
      />
    );
  };

  return appDataReady && getBanner() ? (
    <BannerWrapper children={getBanner()} />
  ) : null;
};

export default Banner;
