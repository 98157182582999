/* istanbul ignore file - tested in LibraryItemService */
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AutomaticAppService extends NewLibraryItemService {
  constructor(data = { isAdding: false }) {
    super();
    this.isAdd = data.isAdding;
  }

  /**
   * Get either the auto app template or instance depending on current URL.
   * @override
   */
  async get(id, params) {
    return this.isAdd ? super.getTemplate(id, params) : super.get(id, params);
  }

  static installationTypes = {
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static updateTypes = {
    ANY: 'any',
    NEWEST: 'newest',
    CUSTOM: 'custom',
  };

  static notificationAlertTypeOptions = {
    NONE: {
      label: 'None',
      value: 0,
    },
    BANNERS: {
      label: 'Banners',
      value: 1,
    },
    ALERTS: {
      label: 'Alerts',
      value: 2,
    },
  };

  static notificationGroupingTypeOptions = {
    AUTOMATIC: {
      label: 'Automatic (default)',
      value: 0,
    },
    BY_APP: {
      label: 'By app',
      value: 1,
    },
    OFF: {
      label: 'Off',
      value: 2,
    },
  };

  static notificationPreviewTypeOptions = {
    ALWAYS: {
      label: 'Always',
      value: 0,
    },
    WHEN_LOCKED: {
      label: 'When unlocked',
      value: 1,
    },
    NEVER: {
      label: 'Never',
      value: 2,
    },
  };
}

export const automaticAppService = new AutomaticAppService();
export default AutomaticAppService;
