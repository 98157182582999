import { Banner, updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useContext, useCallback, useEffect } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import LibraryContext from '../../routes/library.context';
import initial from './initial-state';
import AppIdentifier from './sections/app-identifier';
import AppOptions from './sections/app-options';
import UserOptions from './sections/user-options';
import { appLockService } from './service/app-lock-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useAppLockService from './service/use-app-lock-service';

const AppLockPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const appOptionsUpdate = useCallback(update('appOptions', setModel), []);
  const userOptionsUpdate = useCallback(update('userOptions', setModel), []);
  const appIdentifierUpdate = useCallback(
    update('appIdentifier', setModel),
    [],
  );

  const { itemConfig } = useContext(LibraryContext);

  useEffect(() => populateSelectedDevices(model, setModel, itemConfig), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      extraSummary={
        <Banner theme="info" kind="block" className="b-mt">
          <span>App Lock requires device supervision.</span>
        </Banner>
      }
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={appLockService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      <AppIdentifier
        settings={model.appIdentifier}
        isDisabled={pageState.isDisabled}
        update={appIdentifierUpdate}
        validationDep={validationDep}
      />
      <AppOptions
        settings={model.appOptions}
        isDisabled={pageState.isDisabled}
        update={appOptionsUpdate}
      />
      <UserOptions
        settings={model.userOptions}
        isDisabled={pageState.isDisabled}
        update={userOptionsUpdate}
      />
    </LibraryItemPage>
  );
};

// AppLockPage.propTypes = {};

const AppLock = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useAppLockService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AppLockPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="app-lock-v2"
    />
  );
};

export default AppLock;
