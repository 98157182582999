import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

const GeneralCard = (props) => {
  const { setting, isDisabled, update } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">General</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.isBlockMacAppStore}
              onChange={() => update('isBlockMacAppStore', (p) => !p)}
              disabled={isDisabled}
              label="Block Mac App Store"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              On macOS 10.14 and above, enabling this option will prevent the
              Mac App Store from launching. On macOS 10.10-10.13, this option
              will only allow the Mac App Store to be used for software updates.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.disableSoftwareUpdateNotifications}
              onChange={() =>
                update('disableSoftwareUpdateNotifications', (p) => !p)
              }
              disabled={isDisabled}
              label="Disable software update notifications"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Enabling this option will disable update notifications from the
              Mac App Store.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.disableAppAdoption}
              onChange={() => update('disableAppAdoption', (p) => !p)}
              disabled={isDisabled}
              label="Disable app adoption"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Enabling this option will hide the app adoption offer on new macOS
              devices with included second-party apps, such as the iWork suite.
            </p>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default GeneralCard;
