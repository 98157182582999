import { Button, ButtonGroup, setClass } from '@kandji-inc/bumblebee';
import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import React, { useState, useContext } from 'react';
import CustomizePanel from '../customize-panel';
import useCompanyLogo from '../hooks/use-company-logo.hook';
import KandjiLogo from '../kandji-logo';
import setPreviewImage, { previewPaths } from '../set-preview-image';
import ToggleButton from '../toggle-button';

const InstallScreen = (props) => {
  const { isDarkMode, setIsDarkMode, isDisabled, setGlobalValidations } =
    useContext(KandjiSetupCtx);
  const { model, setModel, initialStateCopy, beforeEditModel } = props;

  const form = {
    key: 'installScreen',
    title: 'Customize Install screen',
    header: {
      label: 'Header',
      maxLength: 35,
    },
    subHeader: {
      label: 'Subheader',
      maxLength: 205,
    },
  };

  const [isInstallConfig, setIsInstallConfig] = useState(true);

  const onValidations = (v) => {
    setGlobalValidations((p) => ({ ...p, [v.name]: v.isInvalid }));
  };

  const companyLogo = useCompanyLogo({ model, isDarkMode });

  return (
    <div className="b-library__setting-card">
      <h3 className="b-h3 b-library__setting-card--title">Install screen</h3>
      <p className="b-txt b-library__setting-card--subtitle">
        Customize the header and subheader text on the Install screen. Installed
        items shown in the preview are for reference only.
      </p>
      <div className="b-library__setting-rows">
        <div className="b-library__setting-row--single-col">
          <div className="b-flex-vc b-flex-btw">
            <div className="b-flex-g b-flex-vc">
              <CustomizePanel
                form={form}
                model={model}
                setModel={setModel}
                beforeEditModel={beforeEditModel}
                initialStateCopy={initialStateCopy}
                disabled={isDisabled}
                multiLineTextAllowed={false}
                onValidations={onValidations}
                autoSubheader
              />
              <Button
                onClick={() => setIsInstallConfig((p) => !p)}
                icon={isInstallConfig ? 'angle-up' : 'angle-down'}
                kind="link"
                size="small"
              >
                {isInstallConfig ? 'Collapse preview' : 'Expand preview'}
              </Button>
            </div>
            <div
              className={setClass(
                'k-ks-toggle',
                !isInstallConfig && '--hidden',
              )}
            >
              <ButtonGroup
                initialSelected={isDarkMode ? 1 : 0}
                activeWatch={isDarkMode ? 1 : 0}
                onToggle={(index) => setIsDarkMode(index !== 0)}
              >
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(false)}
                >
                  Light Mode
                </ToggleButton>
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(true)}
                >
                  Dark Mode
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div
            className={setClass([
              'k-library-preview',
              !isInstallConfig ? 'k-library-preview--collapsed' : '',
            ])}
          >
            <div className="k-library-preview__bg">
              <img
                draggable="false"
                src={setPreviewImage({
                  isDarkMode,
                  displayMode: model.general.displayMode,
                })}
                alt="Preivew"
              />
              <div className="k-library-preview__item">
                <img
                  draggable="false"
                  className="k-library-preview__frame"
                  src={
                    isDarkMode
                      ? previewPaths.installFrameDark
                      : previewPaths.installFrame
                  }
                  alt="Install Screen"
                />
                <img
                  draggable="false"
                  className={setClass(
                    'k-library-preview__logo',
                    companyLogo.isSquare && '--round-corner-preview',
                  )}
                  src={companyLogo.src}
                  alt="Company Logo"
                />
                <KandjiLogo isDarkMode={isDarkMode} />
                <div
                  className={`k-library-preview__content preview-content--install ${
                    isDarkMode ? 'k-library-preview__content--dark' : ''
                  }`}
                >
                  <h2 className="k-library-preview__title">
                    {model.installScreen.header}
                  </h2>
                  <p className="k-library-preview__copy">
                    {model.installScreen.subHeader}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallScreen;
