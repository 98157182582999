import WifiService from 'features/library-items/items/wifi/service/wifi-service';
import initialState from '../../../../initial-state';

const { keys } = WifiService;
const trustKeys = keys.trust;

const transformTrustedCertsFromApi = (certs = []) =>
  certs.map(({ fileName, fileSize, type, data }) => ({
    name: fileName,
    size: fileSize,
    type,
    data,
  }));

/* Cert Trust is created from the authentication object in the data returned
  from the api. i.e there is no dedicated cert trust root field. */
const certificateTrust = (data) => {
  const { authentication } = data;

  if (!authentication) {
    return initialState.trust;
  }

  const {
    isEnterprise = false,
    trustedCertificates = [],
    settings = {},
  } = authentication;
  const { EAPClientConfiguration = {} } = settings || {};
  const { TLSTrustedServerNames, TLSAllowTrustExceptions = false } =
    EAPClientConfiguration || {};

  return {
    [trustKeys.serverCertificateNames]: TLSTrustedServerNames?.map(
      (value, idx) => ({ value, keyField: idx }),
    ) || [{ keyField: '1', value: '' }],
    [trustKeys.specifyServerCertificateNames]:
      isEnterprise && TLSTrustedServerNames != null,
    [trustKeys.specifyCertificates]: Boolean(trustedCertificates?.length),
    [trustKeys.certificates]: transformTrustedCertsFromApi(
      trustedCertificates || [],
    ),
    [trustKeys.allowTrustExceptions]: TLSAllowTrustExceptions || false,
  };
};

export default certificateTrust;
