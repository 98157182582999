import {
  Banner,
  Checkbox,
  Label,
  Radio,
  RadioGroup,
  Select,
} from '@kandji-inc/nectar-ui';
import React, { memo } from 'react';
import { Setting } from 'src/features/library-items/template';
import type { NetworkMountsProps } from '../../devicecontrol.types';
import {
  AppliesToUsersValue,
  UserAccessValue,
} from '../../devicecontrol.types';
import DeviceControlService from '../../service/device-control-service';

const NetworkMounts = (props: NetworkMountsProps) => {
  const { update, isDisabled, settings } = props;

  const isUserAccessReadWrite =
    settings.userAccess === UserAccessValue.READ_WRITE;
  const isUserAccessBlock = settings.userAccess === UserAccessValue.BLOCK;
  const appliesToStandardUsers =
    settings.appliesTo === AppliesToUsersValue.STANDARD;
  const appliesToAllUsers = settings.appliesTo === AppliesToUsersValue.ALL;

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">Server volumes</h3>
        <div data-testid="isManaged">
          <Setting.HeaderToggle
            isEnabled={settings.isManaged}
            onChange={(v) => update('isManaged', !settings.isManaged)}
            isDisabled={isDisabled}
            chipText={{
              enabled: 'Managed',
              disabled: 'Not managed',
            }}
          />
        </div>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure access privileges for server volume mounts.
        </p>
        {!settings.isManaged && (
          <Banner
            className="mt-2"
            text="Not managed: All users will be able to mount server volumes."
          />
        )}
      </Setting.SubHeader>
      {settings.isManaged && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Title>
              <Label above>Access privileges</Label>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Specify the access privileges for server volume mounts.
              </p>
              {isUserAccessReadWrite && (
                <Banner text="Full access to server volume mounts (normal macOS behavior). To further restrict server volume access, set the access privileges to No access." />
              )}
              {isUserAccessBlock && (
                <Banner text="Server volume mounts will be blocked." />
              )}
              <Banner
                theme="warning"
                text="Note: The server volume or file server may have differing access privileges in place. The most restrictive access privilege setting will be enforced."
              />
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={
                  DeviceControlService.userAccessSelectOptionsNetworkMounts
                }
                value={settings.userAccess}
                onChange={(v) => update('userAccess', v)}
                disabled={isDisabled}
                testId="userAccess"
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title>
              <Label above>Access privileges applies to</Label>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Specify whether the access privileges will apply to all users or
                only to standard users.
              </p>
              {appliesToAllUsers && (
                <Banner text="All user accounts (admin and standard) in macOS." />
              )}
              {appliesToStandardUsers && (
                <Banner text="All standard users in macOS." />
              )}
            </Setting.Helpers>
            <Setting.Controls>
              <div data-testid="appliesTo">
                <RadioGroup
                  value={settings.appliesTo}
                  onValueChange={(v) =>
                    update('appliesTo', v as AppliesToUsersValue)
                  }
                  orientation="vertical"
                >
                  {DeviceControlService.appliesToUsersRadioOptions.map(
                    (item) => (
                      <Radio
                        key={`appliesTo-${item.value}`}
                        disabled={isDisabled}
                        {...item}
                      />
                    ),
                  )}
                </RadioGroup>
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title>
              <Label above>Device alerts</Label>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                Display an alert message to users when the mounting of a server
                volume is blocked.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <div data-testid="deviceAlerts">
                <Checkbox
                  label="Display alert messages"
                  checked={settings.deviceAlerts}
                  onCheckedChange={(v) => update('deviceAlerts', v)}
                  disabled={isDisabled}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default memo(NetworkMounts);
