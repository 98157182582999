/* istanbul ignore file */
import {
  Box,
  Button,
  Flex,
  Loader,
  Text,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import { Link } from 'react-router-dom';
import { getItemConfig } from 'src/features/library-items/library/api/transformer';
import { paths } from '../blueprints/common';
import { useTags } from './use-tags';

interface TagRuleFinderProps {
  id: string;
}

const TagRuleFinder = (props: TagRuleFinderProps) => {
  const { id } = props;

  const { getTag } = useTags();
  const { data: rules, refetch, isLoading } = getTag(id, false);

  const { used_in_rule_assignment_maps = [], used_in_rule_library_items = [] } =
    rules || {};

  const hasNoRules =
    !used_in_rule_assignment_maps.length && !used_in_rule_library_items.length;

  return (
    <Tooltip
      hideWhenDetached
      portalContainer={document.getElementById('tags-scroll')}
      theme="light"
      css={{
        zIndex: 99999,
        padding: '$3 $4',
        minWidth: '304px',
        width: '304px',
        maxHeight: '295px',
        borderRadius: '$rounded',
        overflow: 'hidden',
      }}
      onOpenChange={(open) => {
        if (open) {
          refetch();
        }
      }}
      content={
        hasNoRules || isLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            css={{
              color: '$neutral70',
              minHeight: '152px',
            }}
          >
            {isLoading ? <Loader /> : 'This tag is not used in any rules.'}
          </Flex>
        ) : (
          <Flex flow="column" gap="sm">
            <Text variant="description" css={{ fontWeight: '$medium' }}>
              This tag is used in rules in the following places:
            </Text>
            <Flex
              flow="column"
              gap="sm"
              css={{ maxHeight: '246px', overflow: 'auto' }}
            >
              {used_in_rule_assignment_maps.length > 0 && (
                <Box>
                  <Text css={{ color: '$neutral70', padding: '$1 0' }}>
                    Assignment Maps
                  </Text>
                  {used_in_rule_assignment_maps.map((am) => (
                    <Link
                      to={paths.flowBlueprint(am.id)}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Text
                        key={am.id}
                        css={{
                          color: '$neutral90',
                          padding: '$1 0',
                          fontWeight: '$medium',
                        }}
                      >
                        {am.name}
                      </Text>
                    </Link>
                  ))}
                </Box>
              )}

              {used_in_rule_library_items.length > 0 && (
                <Box>
                  <Text css={{ color: '$neutral70', padding: '$1 0' }}>
                    Library Items
                  </Text>
                  {used_in_rule_library_items.map((li) => {
                    const itemConfig = getItemConfig(li);

                    return (
                      <Link
                        to={itemConfig.getUrl({ id: li.id })}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Text
                          key={li.id}
                          css={{
                            color: '$neutral90',
                            padding: '$1 0',
                            fontWeight: '$medium',
                          }}
                        >
                          {li.name}
                          {li.instance_name && ', '}
                          {li.instance_name}
                        </Text>
                      </Link>
                    );
                  })}
                </Box>
              )}
            </Flex>
          </Flex>
        )
      }
      trigger="click"
    >
      <Button
        variant="subtle"
        icon={{ name: 'circle-info' }}
        compact
        css={{ padding: '4px' }}
      />
    </Tooltip>
  );
};

export default TagRuleFinder;
