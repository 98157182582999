import { Icon } from '@kandji-inc/bumblebee';
import {
  annotateComputer,
  exportComputers,
  queryComputers,
  setComputers,
} from 'app/_actions/computer';
import { setModal, setSnackbar } from 'app/_actions/ui';
import { links } from 'app/common/constants';
import { withPermissions } from 'contexts/account';
import isEmpty from 'lodash/isEmpty';
import { parse as parseQueryString } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { initialize } from 'redux-form';
import uuidv1 from 'uuid/v1';
import history from '../../router/history';
import { TableHeader } from '../interface/Base';
import SearchString from '../interface/SearchString';
import TableFilters from '../interface/TableFilters';
import CircleButton from '../interface/buttons/CircleButton';
import { Tooltip } from '../interface/tooltips/Tooltip';
import { BootstrapTable, TableHeaderColumn } from './BootstrapTable';
import Table from './Table';
import {
  formatTime,
  getFiltersFromUrl,
  getHelperText,
  getStatusComputersCircleIconClass,
  getStatusComputersColorClass,
  parseDateFromFilter,
} from './helpers';
import { renderTableActions } from './utils';

export class ComputersTableBlueprint extends Table {
  constructor(props) {
    super(props);
    this.serverSideSorting = true;
    this.selectRowId = 'id';
    this.serverSidePagination = true; // Don't remove! It's used on parent
    this.state = {
      ...this.state,
      isLoading: false,
      data: [],
      selectedRows: new Set(),
      currPage: 1,
    };
    this.fetchFunc = this.fetchComputers.bind(this);
    this.fetchComputers = this.fetchComputers.bind(this);
    this.renderNoDataView = this.renderNoDataView.bind(this);
    this.formatStatusRow = this.formatStatusRow.bind(this);
    this.formatNameRow = this.formatNameRow.bind(this);
    this.formatModelRow = this.formatModelRow.bind(this);
    this.formatSerialRow = this.formatSerialRow.bind(this);
    this.formatDuplicatesRow = this.formatDuplicatesRow.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchComputers();
  }

  onChangeBlueprint(e) {
    const { blueprintNames, computers } = this.props;
    e.preventDefault();
    const { selectedRows } = this.state;
    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      const blackListOfBlueprint = [...selectedRows].map((computer_id) => {
        const computer = computers.find((comp) => comp.id === computer_id);
        if (
          computer &&
          Object.keys(blueprintNames).includes(computer.blueprint_id)
        ) {
          return computer.blueprint_id;
        }
        return '';
      });
      this.props.setModal('COMPUTER_CHANGE_BLUEPRINT', {
        computers: [...selectedRows],
        blackListOfBlueprint, // it needs for excluding from select
        fetchComputers: this.fetchComputers.bind(this),
      });
    }
  }

  onDeleteComputer(e) {
    e.preventDefault();
    const { selectedRows } = this.state;
    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      this.props.setModal('COMPUTER_DELETE', {
        computers: [...selectedRows],
        blueprintId: this.props.blueprintId,
        fetchComputers: this.fetchComputers.bind(this),
      });
    }
  }

  getRequestQuery(blueprintId) {
    const { location } = this.props;
    const filters = getFiltersFromUrl(location);
    const options = [];
    if (blueprintId) {
      options.push({
        name: 'blueprint',
        value: [blueprintId],
        operator: 'equals',
      });
    } // For blueprint page

    if (filters) {
      filters.map((filter) => {
        if (!Object.keys(filter).length) {
          return;
        }
        let option = {};

        switch (filter.name) {
          case 'blueprint':
          case 'computerName':
          case 'model':
          case 'serial':
          case 'os':
          case 'assetUser':
          case 'assetTag':
          case 'agentVersion':
            option = filter;
            options.push(option);
            break;

          case 'status':
            option.operator = filter.operator;
            option.name = filter.name;
            option.value = filter.value;

            const alertStatus = option.value
              ? option.value.indexOf('WARNING,ERROR')
              : -1;
            if (alertStatus > -1) {
              option.value.splice(alertStatus, 1, 'WARNING', 'ERROR');
            }

            const passStatus = option.value
              ? option.value.indexOf('PASS,REMEDIATED')
              : -1;
            if (passStatus > -1) {
              option.value.splice(passStatus, 1, 'PASS', 'REMEDIATED');
            }
            options.push(option);
            break;

          case 'lastCheckIn':
          case 'firstEnrollment':
          case 'lastEnrollment':
            option.name = filter.name;
            option.operator = filter.operator;
            if (filter.operator === 'between' && filter.value) {
              const splittedValues = filter.value.split('@');
              const from = parseDateFromFilter(splittedValues[0]).toISOString();
              const to = parseDateFromFilter(splittedValues[1]).toISOString();
              option.value = `${from}@${to}`;
            } else if (filter.operator !== 'between' && filter.value) {
              option.value = parseDateFromFilter(filter.value).toISOString();
            } else {
              option.value = null;
            }
            options.push(option);
            break;
          default:
            break;
        }
      });
    }
    return options;
  }

  fetchComputers(queryParams) {
    const {
      blueprintId,
      setComputers: setComputersValues,
      queryComputers: queryComputersValues,
    } = this.props;

    const requestQuery = this.getRequestQuery(blueprintId);
    this.setState({ isLoading: true });
    return queryComputersValues({ ...queryParams, filters: requestQuery })
      .then((res) => {
        const computers = res.results;
        const { blueprintNames } = this.props;
        const result = computers.map((computer) =>
          annotateComputer(computer, blueprintNames),
        );
        this.setState({ data: result, isLoading: false });
        setComputersValues(result);
        return Promise.resolve(res);
      })
      .catch((err) => console.log(err));
  }

  renderBulkActions() {
    const actions = [
      ['Change Blueprint', this.onChangeBlueprint.bind(this), 'circle-plus'],
      ['Delete Devices', this.onDeleteComputer.bind(this), 'trash-can'],
    ];

    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="span">
          <Tooltip tooltipMessage="Bulk Actions">
            <CircleButton icon="ellipsis" />
          </Tooltip>
        </DropdownToggle>
        <DropdownMenu right>
          {actions.map((action) => {
            if (typeof action === 'string') {
              return (
                <DropdownItem
                  key={uuidv1()}
                  className="dropdown-header-actions"
                  header
                >
                  {action}
                </DropdownItem>
              );
            }
            const [actionName, actionFunction, iconClass] = action;
            return (
              <DropdownItem
                key={uuidv1()}
                className="new-action-item-design cursor-pointer"
                onClick={actionFunction}
              >
                {iconClass && (
                  <Icon
                    name={iconClass}
                    style={{ marginRight: '15px' }}
                    aria-hidden="true"
                    size="sm"
                  />
                )}
                {actionName}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  renderExportData() {
    const { isExporting } = this.state;
    const exportData = () => {
      const { blueprintId, exportComputers: exportComputersValues } =
        this.props;
      const filters = this.getRequestQuery(blueprintId);
      const fields = ['name', 'model', 'last_seen_dt', 'serial_number'];
      const ordering = 'name';
      this.setState({ isExporting: true }, () => {
        exportComputersValues(filters, fields, ordering).then(() =>
          this.setState({ isExporting: false }),
        );
      });
    };
    return (
      <Tooltip tooltipMessage="Export CSV">
        <CircleButton
          icon="arrow-down-to-line"
          onClick={exportData}
          isProcessing={isExporting}
        />
      </Tooltip>
    );
  }

  renderActionButtons() {
    return <div className="custom-table-toolbar" />;
  }

  formatNameRow = (cell, row) => (
    <span title={row.computerName}>{row.computerName}</span>
  );

  formatStatusRow = (cell, row) => {
    const title = getHelperText(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    const iconClass = getStatusComputersCircleIconClass(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    const iconColorClass = getStatusComputersColorClass(
      row.status,
      null,
      row.deferred_install,
      row.is_missing,
    );
    return (
      <div>
        <i
          className={`table-row-status-icon ${iconClass} ${iconColorClass}`}
          id={`statusRow${row.id}`}
        />
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`statusRow${row.id}`}
        >
          {title}
        </UncontrolledTooltip>
      </div>
    );
  };

  formatBlueprintNameRow = (cell, row) => (
    <span title={row.blueprint}>{row.blueprint}</span>
  );

  formatModelRow = (cell, row) => <span title={row.model}>{row.model}</span>;

  formatSerialRow = (cell, row) => {
    const serialNumber = row.serial_number || 'No info found';
    return <span title={serialNumber}>{serialNumber}</span>;
  };

  formatDuplicatesRow = (cell, row) => {
    if (row.duplicates && row.duplicates.length > 0) {
      let title = 'Duplicates:\n';
      row.duplicates.forEach((el) => {
        title += `${el.name} - ${el.id}\n`;
      });
      return (
        <div>
          <Icon
            id={`duplicatesRow${row.id}`}
            name="octagon-exclamation"
            className="c-dark-red"
          />
          <UncontrolledTooltip
            placement="top"
            delay={{ show: 400, hide: 0 }}
            innerClassName="custom-helper"
            target={`duplicatesRow${row.id}`}
          >
            {title}
          </UncontrolledTooltip>
        </div>
      );
    }
    return <div />;
  };

  renderNoDataView() {
    const { computers, noDataView, location } = this.props;

    const activeFilters = parseQueryString(location.search, {
      arrayFormat: 'bracket',
    });
    const isNoDataViewNeed =
      !computers.length &&
      !this.state.isLoading &&
      isEmpty(activeFilters) &&
      noDataView;
    return isNoDataViewNeed ? noDataView : null;
  }

  render() {
    const { computers, blueprintId, permissions } = this.props;
    const options = {
      ...this.defaultOptions,
      onRowClick: (row) => history.push(`${links.devices}/${row.id}`),
      defaultSortName: 'name',
      defaultSortOrder: 'asc',
      page: this.state.currPage,
      resultsString: 'Devices',
      toolBar: this.renderActionButtons.bind(this),
    };

    const NoDataView = this.renderNoDataView();
    if (NoDataView) {
      return (
        <div data-loading={this.state.isLoading} className="ml-4 mr-4">
          <TableHeader title="Devices" sticky />
          {NoDataView}
        </div>
      );
    }

    return (
      <div data-loading={this.state.isLoading} className="ml-4 mr-4">
        <TableHeader title="Devices" sticky>
          <SearchString
            label="Search Devices..."
            searchFunc={(e) => this.table.handleSearch(e)}
          />
          {renderTableActions([
            this.renderExportData(),
            permissions.canManageDevices && this.renderBulkActions(),
          ])}
        </TableHeader>
        <BootstrapTable
          ref={(node) => {
            this.table = node;
          }}
          data={computers}
          version="4"
          remote={() => ({
            pagination: true,
          })}
          options={options}
          pagination
          selectRow={this.selectRowProp}
          trClassName="table-row-clickable"
          tableContainerClass={blueprintId ? 'bst-borderless' : ''}
          containerClass={
            blueprintId ? 'bst-borderless old-table' : 'old-table'
          }
        >
          <TableHeaderColumn
            dataField="status"
            dataAlign="right"
            dataFormat={this.formatStatusRow}
            columnClassName="td-icon"
            className="td-icon"
          />

          <TableHeaderColumn
            dataField="name"
            dataSort
            dataFormat={this.formatNameRow}
          >
            Device Name
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="model"
            dataFormat={this.formatModelRow}
            dataSort
          >
            Model
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="last_seen_dt"
            dataFormat={(cell) => formatTime(cell, false, null, false, true)}
            dataSort
          >
            Checked In
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="duplicates"
            dataFormat={this.formatDuplicatesRow}
            columnClassName="td-icon"
            className="td-icon"
          />

          <TableHeaderColumn
            dataSort
            dataField="serial_number"
            dataFormat={this.formatSerialRow}
          >
            Serial
          </TableHeaderColumn>

          <TableHeaderColumn dataSort dataField="id" isKey hidden />
          {!blueprintId && (
            <TableHeaderColumn
              dataSort
              dataField="blueprint"
              dataFormat={this.formatBlueprintNameRow}
            >
              Blueprint
            </TableHeaderColumn>
          )}
        </BootstrapTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  computers: state.data.computers,
  blueprints: state.data.blueprints,
  blueprintNames: state.data.blueprintNames,
  isScroll: state.blueprintRecord.isScroll,
  tableFilters: state.form.TableFilters,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
      setSnackbar,
      exportComputers,
      setComputers,
      queryComputers,
      initialize,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(ComputersTableBlueprint));
