import {
  ButtonSelect,
  Checkbox,
  Flex,
  Hint,
  Icon,
  Label,
  Radio,
  RadioGroup,
  TextField,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import type {
  Blueprint,
  DeviceFamily,
  GlobalFilters,
} from 'src/features/visibility/prism/hooks/use-global-filters';
import SearchBlueprintsEmptyState from './SearchBlueprintsEmptyState';

type GlobalFilterModalContentProps = {
  blueprints:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  globalFilters: GlobalFilters;
  selectedBlueprints: Blueprint[];
  setSelectedBlueprints: React.Dispatch<React.SetStateAction<Blueprint[]>>;
  selectedDeviceFamilies: DeviceFamily[];
  setSelectedDeviceFamilies: React.Dispatch<
    React.SetStateAction<DeviceFamily[]>
  >;
  blueprintFilterOption: string;
  setBlueprintFilterOption: React.Dispatch<React.SetStateAction<string>>;
  errors: {
    deviceFamilies: string;
    blueprints?: string;
  };
};

const GlobalFilterModalContent = ({
  blueprints = [],
  globalFilters,
  selectedBlueprints,
  setSelectedBlueprints,
  selectedDeviceFamilies,
  setSelectedDeviceFamilies,
  blueprintFilterOption,
  setBlueprintFilterOption,
  errors,
}: GlobalFilterModalContentProps) => {
  const onChange = (family: DeviceFamily) => {
    const selectedIndex =
      selectedDeviceFamilies?.length && selectedDeviceFamilies.includes(family)
        ? selectedDeviceFamilies.filter((item) => item !== family)
        : [...(selectedDeviceFamilies || []), family];
    setSelectedDeviceFamilies(selectedIndex);
  };

  const [blueprintSearchInput, setBlueprintSearchInput] = React.useState('');
  const handleBlueprintSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setBlueprintSearchInput(event.target.value);
  };
  const filteredBlueprints = React.useMemo(() => {
    if (blueprintSearchInput === '') {
      return blueprints;
    }
    return blueprints?.filter((blueprint) =>
      blueprint.label
        .toLowerCase()
        .includes(blueprintSearchInput.toLowerCase()),
    );
  }, [blueprintSearchInput, blueprints]);

  React.useEffect(() => {
    if (blueprintFilterOption === 'all_blueprints') {
      setSelectedBlueprints([]);
    }
  }, [blueprintFilterOption, setSelectedBlueprints]);

  // as soon as one blueprint is selected switch blueprintFilterOption to 'specific_blueprints
  React.useEffect(() => {
    if (selectedBlueprints.length > 0) {
      setBlueprintFilterOption('specific_blueprints');
    }
  }, [selectedBlueprints]);

  // sync selectedBlueprints with globalFilters.blueprints
  React.useEffect(() => {
    if (globalFilters.blueprints) {
      setSelectedBlueprints(
        globalFilters.blueprints?.map((blueprint) => blueprint),
      );
    }
  }, [globalFilters.blueprints, setSelectedBlueprints]);

  // sync selectedDeviceFamilies with globalFilters.device_families
  React.useEffect(() => {
    if (
      globalFilters.deviceFamilies &&
      globalFilters.deviceFamilies.length === 0
    ) {
      setSelectedDeviceFamilies(['Mac', 'iPhone', 'iPad', 'AppleTV']);
    } else {
      setSelectedDeviceFamilies(globalFilters.deviceFamilies);
    }
  }, [globalFilters.deviceFamilies, setSelectedDeviceFamilies]);

  return (
    <Flex
      flow="column"
      gap="lg"
      css={{ width: '552px', height: '584px', p: '12px' }}
    >
      <Flex flow="column" gap="sm" css={{ width: '100%' }}>
        <Flex flow="column" gap="md">
          <Label variant="description">Device family</Label>
          <ButtonSelect.Root
            type="multiple"
            value={selectedDeviceFamilies}
            css={{
              width: '100%',
              '& button': {
                display: 'inline-flex',
                gap: '$1',
                width: '25%',
              },
            }}
          >
            <ButtonSelect.Item
              value="Mac"
              onClick={() => onChange('Mac')}
              className="pendo-prism-global-filters-device-family__mac"
            >
              <Icon name="sf-desktop-computer" />
              Mac
            </ButtonSelect.Item>
            <ButtonSelect.Item
              value="iPhone"
              onClick={() => onChange('iPhone')}
              className="pendo-prism-global-filters-device-family__iphone"
            >
              <Icon name="sf-iphone" />
              iPhone
            </ButtonSelect.Item>
            <ButtonSelect.Item
              value="iPad"
              onClick={() => onChange('iPad')}
              className="pendo-prism-global-filters-device-family__ipad"
            >
              <Icon name="sf-ipad-landscape" />
              iPad
            </ButtonSelect.Item>
            <ButtonSelect.Item
              value="AppleTV"
              onClick={() => onChange('AppleTV')}
              className="pendo-prism-global-filters-device-family__appletv"
            >
              <Icon name="sf-apple-tv" />
              Apple TV
            </ButtonSelect.Item>
          </ButtonSelect.Root>
        </Flex>
        {errors.deviceFamilies && (
          <Hint label={errors.deviceFamilies} variant="error" />
        )}
      </Flex>
      <Flex flow="column" gap="lg" hFull>
        <Label variant="description">Blueprints</Label>
        <RadioGroup
          value={blueprintFilterOption}
          onValueChange={setBlueprintFilterOption}
          orientation="vertical"
        >
          <Radio
            label="All Blueprints"
            checked={blueprintFilterOption === 'all_blueprints'}
            value="all_blueprints"
            id="all_blueprints"
            // @ts-expect-error -- ok for pendo tracking
            className="pendo-prism-global-filters-blueprint__all-blueprints"
          />
          <Radio
            label="Specific Blueprints"
            checked={
              blueprintFilterOption === 'specific_blueprints' ||
              selectedBlueprints.length > 0
            }
            value="specific_blueprints"
            id="specific_blueprints"
            // @ts-expect-error -- ok for pendo tracking
            className="pendo-prism-global-filters-blueprint__specific-blueprints"
          />
        </RadioGroup>
        <Flex flow="column" gap="lg" hFull css={{ ml: '24px', mb: '16px' }}>
          <TextField
            value={blueprintSearchInput}
            onChange={handleBlueprintSearchInputChange}
            icon="magnifying-glass"
            placeholder="Search Blueprints"
            // @ts-expect-error -- ok for pendo tracking
            className="pendo-prism-global-filters-blueprint__search-blueprints-input"
          />
          <Flex
            flow="column"
            gap="lg"
            css={{
              height: '100%',
              maxHeight: '336px',
              overflowY: 'auto',
              pl: '12px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#F5F5FA',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#B7BFC7',
                borderRadius: '4px',
                height: '50px',
              },
            }}
          >
            {filteredBlueprints.length > 0 ? (
              filteredBlueprints.map((blueprint) => (
                <Checkbox
                  key={blueprint.value}
                  label={blueprint.label}
                  id={blueprint.value}
                  aria-label={blueprint.label}
                  checked={selectedBlueprints.some((b) => {
                    if (typeof b === 'string') {
                      return b === blueprint.value;
                    }
                    return b.id === blueprint.value;
                  })}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedBlueprints([
                        ...selectedBlueprints,
                        { id: blueprint.value, name: blueprint.label },
                      ]);
                    } else {
                      setSelectedBlueprints(
                        selectedBlueprints.filter((b) => {
                          if (typeof b === 'string') {
                            return b !== blueprint.value;
                          }
                          return b.id !== blueprint.value;
                        }),
                      );
                    }
                  }}
                />
              ))
            ) : (
              <SearchBlueprintsEmptyState />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GlobalFilterModalContent;
