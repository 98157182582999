import { useQuery } from '@tanstack/react-query';
import { msDeviceComplianceService } from '../services';
import { msDeviceComplianceKeys } from './query-key-factory';

const useGetIntegrationUserGroupList = (
  searchTerm?: string,
  groupIds?: string[],
) =>
  useQuery({
    queryFn: () =>
      msDeviceComplianceService.getIntegrationUserGroupList(
        searchTerm,
        groupIds,
      ),
    queryKey: msDeviceComplianceKeys.userGroupList(
      searchTerm,
      groupIds?.join(','),
    ),
  });

export default useGetIntegrationUserGroupList;
