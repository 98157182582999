import { FilterButton, MultiSelect } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';
import type { OnApply, OnClear } from '../../../threat.types';

type FilterOption<T> = {
  key: T;
  value: T;
  label: string;
  checked?: boolean;
};

type MultiselectFilterProps<T> = {
  values: T[] | undefined;
  onChange: OnApply;
  onClear: OnClear;
  name: string;
  label: string;
  options: FilterOption<T>[];
  buttonOptions?: FilterOption<T>[];
  maxWidth?: number;
  itemMaxWidth?: number;
  optionCss?: CSS;
  menuCss?: CSS;
  header?: React.ReactNode;
  isLoading?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const MultiselectFilter = <T,>(props: MultiselectFilterProps<T>) => {
  const {
    values = [],
    onChange,
    onClear,
    name,
    label,
    options,
    buttonOptions = options,
    maxWidth = 250,
    itemMaxWidth = 200,
    optionCss = {},
    menuCss = { width: 240 },
    header,
    isLoading,
    onOpenChange,
  } = props;

  const optionsSelected = buttonOptions
    .filter((item) => values.includes(item.value))
    .map((item) => ({ ...item, label: item.buttonLabel || item.label }));

  return (
    <MultiSelect
      multi
      options={options}
      value={values}
      onChange={(selected: T[]) => {
        if (selected.length === 0) {
          onClear(name);
        } else {
          onChange(name, selected);
        }
      }}
      footer={{
        showClear: true,
        clearLabel: 'Clear',
        clearDisabled: values.length === 0,
        handleClear: () => onClear(name),
      }}
      componentCss={{
        menu: { zIndex: 1, ...menuCss },
        option: { '& [role=label]': { cursor: 'pointer' }, ...optionCss },
      }}
      customHeader={header}
      noOptionsFoundMessage={isLoading ? 'Loading...' : 'No options found'}
      onOpenChange={onOpenChange}
    >
      <FilterButton
        filtersSelected={Boolean(values.length)}
        selectedFilters={
          values.length === buttonOptions.length ? [] : optionsSelected
        }
        showRemove={false}
        css={{ height: 28, strong: { fontWeight: '$medium' } }}
        data-testid={`${name}-filter`}
        maxWidth={maxWidth}
        itemMaxWidth={itemMaxWidth}
      >
        {() => (
          <>
            {label}
            {values.length ? ':' : ''}
            {values.length === buttonOptions.length &&
              buttonOptions.length > 0 && <strong>&nbsp;All</strong>}
          </>
        )}
      </FilterButton>
    </MultiSelect>
  );
};

export default MultiselectFilter;
