import {
  Checkbox,
  LazyScriptEditor as ScriptEditor,
  mapInvalidIndexToField,
  useInvalidations,
  useIsChanged,
  useRefs,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { memo, useState, useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';

import { useAppStoreAppsContext } from './hooks/app-store-apps-provider';

const ConfigurationCard = (props) => {
  const {
    setting,
    update,
    isDisabled,
    isEditing,
    supportedDeviceFamilies,
    validationDep,
    cardBanner,
  } = props;
  const [isChecked, setIsChecked] = useState(Boolean(setting.value));
  const [config, setConfig] = useState(setting.value);
  const [isTouched, setIsTouched] = useState(false);

  const isSubmitted = useIsChanged(validationDep, isEditing);

  const fieldsToValidate = ['value'];
  const refs = useRefs(fieldsToValidate.length);
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: fieldsToValidate.length,
  });
  const { isAppConfigDisabled: isAppConfigDisabledByODT } =
    useAppStoreAppsContext();

  const hasValidConfigValue = /^<dict>.*<\/dict>$/s.test(setting.value);

  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    fieldsToValidate,
    refs,
  );
  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);
  useEffect(() => {
    if (isChecked && !hasValidConfigValue) {
      onInvalidate(0)('Configuration must be wrapped with <dict>');
    } else {
      onInvalidate(0)(false);
    }
  }, [setting.value, isChecked, hasValidConfigValue, onInvalidate]);

  useEffect(() => {
    if (isAppConfigDisabledByODT && !hasValidConfigValue) {
      setIsChecked(false);
      onInvalidate(0)(false);
    }
  }, [isAppConfigDisabledByODT, onInvalidate, hasValidConfigValue]);

  useEffect(() => {
    if (isDisabled) {
      setIsTouched(false);
    }
    if (!isEditing) {
      setIsChecked(Boolean(setting.value));
    }
  }, [isDisabled, isEditing]);

  if (
    !featureFlags.getFlag('vpp-mac-app-config') &&
    !supportedDeviceFamilies.ipad &&
    !supportedDeviceFamilies.iphone &&
    !supportedDeviceFamilies.ipod &&
    !supportedDeviceFamilies.tv
  ) {
    return null;
  }

  const handleCheckChange = () => {
    if (isChecked) {
      setConfig(setting.value);
      update('value', '');
    } else {
      update('value', config);
    }

    setIsChecked(!isChecked);
  };

  return (
    <Setting.Card className="k-autoapp-config">
      <Setting.Header>
        <h3 className="b-h3">Configuration</h3>
      </Setting.Header>
      {!!cardBanner && <Setting.Header>{cardBanner}</Setting.Header>}

      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckChange}
              disabled={isDisabled}
              label="Set app configuration"
            />
          </Setting.Controls>
          <Setting.Helpers className="k-autoapp-config__helper">
            <p className="b-txt-light">
              For iOS, iPadOS, and tvOS devices, specify a configuration
              dictionary that is applied to the app. If this configuration is
              updated, any existing managed configuration for the app will be
              updated on relevant devices.{' '}
              <a
                href="https://support.kandji.io/support/solutions/articles/72000558709"
                rel="noopener noreferrer"
                target="_blank"
                className="b-alink"
              >
                Learn more...
              </a>
            </p>
          </Setting.Helpers>

          {isChecked && (
            <Setting.SecondaryControls className="k-autoapp-config__controls">
              <div className="k-vpp2-config">
                <p ref={refs[0]} className="b-txt b-mb1">
                  Configuration dictionary
                </p>
                <div onBlur={() => setIsTouched(true)}>
                  <ScriptEditor
                    isDisabled={isDisabled}
                    value={setting.value}
                    onChange={(v) => update('value', v)}
                    language="python"
                    placeholder="<dict><key>AppConfigKey</key><true/></dict>"
                    error={
                      ((isSubmitted || isTouched) && invalidations[0]) || ''
                    }
                  />
                </div>
              </div>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(ConfigurationCard);
