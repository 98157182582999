import { Checkbox, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import ScreenSaverService from '../../service/screen-saver-service';
import SSPanel from '../side-panel';

const Users = ({
  update,
  isDisabled,
  setting,
  validationDep,
  beforeEditModel,
  triggerValidation,
}) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">Users</h3>
    </Setting.Header>
    <Setting.Rows>
      <Setting.Row>
        <Setting.Controls>
          <Checkbox
            label="Configure Screen Saver for users"
            checked={setting.isScreenSaverConfigured}
            isDisabled={isDisabled}
            onChange={() => update('isScreenSaverConfigured', (p) => !p)}
            id="configure-screen-saver"
            testId="configure-screen-saver"
          />
        </Setting.Controls>
        <Setting.Helpers className="b-txt-light">
          Set the amount of time of inactivity while a user is logged in, after
          which the screen saver will start.
        </Setting.Helpers>

        {setting.isScreenSaverConfigured && (
          <Setting.SecondaryControls>
            <Setting.SecondaryControlsRow>
              <Setting.Controls>
                <Select
                  compact
                  disabled={isDisabled}
                  options={ScreenSaverService.SCREEN_SAVER_START_AFTER}
                  value={ScreenSaverService.SCREEN_SAVER_START_AFTER.find(
                    ({ value }) => value === setting.idle_time,
                  )}
                  onChange={({ value }) => update('idle_time', value)}
                />
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>

      {setting.isScreenSaverConfigured && (
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Configure Screen Saver module for users"
              checked={setting.isScreenSaverModuleConfigured}
              isDisabled={isDisabled}
              onChange={() =>
                update('isScreenSaverModuleConfigured', (p) => !p)
              }
              id="configure-screen-saver-module"
              testId="configure-screen-saver-module"
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            Set the Screen Saver module that will be displayed while a user is
            logged in.
          </Setting.Helpers>

          {setting.isScreenSaverModuleConfigured && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <SSPanel
                    setting={setting}
                    update={update}
                    isDisabled={isDisabled}
                    validationDep={validationDep}
                    beforeEditModel={beforeEditModel}
                    triggerValidation={triggerValidation}
                    field="module_path"
                    testId="users"
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      )}
    </Setting.Rows>
  </Setting.Card>
);

export default Users;
