/* istanbul ignore file */
import { getCode } from 'country-list';
import { LATEST_PUBLIC_OS_VERSION_OPTION } from './gdmf-service';
import {
  APPLE_TV_SKIP_SCREENS,
  IPAD_SKIP_SCREENS,
  IPHONE_SKIP_SCREENS,
  MAC_SKIP_SCREENS,
} from './skip-screens/skip-screen-constants';

export const DEFAULT_VALUES = {
  MAX_RESIDENT_USERS: 7,
  USER_QUOTA_SIZE: 2048,
  GUEST_SESSION_TIMEOUT: 0,
  PASSCODE_LOCK_PERIOD: 0,
  AUTOMATIC_USER_LOGOUT: 0,
  ORG_DETAILS: {
    email: '',
    phoneNumber: '',
    location: '',
  },
  MINIMUM_OS_VERSION_MAC: '14.0',
  MINIMUM_OS_VERSION_IPHONE: LATEST_PUBLIC_OS_VERSION_OPTION.value,
  MINIMUM_OS_VERSION_IPAD: LATEST_PUBLIC_OS_VERSION_OPTION.value,
};

const initialState = () => ({
  id: '',
  name: '',
  isActive: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  general: {
    ssoEnabled: false,
    ssoConnections: [],
    ssoConnectionId: '',
    ssoSyncUserWithDevice: false,
    prefill: false,
    lockPrefilled: false,
    isMdmRemovable: false,
    isContactsOverridden: false,
    orgDetails: {
      email: '',
      phoneNumber: '',
      location: '',
    },
    defaultOrgDetails: {
      email: '',
      phoneNumber: '',
      location: '',
    },
    invalidationsMap: {},
  },
  mac: {
    isEnabled: false,
    isAutoAdvance: false,
    canSkip: false,
    accountType: 'admin',
    provisionLocalAdminAccount: false,
    fullName: '',
    shortName: '',
    password: '',
    verifyPassword: '',
    hideAccount: false,
    makeAutoAdmin: false,
    userActivationLockAllowed: false,
    installPackagesBeforeExit: false,
    screens: MAC_SKIP_SCREENS(),
    packages: [],
    invalidationsMap: {},
    // min OS version
    isMinimumOSVersionSet: false,
    minimumOSVersion: DEFAULT_VALUES.MINIMUM_OS_VERSION_MAC,
    isRegionSet: false,
    region: getCode('United States of America'),
    isLanguageSet: false,
    language: 'en',
  },
  iphone: {
    isEnabled: false,
    canSkip: false,
    isSkipAll: false,
    userActivationLockAllowed: false,
    isEnableOrgActivationLock: false,
    screens: IPHONE_SKIP_SCREENS(),
    invalidationsMap: {},
    // min OS version
    isMinimumOSVersionSet: false,
    minimumOSVersion: DEFAULT_VALUES.MINIMUM_OS_VERSION_IPHONE,
  },
  ipad: {
    isEnabled: false,
    canSkip: false,
    isSkipAll: false,
    userActivationLockAllowed: false,
    isEnableOrgActivationLock: false,
    isShared: false,
    sharedUserConfiguration: '',
    maximumSharedResidents: DEFAULT_VALUES.MAX_RESIDENT_USERS,
    maximumSharedSize: DEFAULT_VALUES.USER_QUOTA_SIZE,
    isGuestSessionTimeout: false,
    guestSessionTimeout: 0,
    isPasscodeLockPeriod: false,
    passcodeLockPeriod: 0,
    isAutomaticUserLogout: false,
    automaticUserLogout: 0,
    screens: IPAD_SKIP_SCREENS(),
    invalidationsMap: {},
    isSkipScreenOrAutoAdvanceInvalid: false,
    // min OS version
    isMinimumOSVersionSet: false,
    minimumOSVersion: DEFAULT_VALUES.MINIMUM_OS_VERSION_IPAD,
  },
  appleTv: {
    isEnabled: false,
    isAutoAdvance: false,
    canSkip: false,
    isRegionSet: false,
    region: getCode('United States of America'),
    isLanguageSet: false,
    language: 'en',
    screens: APPLE_TV_SKIP_SCREENS(),
    invalidationsMap: {},
  },
});

export default initialState;
