/* istanbul ignore file */
import {
  Badge,
  FilterButton,
  Flex,
  MultiSelect,
  Text,
} from '@kandji-inc/nectar-ui';
import { useShallow } from 'zustand/react/shallow';
import useVulnerability from '../../store';

const SeverityMultiSelect = () => {
  const [filter, setFilter] = useVulnerability(
    useShallow((state) => [state.filter, state.setFilter]),
  );

  const severityOptions = [
    { label: 'Critical [9.0 - 10.0]', value: 'critical' },
    { label: 'High [7.0 - 8.9]', value: 'high' },
    { label: 'Medium [4.0 - 6.9]', value: 'medium' },
    { label: 'Low [0.1 - 3.9]', value: 'low' },
    { label: 'None [0.0]', value: 'none' },
  ];

  const visibleItems = filter.severity.slice(0, 3);
  const hiddenItemCount = filter.severity.length - visibleItems.length;

  return (
    <MultiSelect
      multi
      options={severityOptions}
      value={filter.severity}
      onChange={(value) => setFilter({ severity: value })}
      footer={{
        showClear: true,
        clearLabel: 'Clear',
        handleClear: () => setFilter({ severity: [] }),
      }}
      componentCss={{ menu: { zIndex: 3 } }}
    >
      <FilterButton
        filtersSelected={Boolean(filter.severity.length)}
        showRemove={false}
        css={{ height: 28 }}
      >
        <Flex flow="row" alignItems="center">
          <Text>Severity</Text>
          {filter.severity.length === severityOptions.length && (
            <Flex flow="row" gap="xs">
              <Text>: </Text>
              <Text css={{ fontWeight: '$medium' }}>{' All'}</Text>
            </Flex>
          )}
          {filter.severity.length !== severityOptions.length &&
            filter.severity.length > 0 && (
              <Flex flow="row" gap="xs">
                <Text>:</Text>
                <Flex
                  alignItems="center"
                  css={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Text
                    css={{
                      fontWeight: '$medium',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginRight: '$1',
                    }}
                  >
                    {visibleItems
                      .map(
                        (item) =>
                          severityOptions
                            .find((option) => option.value === item)
                            ?.label.split(' ')[0],
                      )
                      .join(', ')}
                  </Text>
                </Flex>
                {hiddenItemCount > 0 && (
                  <Badge
                    compact
                    css={{
                      color: '$neutral0',
                      backgroundColor: '$blue50',
                    }}
                  >
                    +{hiddenItemCount}
                  </Badge>
                )}
              </Flex>
            )}
        </Flex>
      </FilterButton>
    </MultiSelect>
  );
};

export { SeverityMultiSelect };
