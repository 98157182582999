import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';
import type {
  EventTypeOptions,
  ItemTypeOptions,
  PostureOptions,
} from '../avert.types';
import {
  EventTypeLabel,
  EventTypeValue,
  ItemTypeLabel,
  ItemTypeValue,
  PostureLabel,
  PostureValue,
} from '../avert.types';

class AvertService extends NewLibraryItemService {
  static postureOptions: PostureOptions = [
    { label: PostureLabel.PROTECT, value: PostureValue.PROTECT },
    { label: PostureLabel.DETECT, value: PostureValue.DETECT },
  ];

  static itemTypeOptions: ItemTypeOptions = [
    { label: ItemTypeLabel.HASH, value: ItemTypeValue.HASH },
    { label: ItemTypeLabel.PATH, value: ItemTypeValue.PATH },
  ];

  static eventTypeOptions: EventTypeOptions = [
    { label: EventTypeLabel.ALLOW, value: EventTypeValue.ALLOW },
    { label: EventTypeLabel.BLOCK, value: EventTypeValue.BLOCK },
  ];
}

export const avertService = new AvertService();

export default AvertService;
