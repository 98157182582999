import { Select } from '@kandji-inc/nectar-ui';
import React from 'react';

import { Setting } from 'features/library-items/template';

import type { GeneralProps } from '../../avert.types';
import AvertService from '../../service/avert.service';

const General = (props: GeneralProps) => {
  const { isDisabled, update, settings } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">General</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure the security posture for Malware and Potentially Unwanted
          Programs (PUP) event detection.{' '}
          <a
            className="b-alink"
            href="https://support.kandji.io/support/solutions/articles/72000600372"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more...
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers className="b-txt-light">
            Specify the desired security postures for Malware and Potentially
            Unwanted Program (PUP) events. Detect mode will scan and report
            known malicious items. Protect mode will scan, report, and
            automatically quarantine known malicious items.
          </Setting.Helpers>
          <Setting.Controls>
            <div className="b-mb3">
              <Select
                label="Malware posture"
                options={AvertService.postureOptions}
                value={settings.malware_posture}
                onChange={(v) => update('malware_posture', v)}
                disabled={isDisabled}
                testId="malware_posture"
              />
            </div>
            <Select
              label="PUP posture"
              options={AvertService.postureOptions}
              value={settings.pup_posture}
              onChange={(v) => update('pup_posture', v)}
              disabled={isDisabled}
              testId="pup_posture"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default General;
