import {
  Chip,
  TextInput,
  onEmptyBlurValidator,
  urlValidator,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../../service/wifi-service';

const keys = WifiService.keys.authentication.scep;

const urlTags = [
  'macOS 10.11+',
  'iPadOS 13+',
  'tvOS 9+',
  'iOS 4.0+',
  'CIS',
  'NIST',
  'STIG',
  'Supervised',
];

const nameTags = urlTags;
const challengeTags = urlTags;
const fingerprintTags = urlTags;
const restartInstallTags = urlTags;

export const renderTag = (tag) => (
  <Chip
    kind="tertiary-light"
    text={tag}
    className="k-ade-chip--m0 k-wifiv2-protocols-general__tag"
    key={tag}
  />
);

const General = ({
  update,
  setting,
  triggerValidate,
  validationDep,
  onInvalidate,
  refs,
}) => (
  <>
    <Setting.Header testId="scep-general" className="--no-padding --no-shadow">
      <h3 className="b-h3">General</h3>
    </Setting.Header>
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">URL</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">The base URL for the SCEP server</p>
        <div className="b-flex-wrap-gtiny">{urlTags.map(renderTag)}</div>
      </Setting.Helpers>
      <Setting.Controls>
        <div ref={refs[keys.url]}>
          <TextInput
            name="scep_url"
            value={setting[keys.url]}
            onChange={(e) => update(keys.url, e.target.value)}
            compact
            placeholder="URL"
            validator={(v) => [
              onEmptyBlurValidator(v, {
                trigger: ['onBlur', triggerValidate],
              }),
              urlValidator(v, {
                trigger: ['onBlur', triggerValidate],
                options: {
                  allow_underscores: true,
                },
              }),
            ]}
            onInvalidate={onInvalidate(keys.url)}
            removeValidationOnUnmount
          />
        </div>
      </Setting.Controls>
    </Setting.Row>
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">
          Name
          <span className="b-txt-light2"> (optional)</span>
        </p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          A string that's understood by the SCEP server; for example, a domain
          name like example.org. If a certificate authority has multiple CA
          certificates, this field can be used to distinguish which is required.
        </p>
        <div className="b-flex-wrap-gtiny">{nameTags.map(renderTag)}</div>
      </Setting.Helpers>
      <Setting.Controls>
        <div>
          <TextInput
            name="scep_name"
            value={setting[keys.name]}
            onChange={(e) => update(keys.name, e.target.value)}
            compact
            isOptional
            placeholder="Name"
          />
        </div>
      </Setting.Controls>
    </Setting.Row>
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">
          Challenge
          <span className="b-txt-light2"> (optional)</span>
        </p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          A pre-shared secret used for automatic enrollment.
        </p>
        <div className="b-flex-wrap-gtiny">{challengeTags.map(renderTag)}</div>
      </Setting.Helpers>
      <Setting.Controls>
        <div>
          <TextInput
            name="scep_challenge"
            value={setting[keys.challenge]}
            onChange={(e) => update(keys.challenge, e.target.value)}
            compact
            isOptional
            placeholder="Challenge"
          />
        </div>
      </Setting.Controls>
    </Setting.Row>
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">
          Fingerprint
          <span className="b-txt-light2"> (optional)</span>
        </p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          The fingerprint (hex string) of the Certificate Authority certificate.
        </p>
        <div className="b-flex-wrap-gtiny">
          {fingerprintTags.map(renderTag)}
        </div>
      </Setting.Helpers>
      <Setting.Controls>
        <div>
          <TextInput
            name="scep_fingerprint"
            value={setting[keys.fingerprint]}
            onChange={(e) => update(keys.fingerprint, e.target.value)}
            compact
            isOptional
            placeholder="Fingerprint"
          />
        </div>
      </Setting.Controls>
    </Setting.Row>
  </>
);

export default General;
