import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

const Parent = NewLibraryItemService;

class SshProfileService extends Parent {
  static DEFAULT_BANNER =
    'This system is reserved for authorized use only, and the use of this system may be monitored.';

  static DEFAULT_TIMEOUT = '900';

  static DEFAULT_MAX_ATTEMPTS = '4';

  static DEFAULT_PORT = '22';

  static DEFAULT_GRACE = '30';

  static DEFAULT_MAX_ACCOUNTS = '0';

  static limitAccessTypes = {
    GROUP: 'group',
    USER: 'user',
  };
}

export const sshProfileService = new SshProfileService();

export default SshProfileService;
