const transformForAPI = (form) => {
  const {
    company_name,
    company_industry,
    company_size,
    device_count_estimate,
  } = form;

  return {
    estimated_macs: device_count_estimate,
    industry: company_industry,
    name: company_name,
    company_size,
  };
};

const transformForUI = (data) => {
  const { company_size, estimated_macs, industry, name } = data;

  return {
    company_name: name,
    company_industry: industry,
    device_count_estimate: estimated_macs,
    company_size,
  };
};

export { transformForAPI, transformForUI };
