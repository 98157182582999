import { Tooltip, TooltipProvider } from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';

type ThreatListTooltipProps = {
  children: React.ReactNode;
  label?: string | undefined;
  side?: 'top' | 'bottom' | 'left' | 'right';
  css?: CSS;
};

const ThreatListTooltip = ({
  children,
  label,
  side = 'top',
  css = {},
}: ThreatListTooltipProps) => (
  <TooltipProvider>
    <Tooltip hidden={!label} content={label} side={side} css={css}>
      {children}
    </Tooltip>
  </TooltipProvider>
);

export default ThreatListTooltip;
