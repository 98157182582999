import { Checkbox, Select } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import PropTypes from 'prop-types';
/* istanbul ignore file */
import React from 'react';
import featureFlags from 'src/config/feature-flags';

import {
  ENFORCEMENT_OPTIONS,
  FORCE_RESTART_OPTIONS,
  LOGIN_ATTEMPT_OPTIONS,
  REMIND_RESTART_OPTIONS,
  RESTART_OPTIONS,
} from '../../constants';

const Enforcement = ({ update, isDisabled, enforcements }) => {
  const LDFFfileVaultAde = featureFlags.getFlag('LIT-WWDC23-filevault-ade');

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Enforcement</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">FileVault enforcement</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              Configure FileVault to be immediately enforced at the next login
              or allow the user to defer.
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              options={Object.values(ENFORCEMENT_OPTIONS)}
              onChange={(selected) => update('enforcementOption', selected)}
              value={enforcements.enforcementOption}
              disabled={isDisabled}
              inputId="enforcement_option"
            />
          </Setting.Controls>
        </Setting.Row>
        {enforcements.enforcementOption ===
          ENFORCEMENT_OPTIONS.ENFORCE_IMMEDIATELY && (
          <>
            {LDFFfileVaultAde && (
              <Setting.Row>
                <Setting.Controls>
                  <Checkbox
                    label="Enforce during Setup Assistant for Automated Device Enrollment (macOS 14+)"
                    id="ade_enforce"
                    testId="ade_enforce_checkbox"
                    checked={enforcements.ForceEnableInSetupAssistant}
                    onChange={() =>
                      update(
                        'ForceEnableInSetupAssistant',
                        (checked) => !checked,
                      )
                    }
                    disabled={isDisabled}
                  />
                </Setting.Controls>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    For devices running macOS 14+ that enroll using Automated
                    Device Enrollment, attempt to enforce FileVault during Setup
                    Assistant.
                  </p>
                </Setting.Helpers>
              </Setting.Row>
            )}
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label="Prompt for restart if FileVault is not enabled"
                  id="promp_for_restart"
                  testId="promp_for_restart_checkbox"
                  checked={enforcements.DeferDontAskAtUserLogout}
                  onChange={() =>
                    update('DeferDontAskAtUserLogout', (checked) => !checked)
                  }
                  disabled={isDisabled}
                />
              </Setting.Controls>
              <Setting.Helpers>
                <p className="b-txt-light">
                  Configure options to forcibly restart the Mac or remind the
                  end user to restart in order to enforce FileVault encryption.
                </p>
              </Setting.Helpers>
              {enforcements.DeferDontAskAtUserLogout && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      <Select
                        compact
                        label="Prompt type"
                        inputId="restart_options"
                        options={Object.values(RESTART_OPTIONS)}
                        onChange={(selected) =>
                          update('restartOption', selected)
                        }
                        value={enforcements.restartOption}
                        disabled={isDisabled}
                      />
                    </Setting.Controls>
                  </Setting.SecondaryControlsRow>
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      {enforcements.restartOption === RESTART_OPTIONS.FORCE && (
                        <Select
                          compact
                          label="Force after"
                          inputId="force_options"
                          options={FORCE_RESTART_OPTIONS}
                          onChange={(selected) =>
                            update('ForceRestartAfter', selected.value)
                          }
                          value={FORCE_RESTART_OPTIONS.find(
                            (opt) =>
                              opt.value === enforcements.ForceRestartAfter,
                          )}
                          disabled={isDisabled}
                        />
                      )}
                      {enforcements.restartOption ===
                        RESTART_OPTIONS.REMIND && (
                        <Select
                          compact
                          label="Remind every"
                          inputId="remind_options"
                          options={REMIND_RESTART_OPTIONS}
                          onChange={(selected) =>
                            update('RemindRestartEvery', selected.value)
                          }
                          value={REMIND_RESTART_OPTIONS.find(
                            (opt) =>
                              opt.value === enforcements.RemindRestartEvery,
                          )}
                          disabled={isDisabled}
                        />
                      )}
                    </Setting.Controls>
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          </>
        )}
        {enforcements.enforcementOption ===
          ENFORCEMENT_OPTIONS.ALLOW_DEFERRAL && (
          <Setting.Row>
            <Setting.Title className="b-txt">User deferral</Setting.Title>
            <Setting.Controls>
              <Select
                compact
                options={Object.values(LOGIN_ATTEMPT_OPTIONS)}
                onChange={(selected) =>
                  update(
                    'DeferForceAtUserLoginMaxBypassAttempts',
                    selected.value,
                  )
                }
                value={LOGIN_ATTEMPT_OPTIONS.find(
                  (opt) =>
                    opt.value ===
                    enforcements.DeferForceAtUserLoginMaxBypassAttempts,
                )}
                disabled={isDisabled}
              />
            </Setting.Controls>
            <Setting.Helpers>
              <p className="b-txt-light">
                Enabling FileVault for the first time will require a computer
                reboot. You can specify the number of login attempts allowed
                before FileVault is enforced and the reboot occurs.
              </p>
            </Setting.Helpers>
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

Enforcement.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  enforcements: PropTypes.shape({
    enforcementOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    DeferForceAtUserLoginMaxBypassAttempts: PropTypes.number,
    DeferDontAskAtUserLogout: PropTypes.bool,
    restartOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    ForceRestartAfter: PropTypes.number,
    RemindRestartEvery: PropTypes.number,
  }).isRequired,
};

export default Enforcement;
