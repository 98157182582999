import React from 'react';

// Bumblebee
import { Button } from '@kandji-inc/bumblebee';

import { useIs } from '../../../../hooks';

import { NEXT_BUTTON_TEXT } from '../../constants';

import CancelButton from './CancelButton';
import Empty from './Empty';

const FooterButtonsRight = ({
  onClose,
  nextButtonDisabled,
  onClickByStep,
  currentStepIdx,
  downloadProgress,
  handleNext,
}) => {
  const showCancelButton = currentStepIdx !== 2;

  const isDownloadComplete = downloadProgress === 100;

  // the action is different after the download is complete the button will change to Next
  const action = isDownloadComplete
    ? handleNext
    : onClickByStep[currentStepIdx];

  const text = isDownloadComplete ? 'Next' : NEXT_BUTTON_TEXT[currentStepIdx];

  const { isWorking } = useIs();

  return (
    <div className="b-grid-ctas">
      {showCancelButton ? (
        <CancelButton onClick={onClose} isDisabled={isWorking} />
      ) : (
        <Empty />
      )}

      <Button onClick={action} isDisabled={nextButtonDisabled || isWorking}>
        {text}
      </Button>
    </div>
  );
};

export default FooterButtonsRight;
