import {
  Radio,
  TextInput,
  Uploader,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { apiTypes } from 'features/library-items/library/common';
/* istanbul ignore file */
import React, { useEffect, useMemo, useState } from 'react';

import { Setting } from 'features/library-items/template';

import {
  getS3InfoForFile,
  uploadCustomApp,
  waitForSHA256,
} from 'templates/library/custom-apps/api';
import CustomPrinterService from '../service/custom-printer-service';

const PpdPathRow = ({
  setting,
  update,
  isDisabled,
  isSubmitted,
  onValidate,
}) => {
  const fieldsToValidate = ['ppdPath', 'ppdFile'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  const setIsSaveEnabled = onValidate((isValid) => isValid);

  const ppdPathValidator = (v) => [
    {
      message: 'Required',
      invalid: () =>
        setting.ppdType === CustomPrinterService.ppdTypes.CUSTOM && !v,
      trigger: ['onBlur', isSubmitted && 'onSubmit'],
    },
  ];
  useEffect(() => {
    const isInvalid = ppdPathValidator(setting.ppdPath)
      .map(({ invalid }) => invalid())
      .some((v) => v);

    onInvalidate(0)(isInvalid);
  }, [setting.ppdPath, setting.ppdType]);
  useEffect(() => {
    if (
      setting.ppdType === CustomPrinterService.ppdTypes.UPLOAD &&
      !setting.ppdFile?.sha256
    ) {
      onInvalidate(1)('Required');
    } else {
      onInvalidate(1)(false);
    }
  }, [setting.ppdType, setting.ppdFile?.sha256]);

  const [uploadCancelFn, setUploadCancelFn] = useState();
  const forceWithFile = useMemo(
    () =>
      (setting.ppdFile?.sha256 && {
        file: {
          name: setting.ppdFile.name,
          size: setting.ppdFile.size,
        },
        sha256: setting.ppdFile.sha256,
      }) ||
      null,
    [setting.ppdFile?.sha256],
  );
  const onTypeChange = (type) => {
    if (setting.ppdType === CustomPrinterService.ppdTypes.CUSTOM) {
      update('customPpdPath', setting.ppdPath);
    }
    if (CustomPrinterService.ppdPath[type]) {
      update('ppdPath', CustomPrinterService.ppdPath[type]);
    }
    if (type === CustomPrinterService.ppdTypes.CUSTOM) {
      update('ppdPath', setting.customPpdPath);
    }
    update('ppdType', type);
  };

  const isFile = setting.ppdType === CustomPrinterService.ppdTypes.UPLOAD;

  return (
    <Setting.Row>
      <div>
        <p className="b-txt">PPD Path</p>
      </div>
      <Setting.Helpers>
        <p className="b-txt-light">
          Configure the driver option to be used, specify a driver path, use the
          Apple-provided generic driver or Airprint. Enter the full path of the
          printer driver to be used.
        </p>
      </Setting.Helpers>
      <div>
        <div className="b-mb-tiny">
          <Radio
            label="Specify path"
            isDisabled={isDisabled}
            checked={setting.ppdType === CustomPrinterService.ppdTypes.CUSTOM}
            onChange={() => onTypeChange(CustomPrinterService.ppdTypes.CUSTOM)}
          />
        </div>
        <div className="b-mb-tiny">
          <Radio
            label="Generic"
            isDisabled={isDisabled}
            checked={setting.ppdType === CustomPrinterService.ppdTypes.GENERIC}
            onChange={() => onTypeChange(CustomPrinterService.ppdTypes.GENERIC)}
          />
        </div>
        <div className="b-mb-tiny">
          <Radio
            label="AirPrint"
            isDisabled={isDisabled}
            checked={setting.ppdType === CustomPrinterService.ppdTypes.AIRPRINT}
            onChange={() =>
              onTypeChange(CustomPrinterService.ppdTypes.AIRPRINT)
            }
          />
        </div>
        <div>
          <Radio
            label="Upload"
            isDisabled={isDisabled}
            checked={setting.ppdType === CustomPrinterService.ppdTypes.UPLOAD}
            onChange={() => onTypeChange(CustomPrinterService.ppdTypes.UPLOAD)}
          />
        </div>
      </div>
      <Setting.SecondaryControls>
        <div ref={refs[0]} className="b-library-form">
          <p ref={refs[1]} className="b-txt b-mb1">
            {isFile ? 'File' : 'Path'}
          </p>
          {isFile && (
            <Uploader
              withError={(isSubmitted && invalidations[1]) || ''}
              onError={(error) => onInvalidate(1)(!!error)}
              forceWithFile={forceWithFile}
              onUpload={(file, updateProgress) =>
                getS3InfoForFile(file, apiTypes.CUSTOM_PRINTER).then((r) => {
                  setIsSaveEnabled(false);
                  const s3Data = r.data.s3_post_data;
                  const upWithCancel = uploadCustomApp(
                    file,
                    updateProgress,
                    s3Data,
                  );
                  update('ppdFile', (p) => ({
                    ...p,
                    id: r.data.id,
                    filePath: s3Data.fields.key,
                    name: file.name,
                    size: file.size,
                    s3key: s3Data.fields.key,
                    uploadedAt: new Date().toISOString(),
                  }));
                  setUploadCancelFn(() => upWithCancel.cancel);
                  return upWithCancel.upload;
                })
              }
              onUploaded={(file) => {
                setIsSaveEnabled(true);
                update('ppdFile', (p) => ({
                  ...p,
                  name: file.name,
                  size: file.size,
                }));
              }}
              onValidate={() =>
                waitForSHA256(setting.ppdFile.id).then((sha256) => {
                  update('ppdFile', (p) => ({
                    ...p,
                    sha256,
                  }));
                  return sha256;
                })
              }
              onCancel={uploadCancelFn}
              onDelete={() => {
                setUploadCancelFn(null);
                update('ppdFile', null);
              }}
              allowedTypes={['.ppd']}
              className="b-mb2"
              isDisabled={isDisabled}
              uploadInstructions={
                <span>
                  Drag file here or{' '}
                  <span className="b-alink">click to upload</span>
                </span>
              }
            />
          )}
          {!isFile && (
            <TextInput
              value={setting.ppdPath}
              onChange={(e) => update('ppdPath', e.target.value)}
              placeholder="/Library/Printers/PPDs/Contents/Resources/AppleImageWriter.ppd.gz"
              disabled={
                isDisabled ||
                setting.ppdType !== CustomPrinterService.ppdTypes.CUSTOM
              }
              onInvalidate={onInvalidate(0)}
              validator={ppdPathValidator}
            />
          )}
        </div>
      </Setting.SecondaryControls>
    </Setting.Row>
  );
};

export default PpdPathRow;
