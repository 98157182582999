import {
  Checkbox,
  Chip,
  TextInput,
  onEmptyBlurValidator,
} from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import EthernetService from '../../../service/ethernet-service';

const keys = EthernetService.keys.authentication.scep;

const retriesTags = [
  'macOS 10.11+',
  'iPadOS 13+',
  'tvOS 9+',
  'iOS 4.0+',
  'CIS',
  'NIST',
];

const disallowExtractionTags = [
  'macOS 10.15+',
  'CIS',
  'NIST',
  'STIG',
  'Supervised',
];

const allowAccessTags = ['macOS', 'CIS', 'NIST', 'STIG', 'Supervised'];

const certificateExpirationTags = ['macOS', 'CIS', 'NIST'];

const autoProfileTags = certificateExpirationTags;

const renderTag = (tag) => (
  <Chip
    kind="tertiary-light"
    text={tag}
    className="k-ade-chip--m0 k-ethernetv2-protocols-general__tag"
    key={tag}
  />
);

const Options = ({ update, setting, triggerValidate, onInvalidate, refs }) => {
  const withRetries = setting[keys.withRetries];
  const withRetryDelay = setting[keys.withRetryDelay];
  const withCertificateNotification =
    setting[keys.withCertificateExpirationNotification];
  const withAutoProfile = setting[keys.withAutoProfileRedistribution];
  return (
    <>
      <Setting.Header
        testId="scep-options"
        className="--no-padding --no-shadow"
      >
        <h3 className="b-h3">Options</h3>
      </Setting.Header>
      <Setting.Row>
        <Setting.Title>
          <div>
            <Checkbox
              checked={setting[keys.withRetries]}
              onChange={() => update(keys.withRetries, (p) => !p)}
              label="Retries"
              testId="options-retries"
            />
          </div>
        </Setting.Title>
        <Setting.Helpers>
          <div>
            <p className="b-txt-light mb-gap1">
              The number of times the device should retry if the server sends a
              PENDING response.
            </p>
            <div className="b-flex-wrap-gtiny">
              {retriesTags.map(renderTag)}
            </div>
          </div>
        </Setting.Helpers>
        {withRetries && (
          <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
            <Setting.SecondaryControlsRow>
              <div ref={refs[keys.retries]} className="small-input-wrapper">
                <TextInput
                  value={setting[keys.retries]}
                  onChange={(e) => update(keys.retries, e.target.value)}
                  compact
                  isOptional
                  type="number"
                  min={0}
                  validator={(v) => [
                    onEmptyBlurValidator(v, { trigger: [triggerValidate] }),
                  ]}
                  onInvalidate={onInvalidate(keys.retries)}
                  removeValidationOnUnmount
                  data-testid="options-retries-input"
                />
                <div className="b-txt">retries</div>
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <Checkbox
            checked={setting[keys.withRetryDelay]}
            onChange={() => update(keys.withRetryDelay, (p) => !p)}
            label="Retry delay"
            testId="options-retry-delay"
          />
        </Setting.Title>
        <Setting.Helpers>
          <div>
            <p className="b-txt-light mb-gap1">
              The number of seconds to wait between subsequent retries. The
              first retry is attempted without this delay.
            </p>
            <div className="b-flex-wrap-gtiny">
              {retriesTags.map(renderTag)}
            </div>
          </div>
        </Setting.Helpers>
        {withRetryDelay && (
          <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
            <Setting.SecondaryControlsRow>
              <div ref={refs[keys.retryDelay]} className="small-input-wrapper">
                <TextInput
                  value={setting[keys.retryDelay]}
                  onChange={(e) => update(keys.retryDelay, e.target.value)}
                  compact
                  isOptional
                  type="number"
                  min={0}
                  validator={(v) => [
                    onEmptyBlurValidator(v, {
                      trigger: ['onMount', triggerValidate],
                    }),
                  ]}
                  onInvalidate={onInvalidate(keys.retryDelay)}
                  removeValidationOnUnmount
                  data-testid="options-retry-delay-input"
                />
                <div className="b-txt">seconds between retries</div>
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>

      <Setting.Row>
        <Setting.Title>
          <Checkbox
            checked={setting[keys.allowAccessToApps]}
            onChange={() => update(keys.allowAccessToApps, (p) => !p)}
            label="Allow apps to access the private key"
            testId="options-allow-apps"
          />
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            Allow all apps to access the certificate in the keychain.
          </p>
          <div className="b-flex-wrap-gtiny">
            {allowAccessTags.map(renderTag)}
          </div>
        </Setting.Helpers>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title>
          <Checkbox
            checked={setting[keys.disallowKeyExtraction]}
            onChange={() => update(keys.disallowKeyExtraction, (p) => !p)}
            label="Prevent the private key data from being extracted in the keychain"
            testId="options-key-extract"
          />
        </Setting.Title>
        <Setting.Helpers>
          <p className="b-txt-light">
            Disables exporting the private key from the keychain.
          </p>
          <div className="b-flex-wrap-gtiny">
            {disallowExtractionTags.map(renderTag)}
          </div>
        </Setting.Helpers>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title>
          <Checkbox
            checked={setting[keys.withCertificateExpirationNotification]}
            onChange={() =>
              update(keys.withCertificateExpirationNotification, (p) => !p)
            }
            label="Certificate expiration notification"
            testId="options-cert-expiration"
          />
        </Setting.Title>
        <Setting.Helpers>
          <div>
            <p className="b-txt-light mb-gap1">
              The number of days before the certificate expires at which to
              start showing the expiration notification.
            </p>
            <div className="b-flex-wrap-gtiny">
              {certificateExpirationTags.map(renderTag)}
            </div>
          </div>
        </Setting.Helpers>
        {withCertificateNotification && (
          <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
            <Setting.SecondaryControlsRow>
              <div
                ref={refs[keys.certificateExpirationNotification]}
                className="small-input-wrapper"
              >
                <TextInput
                  value={setting[keys.certificateExpirationNotification]}
                  onChange={(e) =>
                    update(
                      keys.certificateExpirationNotification,
                      e.target.value,
                    )
                  }
                  compact
                  isOptional
                  type="number"
                  min={0}
                  validator={(v) => [
                    onEmptyBlurValidator(v, { trigger: [triggerValidate] }),
                  ]}
                  onInvalidate={onInvalidate(
                    keys.certificateExpirationNotification,
                  )}
                  removeValidationOnUnmount
                  data-testid="options-cert-notif-input"
                />
                <div className="b-txt">days before certificate expiration</div>
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
      <Setting.Row>
        <Setting.Title>
          <Checkbox
            checked={setting[keys.withAutoProfileRedistribution]}
            onChange={() =>
              update(keys.withAutoProfileRedistribution, (p) => !p)
            }
            label="Automatic profile redistribution"
            testId="options-auto-profile"
          />
        </Setting.Title>
        <Setting.Helpers>
          <div>
            <p className="b-txt-light mb-gap1">
              The number of days prior to the certificate expiration that Kandji
              should attempt to re-issue the SCEP profile to renew the
              certificate.
            </p>
            <div className="b-flex-wrap-gtiny">
              {autoProfileTags.map(renderTag)}
            </div>
          </div>
        </Setting.Helpers>
        {withAutoProfile && (
          <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
            <Setting.SecondaryControlsRow>
              <div
                ref={refs[keys.autoProfileRedistribution]}
                className="small-input-wrapper"
              >
                <TextInput
                  value={setting[keys.autoProfileRedistribution]}
                  onChange={(e) =>
                    update(keys.autoProfileRedistribution, e.target.value)
                  }
                  compact
                  isOptional
                  type="number"
                  min={0}
                  validator={(v) => [
                    onEmptyBlurValidator(v, { trigger: [triggerValidate] }),
                  ]}
                  onInvalidate={onInvalidate(keys.autoProfileRedistribution)}
                  removeValidationOnUnmount
                  data-testid="options-auto-profile-input"
                />
                <div className="b-txt">days before certificate expiration</div>
              </div>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        )}
      </Setting.Row>
    </>
  );
};

export default Options;
