import {
  Badge,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DropdownMenu,
  Flex,
  Heading,
  Icon,
  Text,
  keyframes,
  styled,
} from '@kandji-inc/nectar-ui';
import type { BadgeProps, CSS } from '@kandji-inc/nectar-ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  NameDescription,
  ReportHeaderDropdown,
} from 'src/features/visibility/pulse/components';
import { getReportIconName } from '../../CreatePulseCheck/components/Header';
import { usePatchPulseNameAndDescMutation } from '../hooks/use-patch-pulse-name-and-desc-mutation';
import type { UsePulseCheckDetailsResult } from '../hooks/use-pulse-check-details';
import { PulseReportSettings } from './PulseReportSettings';

const getStatusDisplayProps: (status: string) => {
  label: string;
  color: BadgeProps['color'];
} = (status: string) => {
  switch (status) {
    case 'in progress':
      return { label: 'In Progress', color: 'purple' };
    case 'complete':
      return { label: 'Complete', color: 'green' };
    case 'failed':
      return { label: 'Failed', color: 'red' };
    default:
      return { label: 'Unknown', color: 'neutral' };
  }
};

const jobTypeDisplayValue = {
  file_check: 'File Check',
  processes: 'Processes',
  logged_in_users: 'Logged In Users',
  preferences: 'Preferences',
};

export interface ReportHeaderProps {
  pulseCheckDetails: UsePulseCheckDetailsResult;
  onRerunJob: () => void;
}

export const ReportHeader = ({
  pulseCheckDetails,
  onRerunJob,
}: ReportHeaderProps) => {
  const {
    jobMetadataIsPending,
    jobType,
    status,
    agentFilters,
    refetchJobDevices,
    dataUpdatedAt,
    isJobDevicesRefetching,
    targetedBlueprints,
    targetedDevices,
    jobMetadata,
  } = pulseCheckDetails;

  const name = jobMetadata?.name;
  const description = jobMetadata?.description;
  const devicesWithResults = jobMetadata?.total_devices_with_results;
  const totalDevicesTargeted = jobMetadata?.total_devices_targeted;
  const filesFound = jobMetadata?.total_number_of_results;

  const [isEditingName, setIsEditingName] = useState(false);
  const [reportNameField, setReportNameField] = useState(name);
  const [reportNameError, setReportNameError] = useState('');
  const [reportDescriptionField, setReportDescriptionField] =
    useState(description);
  const [isAddingDescription, setIsAddingDescription] = useState(false);

  const handleRerunJob = () => {
    onRerunJob();
  };
  const { mutateAsync, variables, isPending } =
    usePatchPulseNameAndDescMutation({
      pulseJobId: jobMetadata?.job_id,
    });

  const handlePatchNameDesc = async () => {
    const response = await mutateAsync({
      pulse_check_id: jobMetadata.pulse_check_id,
      name: reportNameField,
      description: reportDescriptionField,
    });

    if (response) {
      setReportNameField(response.name);
      setReportDescriptionField(response.description);
      setIsEditingName(false);
    }
  };

  useEffect(() => {
    setReportNameField(name);
    setReportDescriptionField(description);
  }, [name, description]);

  useEffect(() => {
    if (isEditingName && description) {
      setIsAddingDescription(true);
    } else {
      setIsAddingDescription(false);
    }
  }, [isEditingName, description]);

  return (
    <ReportHeaderRoot gap="md">
      <Breadcrumbs>
        <BreadcrumbsItem
          item={{ title: 'Pulse', url: '/devices/pulse' }}
          itemRender={({ title, url: itemUrl }) => (
            <Link to={itemUrl}>{title}</Link>
          )}
        />
        <BreadcrumbsItem
          item={{
            title: /* istanbul ignore next */ isPending
              ? variables.name
              : name || 'Report Name',
          }}
        />
      </Breadcrumbs>
      <ReportTitleContainer alignItems={isEditingName ? 'start' : 'center'}>
        <ReportTitleIcon>
          <Icon size={32} name={getReportIconName(jobType)} color="#106AF2" />
        </ReportTitleIcon>
        {!isEditingName ? (
          <Flex flow="column">
            <Flex gap="sm" css={{ whiteSpace: 'nowrap', width: 600 }}>
              <ReportTitleText
                as="h2"
                onClick={() => setIsEditingName(true)}
                title={/* istanbul ignore next */ name || 'Report Name'}
              >
                {
                  /* istanbul ignore next */ jobMetadataIsPending
                    ? 'Pending...'
                    : name || 'Report Name'
                }
              </ReportTitleText>
              <StatusBadge
                status={status}
                align={
                  /* istanbul ignore next */ isEditingName ? 'start' : 'center'
                }
              />
            </Flex>
            {description && (
              <Flex
                css={{
                  width: 600,
                  whiteSpace: 'nowrap',
                }}
                title={description}
              >
                <Text
                  css={{
                    color: '$neutral70',
                    fontSize: '14px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {description}
                </Text>
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex gap="md" flow="column">
            <Flex gap="sm">
              <NameDescription
                reportName={reportNameField}
                reportDescription={reportDescriptionField}
                reportNameError={reportNameError}
                setReportDescription={setReportDescriptionField}
                setReportName={setReportNameField}
                setReportNameError={setReportNameError}
                isAddingDescription={isAddingDescription}
                setIsAddingDescription={setIsAddingDescription}
              />
              <StatusBadge
                status={status}
                align={
                  /* istanbul ignore next */ isEditingName ? 'start' : 'center'
                }
              />
            </Flex>
            <Flex justifyContent="end" gap="sm">
              <Button
                variant="subtle"
                compact
                onClick={() => setIsEditingName(false)}
              >
                Cancel
              </Button>
              <Button
                compact
                onClick={handlePatchNameDesc}
                disabled={!reportNameField}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        )}
        <ReportTitleRightSection>
          {status !== 'complete' ? (
            <>
              <ReportMetaKeyValue
                // labelWidth={100}
                label="Last refreshed" // TODO: change to new results returned
                value={
                  <Text
                    css={{
                      color: '$neutral90',
                      fontWeight: '$medium',
                      fontSize: '$1',
                    }}
                  >
                    {dataUpdatedAt
                      ? moment(dataUpdatedAt).fromNow()
                      : 'Pending...'}
                  </Text>
                }
              />
              <RefetchButton
                variant="subtle"
                iconLeft
                icon={{ name: 'arrows-rotate' }}
                compact
                loading={isJobDevicesRefetching}
                isRefetching={isJobDevicesRefetching}
                disabled={isJobDevicesRefetching}
                onClick={() => refetchJobDevices()}
              >
                Load new results
              </RefetchButton>
            </>
          ) : (
            <ReportHeaderDropdown
              onReRunReport={handleRerunJob}
              reportName={name}
              pulseJobId={jobMetadata?.job_id}
            />
          )}
        </ReportTitleRightSection>
      </ReportTitleContainer>
      <ReportMetaContainer>
        <ReportMetaSection>
          <ReportMetaKeyValue
            label="Pulse type"
            value={
              <Text
                css={{
                  color: '$neutral90',
                  fontWeight: '$medium',
                  fontSize: '$1',
                }}
              >
                {jobMetadataIsPending || !jobType
                  ? 'Pending...'
                  : jobTypeDisplayValue[jobType]}
              </Text>
            }
          />
          <ReportMetaKeyValue
            label="Configuration"
            value={
              <PulseReportSettings
                targetedBlueprints={targetedBlueprints}
                targetedDevices={targetedDevices}
                agentFilters={agentFilters}
                jobType={jobType}
              />
            }
          />
        </ReportMetaSection>
        <ReportMetaSection>
          <ReportMetaKeyValue
            labelCss={{ width: 120 }}
            label="Devices with results"
            value={
              jobMetadataIsPending ? (
                'Pending...'
              ) : (
                <>
                  <Text
                    css={{
                      color: '$neutral90',
                      fontWeight: '$medium',
                      fontSize: '$1',
                    }}
                  >
                    {devicesWithResults || 0}
                  </Text>
                  <Text
                    css={{
                      color: '$neutral70',
                      textOverflow: 'ellipsis',
                      paddingInlineStart: '5px',
                      fontSize: '$1',
                    }}
                  >
                    (out of {totalDevicesTargeted} devices)
                  </Text>
                </>
              )
            }
          />
          <ReportMetaKeyValue
            labelCss={{ width: 120 }}
            label="Results found"
            value={
              <Text
                css={{
                  color: '$neutral90',
                  fontWeight: '$medium',
                  fontSize: '$1',
                }}
              >
                {jobMetadataIsPending
                  ? 'Pending...'
                  : getResultsFoundText(jobType, filesFound)}
              </Text>
            }
          />
        </ReportMetaSection>
      </ReportMetaContainer>
    </ReportHeaderRoot>
  );
};

export const getResultsFoundText = (jobType: string, filesFound: number) => {
  if (jobType === 'file_check') {
    return `${filesFound} file${filesFound === 1 ? '' : 's'} found`;
  }

  if (jobType === 'preferences') {
    return `${filesFound} file${filesFound === 1 ? '' : 's'} found`;
  }

  if (jobType === 'processes') {
    return `${filesFound} process${filesFound === 1 ? '' : 'es'} found`;
  }

  if (jobType === 'logged_in_users') {
    return `${filesFound} user${filesFound === 1 ? '' : 's'} found`;
  }

  return '';
};

const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

const RefetchButton = styled(Button, {
  variants: {
    isRefetching: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',

        '& svg': {
          animation: `${spin} 1s linear infinite`,
        },
      },
    },
  },
});

const ReportHeaderRoot = styled(Flex, {
  padding: '16px 24px 0px 0px',
  minWidth: '360px',
  maxWidth: '1800px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
});

const ReportTitleContainer = styled(Flex, {
  minWidth: '320px',
  alignItems: 'center',
  alignContent: 'center',
  gap: '$2',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
});

const ReportTitleIcon = styled(Flex, {
  backgroundColor: '$blue10',
  minWidth: 48,
  minHeight: 48,
  maxHeight: 48,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 24,
});

const ReportTitleText = styled(Heading, {
  fontSize: '24px',
  fontWeight: '$medium',
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ReportTitleRightSection = styled(Flex, {
  padding: '$1 0px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignContent: 'center',
  gap: '$1 $2',
  flex: '1 0 0',
  flexWrap: 'wrap',
});

const ReportMetaContainer = styled(Flex, {
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
});

const ReportMetaSection = styled(Flex, {
  display: 'flex',
  minWidth: '180px',
  maxWidth: '360px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
});

const ReportMetaPair = styled(Flex, {
  alignItems: 'center',
  alignContent: 'center',
  gap: '0px 12px',
  wrap: 'wrap',
});

const ReportMetaLabel = styled(Flex, {
  padding: '4px 0px',
  alignItems: 'center',
  flex: '1 0 0',
});

const ReportMetaValue = styled(Flex, {
  padding: '4px 0px',
  alignItems: 'center',
});

const StatusBadge = ({
  status,
  align = 'center',
}: {
  status: string;
  align: 'center' | 'start';
}) => {
  if (!status) {
    return null;
  }

  const { label, color } = getStatusDisplayProps(status);
  return (
    <Badge
      color={color}
      css={{ alignSelf: align, marginTop: align === 'start' ? '$2' : 0 }}
    >
      {label}
    </Badge>
  );
};

export const ReportMetaKeyValue = ({
  label,
  value,
  labelCss,
  css,
}: {
  label: string | React.ReactNode | React.ReactNode[];
  value: string | React.ReactNode | React.ReactNode[];
  labelCss?: CSS;
  css?: CSS;
}) => (
  <ReportMetaPair css={css}>
    <ReportMetaLabel css={{ width: 80, ...labelCss }}>
      <Text
        css={{
          color: '$neutral70',
          textOverflow: 'ellipsis',
          fontSize: '$1',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Text>
    </ReportMetaLabel>
    <ReportMetaValue>
      {typeof value === 'string' ? <Text>{value}</Text> : value}
    </ReportMetaValue>
  </ReportMetaPair>
);
