import { ENFORCEMENT_KEYS } from 'features/library-items/common/updates-card/updates-card-constants';
import { setBlueprintsToFormData } from 'features/library-items/data-service/library-item/transformers';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import { applyRSREnforcementOptions } from 'src/features/library-items/common/updates-card/applyRSREnforcementOptions';
import { getUpdatesModel } from 'src/features/library-items/common/updates-card/getUpdatesModel';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';

async function transformFromApi(apiData) {
  const customData = apiData.data;
  // This default is old behavior carried over from /src/theme/function
  const defaultMinimumOsVersion = '10.11';

  const selectedBlueprints = await getAllBlueprintOptions(
    apiData.blueprints || [],
  );
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const result = {
    _data: customData,

    id: apiData.id,
    managedLibraryItemId: customData.managed_library_item_id,
    name: apiData.name,
    // can add this ternary back in when the public api is used
    icon: apiData.app_icon || customData.icon_url,
    isActive: 'active' in apiData ? apiData.active : true,
    currentVersion: customData.version,
    osRequirements: `macOS ${
      customData.minimum_os_version || defaultMinimumOsVersion
    }+ ${customData.requires_supervision ? ' (Requires Supervision)' : ''}`,
    instanceName: apiData.instance_name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints || false,
    description: customData.description,
    publisher: customData.publisher,
    rules: apiData.rules,
  };

  // only set `installation` if a type is given to us. otherwise, defer to what is defined in `constants.js`.
  if (customData.install_enforcement) {
    result.installation = { type: customData.install_enforcement };
  }

  result.selfService = {
    isEnabled: customData.show_in_self_service,
    category: customData.self_service_data?.category,
    isRecommended: customData.self_service_data?.isRecommended,
    displayName: customData.self_service_data?.displayName,
    shortDescription: customData.self_service_data?.description,
    longDescription: customData.self_service_data?.bodyText,
    // isRequireRead can only be `true` when there is a longDescription to be read
    isRequireRead: customData.self_service_data?.mustViewAgreement,
    iconUrl: customData.self_service_data?.iconUrl,
    iconS3Key: customData.self_service_data?.iconS3Key,
    iconFile: null,
    size: customData.file_size,
    version: customData.version?.display || '-',
    displayInfoBtn: customData.self_service_data?.displayInfoBtn, // ??
    infoBtnText: customData.self_service_data?.infoBtnText, // ??
    infoBtnUrl: customData.self_service_data?.infoBtnUrl, // ??
  };

  result.updates = getUpdatesModel(customData);

  return {
    ...apiData,
    data: result,
  };
}

const createTransformToApi =
  ({ selectedTimezone }) =>
  (model) => {
    const toSend = new FormData();
    toSend.set('active', model.isActive);
    toSend.set('instance_name', model.instanceName || '');
    toSend.set('type', 'macos-release');
    toSend.set('name', model.name);
    toSend.set('rules', JSON.stringify(model.rules) || null);
    setBlueprintsToFormData(toSend, model.selectedBlueprints);
    toSend.set('is_all_blueprints', model.isAllBlueprints);

    if (model.skip_blueprint_conflict) {
      toSend.set('skip_blueprint_conflict', model.skip_blueprint_conflict);
    }

    const enf = model.updates.versionEnforcement.value;
    let data = {
      ...omit(model._data, [
        'version_enforcement',
        'enforcement_time',
        'enforcement_timezone',
        'minimum_app_version',
        'enforce_after',
        'enforcement_delay',
      ]),
      version_enforcement: enf,
      install_enforcement: model.installation.type,
    };

    if (enf !== ENFORCEMENT_KEYS.any) {
      data.enforcement_time = model.updates.enforcementTime.value;
      data.enforcement_timezone = selectedTimezone.value;
    }

    data = applyRSREnforcementOptions(data, model.updates);

    data._version_dates = model.updates.versions;

    switch (enf) {
      case ENFORCEMENT_KEYS.custom:
        data.minimum_app_version = model.updates.minimumVersion.value;
        data.enforce_after = DateTime.fromJSDate(
          model.updates.enforcementDeadline,
        ).toFormat('MM/dd/yyyy');
        break;
      case ENFORCEMENT_KEYS.newest:
        data.enforcement_delay = model.updates.enforcementDelay.value;
    }

    if (model.selfService.isEnabled) {
      data.show_in_self_service = true;
      data.self_service_data = {
        isRecommended: model.selfService.isRecommended,
        iconS3Key: model.selfService.iconS3Key,
        iconUrl: data.icon_url,
        displayName: model.selfService.displayName,
        description: model.selfService.shortDescription,
        bodyText: model.selfService.longDescription,
        // isRequireRead can only be `true` when there is a longDescription to be read
        mustViewAgreement: model.selfService.longDescription
          ? model.selfService.isRequireRead
          : false,
        category: model.selfService.category,
        displayInfoBtn: model.selfService.displayInfoBtn,
        infoBtnText: model.selfService.infoBtnUrl,
        infoBtnUrl: model.selfService.infoBtnUrl,
      };
    } else {
      data.show_in_self_service = false;
      delete data.self_service_data;
    }

    toSend.set('data', JSON.stringify(data));

    return toSend;
  };

export { createTransformToApi, transformFromApi };
