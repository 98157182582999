/* istanbul ignore file */
import { logout } from 'app/_actions/account';
import { store } from 'app/_store/rootStore';
import { getMillisecondsInactive } from 'app/api/base';

const LOGOUT_INACTIVITY = 60 * 60 * 1000; // 60 minutes

export const getLogoutInactivityTime = () => {
  let logoutAfter = LOGOUT_INACTIVITY;

  // TODO: remove after testing
  if (localStorage.getItem('logoutInactivity')) {
    logoutAfter = parseInt(localStorage.getItem('logoutInactivity'), 10);
  }

  return logoutAfter;
};

export const useLogoutMonitor = () => {
  let logoutTimout;
  const logoutAfter = getLogoutInactivityTime();

  const checkForInactivityLogout = () => {
    const timeTillLogout = logoutAfter - getMillisecondsInactive();

    // time to log out
    if (timeTillLogout <= 0) {
      // log out any active users
      store.getState().account.user.id && store.dispatch(logout(true));

      // wait max duration before checking again
      logoutTimout = setTimeout(checkForInactivityLogout, logoutAfter);
    }

    // not time to log out
    else {
      // check again when it should be time to log out
      logoutTimout = setTimeout(checkForInactivityLogout, timeTillLogout);
    }
  };

  return {
    start: checkForInactivityLogout,
    stop: () => clearTimeout(logoutTimout),
  };
};
