import toaster from '@kandji-inc/bumblebee/lib/atoms/toaster';
import { Flex, useDialog } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import React, { useCallback, useState } from 'react';
import type {
  AllowBlockEvent,
  AllowBlockFilters,
  AllowBlockListProps,
  SortColumnDirection,
  SortColumnName,
} from '../../avert.types';
import { EventTypeLabel, EventTypeValue } from '../../avert.types';
import AllowBlockBlueButton from './AllowBlockBlueButton';
import AllowBlockListEmptyState from './AllowBlockListEmptyState';
import AllowBlockListFilters from './AllowBlockListFilters';
import AllowBlockListPagination from './AllowBlockListPagination';
import AllowBlockListTable from './AllowBlockListTable';
import AllowBlockModal from './AllowBlockModal';
import './allow-block-list.css';
import useFilterEvents from './useFilterEvents';

const defaultFilters: AllowBlockFilters = {
  search: '',
  eventType: 'all',
  itemType: 'all',
  sortByColumn: 'none',
  sortDirection: 'none',
};

const AllowBlockList = (props: AllowBlockListProps) => {
  const {
    settings,
    generalSettings,
    update,
    isDisabled,
    pageSize = 10,
  } = props;

  const [isModalOpen, toggleDialog] = useDialog();
  const [itemId, setItemId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<AllowBlockFilters>({
    ...defaultFilters,
  });

  const {
    pagedEvents,
    pagedEventsCount,
    filteredEventsCount,
    hasFilteredEvents,
    hasUnfilteredEvents,
    isFiltering,
  } = useFilterEvents(
    settings?.events,
    page,
    pageSize,
    filters,
    defaultFilters,
  );

  const isAdding = !itemId;
  const isSorting = filters.sortByColumn !== 'none';

  const toggleDialogWithId = useCallback(
    (id: string = null) => {
      setItemId(id);
      toggleDialog();
    },
    [toggleDialog, setItemId],
  );

  const handleAddClick = useCallback(
    (item: AllowBlockEvent, keepOpen: boolean) => {
      update('events', (value) => {
        if (!isAdding) {
          return value.map((v) => (v.id === item.id ? item : v));
        }
        return [...value, item];
      });

      if (!keepOpen) {
        toggleDialogWithId();
      }

      const isAllowed = item.event_type === EventTypeValue.ALLOW;
      const listName = isAllowed ? EventTypeLabel.ALLOW : EventTypeLabel.BLOCK;
      toaster(
        isAdding
          ? `${item.name} has been added to your ${listName} list.`
          : `${item.name} has been updated in your ${listName} list.`,
      );

      /*
      Handle pagination: when a new item is added, no filters are set, 
      and no column is sorted, proceed to the last page to display the 
      new added item.
      */
      if (isAdding && !isSorting && !isFiltering) {
        const lastPage = Math.ceil((filteredEventsCount + 1) / pageSize);
        setPage(lastPage);
      }
    },
    [
      filteredEventsCount,
      isAdding,
      isFiltering,
      isSorting,
      pageSize,
      toggleDialogWithId,
      update,
    ],
  );

  const handleClearFiltersClick = useCallback(() => {
    setFilters({ ...defaultFilters });
  }, []);

  const handleDeleteEventClick = useCallback(
    (id: string) => {
      update('events', (p: AllowBlockEvent[]) =>
        p.filter(({ id: eventId }) => eventId !== id),
      );

      // If we're on the last page and we delete the last item, go back one page
      if (pagedEventsCount === 1) {
        const previousPage = Math.max(page - 1, 1);
        setPage(previousPage);
      }
    },
    [page, pagedEventsCount, update],
  );

  const handleEditEventClick = useCallback(
    (id: string) => {
      toggleDialogWithId(id);
    },
    [toggleDialogWithId],
  );

  const handleFiltersChange = useCallback((newFilters: AllowBlockFilters) => {
    setFilters(newFilters);
    setPage(1);
  }, []);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleColumnSortClick = (
    column: SortColumnName,
    direction: SortColumnDirection,
  ) => {
    setFilters((prev) => ({
      ...prev,
      sortByColumn: column,
      sortDirection: direction,
    }));
  };

  return (
    <div className="abl-container">
      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">Allow and Block list</h3>
        </Setting.Header>
        <Setting.SubHeader>
          <p className="b-txt">
            Add and configure the permission (Allowed or Blocked) for specific
            application or file paths and hashes. Expand each item to see more
            detail.{' '}
            <a
              href="https://support.kandji.io/support/solutions/articles/72000600372"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              Learn more...
            </a>
          </p>
        </Setting.SubHeader>
        <Setting.Rows className="abl-rows__no-pad">
          {hasUnfilteredEvents && (
            <Setting.Row className="abl-row__no-grid abl-table__filters">
              <Flex gap="lg">
                <AllowBlockListFilters
                  filters={filters}
                  onChangeFilters={handleFiltersChange}
                />
                {isFiltering && (
                  <AllowBlockBlueButton
                    onClick={handleClearFiltersClick}
                    iconName="circle-xmark"
                    testId="clear-filters-button"
                  >
                    Clear filters
                  </AllowBlockBlueButton>
                )}
              </Flex>
              <Flex gap="lg">
                <AllowBlockBlueButton
                  onClick={() => toggleDialogWithId()}
                  isDisabled={isDisabled}
                  iconName="circle-plus"
                >
                  Add item
                </AllowBlockBlueButton>
              </Flex>
            </Setting.Row>
          )}
          <Setting.Row className="abl-row__no-grid abl-table__list">
            {hasFilteredEvents ? (
              <>
                <AllowBlockListTable
                  events={pagedEvents}
                  isDisabled={isDisabled}
                  onDeleteEvent={handleDeleteEventClick}
                  onEditEvent={handleEditEventClick}
                  onColumnSort={handleColumnSortClick}
                />
                <AllowBlockListPagination
                  onPageChange={handlePageChange}
                  totalItems={filteredEventsCount}
                  page={page}
                  pageSize={pageSize}
                />
              </>
            ) : (
              <>
                <AllowBlockListEmptyState
                  isVisible={isFiltering}
                  header="No results found"
                  body="We couldn't find a match. Try changing the filter, or search with keywords and request methods."
                  button={
                    <AllowBlockBlueButton
                      onClick={handleClearFiltersClick}
                      iconName="circle-xmark"
                      testId="no-results-clear-filters-button"
                    >
                      Clear filters
                    </AllowBlockBlueButton>
                  }
                />
                <AllowBlockListEmptyState
                  isVisible={!isFiltering}
                  header="No Allowed or Blocked items configured"
                  body="Add an item below to configure specific application or file paths and hashes, and specify whether they should be allowed or blocked."
                  button={
                    <AllowBlockBlueButton
                      onClick={() => toggleDialogWithId()}
                      isDisabled={isDisabled}
                      iconName="circle-plus"
                    >
                      Add item
                    </AllowBlockBlueButton>
                  }
                />
              </>
            )}
          </Setting.Row>
        </Setting.Rows>
      </Setting.Card>
      {isModalOpen && (
        <AllowBlockModal
          isOpen
          onCancel={() => toggleDialogWithId()}
          onAdd={handleAddClick}
          generalSettings={generalSettings}
          settings={settings}
          itemId={itemId}
        />
      )}
    </div>
  );
};

export default AllowBlockList;
