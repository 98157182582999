import { useMutation, useQueryClient } from '@tanstack/react-query';
import { integrationsKeys } from 'src/features/integrations/hooks/query-key-factory';
import { msDeviceComplianceService } from '../services';

const useDeleteIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (integrationId: string) =>
      msDeviceComplianceService.deleteIntegration(integrationId),
    onSuccess: () => {
      // refetch the integrations from maint-backend whenever we delete this integration
      queryClient.invalidateQueries({ queryKey: integrationsKeys.all });
    },
  });
};

export default useDeleteIntegration;
