/* istanbul ignore file -- coverage diff picked up prettier changes */

import {
  Banner,
  Radio,
  Select,
  TextInput,
  urlValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import './auth.css';

import { Setting } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';

import MacLogin from '../../assets/standard.png';
import MacLoginSonoma from '../../assets/standard_sonoma.png';
import KandjiLoginService from '../../service/kandji-login-service';
import {
  providerCopy,
  providerImages,
  tempFeatureFlagGoogleIdpProviderOptions,
} from './common';
import GoogleIdp from './google-idp';

const Auth = (props) => {
  const {
    setting,
    update,
    isDisabled,
    isSubmitted,
    validationDep,
    onValidate,
  } = props;
  const fieldsToValidate = [
    'idp',
    'googleIdp',
    'oidcBaseUrl',
    'publicAppId',
    'redirectUri',
    'webLoginClientId',
    'clientSecret',
  ];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );

  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const trigger = ['onBlur', validationDep];
  const copy = providerCopy[setting.idp] || {};

  const showWebClientId =
    setting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN &&
    [
      KandjiLoginService.identityProviders.ONE_LOGIN,
      KandjiLoginService.identityProviders.OTHER,
    ].some((provider) => setting.idp === provider);

  const clientSecretRequired =
    setting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN &&
    setting.idp === KandjiLoginService.identityProviders.ONE_LOGIN;

  useEffect(() => {
    onInvalidate(0)(!setting.idp);
  }, [setting.idp]);

  const reset = () => {
    update('mode', KandjiLoginService.authenticationMode.MAC_LOGIN);
    update('oidcBaseUrl', '');
    update('redirectUri', '');
    update('publicAppId', '');
    update('clientSecret', '');
    update('webLoginClientId', '');
  };

  const LDFFpassportSonoma = featureFlags.getFlag('LIT-WWDC23-passport-sonoma');
  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Authentication configuration</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure the connection between Passport and your identity provider
          (IdP).&nbsp;
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558707"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more...
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <>
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">Identity provider</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              Select the IdP to configure with Passport.
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                testId="idp"
                compact
                placeholder="Select IdP"
                disabled={isDisabled}
                options={tempFeatureFlagGoogleIdpProviderOptions}
                onChange={({ value }) => {
                  update('idp', value);
                  reset();
                }}
                value={tempFeatureFlagGoogleIdpProviderOptions.find(
                  ({ value }) => value === setting.idp,
                )}
                errorText={(isSubmitted && !setting.idp && 'Required') || ''}
              />

              {copy.idpLearnMoreHelper && (
                <p className="b-txt-light b-mt">
                  {copy.idpLearnMoreHelper}{' '}
                  <a
                    href={copy.idpLearnMoreUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="b-alink"
                  >
                    Learn more...
                  </a>
                </p>
              )}
            </Setting.Controls>
          </Setting.Row>

          <GoogleIdp
            ref={refs.googleIdp}
            setting={setting}
            update={update}
            onValidate={onValidate}
            onInvalidate={onInvalidate('googleIdp')}
            invalidations={invalidations}
            isSubmitted={isSubmitted}
            isDisabled={isDisabled}
            isVisible={
              setting.idp ===
              KandjiLoginService.tempFeatureFlagGoogleIdpIdentityProviders
                .GOOGLE_WORKSPACE
            }
          />

          {setting.idp &&
            setting.idp !==
              KandjiLoginService.tempFeatureFlagGoogleIdpIdentityProviders
                .GOOGLE_WORKSPACE && (
              <>
                <Setting.Row>
                  <Setting.Title>
                    <p className="b-txt">Identity provider URL</p>
                  </Setting.Title>
                  <Setting.Helpers className="b-txt-light">
                    {copy.urlHelper}
                  </Setting.Helpers>
                  <Setting.Controls>
                    <div ref={refs[0]}>
                      <TextInput
                        key={setting.idp}
                        data-testid="oidcBaseUrl"
                        compact
                        disabled={isDisabled}
                        value={setting.oidcBaseUrl}
                        onChange={(e) => update('oidcBaseUrl', e.target.value)}
                        placeholder={copy.urlPlaceholder}
                        validator={(v) =>
                          [
                            {
                              message: 'Required',
                              invalid: () => !v,
                              trigger,
                            },
                            setting.idp !==
                              KandjiLoginService.identityProviders.OTHER &&
                              urlValidator(v, { trigger }),
                          ].filter(Boolean)
                        }
                        onInvalidate={onInvalidate(0)}
                      />
                    </div>
                  </Setting.Controls>
                </Setting.Row>

                <Setting.Row>
                  <Setting.Title>
                    <p className="b-txt">Client ID (Password Sync)</p>
                  </Setting.Title>
                  <Setting.Helpers className="b-txt-light">
                    {copy.clientHelper}
                  </Setting.Helpers>
                  <Setting.Controls>
                    <div ref={refs[2]}>
                      <TextInput
                        key={setting.idp}
                        data-testid="publicAppId"
                        compact
                        disabled={isDisabled}
                        value={setting.publicAppId}
                        onChange={(e) => update('publicAppId', e.target.value)}
                        placeholder="Ap9aKX3"
                        validator={(v) => [
                          {
                            message: 'Required',
                            invalid: () => !v,
                            trigger: ['onBlur', validationDep],
                          },
                        ]}
                        onInvalidate={onInvalidate(2)}
                        removeValidationOnUnmount
                      />
                    </div>
                  </Setting.Controls>
                </Setting.Row>

                <Setting.Row className="k-passport-config-row">
                  <Setting.Title className="k-passport-config-row__title">
                    <p className="b-txt">Authentication mode</p>
                  </Setting.Title>
                  <Setting.Helpers className="b-txt-light k-passport-config-row__helper">
                    {copy.modeHelper}{' '}
                    {setting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN &&
                      copy.webLoginBanner && <>{copy.webLoginBanner}</>}
                  </Setting.Helpers>
                  <Setting.Controls className="k-passport-config-row__controls">
                    <div className="b-flex-vg">
                      <div
                        data-testid="select-mac-login"
                        className="b-flex-g b-flex-vc"
                        onClick={() => {
                          if (!isDisabled) {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.MAC_LOGIN,
                            );
                          }
                          if (
                            setting.idp ===
                              KandjiLoginService.identityProviders.ONE_LOGIN ||
                            setting.idp ===
                              KandjiLoginService.identityProviders.OTHER
                          ) {
                            update('clientSecret', '');
                          }
                        }}
                      >
                        <Radio
                          id="mac-login"
                          isDisabled={isDisabled}
                          checked={
                            setting.mode ===
                            KandjiLoginService.authenticationMode.MAC_LOGIN
                          }
                          onChange={() => {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.MAC_LOGIN,
                            );
                            if (
                              setting.idp ===
                                KandjiLoginService.identityProviders
                                  .ONE_LOGIN ||
                              setting.idp ===
                                KandjiLoginService.identityProviders.OTHER
                            ) {
                              update('clientSecret', '');
                            }
                          }}
                        />
                        <img
                          src={LDFFpassportSonoma ? MacLoginSonoma : MacLogin}
                          alt=""
                          className="li-passport_auth-login-img"
                        />
                        <div className="b-flex-vgmicro">
                          <p className="b-txt">Mac Login</p>
                          <p className="b-txt-ctrl7 b-txt-light">
                            Username and password
                          </p>
                        </div>
                      </div>
                      <div
                        data-testid="select-web-login"
                        className="b-flex-g b-flex-vc"
                        onClick={() => {
                          if (!isDisabled) {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.WEB_LOGIN,
                            );
                          }
                          if (
                            setting.idp ===
                              KandjiLoginService.identityProviders.ONE_LOGIN ||
                            setting.idp ===
                              KandjiLoginService.identityProviders.OTHER
                          ) {
                            update('clientSecret', '');
                          }
                        }}
                      >
                        <Radio
                          id="web-login"
                          isDisabled={isDisabled}
                          checked={
                            setting.mode ===
                            KandjiLoginService.authenticationMode.WEB_LOGIN
                          }
                          onChange={() => {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.WEB_LOGIN,
                            );
                            if (
                              setting.idp ===
                                KandjiLoginService.identityProviders
                                  .ONE_LOGIN ||
                              setting.idp ===
                                KandjiLoginService.identityProviders.OTHER
                            ) {
                              update('clientSecret', '');
                            }
                          }}
                        />
                        <img
                          src={providerImages()[setting.idp]}
                          alt=""
                          className="li-passport_auth-login-img"
                        />
                        <div className="b-flex-vgmicro">
                          <p className="b-txt">Web Login</p>
                          <p className="b-txt-ctrl7 b-txt-light">
                            Supports multi-factor authentication (MFA)
                          </p>
                        </div>
                      </div>
                    </div>
                  </Setting.Controls>

                  <Setting.SecondaryControls>
                    {showWebClientId && (
                      <Setting.SecondaryControlsRow className="web-login-clientid">
                        <Banner kind="block" theme="info">
                          <span>{copy.webClientBanner}</span>
                        </Banner>
                      </Setting.SecondaryControlsRow>
                    )}

                    {showWebClientId && (
                      <Setting.SecondaryControlsRow>
                        <Setting.Title>
                          <p className="b-txt" ref={refs.webLoginClientId}>
                            Client ID (Web Authentication)
                          </p>
                        </Setting.Title>
                        <Setting.Helpers className="b-txt-light">
                          {copy.webClientIdHelper}
                        </Setting.Helpers>
                        <Setting.Controls>
                          <TextInput
                            key={setting.idp}
                            data-testid="webClientId"
                            compact
                            disabled={isDisabled}
                            value={setting.webLoginClientId}
                            onChange={(e) =>
                              update('webLoginClientId', e.target.value)
                            }
                            placeholder="Ap9aKX3"
                            validator={(v) => [
                              {
                                message: 'Required',
                                invalid: () => !v,
                                trigger,
                              },
                            ]}
                            onInvalidate={onInvalidate('webLoginClientId')}
                            removeValidationOnUnmount
                          />
                        </Setting.Controls>
                      </Setting.SecondaryControlsRow>
                    )}

                    {setting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN && (
                      <Setting.SecondaryControlsRow>
                        <Setting.Title>
                          <p ref={refs.redirectUri} className="b-txt">
                            Redirect URI
                          </p>
                        </Setting.Title>
                        <Setting.Helpers className="b-txt-light">
                          {copy.redirectUriHelper}
                        </Setting.Helpers>
                        <Setting.Controls>
                          <TextInput
                            key={setting.idp}
                            data-testid="redirectUri"
                            compact
                            disabled={isDisabled}
                            value={setting.redirectUri}
                            onChange={(e) =>
                              update('redirectUri', e.target.value)
                            }
                            placeholder={copy.redirectUriPlaceholder}
                            validator={(v) => [
                              {
                                message: 'Required',
                                invalid: () => !v,
                                trigger,
                              },
                            ]}
                            onInvalidate={onInvalidate('redirectUri')}
                            removeValidationOnUnmount
                          />
                        </Setting.Controls>
                      </Setting.SecondaryControlsRow>
                    )}

                    <Setting.SecondaryControlsRow>
                      <Setting.Title>
                        <p
                          className="b-txt"
                          ref={clientSecretRequired ? refs.clientSecret : null}
                        >
                          Client secret{' '}
                          {!clientSecretRequired && (
                            <span className="b-txt-light">(optional)</span>
                          )}
                        </p>
                      </Setting.Title>
                      <Setting.Helpers className="b-txt-light">
                        {setting.mode ===
                          KandjiLoginService.authenticationMode.WEB_LOGIN &&
                        copy.clientSecretWebHelper
                          ? copy.clientSecretWebHelper
                          : copy.clientSecretHelper}
                      </Setting.Helpers>
                      <Setting.Controls>
                        <TextInput
                          key={setting.idp}
                          data-testid="clientSecret"
                          compact
                          disabled={isDisabled}
                          value={setting.clientSecret}
                          onChange={(e) =>
                            update('clientSecret', e.target.value)
                          }
                          placeholder="client_secret"
                          validator={(v) => [
                            {
                              message: 'Required',
                              invalid: () => clientSecretRequired && !v,
                              trigger,
                            },
                          ]}
                          onInvalidate={onInvalidate('clientSecret')}
                          removeValidationOnUnmount
                        />
                      </Setting.Controls>
                    </Setting.SecondaryControlsRow>
                  </Setting.SecondaryControls>
                </Setting.Row>
              </>
            )}
        </>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Auth;
