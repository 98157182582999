import { updateSetting as update } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React, { useContext, useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import LibraryContext from 'features/library-items/routes/library.context';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import initialState from './initial-state';
import {
  Desktops,
  General,
  PortablesBattery,
  PortablesPowerAdapter,
  Schedule,
} from './sections';
import { energySaverService } from './service/energy-saver.service';
import { transformFromApi, transformToApi } from './service/transformers';
import useEnergySaverService from './service/use-energy-saver.service';

const EnergySaverPage = (props) => {
  const { model, setModel, pageState } = props;
  const { itemConfig } = useContext(LibraryContext);
  const [validationDep, triggerValidation] = useUniqValue();
  const updateGeneral = useCallback(update('general', setModel), []);
  const updateDesktops = useCallback(update('desktops', setModel), []);
  const updateBattery = useCallback(update('battery', setModel), []);
  const updatePowerAdapter = useCallback(update('powerAdapter', setModel), []);
  const updateSchedule = useCallback(update('schedule', setModel), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: `${itemConfig.name} Profile`,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={energySaverService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsRules
      supportsDuplication
    >
      <General
        setting={model.general}
        update={updateGeneral}
        validationDep={validationDep}
        isDisabled={pageState.isDisabled}
      />
      <Desktops
        setting={model.desktops}
        generalSetting={model.general}
        update={updateDesktops}
        validationDep={validationDep}
        isDisabled={pageState.isDisabled}
      />
      <PortablesBattery
        setting={model.battery}
        generalSetting={model.general}
        update={updateBattery}
        validationDep={validationDep}
        isDisabled={pageState.isDisabled}
      />
      <PortablesPowerAdapter
        setting={model.powerAdapter}
        generalSetting={model.general}
        update={updatePowerAdapter}
        validationDep={validationDep}
        isDisabled={pageState.isDisabled}
      />
      <Schedule
        setting={model.schedule}
        update={updateSchedule}
        validationDep={validationDep}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
      />
    </LibraryItemPage>
  );
};

const EnergySaver = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useEnergySaverService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <EnergySaverPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="energy-saver-v2"
    />
  );
};

export default EnergySaver;
