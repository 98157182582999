const convertToCSV = (data: any[]) => {
  if (data.length === 0) return '';

  const header = Object.keys(data[0]);
  const replacer = (key: string, value: unknown) =>
    value === null ? '' : value;
  const csv = data.map((row) =>
    header.map((field) => JSON.stringify(row[field], replacer)).join(','),
  );

  csv.unshift(header.join(','));
  return csv.join('\r\n');
};

export default convertToCSV;
