import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
} from './column-utils';
import { getGlobalColumns } from './globalColumns';

export const getApplicationFirewallColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }
  const applicationFirewallColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();

  const globalColumns = getGlobalColumns(
    schema,
    applicationFirewallColumnHelper,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    applicationFirewallColumnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: OnOffCell,
      meta: {
        displayName: 'Status',
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.block_all_incoming, {
      id: 'block_all_incoming',
      cell: YesNoCell,
      meta: {
        displayName: 'Block All Incoming',
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor(
      (row) => row.allow_signed_applications,
      {
        id: 'allow_signed_applications',
        cell: YesNoCell,
        meta: {
          displayName: 'Allow Signed Applications',
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor((row) => row.stealth_mode, {
      id: 'stealth_mode',
      cell: OnOffCell,
      meta: {
        displayName: 'Stealth Mode',
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.logging, {
      id: 'logging',
      cell: OnOffCell,
      meta: {
        displayName: 'Logging',
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.logging_option, {
      id: 'logging_option',
      meta: {
        displayName: 'Logging Option',
        filterType: 'enum',
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.version, {
      id: 'version',
      meta: {
        displayName: 'Version',
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    applicationFirewallColumnHelper.accessor((row) => row.unloading, {
      id: 'unloading',
      cell: YesNoCell,
      meta: {
        displayName: 'Firewall Unloading',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
