import {
  Checkbox,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';

const KerberosPasswordOptions = (props) => {
  const { setting, update, validationDep, isDisabled } = props;

  const fieldsToValidate = [
    'password_history',
    'password_length',
    'password_requirements_text',
    'password_change_url',
    'max_password_age',
    'password_expiration_notification',
    'min_password_age',
  ];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const requiredValidator = (v) => ({
    message: 'Required.',
    invalid: () => !v,
    trigger: ['onBlur', validationDep],
  });

  const minMaxValidator = (v, min, max) => ({
    message: `Must be between ${min} and ${max}.`,
    invalid: () => {
      const n = parseInt(v, 10);
      return n < min || n > max;
    },
    trigger: ['onBlur', validationDep],
  });

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Password options</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Disallow automatic login"
              checked={setting.disallow_automatic_login}
              isDisabled={isDisabled}
              onChange={() => update('disallow_automatic_login', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>Passwords are not allowed to be saved to the keychain.</p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Disallow password change"
              checked={setting.disallow_password_change}
              isDisabled={isDisabled}
              onChange={() => update('disallow_password_change', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Disables password changes. Available in macOS 10.15 and later.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Require complex passwords"
              checked={setting.require_complex_passwords}
              isDisabled={isDisabled}
              onChange={() => update('require_complex_passwords', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Passwords must meet Active Directory&apos;s definition of
              &quot;complex&quot;. Available in macOS 10.15 and later.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Password history"
              checked={setting.isPasswordHistory}
              isDisabled={isDisabled}
              onChange={() => update('isPasswordHistory', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.password_history}>
              Specify the number of prior passwords that cannot be re-used on
              this domain. Available in macOS 10.15 and later.
            </p>
          </Setting.Helpers>

          {setting.isPasswordHistory && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.password_history}
                    onChange={(e) => {
                      const v = e.target.value;
                      const n = parseInt(v, 10);
                      if (!v.length || !Number.isNaN(n)) {
                        update('password_history', n);
                      }
                    }}
                    disabled={isDisabled}
                    placeholder="15"
                    onInvalidate={onInvalidate('password_history')}
                    validator={(v) => [
                      requiredValidator(v),
                      minMaxValidator(v, 1, 99),
                    ]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Password length"
              checked={setting.isPasswordLength}
              isDisabled={isDisabled}
              onChange={() => update('isPasswordLength', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.password_history}>
              Specify the minimum length of passwords on the domain. Available
              in macOS 10.15 and later.
            </p>
          </Setting.Helpers>

          {setting.isPasswordLength && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.password_length}
                    onChange={(e) => {
                      const v = e.target.value;
                      const n = parseInt(v, 10);
                      if (!v.length || !Number.isNaN(n)) {
                        update('password_length', n);
                      }
                    }}
                    disabled={isDisabled}
                    placeholder="8"
                    onInvalidate={onInvalidate('password_length')}
                    validator={(v) => [
                      requiredValidator(v),
                      minMaxValidator(v, 0, 99),
                    ]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        {!setting.require_complex_passwords && !setting.isPasswordLength && (
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                label="Password requirements text"
                checked={setting.isPasswordRequirementsText}
                isDisabled={isDisabled}
                onChange={() => update('isPasswordRequirementsText', (p) => !p)}
              />
            </Setting.Controls>
            <Setting.Helpers className="b-txt-light">
              <p ref={refs.password_requirements_text}>
                The password requirement helper text that is displayed to the
                end user upon a password requirement change. Only available for
                use if the “Require complex passwords” and “Password length”
                options are not being utilized. Available in macOS 10.15 and
                later.
              </p>
            </Setting.Helpers>

            {setting.isPasswordRequirementsText && (
              <Setting.SecondaryControls>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <TextInput
                      compact
                      textArea
                      removeValidationOnUnmount
                      value={setting.password_requirements_text}
                      onChange={(e) =>
                        update('password_requirements_text', e.target.value)
                      }
                      disabled={isDisabled}
                      placeholder="Passwords must use at least three of the four available character types: lowercase letters, uppercase letters, numbers, and symbols."
                      onInvalidate={onInvalidate('password_requirements_text')}
                      validator={(v) => [requiredValidator(v)]}
                    />
                  </Setting.Controls>
                </Setting.SecondaryControlsRow>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
        )}

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Sync password with local user"
              checked={setting.sync_local_password}
              isDisabled={isDisabled}
              onChange={() => update('sync_local_password', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Use Active Directory password as the local user password. Note
              that this will not work if the user is logged in with a mobile
              account. Available in macOS 10.15 and later.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Change password URL"
              checked={setting.isChangePasswordURL}
              isDisabled={isDisabled}
              onChange={() => update('isChangePasswordURL', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.password_change_url}>
              If set, the extension will open this URL during the change
              password process. Available in macOS 10.15.4 and later.
            </p>
          </Setting.Helpers>

          {setting.isChangePasswordURL && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.password_change_url}
                    onChange={(e) =>
                      update('password_change_url', e.target.value)
                    }
                    disabled={isDisabled}
                    placeholder="https://identity.accuhive.io/forgot"
                    onInvalidate={onInvalidate('password_change_url')}
                    validator={(v) => [requiredValidator(v)]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Maximum password age"
              checked={setting.isMaximumPasswordAge}
              isDisabled={isDisabled}
              onChange={() => update('isMaximumPasswordAge', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.max_password_age}>
              The number of days passwords can be used on this domain.
            </p>
          </Setting.Helpers>

          {setting.isMaximumPasswordAge && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.max_password_age}
                    onChange={(e) => {
                      const v = e.target.value;
                      const n = parseInt(v, 10);
                      if (!v.length || !Number.isNaN(n)) {
                        update('max_password_age', n);
                      }
                    }}
                    disabled={isDisabled}
                    placeholder="365"
                    onInvalidate={onInvalidate('max_password_age')}
                    validator={(v) => [
                      requiredValidator(v),
                      minMaxValidator(v, 0, 999),
                    ]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Password expiration notification"
              checked={setting.isPasswordExpirationNotification}
              isDisabled={isDisabled}
              onChange={() =>
                update('isPasswordExpirationNotification', (p) => !p)
              }
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.password_expiration_notification}>
              The number of days prior to password expiration when users begin
              receiving notifications.
            </p>
          </Setting.Helpers>

          {setting.isPasswordExpirationNotification && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.password_expiration_notification}
                    onChange={(e) => {
                      const v = e.target.value;
                      const n = parseInt(v, 10);
                      if (!v.length || !Number.isNaN(n)) {
                        update('password_expiration_notification', n);
                      }
                    }}
                    disabled={isDisabled}
                    placeholder="15"
                    onInvalidate={onInvalidate(
                      'password_expiration_notification',
                    )}
                    validator={(v) => [
                      requiredValidator(v),
                      minMaxValidator(v, 1, 999),
                    ]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Minimum password age"
              checked={setting.isMinimumPasswordAge}
              isDisabled={isDisabled}
              onChange={() => update('isMinimumPasswordAge', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.min_password_age}>
              The minimum age of passwords before they can be changed on this
              domain.
            </p>
          </Setting.Helpers>

          {setting.isMinimumPasswordAge && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.min_password_age}
                    onChange={(e) => {
                      const v = e.target.value;
                      const n = parseInt(v, 10);
                      if (!v.length || !Number.isNaN(n)) {
                        update('min_password_age', n);
                      }
                    }}
                    disabled={isDisabled}
                    placeholder="15"
                    onInvalidate={onInvalidate('min_password_age')}
                    validator={(v) => [
                      requiredValidator(v),
                      minMaxValidator(v, 0, 999),
                    ]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default KerberosPasswordOptions;
