import { TextInput } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';

import WifiService from '../../service/wifi-service';

const keys = WifiService.keys.authentication.acceptedEapTypes;

const OuterIdentity = ({ refs, isDisabled, update, setting }) => (
  <Setting.SecondaryControlsRow>
    <div>
      <p className="b-txt">Outer identity</p>
    </div>
    <div>
      <p className="b-txt-light">
        Outer identity used to increase security by hiding the user&apos;s true
        name. The outer identity is visible in frame captures, but the
        user&apos;s actual name appears only inside the encrypted tunnel.
      </p>
    </div>
    <div>
      <TextInput
        disabled={isDisabled}
        value={setting[keys.outerIdentity]}
        onChange={(e) => update(keys.outerIdentity, e.target.value)}
        compact
        isOptional
        placeholder="anonymous@accuhive.com"
        removeValidationOnUnmount
      />
    </div>
  </Setting.SecondaryControlsRow>
);

export default React.memo(OuterIdentity);
