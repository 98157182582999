import { withPermissions } from 'contexts/account';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUsersCounts as callGetUsersCounts } from '../_actions/gSuite';
import TabsContainer from './gSuiteUsers/TabsContainer';
import { TableHeader, TableHeaderDescription } from './interface/Base';

const fields = [
  'archived_g_suite_users',
  'device_g_suite_users',
  'without_devices_g_suite_users',
];

export class GSuiteUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSyncing: false,
    };
  }

  componentDidMount() {
    const { getUsersCounts } = this.props;

    this.setState({ isLoading: true }, () => {
      getUsersCounts(fields).then(() => {
        this.setState({ isLoading: false });
      });
    });
  }

  render() {
    const { location, match, history, gSuiteUsersCounts } = this.props;
    const routerProps = () => ({
      location,
      match,
      history,
    });
    return (
      <div>
        <TableHeader title="Device Users" sticky />
        <TableHeaderDescription />
        <TabsContainer
          gSuiteUsersCounts={gSuiteUsersCounts}
          {...routerProps()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gSuiteUsersCounts: state.gSuite.counts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsersCounts: callGetUsersCounts,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(GSuiteUserList));
