import { modal as Modal } from '@kandji-inc/bumblebee';
import { bool, func, object } from 'prop-types';
import React from 'react';
import Content from './components/content';
import Footer from './components/footer';

const NotificationDeleteModal = (props) => {
  const { isOpen, data, onDelete, onCancel } = props;

  const { name } = data;

  return (
    <Modal
      isOpen={isOpen}
      withoutCloseButton
      disableCloseOnOutsideClick
      parts={{
        header: {
          text: 'Delete event notification',
        },
        content: {
          children: <Content name={name} />,
        },
        footer: {
          children: <Footer onCancel={onCancel} onDelete={onDelete} />,
        },
      }}
    />
  );
};

export default NotificationDeleteModal;

NotificationDeleteModal.propTypes = {
  isOpen: bool.isRequired,
  data: object.isRequired,
  onDelete: func.isRequired,
  onCancel: func.isRequired,
};
