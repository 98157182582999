import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SupportLink = styled('a')`
  color: #0085ff;
`;

const KandjiSupportLink = ({ href, text, className }) => (
  <SupportLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    {text}
  </SupportLink>
);

KandjiSupportLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

KandjiSupportLink.defaultProps = {
  text: 'Learn More',
  className: '',
};

export default KandjiSupportLink;
