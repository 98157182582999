import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from './column-utils';
import { getGlobalColumns } from './globalColumns';

export const getInstalledProfilesColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }
  const installedProfilesColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, installedProfilesColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    installedProfilesColumnHelper.accessor((row) => row.profile_display_name, {
      id: 'profile_display_name',
      meta: {
        displayName: 'Display Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.payload_identifier, {
      id: 'payload_identifier',
      meta: {
        displayName: 'Identifier',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.managed, {
      id: 'managed',
      cell: YesNoCell,
      meta: {
        displayName: 'Managed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.organization, {
      id: 'organization',
      meta: {
        displayName: 'Organization',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.profile_data, {
      id: 'profile_data',
      cell: (info) => {
        const cellValue = info.getValue();
        const profileDataJSON = JSON.stringify(cellValue, null, 2);
        return getCodeSnippetCell(info, profileDataJSON);
      },
      meta: {
        displayName: 'Data',
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.payload_uuid, {
      id: 'payload_uuid',
      meta: {
        displayName: 'UUID',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.removal_passcode, {
      id: 'removal_passcode',
      cell: YesNoCell,
      meta: {
        displayName: 'Removal Passcode',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.encrypted, {
      id: 'encrypted',
      cell: YesNoCell,
      meta: {
        displayName: 'Encrypted',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.profile_description, {
      id: 'profile_description',
      meta: {
        defaultHidden: true,
        displayName: 'Description',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.removable, {
      id: 'removable',
      cell: YesNoCell,
      meta: {
        displayName: 'Removable',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
        defaultHidden: true,
      },
    }),

    installedProfilesColumnHelper.accessor((row) => row.signed, {
      id: 'signed',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Signed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
