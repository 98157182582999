import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text } from '@kandji-inc/nectar-ui';
import { ENTERPRISE_API_EXTERNAL_BASE } from 'app/api/domains';
import React, { useState } from 'react';

import { api } from 'app/api/base';
import { URL_DEVICES } from 'app/api/urls';

const ComputerRenewMDM = (props) => {
  const { info, onHide } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);

  const onRenew = () => {
    setIsLoading(true);
    api(
      `${ENTERPRISE_API_EXTERNAL_BASE}${URL_DEVICES}${computerId}/action/renewmdmprofile`,
    )
      .post({})
      .then(() => {
        onHide();
        setIsLoading(false);
        toaster('Command initiated.');
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster('Something went wrong.');
      });
  };

  const content = (
    <Text>
      {`This action re-installs the existing root MDM profile and will not affect
      any existing configurations, apps, or profiles. If you're unsure if you
      need to do this, please contact support.`}
    </Text>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="primary" loading={isLoading} onClick={onRenew}>
        Renew MDM Profile
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Renew MDM Profile"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerRenewMDM;
