import React from 'react';
import addDevice from '../assets/add-device.svg';
import oathInt from '../assets/oath-int.svg';
import oktaVerifyOutline from '../assets/okta-verify-outline.svg';
import oktaIdentityEngine from './assets/oie.svg';
import oktaFastpass from './assets/okta-fastpass.svg';
import oktaIcon from './assets/okta-icon-outline.svg';

// Helpers
import {
  isFastpassDisabled,
  isIdentityEnginesDisabled,
} from '../../integrator/configs/okta/helper';

export default [
  {
    text: 'Learn how to set up, manage and deploy this integration with Okta to your Apple fleet.',
    title: 'Okta Device Trust overview',
    link: 'https://support.kandji.io/support/solutions/articles/72000580342',
    icon: oktaIcon,
  },
  {
    text: 'Before you get started, make sure that your Okta account includes Okta Identity Engine and FastPass is enabled.',
    title: 'Okta Identity Engine and FastPass',
    link: 'https://help.okta.com/oie/en-us/Content/Topics/identity-engine/oie-index.htm',
    icon: oktaIdentityEngine,
  },
];

export const oktaSignInTickets = [
  {
    text: 'In the Okta console, you will create an API Service Integration to establish a secure connection between Kandji and Okta API access.',
    title: 'Create an API Service Integration',
    link: 'https://support.kandji.io/support/solutions/articles/72000580346',
    icon: oathInt,
  },
  {
    text: 'Set up the device management providers your organization will require for device trust. Choose to set this up for macOS, iOS or both. ',
    title: 'Add device platforms',
    link: 'https://support.kandji.io/support/solutions/articles/72000580346#Configure-device-platforms-in-Kandji',
    icon: addDevice,
  },
];

export const oktaVerifyAppTicket = {
  paragraphs: [
    'Sign in to Apple administrative account to obtain licenses for Okta Verify by clicking on your portal link below. Assign the licenses to the Location assigned to Kandji.',
    'Once that is complete, you can sync the Okta Verify app to your Library below or manually sync them later on the Library page.',
  ],
  title: 'Add Okta Verify to your Library',
  icon: oktaVerifyOutline,
};

export const VerifyAppTicket = () => (
  <>
    <p className="b-txt-light b-mt-tiny b-mb-tiny">
      Sign in to Apple administrative account to obtain licenses for Okta Verify
      by clicking on your portal link below. Assign the licenses to the Location
      assigned to Kandji.
    </p>
    <p className="b-txt-light b-mt-tiny b-mb-tiny">
      Once that is complete, you can sync the Okta Verify app to your Library
      below or manually sync them later on the Library page.
    </p>
  </>
);
export const oktaVerifyAppTickets = [
  {
    text: <VerifyAppTicket />,
    title: 'Add Okta Verify to your Library',
    icon: oktaVerifyOutline,
    link: '',
  },
];

export const oktaSignInErrorTickets = [
  {
    title: 'Okta Identity Engine must be on',
    text: 'Sign into the Okta admin console and turn on Okta Identity Engine to complete this integration',
    link: 'https://help.okta.com/oie/en-us/Content/Topics/identity-engine/oie-index.htm',
    icon: oktaIdentityEngine,
    condition: isIdentityEnginesDisabled,
  },
  {
    title: 'FastPass must be enabled',
    text: 'Enable FastPass for your Okta account',
    link: 'https://help.okta.com/oie/en-us/Content/Topics/identity-engine/devices/fp/fp-main.htm',
    icon: oktaFastpass,
    condition: isFastpassDisabled,
  },
];

export const oktaSetupCompleteTickets = [
  {
    title: 'Deploy device trust with Okta Verify',
    text: 'Navigate to the Okta Verify Library Item and turn on the Okta Device Trust integration under settings. Assigning Okta Verify to the correct Blueprints will allow you to deploy device trust to your fleet.',
    link: 'https://support.kandji.io/support/solutions/articles/72000580418-okta-device-trust-configuring-the-okta-verify-library-item',
    icon: oktaVerifyOutline,
  },
];
