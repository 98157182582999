import React, { useEffect } from 'react';

import { Banner, Button, Flex, modal as Modal } from '@kandji-inc/bumblebee';

import { STATUS_PENDING } from 'features/integrations/components-for-specific-types/adcs/status';
import { useAuthority, useConnector, useIs, useModal } from '../../hooks';

const ModalDeleteConnector = () => {
  const { fetchAuthorityList } = useAuthority();

  const {
    connector,
    connectorItem,
    fetchConnectorItem,
    fetchConnectorList,
    clearConnector,
    clearConnectorItem,
    deleteConnectorItem,
  } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { modal, closeModal } = useModal();

  const isOpen = !!modal['delete-connector'];

  const { status } = connectorItem;
  const isPending = status === STATUS_PENDING;

  /* istanbul ignore next */
  const reset = () => {
    clearConnector();
    clearConnectorItem();
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closeModal(reset);
  };

  /* istanbul ignore next */
  const onDelete = async () => {
    await deleteConnectorItem(connector);
    fetchAuthorityList();
    fetchConnectorList();
    closeModal(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && connector) {
      fetchConnectorItem();
    }
  }, [isOpen, connector]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={
        // onClose is required by Modal but not needed by this component
        /* istanbul ignore next */
        () => {}
      }
      withoutCloseButton
      animationDuration={250}
      parts={{
        header: {
          text: 'Delete AD CS connector',
        },
        content: {
          children: (
            <>
              {
                /* istanbul ignore next */
                isPending ? (
                  <p className="b-txt" style={{ width: 496 }}>
                    The connector is still in a pending state, and will be
                    permanently removed. This action cannot be undone.
                  </p>
                ) : (
                  <p className="b-txt" style={{ width: 496 }}>
                    The integration with{' '}
                    <strong>Connector {connectorItem.name}</strong> will be
                    permanently removed. This action cannot be undone.
                  </p>
                )
              }

              <Banner
                className="b-mt2"
                theme="warning"
                icon="octagon-exclamation"
              >
                <p>
                  In order to issue certificates to your Apple fleet, it is
                  required to have an active AD CS Connector with assigned AD CS
                  servers.
                </p>
              </Banner>
            </>
          ),
        },
        footer: {
          children: (
            <Flex>
              <Button
                kind="outline"
                onClick={onCancel}
                isDisabled={isLoading || isWorking}
              >
                Cancel
              </Button>

              <Button
                className="l-ml"
                theme="error"
                onClick={onDelete}
                isDisabled={isLoading || isWorking}
                isProgress={isWorking}
              >
                Delete connector
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
};

export default ModalDeleteConnector;
