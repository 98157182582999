import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ModalContent = styled.div`
  padding: 0;
  display: grid;
  width: 560px;
`;

const Content = (props) => {
  const { name } = props;
  return (
    <ModalContent>
      <p className="b-txt">
        Are you sure you would like to delete <b>{name}</b>? This action cannot
        be undone.
      </p>
    </ModalContent>
  );
};

export default Content;

Content.propTypes = {
  name: string.isRequired,
};
