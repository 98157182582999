import {
  Button,
  DropdownMenu,
  type DropdownMenuOption,
  styled,
} from '@kandji-inc/nectar-ui';
import type { CSS } from '@kandji-inc/nectar-ui';

const EllipsisButton = styled(Button, {
  width: 36,
  height: 36,
  '&:not(:disabled)': {
    backgroundColor: '$transparent',
  },
  '&:not(:disabled):focus': {
    backgroundColor: '$transparent',
    boxShadow: 'none',
  },
  '&:not(:disabled):hover': {
    backgroundColor: 'var(--colors-button_subtle_background_hover)',
  },
  '&:not(:disabled):active': {
    backgroundColor: 'var(--colors-button_subtle_background_active)',
  },
});

type TableActionsDropdownProps = {
  options: DropdownMenuOption[];
  testId?: string;
  css?: CSS;
};

const TableActionsDropdown = (props: TableActionsDropdownProps) => {
  const { options, testId, css = {} } = props;

  return (
    <DropdownMenu
      withArrow={false}
      contentProps={{ align: 'end' }}
      options={options}
      css={css}
    >
      <EllipsisButton
        variant="subtle"
        compact
        icon={{ name: 'ellipsis' }}
        onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}
        data-testid={testId}
      />
    </DropdownMenu>
  );
};

export default TableActionsDropdown;
