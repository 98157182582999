import { Button, Table } from '@kandji-inc/bumblebee';
import { inclusionLvl } from 'contexts/environment';
import ImgWithAlt from 'features/util/components/img-with-alt';
import OptionsMenu from 'features/util/components/options-menu';
import { arrayOf, bool, func } from 'prop-types';
import React, { useState } from 'react';
import {
  IPCard,
  IPCardSection,
  IPCardTitle,
} from '../../../building-blocks/card-parts';
import {
  CONFIG_SHAPE,
  INTEGRATION_SHAPE,
  NOTIFICATION_SHAPE,
  SORT_ORDER,
} from '../../util';
import ExpandedNotificationRow from '../expanded-notification-row';
import { NoNotificationsMessage } from './components/no-notification-message';
import './integration-card.styles.scss';

function IntegrationCard({
  integration,
  notificationsForIntegration,
  openNotificationEditor,
  openDeleteIntegrationModal,
  openDeleteNotificationModal,
  config,
  isAddEventNotificationButtonDisabled,
}) {
  const reAuthIsAvailable = inclusionLvl(0);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.asc);

  const { integrationAlias, makeIntegrationLink, getTableColumns } = config;

  const sorted = [...notificationsForIntegration].sort((a, b) =>
    a.name > b.name ? sortOrder : -sortOrder,
  );

  const { display_name, name, icon } = integration;

  return (
    <>
      <IPCard className="integration-card">
        <IPCardTitle className="integration-card_title">
          <ImgWithAlt
            alt="integration-icon"
            src={[icon, config.icons.medium]}
            height={36}
            width={36}
          />
          <h4 className="b-h4 b-mr b-ml-tiny cmn-title--size-medium">
            {display_name || name}
          </h4>
          <div className="b-txt-light">{makeIntegrationLink(name)}</div>

          <OptionsMenu
            className="integration-card_options-btn"
            render={(close) => (
              <>
                {reAuthIsAvailable && (
                  <Button
                    icon="arrows-rotate"
                    kind="link"
                    onClick={() => {
                      close();
                    }}
                  >
                    Reauthenticate {integrationAlias}
                  </Button>
                )}

                <Button
                  icon="trash-can"
                  theme="error"
                  kind="link"
                  onClick={() => {
                    close();
                    openDeleteIntegrationModal();
                  }}
                >
                  Delete {integrationAlias}
                </Button>
              </>
            )}
          >
            <Button icon="ellipsis" kind="link" />
          </OptionsMenu>
        </IPCardTitle>

        <Table
          columns={getTableColumns(
            openDeleteNotificationModal,
            openNotificationEditor,
          )}
          sort={sortOrder === SORT_ORDER.asc ? 'name' : '-name'}
          setSort={() => setSortOrder((curr) => -1 * curr)}
          ExpandedComponent={ExpandedNotificationRow}
          data={sorted}
          className="integration-card_table"
          noDataMessage={
            <NoNotificationsMessage
              onButtonClick={openNotificationEditor}
              isButtonDisabled={isAddEventNotificationButtonDisabled}
            />
          }
        />
        {!!sorted.length && (
          <IPCardSection>
            <Button
              icon="circle-plus"
              kind="link"
              onClick={() => openNotificationEditor()}
              className="cmn-button--size-medium"
            >
              Add event notification
            </Button>
          </IPCardSection>
        )}
      </IPCard>
    </>
  );
}

IntegrationCard.propTypes = {
  integration: INTEGRATION_SHAPE.isRequired,
  notificationsForIntegration: arrayOf(NOTIFICATION_SHAPE).isRequired,
  openNotificationEditor: func.isRequired,
  openDeleteIntegrationModal: func.isRequired,
  openDeleteNotificationModal: func.isRequired,
  config: CONFIG_SHAPE.isRequired,
  isAddEventNotificationButtonDisabled: bool.isRequired,
};

export default IntegrationCard;
