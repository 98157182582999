import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import { bool, func, shape, string } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

import HubSpotHandler from 'components/common/hubspot-handler';

import { sfSymbolsRegularIcons } from '../../icons';
import initialState from '../../initial-state';
import { STATUSES, TYPES } from '../constants';
import { AddEditInputs, EditAllInputs, EditRecommendedInputs } from './inputs';

const AddEditPanel = ({
  addEditCategory,
  type,
  handlers,
  setBody,
  currArgs,
}) => {
  const {
    closePanel,
    openPanelType,
    update: u,
    getConflicts,
    onDelete,
    onSave,
    onSaveRecAllCategory,
  } = handlers;
  const update = u('addEditCategory');
  const { IDLE, LOADING, SAVING, DISABLED } = STATUSES;
  const [status, setStatus] = useState(IDLE);
  const isLoading = status === LOADING;
  const isSaving = status === SAVING;
  const isDisabled = status === DISABLED;
  const hasSavedDataRetrieved = useRef(false);

  useEffect(() => {
    if (type !== TYPES.ADD && hasSavedDataRetrieved.current === false) {
      setStatus(LOADING);
    }
    if (
      hasSavedDataRetrieved.current === false &&
      (type === TYPES.ADD || addEditCategory.name !== '')
    ) {
      hasSavedDataRetrieved.current = true;
      setStatus(IDLE);
    }
  }, [addEditCategory.name, type]);

  useEffect(() => {
    const { name, id, description, icon, iconImg } = currArgs;
    const withCustomNameArgs =
      type === TYPES.EDIT_RECOMMENDED
        ? {
            customName: '',
            isCustomNameSelected: false,
          }
        : {};
    update({
      batchKeyValues: {
        name,
        id,
        description,
        icon,
        iconImg,
        ...withCustomNameArgs,
      },
    });
  }, [currArgs]);

  useEffect(() => {
    const defaultIcon = sfSymbolsRegularIcons.find(
      ({ icon }) => icon === 'app',
    );
    if (type === TYPES.ADD && defaultIcon) {
      update({
        batchKeyValues: { icon: defaultIcon.icon, iconImg: defaultIcon.src },
      });
    }
  }, [type]);

  const resetAddEditState = () =>
    update({
      batchKeyValues: initialState.addEditCategory,
    });

  const onClose = () => {
    closePanel(() => resetAddEditState());
  };

  const checkDisableSave = (value = addEditCategory.name) => {
    if (!value) {
      setStatus(DISABLED);
      return false;
    }
    setStatus(IDLE);
    return true;
  };

  const checkDisableCustomNameSave = () => {
    if (addEditCategory.isCustomNameSelected) {
      return checkDisableSave(addEditCategory.customName);
    }
    return checkDisableSave(addEditCategory.name);
  };

  const handleSaveAddEdit = async () => {
    const canSave = checkDisableSave();
    if (canSave) {
      setStatus(SAVING);
      try {
        await onSave(addEditCategory, type === TYPES.EDIT);
      } catch (error) {
        toaster('Something went wrong.');
      }
      closePanel(() => {
        setStatus(IDLE);
        resetAddEditState();
      });
    }
  };

  const handleSaveEditRecAll = async () => {
    const canSave = checkDisableCustomNameSave();
    if (canSave) {
      setStatus(SAVING);
      try {
        await onSaveRecAllCategory(
          addEditCategory,
          type === TYPES.EDIT_RECOMMENDED,
        );
      } catch (error) {
        toaster('Something went wrong.');
      }
      closePanel(() => {
        setStatus(IDLE);
        resetAddEditState();
      });
    }
  };

  const getSaveType = () => {
    if ([TYPES.EDIT, TYPES.ADD].includes(type)) {
      return handleSaveAddEdit;
    }
    if ([TYPES.EDIT_ALL, TYPES.EDIT_RECOMMENDED].includes(type)) {
      return handleSaveEditRecAll;
    }

    return () => toaster('Something went wrong.');
  };

  const inputsByType = {
    [TYPES.ADD]: (
      <AddEditInputs
        addEditCategory={addEditCategory}
        update={update}
        checkDisableSave={checkDisableSave}
        status={status}
        isAdd
      />
    ),
    [TYPES.EDIT]: (
      <AddEditInputs
        addEditCategory={addEditCategory}
        update={update}
        checkDisableSave={checkDisableSave}
        status={status}
      />
    ),
    [TYPES.EDIT_RECOMMENDED]: (
      <EditRecommendedInputs
        addEditCategory={addEditCategory}
        update={update}
        status={status}
        checkDisableSave={checkDisableSave}
      />
    ),
    [TYPES.EDIT_ALL]: (
      <EditAllInputs
        addEditCategory={addEditCategory}
        update={update}
        checkDisableSave={checkDisableSave}
        status={status}
      />
    ),
  };

  return (
    <>
      <HubSpotHandler />
      <div className="b-side-panel-layout__body" ref={setBody}>
        <div className="b-form-grid">
          {hasSavedDataRetrieved.current ? inputsByType[type] : null}
        </div>
      </div>
      <div className="b-side-panel-layout__footer">
        <div
          className={`${
            type === TYPES.EDIT ? 'b-flex-btw' : 'b-flex-justify-end'
          }`}
        >
          {type === TYPES.EDIT && (
            <Button
              kind="link"
              theme="error"
              isProgress={isLoading}
              isDisabled={isSaving}
              icon={isLoading ? 'arrows-rotate' : ''}
              onClick={() => {
                setStatus(LOADING);
                getConflicts(currArgs.id)
                  .then((conflicts) =>
                    conflicts.length
                      ? openPanelType.delete({
                          conflicts,
                          category: currArgs,
                        })
                      : onDelete(currArgs.id).then(closePanel),
                  )
                  .finally(() => setStatus(LOADING));
              }}
            >
              Delete
            </Button>
          )}
          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onClose}
              isDisabled={isLoading || isSaving}
            >
              Cancel
            </Button>
            <Button
              onClick={getSaveType()}
              isDisabled={isLoading || isDisabled}
              icon={isSaving ? 'arrows-rotate' : ''}
              isProgress={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

AddEditPanel.propTypes = {
  addEditCategory: shape({
    id: string,
    icon: string,
    iconImg: string,
    name: string,
    description: string,
    customName: string,
    isCustomNameSelected: bool,
  }),
  handlers: shape({
    closePanel: func,
    openPanelType: shape({
      delete: func,
      customize: func,
      add: func,
      edit: func,
      editRecommended: func,
      editAll: func,
    }),
    update: func,
    getConflicts: func,
    onDelete: func,
    onSave: func,
    onSaveRecAllCategory: func,
  }),
  type: string,
  currArgs: shape({
    id: string,
    icon: string,
    iconImg: string,
    name: string,
    description: string,
  }),
  setBody: func,
};

AddEditPanel.defaultProps = {
  addEditCategory: initialState.addEditCategory,
  handlers: {
    closePanel: () => {},
    openPanelType: () => {},
    update: () => {},
    getConflicts: () => {},
    onDelete: () => {},
    onSave: () => {},
    onSaveRecAllCategory: () => {},
  },
  type: 'add',
  currArgs: {},
  setBody: () => {},
};

export default AddEditPanel;
